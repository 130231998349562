export function validateText(text,name="") {
    const minLength = 3;

    if (text.length < minLength) {
      return "Must be at least 3 characters";
    }
  
    if (text.includes("  ")) {
      return "Double empty space is not allowed";
    }
  
    if (!/^[a-zA-Z\s]+$/.test(text)) {
      return `Invalid characters in the ${name}`;
    }
  
    if (!/[a-zA-Z]/.test(text)) {
      return "Invalid text";
    }
  
    return false;
  }

export function validateFlightNumber(flightNumber) {
    // Check if flight number is empty
    if (flightNumber.trim() === "") {
      return null; // No error if flight number is empty
    }
  
    // Regular expression to match flight number pattern
    const flightNumberPattern = /^[A-Za-z0-9\s]{2,6}$/;
  
    // Check if the flight number matches the pattern
    if (!flightNumberPattern.test(flightNumber)) {
      return "Invalid flight number";
    }
  
    // If all conditions passed, return null indicating no error
    return null;
  }