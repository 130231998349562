import { Requests } from "../../../../services";
import Local from "../../local";

export type ThunkSignUpRepo = {
  postSignUp: (error: any) => any;
};

const ThunkSignUp: ThunkSignUpRepo = {
  postSignUp: (error) => (dispatch: any, getState: any, mobioApi: any) => {
    if (error !== "") {
      dispatch(
        Local.SignUp.api.error(
          "Request Failed",
          error
        )
      );
    } else {
      const api: Requests = mobioApi();
      const {
        signUp: {
          state: {
            userName,
            email,
            password,
            companyName,
            country,
            city,
            address,
            addressLineTwo,
            postalCode,
            IBAN,
          },
        },
      } = getState();
      dispatch(Local.SignUp.api.request());
  
      return api
        .signup({
          displayName: userName,
          email,
          password,
          companyName,
          country,
          city,
          activeCity: city,
          address,
          addressLineTwo,
          activeCountry: country,
          postalCode,
          IBAN,
        })
        .then((response: any) => {
          dispatch(Local.SignUp.resetAll());
          dispatch(
            Local.SignUp.api.response(
              "Sign up successful. Check and verify your email."
            )
          );
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              Local.SignUp.api.error(
                "Request Failed",
                "The API request timed out. Please Refresh the page"
              )
            );
          } else {
            let errorMsg = "Failed!";
            if (error?.response?.data?.msg) {
              errorMsg = error?.response?.data?.msg;
            }
            dispatch(Local.SignUp.api.error("Request Failed", errorMsg));
          }
        });
    }
  },
};

export default ThunkSignUp;
