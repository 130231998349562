import { useEffect, useRef, useState } from "react";
import DistanceRateEditModal from "./Modals/DistanceRateEditModal";
import EditIcon from "../../../assets/icons/lucide_edit.svg";
import CheckRateModal from "./Modals/CheckRateModal";
import mobioApi from "../../../services";
import { useParams } from "react-router";
import { getCountryCurrency } from "../../../helpers/getCountryCurrency";
import { filterServiceLevel } from "../../../helpers/filterServiceLevel";

const DistanceRate = ({
  token,
  serviceLevelsList,
  checkRateModalOpen,
  setCheckRateModalOpen = () => {},
  setRateStatusData = () => {},
}) => {
  const { airportId, country, integrationType } = useParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [distanceRateList, setDistanceRateList] = useState([]);
  const [selectDefaultService, setSelectDefaultService] = useState(null);

  const [countryCurrency, setCountryCurrency] = useState("");

  const countryCurrencyHandle = async (countryName) => {
    const currency = await getCountryCurrency(countryName);
    setCountryCurrency(currency);
  };

  const getDistanceRates = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getDistanceRate(airportId);
      const getResponseData = response?.data?.data;
      // setDistanceRateList(response?.data?.data);
      // check if there is distance rate is have some data
      setDistanceRateList(
        filterServiceLevel(integrationType, getResponseData || [])
      );
      if (getResponseData) {
        getResponseData?.forEach((item) => {
          if (item?.distanceRatesLevels?.length > 0) {
            setRateStatusData((prev) => ({
              ...prev,
              distanceRates: true,
            }));
          }
        });
      }
    } catch (error) {
      setDistanceRateList([]);
    }
  };

  const updateDistanceList = (data) => {
    const updateList = distanceRateList?.map((dm) => {
      if (Object.keys(data).length > 0 && dm.id == data.serviceLevelId) {
        if (dm?.distanceRatesLevels?.length > 0) {
          dm.distanceRatesLevels[0].initialRate = data.initialRate;
          dm.distanceRatesLevels[0].initialDistance = data.initialDistance;
          dm.distanceRatesLevels[0].pricePerKM = data.pricePerKM;
          dm.distanceRatesLevels[0].pricePerMint = data.pricePerMint;
          dm.distanceRatesLevels[0].waitPrice = data.waitPrice;
        } else {
          const appendData = {
            initialRate: data.initialRate,
            initialDistance: data.initialDistance,
            pricePerKM: data.pricePerKM,
            pricePerMint: data.pricePerMint,
            waitPrice: data.waitPrice,
          };
          dm.distanceRatesLevels.push(appendData);
        }
        setRateStatusData((prev) => ({
          ...prev,
          distanceRates: true,
        }));
      }
      return dm;
    });
    setDistanceRateList(updateList);
  };

  useEffect(() => {
    getDistanceRates();
    if (country) {
      countryCurrencyHandle(country);
    }
  }, []);

  useEffect(() => {
    if (!modalOpen) {
      setSelectDefaultService("");
    }
  }, [modalOpen]);

  return (
    <div>
      {/* Distance Rate */}
      <div className="bg-white  mt-20 border-radius-8">
        <table className="custom-table-v3">
          <thead>
            <tr>
              <th>Vehicle Type</th>
              <th>Initial Rate</th>
              <th>Rate Per KM</th>
              <th>Rate Per Minute</th>
              <th>Rate For Waiting</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {distanceRateList?.map((row, index) => (
              <tr key={index}>
                <td>
                  <div className="flex align-item-center">
                    <img
                      src={row.icon}
                      alt="icon"
                      className="distance-rate-table-icon"
                    />
                    <p className="m-0"> {row.name}</p>
                  </div>
                </td>
                <td>
                  {row.distanceRatesLevels.length > 0
                    ? `${row.distanceRatesLevels[0]?.initialRate} ${countryCurrency} (${row.distanceRatesLevels[0]?.initialDistance} KM)`
                    : "--"}{" "}
                </td>
                <td>
                  {row.distanceRatesLevels.length > 0
                    ? `${row.distanceRatesLevels[0]?.pricePerKM} ${countryCurrency}`
                    : "--"}{" "}
                </td>
                <td>
                  {row.distanceRatesLevels.length > 0
                    ? `${row.distanceRatesLevels[0]?.pricePerMint} ${countryCurrency}`
                    : "--"}
                </td>
                <td>
                  {row.distanceRatesLevels.length > 0
                    ? `${row.distanceRatesLevels[0]?.waitPrice} ${countryCurrency}`
                    : "--"}
                </td>
                <td>
                  <img
                    src={EditIcon}
                    className="cursor-pointer"
                    style={{ width: "23px", height: "23px" }}
                    onClick={() => {
                      setSelectDefaultService(row?.id);
                      setModalOpen(true);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <DistanceRateEditModal
        token={token}
        airportId={airportId}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        distanceRateList={distanceRateList}
        serviceLevelsList={serviceLevelsList}
        updateDistanceList={updateDistanceList}
        selectDefaultService={selectDefaultService}
        countryCurrency={countryCurrency}
      />
      <CheckRateModal
        modalOpen={checkRateModalOpen}
        setModalOpen={setCheckRateModalOpen}
        data={distanceRateList}
        countryCurrency={countryCurrency}
      />
    </div>
  );
};

export default DistanceRate;
