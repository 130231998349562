import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Box, Button, Grid, Modal, Paper, Checkbox, TextField, Toolbar, Typography, MenuItem, Select } from "@material-ui/core";
import MaterialTable from "material-table";
import CardContent from "@material-ui/core/CardContent";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import mobioApi from "../../../services";
import ThunksBooking from "../../../redux/actions/thunks/bookings";
import Local from "../../../redux/actions/local";
import ThunkCommission from "../../../redux/actions/thunks/commission";
import moment from "moment";
import { useSearchParams } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  mainCard: {
    display: "flex",
    alignContent: "center",
    minWidth: 275,
  },
  root: {
    padding: 20
    // justifyContent: "center",
    // alignItems: "center",
  },

  title: {
    // fontSize: 24,
    // fontWeight: 600,
    // textAlign: "center",
    marginLeft: 80,
    marginRight: 80,
    // marginBottom: 28,
  },
  input: {
    display: "flex",
    flexDirection: "column",
  },
  innerInput: {
    paddingTop: 48,
  },
  button: {
    backgroundColor: theme.palette.error.main,
    color: "#fff",
  },
}));
const IntegrationCommission = ({
  connectSuccess,
  connectError,
  reset,
  error,
  token,
  loader,
  message,
  addCommission,
  getCommissions,
  updateCommission,
  deleteCommission
}) => {
  const classes = useStyles();
  const [commissions, setCommissions] = useState([])
  const [selected, setSelected] = useState([])
  const [success, setSuccess] = useState(false);
  const [load, setLoad] = useState(true);
  const [commission, setCommission] = useState(null);
  const [serviceLevel, setServiceLevel] = useState(0);
  const [roundable, setRoundable] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const [toTime, setToTime] = useState(
    dayjs('2018-01-01T00:00:00.000Z')
  );
  const [fromTime, setFromTime] = useState(
    dayjs('2018-01-01T00:00:00.000Z')
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get("integration")

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    let fromTimeUTC = fromTime.add(new Date().getTimezoneOffset(), "Minute")
    let toTimeUTC = toTime.add(new Date().getTimezoneOffset(), "Minute")
    let fromT = fromTimeUTC.hour() + ":" + fromTimeUTC.minute() + ":" + fromTimeUTC.second()
    let toT = toTimeUTC.hour() + ":" + toTimeUTC.minute() + ":" + toTimeUTC.second()

    if (toTime && fromTime && commission && serviceLevel !== 0) {
      let result = await addCommission({
        value,
        toTime: toT,
        fromTime: fromT,
        commission,
        roundable,
        serviceLevel
      });

      setOpen(false);
      if (result) {
        setToTime(dayjs('2018-01-01T00:00:00.000Z'));
        setFromTime(dayjs('2018-01-01T00:00:00.000Z'));
        setCommission(null);
        setRoundable(false);
        setServiceLevel(0)
      }

      await fetchCommissions();
    } else {
      alert("Please fill all required fields.")
    }

  }

  const fetchCommissions = async () => {
    let commissions = await getCommissions(value);
    setCommissions(commissions);
    setLoad(false);
  }

  useEffect(() => {
    (async () => {
      await fetchCommissions()
    })();
  }, [connectSuccess, connectError, loader, load]);

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const columns = [
    { title: "Commission(%)", field: "commission" },
    {
      title: "From Time",
      field: "fromTime",
      type: "datetime",
      render: (data) => {
        return moment(data.fromTime, 'HH:mm:ss').format('hh:mm:ss A');
      },
      editComponent: ({ value, onChange }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            margin="normal"
            id="time-picker"
            value={moment(value, 'HH:mm:ss')}
            onChange={(event) => onChange(event)}
            KeyboardButtonProps={{
              "aria-label": "change time"
            }}
            ampm={true}
          />
        </MuiPickersUtilsProvider>
      )
    },
    {
      title: "To", field: "toTime",
      render: (data) => {
        return moment(data.toTime, 'HH:mm:ss').format('hh:mm:ss A');
      },
      editComponent: ({ value, onChange }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            margin="normal"
            id="time-picker"
            value={moment(value, 'HH:mm:ss')}
            onChange={(event) => onChange(event)}
            KeyboardButtonProps={{
              "aria-label": "change time"
            }}
            ampm={true}
          />
        </MuiPickersUtilsProvider>
      )
    },
    { title: "Service Level", field: "serviceLevel", lookup: { "STANDARD": "STANDARD", "EXECUTIVE": "EXECUTIVE", "ELECTRIC": "ELECTRIC", "MINI_BUS": "MINI BUS", "CARRIER": "CARRIER", "LARGE_CARRIER": "LARGE CARRIER", "EXE_CARRIER": "EXE CARRIER", "LUXURY": "LUXURY", "ELECTRIC_LUXURY": "ELECTRIC LUXURY", "ELECTRIC_PEOPLE_CARRIER": "ELECTRIC PEOPLE CARRIER", "ELECTRIC_EXE_PEOPLE_CARRIER": "ELECTRIC EXE PEOPLE CARRIER", "ELECTRIC_LARGE_PEOPLE_CARRIER": "ELECTRIC LARGE PEOPLE CARRIER" } },
    { title: "Roundable", field: "roundable", lookup: { true: "Yes", false: "No" } },
    {
      title: "Apply", field: "apply",
      lookup: {
        true: <Checkbox {...label} checked={true} style={{ marginLeft: -11 }} disabled />,
        false: <Checkbox {...label} checked={false} style={{ marginLeft: -11 }} disabled defaultChecked />
      }
    },
  ];

  return (
    <>
      <Button
        style={{
          borderRadius: 8,
          backgroundColor: "#2AABD5",
          color: "#FFFFFF",
          height: 40,
          marginRight: 10,
          float: "right"
        }}
        onClick={() => handleOpen()}
        variant="contained"

      >
        Add Rule
      </Button>

      <div style={{ height: 50 }}></div>
      <></>

      <MaterialTable
        icons={tableIcons}
        isLoading={loading}
        columns={columns}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        data={commissions}
        options={{
          filtering: false,
          actionsColumnIndex: -1,
          search: false,
          title: false
        }}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                await deleteCommission(oldData.id);
                await fetchCommissions();
                const index = oldData.tableData.id;
                resolve();
              }, 1000);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                if (newData.fromTime !== oldData.fromTime) {
                  const formattedFromTime = moment(new Date(newData.fromTime)).add(new Date().getTimezoneOffset(), 'Minute');
                  let fromT = formattedFromTime.hour() + ":" + formattedFromTime.minute() + ":" + formattedFromTime.second();
                  newData.fromTime = fromT;
                } else if (newData.fromTime == oldData.fromTime) {
                  const formattedFromTime = moment(new Date("2223-01-01 " + newData.fromTime)).add(new Date().getTimezoneOffset(), 'Minute');
                  let fromT = formattedFromTime.hour() + ":" + formattedFromTime.minute() + ":" + formattedFromTime.second()
                  newData.fromTime = fromT;
                }
                if (newData.toTime !== oldData.toTime) {
                  const formattedToTime = moment(new Date(newData.toTime)).add(new Date().getTimezoneOffset(), 'Minute');
                  let toT = formattedToTime.hour() + ":" + formattedToTime.minute() + ":" + formattedToTime.second();
                  newData.toTime = toT;
                } else if (newData.toTime == oldData.toTime) {
                  const formattedToTime = moment(new Date("2223-01-01 " + newData.toTime)).add(new Date().getTimezoneOffset(), 'Minute');
                  let toT = formattedToTime.hour() + ":" + formattedToTime.minute() + ":" + formattedToTime.second()
                  newData.toTime = toT;
                }
                await updateCommission(oldData.id, newData);
                await fetchCommissions();
                const index = oldData.tableData.id;
                resolve();
              }, 1000);
            }),
        }}
        title="Commission Rules"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 5,
          p: 4,
        }}>
          <Grid container>

            <Toolbar className={classes.toolbar} style={{ marginLeft: "-102px" }}>
              <Typography
                component="h1"
                variant="h6"
                color="#4B4B4B"
                noWrap
                className={classes.title}
              >
                Commission ( Booking.com )
              </Typography>

            </Toolbar>
            <Grid
              container
              alignItems="center"
              direction="row"
              className={classes.mainCard}
            >
              <Card className={classes.root}>
                <CardContent>
                  <Grid container justifyContent="center" alignItems="center">

                  </Grid>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>

                    <Stack spacing={3}>
                      <Typography
                        component="p"
                        color="#4B4B4B"
                      >
                        Time Range *
                      </Typography>
                      <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
                        <div style={{ marginRight: 5 }}>
                          <TimePicker
                            value={fromTime}
                            ampm={true}
                            ampmInClock={true}
                            onChange={(value) => {
                              setFromTime(value);
                            }}
                            renderInput={(params) => <TextField {...params} />}

                          />
                        </div>
                        <div style={{ marginLeft: 5 }}>
                          <TimePicker
                            value={toTime}
                            ampm={true}
                            ampmInClock={true}
                            onChange={(value) => {
                              setToTime(value)
                            }}
                            renderInput={(params) => <TextField {...params} />}

                          />
                        </div>
                      </div>


                    </Stack>
                  </LocalizationProvider>
                  <Select
                    style={{ width: "100%", marginBottom: 10, marginTop: 22 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={"0"}
                    label={"serviceLevel"}
                    value={serviceLevel}
                    onChange={(e) => {
                      setServiceLevel(e.target.value)
                    }}
                  >
                    <MenuItem value="0">Select Service Level *</MenuItem>
                    <MenuItem value="STANDARD">STANDARD</MenuItem>
                    <MenuItem value="EXECUTIVE">EXECUTIVE</MenuItem>
                    <MenuItem value="ELECTRIC">ELECTRIC</MenuItem>
                    <MenuItem value="MINI_BUS">MINI BUS</MenuItem>
                    <MenuItem value="CARRIER">CARRIER</MenuItem>
                    <MenuItem value="LARGE_CARRIER">LARGE CARRIER</MenuItem>
                    <MenuItem value="EXE_CARRIER">EXE CARRIER</MenuItem>
                    <MenuItem value="LUXURY">LUXURY</MenuItem>

                    <MenuItem value="ELECTRIC_LUXURY">ELECTRIC LUXURY</MenuItem>
                    <MenuItem value="ELECTRIC_PEOPLE_CARRIER">ELECTRIC PEOPLE CARRIER</MenuItem>
                    <MenuItem value="ELECTRIC_EXE_PEOPLE_CARRIER">ELECTRIC EXE PEOPLE CARRIER</MenuItem>
                    <MenuItem value="ELECTRIC_LARGE_PEOPLE_CARRIER">ELECTRIC LARGE PEOPLE CARRIER</MenuItem>

                  </Select>

                  <TextField
                    onChange={(e) => setCommission(e.target.value)}
                    value={commission}
                    label={"Commission(%) *"}
                    style={{ width: "100%", marginBottom: 10 }}
                  />
                  <Checkbox {...label} checked={roundable} style={{ marginLeft: -11 }} onChange={() => setRoundable(!roundable)} defaultChecked /> Roundable

                </CardContent>

                <Grid container justifyContent="center" alignItems="center">
                  <Button onClick={handleSubmit} style={{ backgroundColor: "#2AABD5", color: "#FFFFFF", }}>Submit</Button>
                </Grid>

              </Card>
            </Grid>
            {connectSuccess && (
              <ErrorSnack success reset={() => reset()} errorMsg={message} />
            )}
            {connectError && (
              <ErrorSnack reset={() => reset()} errorMsg={error.message} />
            )}

          </Grid>
        </Box>
      </Modal>

    </>
  );
};

const mapState = (state) => ({
  companyId: state.userInfo.user.id,
  token: state.userInfo.token,
  error: state.bookingIntegration.error,
  connectSuccess: state.bookingIntegration.response.status,
  message: state.bookingIntegration.response.data,
  loader: state.bookingIntegration.loading,
  connectError: state.bookingIntegration.error.status,
});

const mapDispatch = {
  bookingIntegration: ThunksBooking.BookingIntegration,
  updateCommissionRoundable: ThunksBooking.updateCommissionRoundable,
  updateCommission: ThunkCommission.updateCommission,
  deleteCommission: ThunkCommission.deleteCommission,
  removeBookingIntegration: ThunksBooking.RemoveBookingIntegration,
  addCommission: ThunkCommission.addCommission,
  getCommissions: ThunkCommission.getCommissions,
  reset: Local.BookingIntegration.api.reset,
};

export default connect(mapState, mapDispatch)(IntegrationCommission);
