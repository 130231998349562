import React from "react";
import { Grid } from "@material-ui/core";
import AddSubAdmin from "./AddSubAdmin";
import SubAdminList from "./SubAdminList";
import { connect } from "react-redux";
import SubAdminThunks from "../../../redux/actions/thunks/SubAdmins";
import AddSubAdminForm from "./AddSubAdminForm";

function SubAdmins({ getAllAuthorizations, getAllSubAdmins }) {
  React.useEffect(() => {
    getAllAuthorizations();
    getAllSubAdmins();
  }, []);

  return (
    <>
      <Grid
        container
        direction="row-reverse"
        justifyContent="flex-start"
        alignItems="center"
      >
        <AddSubAdminForm />
        {/* <AddSubAdmin /> */}
      </Grid>
      <SubAdminList />
    </>
  );
}

const mapDispatch = {
  getAllAuthorizations: SubAdminThunks.getAllAuthorizations,
  getAllSubAdmins: SubAdminThunks.subAdmins,
};

export default connect(null, mapDispatch)(SubAdmins);
