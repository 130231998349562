import MOModalWrapper from "../../../../components/commons/MOModalWrapper";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import { toastMessage } from "../../../../helpers/toastMessage";
import { connect } from "react-redux";
import mobioApi from "../../../../services";
import * as Yup from "yup";

const AddNewPolygon = ({
  modalOpen,
  setModalOpen,
  data,
  token,
  setPolygonData,
  setData,
  allPolygons,
  getAllPolygonAPI,
}) => {
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Area name is required")
      .min(2, "Name must be at least 2 characters long"),
  });
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const payload = {
        name: values.name,
        airportId: values.airportId,
        coordinates: values.coordinates,
      };
      const api = mobioApi(token);
      let response = null;
      if (data?.action === "edit") {
        payload.id = data.id;
        response = await api.updatePolygon(payload);
        const polygonData = {
          id: data?.id,
          name: payload.name,
          ...data.newPolygon,
        };
        const updateExistingData = allPolygons?.map((pd) =>
          pd.id === data?.id ? polygonData : pd
        );

        setPolygonData(updateExistingData);
      } else {
        response = await api.addPolygon(payload);
        // const polygonData = {
        //   id: response?.data?.data?.id,
        //   name: payload.name,
        //   ...data.newPolygon,
        // };
        // setPolygonData((prevData) => [...prevData, polygonData]);
        getAllPolygonAPI();
      }

      toastMessage(
        "success",
        response?.data?.msg || "Polygon Added Successfully"
      );
    } catch (error) {
      toastMessage("error", error.response?.data?.msg);
    }
    setData("");
    setModalOpen(false);
  };
  return (
    <MOModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={data?.action === "edit" ? "Edit Polygon" : "Save Polygon"}
      className={"poly-modal header-modal-section"}
    >
      <div>
        <Formik
          initialValues={{
            name: data?.name || "",
            airportId: data?.airportId || "",
            coordinates: data?.newPolygon?.geometry?.coordinates || [[]],
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="flex align-item-center justify-content-between gap-12">
                {/* Start time */}
                <div className="input_field flex-1 ">
                  <h6 className="font-12 font-500 mb-8">Area Name</h6>
                  <Field
                    type="text"
                    name="name"
                    className=""
                    style={{ textIndent: 4 }}
                  />
                  <ErrorMessage
                    name="name"
                    component="p"
                    className="text-danger font-12"
                  />
                </div>
              </div>
              {/*  */}

              <div className="hourly-rate-edit-btn">
                <MOButtonV2
                  text={data?.action === "edit" ? "UPDATE" : "SAVE"}
                  type="submit"
                  textColor="#ffffff"
                  radius={4}
                  backgroundColor="#29AEE6"
                  height={44}
                  fontSize={11}
                  padding={"16px px"}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </MOModalWrapper>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(AddNewPolygon);
