import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../../components/commons/ButtonGrouping";
import { Box } from "@material-ui/core";
import Breadcrumb from "../../../components/BreadCrumb";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Delete,
  EditOutlined,
} from "@material-ui/icons";
import HeaderDescription from "../../../components/commons/HeaderDescription";
import MOSwitch from "../../../components/commons/MOSwitch";
import DropDown from "../../../components/commons/DropDown";
import DeleteOfferModal from "./Modals/DeleteOfferModal";
import SearchingInput from "../../../components/commons/SearchingInput";
import Standard from "../../../assets/icons/vehicle/Standard.svg";
import HorizontalIcon from "../../../assets/icons/horizontalIcon.png";
import EditIcon from "../../../assets/icons/editUpdateIcon.png";
import DeleteIcon from "../../../assets/icons/menuDelete.png";
import AddOfferModal from "./Modals/AddOfferModal";

const paths = [
  {
    name: "Offers",
    url: "/dashboard/integration/rates/mange-location",
  },
];
const secondaryPaths = [
  {
    name: "Netherlands",
    url: "/dashboard/integration/rates",
  },
  {
    name: "Amsterdam",
    url: "/dashboard/integration/rates/city",
  },
  {
    name: "Airports",
    url: "/dashboard/integration/rates/city-airport",
  },
  {
    name: "Manage AMS ",
    url: "/dashboard/integration/rates/mange-location",
  },
  {
    name: "Offers",
    // url: "/dashboard/integration/rates/city",
  },
];
const data = [
  {
    id: 1,
    vehicleType: "Standard",

    startDate: "18/01/2024",
    endDate: "20/01/2024",
    startTime: "08:00",
    endTime: "12:00",

    active: true,
    growthLevel: true,
  },
  {
    id: 2,
    vehicleType: "Standard",

    startDate: "18/01/2024",
    endDate: "20/01/2024",
    startTime: "08:00",
    endTime: "12:00",

    active: false,
    growthLevel: false,
  },
  {
    id: 3,
    vehicleType: "Standard",

    startDate: "18/01/2024",
    endDate: "20/01/2024",
    startTime: "08:00",
    endTime: "12:00",

    active: true,
    growthLevel: true,
  },
  {
    id: 4,
    vehicleType: "Standard",

    startDate: "18/01/2024",
    endDate: "20/01/2024",
    startTime: "08:00",
    endTime: "12:00",

    active: true,
    growthLevel: false,
  },
  {
    id: 5,
    vehicleType: "Standard",

    startDate: "18/01/2024",
    endDate: "20/01/2024",
    startTime: "08:00",
    endTime: "12:00",

    active: true,
    growthLevel: true,
  },
  {
    id: 6,
    vehicleType: "Standard",

    startDate: "18/01/2024",
    endDate: "20/01/2024",
    startTime: "08:00",
    endTime: "12:00",

    active: true,
    growthLevel: false,
  },
];

const Index = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  return (
    <>
      <div>
        <div className="mt-18 flex justify-content-between">
          <div>
            <Breadcrumb paths={paths} />
            <div className="breadcrumb-description">
              Offers for Services on special days
            </div>
          </div>
          <div className="flex">
            <div className="mr-12">
              <SearchingInput />
            </div>
            <MOButtonV2
              text="ADD NEW OFFER"
              textColor="#ffffff"
              radius={4}
              backgroundColor="#29AEE6"
              height={30}
              fontSize={11}
              padding={"6.5px 31px"}
              IconType={() => <Add style={{ marginRight: 10, fontSize: 16 }} />}
              onClick={() => {
                setAddModalOpen(true);
              }}
            />
          </div>
        </div>
        <div className="mt-12 ml-6">
          <Breadcrumb paths={secondaryPaths} secondary={true} />
        </div>
        <div>
          <div className="bg-white  mt-20 border-radius-8">
            <div className="">
              <table className="custom-table-v3">
                <thead>
                  <tr>
                    <th className="">Services Level</th>
                    <th className="">Start Date</th>
                    <th className="">Start Time</th>
                    <th className="">End Date</th>
                    <th className="">End Time</th>
                    <th className="">Active</th>
                    <th className="">Action</th>
                  </tr>
                </thead>
                <tbody className="dispatcher-list-tbody">
                  {data.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <div className="flex align-item-center">
                          <img
                            src={Standard}
                            alt="icon"
                            className="distance-rate-table-icon"
                          />
                          <p className="m-0"> {row.vehicleType}</p>
                        </div>
                      </td>

                      <td>{row.startDate}</td>
                      <td>{row.startTime}</td>
                      <td>{row.endDate}</td>
                      <td>{row.endTime}</td>

                      <td>
                        <MOSwitch isOnStatus={row.active} id={row.id} />
                      </td>
                      <td>
                        {" "}
                        <DropDown
                          dropDownOpen={anchorEl}
                          setDropDownOpen={setAnchorEl}
                          uniqueId={row.id}
                          IconRender={() => <img src={HorizontalIcon} />}
                          dropDownShape="horizontal"
                          menu={[
                            {
                              onClick: () => {
                                setAddModalOpen(true);
                              },
                              IconRender: () => <img src={EditIcon} />,
                              title: "Edit",
                            },
                            {
                              onClick: () => {
                                setDeleteModalOpen(true);
                              },
                              IconRender: () => <img src={DeleteIcon} />,
                              title: "Delete",
                            },
                          ]}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* Modals */}
        <DeleteOfferModal
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
        />
        <AddOfferModal
          modalOpen={addModalOpen}
          setModalOpen={setAddModalOpen}
        />
      </div>
    </>
  );
};

export default Index;
