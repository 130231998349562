import React, { useEffect, useRef, useState } from "react";
import MOModalWrapper from "../../../../components/commons/MOModalWrapper";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import VehicleList from "../../../../components/commons/VehicleList";
import TimePickerField from "../../../../components/commons/TimePickerField";
import mobioApi from "../../../../services";
import { toastMessage } from "../../../../helpers/toastMessage";
import {
  convertTimeStringToDate,
  getTimeFormat24Hour,
} from "../../../../helpers/getTimeFormat";
import { hourlyRateValidationSchema } from "../../../../validations/rateModuleValidation";

const HourlyEditModal = ({
  token,
  modalOpen,
  setModalOpen,
  serviceLevelsList,
  selectDefaultService,
  airportId,
  updateList,
  hourlyRateList = [],
  countryCurrency = "",
}) => {
  const [selectServiceLevel, setSelectServiceLevel] = useState(null);
  const formik = useRef();
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const payload = {
        serviceLevelId: selectServiceLevel,
        airportId: airportId,
        minBookingHour: getTimeFormat24Hour(values?.minBookingHour),
        // cancellationTime: getTimeFormat24Hour(values?.cancellationTime),
        rate: values.rate,
      };
      const api = mobioApi(token);
      const response = await api.addHourlyRate(payload);
      updateList(payload);
      toastMessage("success", response?.data?.msg);
    } catch (error) {
      toastMessage("error", error.response?.data?.msg);
    }

    // setModalOpen(false);
  };

  const getInitialData = () => {
    const getInitialValue = hourlyRateList?.filter(
      (df) => df.id === selectServiceLevel
    );
    if (getInitialValue.length > 0) {
      const getHourlyRateInitial = getInitialValue[0]?.hourlyRateLevel;
      if (getHourlyRateInitial.length > 0) {
        if (formik?.current) {
          formik.current.setFieldValue(
            "minBookingHour",
            convertTimeStringToDate(getHourlyRateInitial[0].minBookingHour)
          );
          // formik.current.setFieldValue(
          //   "cancellationTime",
          //   convertTimeStringToDate(getHourlyRateInitial[0].cancellationTime)
          // );
          formik.current.setFieldValue("rate", getHourlyRateInitial[0].rate);
        }
      } else {
        if (formik?.current) {
          formik.current.setFieldValue(
            "minBookingHour",
            convertTimeStringToDate("00:00:00")
          );
          // formik.current.setFieldValue(
          //   "cancellationTime",
          //   convertTimeStringToDate("00:00:00")
          // );
          formik.current.setFieldValue("rate", "");
        }
      }
    } else {
      if (formik?.current) {
        formik.current.setFieldValue(
          "minBookingHour",
          convertTimeStringToDate("00:00:00")
        );
        // formik.current.setFieldValue(
        //   "cancellationTime",
        //   convertTimeStringToDate("00:00:00")
        // );
        formik.current.setFieldValue("rate", "");
      }
    }
  };

  useEffect(() => {
    getInitialData();
  }, [selectServiceLevel]);
  return (
    <MOModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={
        <div>
          <VehicleList
            defaultValue={selectDefaultService}
            serviceLevelsList={serviceLevelsList}
            setSelectServiceLevel={setSelectServiceLevel}
          />
        </div>
      }
      className={"hourly-rate-edit-modal"}
    >
      <div>
        <Formik
          innerRef={formik}
          initialValues={{
            minBookingHour: convertTimeStringToDate("00:00:00"),
            // cancellationTime: convertTimeStringToDate("00:00:00"),
            rate: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={hourlyRateValidationSchema}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              {/*  */}
              <div className="flex gap-12">
                <div className="flex-1">
                  <p className="font-12 font-600 mb-6">Min Booking Hour</p>
                  <Field
                    name="minBookingHour"
                    className="hourly-time-picker"
                    component={TimePickerField}
                    value={values.minBookingHour}
                  />
                  <ErrorMessage
                    name="minBookingHour"
                    component="p"
                    className="text-danger font-12"
                  />
                </div>
                {/* <div className="flex-1">
                  <p className="font-12 font-600 mb-6">Cancellation Time</p>
                  <Field
                    name="cancellationTime"
                    className="hourly-time-picker"
                    component={TimePickerField}
                    value={values.cancellationTime}
                  />
                </div> */}
              </div>

              <div className="mt-20">
                <p className="font-600 font-12 mb-6"> Rate</p>
                <div className="hourly-rate-field-rate-section">
                  <div className="flex align-item-center">
                    <input
                      className="remove-number-arrow"
                      type="number"
                      step={"0.1"}
                      name="rate"
                      placeholder="0"
                      value={values.rate}
                      onChange={(e) => {
                        setFieldValue("rate", e.target.value);
                      }}
                    />
                    <p>{countryCurrency}</p>
                  </div>
                  <div className="flex align-item-center">
                    <button
                      type="button"
                      onClick={() => {
                        const getValue = parseFloat(values.rate) + 1;
                        if (getValue) {
                          setFieldValue("rate", getValue);
                        } else if (!getValue) {
                          setFieldValue("rate", 1);
                        }
                      }}
                    >
                      +
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        const getValue = parseFloat(values.rate) - 1;
                        if (getValue >= 0) {
                          setFieldValue("rate", getValue);
                        }
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>
                <ErrorMessage
                  name="rate"
                  component="p"
                  className="text-danger font-12"
                />
              </div>
              <div className="hourly-rate-edit-btn">
                <MOButtonV2
                  type="submit"
                  text="SAVE"
                  textColor="#ffffff"
                  radius={4}
                  backgroundColor="#29AEE6"
                  height={44}
                  //   width={"100%"}
                  fontSize={11}
                  padding={"16px px"}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </MOModalWrapper>
  );
};

export default HourlyEditModal;
