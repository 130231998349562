import Local from "../../local";

export type ThunksRejectOnboarding = {
  RejectOnboarding: (id: any, reason: string) => any;
};

export type ThunksApproveOnboarding = {
  ApproveOnboarding: (id: any) => any;
};

const ThunksApprove: ThunksApproveOnboarding = {
  ApproveOnboarding: (rowData) => async (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();

    if (rowData.user.displayName == null || rowData.user.displayName == '' ){
      dispatch(
        Local.ApproveOnboarding.api.error("Request Failed", "Please enter display name.")
      );
    } else if (rowData.user.displayName.split(" ").length > 1) {
      dispatch(
        Local.ApproveOnboarding.api.error("Request Failed", "Please enter a valid display name.")
      );
    } else {
      const api = mobioApi(token);
      dispatch(Local.ApproveOnboarding.api.request());

      let user = { displayName: rowData.user.displayName }

      return api
        .approve({id: rowData.id, user})
        .then((response: any) => {
          dispatch(Local.ApproveOnboarding.api.response(response.data));
          dispatch(Local.OnboardingListing.api.request());
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              Local.ApproveOnboarding.api.error(
                "Request Failed",
                "The API request timed out. Please Refresh the page"
              )
            );
          } else {
            const {
              response: { data },
            } = error;
            dispatch(
              Local.ApproveOnboarding.api.error("Request Failed", data.msg)
            );
          }
        });
    }
  },
};

const ThunksReject: ThunksRejectOnboarding = {
  RejectOnboarding:
    (id, reason) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();

      const api = mobioApi(token);

      dispatch(Local.RejectOnboarding.api.request());

      return api
        .reject(id, { reason: reason })
        .then((response: any) => {
          dispatch(Local.RejectOnboarding.api.response(response.data));
          dispatch(Local.OnboardingListing.api.request());
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              Local.RejectOnboarding.api.error(
                "Request Failed",
                "The API request timed out. Please Refresh the page"
              )
            );
          } else {
            const {
              response: { data },
            } = error;
            dispatch(
              Local.RejectOnboarding.api.error("Request Failed", data.msg)
            );
          }
        });
    },
};

export { ThunksReject, ThunksApprove };
