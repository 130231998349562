import Car_Executive from "../../../assets/icons/Car_Executive.png";
import noun_SUV_446092 from "../../../assets/icons/noun_SUV_446092.png";
import noun_Transit_Minibus_65031 from "../../../assets/icons/noun_Transit_Minibus_65031.png";
import noun_family_hatchback_2011472 from "../../../assets/icons/noun_family_hatchback_2011472.png";
import Group_149 from "../../../assets/icons/Group_149.png";
import Group_220 from "../../../assets/icons/Group_220.png";
import Group_179 from "../../../assets/icons/Group_179.png";
import Car_Electric from "../../../assets/icons/Car_Electric.png";

export const cartypes = [
  {
    id: "1",
    value: "STANDARD",
    title: "Standard",
    image: noun_SUV_446092,
    passangers: "4",
  },
  {
    id: "2",
    value: "ELECTRIC",
    title: "Electric",
    image: Car_Electric,
    passangers: "4",
  },
  {
    id: "3",
    image: Car_Executive,
    value: "EXECUTIVE",
    title: "Executive",
    passangers: "4",
  },
  {
    id: "4",
    image: noun_family_hatchback_2011472,
    value: "MINI_BUS",
    title: "MiniBus",
    passangers: "8",
  },
  {
    id: "5",
    value: "CARRIER",
    title: "Carrier",
    passangers: "5",
    image: noun_Transit_Minibus_65031,
  },
  {
    id: "6",
    value: "LARGE_CARRIER",
    title: "Lrg Carrier",
    passangers: "8",
    image: Group_149,
  },

  {
    id: "7",
    value: "EXE_CARRIER",
    title: "Exe Carrier",
    passangers: "7",
    image: Group_220,
  },
  {
    id: "8",
    value: "LUXURY",
    title: "Luxury",
    passangers: "4",
    image: Group_179,
  },
  {
    id: "9",
    value: "ELECTRIC_LUXURY",
    title: "Electric Luxury",
    passangers: "4",
    image: Group_179,
  },
  {
    id: "10",
    value: "ELECTRIC_PEOPLE_CARRIER",
    title: "Electric People Carrier",
    passangers: "5",
    image: Group_179,
  },
  {
    id: "11",
    value: "ELECTRIC_EXE_PEOPLE_CARRIER",
    title: "Electric People Carrier",
    passangers: "7",
    image: Group_179,
  },
  {
    id: "12",
    value: "ELECTRIC_LARGE_PEOPLE_CARRIER",
    title: "Electric Lrg People Carrier",
    passangers: "8",
    image: Group_179,
  },
];
