import { Error, ErrorOutline, Help, Warning } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import ToolTipMessage from "./ToolTipMessage";
import ExclamationIcon from "../../assets/icons/manage_heroicons_exclaimation-circle.svg";
import { useEffect } from "react";

const IntegrationServicesCard = ({
  data,
  airportCardStatus = {
    areaOfOperationStatus: false,
    rateStatus: false,
    pickUpInstructionStatus: false,
    pickUpTimingStatus: false,
    contactInformationStatus: false,
    dispatcherStatus: false,
    autoDriverEvent: false,
  },
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (data?.url) {
      if (data?.stateData) {
        navigate(data?.url, {
          state: { ...data?.stateData },
        });
      } else {
        navigate(data?.url);
      }
    }
  };

  return (
    <div
      className={`IntegrationServicesCard ${
        Object.keys(airportCardStatus).filter(
          (keyName) => keyName === data.statusKey
        ).length > 0
          ? !airportCardStatus[data.statusKey]
            ? data?.tooltipStatus === "warning"
              ? "IntegrationServicesCard-not-complete-warning"
              : "IntegrationServicesCard-not-complete"
            : "IntegrationServicesCard-filled"
          : "IntegrationServicesCard-filled"
      }`}
      onClick={handleClick}
    >
      <div className="card-content">
        <div className="card-content-icon">{data?.Icon && data.Icon}</div>
        <div className="text-content">
          <h2 className="heading">{data?.heading}</h2>
          <p className="description">{data?.description}</p>
        </div>
      </div>
      {/* help */}
      {Object.keys(airportCardStatus).filter(
        (keyName) => keyName === data.statusKey
      ).length > 0 ? (
        !airportCardStatus[data.statusKey] ? (
          <>
            <ToolTipMessage
              type="primary"
              content={
                <>
                  <p className="font-12 font-500">{data?.tooltipMessage}</p>
                </>
              }
            >
              <div className="integration-services-card-help">
                <div>
                  {/* <Error
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "#db4446",
                      cursor: "pointer",
                    }}
                  /> */}
                  {data.tooltipStatus === "warning" ? (
                    <Warning
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#ffc107",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <img
                      src={ExclamationIcon}
                      alt="ExclamationIcon"
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </div>
            </ToolTipMessage>
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default IntegrationServicesCard;
