import React, { useEffect, useState } from "react";
import FixedRouteRateModal from "./Modals/FixedRouteRateModal";
import EditIcon from "../../../assets/icons/lucide_edit.svg";
import { useParams } from "react-router";
import mobioApi from "../../../services";
import { toastMessage } from "../../../helpers/toastMessage";
import EyeIcon from "../../../assets/icons/table_action _icon.svg";
import PopoverItem from "../../../components/commons/PopoverItem";

const FixedRouteRate = ({ token, serviceLevelsList }) => {
  const { airportId, airportIATA } = useParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [fixedRateList, setFixedRateList] = useState([]);
  const [singleData, setSingleData] = useState(null);

  const getFixedRates = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getFixedRate(airportId);
      setFixedRateList(response?.data?.data);
    } catch (error) {
      setFixedRateList([]);
    }
  };

  const addFixedRates = async (payload) => {
    try {
      const api = mobioApi(token);
      const response = await api.addFixedRate(payload);
      getFixedRates();
      setSingleData(null);
      setModalOpen(false);
      toastMessage("success", response?.data?.data?.msg);
    } catch (error) {
      toastMessage("error", error?.response?.data?.data?.msg);
    }
  };

  useEffect(() => {
    getFixedRates();
  }, []);

  useEffect(() => {
    if (!modalOpen) {
      setSingleData(null);
    }
  }, [modalOpen]);

  const getTotalActiveService = (list = []) => {
    return list.filter((lf) => lf?.fromAirport && lf?.status);
  };
  return (
    // <div className="bg-white main-section-spacing border-radius-8">
    <div className="bg-white  mt-20 border-radius-8">
      {/* <table className="custom-table-rm"> */}
      <table className="custom-table-v3">
        <thead>
          <tr>
            <th className="location">Route Name</th>
            <th className="service-level">Active Service Levels</th>
            <th className="action ">Action</th>
          </tr>
        </thead>
        <tbody>
          {fixedRateList?.length > 0 ? (
            fixedRateList.map((row, index) => (
              <tr key={index}>
                <td>{row.name}</td>
                <td className="rm-service-leave-section">
                  <div className="flex align-item-center">
                    <p>
                      {getTotalActiveService(row?.associatedFixedRate)?.length}
                    </p>
                    <div className="flex align-item-center gap-8">
                      {getTotalActiveService(row?.associatedFixedRate)?.length >
                        0 && (
                        <>
                          <PopoverItem
                            names={getTotalActiveService(
                              row?.associatedFixedRate
                            )?.map((sl) => sl?.associatedServiceLevel?.name)}
                            showItems={5}
                          />
                        </>
                      )}

                      {/* {getTotalActiveService(row?.associatedFixedRate)?.map(
                        (sl) => {
                          return (
                            <div className="rm-service-leave-badge">
                              {sl?.associatedServiceLevel?.name}
                            </div>
                          );
                        }
                      )} */}
                    </div>
                  </div>
                </td>
                <td className="rm-fr-edit">
                  <img
                    src={EyeIcon}
                    alt="Edit"
                    style={{ width: "21px", height: "21px", cursor: "pointer" }}
                  />
                  <img
                    src={EditIcon}
                    className="ml-8"
                    onClick={() => {
                      setSingleData({
                        iata: airportIATA,
                        data: row,
                      });
                      setModalOpen(true);
                    }}
                    alt="Edit"
                    style={{ width: "21px", height: "21px", cursor: "pointer" }}
                  />
                </td>
              </tr>
            ))
          ) : (
            <>
              <tr>
                <td colSpan="3">
                  <div className="table-no-record-section">
                    No records are found.
                  </div>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      <FixedRouteRateModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        serviceLevelsList={serviceLevelsList}
        singleData={singleData}
        addFixedRateApi={addFixedRates}
      />
    </div>
  );
};

export default FixedRouteRate;
