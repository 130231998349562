export default function DetailViewDots({ data, type}) {
  return (
    <div style={{ display:"flex",flexDirection:"row", alignItems:"center"}}>
      <>
      { type == "assigned" ?
      <>
      <div
       style={
         data?.rideStart === true
           ? {  height: "24px",
                width: "24px",
                borderRadius: "12px",
                borderWidth: "1px",
                backgroundColor: "#2aabd5",
                borderColor: "#6A6A6A",
                borderStyle:"solid", marginRight:"6px" }
           : {  height: "24px",
                width: "24px",
                borderRadius: "12px",
                borderWidth: "1px",
                borderColor: "#6A6A6A",
                borderStyle: "solid",
                marginRight:"6px"}
       }
     ></div>

      <div
        style={
          data?.rideArrived === true
            ?{  
              backgroundColor: "#2aabd5",
              marginRight:"6px",
              height: "24px",
              width: "24px",
              borderRadius: "12px",
              borderWidth: "1px",
              borderColor: "#6A6A6A",
              borderStyle:"solid"
            }
            :  { 
              height: "24px",
              width: "24px",
              borderRadius: "12px",
              borderWidth: "1px",
              borderColor: "#6A6A6A",
              marginRight:"6px",
              borderStyle:"solid" }
        }
      ></div>

      <div
        style={
          data?.rideInTransit === true
            ? {  
              backgroundColor: "#2aabd5",
              marginRight:"6px",
              height: "24px",
              width: "24px",
              borderRadius: "12px",
              borderWidth: "1px",
              borderColor: "#6A6A6A",
              borderStyle: "solid",
              borderStyle:"solid"
            }
            :  { 
              height: "24px",
              width: "24px",
              borderRadius: "12px",
              borderWidth: "1px",
              borderColor: "#6A6A6A",
              marginRight:"6px",
              borderStyle:"solid"}
        }
      ></div>
     </>
      :
      data?.cancelled && data?.rideEnd ? 
      <>
        <div style={ {  
                backgroundColor: "#F46262",
                marginRight:"6px",
                height: "24px",
                width: "24px",
                borderRadius: "12px",
                borderWidth: "1px",
                borderColor: "#6A6A6A",
                borderStyle:"solid"
              } } ></div>
       <div style={ {  
                backgroundColor: "#F46262",
                marginRight:"6px",
                height: "24px",
                width: "24px",
                borderRadius: "12px",
                borderWidth: "1px",
                borderColor: "#6A6A6A",
                borderStyle:"solid"
              } } ></div>
       <div style={ {  
                backgroundColor: "#F46262",
                height: "24px",
                width: "24px",
                borderRadius: "12px",
                borderWidth: "1px",
                borderColor: "#6A6A6A",
                marginRight:"6px",
                borderStyle:"solid"
              } } ></div>
      </>
      :
      <>
       <div
        style={
          data?.rideStart === true
            ? {  
                backgroundColor: "#2aabd5",
                marginRight:"6px",
                height: "24px",
                width: "24px",
                borderRadius: "12px",
                borderWidth: "1px",
                borderColor: "#6A6A6A",
                borderStyle:"solid"
              }
            :{  height: "24px",
                width: "24px",
                borderRadius: "12px",
                borderWidth: "1px",
                borderColor: "#6A6A6A",
                borderStyle:"solid",
                marginRight:"6px",}
        }
      ></div>

        <div
          style={
            data?.rideInTransit === true
              ? {  
                backgroundColor: "#2aabd5",
                height: "24px",
                width: "24px",
                borderRadius: "12px",
                borderWidth: "1px",
                borderColor: "#6A6A6A",
                marginRight: "6px",
                borderStyle:"solid"
              }
              : { 
                height: "24px",
                width: "24px",
                borderRadius: "12px",
                borderWidth: "1px",
                borderColor: "#6A6A6A",
                borderStyle:"solid",
                marginRight:"6px"
             }
          }
        ></div>

        <div
          style={
            data?.rideEnd === true
            ?  {  height: "24px",
                  width: "24px",
                  borderRadius: "12px",
                  borderWidth: "1px",
                  backgroundColor: "#2aabd5",
                  borderStyle:"solid",
                  marginRight:"6px",}
              : {  height: "24px",
                  width: "24px",
                  borderRadius: "12px",
                  borderWidth: "1px",
                  borderColor: "#6A6A6A",
                  borderStyle:"solid",
                  marginRight:"6px",
                }
                 }
        ></div>
      </>
      }
      </> 
    </div>
  );
}