import IntegrationServicesCard from "../../../components/commons/IntegrationServicesCard";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/BreadCrumb";

import PickUpInstructionIcon from "../../../components/iconLibrary/PickUpInstructionIcon";
import AvailabilityIcon from "../../../components/iconLibrary/AvailabilityIcon";
import DispatcherIcon from "../../../components/iconLibrary/DispatcherIcon";
import ImmediateDispatcherIcon from "../../../components/iconLibrary/ImmediateDispatcherIcon";

import AnalysisIcon from "../../../components/iconLibrary/AnalysisIcon";
import CampaignsIcon from "../../../components/iconLibrary/CampaignsIcon";
import OffersIcons from "../../../components/iconLibrary/OffersIcons";
import SearchingInput from "../../../components/commons/SearchingInput";
import RideLinkIcon from "../../../assets/icons/ride_link_icon.svg";
import RateIcon from "../../../assets/icons/manage_rate_icon.svg";
import LocationIcon from "../../../assets/icons/manage_location_icon.svg";
import PickUpTimeIcon from "../../../assets/icons/manage_picktime_icon.svg";
import CSCommIcon from "../../../assets/icons/manage_communication_icon.svg";
import ServiceLevelIcon from "../../../assets/icons/manage_hugeicons_car.svg";
import ContactInfoIcon from "../../../assets/icons/manage_contact_icon.svg";
import AutoDriverIcon from "../../../assets/icons/manage_auto-driver_icon.svg";

import mobioApi from "../../../services";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { daysRemaining } from "../../../helpers/daysRemaingAlert";

function RatesApiLocationManage({ token }) {
  const navigate = useNavigate();
  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();

  const [storeStatusData, setStoreStatusData] = useState("");

  const [airportCardStatus, setAirportCardStatus] = useState({
    areaOfOperationStatus: true,
    rateStatus: true,
    pickUpInstructionStatus: true,
    pickUpTimingStatus: true,
    contactInformationStatus: true,
    dispatcherStatus: true,
    autoDriverEvent: true,
    airportServiceLevels: true,
    commissionRule: true,
  });

  const paths = [
    {
      name: `Manage ${airportIATA} - ${city}`,
      url: "/dashboard/integration/rates/city-airport",
    },
  ];

  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },

    {
      name: `Manage ${airportIATA}`,
      // url: "/dashboard/integration/rates/city-airport/detail",
    },
  ];
  const [cardsData, setCardData] = useState([
    {
      heading: "Service Level",
      description: "Defining the Driver events-on the basis of Driver details.",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/service-level-list`,
      Icon: <img src={ServiceLevelIcon} alt="ServiceLevelIcon" />,
      statusKey: "airportServiceLevels",
      tooltipMessage:
        "Please provide all service details, along with passenger and suitcase information",
    },
    {
      heading: "Area of Operation",
      description: "Use this area to manage how far your fleet travels",
      Icon: <img src={LocationIcon} alt="LocationIcon" />,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/area-operation`,
      stateData: { airportId: airportId },
      statusKey: "areaOfOperationStatus",
      tooltipMessage:
        "Please complete the Area of Operations section to enable bid functionality for bookings.",
    },
    {
      heading: "Rate",
      description: "The rate for the specified service.",
      Icon: <img src={RateIcon} alt="RateIcon" />,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/rate-manage`,
      stateData: { rateStatus: storeStatusData },
      statusKey: "rateStatus",
      tooltipMessage:
        "Please complete this to enable the bid functionality for bookings. Double-check and update the values for Fixed Route, Distance Route, Airport Pick-up Fees, and Lead Time.",
    },

    {
      heading: "Pick-up Instruction",
      description: "pickup instructions for a smooth and efficient service.",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/pickup-instruction`,
      Icon: <PickUpInstructionIcon width="25px" height="25px" />,
      statusKey: "pickUpInstructionStatus",
      tooltipMessage:
        "Please add at least one pickup instruction to display to the driver",
    },
    {
      heading: "Pick-up Timings",
      description: "select your preferred pick-up time",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/pickup-time`,
      Icon: <img src={PickUpTimeIcon} alt="PickUpTimeIcon" />,
      statusKey: "pickUpTimingStatus",
      tooltipMessage: "Please complete the pickup timing .",
      tooltipStatus: "alert",
    },
    {
      heading: "Contact Information",
      description:
        "Kindly share your contact information for effective communication",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/contact-information`,
      Icon: <img src={ContactInfoIcon} alt="ContactInfoIcon" />,
      statusKey: "contactInformationStatus",
      tooltipMessage: "Please provide the contact information",
      tooltipStatus: "alert",
    },
    {
      heading: "Auto Driver Events",
      description:
        "Defining commission rates and rules for transparent business transactions",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/auto-driver-event`,
      Icon: <img src={AutoDriverIcon} alt="AutoDriverIcon" />,
      statusKey: "autoDriverEvent",
      tooltipMessage: "Please complete the auto driver event detail",
    },
    {
      heading: "Commission Rule",
      description: "Defining the Driver events-on the basis of Driver details.",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/commission-list`,
      Icon: <OffersIcons />,
      statusKey: "commissionRule",
      tooltipMessage: "Please provide this information",
    },
    {
      heading: "Dispatcher",
      description:
        "The dispatcher coordinates and optimizes for efficient operations.",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/dispatchers`,
      Icon: <DispatcherIcon />,
      statusKey: "dispatcherStatus",
      tooltipMessage: "Please provide the dispatcher",
    },
    {
      heading: "Stop Sale",
      description: "View and manage your stop sales",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/availability`,
      Icon: <AvailabilityIcon />,
    },
    {
      heading: "Campaigns ",
      description:
        "Executing targeted campaigns for impactful outreach and engagement.",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/campaign-list`,
      Icon: <CampaignsIcon />,
    },

    {
      heading: "Automated Dispatch",
      description:
        "Utilize our immediate dispatcher for prompt and efficient  coordination",
      Icon: <ImmediateDispatcherIcon />,
    },
    {
      heading: "CS Communication",
      description: "Helping to send Auto- Generated Message for ease",
      Icon: <img src={CSCommIcon} alt="CSCommIcon" />,
    },
    {
      heading: "Analysis & Rate Automation ",
      description:
        "Implementing analysis and rate automation for streamlined efficiency",
      url: "/dashboard/integration/rates/analysis-list",
      Icon: <AnalysisIcon />,
    },

    {
      heading: "RideLink",
      description:
        "Oversee and manage the distribution of rides through RideLink, ensuring they are distributed to drivers within specific networks.",
      // url: "/dashboard/integration/rates/offer-list",
      Icon: <img src={RideLinkIcon} />,
    },
  ]);
  const getStatus = async (id) => {
    try {
      let reUpdateCard = cardsData;
      const api = mobioApi(token);
      const response = await api.getAirportSectionStatus(id);
      const statusData = response?.data?.data;
      setStoreStatusData(statusData);
      if (statusData) {
        const updateCardData = cardsData?.map((card) => {
          if (card.heading === "Rate") {
            card.stateData.rateStatus = statusData;
          }
          return card;
        });
        setCardData(updateCardData);
        let rateStatus = false;
        let contactStatus = false;
        let pickUpTimingStatus = false;

        if (
          statusData?.fixedRates.length > 0 &&
          statusData?.distanceRates?.length > 0 &&
          statusData.airportPickupFees?.length > 0 &&
          statusData?.leadTimes?.length > 0
        ) {
          rateStatus = true;
        }

        if (statusData?.address && statusData?.addressLineTwo) {
          contactStatus = true;
        }

        // for Status
        if (statusData?.pickupTimings?.length > 0) {
          pickUpTimingStatus = true;
          if (
            statusData?.pickupTimings[0]?.startDate &&
            statusData?.pickupTimings[0]?.endDate
          ) {
            const remainingDays = daysRemaining(
              statusData?.pickupTimings[0]?.startDate,
              statusData?.pickupTimings[0]?.endDate
            );
            if (remainingDays >= 0 && remainingDays <= 7) {
              pickUpTimingStatus = false;
              reUpdateCard = cardsData?.map((cd) => {
                if (cd.statusKey === "pickUpTimingStatus") {
                  cd.tooltipStatus = "warning";
                  cd.tooltipMessage = `You have only ${remainingDays} day's is remaining please update the start date end end date for shop availability.`;
                }
                return cd;
              });
            } else if (remainingDays < 0) {
              pickUpTimingStatus = false;
              reUpdateCard = cardsData?.map((cd) => {
                if (cd.statusKey === "pickUpTimingStatus") {
                  cd.tooltipStatus = "alert";
                  cd.tooltipMessage = `Your shop is not  availability please update the start and end dates.`;
                }
                return cd;
              });
            }
          }
        }
        setAirportCardStatus({
          areaOfOperationStatus: statusData?.name ? true : false,
          rateStatus: rateStatus,
          pickUpInstructionStatus:
            statusData?.pickupInstructions.length > 0 ? true : false,
          pickUpTimingStatus: pickUpTimingStatus,
          // pickUpTimingStatus:
          //   statusData?.pickupTimings?.length > 0 ? true : false,
          contactInformationStatus: contactStatus,
          dispatcherStatus:
            statusData?.dispatchers?.length > 0 ? true : false,
          autoDriverEvent:
            statusData?.autoDriverEvents?.length > 0 ? true : false,
          airportServiceLevels:
            statusData?.airportServiceLevels?.length > 0 ? true : false,
          commissionRule: false,
        });
        setCardData(reUpdateCard);
      }
    } catch (error) {
      console.log("Error", error);
      setStoreStatusData("");
    }
  };

  useEffect(() => {
    if (airportId) {
      getStatus(airportId);
    }
  }, [airportId]);
  return (
    <div>
      <div className="mt-18 flex justify-content-between">
        <div>
          <Breadcrumb paths={paths} backType="auto" />
          <div className="breadcrumb-description">
            Manage all detail of this location from here.
          </div>
          <div className="mt-8 ml-6">
            <Breadcrumb paths={secondaryPaths} secondary={true} />
          </div>
        </div>
        <div>
          <SearchingInput />
        </div>
      </div>

      <div className="mt-16 integrationServicesCard-main-section">
        {cardsData.map((data, index) => (
          <IntegrationServicesCard
            key={index}
            data={data}
            airportCardStatus={airportCardStatus}
          />
        ))}
      </div>
    </div>
  );
}

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(RatesApiLocationManage);
