import Local from "../../local";
import { Profile, ProfilePassword, ForgotPassword } from "../../local/user";

export type ThunksLoginRepo = {
  postLogin: () => any;
  postCustomerLogin: (email: string, password: string) => any;
  updateCustomerUser: (
    firstName: string,
    lastName: string,
    companyName: string,
    companyAddress: string,
    companyPostCode: number,
    companyCity: string,
    companyRegistrationNumber: string,
    companyVATNumber: string,
    bankAccountHolderName: string,
    bankAccountNumber: string,
    vatPercentage: number
  ) => any;
  changeCustomerPassword: (obj: any) => any;
  forgotPassword: (obj: any) => any;
  logout: () => any;
};

const ThunksLogin: ThunksLoginRepo = {
  postLogin: () => (dispatch: any, getState: any, mobioApi: any) => {
    /* Set pending */
    dispatch(Local.Login.api.request());
    const {
      login: {
        state: { displayName, password },
      },
    } = getState();
    const api = mobioApi();
    dispatch(Local.Login.api.request());

    return api
      .login(displayName, password)
      .then((response: any) => {
        /* Set user data */
        dispatch(Local.User.updateUser(response.data));
        /* reset api pending state */

        dispatch(Local.Login.api.response(response.data));
        dispatch(Local.Login.resetAll());
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.Login.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(Local.Login.api.error("Request Failed", data.msg));
          setTimeout(() => {
            dispatch(Local.Login.api.reset());
          }, 6000);
        }
      });
  },
  postCustomerLogin:
    (email, password) => (dispatch: any, getState: any, mobioApi: any) => {
      /* Set pending */
      dispatch(Local.Login.api.request());
      const api = mobioApi();
      return api
        .customerLogin({ email, password })
        .then((response: any) => {
          /* Set user data */
          dispatch(Local.User.updateUser(response.data));
          /* reset api pending state */
          dispatch(Local.Login.api.response(response.data));
          dispatch(Local.Login.resetAll());
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              Local.Login.api.error(
                "Request Failed",
                "The API request timed out. Please Refresh the page"
              )
            );
          } else {
            const {
              response: { data },
            } = error;
            dispatch(Local.Login.api.error("Request Failed", data.msg));
            setTimeout(() => {
              dispatch(Local.Login.api.reset());
            }, 6000);
          }
        });
    },
  updateCustomerUser:
    (
      firstName,
      lastName,
      companyName,
      companyAddress,
      companyPostCode,
      companyCity,
      companyRegistrationNumber,
      companyVATNumber,
      bankAccountHolderName,
      bankAccountNumber,
      vatPercentage
    ) =>
    (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token,user },
      } = getState();
      const api = mobioApi(token);
      dispatch(Profile.api.request());
      return api
        .updateCustomer({
          firstName,
          lastName,
          companyName,
          companyAddress,
          companyPostCode,
          companyCity,
          companyRegistrationNumber,
          companyVATNumber,
          bankAccountHolderName,
          bankAccountNumber,
          vatPercentage,
        })
        .then((res: any) => {

          dispatch(Profile.api.response({}));
          dispatch(Local.User.updateUser({token:token, user:{...user,...res.data.client}}));

        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            const {
              response: { data },
            } = error;
            dispatch(Profile.api.error("Request Failed", data.msg));
          }
        });
    },
  changeCustomerPassword:
    (obj) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token, user },
      } = getState();
      const api = mobioApi(token);
      dispatch(ProfilePassword.api.request());

      return api
        .changeCustomerPassword(obj)
        .then((res: any) => {
          const obj = {
            ...res.data,
            user: { ...res.data.user, role: user.role },
          };
          dispatch(Local.User.updateUser(obj));
          dispatch(ProfilePassword.api.response({}));

          // alert("Password channged successfully");
          console.log("passsss", res);
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            const {
              response: { data },
            } = error;
            console.log("error", data);
            dispatch(ProfilePassword.api.error("Request Failed", data.msg));
          }
        });
    },
  forgotPassword: (email) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    dispatch(ForgotPassword.api.request());
    console.log("this email", email);
    return api
      .forgotPassword({ email })
      .then((res: any) => {
        // dispatch(Local.User.updateUser(res.data));
        dispatch(ForgotPassword.api.request());
        dispatch(ForgotPassword.api.response({}));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          const {
            response: { data },
          } = error;
          dispatch(ForgotPassword.api.error("Request Failed", data.msg));
        }
      });
  },
  logout: () => (dispatch: any) => {
    dispatch(Local.User.resetAll());
    dispatch(Local.SignUp.api.response(""));
    dispatch(Profile.api.reset());
  },
};

export default ThunksLogin;
