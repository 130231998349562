import * as Yup from "yup";

export const orgUserValidationSchema = Yup.object().shape({
  displayName: Yup.string()
    .required("Display Name is required.")
    .matches(/^\S*$/, "Spaces are not allowed in the display name.")
    .matches(
      /^[^\s!@#$%^&*()]+$/,
      "Special characters are not allowed in the display name."
    ),
  fullName: Yup.string()
    .min(3, "Must be at least 3 characters")
    .matches(/^(?=.*[a-zA-Z])[a-zA-Z\s]*$/, "Invalid Full Name")
    .required("Full Name is required."),
  selectedAuthorities: Yup.array()
    .min(1, "Please select at least one authorities role")
    .required("Please select at least one authorities role"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address"),
});
