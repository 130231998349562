import { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toastMessage } from "../../../../helpers/toastMessage";
import MOModalWrapper from "../../../../components/commons/MOModalWrapper";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import Select from "react-select";
import mobioApi from "../../../../services";
import * as Yup from "yup"; // Import Yup

const AddDispatcherModal = ({
  token,
  modalOpen,
  setModalOpen,
  status = "add",
  dispatcherId,
}) => {
  const [dispatchersList, setDispatchersList] = useState([]);
  const [airportsList, setAirportsList] = useState([]);
  const [integrationDispatcherDetails, setIntegrationDispatcherDetails] =
    useState({});

  const { integrationId, cityId } = useParams();

  const customFormatOptionLabel = (name, { context }) => (
    <>
      {context === "value" && (
        <span className="font-12 font-400">{name.label}</span>
      )}

      {context === "menu" && (
        <>
          <p className="font-14 mb-0">{name.label}</p>
        </>
      )}
    </>
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "1px solid #ced4da" : "1px solid #ced4da",
      boxShadow: state.isFocused ? "0 0 0 1px #ced4da" : provided.boxShadow,
      "&:hover": {
        border: "1px solid #ced4da",
      },
    }),
    menu: (provided) => ({
      ...provided,
      height: "200px",
      overflowY: "auto",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "1px 6px",
      backgroundColor: state.isSelected ? "#29AEE6" : "#fff",
      color: state.isSelected ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: state.isSelected ? "#0056b3" : "#e9ecef",
        color: state.isSelected ? "#fff" : "#000",
      },
    }),
  };

  const validationSchema = Yup.object().shape({
    dispatcherId: Yup.string().required("Dispatcher is required"),
    airportIds: Yup.array().min(1, "At least one location is required"),
    fullName:
      status === "edit"
        ? Yup.string().required("Full Name is required")
        : Yup.string().notRequired(),
  });

  const getDispatchers = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getCustomerDispatchers("customer");
      const getData = response?.data?.map((item) => {
        return { value: item?.id, label: item?.displayName, ...item };
      });
      setDispatchersList(getData);
    } catch (error) {
      setDispatchersList([]);
    }
  };

  const getAirports = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getAirports(cityId);
      const getData = response?.data?.data?.map((item) => {
        return { value: item?.name, label: item?.name, id: item?.id };
      });
      setAirportsList(getData);
    } catch (error) {
      setAirportsList([]);
    }
  };

  const uploadImageToAWS = (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("image", file);
        const api = mobioApi(token);
        const response = await api.upload(formData);
        resolve(response.data.url);
      } catch (error) {
        toastMessage("error", "Error in uploading file");
        reject(error);
      }
    });
  };

  const handleSubmit = async ({
    airportIds,
    dispatcherId,
    fullName,
    profileImage,
  }) => {
    try {
      const api = mobioApi(token);
      if (status == "add") {
        await api.addRateIntegrationDispatcher({
          integrationId,
          airportIds,
          dispatcherId,
        });

        toastMessage("success", "Dispatcher added");
      } else if (status == "edit") {
        const payload = {
          integrationId,
          dispatcherId,
          airportIds,
          fullName,
        };

        if (profileImage) {
          const imageUrl = await uploadImageToAWS(profileImage);
          payload.imageUrl = imageUrl;
        } else {
          payload.imageUrl = integrationDispatcherDetails.imageUrl;
        }

        await api.updateRateIntegrationDispatcher(payload);
        toastMessage("success", "Dispatcher updated");
      }
      setModalOpen(false);
    } catch (error) {
      toastMessage("error", error.response?.data?.msg);
    }
  };

  const getRateIntegrationDispatcherDetails = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getRateIntegrationDispatcher(dispatcherId);
      setIntegrationDispatcherDetails(response?.data.data);
    } catch (error) {
      setIntegrationDispatcherDetails([]);
    }
  };

  useEffect(() => {
    getDispatchers();
    getAirports();
  }, []);

  useEffect(() => {
    if (status == "edit" && dispatcherId) {
      getRateIntegrationDispatcherDetails();
    } else {
      setIntegrationDispatcherDetails({});
    }
  }, [status, dispatcherId]);

  return (
    <MOModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={status === "add" ? "Add a dispatcher" : "Edit dispatcher details"}
      className={"dispatcher-add-modal"}
    >
      <Formik
        initialValues={{
          dispatcherId: integrationDispatcherDetails?.dispatcherId || "",
          airportIds: integrationDispatcherDetails?.airportIds || [],
          fullName: integrationDispatcherDetails?.fullName || "",
          profileImage: null,
          profileImagePreview: integrationDispatcherDetails.imageUrl || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values }) => (
          <Form>
            {status === "add" && (
              <div className="mb-20">
                <p className="m-0 font-14 font-500 mb-10">Search Dispatcher</p>
                <Field name="dispatcher">
                  {({ field }) => (
                    <Select
                      {...field}
                      options={dispatchersList}
                      placeholder="Search Dispatcher"
                      formatOptionLabel={customFormatOptionLabel}
                      styles={customStyles}
                      isSearchable={true}
                      className="dispatcher-selection-box"
                      onChange={(option) =>
                        setFieldValue("dispatcherId", option.id)
                      }
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="dispatcherId"
                  component="div"
                  className="field-error"
                />
              </div>
            )}

            {status === "edit" && (
              <div className="input-field-md-lg mb-20">
                <p className="m-0 font-14 font-500 mb-10">Full Name</p>
                <Field type="text" name="fullName" placeholder="Your Name" />
                <ErrorMessage
                  name="fullName"
                  component="div"
                  className="field-error"
                />
              </div>
            )}

            <div>
              <p className="m-0 font-14 font-500 mb-10">Operating locations</p>
              <Field name="location">
                {({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={airportsList}
                    placeholder="Select"
                    formatOptionLabel={customFormatOptionLabel}
                    styles={customStyles}
                    isSearchable={true}
                    className="dispatcher-selection-box"
                    value={airportsList.filter((a) =>
                      values.airportIds.includes(a.id)
                    )}
                    onChange={(selectedOptions) => {
                      setFieldValue(
                        "airportIds",
                        selectedOptions.map((o) => o.id)
                      );
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="airportIds"
                component="div"
                className="field-error"
              />
            </div>
            <div className="mt-30">
              <p className="m-0 font-14 font-400 text-black-gray">
                {status === "add"
                  ? " We'll send the dispatcher a link to the dispatcher app"
                  : "If you need to change the mobile number for this driver you will need to delete this driver and set up the driver again with a new number"}
              </p>
            </div>
            <div className="dispatcher-add-profile-img">
              {values.dispatcherId && (
                <>
                  <p className="m-0 font-14 font-500">
                    {status === "add"
                      ? "Profile Image"
                      : "Upload Profile Image"}
                  </p>
                  <div>
                    {status == "edit" && (
                      <label for="profileImage" style={{ cursor: "pointer" }}>
                        <input
                          id="profileImage"
                          name="profileImage"
                          type="file"
                          accept="image/jpeg,image/png"
                          style={{ display: "none" }}
                          onChange={(event) => {
                            const file = event.currentTarget.files[0];
                            setFieldValue("profileImage", file);
                            const reader = new FileReader();
                            reader.onloadend = () => {
                              setFieldValue(
                                "profileImagePreview",
                                reader.result
                              );
                            };
                            reader.readAsDataURL(file);
                          }}
                        />
                        <img
                          style={{ width: "70px", height: "70px" }}
                          src={
                            values.profileImagePreview ||
                            dispatchersList.find(
                              (d) => d.id == values.dispatcherId
                            )?.imageUrl
                          }
                          alt="Profile"
                        />
                      </label>
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              <MOButtonV2
                text={status === "add" ? "ADD DISPATCHER" : "SAVE CHANGES"}
                textColor="#ffffff"
                radius={4}
                backgroundColor="#29AEE6"
                height={49}
                fontSize={13}
                padding={"17px 12px"}
                type="submit"
                widthSize="100%"
              />
            </div>
          </Form>
        )}
      </Formik>
    </MOModalWrapper>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(AddDispatcherModal);
