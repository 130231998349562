export const serviceLevels ={
    STANDARD: "Standard",
    ELECTRIC: "Electric",
    EXECUTIVE: "Executive",
    MINI_BUS: "Mini Bus",
    CARRIER: "Carrier",
    LARGE_CARRIER: "Lrg Carrier",
    EXE_CARRIER: "Exe Carrier",
    LUXURY: "Luxury",
    ELECTRIC_LUXURY: 'Electric Luxury',
    ELECTRIC_PEOPLE_CARRIER: 'Elec. People Carrier',
    ELECTRIC_EXE_PEOPLE_CARRIER: 'Elec. Exe People Carrier', 
    ELECTRIC_LARGE_PEOPLE_CARRIER: 'Elec. Lrg People Carrier'
}