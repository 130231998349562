import CLIENT from "../constants/client";
export type LocalClientRepo = {
  resetAll: () => any;
  updateClient: (obj: any | null) => any;
};

const Client: LocalClientRepo = {
  resetAll: () => ({
    type: CLIENT.RESET_CLIENT,
    payload: {},
  }),
  updateClient: (payload: any) => ({
    type: CLIENT.UPDATE_CLIENT,
    payload: payload,
  }),
};

export default Client;
