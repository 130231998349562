/* Local actions: without side effects */
import Visibilities from "./visibilities";
import SignUp from "./sign-up";
import Login from "./login";
import User from "./user";
import TaxiCompanies from "./taxiCompanies";
import OnboardingListing from "./onboardingListing";
import { VehiclesListing, AddBrand, DeleteBrand } from "./vehicle-listing";
import { ApproveOnboarding, RejectOnboarding } from "./verifyOnboarding";
import {
  BookingsListing,
  DispatchersListing,
  // DispatchersByBooking,
  CreateBooking,
  BookingIntegration,
  TotalOverAssigned,
} from "./bookings";
import Drivers from "./drivers";
import Passengers from "./passengers";
import { Customers, AddCustomer, CustomerDispatchers } from "./customers";
import Client from "./client";
import {
  AuthSpreadSheet,
  CreateSheet,
  ImportSheet,
  Sheet,
} from "./spread-sheet";
import * as OrgUser from "./OrgUsers";
import * as CustomerUser from "./CustomerUsers";
import * as SubAdmins from "./SubAdmins";
import Networks from "./networks";
import { PaymentPayIn } from "./paymentPayins";
import { CreateLocation } from "./rate-module";

const Local = {
  Visibilities,
  SignUp,
  Login,
  User,
  TaxiCompanies,
  OnboardingListing,
  VehiclesListing,
  ApproveOnboarding,
  RejectOnboarding,
  BookingsListing,
  DispatchersListing,
  // DispatchersByBooking,
  CreateBooking,
  BookingIntegration,
  TotalOverAssigned,
  Drivers,
  AddBrand,
  DeleteBrand,
  Customers,
  AddCustomer,
  Client,
  CustomerDispatchers,
  AuthSpreadSheet,
  CreateSheet,
  ImportSheet,
  Sheet,
  Passengers,
  ...OrgUser,
  ...CustomerUser,
  ...SubAdmins,
  Networks,
  PaymentPayIn,
  CreateLocation,
};
export default Local;
