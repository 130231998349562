import { REJECT } from "../../actions/local/constants/onboarding-verification";
import rejectStates, {
  RejectStates,
} from "../../initial-state/rejectOnboarding";

const rejectReducer = (state: RejectStates = rejectStates, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case REJECT.RESET_ALL: {
      return {
        ...rejectStates,
      };
    }
    case REJECT.FIELD.UPDATE: {
      const { field, newContent } = payload;
      return {
        ...state,
        [field]: newContent,
      };
    }
    case REJECT.FIELD.CLEAR: {
      const { field } = payload;
      return {
        ...state,
        [field]: "",
      };
    }

    default:
      return state;
  }
};

export default rejectReducer;
