type SignUpFlag = {
  completed: boolean;
  currentStep: number;
};

export type SignUpState = {
  userName: string;
  email: string;
  password: string;
  companyName: string;
  country: string;
  city: string;
  address: string;
  addressLineTwo: string;
  postalCode: string;
  IBAN: string;
  flags: SignUpFlag;
};

const signUpState: SignUpState = {
  userName: "",
  email: "",
  password: "",
  companyName: "",
  country: "Netherlands",
  city: "",
  address: "",
  IBAN: "",
  addressLineTwo: "",
  postalCode: "",
  flags: {
    completed: false,
    currentStep: 1,
  },
};

export default signUpState;
