import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Breadcrumb from "../../../components/BreadCrumb";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import { Add, ArrowDownward, ArrowUpward } from "@material-ui/icons";
import MOSwitch from "../../../components/commons/MOSwitch";
import DropDown from "../../../components/commons/DropDown";
import DeleteCampaignModal from "./Modals/DeleteCampaignModal";
import SearchingInput from "../../../components/commons/SearchingInput";
import HorizontalIcon from "../../../assets/icons/horizontalIcon.png";
import EditIcon from "../../../assets/icons/editUpdateIcon.png";
import DeleteIcon from "../../../assets/icons/menuDelete.png";
import AddCampaignModal from "./Modals/AddCampaignModal";
import { connect } from "react-redux";
import mobioApi from "../../../services";
import { formatDate, removeLast00 } from "../../../helpers/getTimeFormat";
import { toastMessage } from "../../../helpers/toastMessage";
import { filterServiceLevel } from "../../../helpers/filterServiceLevel";

const Index = ({ token }) => {
  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();
  const [anchorEl, setAnchorEl] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [serviceLevelsList, setServiceLevelsList] = useState([]);
  const [singleData, setSingleData] = useState("");

  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },

    {
      name: "Manage AMS",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },

    {
      name: "Campaigns",
      // url: "",
    },
  ];

  const getCampaignList = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getCampaign(airportId);
      const getData = response?.data?.data;
      setCampaignList(getData);
    } catch (error) {
      setCampaignList([]);
    }
  };
  const deleteCampaign = async (id) => {
    try {
      const api = mobioApi(token);
      const response = await api.deleteCampaign(id);
      toastMessage("success", response?.data?.msg);
      setCampaignList(campaignList?.filter((item) => item?.id !== id));
      setDeleteModalOpen(false);
    } catch (error) {
      toastMessage("error", error?.response?.data?.msg);
    } finally {
      setSingleData("");
    }
  };
  const getAllServiceLevels = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getAllServiceLevels();
      // setServiceLevelsList(response?.data?.data);
      const getResponseData = response?.data?.data;
      if (getResponseData?.length > 0) {
        setServiceLevelsList(
          filterServiceLevel(integrationType, getResponseData || [])
        );
      }
    } catch (error) {
      setServiceLevelsList([]);
    }
  };

  const updateCampaignStatus = async (data, status) => {
    try {
      const payload = {
        serviceLevelId: data.serviceLevelId,
        airportId: airportId,
        campaignName: data.campaignName,
        startTime: data.startTime,
        startDate: data.startDate,
        endTime: data.endTime,
        endDate: data.endDate,
        status: status,
        differenceType: data.differenceType,
        differencePercentage: data.differencePercentage,
        campaignId: data.id,
      };
      const api = mobioApi(token);
      const response = await api.updateCampaign(payload);
      toastMessage("success", response?.data?.msg);
    } catch (error) {
      toastMessage("error", error?.response?.data?.msg);
    }
  };
  useEffect(() => {
    getCampaignList();
    getAllServiceLevels();
  }, []);

  useEffect(() => {
    if (!addModalOpen) {
      setSingleData("");
    }
  }, [addModalOpen]);

  return (
    <>
      <div>
        <div className="mt-18 flex justify-content-between">
          <div>
            <Breadcrumb
              paths={[
                {
                  name: "Campaigns",
                  url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
                },
              ]}
            />
            <div className="breadcrumb-description">
              Change your distance and fixed route rates for specific events.
            </div>
          </div>
          <div className="flex">
            <div className="mr-12">
              <SearchingInput />
            </div>
            <MOButtonV2
              text="ADD NEW CAMPAIGN"
              textColor="#ffffff"
              radius={4}
              backgroundColor="#29AEE6"
              height={30}
              fontSize={11}
              padding={"6.5px 31px"}
              IconType={() => <Add style={{ marginRight: 10, fontSize: 16 }} />}
              onClick={() => {
                setAddModalOpen(true);
              }}
            />
          </div>
        </div>
        <div className="mt-12 ml-6">
          <Breadcrumb paths={secondaryPaths} secondary={true} />
        </div>
        <div>
          <div className="bg-white  mt-20 border-radius-8">
            <div className="campaign-table-section">
              <table className="custom-table-v3 ">
                <thead>
                  <tr>
                    <th>
                      <span className="campaign-table-section-first-cell">
                        Name of Campaigns
                      </span>
                    </th>
                    <th className="">Vehicle Type</th>
                    <th className="">Difference %</th>
                    <th className="">Start Date</th>
                    <th className="">End Date</th>
                    <th className="">Start Time</th>
                    <th className="">End Time</th>
                    <th className="">Active</th>
                    <th className="">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {campaignList?.length ? (
                    campaignList.map((row, index) => (
                      <tr key={index}>
                        <td className="text-warp">{row.campaignName}</td>
                        <td>
                          <div className="flex align-item-center">
                            <img
                              src={row.campaignServiceLevel?.icon}
                              alt="icon"
                              className="distance-rate-table-icon"
                            />
                            <p className="m-0">
                              {row.campaignServiceLevel?.name}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="flex align-item-center">
                            {row.differencePercentage} %
                            {row.differenceType === "Increase" ? (
                              <ArrowUpward
                                style={{ marginLeft: "4px", color: "#428702" }}
                              />
                            ) : (
                              <ArrowDownward
                                style={{ marginLeft: "4px", color: "#FC0808" }}
                              />
                            )}
                          </div>
                        </td>
                        <td>{formatDate(row?.startDate)}</td>
                        <td>{formatDate(row?.endDate)}</td>
                        <td>{removeLast00(row.startTime)}</td>
                        <td>{removeLast00(row.endTime)}</td>

                        <td>
                          <MOSwitch
                            isOnStatus={row.status}
                            id={row.id}
                            setStatus={(value) => {
                              updateCampaignStatus(row, value);
                            }}
                          />
                        </td>
                        <td>
                          {" "}
                          <DropDown
                            dropDownOpen={anchorEl}
                            setDropDownOpen={setAnchorEl}
                            uniqueId={row.id}
                            IconRender={() => <img src={HorizontalIcon} />}
                            dropDownShape="horizontal"
                            menu={[
                              {
                                onClick: () => {
                                  setAddModalOpen(true);
                                  setSingleData(row);
                                },
                                IconRender: () => <img src={EditIcon} />,
                                title: "Edit",
                              },
                              {
                                onClick: () => {
                                  setSingleData(row);
                                  setDeleteModalOpen(true);
                                },
                                IconRender: () => <img src={DeleteIcon} />,
                                title: "Delete",
                              },
                            ]}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr>
                        <td colSpan="9">
                          <div className="table-no-record-section">
                            No records are found.
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* Modals */}
        <DeleteCampaignModal
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          deleteAPICall={() => {
            deleteCampaign(singleData?.id);
          }}
        />
        <AddCampaignModal
          token={token}
          airportId={airportId}
          modalOpen={addModalOpen}
          setModalOpen={setAddModalOpen}
          serviceLevelsList={serviceLevelsList}
          apiCall={getCampaignList}
          singleData={singleData}
        />
      </div>
    </>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Index);
