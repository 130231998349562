import React, { useState } from "react";
import MOModalWrapper from "../../../../components/commons/MOModalWrapper";
import HeaderDescription from "../../../../components/commons/HeaderDescription";
import MOSwitch from "../../../../components/commons/MOSwitch";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import {
  convertTimeStringToDate,
  getTimeFormat24Hour,
} from "../../../../helpers/getTimeFormat";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TimePickerField from "../../../../components/commons/TimePickerField";
import mobioApi from "../../../../services";
import { useParams } from "react-router";
import { toastMessage } from "../../../../helpers/toastMessage";
import VehicleList from "../../../../components/commons/VehicleList";
import {
  daysOptions,
  hoursOptions,
  weeksOptions,
} from "../../../../helpers/generateNumber";

const AddLeadTimeOfferModal = ({
  token,
  apiCall,
  modalOpen,
  setModalOpen,
  singleLeadTime,
  serviceLevelsList,
  setSingleLeadTime = () => {},
}) => {
  const { airportId } = useParams();
  const [serviceLevelId, setServiceLevelId] = useState("");

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const payload = {
        serviceLevelId: serviceLevelId,
        increase: values.rate,
        rate: values.rate,
        // priorTime: getTimeFormat24Hour(values?.priorTime),
        priorTime: values?.priorTime,
        effect: values.effect,
        airportId: airportId,
        priorDays: values.priorDays,
        priorWeeks: values.priorWeeks,
      };
      const api = mobioApi(token);
      let response = null;
      if (singleLeadTime) {
        response = await api.updateLeadTime(singleLeadTime?.id, payload);
      } else {
        response = await api.addLeadTime(payload);
      }

      toastMessage("success", response?.data?.msg);
      setSingleLeadTime("");
      apiCall();
      setModalOpen(false);
    } catch (error) {
      toastMessage("error", error.response?.data?.msg);
    }
  };

  return (
    <MOModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={
        <div>
          <VehicleList
            defaultValue={singleLeadTime ? singleLeadTime?.serviceLevelId : ""}
            serviceLevelsList={serviceLevelsList}
            setSelectServiceLevel={setServiceLevelId}
          />
        </div>
      }
      className={"lead-time-modal-section"}
    >
      <div>
        <Formik
          initialValues={{
            // serviceLevelId: singleLeadTime
            //   ? singleLeadTime?.associatedLeadTime?.id
            //   : "",
            // priorTime: singleLeadTime
            //   ? convertTimeStringToDate(singleLeadTime?.priorTime)
            //   : "",
            effect: singleLeadTime ? singleLeadTime?.effect : false,
            increase: singleLeadTime ? singleLeadTime?.increase : 0,
            priorTime: singleLeadTime ? singleLeadTime?.priorTime : "0",
            priorDays: singleLeadTime ? singleLeadTime?.priorDays : "0",
            priorWeeks: singleLeadTime ? singleLeadTime?.priorWeeks : "0",
            rate: singleLeadTime ? singleLeadTime?.rate : "",
          }}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, handleChange }) => (
            <Form>
              <div className="">
                <div>
                  {/* Service Levels */}
                  {/* <div className="input-field-sm">
                    <label className="font-600 font-12 mb-8">
                      Service Levels
                    </label>
                    <Field
                      as="select"
                      name="serviceLevelId"
                      className="form-select"
                    >
                      <option value="">Enter Your Service Type</option>
                      {serviceLevelsList?.map((service) => (
                        <option key={service?.id} value={service?.id}>
                          {service?.name}
                        </option>
                      ))}
                    </Field>
                  </div> */}

                  <div className="add-lead-time-section">
                    <p className="font-12 font-600 mb-6">Prior Time </p>
                  </div>

                  <div className="flex gap-16">
                    {/* Hour */}
                    <div className="flex-1">
                      <div className="rate-lead-time-field">
                        <Field
                          as="select"
                          name="priorTime"
                          onChange={handleChange}
                          value={values.priorTime}
                          style={{ textIndent: "12px" }}
                        >
                          <option value="0" label="0" />
                          {hoursOptions.map((hr) => (
                            <option key={hr} value={hr}>
                              {hr}
                            </option>
                          ))}
                        </Field>
                        <p className="font-11 font-500">Hours</p>
                      </div>
                      {/* <ErrorMessage
                        name={`priorHour`}
                        component="div"
                        className="text-danger"
                      /> */}
                    </div>
                    {/* Days */}
                    <div className="flex-1">
                      <div className="rate-lead-time-field">
                        <Field
                          as="select"
                          name="priorDays"
                          onChange={handleChange}
                          value={values.priorDays}
                          style={{ textIndent: "12px" }}
                        >
                          <option value="0" label="0" />
                          {daysOptions.map((day) => (
                            <option key={day} value={day}>
                              {day}
                            </option>
                          ))}
                        </Field>
                        <p className="font-11 font-500">Days</p>
                      </div>
                      <ErrorMessage
                        name={`priorDays`}
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    {/* Weeks */}
                    <div className="flex-1">
                      <div className="rate-lead-time-field">
                        <Field
                          as="select"
                          name="priorWeeks"
                          onChange={handleChange}
                          value={values.priorWeeks}
                          style={{ textIndent: "12px" }}
                        >
                          <option value="0" label="0" />
                          {weeksOptions.map((week) => (
                            <option key={week} value={week}>
                              {week}
                            </option>
                          ))}
                        </Field>
                        <p className="font-11 font-500">Weeks</p>
                      </div>
                      <ErrorMessage
                        name={`priorWeeks`}
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  {/* <div className="add-lead-time-section">
                    <div className="flex-1">
                      <p className="font-12 font-600 mb-6">Prior Time </p>
                      <Field
                        name="priorTime"
                        className="lead-prior-time-picker"
                        component={TimePickerField}
                      />
                    </div>
                  </div>

                  <div className="input-field-sm mt-20">
                    <p className="font-600 font-12 mb-6">Prior Days</p>
                    <Field
                      as="select"
                      name="priorDays"
                      onChange={handleChange}
                      value={values.priorDays}
                      style={{ textIndent: "12px" }}
                    >
                      <option value="" label="Select days" />
                      {daysOptions.map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </Field>
                  </div>

           
                  <div className="input-field-sm mt-20">
                    <p className="font-600 font-12 mb-6">Prior Weeks</p>
                    <Field
                      as="select"
                      name="priorWeeks"
                      onChange={handleChange}
                      value={values.priorWeeks}
                      style={{ textIndent: "12px" }}
                    >
                      <option value="" label="Select weeks" />
                      {weeksOptions.map((week) => (
                        <option key={week} value={week}>
                          {week}
                        </option>
                      ))}
                    </Field>
                  </div> */}

                  {/* Discount */}
                  <div className="mt-20">
                    <p className="font-600 font-12 m-0">Discount %</p>
                    <div className="flex align-item-center mt-12">
                      <p className="m-0 font-10 font-400 w-118">No Effect</p>
                      <div className="ml-8">
                        <MOSwitch
                          isOnStatus={values.effect}
                          setStatus={(value) => {
                            setFieldValue("effect", value);
                          }}
                        />
                      </div>
                    </div>

                    {/* <div className="flex mt-30">
           
                      <div className="flex align-item-center flex-1">
                        <div className="flex align-item-center justify-content-between">
                          <p className="font-15 font-400">Increase %</p>
                          <ArrowUpward style={{ color: "#428702" }} />
                        </div>

                        <div className="input-field-lg ml-8 flex-1">
                          <Field
                            type="number"
                            name="increase"
                            max={100}
                            min={0}
                            className="form-input"
                          />
                        </div>
                      </div>
                 
                    </div> */}
                    <div className="mt-20">
                      <p className="font-600 font-12 mb-6"> Rate</p>
                      <div className="hourly-rate-field-rate-section">
                        <div className="flex align-item-center">
                          <input
                            className="remove-number-arrow"
                            type="number"
                            step={"0.1"}
                            name="rate"
                            placeholder="0"
                            value={values.rate}
                            onChange={(e) => {
                              setFieldValue("rate", e.target.value);
                            }}
                          />
                          <p>%</p>
                        </div>
                        <div className="flex align-item-center">
                          <button
                            type="button"
                            onClick={() => {
                              const getValue = parseFloat(values.rate) + 1;
                              if (getValue) {
                                setFieldValue("rate", getValue);
                              } else if (!getValue) {
                                setFieldValue("rate", 1);
                              }
                            }}
                          >
                            +
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              const getValue = parseFloat(values.rate) - 1;
                              if (getValue >= 0) {
                                setFieldValue("rate", getValue);
                              }
                            }}
                          >
                            -
                          </button>
                        </div>
                      </div>
                      <ErrorMessage
                        name="rate"
                        component="p"
                        className="text-danger font-12"
                      />
                    </div>
                  </div>
                </div>

                {/* button */}
                <div className="mt-20 ">
                  <MOButtonV2
                    text={singleLeadTime ? "EDIT" : "SAVE"}
                    textColor="#ffffff"
                    radius={4}
                    backgroundColor="#29AEE6"
                    type="submit"
                    height={44}
                    fontSize={11}
                    padding={"8px 32px"}
                    widthSize="100%"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </MOModalWrapper>
  );
};

export default AddLeadTimeOfferModal;
