export type CreateAddLocationState = {
  companyId: string;
  integrationType: string;
  countryName: string;
  stateName:string,
  cityName: string;
  airportLat: string;
  airportLng: string;
  centralZone: string;
  leadTime: string;
  airportName: string;
  supportNumber: string;
  iata: string;
};

const createAddLocationState: CreateAddLocationState = {
  companyId: "",
  integrationType: "",
  countryName: "",
  stateName:"",
  cityName: "",
  airportLat: "",
  airportLng: "",
  centralZone: "",
  leadTime: "",
  airportName: "",
  supportNumber: "",
  iata:""
};

export default createAddLocationState;
