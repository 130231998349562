export const TAXI_COMPANIES_API = {
  SET_REQUEST: "ACTION.TAXI_COMPANIES.SET_REQUEST",
  SET_RESPONSE: "ACTION.TAXI_COMPANIES.SET_RESPONSE",
  SET_ERROR: "ACTION.TAXI_COMPANIES.SET_ERROR",
  SET_RETRY: "ACTION.TAXI_COMPANIES.SET_RETRY",
};

const TAXI_COMPANIES = {
  TAXI_COMPANIES_API,
};

export default TAXI_COMPANIES;
