import MaterialTable from "material-table";
import _ from "lodash";
import { connect } from "react-redux";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import ThunksVehicle from "../../../redux/actions/thunks/vehicles-listing";

function DetailTable({ rowData, updateCar, addCar, deleteCar, response }) {
  const year = new Date().getFullYear();
  const columns = [
    {
      title: "Car Name",
      field: "carName",
    },
    {
      title: "Year",
      field: "year",
      validate: (rowData) => rowData.year >= 2010 && rowData.year <= year,
      type: "numeric",
    },

    {
      title: "Category",
      field: "category",
      lookup: {
        STANDARD: "Standard",
        ELECTRIC: "Electric",
        EXECUTIVE: "Executive",
        MINI_BUS: "Mini Bus",
        CARRIER: "Carrier",
        LARGE_CARRIER: "Lrg Carrier",
        EXE_CARRIER: "Exe Carrier",
        LUXURY: "Luxury",
        ELECTRIC_LUXURY: 'ELECTRIC_LUXURY',
        ELECTRIC_PEOPLE_CARRIER: 'ELECTRIC_PEOPLE_CARRIER',
        ELECTRIC_EXE_PEOPLE_CARRIER: 'ELECTRIC_EXE_PEOPLE_CARRIER', 
        ELECTRIC_LARGE_PEOPLE_CARRIER: 'ELECTRIC_LARGE_PEOPLE_CARRIER'
      },
    },
    {
      title: "Seating Capacity",
      field: "seatingCapacity",
      validate: (rowData) =>
        rowData.seatingCapacity > 0 && rowData.seatingCapacity <= 8,
      type: "numeric",
    },
  ];

  return (
    <MaterialTable
      options={{ search: true }}
      title="Models"
      icons={tableIcons}
      columns={columns}
      data={rowData.cars}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              // setData([...rowData, newData]);
              addCar(rowData.id, newData);
              resolve();
            }, 1000);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              updateCar(oldData.id, rowData.id, newData);

              resolve();
            }, 1000);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              deleteCar(oldData.id);
              resolve();
            }, 1000);
          }),
      }}
    />
  );
}

const mapState = (state) => ({
  response: state,
});

const mapDispatch = {
  addCar: ThunksVehicle.AddCar,
  updateCar: ThunksVehicle.UpdateCar,
  deleteCar: ThunksVehicle.DeleteCar,
};

export default connect(mapState, mapDispatch)(DetailTable);
