import React, { useEffect, useState } from "react";
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../../components/commons/ButtonGrouping";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import {
  Badge,
  Box,
  Button,
  Chip,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { DownloadCloudIcon } from "../../../assets/icons";
import { ChevronLeftOutlined, ChevronRightOutlined } from "@material-ui/icons";
import { data } from "./data";
import { connect } from "react-redux";
import ThunkPayment from "../../../redux/actions/thunks/paymant";
import moment from "moment";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import { useLocation } from "react-router";
import { floor } from "lodash";
import VisibilityIcon from "@mui/icons-material/Visibility";

const useStyles = makeStyles({
  mainContainer: {
    marginTop: 12,
    height: "100%",
  },
  statusContainer: {
    width: "max-content",
    display: "flex",
    alignItems: "center",
    padding: "2px 8px",
    borderRadius: 50,
  },
  statusDot: {
    width: 6,
    height: 6,
    borderRadius: 50,
    marginRight: 6,
  },
  statusText: { fontSize: 12, fontWeight: 500 },
});
const Index = ({
  getPayinPaymentCustomer,
  loading,
  response,
  error,
}) => {
  const classes = useStyles();
  const [type, setType] = useState("All");
  const [invoiceData, setInvoiceData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [totalDocCount,setTotalDocCount]=useState(0)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(7);
  const [total, setTotal] = useState(null);


  useEffect(() => {
    if(type == "pending" || type == "paid"){
      getPayinPaymentCustomer(type,searchText,page,limit);
    }else{
      getPayinPaymentCustomer("",searchText,page,limit);
    }
  }, [type,searchText,page,limit]);

  useEffect(() => {
    console.log("response", response);
    setInvoiceData(response?.rows);
    setTotalDocCount(response?.count)
    countTotalPage(response?.count)
  }, [response]);
  const columns = [
    {
      title: "Invoice Number",
      field: "receiptNo",
    },
    {
      title: "Invoice Date",
      field: "createdAt",
      render: (rowData) => {
        return <>{moment(rowData?.createdAt).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: "Time Frame",
      field: "TimeFrame",
      render: (rowData) => {
        return (
          <>
            <p>
              {moment(rowData?.weekStart).format("DD-MM-YYYY")} -{" "}
              {moment(rowData?.weekEnd).format("DD-MM-YYYY")}
            </p>
          </>
        );
      },
    },
    {
      title: "Status",
      field: "isPaid",
      render: (rowData) => (
        <>
          <Box
            className={classes.statusContainer}
            style={{
              backgroundColor: rowData.isPaid ? "#ECFDF3" : "#F42A4117",
            }}
          >
            <Box
              className={classes.statusDot}
              style={{
                backgroundColor: rowData.isPaid ? "#037847" : "#F42A41",
              }}
            />
            <Typography
              className={classes.statusText}
              style={{
                color: rowData.isPaid ? "#037847" : "#FF3B30",
              }}
            >
              {rowData.isPaid ? "Paid" : "Unpaid"}
            </Typography>
          </Box>
        </>
      ),
    },
    {
      title: "Action",
      field: "Action",
      render: (rowData) => (
        <>
        <div style={{ display: "flex", flexDirection: "row" }}>
        <Button style={{ borderRadius: 25, minWidth: 40, marginRight: 10 }} size="sm" onClick={async () => {
              let a1link = document.createElement('a');
              a1link.id = "view" + rowData.id;
              a1link.href = rowData.pdfURL;
              a1link.target = "_blank";
              a1link.download = `${rowData.receiptNo}.pdf`;
              a1link.click();
            }}>
              <VisibilityIcon />
            </Button>
            <Box
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={async () => {
                  let alink1 = document.createElement("a");
                  alink1.id = "download" + rowData.id;
                  alink1.href = `${process.env.REACT_APP_API_BASE_URL}/payment/payin/download/${rowData.id}`;
                  alink1.download = `${rowData.receiptNo}.pdf`;
                  alink1.click();
                }}
          >
            <DownloadCloudIcon style={{ marginRight: 8 }} />
            <Typography
              style={{ color: "#29AEE6", fontSize: 16, fontWeight: 400,whiteSpace:"nowrap" }}
            >
              Download Invoice
            </Typography>
          </Box>
        </div>
          
        </>
      ),
    },
  ];

  const locationQuery = useLocation();
  
  useEffect(() => {
    const queryParams = new URLSearchParams(locationQuery.search);
    const searchingQuery = queryParams.get('search');
    if(searchingQuery){
      setSearchText(searchingQuery)
    }else{
      setSearchText("")
    }
    
  }, [locationQuery]);

  // functions
  const onpageChange = async (selectedPage) => {
    console.log("selectedPage",selectedPage)
    // await getBookings(type, selectedPage, limit, searchText);
  };
  const countTotalPage = (count) => {
    const finalCount = count || 0
    if (parseInt(finalCount) % parseInt(limit) !== 0) {
      setTotal(floor(parseInt(finalCount) / parseInt(limit)) + 1);
    } else {
      setTotal(parseInt(finalCount) / parseInt(limit));
    }
  };
  return (
    <div>
      <StyledToggleButtonGroup
        value={type}
        exclusive
        onChange={(e, value) => {value !== null && setType(value);setPage(1)}}
      >
        <StyledToggleButton disabled={loading} key={0} value={"All"}>
          All
        </StyledToggleButton>
        <StyledToggleButton disabled={loading} key={0} value={"pending"}>
          Pending
        </StyledToggleButton>
        <StyledToggleButton disabled={loading} key={0} value={"paid"}>
          Paid
        </StyledToggleButton>
      </StyledToggleButtonGroup>

      <Box className={classes.mainContainer}>
        <MaterialTable
          style={{ minWidth: "100%", height: "100%" }}
          icons={tableIcons}
          isLoading={loading}
          columns={columns}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          data={invoiceData}
          title={""}
          options={{
            search: false,
            paginationPosition: false,
            toolbar: false,
            actionsColumnIndex: -1,
            pageSize: 10,
          }}
        />
      </Box>
      {/* Pagination */}
      <Box
        style={{
          backgroundColor: "#E0E0E0",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "15px 17px",
          width: "100%",
        }}
      >
        <Typography
          style={{
            color: "#1C1C1C",
            fontSize: 12,
            fontWeight: 600,
            marginRight: 70,
          }}
        >
          Rows per page: {limit}
        </Typography>
        <Typography
          style={{
            color: "#1C1C1C",
            fontSize: 12,
            fontWeight: 600,
            marginRight: 40,
          }}
        >
          {page}-{total} of {totalDocCount}
        </Typography>
        <ChevronLeftOutlined 
        style={{color:page > 1?"#000000":"#BDBDBD"}}
        onClick={async () => {
          if(page > 1){
            setPage(page - 1);
            await onpageChange(page - 1);
          }
             
                  }} />
        <ChevronRightOutlined
        style={{color:total > page?"#000000":"#BDBDBD"}}
        onClick={async () => {
          if(total > page){
            setPage(page + 1);
            await onpageChange(page + 1);
          }
                   
                  }}
         />
      </Box>
      {error.status && <ErrorSnack errorMsg={error.message} />}
    </div>
  );
};

// export default Index;

const mapState = (state) => ({
  response: state.paymentPayins.response.data,
  loading: state.paymentPayins.loading,
  error: state.paymentPayins.error.status,
});

const mapDispatch = {
  getPayinPaymentCustomer: ThunkPayment.GetCustomerPayinPayments,
};

export default connect(mapState, mapDispatch)(Index);
