import React from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddUser from "./AddUser";
import UserList from "./UserList";
import { connect } from "react-redux";
import CustomerUsers from "../../../redux/actions/thunks/CustomerUsers";
import AddUserForm from "./AddUserForm";

function ClientUsers({ getAllAuthorizations, getAllCustomerUsers }) {
  React.useEffect(() => {
    getAllAuthorizations();
    getAllCustomerUsers();
  }, []);

  return (
    <>
      <Grid
        container
        direction="row-reverse"
        x
        justifyContent="flex-start"
        alignItems="center"
        style={{ marginBottom: 16 }}
      >
        {/* <AddUser /> */}
        <AddUserForm />
      </Grid>
      <UserList />
    </>
  );
}

const mapDispatch = {
  // getCustomers: ThunksCustomers.Customers,
  getAllAuthorizations: CustomerUsers.getAllAuthorizations,
  getAllCustomerUsers: CustomerUsers.customerUsers,
};

export default connect(null, mapDispatch)(ClientUsers);
