export const LOGIN_API = {
  SET_REQUEST: "ACTION.LOGIN.SET_REQUEST",
  SET_RESPONSE: "ACTION.LOGIN.SET_RESPONSE",
  SET_ERROR: "ACTION.LOGIN.SET_ERROR",
  SET_RETRY: "ACTION.LOGIN.SET_RETRY",
  RESET: "ACTION.LOGIN.RESET",
};

const LOGIN = {
  RESET_ALL: "ACTION.LOCAL.LOGIN.RESET_ALL",
  FIELD: {
    UPDATE: "ACTION.LOCAL.LOGIN.FIELD.UPDATE",
    CLEAR: "ACTION.LOCAL.LOGIN.FIELD.CLEAR",
  },
  FLAGS: {
    TOGGLE_COMPLETE: "ACTION.LOCAL.LOGIN.FLAGS.TOGGLE_COMPLETE",
    UPDATE_STEP: "ACTION.LOCAL.LOGIN.FLAGS.UPDATE_STEP",
    RESET: "ACTION.LOCAL.LOGIN.FLAGS.RESET",
  },
  LOGIN_API,
};

export default LOGIN;
