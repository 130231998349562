import Local from "../../local";

export type ThunksUsersListing = {
  updateStar: (id: any, obj: any) => any;
  updateGhost: (id: any, obj: any) => any;
  updateUser: (id: any, obj: any) => any;
  getDisplayNameByUserId: (id: any) => any;
  getLatestImage: () => any;

};

const ThunksUsers: ThunksUsersListing = {
  updateStar: (id, obj) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();

    const api = mobioApi(token);

    return api
      .updateStar(id, obj)
      .then((response: any) => {
        console.log(response);

        return 1
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.Drivers.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(
            Local.Drivers.api.error("Request Failed", data.msg)
          );
        }
      });
  },
  updateGhost: (id, obj) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token, user },
    } = getState();

    const api = mobioApi(token);

    return api
      .updateGhost(id, obj)
      .then((response: any) => {
        console.log(response);

        return 1
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.Drivers.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(
            Local.Drivers.api.error("Request Failed", data.msg)
          );
        }
      });
  },
  updateUser: (id, obj) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo,
      userInfo: { token, user },
    } = getState();

    const api = mobioApi(token);

    return api
      .updateCompany(id, obj)
      .then((response: any) => {
        let obj = {
          ...userInfo,
          user: response.data
        }
        dispatch(Local.User.updateUser(obj));
        dispatch(Local.Login.api.response(obj));
        return response;
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.Drivers.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(
            Local.Drivers.api.error("Request Failed", data.msg)
          );
        }
      });
  },
  getDisplayNameByUserId: (id) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo,
      userInfo: { token, user },
    } = getState();

    const api = mobioApi(token);

    return api
      .getDisplayNameByUserId({ id: id })
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.Drivers.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(
            Local.Drivers.api.error("Request Failed", data.msg)
          );
        }
      });
  },
  getLatestImage: () => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token, user },
    } = getState();
    const api = mobioApi(token);
    return api
      .getImage()
      .then((response: any) => {
        // return response.data;
        dispatch(Local.User.updateUserImage(response?.data?.imageUrl || null));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.Drivers.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(
            Local.Drivers.api.error("Request Failed", data.msg)
          );
        }
      });
  },
};

export default ThunksUsers;
