import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Input from "../../../components/commons/Input";
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  InputAdornment,
  List,
  ListItem,
  Paper,
} from "@material-ui/core";
import { connect } from "react-redux";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import Local from "../../../redux/actions/local";
import AddUserAuthorities from "./AddUserAuthorities";
import CustomerUsers from "../../../redux/actions/thunks/CustomerUsers";
import MOButton from "../../../components/commons/MOButton";
import { SignalPassengerIcon } from "../../../assets/icons";
import { MailOutline } from "@material-ui/icons";
import { ErrorMessage, Form, Formik } from "formik";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  root: {
    ".MuiDialogContent-root": {
      // width:"900px"
    },
    "& .MuiDialog-paperWidthLg": {
      borderRadius: 10,
    },
    "& .MuiDialog-paper": {
      // width: "900px !important"
    },
    "& .MuiDialog-paperWidthSm": {
      width: "900px !important",
    },
    "& .MuiDialogTitle-root": {},
  },
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    height: 600,
    marginLeft: 16,
    marginRight: 34,
  },

  button: {
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 8,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
  },
  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    // width: 550,
    width: "100%",
    height: 40,
  },
  customDialog: {
    maxWidth: "80%", // Adjust the maximum width as needed
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AddUser = ({
  openDialog,
  closeDialog,
  getAllClientUsers,
  postUser,
  updateClientUser,
  status,
  failure,
  success,
  reset,
  response,
  dialogIsOpen,
  selectedUser,
  resetUpadte,
}) => {
  const initailUser = {
    email: "",
    firstName: "",
    lastName: "",
  };
  const classes = useStyles();
  const [user, setUser] = useState(initailUser);
  const [error, setError] = useState({
    email: false,
    firstName: false,
    lastName: false,
  });
  const handleClickOpen = () => {
    openDialog();
  };
  const handleClose = () => {
    closeDialog();
  };
  useEffect(() => {
    if (!!selectedUser?.id) {
      setUser(selectedUser);
    } else {
      setUser(initailUser);
    }
  }, [selectedUser?.id]);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
      };
      payload.selectedAuthorities = values.selectedAuthorities.map((item) => {
        return item.id;
      });
      if (selectedUser?.id) {
        payload.id = selectedUser.id;
        await updateClientUser(payload);
      } else {
        payload.email = values.email;
        await postUser(payload);
      }
    } catch (error) {
      console.log("Error");
      //   alert("Form not submit successfully ")
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (success) {
      getAllClientUsers();
      handleClose();
    }

    if (response?.success) {
      getAllClientUsers();
      handleClose();
    }
  }, [success, response?.success]);

  return (
    <div>
      <MOButton
        title={!!selectedUser?.id ? "Edit User" : "Add User"}
        onClick={handleClickOpen}
      />

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={dialogIsOpen}
        className={classes.root}
        maxWidth={"lg"}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {!!selectedUser?.id ? "Update User" : "Create User"}
        </DialogTitle>
        <DialogContent>
          <div style={{ width: "900px" }}>
            {/* Formik Form handling start */}
            <Formik
              initialValues={{
                email: selectedUser ? selectedUser?.email : "",
                firstName: selectedUser ? selectedUser?.firstName : "",
                lastName: selectedUser ? selectedUser?.lastName : "",
                selectedAuthorities: selectedUser?.userAuthorities
                  ? selectedUser?.userAuthorities
                  : [],
              }}
              validate={(values) => {
                const errors = {};
                if (!values.firstName) {
                  errors.firstName = "First Name is required.";
                }
                if (!values.lastName) {
                  errors.lastName = "Last Name is required.";
                }

                if (values.selectedAuthorities.length == 0) {
                  errors.selectedAuthorities =
                    "Please select at least one authorities role";
                }

                if (!values.email) {
                  errors.email = "Email is required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }

                return errors;
              }}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                  <Grid>
                    <Input
                      label="First Name"
                      value={values.firstName}
                      type="text"
                      name="firstName"
                      className={classes.textfield_upper}
                      onChange={(e) =>
                        setFieldValue("firstName", e.target.value)
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SignalPassengerIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name={`firstName`}
                      component="div"
                      className="error-text-danger error-text-style"
                    />
                  </Grid>
                  <Grid>
                    <Input
                      label="Last Name"
                      value={values.lastName}
                      type="text"
                      size="small"
                      name="lastName"
                      className={classes.textfield_upper}
                      onChange={(e) =>
                        setFieldValue("lastName", e.target.value)
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SignalPassengerIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name={`lastName`}
                      component="div"
                      className="error-text-danger error-text-style"
                    />
                  </Grid>
                  <Grid>
                    <Input
                      label="Email "
                      value={values.email}
                      disabled={!!selectedUser?.id}
                      autoFocus
                      type="email"
                      size="small"
                      name="email"
                      className={classes.textfield_upper}
                      onChange={(e) => setFieldValue("email", e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutline style={{ color: "#AAAAAA" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ErrorMessage
                      name={`email`}
                      component="div"
                      className="error-text-danger error-text-style"
                    />
                  </Grid>
                  <Grid>
                    <AddUserAuthorities
                      selectedAuthorities={(sel) => {
                        // setUser({ ...user, selectedAuthorities: sel });
                        setFieldValue("selectedAuthorities", sel);
                      }}
                    />
                    <ErrorMessage
                      name={`selectedAuthorities`}
                      component="div"
                      className="error-text-danger error-text-style"
                    />
                  </Grid>

                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    marginTop={"24px"}
                  >
                    <MOButton
                      submitType="submit"
                      title={!!selectedUser?.id ? "Update User" : "Add User"}
                      disabled={isSubmitting}
                    />
                  </Box>
                </Form>
              )}
            </Formik>
            {/* Formik Form handling end */}
          </div>
        </DialogContent>
      </Dialog>
      {!!failure ? (
        <ErrorSnack
          reset={() => reset()}
          errorMsg={failure?.message || failure}
        />
      ) : null}
      {!!success ? (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"User Created Successfully"}
        />
      ) : null}
      {!!response?.error && (
        <ErrorSnack errorMsg={response.error} reset={() => resetUpadte()} />
      )}
      {!!response?.success && (
        <ErrorSnack
          reset={() => resetUpadte()}
          success
          errorMsg={"User Updated Successfully"}
        />
      )}
    </div>
  );
};
const mapState = (state) => ({
  status: state,
  dialogIsOpen: state.orgUsers.dialog.isOpen,
  selectedUser: state.orgUsers.dialog.selectedUser,
  failure: state.customerUsers.createUser.error,
  success: state.customerUsers.createUser.success,
  response: state.customerUsers.updateCompleteUser,
});
const mapDispatch = {
  openDialog: Local.dialogActions.open,
  closeDialog: Local.dialogActions.close,
  postUser: CustomerUsers.createCustomerUser,
  getAllClientUsers: CustomerUsers.customerUsers,
  updateClientUser: CustomerUsers.updateCustomerUser,
  reset: Local.CreateCustomerUser.reset,
  resetUpadte: Local.UpdateCompleteCustomerUser.reset,
};
export default connect(mapState, mapDispatch)(AddUser);
