import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import UserIcon from "../../../assets/icons/auto-driver-user-icon.svg";
import ContactIcon from "../../../assets/icons/auto-contact-icon.svg";

import Breadcrumb from "../../../components/BreadCrumb";

import { AccessTime } from "@material-ui/icons";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import { connect } from "react-redux";
import mobioApi from "../../../services";
import { toastMessage } from "../../../helpers/toastMessage";

const Index = ({ token }) => {
  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();
  const formik = useRef();

  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },

    {
      name: `Manage ${airportIATA}`,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },
    {
      name: "Auto Driver Events",
      //  url:""
    },
  ];

  const getAutoDriverEvent = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getAutoDriverEvent(airportId);
      const getData = response?.data?.data;
      if (formik?.current && getData) {
        formik.current?.setFieldValue(
          "driverName",
          getData?.driverName?.toString()
        );
        formik.current?.setFieldValue("contact", getData?.contact?.toString());
        formik.current?.setFieldValue(
          "rideOption",
          getData?.rideOption?.toString()
        );
        formik.current?.setFieldValue(
          "arrivedOption",
          getData?.arrivedOption?.toString()
        );
        formik.current?.setFieldValue(
          "rideMints",
          getData?.rideMints?.toString()
        );
        formik.current?.setFieldValue("rideLng", getData?.rideLng?.toString());
        formik.current?.setFieldValue("rideLat", getData?.rideLat?.toString());
        formik.current?.setFieldValue(
          "arrivedMints",
          getData?.arrivedMints?.toString()
        );
        formik.current?.setFieldValue(
          "arrivedLng",
          getData?.arrivedLng?.toString()
        );
        formik.current?.setFieldValue(
          "arrivedLat",
          getData?.arrivedLat?.toString()
        );

        formik.current?.setFieldValue(
          "noShowMints",
          getData?.noShowMints?.toString()
        );
        formik.current?.setFieldValue(
          "noShowLng",
          getData?.noShowLng?.toString()
        );
        formik.current?.setFieldValue(
          "noShowLat",
          getData?.noShowLat?.toString()
        );
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAutoDriverEvent();
  }, []);

  return (
    <>
      <div>
        <div
          className="mt-18"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Breadcrumb
              paths={[
                {
                  name: "Auto Driver Events",
                  url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
                },
              ]}
            />
            <div className="breadcrumb-description">
              Need this section to set up Auto events for each location
            </div>
            <div className="mt-8 ml-6">
              <Breadcrumb paths={secondaryPaths} secondary={true} />
            </div>
          </div>
        </div>
        {/* body */}
        <div className="bg-white auto-driver-spacing-spacing  border-radius-8">
          {/* form */}

          <div className="">
            <Formik
              innerRef={formik}
              initialValues={{
                driverName: "",
                contact: "",
                rideOption: "flightLandingTime",
                arrivedOption: "flightLandingTime",
                rideMints: "",
                rideLng: "",
                rideLat: "",
                arrivedMints: "",
                arrivedLng: "",
                arrivedLat: "",
                noShowMints: "",
                noShowLng: "",
                noShowLat: "",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const payload = {
                    driverName: values.driverName,
                    contact: values.contact,
                    rideOption: values.rideOption,
                    rideMints: values.rideMints,
                    rideLng: values.rideLng,
                    rideLat: values.rideLat,
                    arrivedOption: values.arrivedOption,
                    arrivedMints: values.arrivedMints,
                    arrivedLng: values.arrivedLng,
                    arrivedLat: values.arrivedLat,
                    noShowMints: values.noShowMints,
                    noShowLng: values.noShowLng,
                    noShowLat: values.noShowLat,
                    airportId: airportId,
                  };

                  const api = mobioApi(token);
                  const response = await api.addAutoDriverEvent(payload);
                  toastMessage("success", response?.data?.msg);
                } catch (error) {
                  toastMessage("error", error.response?.data?.msg);
                }
                setSubmitting(false);
              }}
            >
              {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                  <div>
                    {/* Header */}
                    <h1 className="font-15 font-600 mb-16">Driver Details</h1>
                    <div className="flex gap-24">
                      <div className="auto-driver-event-driver-detail">
                        <img src={UserIcon} alt="UserIcon" />

                        <div>
                          <Field
                            type="text"
                            name="driverName"
                            placeholder="Jenny Wilson"
                          />
                        </div>
                        <ErrorMessage name="driverName" component="div" />
                      </div>

                      <div className="auto-driver-event-driver-detail ">
                        <img src={ContactIcon} alt="ContactIcon" />
                        <div>
                          <Field
                            type="text"
                            name="contact"
                            placeholder="+31 6463563"
                          />
                        </div>
                        <ErrorMessage name="name" component="div" />
                      </div>
                    </div>
                  </div>
                  {/* Setup Time */}
                  <div className="flex align-item-center mt-40 mb-20">
                    <AccessTime
                      style={{
                        marginRight: "14px",
                      }}
                    />
                    <p className="font-14 font-500">Setup Time</p>
                  </div>

                  <div>
                    {/* Ride Detail */}
                    <div className="box-shadow-all-3 auto-driver-field-spacing-spacing ">
                      <div className="flex gap-24 ">
                        <div className="flex-1">
                          <div className="input_field ">
                            <p className="text-gray font-16 font-500 mb-12">
                              Start Ride Option
                            </p>
                            <Field as="select" name="rideOption">
                              <option value="flightLandingTime">
                                Flight landing Time
                              </option>
                              <option value="customTime">Custom Time </option>
                            </Field>
                          </div>
                        </div>

                        <div className="flex-1">
                          <div className="input_field">
                            <p className="text-primary font-16 font-500 mb-12">
                              Start Ride: (Minutes)
                            </p>
                            <Field
                              type="number"
                              name="rideMints"
                              placeholder="10 minutes"
                              className="border-primary-color"
                            />
                            <ErrorMessage name="rideMints" component="div" />
                          </div>
                        </div>
                      </div>

                      <div className="flex gap-24 mt-16">
                        <div className="flex-1">
                          <div className="input_field ">
                            <p className="text-gray font-16 font-500 mb-12">
                              Longitude
                            </p>
                            <Field
                              type="text"
                              name="rideLng"
                              placeholder="435:378:4567"
                            />
                            <ErrorMessage name="rideLng" component="div" />
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="input_field ">
                            <p className="text-gray font-16 font-500 mb-12">
                              Latitude
                            </p>
                            <Field
                              type="text"
                              name="rideLat"
                              placeholder="435:378:4567"
                            />
                            <ErrorMessage name="rideLat" component="div" />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Arrived: */}
                    <div className="box-shadow-all-3 auto-driver-field-spacing-spacing mt-22">
                      <div className="flex gap-24 ">
                        <div className="flex-1">
                          <div className="input_field ">
                            <p className="text-gray font-16 font-500 mb-12">
                              Arrived Option
                            </p>
                            <Field as="select" name="arrivedOption">
                              <option value="flightLandingTime">
                                Flight landing Time
                              </option>
                              <option value="customTime">Custom Time </option>
                            </Field>
                          </div>
                        </div>

                        <div className="flex-1">
                          <div className="input_field">
                            <p
                              className="text-primary font-16 font-500 mb-12"
                              style={{
                                color: "#428702",
                              }}
                            >
                              Arrived: (Minutes)
                            </p>
                            <Field
                              type="number"
                              name="arrivedMints"
                              placeholder="15 Minutes"
                              style={{
                                borderColor: "#428702",
                              }}
                            />
                            <ErrorMessage name="arrivedMints" component="div" />
                          </div>
                        </div>
                      </div>

                      <div className="flex gap-24 mt-16">
                        <div className="flex-1">
                          <div className="input_field ">
                            <p className="text-gray font-16 font-500 mb-12">
                              Longitude
                            </p>
                            <Field
                              type="text"
                              name="arrivedLng"
                              placeholder="435:378:4567"
                            />
                            <ErrorMessage name="arrivedLng" component="div" />
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="input_field ">
                            <p className="text-gray font-16 font-500 mb-12">
                              Latitude
                            </p>
                            <Field
                              type="text"
                              name="arrivedLat"
                              placeholder="435:378:4567"
                            />
                            <ErrorMessage name="arrivedLat" component="div" />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* No Show: */}
                    <div className="box-shadow-all-3 auto-driver-field-spacing-spacing mt-22">
                      <div className="flex  gap-24">
                        <div className="flex-1">
                          <div className="input_field ">
                            <p
                              className="text-gray font-16 font-500 mb-12"
                              style={{
                                color: "#FC0808",
                              }}
                            >
                              No Show: (Minutes)
                            </p>
                            <Field
                              type="text"
                              name="noShowMints"
                              placeholder="10 Minutes"
                              style={{
                                borderColor: "#FC0808",
                              }}
                            />
                            <ErrorMessage name="noShowMints" component="div" />
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="input_field ">
                            <p className="text-gray font-16 font-500 mb-12">
                              Longitude
                            </p>
                            <Field
                              type="text"
                              name="noShowLng"
                              placeholder="435:378:4567"
                            />
                            <ErrorMessage name="noShowLng" component="div" />
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="input_field ">
                            <p className="text-gray font-16 font-500 mb-12">
                              Latitude
                            </p>
                            <Field
                              type="text"
                              name="noShowLat"
                              placeholder="435:378:4567"
                            />
                            <ErrorMessage name="noShowLat" component="div" />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* save and cancel button */}
                    <div className="flex align-item-center justify-content-end mt-24">
                      <div>
                        <MOButtonV2
                          type="submit"
                          text="SAVE AUTO DRIVER EVENTS"
                          textColor="#ffffff"
                          radius={4}
                          backgroundColor="#29AEE6"
                          height={40}
                          fontSize={11}
                          padding={"8.5px 63px"}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Index);
