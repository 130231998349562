import MOModalWrapper from "../../../../components/commons/MOModalWrapper";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { East } from "@mui/icons-material";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { getCountryCurrency } from "../../../../helpers/getCountryCurrency";
import { servicelevelValidationSchema } from "../../../../validations/rateModuleValidation";

const FixedRouteRateModal = ({
  modalOpen,
  setModalOpen,
  singleData,
  serviceLevelsList,
  addFixedRateApi,
  countryName,
}) => {
  const [countryCurrency, setCountryCurrency] = useState("");
  const { country } = useParams();

  const countryCurrencyHandle = async (countryName) => {
    const currency = await getCountryCurrency(countryName);
    setCountryCurrency(currency);
  };

  useEffect(() => {
    if (country || countryName) {
      countryCurrencyHandle(country || countryName);
    }
  }, [country]);
  return (
    <>
      <MOModalWrapper
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title={singleData?.data ? singleData?.data?.name : ""}
        className={"fr-modal-section"}
      >
        <div className="fr-modal-section-detail">
          <p className="m-0 font-14 font-500">
            Please include any airport pickup fees with this fixed price
          </p>
        </div>

        <Formik
          initialValues={{
            serviceLevelsFrom: serviceLevelsList.map((sl) => {
              const getPerviousData =
                singleData?.data?.associatedFixedRate.filter(
                  (sf) =>
                    sf?.fromAirport === true && sf?.serviceLevelId === sl?.id
                );
              return {
                id: sl.id,
                title: sl.name,
                checked:
                  getPerviousData?.length > 0
                    ? getPerviousData[0].status
                    : false,
                fromAirport: true,
                rate:
                  getPerviousData?.length > 0 ? getPerviousData[0].rate : "",
              };
            }),
            serviceLevelsTo: serviceLevelsList.map((sl) => {
              const getPerviousData =
                singleData?.data?.associatedFixedRate.filter(
                  (sf) =>
                    sf?.fromAirport === false && sf?.serviceLevelId === sl?.id
                );
              return {
                id: sl.id,
                title: sl.name,
                checked:
                  getPerviousData?.length > 0
                    ? getPerviousData[0].status
                    : false,
                fromAirport: false,
                rate:
                  getPerviousData?.length > 0 ? getPerviousData[0].rate : "",
              };
            }),
          }}
          validationSchema={servicelevelValidationSchema}
          onSubmit={(values) => {
            const combineData = [
              ...values.serviceLevelsFrom,
              ...values.serviceLevelsTo,
            ];
            const payload = combineData
              ?.map((cd) => {
                if (cd.rate) {
                  return {
                    serviceLevelId: cd?.id,
                    airportId: singleData?.data?.airportId,
                    polygonId: singleData?.data?.id,
                    fromAirport: cd?.fromAirport,
                    rate: cd?.rate,
                    status: cd?.checked,
                  };
                }
              })
              .filter((item) => item);

            addFixedRateApi(payload);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="fr-model-section-rate-form-section">
                {/* from->to side */}
                <div className="flex-1 rate-form-section">
                  <div className="flex align-item-center gap-8 mb-16">
                    <p className="m-0 font-16 font-600">{singleData?.iata}</p>
                    <East />
                    <p className="m-0 font-16 font-600">
                      {singleData?.data?.name}
                    </p>
                  </div>
                  {values.serviceLevelsFrom.map((sl, index) => (
                    <div
                      className="fr-model-section-rate-section mb-8"
                      key={sl.id}
                    >
                      <div className="fr-model-section-service-field">
                        <div className="flex align-item-start">
                          <Field
                            type="checkbox"
                            name={`serviceLevelsFrom.${index}.checked`}
                            checked={sl.checked}
                            onChange={() =>
                              setFieldValue(
                                `serviceLevelsFrom.${index}.checked`,
                                !sl.checked
                              )
                            }
                          />
                          <label htmlFor={`serviceLevelsTo.${index}.checked`}>
                            {sl.title}
                          </label>
                        </div>
                      </div>
                      <div>
                        <div className="fr-model-section-rate-field">
                          <div className="fr-model-section-rate-field-pre-fix">
                            {/* <p className="m-0">EUR</p> */}
                            <p className="m-0">{countryCurrency}</p>
                          </div>
                          <div>
                            <Field
                              type="number"
                              name={`serviceLevelsFrom.${index}.rate`}
                              value={sl.rate}
                              onChange={(e) =>
                                setFieldValue(
                                  `serviceLevelsFrom.${index}.rate`,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          style={{
                            color: "red",
                            fontSize: "10px",
                          }}
                          name={`serviceLevelsFrom.${index}.rate`}
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                  ))}
                </div>

                {/* to->from side */}
                <div className="flex-1 pl-16 rate-to-section">
                  <div className="flex align-item-center gap-8 mb-16">
                    <p className="m-0 font-16 font-600">
                      {singleData?.data?.name}
                    </p>
                    <East />
                    <p className="m-0 font-16 font-600">{singleData?.iata}</p>
                  </div>
                  {values.serviceLevelsTo.map((sl, index) => (
                    <div
                      className="fr-model-section-rate-section mb-8"
                      key={sl.id}
                    >
                      <div className="fr-model-section-service-field">
                        <div className="flex align-item-start">
                          <Field
                            type="checkbox"
                            name={`serviceLevelsTo.${index}.checked`}
                            checked={sl.checked}
                            onChange={() =>
                              setFieldValue(
                                `serviceLevelsTo.${index}.checked`,
                                !sl.checked
                              )
                            }
                          />
                          <label htmlFor={`serviceLevelsTo.${index}.checked`}>
                            {sl.title}
                          </label>
                        </div>
                      </div>
                      <div>
                        <div className="fr-model-section-rate-field">
                          <div className="fr-model-section-rate-field-pre-fix">
                            {/* <p className="m-0">EUR</p> */}
                            <p className="m-0">{countryCurrency}</p>
                          </div>
                          <div>
                            <Field
                              type="number"
                              step="0.01"
                              name={`serviceLevelsTo.${index}.rate`}
                              value={sl.rate}
                              onChange={(e) =>
                                setFieldValue(
                                  `serviceLevelsTo.${index}.rate`,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          style={{
                            color: "red",
                            fontSize: "10px",
                          }}
                          name={`serviceLevelsTo.${index}.rate`}
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* save and cancel button */}
              <div className="flex align-item-center justify-content-end mt-24 mb-40">
                <div className="mr-20">
                  <MOButtonV2
                    text="Save"
                    fontSize={12}
                    padding={"10px 35px"}
                    textColor="#ffffff"
                    radius={8}
                    backgroundColor="#29AEE6"
                    height={32}
                    type="submit"
                  />
                </div>
                <div>
                  <MOButtonV2
                    text="Cancel"
                    textColor="#1D2433"
                    radius={8}
                    fontSize={12}
                    backgroundColor="#ffffff"
                    padding={"10px 28px"}
                    border={"1px solid #E1E6EF"}
                    height={32}
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </MOModalWrapper>
    </>
  );
};

export default FixedRouteRateModal;
