import { Box, Card, CardContent, Divider, Typography } from "@material-ui/core";
import React from "react";
import {
  DropPointIcon,
  FlightIcon,
  PickPointIcon,
} from "../../../assets/icons";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  flightCardDetailSection: {
    display: "flex",
    padding: "4px 10px",
  },
  flightNoTitle: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#00000080",
  },
  flightDetailTitle: {
    fontSize: "14px",
    fontWeight: "600",
  },
  locationTitle: {
    fontSize: "12px",
    fontWeight: "500",
    color: "#BDBDBD",
    lineHeight: "0.8",
  },
  locationSubTitle: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#000000",
  },
}));
const FlightCardDetail = (props) => {
  const { data } = props;
  const classes = useStyles();
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box>
        <Card
          style={{
            boxShadow:
              data?.driver_events?.findIndex(
                (ef) => ef.eventName === "DRIVER_SUBMITTED_CUSTOMER_NO_SHOW"
              ) >= 0
                ? "1px 3px 3px 2px #F42A4161"
                : "1px 3px 3px 2px #ECFDF3",
            borderRadius: "10px",
            border:
              data?.driver_events?.findIndex(
                (ef) => ef.eventName === "DRIVER_SUBMITTED_CUSTOMER_NO_SHOW"
              ) >= 0
                ? "2px solid #F42A41"
                : "2px solid #54A753",
          }}
        >
          <CardContent
            className={classes.flightCardDetailSection}
            style={{ paddingBottom: "4px" }}
          >
            <FlightIcon style={{ marginTop: "4px", marginRight: "4px" }} />
            <Box>
              <Typography className={classes.flightNoTitle}>
                Flight No./Time
              </Typography>
              <Typography className={classes.flightDetailTitle}>
                {data?.flightNumber} {data?.estimatedIn ? `- ${moment(data.estimatedIn).format("HH:mm")}` : ""}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ position: "relative", width: "245px" }}>
        <Box sx={{ display: "flex", marginBottom: "24px" }}>
          <PickPointIcon style={{ flexShrink: 0 }} />
          <Box sx={{ marginLeft: "10px" }}>
            <Typography className={classes.locationTitle}>
              Pickup Point
            </Typography>
            <Typography className={classes.locationSubTitle}>
              {/* Amsterdam Central Station */}
              {data?.pickup?.name}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            borderLeft: "2px solid #000000",
            // height: "27px",
            minHeight: "27px",
            position: "absolute",
            top: "26px",
            left: "12px",
          }}
        />
        <Box sx={{ display: "flex" }}>
          <DropPointIcon style={{ flexShrink: 0 }} />
          <Box sx={{ marginLeft: "10px" }}>
            <Typography className={classes.locationTitle}>
              Drop Point
            </Typography>
            <Typography className={classes.locationSubTitle}>
              {/* Amsterdam Hotel */}
              {data?.destination?.name}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FlightCardDetail;
