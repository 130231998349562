import { createTheme } from "@material-ui/core/styles";
import colors from "./colors";

const theme = createTheme({
  palette: {
    primary: {
      light: colors.blue.light,
      dark: colors.blue.dark,
      main: colors.blue.main,
    },

    secondary: {
      // light: will be calculated from palette.primary.main,
      main: "#C0C0C0",
    },

    //  secondary: {},
    // error: {},
    // warning: {},
    // info: {},
    // success: {},
    // grey: {},
    // text: {},
    // background: {},
    // action: {},
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    h1: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "3.375rem",
      lineHeight: 1.4,
    },
    h2: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "1.875rem",
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "1.5rem",
      lineHeight: 1.2,
    },
  },
});

/* responsive typography */
theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.up("xl")]: {
    fontSize: "2.5rem",
  },
};

theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.up("xl")]: {
    fontSize: "4rem",
  },
};

export default theme;
