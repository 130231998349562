import { Box, TextField, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { CallRingIcon, MailIcon, WhatsUpIcon } from "../../../assets/icons";
import MOButton from "../../../components/commons/MOButton";

const useStyles = makeStyles({
  mainContainer: {
    position: "relative",
    backgroundColor: "#FFFFFF",
    padding: "30px 21px",
    marginTop: 12,
    // display: "flex",
    // gap: "12px",
    // flexWrap: "wrap",
    width: "100%",
    // minHeight: "calc(100vh - 140px)",
  },
  innerContainer: {
    // width: 632,
    width: "100%",
    // backgroundColor: "#00000005",
    padding: "24px 24px",
  },
  titleHeading: {
    fontSize: 24,
    fontWeight: 700,
    width: "100%",
  },
  infoDetailSection: { display: "flex", marginTop: 41 },
  infoDetailTitle: { fontSize: 13, fontWeight: 400, color: "#BDBDBD" },
  infoDetailLink: { fontSize: 13, fontWeight: 400, color: "#000000  " },
  messageSendContainer: {
    // position: "absolute",
    // top: 106,
    // left: 328,
    width: 676,
    height: 582,
    backgroundColor: "#FFFFFF ",
    boxShadow: "4px 4px 4px 3px #E0E0E0",
    padding: "32px 66px 89px 66px",
    alignSelf: "center",
  },
  messageSendHeading: {
    fontSize: 36,
    fontWeight: 700,
    color: "#29AEE6",
    textAlign: "center",
    marginBottom: 57,
  },
});
const Index = () => {
  const classes = useStyles();
  return (
    <div className="customer-contact-us-section">
      <Box className={classes.mainContainer}>
        <Box
          style={{
            background: "linear-gradient(90deg,#00000005 50%, #FFFFFF 50%)",
            minHeight: "calc(100vh - 220px)",

            justifyContent: "space-between",
            padding: "30px",
          }}
        >
          <Typography className={classes.titleHeading}>
            You Curious To Know About Us
          </Typography>
          <Box
            style={{
              display: "flex",
              height: "100%",
              // flexWrap: "wrap",
              // border: "1px solid red",
            }}
          >
            <Box className={classes.innerContainer}>
              {/* <Typography className={classes.titleHeading}>
              You Curious To Know About Us
            </Typography> */}
              <Box style={{ marginTop: 71 }}>
                <Typography className={classes.titleHeading}>Info</Typography>
                {/* mail */}
                <Box className={classes.infoDetailSection}>
                  <Box>
                    <MailIcon />
                  </Box>
                  <Box style={{ marginLeft: 13 }}>
                    <Typography className={classes.infoDetailTitle}>
                      EMAIL
                    </Typography>
                    <Typography className={classes.infoDetailLink}>
                      Mobio.com
                    </Typography>
                  </Box>
                </Box>
                {/* Phone */}
                <Box className={classes.infoDetailSection}>
                  <Box>
                    <CallRingIcon />
                  </Box>
                  <Box style={{ marginLeft: 13 }}>
                    <Typography className={classes.infoDetailTitle}>
                      PHONE
                    </Typography>
                    <Typography className={classes.infoDetailLink}>
                      0354321234
                    </Typography>
                  </Box>
                </Box>
                {/* What's up */}
                <Box className={classes.infoDetailSection}>
                  <Box>
                    <WhatsUpIcon />
                  </Box>
                  <Box style={{ marginLeft: 13 }}>
                    <Typography className={classes.infoDetailTitle}>
                      whatsapp
                    </Typography>
                    <Typography className={classes.infoDetailLink}>
                      hhtp//:whatsapplink{" "}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* Get In Touch */}
            <Box className={classes.messageSendContainer}>
              <Typography className={classes.messageSendHeading}>
                Get In Touch
              </Typography>
              <TextField
                multiline={true}
                rows={12}
                placeholder="Message to Us"
                autoComplete="off"
                variant="outlined"
                // value={description}
                // onChange={e => setDescription(e.target.value)}
              />
              <Box style={{ textAlign: "right", marginTop: 53 }}>
                <MOButton icon={false} title="Save" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Index;
