import {
  CUSTOMERS,
  ADD_CUSTOMER,
  CUSTOMER_DISPATCHER,
} from "../constants/customers";

export type CustomersRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
  };
};

export type AddCustomerRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
};
export type CustomerDispatchersRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
  };
};

const CustomerDispatchers: CustomerDispatchersRepo = {
  api: {
    request: () => ({
      type: CUSTOMER_DISPATCHER.CUSTOMER_DISPATCHER_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: CUSTOMER_DISPATCHER.CUSTOMER_DISPATCHER_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: CUSTOMER_DISPATCHER.CUSTOMER_DISPATCHER_API.SET_RESPONSE,
      payload: { data },
    }),
  },
};

const Customers: CustomersRepo = {
  api: {
    request: () => ({
      type: CUSTOMERS.CUSTOMERS_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: CUSTOMERS.CUSTOMERS_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: CUSTOMERS.CUSTOMERS_API.SET_RESPONSE,
      payload: { data },
    }),
  },
};

const AddCustomer: AddCustomerRepo = {
  api: {
    request: () => ({
      type: ADD_CUSTOMER.ADD_CUSTOMER_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: ADD_CUSTOMER.ADD_CUSTOMER_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: ADD_CUSTOMER.ADD_CUSTOMER_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: ADD_CUSTOMER.ADD_CUSTOMER_API.RESET,
      payload: {},
    }),
  },
};

export { Customers, AddCustomer, CustomerDispatchers };
