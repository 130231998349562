import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import { Add, CloudUploadOutlined } from "@material-ui/icons";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router";
import mobioApi from "../../../services";
import { connect } from "react-redux";
import { toastMessage } from "../../../helpers/toastMessage";
import { formatDate, removeLast00 } from "../../../helpers/getTimeFormat";
import { pickupInstructionsValidationSchema } from "../../../validations/rateModuleValidation";
import ClockTimer from "../../../components/commons/ClockTimer";
import DatePickerField from "../../../components/commons/DatePickerField";

const selectDate = [
  { value: "24/7", label: "24/7" },
  { value: "dd/mm/yy", label: "dd/mm/yy" },
];

const AddPickupInstruction = ({ token }) => {
  const navigate = useNavigate();
  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();
  const updateDataState = useLocation();
  const formik = useRef();
  const fileInputRef = useRef(null);
  const [preview, setPreview] = useState(null);
  const customFormatOptionLabel = (option, { context, selectValue }) => {
    const isSelected = selectValue.some(
      (selectedOption) => selectedOption.value === option.value
    );
    return (
      <>
        {context === "menu" && (
          <div className="flex mb-6">
            <input type="radio" checked={isSelected} readOnly />
            <p className="font-14 m-0">{option.label}</p>
          </div>
        )}
        {context === "value" && (
          <>
            {option.label === "dd/mm/yy" ? (
              <>
                {/* <div className="date-picker-selection ">
              

                  <label htmlFor="date">

                  <input
                      value={formatDate(formik?.current?.values?.date)}
                      type="date"
                      id="date"
                      style={{
                        border: "none",
                        outline: "none",
                        fontSize: "12px",
                        fontWeight: "400",
                        backgroundColor: "transparent",
                      }}
                      onChange={(e) =>
                        formik?.current?.setFieldValue("date", e.target.value)
                      }
                    />
                  </label>
                </div> */}
              </>
            ) : (
              <>
                <span className="font-12 font-400">{option.label}</span>
              </>
            )}
          </>
        )}
      </>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "0.5px solid #000" : "0.5px solid #000",
      // boxShadow: state.isFocused ? "0 0 0 1px #000" : provided.boxShadow,
      borderWidth: "0px",
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        // border: "1px solid #000",
      },
      height: "34px",
      minHeight: "34px",
    }),
    menu: (provided) => ({
      ...provided,
      // height: "200px",
      // overflowY: "auto",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "1px 6px",

      backgroundColor: state.isSelected ? "#fff" : "#fff",
      color: state.isSelected ? "#000" : "#000",
      "&:hover": {
        backgroundColor: state.isSelected ? "#e9ecef" : "#e9ecef",
        color: state.isSelected ? "#000" : "#000",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "16px",
      color: "#495057",
    }),
  };
  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },

    {
      name: `Manage ${airportIATA}`,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },

    {
      name: "Pickup Instruction",
      // url: "/dashboard/integration/rates/mange-location",
    },
  ];

  const uploadImageToAWS = (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("image", file);
        const api = mobioApi(token);
        const response = await api.upload(formData);
        resolve(response.data.url);
      } catch (error) {
        toastMessage("error", "Error in uploading file");
        reject(error);
      }
    });
  };

  const settingTheFormikData = (formikData) => {
    if (formik?.current) {
      formik.current.setFieldValue("id", formikData?.id || "");
      formik.current.setFieldValue("name", formikData?.name || "");
      formik.current.setFieldValue(
        "startTime",
        formikData?.startTime ? removeLast00(formikData?.startTime) : ""
      );
      formik.current.setFieldValue(
        "endTime",
        formikData?.endTime ? removeLast00(formikData?.endTime) : ""
      );
      formik.current.setFieldValue(
        "instruction",
        formikData?.instruction || ""
      );
      formik.current.setFieldValue(
        "internationalArrival",
        formikData?.internationalArrival || ""
      );
      formik.current.setFieldValue(
        "domesticArrival",
        formikData?.domesticArrival || ""
      );
      formik.current.setFieldValue("pickupType", formikData?.pickupType || "");
      if (formikData?.instructionsMeetingPoint?.length > 0) {
        formik.current.setFieldValue(
          "meetingPoints",
          formikData?.instructionsMeetingPoint
        );
      }
      if (formikData?.imageUrl) {
        setPreview(formikData?.imageUrl);
      }
      if (formikData?.twentyFourSeven) {
        formik.current.setFieldValue("selectDate", {
          value: "24/7",
          label: "24/7",
        });
      } else {
        formik.current.setFieldValue("selectDate", {
          value: "dd/mm/yy",
          label: "dd/mm/yy",
        });
        // formik.current.setFieldValue("date", formikData?.selectDate || "");
        formik.current.setFieldValue(
          "date",
          new Date(formikData?.selectDate) || ""
        );
      }
    }
  };

  useEffect(() => {
    if (updateDataState.state?.status === "edit") {
      settingTheFormikData(updateDataState.state?.pickupInstructionData);
    }
  }, []);

  return (
    <>
      <div>
        <div className="mt-18 flex justify-content-between">
          <div>
            <Breadcrumb
              paths={[
                {
                  name: "Pickup Instruction ",
                  url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
                },
              ]}
            />
            <div className="breadcrumb-description">
              Dispatchers can be assigned to bookings and use the driver app
            </div>
            <div className="mt-12 ml-6">
              <Breadcrumb paths={secondaryPaths} secondary={true} />
            </div>
          </div>
          {/* <div className="flex">
            <MOButtonV2
              text="ADD INSTRUCTION"
              textColor="#ffffff"
              radius={4}
              backgroundColor="#29AEE6"
              height={30}
              fontSize={11}
              padding={"8.5px 30.5px"}
              onClick={() => {}}
            />
          </div> */}
        </div>
        <div className="bg-white  mt-20 border-radius-8 main-section-spacing-sm">
          {/*  */}
          <Formik
            innerRef={formik}
            initialValues={{
              name: "",
              startTime: "",
              endTime: "",
              selectDate: "",
              instruction: "",
              file: null,
              internationalArrival: "",
              domesticArrival: "",
              pickupType: "",
              meetingPoints: [{ terminal: "", location: "" }],
              date: new Date(),
              id: "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const payload = {
                  name: values.name,
                  startTime: values.startTime,
                  endTime: values.endTime,
                  // selectDate: values.selectDate?.value === "24/7"?"24/7":values.date,
                  internationalArrival: values.internationalArrival,
                  domesticArrival: values.domesticArrival,
                  pickupType: values.pickupType,
                  instruction: values.instruction,
                  airportId: airportId,
                  meetingPoint: values.meetingPoints,
                };
                if (values.selectDate?.value === "24/7") {
                  payload.twentyFourSeven = true;
                } else if (values.selectDate?.value === "dd/mm/yy") {
                  payload.twentyFourSeven = false;
                  payload.selectDate = values.date;
                }

                if (values?.file) {
                  const imageUrl = await uploadImageToAWS(values.file);
                  payload.imageUrl = imageUrl;
                }
                let response = null;
                const api = mobioApi(token);
                if (updateDataState?.state?.status === "edit") {
                  payload.id = values?.id;
                  response = await api.updatePickupInstruction(payload);
                } else {
                  response = await api.addPickupInstructions(payload);
                }

                toastMessage("success", response?.data?.msg);
                navigate(-1);
              } catch (error) {
                toastMessage("error", error.response?.data?.msg);
              } finally {
                setSubmitting(false);
              }
            }}
            validationSchema={pickupInstructionsValidationSchema}
          >
            {({ values, setFieldValue, isSubmitting, handleChange }) => (
              <Form>
                <div>
                  <div className="pickup-instruction-main-section">
                    {/* left */}
                    <div className="flex-1 pickup-instruction-left-side">
                      {/* Instruction Name */}
                      <div className="pick-up-instruction-name">
                        <h1 className="font-17 font-600 m-0">
                          Instruction Name
                        </h1>
                        <p className="m-0 font-13 font-400 mt-8">
                          Please enter your proposed name of instruction
                        </p>
                        <Field
                          type="text"
                          name="name"
                          placeholder="Enter Pick Up Instruction Name"
                        />
                        <ErrorMessage
                          name="name"
                          component="p"
                          className="text-danger font-10"
                        />
                      </div>
                      {/* Time Frame */}
                      <h1 className="font-17 font-600 m-0">Time Frame</h1>
                      <p className="m-0 font-13 font-400 mt-8">
                        Please select your time frame{" "}
                      </p>

                      <div
                        className="flex align-item-end mt-24"
                        style={{
                          borderBottom: "1px solid #8E8E9366",
                          paddingBottom: "37px",
                        }}
                      >
                        {/* Start time */}
                        <div className="input_fiel w-142 ">
                          <p className=" m-0 font-400 font-12 mb-6">
                            Start time
                          </p>
                          {/* <Field type="time" name="startTime" className="" /> */}

                          <Field
                            name="startTime"
                            render={({ field, form }) => {
                              return (
                                <ClockTimer
                                  field={field}
                                  form={form}
                                  style={{
                                    height: "32px",
                                  }}
                                  iconStyle={{
                                    height: "16px",
                                    width: "16px",
                                    cursor: "pointer",
                                    marginRight: "4px",
                                  }}
                                />
                              );
                            }}
                          />
                          <ErrorMessage
                            name="startTime"
                            component="p"
                            className="text-danger font-10"
                          />
                        </div>
                        {/* End Time */}
                        <div className="input_fiel w-142 ml-6">
                          <p className=" m-0 font-400 font-12 mb-6">End time</p>
                          {/* <Field type="time" name="endTime" /> */}
                          <Field
                            name="endTime"
                            render={({ field, form }) => {
                              return (
                                <ClockTimer
                                  field={field}
                                  form={form}
                                  style={{
                                    height: "32px",
                                  }}
                                  iconStyle={{
                                    height: "16px",
                                    width: "16px",
                                    cursor: "pointer",
                                    marginRight: "4px",
                                  }}
                                />
                              );
                            }}
                          />
                          <ErrorMessage
                            name="endTime"
                            component="p"
                            className="text-danger font-10"
                          />
                        </div>
                        {/* Date */}
                        <div className="w-142 ml-16">
                          <p className="m-0 font-400 font-12 mb-6">
                            Select Date
                          </p>
                          <Field name="selectDate">
                            {({ field, form }) => (
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <Select
                                  {...field}
                                  options={selectDate}
                                  placeholder="Select"
                                  formatOptionLabel={customFormatOptionLabel}
                                  styles={customStyles}
                                  isSearchable={false}
                                  className="pickup-instruction-select-box"
                                  onChange={(option) =>
                                    setFieldValue("selectDate", option)
                                  }
                                />
                                {form.values.selectDate?.value ===
                                  "dd/mm/yy" && (
                                  <div className="date-picker-selection-v2">
                                    <DatePickerField
                                      placeholder="Select Date"
                                      name="date"
                                      value={form?.values?.date}
                                      onChange={(value) =>
                                        form.setFieldValue("date", value)
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </Field>
                          <ErrorMessage
                            name="selectDate"
                            component="p"
                            className="text-danger font-10"
                          />
                        </div>
                      </div>

                      <div className="mt-22">
                        <h3 className="m-0 font-17 font-600">
                          When will your driver arrive for international
                          arrivals?
                        </h3>
                        <div className="mt-20">
                          <div>
                            <label>
                              <Field
                                type="radio"
                                name="internationalArrival"
                                value="whenPlaneLands"
                                checked={
                                  values.internationalArrival ===
                                  "whenPlaneLands"
                                }
                                onChange={handleChange}
                              />
                              <span
                                className={`font-15 font-400 ml-10 ${
                                  values.internationalArrival !==
                                    "whenPlaneLands" && "text-black-gray"
                                }`}
                              >
                                When the plane lands
                              </span>
                            </label>
                          </div>
                          <div className="mt-12">
                            <label>
                              <Field
                                type="radio"
                                name="internationalArrival"
                                value="afterPlaneLands"
                                checked={
                                  values.internationalArrival ===
                                  "afterPlaneLands"
                                }
                                onChange={handleChange}
                              />
                              <span
                                className={`font-15 font-400 ml-10 ${
                                  values.internationalArrival !==
                                    "afterPlaneLands" && "text-black-gray"
                                }`}
                              >
                                {" "}
                                After the plane lands
                              </span>
                            </label>
                          </div>
                          <ErrorMessage
                            name="internationalArrival"
                            component="p"
                            className="text-danger font-10"
                          />
                        </div>
                      </div>

                      <div
                        className="mt-47"
                        style={{
                          borderBottom: "1px solid #0000002E",
                          paddingBottom: "29px",
                        }}
                      >
                        <h3 className="m-0 font-17 font-600">
                          When will your driver arrive for domestic arrivals?
                        </h3>
                        <div className="mt-20">
                          <div>
                            {" "}
                            <label>
                              <Field
                                type="radio"
                                name="domesticArrival"
                                value="whenPlaneLands"
                                checked={
                                  values.domesticArrival === "whenPlaneLands"
                                }
                                onChange={handleChange}
                              />
                              <span
                                className={`font-15 font-400 ml-10 ${
                                  values.domesticArrival !== "whenPlaneLands" &&
                                  "text-black-gray"
                                }`}
                              >
                                When the plane lands
                              </span>
                            </label>
                          </div>
                          <div className="mt-12">
                            <label>
                              <Field
                                type="radio"
                                name="domesticArrival"
                                value="afterPlaneLands"
                                checked={
                                  values.domesticArrival === "afterPlaneLands"
                                }
                                onChange={handleChange}
                              />
                              <span
                                className={`font-15 font-400 ml-10 ${
                                  values.domesticArrival !==
                                    "afterPlaneLands" && "text-black-gray"
                                }`}
                              >
                                After the plane lands
                              </span>
                            </label>
                          </div>
                        </div>
                        <ErrorMessage
                          name="domesticArrival"
                          component="p"
                          className="text-danger font-10"
                        />
                      </div>

                      {/*  */}
                      <div className="mt-24">
                        <h2 className="m-0 font-16 font-600">
                          Where will the driver meet the passenger? What is the
                          timeframe?
                        </h2>
                        <p className="m-0 mt-8 font-12 font-400 text-black-gray-100">
                          Please provide all instructions in English as they
                          will be translated into multiple languages.
                        </p>

                        <FieldArray name="meetingPoints">
                          {({ push, remove }) => (
                            <div>
                              {values.meetingPoints.map(
                                (meetingPoint, index) => (
                                  <div
                                    key={index}
                                    className="pickup-ins-meeting-point-section"
                                  >
                                    <div className="meeting-point-card">
                                      <h3 className="m-0 font-13 font-600">
                                        Meeting Point {index + 1}
                                      </h3>
                                      <div className="mt-20">
                                        <div className="flex justify-content-between align-item-center">
                                          <p className="m-0 font-12 font-500">
                                            Terminal
                                          </p>
                                          <p className="m-0 font-11 font-400">
                                            {meetingPoint.terminal.length}/140
                                          </p>
                                        </div>
                                        <div className="mt-8">
                                          <Field
                                            type="text"
                                            name={`meetingPoints[${index}].terminal`}
                                            value={meetingPoint.terminal}
                                            onChange={(e) => {
                                              if (
                                                e.target.value.length <= 140
                                              ) {
                                                handleChange(e);
                                              }
                                            }}
                                          />
                                          <ErrorMessage
                                            name={`meetingPoints[${index}].terminal`}
                                            component="p"
                                            className="text-danger font-10"
                                          />
                                        </div>
                                      </div>
                                      <div className="mt-20">
                                        <div className="flex justify-content-between align-item-center">
                                          <p className="m-0 font-12 font-500">
                                            Location
                                          </p>
                                          <p className="m-0 font-11 font-400">
                                            {meetingPoint.location?.length}
                                            /140
                                          </p>
                                        </div>
                                        <div className="mt-8">
                                          <Field
                                            type="text"
                                            name={`meetingPoints[${index}].location`}
                                            value={meetingPoint.location}
                                            onChange={(e) => {
                                              if (
                                                e.target.value.length <= 140
                                              ) {
                                                handleChange(e);
                                              }
                                            }}
                                          />
                                          <ErrorMessage
                                            name={`meetingPoints[${index}].location`}
                                            component="p"
                                            className="text-danger font-10"
                                          />
                                        </div>
                                      </div>
                                      {index > 0 && (
                                        <div className="mt-12">
                                          <p
                                            className="m-0 font-13 font-600 text-primary cursor-pointer"
                                            onClick={() => remove(index)}
                                          >
                                            - Remove this meeting point
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                              <div className="mt-12">
                                <p
                                  className="m-0 font-13 font-600 text-primary cursor-pointer"
                                  onClick={() =>
                                    push({ terminal: "", location: "" })
                                  }
                                >
                                  + Add another meeting point
                                </p>
                              </div>
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                    {/* right */}

                    <div className="flex-1 pickup-instruction-right-side">
                      <div
                        style={{
                          borderBottom: "1px solid #0000002E",
                          paddingBottom: "29px",
                        }}
                      >
                        <h3 className="m-0 font-17 font-600">
                          What is the pickup type?
                        </h3>
                        <p className="m-0 font-13 font-400 text-black-gray-100 mt-8">
                          Based on the pickup type you select, the customer will
                          receive the relevant instructions to find their
                          driver.
                        </p>
                        <div>
                          <div className="mt-20">
                            <label>
                              <div className="flex">
                                <div>
                                  <Field
                                    type="radio"
                                    name="pickupType"
                                    value="meetAndGreet"
                                    checked={
                                      values.pickupType === "meetAndGreet"
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="ml-10">
                                  <p
                                    className={`m-0 font-15 font-400 ${
                                      values.pickupType !== "meetAndGreet" &&
                                      "text-black-gray-100"
                                    }`}
                                  >
                                    Meet and greet
                                  </p>
                                  <p className="m-0 font-11 font-400 text-black-gray-100">
                                    Look out for your driver. They will be
                                    holding a sign with your name on it.
                                  </p>
                                </div>
                              </div>
                            </label>
                          </div>
                          <div className="mt-18">
                            <label>
                              <div className="flex">
                                <div>
                                  <Field
                                    type="radio"
                                    name="pickupType"
                                    value="desk"
                                    checked={values.pickupType === "desk"}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="ml-10">
                                  <p
                                    className={`m-0 font-15 font-400 ${
                                      values.pickupType !== "desk" &&
                                      "text-black-gray-100"
                                    }`}
                                  >
                                    Desk
                                  </p>
                                  <p className="m-0 font-11 font-400 text-black-gray-100">
                                    At the counter, give your booking reference
                                    to a member of staff, who will then guide
                                    you to your driver.
                                  </p>
                                </div>
                              </div>
                            </label>
                          </div>
                          <div className="mt-18">
                            <label>
                              <div className="flex">
                                <div>
                                  <Field
                                    type="radio"
                                    name="pickupType"
                                    value="host"
                                    checked={values.pickupType === "host"}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="ml-10">
                                  <p
                                    className={`m-0 font-15 font-400 ${
                                      values.pickupType !== "host" &&
                                      "text-black-gray-100"
                                    }`}
                                  >
                                    Host
                                  </p>
                                  <p className="m-0 font-11 font-400 text-black-gray-100">
                                    At the meeting point, you will be greeted by
                                    a representative. They will guide you to
                                    your driver.
                                  </p>
                                </div>
                              </div>
                            </label>
                          </div>
                          <div className="mt-18">
                            <label>
                              <div className="flex">
                                <div>
                                  <Field
                                    type="radio"
                                    name="pickupType"
                                    value="curbside"
                                    checked={values.pickupType === "curbside"}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="ml-10">
                                  <p
                                    className={`m-0 font-15 font-400 ${
                                      values.pickupType !== "curbside" &&
                                      "text-black-gray-100"
                                    }`}
                                  >
                                    Curbside
                                  </p>
                                  <p className="m-0 font-11 font-400 text-black-gray-100">
                                    Please call your driver to let them know
                                    you're ready to be picked up. The driver
                                    will let you know where to meet them.
                                  </p>
                                </div>
                              </div>
                            </label>
                          </div>
                          <ErrorMessage
                            name={`pickupType`}
                            component="p"
                            className="text-danger font-10"
                          />
                        </div>
                      </div>

                      {/*  */}
                      <div
                        className="mt-28"
                        style={{
                          borderBottom: "1px solid #0000002E",
                          paddingBottom: "29px",
                        }}
                      >
                        <h3 className="m-0 font-17 font-600">
                          Are there any special instructions?
                        </h3>
                        <p className="m-0 font-13 font-400 text-black-gray-100 mt-8">
                          If there are any special instructions you’d like to
                          share with the customer, you can add them as an outro.
                          Please don’t include any supplier contact details or
                          directions.
                        </p>

                        <div className=" mt-10">
                          <p className="m-0 font-11 font-400 text-align-right">
                            {values.instruction.length}/250
                          </p>
                          <div className="input_field mt-10">
                            <textarea
                              rows={6}
                              name="instruction"
                              value={values.instruction}
                              onChange={(e) => {
                                setFieldValue("instruction", e.target.value);
                              }}
                              style={{ width: "100%" }}
                              placeholder="Write your instructions here "
                            />
                            <ErrorMessage
                              name="instruction"
                              component="p"
                              className="text-danger font-10"
                            />
                          </div>
                        </div>
                      </div>

                      {/*  */}
                      <div className="mt-22">
                        <h3 className="m-0 font-17 font-600">
                          Upload the Image
                        </h3>
                        <p className="m-0 font-13 font-400 text-black-gray-100 mt-6">
                          Tell more the pickup instruction with image
                        </p>
                        <div className="flex align-item-center">
                          <div className="add-pickup-instruction-image mt-16">
                            {/* show image if uploaded */}
                            {preview ? (
                              <div className="add-pickup-instruction-image-preview">
                                <img src={preview} alt="Preview" />
                              </div>
                            ) : (
                              <>
                                <CloudUploadOutlined
                                  style={{
                                    color: "#00000066",
                                    fontSize: "44px",
                                  }}
                                />
                                <div>
                                  <input
                                    id="file"
                                    name="file"
                                    type="file"
                                    accept="image/jpeg,image/png"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    onChange={(event) => {
                                      const file = event.currentTarget.files[0];
                                      setFieldValue("file", file);
                                      const reader = new FileReader();
                                      reader.onloadend = () => {
                                        setPreview(reader.result);
                                      };
                                      reader.readAsDataURL(file);
                                    }}
                                  />
                                  <label htmlFor="file">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        document.getElementById("file").click()
                                      }
                                      // onClick={() =>
                                      //   fileInputRef.current.click()
                                      // }
                                    >
                                      Upload Image
                                    </button>
                                  </label>
                                </div>
                              </>
                            )}
                          </div>
                          <div>
                            {preview && (
                              <label>
                                <input
                                  type="file"
                                  accept="image/jpeg,image/png"
                                  style={{ display: "none" }}
                                  onChange={(event) => {
                                    const file = event.currentTarget.files[0];
                                    setFieldValue("file", file);
                                    const reader = new FileReader();
                                    reader.onloadend = () => {
                                      setPreview(reader.result);
                                    };
                                    reader.readAsDataURL(file);
                                  }}
                                />
                                <Add
                                  // onClick={() => fileInputRef.current.click()}
                                  style={{
                                    width: "48px",
                                    height: "48px",
                                    color: "#0000008F",
                                    cursor: "pointer",
                                    marginLeft: "29px",
                                  }}
                                />
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      <ErrorMessage
                        name="file"
                        component="p"
                        className="text-danger font-10"
                      />

                      <div className="flex justify-content-end mt-20">
                        <MOButtonV2
                          type="submit"
                          text={
                            updateDataState?.state?.status === "edit"
                              ? "EDIT INSTRUCTION"
                              : "ADD INSTRUCTION"
                          } //ADD INSTRUCTION
                          textColor="#ffffff"
                          radius={4}
                          backgroundColor="#29AEE6"
                          height={30}
                          fontSize={11}
                          padding={"8.5px 30.5px"}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(AddPickupInstruction);
