import { Avatar, Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  userAvatarSection: {
    display: "flex",
    alignItems: "center",
  },
  titleRole: {
    flexGrow: 1,
    color: "#878787",
    fontSize: 10,
  },
  titleRoleSM: {
    flexGrow: 1,
    color: "#878787",
    fontSize: 10,
  },
  titleRoleMD: {
    flexGrow: 1,
    color: "#878787",
    fontSize: 14,
    fontWeight: "500",
  },
  titleName: {
    flexGrow: 1,
    color: "#000000",
    fontSize: 16,
  },
  titleNameSM: {
    flexGrow: 1,
    color: "#000000",
    fontSize: 16,
  },
  titleNameMD: {
    flexGrow: 1,
    color: "#000000",
    fontSize: 14,
    fontWeight: "600",
  },
  userAvatar: {
    height: "32px",
    width: "32px",
  },
  userAvatarSM: {
    height: "32px",
    width: "32px",
  },
  userAvatarMD: {
    height: "40px",
    width: "40px",
  },
  userAvatarLG: {
    height: "32px",
    width: "32px",
  },
}));

function getInitials(fullName) {
  // Split the full name into words
  const words = fullName.split(" ");

  // Take the first letter of each word (up to two words)
  const initials = words.slice(0, 2).map((word) => word.charAt(0));

  // Join the initials to form the result
  return initials.join("");
}

const UserAvatar = (props) => {
  const { user, size = "sm" } = props;
  const { fullName="", role = "", imageUrl = "" } = user;
  const classes = useStyles();
  return (
    <Box className={classes.userAvatarSection}>
      <Avatar
        src={imageUrl && imageUrl}
        className={
          (size === "sm" && classes.userAvatarSM) ||
          (size === "md" && classes.userAvatarMD)
        }
      >
        {!imageUrl && getInitials(fullName)}
      </Avatar>
      {/* 63px */}
      <Box sx={{ marginLeft: "12px" }}>
        <Typography
          component="p"
          variant="body1"
          noWrap
          className={
            (size === "sm" && classes.titleRoleSM) ||
            (size === "md" && classes.titleRoleMD)
          }
        >
          {role && `${role}`}
        </Typography>
        <Typography
          component="p"
          variant="body1"
          noWrap
          className={
            (size === "sm" && classes.titleNameSM) ||
            (size === "md" && classes.titleNameMD)
          }
        >
          {fullName && fullName}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserAvatar;
