export const emptyValidation = (rowData: any, field: string) => {
    if (rowData[field] === '' || rowData[field] === undefined) {
        return 'Required';
    }

    return true;
};

export const emailValidation = (rowData: any, field: string) => {

    if (rowData[field] === '' || rowData[field] === undefined) {
        return 'Required';
    } else if (!String(rowData[field])
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
        return 'Valid email required';
    }

    return true;
};

export const IBANValueValidation = (rowData: any, field: string, required: boolean = false) => {
   
    if (required && (rowData[field] === "" || !rowData[field])) {
      return "Required";
    } else if (rowData[field] && !String(rowData[field]).match(/^^[A-Z]{2}[A-Za-z0-9]{13,31}$/)) {
      return "Invalid IBAN.";
    }

    return true;
  };