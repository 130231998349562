import { REJECT, APPROVE_API } from "../constants/onboarding-verification";

export type Field = "reason";

export type ApproveRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
};

export type RejectRepo = {
  field: {
    update: (field: Field, newContent: string) => any;
    clear: (field: Field) => any;
  };
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
};

const RejectOnboarding: RejectRepo = {
  field: {
    update: (field, newContent) => ({
      type: REJECT.FIELD.UPDATE,
      payload: { field, newContent },
    }),
    clear: (field) => ({
      type: REJECT.FIELD.CLEAR,
      payload: { field },
    }),
  },
  api: {
    request: () => ({
      type: REJECT.REJECT_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: REJECT.REJECT_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: REJECT.REJECT_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: REJECT.REJECT_API.RESET,
    }),
  },
};

const ApproveOnboarding: ApproveRepo = {
  api: {
    request: () => ({
      type: APPROVE_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: APPROVE_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: APPROVE_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: APPROVE_API.RESET,
    }),
  },
};

export { RejectOnboarding, ApproveOnboarding };
