import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Pressable from "../commons/pressable";
import { HandleChange, Error } from "../../containers/public/sign-up";
import MobioTextField from "../commons/fields/mobioTextField";
import { Grid } from "@material-ui/core";

interface StepTwoProps {
  companyName: string;
  error: Error;
  country: string;
  city: string;
  address: string;
  addressLineTwo?: string;
  postalCode: string;
  IBAN: string;
  handleSubmit: () => any;
  handleChange: HandleChange;
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: "1rem",
  },
  divider: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.text.disabled,
    borderBottomStyle: "solid",
    width: "45%",
  },
  or: {
    color: theme.palette.text.disabled,
  },
  signInContainer: {
    alignSelf: "center",
    marginTop: "2rem",
    color: theme.palette.text.disabled,
  },
  signIn: {
    color: theme.palette.primary.dark,
    textDecoration: "none",
  },
}));

const StepTwo: React.FunctionComponent<StepTwoProps> = ({
  companyName,
  country,
  city,
  error,
  address,
  addressLineTwo,
  postalCode,
  IBAN,
  handleSubmit,
  handleChange,
}) => {
  const classes = useStyles();

  function checkIban(str: any) {
    return /^^NL[0-9]{2}[A-z0-9]{4}[0-9]{10}$/.test(str);
  }

  return (
    <>
      <MobioTextField
        fullWidth
        error={error.companyName}
        onChange={(event) => handleChange("companyName", event.target.value)}
        label="Company Name"
        value={companyName}
        className={classes.field}
      />
      <MobioTextField
        fullWidth
        error={error.IBAN}
        onChange={(event) => handleChange("IBAN", event.target.value)}
        label="IBAN"
        value={IBAN}
        className={classes.field}
      />
      <Grid container>
        <Grid item xs={6}>
          <MobioTextField
            fullWidth
            error={error.country}
            disabled
            onChange={(event) => handleChange("country", event.target.value)}
            label="Country"
            value={country}
            className={classes.field}
          />
        </Grid>
        <Grid item xs={6}>
          <MobioTextField
            fullWidth
            error={error.city}
            onChange={(event) => handleChange("city", event.target.value)}
            label="City"
            value={city}
            className={classes.field}
          />
        </Grid>
      </Grid>

      <MobioTextField
        fullWidth
        error={error.address}
        onChange={(event) => handleChange("address", event.target.value)}
        label="Address Line"
        value={address}
        className={classes.field}
      />
      <Grid container>
        <Grid item xs={6}>
          <MobioTextField
            fullWidth
            onChange={(event) =>
              handleChange("addressLineTwo", event.target.value)
            }
            label="Address Line 2 (optional"
            value={addressLineTwo}
            className={classes.field}
          />
        </Grid>
        <Grid item xs={6}>
          <MobioTextField
            fullWidth
            error={error.postalCode}
            onChange={(event) => handleChange("postalCode", event.target.value)}
            label="Post/Zip Code"
            value={postalCode}
            className={classes.field}
          />
        </Grid>
      </Grid>

      <Pressable onClick={handleSubmit} size={"large"} title={"Next"} />
      <span className={classes.signInContainer}>
        Already have an account?{" "}
        <Link to="/login" className={classes.signIn}>
          Sign In
        </Link>
      </span>
    </>
  );
};

export default StepTwo;
