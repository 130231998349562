import React from "react";
import Customers from "./Customers";
import { Button, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCustomer from "./AddCustomer";
import AddCustomerForm from "./AddCustomerForm";

const useStyles = makeStyles({
  main_dropdown: {
    marginTop: 20,
  },
  image: {
    height: 80,
    width: 80,
    marginRight: 20,
    borderRadius: 14,
  },

  user_info: {
    marginLeft: 24,
  },
});

function DriversDetail() {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="row-reverse"
        justifyContent="flex-start"
        alignItems="center"
      >
        {/* <AddCustomer /> */}
        <AddCustomerForm />
      </Grid>
      <Customers />
    </>
  );
}

export default DriversDetail;
