export const DRIVERS_API = {
  SET_REQUEST: "ACTION.DRIVERS.SET_REQUEST",
  SET_RESPONSE: "ACTION.DRIVERS.SET_RESPONSE",
  SET_ERROR: "ACTION.DRIVERS.SET_ERROR",
  SET_RETRY: "ACTION.DRIVERS.SET_RETRY",
};

const DRIVERS = {
  RESET_ALL: "ACTION.LOCAL.DRIVERS.RESET_ALL",
  DRIVERS_API,
};

export default DRIVERS;
