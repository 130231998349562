import Local from "../../local";

export type ThunksCustomerUsers = {
  getAllAuthorizations: () => any;
  UpdateUserAuthorizations: (data: string) => any;
  createCustomerUser: (data: string) => any;
  customerUsers: (searchText:String) => any;
  deleteCustomerUser: (data: any) => any;
  updateCustomerUser: (data: any) => any;
  getAuthorities: (data: any) => any;
  blockUser: (data: any, blockCheck: any) => any;
  updateUserPassword: (data: any) => any;
};

const CustomerUsers: ThunksCustomerUsers = {
  getAllAuthorizations: () => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .getClientAllAuthorities()
      .then((res: any) => {
        dispatch(Local.GetCustomerAuthorizations.response(res.data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = error;
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          }
          dispatch(Local.GetCustomerAuthorizations.error("Request Failed", errorMsg));
          alert(errorMsg);
        }
      });
  },
  createCustomerUser:
    (data) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .createClientUser(data)
        .then((res: any) => {
          console.log("resposnse create user", res);
          dispatch(Local.CreateCustomerUser.response(res.data));
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = "Error Creating User";
            if (error?.response?.data?.message) {
              errorMsg = error?.response?.data?.message;
            }
            dispatch(Local.CreateCustomerUser.error("Request Failed", errorMsg));
          }
        });
    },
  customerUsers: (searchText="") => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .getClientUsers(searchText)
      .then((res: any) => {
        dispatch(Local.getCustomerUsers.response(res.data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = error;
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          }
          dispatch(Local.getCustomerUsers.error("Request Failed", errorMsg));
          alert(errorMsg);
        }
      });
  },
  deleteCustomerUser:
    (data) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .deleteClientUser(data)
        .then((res: any) => {
          dispatch(Local.deleteCustomerUser.response(res.data));
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = "Error Deleting User";
            if (error?.response?.data?.message) {
              errorMsg = error?.response?.data?.message;
            }
            dispatch(Local.deleteCustomerUser.error("Request Failed", errorMsg));
          }
        });
    },
  UpdateUserAuthorizations:
    (data) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .updateClientUserAuthorizations(data)
        .then((res: any) => {
          dispatch(Local.UpdateCustomerUser.response(res.data));
        })
        .catch((error: any) => {
          console.log("error", error);

          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = "Cannot Update User";
            if (error?.response?.data?.message) {
              errorMsg = error?.response?.data?.message;
            }
            dispatch(Local.UpdateCustomerUser.error("Request Failed", errorMsg));
          }
        });
    },
  updateCustomerUser:
    (data) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .updateClientUser(data)
        .then((res: any) => {
          dispatch(Local.UpdateCompleteCustomerUser.response(res.data));
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = "Cannot Update User";
            if (error?.response?.data?.message) {
              errorMsg = error?.response?.data?.message;
            }
            dispatch(
              Local.UpdateCompleteCustomerUser.error("Request Failed", errorMsg)
            );
          }
        });
    },
  getAuthorities: (data) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .getClientAuthorities({ id: data })
      .then((res: any) => {
        dispatch(Local.getCustomerAuthorz.response(res.data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = "Authorities Not Found";
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          }
          dispatch(
            Local.getCustomerAuthorz.error("Request Failed", errorMsg)
          );
        }
      });
  },
  blockUser:
    (data, blockCheck) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .blockClientUser({ id: data, isBlocked: blockCheck })
        .then((res: any) => {})
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = "User Not Blocked";
            if (error?.response?.data?.message) {
              errorMsg = error?.response?.data?.message;
            }
          }
        });
    },
  updateUserPassword:
    (data) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .updateClientPass(data)
        .then((res: any) => {
          dispatch(Local.UpdateCustomerPasswordError.response(res.data));
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = "Password Update Error";
            if (error?.response?.data?.message) {
              errorMsg = error?.response?.data?.message;
            }
            dispatch(
              Local.UpdateCustomerPasswordError.error("Request Failed", errorMsg)
            );
          }
        });
    },
};

export default CustomerUsers;
