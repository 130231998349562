import {
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ReactElement, useState, useEffect } from "react";
import { connect } from "react-redux";
import Pressable from "../../../components/commons/pressable";
import ThunksLogin from "../../../redux/actions/thunks/login";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import { Profile, ProfilePassword } from "../../../redux/actions/local/user";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ThunksUsers from "../../../redux/actions/thunks/OrgUsers";
import Local from "../../../redux/actions/local";

interface Props {
  user: any;
  error: any;
  success: any;
  changeUserPass: (data: any) => any;
  reset: () => any;
}
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  pageTitle: {
    fontWeight: 700,
  },
  topWidth: { marginTop: "5vh" },
  inputHint: { fontWeight: 300, color: "red" },
}));
function ChangePassword({
  user,
  error,
  success,
  changeUserPass,
  reset,
}: Props): ReactElement {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confNewPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [confPassword, setConfPassword] = useState(false);
  const [typeError, setTypeError] = useState({
    didNotMatch: false,
    invalid: false,
  });

  React.useEffect(() => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (password.newPassword !== "" && !regex.test(password.newPassword)) {
      setTypeError({ ...typeError, invalid: true });
    } else {
      setTypeError({ ...typeError, invalid: false });
    }
  }, [password.newPassword]);

  React.useEffect(() => {
    if (password.newPassword !== password.confNewPassword) {
      setTypeError({ ...typeError, didNotMatch: true });
    } else {
      setTypeError({ ...typeError, didNotMatch: false });
    }
  }, [password.confNewPassword]);
  return (
    <>
      <Grid container spacing={6} className={classes.container} direction="row">
        <Grid item container direction="column" xs={6} spacing={4}>
          <Typography
            variant="h2"
            className={`${classes.pageTitle} ${classes.topWidth}`}
          >
            Change Password
          </Typography>
          <Grid item>
            <Input
              id="new-password"
              fullWidth
              placeholder="Current Password"
              onChange={(e) =>
                setPassword({ ...password, currentPassword: e.target.value })
              }
              type={"text"}
              value={password.currentPassword}
            />
          </Grid>
          <Grid item>
            <Input
              id="new-password"
              fullWidth
              placeholder="New Password"
              error={typeError.invalid}
              onChange={(e) =>
                setPassword({ ...password, newPassword: e.target.value })
              }
              type={showPassword ? "text" : "password"}
              value={password.newPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {!showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {typeError.invalid && (
              <Typography variant="caption" className={classes.inputHint}>
                Password should contain atleast 8 characters including
                Alpha-numeric and number
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Input
              id="new-password"
              fullWidth
              placeholder="Confirm New Password"
              error={typeError.didNotMatch}
              onChange={(e) =>
                setPassword({ ...password, confNewPassword: e.target.value })
              }
              type={confPassword ? "text" : "password"}
              value={password.confNewPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password"
                    onClick={() => {
                      setConfPassword(!confPassword);
                    }}
                  >
                    {!confPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {typeError.didNotMatch && (
              <Typography variant="caption" className={classes.inputHint}>
                Password And Confirm Password Not Same
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Pressable
              onClick={async () => {
                if (!typeError.invalid && !typeError.didNotMatch) {
                  setLoading(true);
                  await changeUserPass({ ...password, userId: user.myId });
                  setPassword({
                    currentPassword: "",
                    newPassword: "",
                    confNewPassword: "",
                  });
                  setLoading(false);
                }
              }}
              disabled={loading}
              size="large"
              title="Change Password"
            />
          </Grid>
        </Grid>
      </Grid>

      {success && (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"Password Updated Successfully"}
        />
      )}
      {error && <ErrorSnack reset={() => reset()} errorMsg={error} />}
    </>
  );
}

const mapState = (state: any) => ({
  user: state.userInfo.user,
  error: state?.orgUsers?.updatePass?.error || null,
  success: state?.orgUsers?.updatePass?.success || null,
});
const mapDispatch = {
  changeUserPass: ThunksUsers.updateUserPassword,
  reset: Local.UpdatePasswordError.reset,
};

export default connect(mapState, mapDispatch)(ChangePassword);
