// import { CREATE_BOOKING, DISPATCHERS_BY_BOOKING_API} from "../../actions/local/constants/bookings";
import { CREATE_BOOKING } from "../../actions/local/constants/bookings";
import USER from "../../actions/local/constants/user";
import createBookingState, {
  CreateBookingState,
  // BookingState,
} from "../../initial-state/bookings";

const bookingReducer = (
  state: CreateBookingState = createBookingState,
  action: any
) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_BOOKING.RESET_ALL: {
      return {
        ...createBookingState,
      };
    }
    case CREATE_BOOKING.FIELD.UPDATE: {
      const { field, newContent } = payload;
      return {
        ...state,
        [field]: newContent,
      };
    }
    case CREATE_BOOKING.FIELD.CLEAR: {
      const { field } = payload;
      return {
        ...state,
        [field]: "",
      };
    }
    case USER.RESET_USER: {
      return { ...createBookingState }
    }
    // Dispatcher in edit and duplicate booking in web should be removed
    // case DISPATCHERS_BY_BOOKING_API.SET_REQUEST: {
    //   return {
    //     ...state,
    //     isLoading: true,
    //   };
    // }
    // case DISPATCHERS_BY_BOOKING_API.SET_RESPONSE: {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     data: payload,
    //   };
    // }
    // case DISPATCHERS_BY_BOOKING_API.SET_ERROR: {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: payload,
    //   };
    // }
    default:
      return state;
  }
};

export default bookingReducer;
