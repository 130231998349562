import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { connect } from "react-redux";
import EditIcon from "../../../assets/icons/editUpdateIcon.png";
import DeleteIcon from "../../../assets/icons/menuDelete.png";
import DeleteNetworkModal from "./Modals/DeleteNetworkModal";
import AddNetworkModal from "./Modals/AddNetworkModal";
import mobioApi from "../../../services";
import DropDown from "../../../components/commons/DropDown";
import HorizontalIcon from "../../../assets/icons/horizontalIcon.png";
import SearchingInput from "../../../components/commons/SearchingInput";
import MOButtonV2 from "../../../components/commons/MOButtonV2";

const NetworksList = ({ token }) => {
  const [networkId, setNetworkId] = useState();
  const [anchorEl, setAnchorEl] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);

  const [data, setData] = useState([]);

  const getAllNetworks = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getAllNetworks();
      setData(response?.data);
    } catch (error) {
      setData([]);
    }
  };

  const countUsersByRole = (users, role) => {
    return users.filter((user) => user.role === role).length;
  };

  useEffect(() => {
    if (addModalOpen === false && deleteModalOpen === false) {
      setNetworkId("");
      getAllNetworks();
    }
  }, [addModalOpen, deleteModalOpen]);

  return (
    <div>
      <Box
        className="mt-18"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <span style={{ fontSize: "16px" }}>
            {data.length} Available Networks
          </span>
        </div>
        <div className="flex gap-12">
          <div>
            <SearchingInput />
          </div>
          <div>
            <MOButtonV2
              text="+ ADD Network"
              textColor="#ffffff"
              radius={4}
              backgroundColor="#29AEE6"
              onClick={() => {
                setModalStatus("add");
                setAddModalOpen(true);
              }}
              height={30}
              widthSize="168px"
              fontSize={10}
              padding={"8px 12px 8px 12px"}
            />
          </div>
        </div>
      </Box>
      <div className="bg-white main-section-spacing border-radius-8">
        <div className="bg-white border-radius-8">
          <table className="custom-table-v3">
            <thead>
              <tr>
                <th>Display Name</th>
                <th>Country</th>
                <th>City</th>
                <th>Drivers in Network</th>
                <th>Dispatchers in Network</th>
                <th>Support Phone Nr</th>
                <th>Country Code</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td>{row.name}</td>
                  <td>{row.country}</td>
                  <td>{row.city}</td>
                  <td>{countUsersByRole(row.users, "Driver")}</td>
                  <td>{countUsersByRole(row.users, "Dispatcher")}</td>
                  <td>{row.supportNumber}</td>
                  <td>{row.countryCode}</td>
                  <td>
                    <DropDown
                      dropDownOpen={anchorEl}
                      setDropDownOpen={setAnchorEl}
                      uniqueId={row.id}
                      IconRender={() => <img src={HorizontalIcon} />}
                      dropDownShape="horizontal"
                      menu={[
                        {
                          onClick: () => {
                            setNetworkId(row.id);
                            setModalStatus("edit");
                            setAddModalOpen(true);
                          },
                          IconRender: () => <img src={EditIcon} />,
                          title: "Edit",
                        },
                        {
                          onClick: () => {
                            setNetworkId(row.id);
                            setDeleteModalOpen(true);
                          },
                          IconRender: () => <img src={DeleteIcon} />,
                          title: "Delete",
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Modals */}
      <AddNetworkModal
        modalOpen={addModalOpen}
        setModalOpen={setAddModalOpen}
        status={modalStatus}
        networkId={networkId}
      />
      <DeleteNetworkModal
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        networkId={networkId}
      />
    </div>
  );
};
const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(NetworksList);
