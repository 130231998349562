import React, { useEffect, useState } from "react";
import ToolTipMessage from "./ToolTipMessage";
import ExclamationIcon from "../../assets/icons/manage_heroicons_exclaimation-circle.svg";

const TabSelection = ({
  tooltipStatus = {},
  menu = [],
  defaultSelected = "",
  onMenuChange = () => {},
}) => {
  const [tabSelected, setTabSelected] = useState("");
  useEffect(() => {
    setTabSelected(defaultSelected);
  }, []);
  return (
    <div className="tab-selection-body">
      {menu?.map((item) => {
        return (
          <div style={{ position: "relative" }}>
            <div
              onClick={() => {
                setTabSelected(item.title);
                onMenuChange(item);
              }}
              className={`tab-menu-title ${
                item?.disabled
                  ? item?.disabled
                    ? "tab-menu-disabled"
                    : ""
                  : ""
              }  ${
                tabSelected === item.title
                  ? "tab-menu-title-active"
                  : "tab-menu-title-deactive"
              }`}
            >
              <p>{item?.title}</p>
            </div>
            {tooltipStatus[item?.toolTipStatusKey] === false &&
              item?.tooltipStatus && (
                <div>
                  <ToolTipMessage
                    type="primary"
                    content={
                      <>
                        <p className="font-12 font-500">
                          {item?.tooltipMessage}
                        </p>
                      </>
                    }
                  >
                    <div className="tab-selection-tooltip-icon">
                      <div>
                        <img
                          src={ExclamationIcon}
                          alt="ExclamationIcon"
                          style={{
                            cursor: "pointer",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                    </div>
                  </ToolTipMessage>
                </div>
              )}
          </div>
        );
      })}
    </div>
  );
};

export default TabSelection;
