export const DIALOG = {
    OPEN_DIALOG: "ACTION.OPEN_SUBADMIN_DIALOG",
    CLOSE_SUBADMIN_DIALOG: "ACTION.CLOSE_SUBADMIN_DIALOG",
    SELECT_USER: "ACTION.SELECT_USER",
  };
  
  export const SUB_ADMIN_API = {
    SET_REQUEST: "ACTION.SUB_ADMIN.SET_REQUEST",
    SET_RESPONSE: "ACTION.SUB_ADMIN.SET_RESPONSE",
    SET_ERROR: "ACTION.SUB_ADMIN.SET_ERROR",
    SET_RETRY: "ACTION.SUB_ADMIN.SET_RETRY",
    RESET: "ACTION.SUB_ADMIN.RESET",
  };
  export const SUB_ADMIN_AUTHORIZATIONS = {
    SET_REQUEST: "ACTION.SUB_ADMIN_AUTHORIZATIONS.SET_REQUEST",
    SET_RESPONSE: "ACTION.SUB_ADMIN_AUTHORIZATIONS.SET_RESPONSE",
    SET_ERROR: "ACTION.SUB_ADMIN_AUTHORIZATIONS.SET_ERROR",
    SET_RETRY: "ACTION.SUB_ADMIN_AUTHORIZATIONS.SET_RETRY",
    RESET: "ACTION.SUB_ADMIN_AUTHORIZATIONS.RESET",
  };
  
  export const SUB_ADMIN_UPDATE_API = {
    SET_REQUEST: "ACTION.SUB_ADMIN_UPDATE_API.SET_REQUEST",
    SET_RESPONSE: "ACTION.SUB_ADMIN_UPDATE_API.SET_RESPONSE",
    SET_ERROR: "ACTION.SUB_ADMIN_UPDATE_API.SET_ERROR",
    SET_RETRY: "ACTION.SUB_ADMIN_UPDATE_API.SET_RETRY",
    RESET: "ACTION.SUB_ADMIN_UPDATE_API.RESET",
  };
  export const SUB_ADMIN_GET_API = {
    SET_REQUEST: "ACTION.SUB_ADMIN_GET_API.SET_REQUEST",
    SET_RESPONSE: "ACTION.SUB_ADMIN_GET_API.SET_RESPONSE",
    SET_ERROR: "ACTION.SUB_ADMIN_GET_API.SET_ERROR",
    SET_RETRY: "ACTION.SUB_ADMIN_GET_API.SET_RETRY",
    RESET: "ACTION.SUB_ADMIN_GET_API.RESET",
  };
  
  export const SUB_ADMIN_DELETE_API = {
    SET_RESPONSE: "ACTION.SUB_ADMIN_DELETE_API.SET_RESPONSE",
    SET_ERROR: "ACTION.SUB_ADMIN_DELETE_API.SET_ERROR",
    RESET: "ACTION.SUB_ADMIN_DELETE_API.RESET",
  };
  export const USER_UPDATE_API = {
    SET_RESPONSE: "ACTION.USER_UPDATE_API.SET_RESPONSE",
    SET_ERROR: "ACTION.USER_UPDATE_API.SET_ERROR",
    RESET: "ACTION.USER_UPDATE_API.RESET",
  };
  export const GET_USER_AUTHORIZATIONS = {
    SET_RESPONSE: "ACTION.GET_USER_AUTHORIZATIONS.SET_RESPONSE",
    SET_ERROR: "ACTION.GET_USER_AUTHORIZATIONS.SET_ERROR",
    RESET_ERROR: "ACTION.GET_USER_AUTHORIZATIONS.RESET_ERROR",
  };
  export const UPDATE_SUB_ADMIN_PASSWORD = {
    SET_RESPONSE: "ACTION.UPDATE_SUB_ADMIN_PASSWORD.SET_RESPONSE",
    SET_ERROR: "ACTION.UPDATE_SUB_ADMIN_PASSWORD.SET_ERROR",
    RESET_ERROR: "ACTION.UPDATE_SUB_ADMIN_PASSWORD.RESET_ERROR",
  };