import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";

export const StyledToggleButton = withStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.secondary.light,
    // backgroundColor: "#EDF3F5",
    backgroundColor: "#ffffff",
    textTransform: "none",
    marginLeft: theme.spacing(1) / 2,
    marginRight: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,

    height: 32,

    alignSelf: "flex-end",
    overflow: "hidden",
    "&:hover": {
      backgroundColor: "#FFFFF",
      color: theme.palette.background.paper,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.dark,
      borderBottom:"2px solid #0059C8",
      borderRadius:"0"
      // boxShadow:
      //   "0 0px 2px 0 rgba(55, 64, 78, 0.06), 0 0px 5px 0 rgba(55, 64, 78, 0.06)",
    },
  },
  "&.Mui-hasData": {
    backgroundColor: 'rgb(202 218 224)', // Background color when data is available
  },
}))((props) => <ToggleButton {...props} />);

export const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    borderWidth: 0,
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
  },
  root: {
    justifySelf: "flex-end",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
    // marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    // backgroundColor: "#EDF3F5",
    backgroundColor: "#ffffff",
    // borderRadius: theme.spacing(1) / 2,
  },
  groupedHorizontal: {
    // borderRadius: theme.spacing(1) / 2,
    // "&:first-child": {
    //   borderRadius: theme.spacing(1) / 2,
    // },
    // "&:not(:last-child)": {
    //   borderRadius: theme.spacing(1) / 2,
    // },
    // "&:not(:first-child)": {
    //   borderRadius: theme.spacing(1) / 2,
    // },
    // "&:last-child": {
    //   borderRadius: theme.spacing(1) / 2,
    // },
  },
}))((props) => <ToggleButtonGroup {...props} />);

export const StyledSelectButton = withStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1) / 2,
    marginRight: theme.spacing(1) / 2,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
    height: 24,
    borderColor: "red",
    backgroundColor: "red",
  },
}))((props) => <Select {...props} />);
