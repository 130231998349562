import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Pressable from "../../../components/commons/pressable";
import { Google, Apple, Facebook } from "../../../assets/icons";
import LoginRepo from "../../../redux/actions/local/login";
import { LocalLoginRepo, Field } from "../../../redux/actions/local/login";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MobioTextField from "../../../components/commons/fields/mobioTextField";
import ThunksLogin, {
  ThunksLoginRepo,
} from "../../../redux/actions/thunks/login";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import axios from "axios";

interface ForgotPasswordProps {
  role: string;
  displayName: string;
  loading: boolean;
  password: string;
  login: ThunksLoginRepo["postLogin"];
  forgotPassword: ThunksLoginRepo["forgotPassword"];
  updateField: LocalLoginRepo["field"]["update"];
  error?: any;
}
const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: "1rem",
  },
  divider: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "7vh",
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.text.disabled,
    borderBottomStyle: "solid",
    width: "45%",
  },
  or: {
    color: theme.palette.text.disabled,
  },
  forgotPassword: {
    color: theme.palette.primary.dark,
    marginTop: "2vh",
    alignSelf: "end",
  },
  signInContainer: {
    alignSelf: "center",
    marginTop: "7vh",
    color: theme.palette.text.disabled,
  },
  signIn: {
    color: theme.palette.primary.dark,
    textDecoration: "none",
  },
  container: {},
}));
const Login: React.FunctionComponent<ForgotPasswordProps> = ({
  role,
  displayName,
  loading,
  password,
  login,
  updateField,
  forgotPassword,
  error,
}) => {
  const classes = useStyles();
  const [getToken, setGetToken] = useState(false);
  const [token, setToken] = useState("");
  const [pass, setPass] = useState("");
  const [passError, setPassError] = useState(false);
  const [passSuccess, setPassSuccess] = useState(false);
  const [tokenError, setTokenError] = useState(false);

  const clearState = () => {
    setPassError(false);
    setPassSuccess(false);
    setTokenError(false);
  };

  const handleChange = (field: Field, value: string) =>
    updateField(field, value);

  // useEffect(() => {
  //   if (error.status) {
  //     alert(error.message);
  //   }
  // }, [error]);

  const handlePass = (e: any) => {
    setPass(e.target.value);
  };
  const handleToken = (e: any) => {
    setToken(e.target.value);
  };

  const handleSubmit = (displayName: any) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/user/password-forgot/role`,
        { email: displayName, role}
      )
      .then((res) => {
        setGetToken(true);
        setTimeout(() => {
          clearState();
        }, 1000);
      })
      .catch((err) => {
        setTokenError(true);
        setTimeout(() => {
          clearState();
        }, 1000);
      });
  };

  const handleChangePasswordSubmit = (token: any, pass: any) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/user/password-reset/role/${role}/${token}`,
        { password: pass }
      )
      .then((res) => {
        setPassSuccess(true);
        setTimeout(() => {
          clearState();
        }, 1000);
      })
      .catch((err) => {
        setPassError(true);
      });
  };

  return (
    <>
      <div className={classes.container} />
      <MobioTextField
        fullWidth
        label="Email/Username"
        onChange={(event) => handleChange("displayName", event.target.value)}
        value={displayName}
        className={classes.field}
      />
      {getToken && (
        <>
          <MobioTextField
            fullWidth
            label="Token"
            onChange={handleToken}
            value={token}
            className={classes.field}
          />
          <MobioTextField
            fullWidth
            label="New Password"
            onChange={handlePass}
            value={pass}
            className={classes.field}
          />
        </>
      )}
      {getToken ? (
        <Pressable
          onClick={() => {
            handleChangePasswordSubmit(token, pass);
          }}
          size={"large"}
          title={"Change Password"}
          loading={loading}
        />
      ) : (
        <Pressable
          onClick={() => {
            handleSubmit(displayName);
          }}
          size={"large"}
          title={"Get Token"}
          loading={loading}
        />
      )}

      <div className={classes.divider}>
        <div className={classes.border} />
        <span className={classes.or}>OR</span>
        <div className={classes.border} />
      </div>
      <span className={classes.signInContainer}>
        Not a member yet?{" "}
        <Link to="/sign-up" className={classes.signIn}>
          Register here.
        </Link>
      </span>

      <div className={classes.container} />
      {error.status && <ErrorSnack errorMsg={error.message} />}
      {passError && <ErrorSnack errorMsg={"Token is Expired"} />}
      {passSuccess && <ErrorSnack success errorMsg={"Password Updated"} />}
      {tokenError && <ErrorSnack errorMsg={"Use Your Email"} />}
    </>
  );
};

const mapState = (state: any) => ({
  displayName: state.login.state.displayName,
  password: state.login.state.password,
  loading: state.login.api.loading,
  error: state.login.api.error,
});

const mapDispatch = {
  updateField: LoginRepo.field.update,
  login: ThunksLogin.postLogin,
  forgotPassword: ThunksLogin.forgotPassword,
};

export default connect(mapState, mapDispatch)(Login);
