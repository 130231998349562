import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Input from "../../../components/commons/Input";
import { Box, Grid, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import Local from "../../../redux/actions/local";
import AddUserAuthorities from "./AddUserAuthorities";
import ThunksUsers from "../../../redux/actions/thunks/OrgUsers";
import MOButton from "../../../components/commons/MOButton";
import { ErrorMessage, Form, Formik } from "formik";
import { orgUserValidationSchema } from "../../../validations/orgUserValidation";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    height: 600,
    marginLeft: 16,
    marginRight: 34,
  },

  button: {
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 8,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
  },
  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    width: 550,
    height: 40,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AddUser = ({
  openDialog,
  closeDialog,
  getAllOrgUsers,
  postUser,
  updateOrgUser,
  status,
  failure,
  success,
  reset,
  response,
  dialogIsOpen,
  selectedUser,
  resetUpadte,
}) => {
  const classes = useStyles();

  const handleClickOpen = () => {
    openDialog();
  };
  const handleClose = () => {
    closeDialog();
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const payload = {
        displayName: values.displayName,
        fullName: values.fullName,
      };
      payload.selectedAuthorities = values.selectedAuthorities.map((item) => {
        return item.id;
      });
      if (selectedUser?.id) {
        payload.id = selectedUser.id;
        await updateOrgUser(payload);
      } else {
        payload.email = values.email;
        await postUser(payload);
      }
    } catch (error) {
      console.log("Error");
      //   alert("Form not submit successfully ")
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (success) {
      getAllOrgUsers();
      handleClose();
    }

    if (response?.success) {
      getAllOrgUsers();
      handleClose();
    }
  }, [success, response?.success]);

  return (
    <div>
      <Box marginTop={"12px"} marginBottom={"12px"}>
        <MOButton
          title={!!selectedUser?.id ? "Edit User" : "Add User"}
          onClick={handleClickOpen}
        />
      </Box>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={dialogIsOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {!!selectedUser?.id ? "Update User" : "Create User"}
        </DialogTitle>
        <DialogContent dividers>
          {/* Formik Form handling start */}
          <Formik
            initialValues={{
              email: selectedUser ? selectedUser?.email : "",
              displayName: selectedUser ? selectedUser?.displayName : "",
              fullName: selectedUser ? selectedUser?.fullName : "",
              selectedAuthorities: selectedUser?.userAuthorities
                ? selectedUser?.userAuthorities
                : [],
            }}
            validationSchema={orgUserValidationSchema}
            // validate={(values) => {
            //   const errors = {};
            //   if (!values.displayName) {
            //     errors.displayName = "User Name is required.";
            //   }
            //   if (!values.fullName) {
            //     errors.fullName = "Full Name is required.";
            //   }

            //   if (values.selectedAuthorities.length == 0) {
            //     errors.selectedAuthorities =
            //       "Please select at least one authorities role";
            //   }

            //   if (!values.email) {
            //     errors.email = "Email is required";
            //   } else if (
            //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            //   ) {
            //     errors.email = "Invalid email address";
            //   }

            //   return errors;
            // }}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form style={{ width: "550px" }}>
                {/* Email */}
                <Grid>
                  <Input
                    label="User Email"
                    value={values.email}
                    disabled={!!selectedUser?.id}
                    autoFocus
                    type="email"
                    size="small"
                    name="email"
                    className={classes.textfield_upper}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                  />
                  <ErrorMessage
                    name={`email`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>
                {/* Display Name */}
                <Grid>
                  <Input
                    label="User Name"
                    value={values.displayName}
                    type="text"
                    size="small"
                    name="displayName"
                    className={classes.textfield_upper}
                    onChange={(e) =>
                      setFieldValue("displayName", e.target.value)
                    }
                  />
                  <ErrorMessage
                    name={`displayName`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>
                <Grid>
                  <Input
                    label="Full Name"
                    value={values.fullName}
                    type="text"
                    size="small"
                    name="fullName"
                    className={classes.textfield_upper}
                    onChange={(e) => setFieldValue("fullName", e.target.value)}
                  />
                  <ErrorMessage
                    name={`fullName`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>
                <Grid>
                  <AddUserAuthorities
                    selectedAuthorities={(sel) =>
                      setFieldValue("selectedAuthorities", sel)
                    }
                  />
                  <Box marginTop={"8px"}>
                    <ErrorMessage
                      name={`selectedAuthorities`}
                      component="div"
                      className="error-text-danger error-text-style"
                    />
                  </Box>
                </Grid>

                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  marginTop={"24px"}
                >
                  <MOButton
                    submitType="submit"
                    title={!!selectedUser?.id ? "Update User" : "Add User"}
                    disabled={isSubmitting}
                  />
                </Box>
              </Form>
            )}
          </Formik>
          {/* Formik Form handling end */}
        </DialogContent>
      </Dialog>
      {!!failure ? (
        <ErrorSnack
          reset={() => reset()}
          errorMsg={failure?.message || failure}
        />
      ) : null}
      {!!success ? (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"User Created Successfully"}
        />
      ) : null}
      {!!response?.error && (
        <ErrorSnack errorMsg={response.error} reset={() => resetUpadte()} />
      )}
      {!!response?.success && (
        <ErrorSnack
          reset={() => resetUpadte()}
          success
          errorMsg={"User Updated Successfully"}
        />
      )}
    </div>
  );
};
const mapState = (state) => ({
  status: state,
  dialogIsOpen: state.orgUsers.dialog.isOpen,
  selectedUser: state.orgUsers.dialog.selectedUser,
  failure: state.orgUsers.createUser.error,
  success: state.orgUsers.createUser.success,
  response: state.orgUsers.updateCompleteUser,
});

const mapDispatch = {
  openDialog: Local.dialogActions.open,
  closeDialog: Local.dialogActions.close,
  postUser: ThunksUsers.createOrgUser,
  getAllOrgUsers: ThunksUsers.orgUsers,
  updateOrgUser: ThunksUsers.updateOrgUser,
  reset: Local.CreateOrgUser.reset,
  resetUpadte: Local.UpdateCompleteUser.reset,
};
export default connect(mapState, mapDispatch)(AddUser);
