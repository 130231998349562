export const CREATE_LOCATION_API = {
  SET_REQUEST: "ACTION.CREATE_LOCATION.SET_REQUEST",
  SET_RESPONSE: "ACTION.CREATE_LOCATION.SET_RESPONSE",
  SET_ERROR: "ACTION.CREATE_LOCATION.SET_ERROR",
  SET_RETRY: "ACTION.CREATE_LOCATION.SET_RETRY",
  RESET: "ACTION.CREATE_LOCATION.RESET",
};

const CREATE_LOCATION = {
  RESET_ALL: "ACTION.LOCAL.CREATE_LOCATION.RESET_ALL",
  FIELD: {
    UPDATE: "ACTION.LOCAL.CREATE_LOCATION.FIELD.UPDATE",
    CLEAR: "ACTION.LOCAL.CREATE_LOCATION.FIELD.CLEAR",
  },
  FLAGS: {
    TOGGLE_COMPLETE: "ACTION.LOCAL.CREATE_LOCATION.FLAGS.TOGGLE_COMPLETE",
    UPDATE_STEP: "ACTION.LOCAL.CREATE_LOCATION.FLAGS.UPDATE_STEP",
    RESET: "ACTION.LOCAL.CREATE_LOCATION.FLAGS.RESET",
  },
  CREATE_LOCATION_API,
};

export { CREATE_LOCATION };
