import { ErrorMessage, Field, Form, Formik } from "formik";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import MOModalWrapper from "../../../../components/commons/MOModalWrapper";
import { useNavigate } from "react-router";

const services = [
  {
    type: "STANDARD",
  },
  {
    type: "CARRIER",
  },
  {
    type: "EXECUTIVE",
  },
  {
    type: "EXE_CARRIER",
  },
  {
    type: "ELECTRIC_LARGE_PEOPLE_CARRIER",
  },
  {
    type: "LUXURY",
  },
  {
    type: "ELECTRIC_EXE_PEOPLE_CARRIER",
  },
  {
    type: "ELECTRIC_PEOPLE_CARRIER",
  },
  {
    type: "ELECTRIC_LUXURY",
  },
  {
    type: "LARGE_CARRIER",
  },
  {
    type: "MINI_BUS",
  },
  {
    type: "ELECTRIC",
  },
];
const AddOfferModal = ({ modalOpen, setModalOpen, status = "add" }) => {
  const navigate = useNavigate();
  return (
    <MOModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={status === "add" ? "Add New Offer" : "Edit Offer"}
      className={"offer-add-modal"}
    >
      <div className="p-20">
        <Formik
          initialValues={{
            amount: "",
            vehicleType: "",
            startTime: "",
            endTime: "",
            startDate: "",
            endDate: "",
            campaignTitle: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            // Handle form submission
            console.log(values);
            setSubmitting(false);
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div>
                {/* Vehicle Types */}
                <div className="">
                  <h2 className="m-0 font-600 font-14">Service Levels</h2>
                  <div className="width-half-46 mt-10">
                    <div className="campaign-select">
                      <Field as="select" name="vehicleType" className="">
                        <option value="">Enter Your Vehicle Type</option>
                        {services?.map((service) => {
                          return (
                            <>
                              {" "}
                              <option>
                                {service.type
                                  .toLowerCase()
                                  .replace(/_/g, " ")
                                  .replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )}
                              </option>
                            </>
                          );
                        })}
                      </Field>
                      <ErrorMessage name="vehicleType" component="div" />
                    </div>
                  </div>
                </div>

                {/* Offer Name */}
                <div className="mt-30">
                  <h2 className="m-0 font-600 font-14">Offer Name</h2>
                  <div className="mt-10 width-half-46">
                    <div className="input_field">
                      <Field
                        type="text"
                        name="campaignTitle"
                        placeholder="Enter Campaign Name"
                      />
                      <ErrorMessage name="campaignTitle" component="div" />
                    </div>
                  </div>
                </div>

                {/* Enter an amount */}
                <div className="mt-30">
                  <h2 className="m-0 font-600 font-14">Adjustment</h2>
                  <div className="w-142 mt-10">
                    <div className="input-field-increase-by mt-12">
                      <Field type="text" id={`amount`} name={`amount`} />
                      <ErrorMessage
                        name={`amount`}
                        component="div"
                        className="text-danger"
                      />
                      <p className="m-0">%</p>
                    </div>
                  </div>
                </div>

                {/* Active Pick-up dates */}
                <div className="mt-30">
                  <h2 className="m-0 font-600 font-14">Active Pick-up dates</h2>
                  <div className=" mt-16">
                    <div className="flex align-item-center gap-32">
                      <div className="flex-1">
                        {/* Start Date */}
                        <div className="input_field">
                          <label className="font-12 mb-6">Start Date</label>
                          <Field
                            type="date"
                            name="startDate"
                            style={{ textIndent: "4px" }}
                          />
                          <ErrorMessage name="startDate" component="div" />
                        </div>
                      </div>
                      <div className="flex-1">
                        {/* End Date */}
                        <div className="input_field">
                          <label className="font-12 mb-6">End Date</label>
                          <Field
                            type="date"
                            name="endDate"
                            style={{ textIndent: "4px" }}
                          />
                          <ErrorMessage name="endDate" component="div" />
                        </div>
                      </div>
                    </div>
                    <div className="flex align-item-center gap-32 mt-12">
                      <div className="flex-1">
                        {/* Start time */}
                        <div className="input_field">
                          <label className="font-12 mb-6">Start time</label>
                          <Field
                            type="time"
                            name="startTime"
                            style={{ textIndent: "4px" }}
                          />
                          <ErrorMessage name="startTime" component="div" />
                        </div>
                      </div>
                      <div className="flex-1">
                        {/* End Time */}
                        <div className="input_field">
                          <label className="font-12 mb-6">End time</label>
                          <Field
                            type="time"
                            name="endTime"
                            style={{ textIndent: "4px" }}
                          />
                          <ErrorMessage name="endTime" component="div" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* save and cancel button */}
                <div className="flex align-item-center justify-content-end mt-24">
                  <div className=" ">
                    <MOButtonV2
                      text="CREATE OFFER"
                      textColor="#ffffff"
                      radius={4}
                      backgroundColor="#29AEE6"
                      height={30}
                      fontSize={11}
                      padding={"10px 28px"}
                      onClick={() => {}}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </MOModalWrapper>
  );
};

export default AddOfferModal;
