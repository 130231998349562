export const PAYMENT_PAYINS_API = {
    SET_REQUEST: "ACTION.PAYMENT_PAYINS.SET_REQUEST",
    SET_RESPONSE: "ACTION.PAYMENT_PAYINS.SET_RESPONSE",
    SET_ERROR: "ACTION.PAYMENT_PAYINS.SET_ERROR",
    SET_RETRY: "ACTION.PAYMENT_PAYINS.SET_RETRY",
  };

  const PAYMENT_PAYINS = {
    RESET_ALL: "ACTION.LOCAL.PAYMENT_PAYINS.RESET_ALL",
    FIELD: {
      UPDATE: "ACTION.LOCAL.PAYMENT_PAYINS.FIELD.UPDATE",
      CLEAR: "ACTION.LOCAL.PAYMENT_PAYINS.FIELD.CLEAR",
    },
    FLAGS: {
      TOGGLE_COMPLETE: "ACTION.LOCAL.PAYMENT_PAYINS.FLAGS.TOGGLE_COMPLETE",
      UPDATE_STEP: "ACTION.LOCAL.PAYMENT_PAYINS.FLAGS.UPDATE_STEP",
      RESET: "ACTION.LOCAL.PAYMENT_PAYINS.FLAGS.RESET",
    },
    PAYMENT_PAYINS_API,
  };

  export {PAYMENT_PAYINS}