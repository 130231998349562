import { AUTH_URL, CREATE_SHEET, IMPORT_SHEET, SHEET } from "../constants/spread-sheet";

export type SpreadSheetRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
  };
};

const AuthSpreadSheet: SpreadSheetRepo = {
  api: {
    request: () => ({
      type: AUTH_URL.AUTH_URL_SHEET_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: AUTH_URL.AUTH_URL_SHEET_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: AUTH_URL.AUTH_URL_SHEET_API.SET_RESPONSE,
      payload: { data },
    }),
  },
};

export type ImportSheetRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
};
const ImportSheet: ImportSheetRepo = {
  api: {
    request: () => ({
      type: IMPORT_SHEET.IMPORT_SHEET_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: IMPORT_SHEET.IMPORT_SHEET_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: IMPORT_SHEET.IMPORT_SHEET_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: IMPORT_SHEET.IMPORT_SHEET_API.RESET,
      payload: {},
    }),
  },
};

export type CreateSheetRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
  };
};

const CreateSheet: CreateSheetRepo = {
  api: {
    request: () => ({
      type: CREATE_SHEET.CREATE_SHEET_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: CREATE_SHEET.CREATE_SHEET_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: CREATE_SHEET.CREATE_SHEET_API.SET_RESPONSE,
      payload: { data },
    }),
  },
};

export type SheetRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
  };
};

const Sheet: SheetRepo = {
  api: {
    request: () => ({
      type: SHEET.SHEET_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: SHEET.SHEET_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: SHEET.SHEET_API.SET_RESPONSE,
      payload: { data },
    }),
  },
};

export { AuthSpreadSheet, CreateSheet, ImportSheet, Sheet };
