import React from "react";
import { Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";

interface IPublicRouteProps {
  component: any;
  token: any;
  path?: any;
}

const PublicRoute: React.FC<IPublicRouteProps> = ({
  component: Component,
  token,
  ...rest
}) => {
  return (
    <Route
      element={!token ? <Component /> : <Navigate to="/dashboard" />}
      {...rest}
    />
  );
};
const mapState = (state: any) => ({
  token: state.userInfo.token,
});
export default connect(mapState)(PublicRoute);
