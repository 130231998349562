import axios from "axios";

export const getDistance = async (origin, destination) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/distancematrix/json`,
      {
        params: {
          origins: `${origin.latitude},${origin.longitude}`, // origin coordinates
          destinations: `${destination.latitude},${destination.longitude}`, // destination coordinates
          key: process.env.REACT_APP_GOOGLE_MAP_API_KEY, // Your Google API key
          mode: "driving", // You can change mode to 'walking', 'bicycling', 'transit', etc.
        },
      }
    );

    // Extract distance in km from the response
    const distanceText = response.data.rows[0].elements[0].distance.text;
    const distanceValue = response.data.rows[0].elements[0].distance.value; // in meters

    console.log(`Distance: ${distanceText} (${distanceValue / 1000} km)`);

    return distanceValue / 1000; // Return distance in kilometers
  } catch (error) {
    console.error("Error fetching distance from Google API", error);
  }
};

export const getDistanceAndDuration = async (origin, destination) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://maps.googleapis.com/maps/api/directions/json?origin=${origin.latitude},${origin.longitude}&destination=${destination.latitude},${destination.longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
  };

  return axios
    .request(config)
    .then((response) => {
      if (
        response.data.status &&
        response.data.status === "OK" &&
        response.data.routes &&
        response.data.routes.length > 0 &&
        response.data.routes[0].legs &&
        response.data.routes[0].legs.length > 0
      ) {
        const returnData = {
          distance: response.data.routes[0].legs[0].distance, // distance object with value and text
          duration: response.data.routes[0].legs[0].duration, // duration object with value and text
        };
        return returnData;
      } else {
        return {
          distance: { value: null, text: null },
          duration: { value: null, text: null },
        };
      }
    })
    .catch((error) => {
      console.error("Error fetching data from Google Directions API", error);
      return {
        distance: { value: null, text: null },
        duration: { value: null, text: null },
      };
    });
};

export const haversineDistance = (origin, destination) => {
  const toRadian = (angle) => (Math.PI / 180) * angle;
  const distance = (a, b) => (Math.PI / 180) * (a - b);
  const RADIUS_OF_EARTH_IN_KM = 6371; // Radius of Earth in kilometers

  const dLat = distance(destination.latitude, origin.latitude);
  const dLon = distance(destination.longitude, origin.longitude);

  const lat1Rad = toRadian(origin.latitude);
  const lat2Rad = toRadian(destination.latitude);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(lat1Rad) *
      Math.cos(lat2Rad);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return RADIUS_OF_EARTH_IN_KM * c; // The distance in kilometers
};
