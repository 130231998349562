import Local from "../../local";

export type ThunksVehiclesListing = {
  VehiclesListing: () => any;
  AddBrand: (brandName: string) => any;
  DeleteBrand: (brandId: string) => any;
  UpdateBrand: (brandId: string, obj: any) => any;
  AddCar: (brandId: string, carData: any) => any;
  UpdateCar: (carId: string, brandId: string, carData: any) => any;
  DeleteCar: (carId: string) => any;
};

const ThunksVehicle: ThunksVehiclesListing = {
  VehiclesListing: () => (dispatch: any, getState: any, mobioApi: any) => {
    console.log("vehicles list");
    const {
      userInfo: { token },
    } = getState();
    console.log("this is user token", token);
    const api = mobioApi(token);
    dispatch(Local.VehiclesListing.api.request());

    return api
      .getVehiclesListing()
      .then((response: any) => {
        dispatch(Local.VehiclesListing.api.response(response.data));
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        dispatch(Local.VehiclesListing.api.error("Request Failed", data.msg));
      });
  },
  AddBrand:
    (brandName: string) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      dispatch(Local.AddBrand.api.request());

      return api
        .postVehicle({
          brandName: brandName,
        })
        .then((response: any) => {
          dispatch(Local.VehiclesListing.api.request());
          dispatch(Local.AddBrand.api.response(response));

          console.log(
            "this is response of update brand from thunk",
            response.data
          );
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              Local.AddBrand.api.error(
                "Request Failed",
                "The API request timed out. Please Refresh the page"
              )
            );
          } else {
            const {
              response: { data },
            } = error;
            dispatch(Local.AddBrand.api.error("Request Failed", data.msg));
          }
        });
    },
  DeleteBrand:
    (brandId: string) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .deleteBrand(brandId)
        .then((response: any) => {
          // alert(response.data.msg);
          dispatch(Local.VehiclesListing.api.request());
        })
        .catch((error: any) => {
          const {
            response: {
              data: { msg },
            },
          } = error;
          alert(msg);
        });
    },
  UpdateBrand: (id, obj) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .updateBrand(id, obj)
      .then((response: any) => {
        // alert(response.data.msg);
      })
      .catch((error: any) => {
        const {
          response: {
            data: { msg },
          },
        } = error;
        alert(msg);
      });
  },
  AddCar: (id, carData) => (dispatch: any, getState: any, mobioApi: any) => {
    console.log("clicked");
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .postCar({
        carName: carData.carName,
        brandId: id,
        year: carData.year,
        category: carData.category,
        seatingCapacity: carData.seatingCapacity,
      })
      .then((response: any) => {
        console.log(response.data.msg);
        dispatch(Local.VehiclesListing.api.request());
      })
      .catch((error: any) => {
        const {
          response: {
            data: { msg },
          },
        } = error;
        alert(msg);
      });
  },
  UpdateCar:
    (carId, brandId, obj) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .updateCar(carId, {
          brandId: brandId,
          carName: obj.carName,
          category: obj.category,
          seatingCapacity: obj.seatingCapacity,
          year: obj.year,
        })
        .then((response: any) => {
          dispatch(Local.VehiclesListing.api.request());
        })
        .catch((error: any) => {
          const {
            response: {
              data: { msg },
            },
          } = error;
          alert(msg);
        });
    },
  DeleteCar:
    (carId: string) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .deleteCar(carId)
        .then((response: any) => {
          dispatch(Local.VehiclesListing.api.request());
        })
        .catch((error: any) => {
          const {
            response: {
              data: { msg },
            },
          } = error;
          alert(msg);
        });
    },
};

export default ThunksVehicle;
