import BookingList from "./BookingList";
import { connect } from "react-redux";
import CustomerBookingList from "./CustomerBookingList";
import { useEffect } from "react";
import ThunksBooking from "../../../redux/actions/thunks/bookings";
interface Props {
  role?: string;
  getTotalAndAssignedRides: () => any;
}
function Bookings({ role,getTotalAndAssignedRides }: Props) {
  
  useEffect(() => {
    getTotalAndAssignedRides();
  }, [])

  return role == "Customer" || role == "CustomerUser" ? (
    <CustomerBookingList role={role} />
  ) : (
    <BookingList role={role} />
  );
}
const mapState = (state: any) => ({
  role: state.userInfo.user.role,
});

const mapDispatch = {
  getTotalAndAssignedRides: ThunksBooking.getTotalAndAssignedRides
};

export default connect(mapState, mapDispatch)(Bookings);
