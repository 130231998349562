import Local from "../../local";

export type ThunksPassengersListing = {
  Passengers: (searchText:String) => any;
  updatePassengers: (id: string, obj: any) => any;
  deletePassenger: (id: string) => any;
  createPassenger: (obj: any) => any;
};
const ThunksPassengers: ThunksPassengersListing = {
  Passengers: (searchText="") => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    console.log("this is user token", token);
    const api = mobioApi(token);
      dispatch(Local.Passengers.api.request());
    return api
      .getPassengers(searchText)
      .then((response: any) => {
        dispatch(Local.Passengers.api.response(response.data));
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        dispatch(Local.Passengers.api.error("Request Failed", data.msg));
      });
  },
  updatePassengers: (id, obj) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    console.log("this is user token", token);
    console.log(id, obj)
    const api = mobioApi(token);
      dispatch(Local.Passengers.api.request());
    return api
      .updatePassengers(obj)
      .then((response: any) => {
        
        dispatch(Local.Passengers.api.response(response.data));
        return true;
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        return data.msg
      });
  },
  createPassenger: (obj) => (dispatch: any, getState: any, mobioApi: any) => {
    if( obj.passengerName == "" || obj.phone == ""){
      return false;
    }else if(obj.passengerName !== "" && obj.phone !== ""){
      const {
        userInfo: { token },
      } = getState();
      console.log("this is user token", token);
      console.log(obj)
      const api = mobioApi(token);
        dispatch(Local.Passengers.api.request());
      return api
        .createPassenger(obj)
        .then((response: any) => {
          dispatch(Local.Passengers.api.response(response.data));
          return true;
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          return data.msg;
          dispatch(Local.Passengers.api.error("Request Failed", data.msg));
        });
    }
    
  },
  deletePassenger: (id) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
      dispatch(Local.Passengers.api.request());
    return api
      .deletePassenger({id : id})
      .then((response: any) => {
        dispatch(Local.Passengers.api.response(response.data));
        return true;
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        dispatch(Local.Passengers.api.error("Request Failed", data.msg));
        return data.msg;
      });
  },

};

export default ThunksPassengers;
