import moment from "moment";
import _ from "lodash";
import axios from "axios";
import momentTimeZone from "moment-timezone";

export const generateReceiptNumber = async (user: any, GetPaymentByYear: any) => {
  const curentYear = moment().year();
  const data = await GetPaymentByYear(curentYear);
  const countryName = (user.activeCountry?.substr(0, 3) ?? user.country?.substr(0, 3) ?? '---').toUpperCase();
  const companyName = (user.companyName?.substr(0, 3) ?? '---').toUpperCase();

  if (!data) {
    return countryName + companyName + '0001' + curentYear;
  }

  // considering the format is 00012023
  // dropping last 4 digits
  let firstFourDigits;
  if(data.receiptNo.length>8)
     firstFourDigits = data.receiptNo.slice(6, 10);
  else firstFourDigits = data.receiptNo.slice(0, -4);

  // convertting to number then to string
  let convertedString = (parseInt(firstFourDigits, 10) + 1).toString();

  let receiptNumber = convertedString.length < 4 ? "0".repeat(4 - convertedString.length) + convertedString : convertedString;

  return countryName + companyName + receiptNumber + curentYear;

}
export const generateinvoiceNumber = async (user: any, GetPaymentByYear: any) => {
  const curentYear = moment().year();
  const data = await GetPaymentByYear(curentYear);
  console.log(data.invoiceNo, "<=======")
  const countryName = (user.activeCountry?.substr(0, 3) ?? user.country?.substr(0, 3) ?? '---').toUpperCase();
  const companyName = (user.companyName?.substr(0, 3) ?? '---').toUpperCase();

  if (!data) {
    return countryName + companyName + curentYear + '0001';
  }

  // considering the format is 00012023
  // dropping last 4 digits
  let firstFourDigits;
  if(data.invoiceNo.length>8)
     firstFourDigits = data.invoiceNo.slice(10);
  else firstFourDigits = data.invoiceNo.slice(-4);

  if(firstFourDigits == curentYear){
    if(data.invoiceNo.length>8)
      firstFourDigits = data.invoiceNo.slice(6, 10);
   else firstFourDigits = data.invoiceNo.slice(0, -4);
  }


  // convertting to number then to string
  let convertedString = (parseInt(firstFourDigits, 10) + 1).toString();

  let invoiceNumber = convertedString.length < 4 ? "0".repeat(4 - convertedString.length) + convertedString : convertedString;

  return countryName + companyName + curentYear + invoiceNumber;

}
export const convertFileToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result);
    }
    fileReader.onerror = (error) => {
      reject(error);
    }
  })
}
export const GetInfoFromFlightaware = async (response: any) => {
  let response_data = [];
  try {
    for (let item of response) {
      if ((item.pickup.name).indexOf("Schiphol") > -1 && (item.pickup.name).indexOf("Airport") > -1 && (item.flightNumber == null || item.flightNumber == '')) {

        const dateTimeDifference = moment(new Date()).diff(item.dateTime, 'minutes');
        if (dateTimeDifference > 0) {
          item.flightLanded = true;
          item.flightLandTime = item.dateTime;
        }
      }
      item.flightInfo = null;
      item.flightTime = item.dateTime;
      item.flightTimeForSorting = item.dateTime;

      item.landed_dif = 0;

      let flightInfo = {
        estimated_in: item.estimatedIn,
        arrival_delay: item.arrivalDelay
      };

      if (item.flightNumber !== "" && item.flightLanded == false) {
        item.flightInfo = flightInfo;
        item.flightTime = item.dateTime;
        item.flightTimeForSorting = flightInfo?.estimated_in || item.dateTime;
      } else if ((item.flightNumber != null || item.flightNumber != "") && item.flightLanded == true) {
        item.flightInfo = null;
        item.flightTime = item.flightLandTime;
        item.flightTimeForSorting = item.flightLandTime;
        item.landed_dif = moment(new Date()).diff(new Date(item.flightLandTime), 'minutes');
        console.log("here is moment", new Date());
        console.log("here is flight land time", item.flightLandTime)
      } else {
        item.flightTime = item.dateTime;
        item.flightTimeForSorting = item.dateTime;
      }
      response_data.push(item);
    }

  } catch (error) {
    // console.log(error);
  }

  let sortedData = response_data.sort((a: { flightTimeForSorting: string }, b: { flightTimeForSorting: string }) => new Date(a.flightTimeForSorting).getTime() - new Date(b.flightTimeForSorting).getTime());

  return sortedData
}

export function showGoogleMap(pickup: any, destination: any) {
  const originalLat = pickup[0];
  const originalLng = pickup[1];
  const destinationLat = destination[0];
  const destinationLng = destination[1];
  const origin = encodeURIComponent(originalLat + "," + originalLng);
  const dest = encodeURIComponent(destinationLat + "," + destinationLng);
  const link = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${dest}`;
  window.open(link, "_blank");
}

export async function GetTimeZone(pickup: any) {
  const originalLat = pickup[0];
  const originalLng = pickup[1];
  const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const link = `https://maps.googleapis.com/maps/api/timezone/json?location=${originalLat}0%2C${originalLng}&timestamp=1331161200&key=${key}`;
  const response = await axios.get(link)
  return response.data
}

export function getTimeZoneTime(date: any, timeZone: any) {
  const now = momentTimeZone(date);
  const another = now.clone();
  another.tz(timeZone, true);
  return another.format()
}

export const getMonday = {
  0: 6,
  1: 0,
  2: 1,
  3: 2,
  4: 3,
  5: 4,
  6: 5,
}