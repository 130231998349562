import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Link } from "react-router-dom";
import MobioTextField from "../../../components/commons/fields/mobioTextField";
import Pressable from "../../../components/commons/pressable";
import { connect } from "react-redux";
import ThunksLogin from "../../../redux/actions/thunks/login";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import Local from "../../../redux/actions/local";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: "1rem",
  },
  forgotPassword: {
    color: theme.palette.primary.dark,
    marginTop: "2vh",
    alignSelf: "end",
  },
  signIn: {
    color: theme.palette.primary.dark,
    textDecoration: "none",
  },
  divider: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "7vh",
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.text.disabled,
    borderBottomStyle: "solid",
    width: "45%",
  },
  or: {
    color: theme.palette.text.disabled,
  },
  customerLogin: {
    marginTop: "2vh",
    padding: theme.spacing(1.5),
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderRadius: 15,
    alignSelf: "center",
  },
}));

const CustomerLogin = ({ login, loading, error, reset }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [loading, setLoading] = useState(false);

  return (
    <>
      <MobioTextField
        fullWidth
        label="Email"
        value={email}
        className={classes.field}
        onChange={(e) => setEmail(e.target.value)}
      />
      <div className={classes.field}>
        <FormControl fullWidth>
          <InputLabel htmlFor="password-first">Password</InputLabel>
          <Input
            id="password-first"
            fullWidth
            onChange={(event) => setPassword(event.target.value)}
            type={showPassword ? "text" : "password"}
            value={password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {!showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <div className={classes.forgotPassword}>
        <Link to="/forgot-password/customer" className={classes.signIn}>
          Forgot Password?
        </Link>
      </div>
      <Pressable
        onClick={() => {
          login(email, password);
        }}
        size={"large"}
        title={"Sign In"}
        loading={loading}
      />
      <div className={classes.divider}>
        <div className={classes.border} />
        <span className={classes.or}>OR</span>
        <div className={classes.border} />
      </div>
      <Link
        to="/login"
        className={`${classes.signIn} ${classes.customerLogin}`}
      >
        Enterprise Login
      </Link>
      {error.status && (
        <ErrorSnack reset={() => reset()} errorMsg={error.message} />
      )}
    </>
  );
};

const mapState = (state) => ({
  loading: state.login.api.loading,
  error: state.login.api.error,
});

const mapDispatch = {
  login: ThunksLogin.postCustomerLogin,
  reset: Local.Login.api.reset,
};

export default connect(mapState, mapDispatch)(CustomerLogin);
