export const DIALOG = {
  OPEN_DIALOG: "ACTION.OPEN_DIALOG",
  CLOSE_DIALOG: "ACTION.CLOSE_DIALOG",
  SELECT_USER: "ACTION.SELECT_USER",
};

export const CLIENT_USER_API = {
  SET_REQUEST: "ACTION.CLIENT_USER.SET_REQUEST",
  SET_RESPONSE: "ACTION.CLIENT_USER.SET_RESPONSE",
  SET_ERROR: "ACTION.CLIENT_USER.SET_ERROR",
  SET_RETRY: "ACTION.CLIENT_USER.SET_RETRY",
  RESET: "ACTION.CLIENT_USER.RESET",
};
export const CLIENT_AUTHORIZATIONS = {
  SET_REQUEST: "ACTION.CLIENT_AUTHORIZATIONS.SET_REQUEST",
  SET_RESPONSE: "ACTION.CLIENT_AUTHORIZATIONS.SET_RESPONSE",
  SET_ERROR: "ACTION.CLIENT_AUTHORIZATIONS.SET_ERROR",
  SET_RETRY: "ACTION.CLIENT_AUTHORIZATIONS.SET_RETRY",
  RESET: "ACTION.CLIENT_AUTHORIZATIONS.RESET",
};

export const CLIENT_USER_UPDATE_API = {
  SET_REQUEST: "ACTION.CLIENT_USER_UPDATE_API.SET_REQUEST",
  SET_RESPONSE: "ACTION.CLIENT_USER_UPDATE_API.SET_RESPONSE",
  SET_ERROR: "ACTION.CLIENT_USER_UPDATE_API.SET_ERROR",
  SET_RETRY: "ACTION.CLIENT_USER_UPDATE_API.SET_RETRY",
  RESET: "ACTION.CLIENT_USER_UPDATE_API.RESET",
};
export const CLIENT_USER_GET_API = {
  SET_REQUEST: "ACTION.CLIENT_USER_GET_API.SET_REQUEST",
  SET_RESPONSE: "ACTION.CLIENT_USER_GET_API.SET_RESPONSE",
  SET_ERROR: "ACTION.CLIENT_USER_GET_API.SET_ERROR",
  SET_RETRY: "ACTION.CLIENT_USER_GET_API.SET_RETRY",
  RESET: "ACTION.CLIENT_USER_GET_API.RESET",
};

export const CLIENT_USER_DELETE_API = {
  SET_RESPONSE: "ACTION.CLIENT_USER_DELETE_API.SET_RESPONSE",
  SET_ERROR: "ACTION.CLIENT_USER_DELETE_API.SET_ERROR",
  RESET: "ACTION.CLIENT_USER_DELETE_API.RESET",
};
export const CLIENT_UPDATE_API = {
  SET_RESPONSE: "ACTION.CLIENT_UPDATE_API.SET_RESPONSE",
  SET_ERROR: "ACTION.CLIENT_UPDATE_API.SET_ERROR",
  RESET: "ACTION.CLIENT_UPDATE_API.RESET",
};
export const GET_CLIENT_AUTHORIZATIONS = {
  SET_RESPONSE: "ACTION.GET_CLIENT_AUTHORIZATIONS.SET_RESPONSE",
  SET_ERROR: "ACTION.GET_CLIENT_AUTHORIZATIONS.SET_ERROR",
  RESET_ERROR: "ACTION.GET_CLIENT_AUTHORIZATIONS.RESET_ERROR",
};
export const UPDATE_CLIENT_USER_PASSWORD = {
  SET_RESPONSE: "ACTION.UPDATE_CLIENT_USER_PASSWORD.SET_RESPONSE",
  SET_ERROR: "ACTION.UPDATE_CLIENT_USER_PASSWORD.SET_ERROR",
  RESET_ERROR: "ACTION.UPDATE_CLIENT_USER_PASSWORD.RESET_ERROR",
};
