import React from 'react'

export const BookingIcon = ({color}) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.79409 18.8115C4.79409 18.8115 4.69709 19.5665 4.79409 19.9255C4.86159 20.1745 4.92609 20.594 5.18409 20.594H6.63259C6.89059 20.594 6.95509 20.1745 7.02259 19.9255C7.11959 19.567 7.02259 18.8115 7.02259 18.8115" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M20.1155 11.5684H5.07249C3.84173 11.5684 2.84399 12.5661 2.84399 13.7969V16.5829C2.84399 17.8136 3.84173 18.8114 5.07249 18.8114H20.1155C21.3463 18.8114 22.344 17.8136 22.344 16.5829V13.7969C22.344 12.5661 21.3463 11.5684 20.1155 11.5684Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.1654 18.8115C18.1654 18.8115 18.0684 19.5665 18.1654 19.9255C18.2329 20.1745 18.2974 20.594 18.5554 20.594H20.0039C20.2619 20.594 20.3264 20.1745 20.3939 19.9255C20.4909 19.567 20.3939 18.8115 20.3939 18.8115" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.90845 16.583C6.52397 16.583 7.02295 16.084 7.02295 15.4685C7.02295 14.853 6.52397 14.354 5.90845 14.354C5.29292 14.354 4.79395 14.853 4.79395 15.4685C4.79395 16.084 5.29292 16.583 5.90845 16.583Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.07251 11.5683L6.35401 6.55382C6.57301 5.69732 7.53051 4.88232 8.41551 4.88232H16.7725C17.6575 4.88232 18.615 5.69732 18.834 6.55382L20.1155 11.5683" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.2795 16.583C19.8951 16.583 20.394 16.084 20.394 15.4685C20.394 14.853 19.8951 14.354 19.2795 14.354C18.664 14.354 18.165 14.853 18.165 15.4685C18.165 16.084 18.664 16.583 19.2795 16.583Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.594 6.52979C11.8837 6.52979 11.1893 6.74331 10.5987 7.14337C10.008 7.54342 9.54767 8.11204 9.27584 8.7773C9.004 9.44257 8.93287 10.1746 9.07145 10.8809C9.21004 11.5871 9.5521 12.2358 10.0544 12.745C10.5567 13.2542 11.1966 13.6009 11.8933 13.7414C12.59 13.8819 13.3122 13.8098 13.9685 13.5342C14.6247 13.2587 15.1857 12.792 15.5803 12.1933C15.975 11.5946 16.1856 10.8906 16.1856 10.1706C16.1846 9.2053 15.8058 8.27987 15.1325 7.59732C14.4592 6.91477 13.5463 6.53085 12.594 6.52979ZM12.594 13.5201C11.9405 13.5201 11.3017 13.3236 10.7583 12.9556C10.2149 12.5875 9.79138 12.0644 9.54129 11.4524C9.2912 10.8403 9.22576 10.1669 9.35326 9.51711C9.48076 8.86737 9.79546 8.27054 10.2576 7.8021C10.7197 7.33366 11.3084 7.01465 11.9494 6.88541C12.5904 6.75617 13.2547 6.8225 13.8585 7.07601C14.4623 7.32953 14.9783 7.75885 15.3414 8.30967C15.7045 8.8605 15.8983 9.5081 15.8983 10.1706C15.8973 11.0586 15.5489 11.91 14.9294 12.538C14.31 13.1659 13.4701 13.5191 12.594 13.5201ZM14.749 10.1706C14.749 10.2092 14.7338 10.2462 14.7069 10.2735C14.68 10.3009 14.6434 10.3162 14.6053 10.3162H12.594C12.5559 10.3162 12.5194 10.3009 12.4924 10.2735C12.4655 10.2462 12.4504 10.2092 12.4504 10.1706V8.13173C12.4504 8.09311 12.4655 8.05606 12.4924 8.02875C12.5194 8.00144 12.5559 7.9861 12.594 7.9861C12.6321 7.9861 12.6687 8.00144 12.6956 8.02875C12.7226 8.05606 12.7377 8.09311 12.7377 8.13173V10.0249H14.6053C14.6434 10.0249 14.68 10.0403 14.7069 10.0676C14.7338 10.0949 14.749 10.1319 14.749 10.1706Z" fill={color}/>
    </svg>
    
  )
}
