import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Input from "../../../components/commons/Input";
import { Box, Grid, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import ThunksCustomers from "../../../redux/actions/thunks/customer";
import Local from "../../../redux/actions/local";
import MOButton from "../../../components/commons/MOButton";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { addCustomerValidationSchema } from "../../../validations/addCustomerValidation";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    height: 600,
    marginLeft: 16,
    marginRight: 34,
  },

  button: {
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 8,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
  },
  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    width: 550,
    height: 40,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AddCustomer = ({ addCustomer, status, failure, success, reset }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await addCustomer(values);
    } catch (error) {
      console.log("Error");
      //   alert("Form not submit successfully ")
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (success.status) {
      handleClose();
    }
  }, [success]);

  return (
    <div>
      <Box marginTop={"12px"} marginBottom={"12px"}>
        <MOButton title={"Add Customer"} onClick={handleClickOpen} />
      </Box>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Create Customer
        </DialogTitle>
        <DialogContent dividers>
          {/* Formik Form handling start */}
          <Formik
            initialValues={{
              email: "",
              firstName: "",
              lastName: "",
              companyName: "",
              companyAddress: "",
              companyPostCode: "",
              companyCity: "",
              companyRegistrationNumber: "",
              companyVATNumber: "",
              bankAccountNumber: "",
              bankAccountHolderName: "",
              vatPercentage: "",
            }}
            // validate={(values) => {
            //   const errors = {};
            //   if (!values.firstName) {
            //     errors.firstName = "First Name is required.";
            //   }

            //   if (!values?.lastName) {
            //     errors.lastName = "Last Name is required.";
            //   }

            //   if (!values.email) {
            //     errors.email = "Email is required";
            //   } else if (
            //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            //   ) {
            //     errors.email = "Invalid email address";
            //   }

            //   if (!values.companyName) {
            //     errors.companyName = "Company Name is required.";
            //   }

            //   if (!values.companyAddress) {
            //     errors.companyAddress = "Company Address is required.";
            //   }
            //   if (!values.companyPostCode) {
            //     errors.companyPostCode = "Company PostCode is required.";
            //   }
            //   if (!values.companyCity) {
            //     errors.companyCity = "Company City is required.";
            //   }
            //   if (!values.companyRegistrationNumber) {
            //     errors.companyRegistrationNumber =
            //       "Company Registration Number is required.";
            //   }
            //   if (!values.companyVATNumber) {
            //     errors.companyVATNumber = "Company VAT Number is required.";
            //   }
            //   if (!values.bankAccountNumber) {
            //     errors.bankAccountNumber = "Bank Account Number is required.";
            //   } else if (
            //     !/^^[A-Z]{2}[A-Za-z0-9]{13,31}$/.test(values.bankAccountNumber)
            //   ) {
            //     errors.bankAccountNumber =
            //       "Bank account number must start with two uppercase letters followed by 13 to 31 alphanumeric characters.";
            //   }
            //   if (!values.bankAccountHolderName) {
            //     errors.bankAccountHolderName =
            //       "Bank Account Holder Name is required.";
            //   }
            //   if (!values.vatPercentage) {
            //     errors.vatPercentage = "Vat Percentage is required.";
            //   }

            //   return errors;
            // }}
            validationSchema={addCustomerValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form style={{ width: "550px" }}>
                {/* Email */}
                <Grid>
                  <Input
                    label="Customer Email*"
                    value={values.email}
                    type="email"
                    size="small"
                    name="email"
                    className={classes.textfield_upper}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                  />
                  <ErrorMessage
                    name={`email`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>
                {/* First Name */}
                <Grid>
                  <Input
                    label="First Name"
                    value={values.firstName}
                    type="text"
                    size="small"
                    name="firstName"
                    className={classes.textfield_upper}
                    onChange={(e) => setFieldValue("firstName", e.target.value)}
                  />
                  <ErrorMessage
                    name={`firstName`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>
                {/* Last Name */}
                <Grid>
                  <Input
                    label="Last Name"
                    value={values.lastName}
                    type="text"
                    size="small"
                    name="lastName"
                    className={classes.textfield_upper}
                    onChange={(e) => setFieldValue("lastName", e.target.value)}
                  />
                  <ErrorMessage
                    name={`lastName`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>
                {/* Company Nam */}
                <Grid>
                  <Input
                    label="Company Name"
                    value={values.companyName}
                    type="text"
                    size="small"
                    name="companyName"
                    className={classes.textfield_upper}
                    onChange={(e) =>
                      setFieldValue("companyName", e.target.value)
                    }
                  />
                  <ErrorMessage
                    name={`companyName`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>

                {/* Company Address */}

                <Grid>
                  <Input
                    label="Company Address"
                    value={values.companyAddress}
                    type="text"
                    size="small"
                    name="companyAddress"
                    className={classes.textfield_upper}
                    onChange={(e) =>
                      setFieldValue("companyAddress", e.target.value)
                    }
                  />
                  <ErrorMessage
                    name={`companyAddress`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>

                {/* Company Postal Code */}
                <Grid>
                  <Input
                    label="Company Postal Code"
                    value={values.companyPostCode}
                    type="text"
                    size="small"
                    name="companyPostCode"
                    className={classes.textfield_upper}
                    onChange={(e) =>
                      setFieldValue("companyPostCode", e.target.value)
                    }
                  />
                  <ErrorMessage
                    name={`companyPostCode`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>
                {/*Company City  */}
                <Grid>
                  <Input
                    label="Company City"
                    value={values.companyCity}
                    type="text"
                    size="small"
                    name="companyCity"
                    className={classes.textfield_upper}
                    onChange={(e) =>
                      setFieldValue("companyCity", e.target.value)
                    }
                  />
                  <ErrorMessage
                    name={`companyCity`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>

                {/* Company Registration Number */}
                <Grid>
                  <Input
                    label="Company Registration Number"
                    value={values.companyRegistrationNumber}
                    type="text"
                    size="small"
                    name="companyRegistrationNumber"
                    className={classes.textfield_upper}
                    onChange={(e) =>
                      setFieldValue("companyRegistrationNumber", e.target.value)
                    }
                  />
                  <ErrorMessage
                    name={`companyRegistrationNumber`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>
                {/* Company VAT Number */}
                <Grid>
                  <Input
                    label="Company VAT Number"
                    value={values.companyVATNumber}
                    type="text"
                    size="small"
                    name="companyVATNumber"
                    className={classes.textfield_upper}
                    onChange={(e) =>
                      setFieldValue("companyVATNumber", e.target.value)
                    }
                  />
                  <ErrorMessage
                    name={`companyVATNumber`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>

                {/* Bank Account Number */}
                <Grid>
                  <Input
                    label="Bank Account Number"
                    value={values.bankAccountNumber}
                    type="text"
                    size="small"
                    name="bankAccountNumber"
                    className={classes.textfield_upper}
                    onChange={(e) =>
                      setFieldValue("bankAccountNumber", e.target.value)
                    }
                  />
                  <ErrorMessage
                    name={`bankAccountNumber`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>

                {/* Bank Account Holder Name */}
                <Grid>
                  <Input
                    label="Bank Account Holder Name"
                    value={values.bankAccountHolderName}
                    type="text"
                    size="small"
                    name="bankAccountHolderName"
                    className={classes.textfield_upper}
                    onChange={(e) =>
                      setFieldValue("bankAccountHolderName", e.target.value)
                    }
                  />
                  <ErrorMessage
                    name={`bankAccountHolderName`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>

                <Grid>
                  <Input
                    label="VAT Percentage"
                    value={values.vatPercentage}
                    type="number"
                    size="small"
                    name="vatPercentage"
                    className={classes.textfield_upper}
                    onChange={(e) =>
                      setFieldValue("vatPercentage", e.target.value)
                    }
                  />
                  <ErrorMessage
                    name={`vatPercentage`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>

                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  marginTop={"24px"}
                >
                  <MOButton
                    submitType="submit"
                    title="Add Customer"
                    disabled={isSubmitting}
                  />
                </Box>
              </Form>
            )}
          </Formik>
          {/* Formik Form handling end */}
        </DialogContent>
      </Dialog>
      {failure.status && (
        <ErrorSnack reset={() => reset()} errorMsg={failure.message} />
      )}
      {success.status && (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"Customer Created Successfully"}
        />
      )}
    </div>
  );
};
const mapState = (state) => ({
  status: state,
  failure: state.addCustomer.error,
  success: state.addCustomer.response,
});

const mapDispatch = {
  addCustomer: ThunksCustomers.addCustomer,
  reset: Local.AddCustomer.api.reset,
};
export default connect(mapState, mapDispatch)(AddCustomer);
