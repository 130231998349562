import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles, createStyles } from "@material-ui/core/styles";

const MobioTextField = withStyles((theme) =>
  createStyles({
    root: {
      "& .MuiInput-underline:before": {
        borderWidth: 0.5,
        borderColor: "#CCCCCC",
        borderTop: 0,
        borderRight: 0,
        borderLeft: 0,
      },
      "& .MuiInput-underline:after": {
        borderColor: theme.palette.primary.main,
        borderTop: 0,
        borderRight: 0,
        borderLeft: 0,
        borderWidth: 0.75,
      },
      "& .MuiInputBase-input": {
        "&:hover": {
          borderColor: "#add8e6",
        },
      },
      "& .MuiInput-formControl": {
        marginTop: 24,
      },
      "& .MuiFormLabel-root": {
        fontSize: "0.875rem",
        [theme.breakpoints.up("xl")]: {
          fontSize: "1rem",
        },
      },
    },
  })
)(TextField);

export default MobioTextField;
