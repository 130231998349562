export type CreateBookingState = {
  pickup: {
    name: String;
    coordinates: any;
  };
  destination: {
    name: String;
    coordinates: any;
  };
  dateTime: string;
  noOfPeople: any;
  carType: string;
  fare: any;
  clientId: any;
  guestName: string;
  guestPhoneNumber: string;
  flightNumber: any;
  nameCaller: string;
  paymentType: string;
  note: string;
  bookingReference: string;
};


const createBookingState: CreateBookingState = {
  pickup: {
    name: "",
    coordinates: [],
  },
  destination: {
    name: "",
    coordinates: [],
  },
  dateTime: "",
  noOfPeople: 1,
  carType: "STANDARD",
  fare: "",
  clientId: "",
  guestName: "",
  guestPhoneNumber: "",
  flightNumber: "",
  nameCaller: "",
  paymentType: "CASH",
  note: "",
  bookingReference: "",
};

// Dispatcher in edit and duplicate booking in web should be removed
// export interface BookingState {
//   isLoading: boolean;
//   error: null | string;
//   data: null | any;
// }

// const initialBookingState: BookingState = {
//   isLoading: false,
//   error: null,
//   data: null,
// };

export default createBookingState;
