import * as Yup from "yup";

export const addCustomerValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  // firstName: Yup.string()
  //   .min(3, "Must be at least 3 characters")
  //   // .matches(/^\S+(?: \S+)*$/, "Empty space is not allowed")
  //   .matches(/^(?!.* {2})[a-zA-Z\s]+$/, "Double empty space is not allowed")
  //   .matches(/^(?=.*[a-zA-Z])[a-zA-Z\s]*$/, "Invalid Full Name")
  //   .required("First Name is required."),
  // lastName: Yup.string()
  //   .min(3, "Must be at least 3 characters")
  //   .matches(/^(?!.* {2})[a-zA-Z\s]+$/, "Double empty space is not allowed")
  //   .matches(/^(?=.*[a-zA-Z])[a-zA-Z\s]*$/, "Invalid Full Name")
  //   .required("Last Name is required."),
  companyName: Yup.string()
    .min(3, "Must be at least 3 characters")
    .required("Company Name is required.")
    .matches(/^(?!.* {2})[a-zA-Z\s]+$/, "Double empty space is not allowed")
    .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9\s]*$/, "Invalid company name"),
  // companyAddress: Yup.string()
  //   .required("Company Address required")
  //   .min(3, "Must be at least 3 characters")
  //   .matches(/^(?!.* {2})[a-zA-Z\s]+$/, "Double empty space is not allowed")
  //   .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9\s]*$/, "Invalid Company address"),
  // companyPostCode: Yup.string()
  //   .required("Company PostCode required")
  //   .min(3, "Must be at least 3 characters")
  //   .matches(/^[^\W_]+$/, "Invalid characters"),
  // companyCity: Yup.string()
  //   .required("Company City is required")
  //   .min(3, "Must be at most 3 characters")
  //   .matches(/^(?!.* {2})[a-zA-Z\s]+$/, "Double empty space is not allowed")
  //   .matches(/^(?=.*[a-zA-Z])[a-zA-Z\s]*$/, "Invalid Company city"),
  // companyRegistrationNumber: Yup.string()
  //   .required("Company Registration Number is required")
  //   .min(3, "Must be at most 3 characters")
  //   .matches(/^[^\W_]+$/, "Invalid characters"),

  // companyVATNumber: Yup.string()
  //   .required("Company VAT Number is required")
  //   .matches(/^\d+$/, "Invalid VAT number"),
  // bankAccountNumber: Yup.string()
  //   .required("Bank account number is required")
  //   .matches(
  //     /^[A-Z]{2}[A-Za-z0-9]{13,31}$/,
  //     "Bank account number must start with two uppercase letters followed by 13 to 31 alphanumeric characters."
  //   ),
  // bankAccountHolderName: Yup.string()
  //   .min(3, "Must be at least 3 characters")
  //   .required("Bank Account Holder Name is required")
  //   .matches(/^[^\d]+( [\w\s]+)*$/, "Cannot contain numbers")
  //   .matches(/^[A-Za-z\s]*$/, "Cannot contain special characters"),
  vatPercentage: Yup.number()
    .required("Vat Percentage is required")
    .min(0, "Vat Percentage must be 0 or more"),
});
