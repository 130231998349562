import { Close } from "@material-ui/icons";
import { useState } from "react";
import SearchIcon2 from "../../assets/icons/SearchIcon2.svg";

const SearchingInput = ({
  className = "",
  crossIcon = false,
  onSearching = () => {},
}) => {
  const [searchValue, setSearchValue] = useState("");
  return (
    <div className={`searching-input-section ${className}`}>
      <img src={SearchIcon2} />
      <input
        placeholder="Search"
        value={searchValue}
        onChange={(e) => {
          const value = e.target.value;
          setSearchValue(value);
          onSearching(value);
        }}
      />
      {crossIcon && (
        <Close
          style={{
            color: "#737373",
            width: "14px",
            height: "14px",
            cursor: "pointer",
          }}
          onClick={() => {
            setSearchValue("");
            onSearching("");
          }}
        />
      )}
    </div>
  );
};

export default SearchingInput;
