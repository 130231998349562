import React from 'react'

function Privacy() {
    return (
        <object data="https://www.mobiodispatch.com/privacy/view/" type="application/pdf" width="100%" height="1200px">
            <p>Alternative text - include a link <a href="https://www.mobiodispatch.com/privacy/view/">to the PDF!</a></p>
        </object>
    )
}

export default Privacy