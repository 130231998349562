
import { Grid, makeStyles } from "@material-ui/core";
const useStyles = makeStyles(() => ({
    detailsInfo: {
      width: '90%',
      marginBottom: 10,
    }
  }));
function CompanyDetails({rowData}) {
    const classes = useStyles();
    return (
        <>
        
            <h3 style={{marginLeft: 20}}>Company Details</h3>
            { !rowData.companyName && !rowData.companyCity && !rowData.companyPostCode && !rowData.companyAddress &&
            !rowData.companyRegistrationNumber && !rowData.companyVATNumber && !rowData.bankAccountNumber && !rowData.bankAccountHolderName ? 
            <Grid xs = {12} style={{ marginBottom: 10}}>
                <p style={{textAlign: "center"}}>No information is available yet.</p>
            </Grid>
            :
            <>
                <div style={{display:"flex", direction: "row"}}> 
                    <Grid xs = {5} style={{marginLeft: 20}}>
                        {rowData.companyName &&
                            <div class={classes.detailsInfo}>
                            <h4 style={{ margin: 0,  }}>Name:</h4>
                            {rowData.companyName}
                            </div>
                        }
                        {rowData.companyCity &&
                            <div class={classes.detailsInfo}>
                            <h4 style={{ margin: 0, fontSize: "15px" }}>City:</h4>
                            {rowData.companyCity}
                            </div>
                        }
                        {rowData.companyPostCode &&
                            <div class={classes.detailsInfo}>
                            <h4 style={{ margin: 0, fontSize: "15px" }}>PostCode:</h4>
                            {rowData.companyPostCode}
                            </div>
                        }
                        {rowData.companyAddress &&
                            <div class={classes.detailsInfo}>
                            <h4 style={{ margin: 0, fontSize: "15px" }}>Address:</h4>
                            {rowData.companyAddress}
                            </div>
                        }
                    </Grid>
                    <Grid xs = {12} style={{marginLeft: 20}}>
                    {rowData.companyRegistrationNumber &&
                        <div class={classes.detailsInfo}>
                        <h4 style={{ margin: 0, fontSize: "15px" }}>Registration Number:</h4>
                        {rowData.companyRegistrationNumber}
                        </div>
                    }
                    {rowData.companyVATNumber &&
                        <div class={classes.detailsInfo}>
                        <h4 style={{ margin: 0, fontSize: "15px" }}>VAT Number:</h4>
                        {rowData.companyVATNumber}
                        </div>
                    }
                    {rowData.IBAN &&
                        <div class={classes.detailsInfo}>
                        <h4 style={{ margin: 0, fontSize: "15px" }}>Bank Account Number:</h4>
                        {rowData.IBAN}
                        </div>
                    }
                    {rowData.bankAccountHolderName &&
                        <div class={classes.detailsInfo}>
                        <h4 style={{ margin: 0, fontSize: "15px" }}>Bank Account Holder Name:</h4>
                        {rowData.bankAccountHolderName}
                        </div>
                    }
                    
                </Grid>
                </div>
            </>
            
            }
        </>
    );

}


export default CompanyDetails;