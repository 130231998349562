import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import Closed from "../../assets/icons/Closed.svg";
import StarttoEnd from "../../assets/icons/StarttoEnd.svg";
import hrstimings from "../../assets/icons/hrstimings.png";

// Custom styles for react-select
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "31px",
    maxHeight: "31px",
    boxShadow: "none",
    border: "0.2px solid #000000",
    padding: 0,
    outline: "none",
    borderRadius: "3px",
    backgroundColor: "white",
    // cursor: state.isDisabled ? "not-allowed" : "default",
    width: "100%", // Ensure control takes full width
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 8px 0px",
    display: "flex",
    alignItems: "center",
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "400",
    height: "31px",
    padding: "0 8px 8px",
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    backgroundColor: state.isSelected ? "#f0f0f0" : "white",
    color: "black",
    height: "31px",
    padding: "0 8px",
  }),
  menu: (provided) => ({
    ...provided,
    width: "100%", // Ensure menu takes full width
    height: "118px",
    gap: "0px",
    opacity: 1,
    marginTop: "4px",
    position: "absolute",
    right: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    marginTop: "10px",
    marginBottom: "24px",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};

// Options for the dropdown
const options = [
  {
    value: "24Hours",
    label: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          fontWeight: "500",
          height: "31px",
        }}
      >
        <img
          src={hrstimings}
          alt="24hrs"
          style={{ marginRight: "5px", height: "20px", objectFit: "contain" }}
        />
        24 Hours
      </div>
    ),
    text: "24 Hours",
  },
  {
    value: "startToEnd",
    label: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          fontWeight: "500",
          height: "31px",
        }}
      >
        <img
          src={StarttoEnd}
          alt="StarttoEnd"
          style={{
            marginRight: "5px",
            objectFit: "contain",
            marginLeft: "5px",
          }}
        />
        Apply Start to End
      </div>
    ),
    text: "Apply Start to End",
  },
  {
    value: "closed",
    label: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          fontWeight: "500",
          height: "31px",
        }}
      >
        <img
          src={Closed}
          alt="Closed"
          style={{
            marginRight: "5px",
            objectFit: "contain",
            marginLeft: "5px",
            height: "12px",
          }}
        />
        Closed
      </div>
    ),
    text: "Closed",
  },
];

// Custom Option component to include radio buttons
const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center", height: "31px" }}>
        <div
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            border: `2px solid ${props.isSelected ? "#24A0ED" : "#ccc"}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "12px",
            position: "relative",
          }}
        >
          <input
            type="radio"
            name="custom-radio"
            checked={props.isSelected}
            onChange={() => {}}
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: props.isSelected ? "#24A0ED" : "#878787",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              border: "none",
              cursor: "pointer",
            }}
          />
        </div>
        {props.data.label}
      </div>
    </components.Option>
  );
};

// Custom SingleValue component to show only the text of the selected option
const CustomSingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      {props.data.text}
    </components.SingleValue>
  );
};

const DropDownPickupTimings = ({
  handleOnChange = () => {},
  value,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    if (value) {
      const matchedOption = options?.find(
        (option) => option.value === value.value
      );
      setSelectedOption(matchedOption);
    }
  }, [value]);

  const handleChange = (option) => {
    setSelectedOption(option);
    handleOnChange(option);
  };

  return (
    <Select
      {...props}
      placeholder={"Select"}
      options={options}
      styles={customStyles}
      onChange={handleChange}
      value={selectedOption}
      components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
    />
  );
};

export default DropDownPickupTimings;
