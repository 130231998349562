import {
  DIALOG,
  GET_USER_AUTHORIZATIONS,
  ORG_AUTHORIZATIONS,
  ORG_USER_API,
  ORG_USER_DELETE_API,
  ORG_USER_GET_API,
  ORG_USER_UPDATE_API,
  UPDATE_ORG_USER_PASSWORD,
  USER_UPDATE_API,
} from "../../actions/local/constants/orgUsers";

import orgUserState, { orgUsersState } from "../../initial-state/OrgUsers";

const orgUsers = (state: orgUsersState = orgUserState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case ORG_USER_API.SET_RESPONSE: {
      return {
        ...state,
        createUser: { success: "Successfully Created User", error: "" },
      };
    }
    case ORG_USER_API.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        createUser: { success: "", error: message },
      };
    }
    case ORG_USER_API.RESET: {
      return {
        ...state,
        createUser: { success: "", error: "" },
      };
    }
    case DIALOG.OPEN_DIALOG: {
      return {
        ...state,
        dialog: { isOpen: true },
      };
    }
    case DIALOG.CLOSE_DIALOG: {
      return {
        ...state,
        dialog: { isOpen: false },
      };
    }
    case DIALOG.SELECT_USER: {
      const { data } = payload;
      return {
        ...state,
        dialog: { selectedUser: data, isOpen: true },
      };
    }
    case ORG_AUTHORIZATIONS.SET_RESPONSE: {
      const { data } = payload;
      return {
        ...state,
        authorizations: data,
      };
    }
    case ORG_AUTHORIZATIONS.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        authorizationsError: message,
      };
    }
    case ORG_USER_GET_API.SET_RESPONSE: {
      const { data } = payload;

      return {
        ...state,
        allOrgUsers: data,
      };
    }
    case ORG_USER_GET_API.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        allOrgUsersError: message,
      };
    }
    case ORG_USER_DELETE_API.SET_RESPONSE: {
      return {
        ...state,
        deleteUser: { success: "Successfully Deleted User", error: "" },
      };
    }
    case ORG_USER_DELETE_API.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        deleteUser: { success: "", error: message },
      };
    }
    case ORG_USER_DELETE_API.RESET: {
      return {
        ...state,
        deleteUser: { success: "", error: "" },
      };
    }
    case ORG_USER_UPDATE_API.SET_RESPONSE: {
      return {
        ...state,
        updateUser: { success: "Successfully Updated User", error: "" },
      };
    }
    case ORG_USER_UPDATE_API.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        updateUser: { success: "", error: message },
      };
    }
    case ORG_USER_UPDATE_API.RESET: {
      return {
        ...state,
        updateUser: { success: "", error: "" },
      };
    }
    case USER_UPDATE_API.SET_RESPONSE: {
      return {
        ...state,
        updateCompleteUser: { success: "Successfully Updated User", error: "" },
      };
    }
    case USER_UPDATE_API.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        updateCompleteUser: { success: "", error: message },
      };
    }
    case USER_UPDATE_API.RESET: {
      return {
        ...state,
        updateCompleteUser: { success: "", error: "" },
      };
    }
    case UPDATE_ORG_USER_PASSWORD.SET_RESPONSE: {
      return {
        ...state,
        updatePass: { success: "Successfully Updated Password", error: "" },
      };
    }
    case UPDATE_ORG_USER_PASSWORD.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        updatePass: { success: "", error: message },
      };
    }
    case UPDATE_ORG_USER_PASSWORD.RESET_ERROR: {
      return {
        ...state,
        updatePass: { success: "", error: "" },
      };
    }
    default:
      return state;
  }
};

export default orgUsers;
