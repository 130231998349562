export const BOOKINGS_LISTING_API = {
  SET_REQUEST: "ACTION.BOOKINGS_LISTING.SET_REQUEST",
  SET_RESPONSE: "ACTION.BOOKINGS_LISTING.SET_RESPONSE",
  SET_ERROR: "ACTION.BOOKINGS_LISTING.SET_ERROR",
  SET_RETRY: "ACTION.BOOKINGS_LISTING.SET_RETRY",
  RESET: "ACTION.BOOKINGS_LISTING.RESET",
};

export const DISPATCHERS_LISTING_API = {
  SET_REQUEST: "ACTION.DISPATCHERS_LISTING.SET_REQUEST",
  SET_RESPONSE: "ACTION.DISPATCHERS_LISTING.SET_RESPONSE",
  SET_ERROR: "ACTION.DISPATCHERS_LISTING.SET_ERROR",
  SET_RETRY: "ACTION.DISPATCHERS_LISTING.SET_RETRY",
};

// Dispatcher in edit and duplicate booking in web should be removed
// export const DISPATCHERS_BY_BOOKING_API = {
//   SET_REQUEST: "ACTION.DISPATCHERS_BY_BOOKING.SET_REQUEST",
//   SET_RESPONSE: "ACTION.DISPATCHERS_BY_BOOKING.SET_RESPONSE",
//   SET_ERROR: "ACTION.DISPATCHERS_BY_BOOKING.SET_ERROR",
//   SET_RETRY: "ACTION.DISPATCHERS_BY_BOOKING.SET_RETRY",
// };

export const CREATE_BOOKING_API = {
  SET_REQUEST: "ACTION.CREATE_BOOKING.SET_REQUEST",
  SET_RESPONSE: "ACTION.CREATE_BOOKING.SET_RESPONSE",
  SET_ERROR: "ACTION.CREATE_BOOKING.SET_ERROR",
  SET_RETRY: "ACTION.CREATE_BOOKING.SET_RETRY",
  RESET: "ACTION.CREATE_BOOKING.RESET",
};
export const BOOKING_INTEGRATION_API = {
  SET_REQUEST: "ACTION.BOOKING_INTEGRATION.SET_REQUEST",
  SET_RESPONSE: "ACTION.BOOKING_INTEGRATION.SET_RESPONSE",
  SET_ERROR: "ACTION.BOOKING_INTEGRATION.SET_ERROR",
  SET_RETRY: "ACTION.BOOKING_INTEGRATION.SET_RETRY",
  RESET: "ACTION.BOOKING_INTEGRATION.RESET",
};
const BOOKING_INTEGRATION = {
  RESET_ALL: "ACTION.LOCAL.BOOKING_INTEGRATION.RESET_ALL",
  FIELD: {
    UPDATE: "ACTION.LOCAL.BOOKING_INTEGRATION.FIELD.UPDATE",
    CLEAR: "ACTION.LOCAL.BOOKING_INTEGRATION.FIELD.CLEAR",
  },
  FLAGS: {
    TOGGLE_COMPLETE: "ACTION.LOCAL.BOOKING_INTEGRATION.FLAGS.TOGGLE_COMPLETE",
    UPDATE_STEP: "ACTION.LOCAL.BOOKING_INTEGRATION.FLAGS.UPDATE_STEP",
    RESET: "ACTION.LOCAL.BOOKING_INTEGRATION.FLAGS.RESET",
  },
  BOOKING_INTEGRATION_API,
};

const CREATE_BOOKING = {
  RESET_ALL: "ACTION.LOCAL.CREATE_BOOKING.RESET_ALL",
  FIELD: {
    UPDATE: "ACTION.LOCAL.CREATE_BOOKING.FIELD.UPDATE",
    CLEAR: "ACTION.LOCAL.CREATE_BOOKING.FIELD.CLEAR",
  },
  FLAGS: {
    TOGGLE_COMPLETE: "ACTION.LOCAL.CREATE_BOOKING.FLAGS.TOGGLE_COMPLETE",
    UPDATE_STEP: "ACTION.LOCAL.CREATE_BOOKING.FLAGS.UPDATE_STEP",
    RESET: "ACTION.LOCAL.CREATE_BOOKING.FLAGS.RESET",
  },
  CREATE_BOOKING_API,
};
const BOOKINGS_LISTING = {
  BOOKINGS_LISTING_API,
};

const DISPATCHERS_LISTING = {
  DISPATCHERS_LISTING_API,
};
const BOOKING_STATS = {
  SET_REQUEST: "ACTION.BOOKING_STATS.SET_REQUEST",
  SET_RESPONSE: "ACTION.BOOKING_STATS.SET_RESPONSE",
  SET_ERROR: "ACTION.BOOKING_STATS.SET_ERROR",
  SET_RESET: "ACTION.BOOKING_STATS.SET_RESET",
  SET_RETRY: "ACTION.BOOKING_STATS.SET_RETRY",
};

// const DISPATCHERS_BY_BOOKING = {
//   DISPATCHERS_BY_BOOKING_API,
// };

export {
  BOOKINGS_LISTING,
  DISPATCHERS_LISTING,
  // DISPATCHERS_BY_BOOKING,
  CREATE_BOOKING,
  BOOKING_INTEGRATION,
  BOOKING_STATS
};
