import React, { forwardRef, useEffect, useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import CommuteOutlinedIcon from "@material-ui/icons/CommuteOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import LocalTaxiOutlinedIcon from "@material-ui/icons/LocalTaxiOutlined";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import DepartureBoardOutlinedIcon from "@material-ui/icons/DepartureBoardOutlined";
import PaymentOutlined from "@material-ui/icons/PaymentOutlined";
import PersonIcon from "@material-ui/icons/Person";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";

import Settings from "@material-ui/icons/Settings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { LocalUserRepo } from "../../../redux/actions/local/user";
import { FC } from "react";
import ThunksLogin from "../../../redux/actions/thunks/login";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import List from "@material-ui/core/List";
import { Roles } from "../../../enum";
import ThunksUsers from "../../../redux/actions/thunks/OrgUsers";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import Local from "../../../redux/actions/local";
import CustomerUsers from "../../../redux/actions/thunks/CustomerUsers";
import SubAdminThunks from "../../../redux/actions/thunks/SubAdmins";
import { BookingIcon } from "../../../components/iconLibrary/BookingIcon";
import MangeUserIcon from "../../../components/iconLibrary/MangeUserIcon";
import MyProfileIcon from "../../../components/iconLibrary/MyProfileIcon";
import MangePassengersIcon from "../../../components/iconLibrary/MangePassengersIcon";
import InvoiceIcon from "../../../components/iconLibrary/InvoiceIcon";
import SettingsIcon from "../../../components/iconLibrary/SettingsIcon";
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, Slide, Typography } from "@material-ui/core";
import ChatIcon from "../../../components/iconLibrary/ChatIcon";
import { LogoutIcon } from "../../../assets/icons";
import MOButton from "../../../components/commons/MOButton";
import { Biotech, Circle } from "@mui/icons-material";
import ThunksCustomers from "../../../redux/actions/thunks/customer";
// import { BookingIcon } from "../../../assets/icons";

interface Props {
  resetAll: LocalUserRepo["resetAll"];
  getAuthorities: (data: any) => any;
  getCustomerAuthorities: (data: any) => any;
  resetError: () => any;
  getSubAdminAuthorities: (data: any) => any;
  role: any;
  userAuthorities: any;
  userData: any;
  userError: any;
  handleHeaderDetail: (title: String, detail: String) => any
  getCustomers: () => any;
}

const useStyles = makeStyles((theme: Theme) => ({
  colorAction: {
    color: theme.palette.secondary.main,
  },
  itemMenuTitle: {
    fontSize: "16px",
    fontWeight: "bolder"
  },
  modalRoot: {
    ".MuiDialogContent-root": {
      // width:"900px"
    },
    "& .MuiDialog-paperWidthLg": {
      // borderRadius:32,
    },
    "& .MuiDialog-paper": {
      // width: "900px !important"
    },
    "& .MuiDialog-paperWidthSm": {
      width: "690px !important",

    },
    "& .MuiDialogTitle-root": {},
    "& .MuiPaper-rounded": {
      borderRadius: 32,
    },
  },
}));
// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });
const MainListItems: FC<Props> = ({
  resetAll,
  getAuthorities,
  resetError,
  role,
  userData,
  userAuthorities,
  userError,
  getCustomerAuthorities,
  getSubAdminAuthorities,
  handleHeaderDetail,
  getCustomers
}) => {
  const [open, setOpen] = useState(true);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const paramValue = params.get("integration");

  const classes = useStyles();
  const { pathname } = useLocation();
  const isCurrentPath = (path: string): boolean => {
    return pathname.endsWith(path);
  };
  React.useEffect(() => {
    if (role === "Organization") {
      getCustomers()
    }
    if (role === "OrgUser") {
      getAuthorities(userData.myId);
    } else if (role === "CustomerUser") {
      getCustomerAuthorities(userData.myId);
    } else if (role === "SubAdmin") {
      getSubAdminAuthorities(userData.myId);
    }
  }, []);
  React.useEffect(() => {
    if (
      (role === "OrgUser" || role === "CustomerUser" || role === "SubAdmin") &&
      userData.block === true
    ) {
      resetAll();
    }
  }, [userData.block]);
  const handleLogoutClose = () => {
    setLogoutModalOpen(false);
  };
  return (
    <>
      <Box className="aside-bar-menu-section">
        <Box className="aside-bar-menu-section-first">
          {role == "Admin" && (
            <>
              <ListItem
                button
                component={Link}
                to="/dashboard/onboardings"
                style={
                  pathname.endsWith("onboardings")
                    ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                    : {}
                }
              >
                <ListItemIcon style={{ minWidth: "37px" }}>
                  <PostAddOutlinedIcon className={classes.colorAction}
                    style={{
                      color: pathname.endsWith("onboardings")
                        ? "#29AEE6"
                        : "#95919E"
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Onbordings" className={"itemMenuTitle"}
                  style={
                    pathname.endsWith("onboardings")
                      ? { color: "#29AEE6", }
                      : { color: "#95919E" }}
                />
              </ListItem>

              <ListItem
                button
                component={Link}
                to="/dashboard/taxi-companies"
                style={
                  pathname.endsWith("companies")
                    ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                    : {}
                }
              >
                <ListItemIcon style={{ minWidth: "37px" }}>
                  <LocalTaxiOutlinedIcon className={classes.colorAction}
                    style={{
                      color: pathname.endsWith("companies")
                        ? "#29AEE6"
                        : "#95919E"
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Taxi Companies"
                  className={"itemMenuTitle"}
                  style={
                    pathname.endsWith("companies")
                      ? { color: "#29AEE6", }
                      : { color: "#95919E" }}
                />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/dashboard/vehicles"
                style={
                  pathname.endsWith("vehicles")
                    ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                    : {}
                }
              >
                <ListItemIcon style={{ minWidth: "37px" }}>
                  <CommuteOutlinedIcon className={classes.colorAction}
                    style={{
                      color: pathname.endsWith("vehicles")
                        ? "#29AEE6"
                        : "#95919E"
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Vehicles" className={"itemMenuTitle"}
                  style={
                    pathname.endsWith("vehicles")
                      ? { color: "#29AEE6", }
                      : { color: "#95919E" }}
                />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/dashboard/sub-admin"
                style={
                  pathname.endsWith("-admin")
                    ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                    : {}
                }
              >
                <ListItemIcon style={{ minWidth: "37px" }}>
                  <SupervisedUserCircleIcon className={classes.colorAction}
                    style={{
                      color: pathname.endsWith("-admin")
                        ? "#29AEE6"
                        : "#95919E"
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Manage Sub Admins"
                  className={"itemMenuTitle"}
                  style={
                    pathname.endsWith("-admin")
                      ? { color: "#29AEE6", }
                      : { color: "#95919E" }}
                />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/dashboard/networks"
                style={
                  pathname.endsWith("networks")
                    ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                    : {}
                }
              >
                <ListItemIcon style={{ minWidth: "37px" }}>
                  <AccountTreeOutlinedIcon className={classes.colorAction}
                    style={{
                      color: pathname.endsWith("networks")
                        ? "#29AEE6"
                        : "#95919E"
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Networks" className={"itemMenuTitle"}
                  style={
                    pathname.endsWith("networks")
                      ? { color: "#29AEE6", }
                      : { color: "#95919E" }}
                />
              </ListItem>
              <ListItem
                button
                component={Link}
                to="/dashboard/drivers"
                style={
                  pathname.endsWith("drivers")
                    ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                    : {}
                }
              >
                <ListItemIcon style={{ minWidth: "37px" }}>
                  <PermContactCalendarOutlinedIcon
                    className={classes.colorAction}
                    style={{
                      color: pathname.endsWith("drivers")
                        ? "#29AEE6"
                        : "#95919E"
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Drivers" className={"itemMenuTitle"}
                  style={
                    pathname.endsWith("drivers")
                      ? { color: "#29AEE6", }
                      : { color: "#95919E" }}
                />
              </ListItem>

              <ListItem
                button
                component={Link}
                to="/dashboard/dispatchers"
                style={
                  pathname.endsWith("dispatchers")
                    ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                    : {}
                }
              >
                <ListItemIcon style={{ minWidth: "37px" }}>
                  <ContactPhoneOutlinedIcon className={classes.colorAction}
                    style={{
                      color: pathname.endsWith("dispatchers")
                        ? "#29AEE6"
                        : "#95919E"
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Dispatchers" className={"itemMenuTitle"}
                  style={
                    pathname.endsWith("dispatchers")
                      ? { color: "#29AEE6", }
                      : { color: "#95919E" }}
                />
              </ListItem>
            </>
          )}
          {role == "Customer" && (
            <>
              <ListItem
                button
                component={Link}
                to="/dashboard/customer-bookings"
                // { backgroundColor: "#C7E4EF" }
                onClick={() => {
                  handleHeaderDetail("Bookings", "Here is the information about all your orders ")
                }}
                style={
                  pathname.endsWith("-bookings")
                    ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                    : {}
                }
              >
                <ListItemIcon style={{ minWidth: "37px" }}>
                  {/* <DepartureBoardOutlinedIcon className={classes.colorAction} /> */}
                  <BookingIcon color={pathname.endsWith("-bookings")
                    ? "#29AEE6"
                    : "#95919E"} />
                </ListItemIcon>
                <ListItemText primary="Bookings" className={"itemMenuTitle"}
                  style={
                    pathname.endsWith("-bookings")
                      ? { color: "#29AEE6", }
                      : { color: "#95919E" }} />
              </ListItem>
              {/* Manage User */}
              <ListItem
                button
                component={Link}
                to="/dashboard/customer-users"
                onClick={() => {
                  handleHeaderDetail("Manage User", "Here is the information about all your orders ")
                }}
                style={
                  pathname.endsWith("-users") ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" } : {}
                }
              >
                <ListItemIcon style={{ minWidth: "37px" }}>
                  {/* <SupervisedUserCircleIcon className={classes.colorAction} /> */}
                  <MangeUserIcon color={pathname.endsWith("-users")
                    ? "#29AEE6"
                    : "#95919E"} />
                </ListItemIcon>
                <ListItemText primary="Manage Users" className={"itemMenuTitle"}
                  style={
                    pathname.endsWith("-users")
                      ? { color: "#29AEE6", }
                      : { color: "#95919E" }} />
              </ListItem>
              {/* My Profile */}
              <ListItem
                button
                component={Link}
                to="/dashboard/customer-profile"
                onClick={() => {
                  handleHeaderDetail("My Profile", "Here is the information about all your orders ")
                }}
                style={
                  pathname.endsWith("-profile")
                    ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                    : {}
                }
              >
                <ListItemIcon style={{ minWidth: "37px" }}>
                  {/* <PersonIcon className={classes.colorAction} /> */}
                  <MyProfileIcon color={pathname.endsWith("-profile")
                    ? "#29AEE6"
                    : "#95919E"} />
                </ListItemIcon>
                <ListItemText
                  primary="Customer Profile"
                  className={"itemMenuTitle"}
                  style={
                    pathname.endsWith("-profile")
                      ? { color: "#29AEE6", }
                      : { color: "#95919E" }}
                />
              </ListItem>
              {/* Manage Passengers */}
              <ListItem
                button
                component={Link}
                to="/dashboard/manage-passengers"
                onClick={() => {
                  handleHeaderDetail("Manage Passengers", "Here is the information about all your orders ")
                }}
                style={
                  pathname.endsWith("-passengers")
                    ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                    : {}
                }
              >
                <ListItemIcon style={{ minWidth: "37px" }}>

                  <MangePassengersIcon color={pathname.endsWith("-passengers")
                    ? "#29AEE6"
                    : "#95919E"} />
                </ListItemIcon>
                <ListItemText
                  primary="Manage Passengers"
                  className={"itemMenuTitle"}
                  style={
                    pathname.endsWith("-passengers")
                      ? { color: "#29AEE6", }
                      : { color: "#95919E" }}
                />
              </ListItem>
              {/* Invoice */}
              <ListItem
                button
                component={Link}
                to="/dashboard/customer-invoice"
                onClick={() => {
                  handleHeaderDetail("Invoice", "Here is the information about all your orders ")
                }}
                style={
                  pathname.endsWith("-invoice")
                    ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                    : {}
                }
              >
                <ListItemIcon style={{ minWidth: "37px" }}>
                  <InvoiceIcon color={pathname.endsWith("-invoice")
                    ? "#29AEE6"
                    : "#95919E"} />
                </ListItemIcon>
                <ListItemText
                  primary="Invoice"
                  className={"itemMenuTitle"}
                  style={
                    pathname.endsWith("-invoice")
                      ? { color: "#29AEE6", }
                      : { color: "#95919E" }}
                />
              </ListItem>
              {/* Setting */}
              {/* <ListItem
                button
                component={Link}
                to="/dashboard/customer-setting"
                onClick={()=>{
                  handleHeaderDetail("Setting","Here is the information about all your orders ")
                }}
                style={
                  pathname.endsWith("-setting")
                    ? { backgroundColor: "#F7F7FB",borderRightColor: "#2CA9DD", borderRightWidth:4,borderStyle:"solid"}
                    : {}
                }
              >
                <ListItemIcon style={{minWidth:"37px"}}>
                <SettingsIcon color={pathname.endsWith("-setting")
                    ? "#29AEE6"
                    : "#95919E"}/>
                </ListItemIcon>
                <ListItemText
                  primary="Setting"
                  className={"itemMenuTitle"}
                  style={
                    pathname.endsWith("-setting")
                      ? { color: "#29AEE6", }
                      : { color: "#95919E"}}
                />
              </ListItem> */}
            </>
          )}
          {role == "Admin" ||
            (role == "Organization" && (
              <>
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/customers"
                  onClick={() => {
                    handleHeaderDetail("Customers", "Here is the information about all your orders ")
                  }}
                  style={
                    pathname.endsWith("customers")
                      ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                      : {}
                  }
                >
                  <ListItemIcon style={{ minWidth: "37px" }}>
                    <ContactPhoneOutlinedIcon className={classes.colorAction}
                      style={{
                        color: pathname.endsWith("customers")
                          ? "#29AEE6"
                          : "#95919E"
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Customers" className={"itemMenuTitle"}
                    style={
                      pathname.endsWith("customers")
                        ? { color: "#29AEE6", }
                        : { color: "#95919E" }}
                  />
                </ListItem>

                <ListItem
                  button
                  component={Link}
                  to="/dashboard/bookings"
                  onClick={() => {
                    handleHeaderDetail("Bookings", "Here is the information about all your orders ")
                  }}
                  style={
                    pathname.endsWith("bookings")
                      ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                      : {}
                  }
                >
                  <ListItemIcon style={{ minWidth: "37px" }}>
                    <DepartureBoardOutlinedIcon className={classes.colorAction}
                      style={{
                        color: pathname.endsWith("bookings")
                          ? "#29AEE6"
                          : "#95919E"
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Bookings" className={"itemMenuTitle"}
                    style={
                      pathname.endsWith("bookings")
                        ? { color: "#29AEE6", }
                        : { color: "#95919E" }}
                  />
                </ListItem>

                <ListItem
                  button
                  component={Link}
                  to="/dashboard/org-users"
                  onClick={() => {
                    handleHeaderDetail("Organization Users", "Here is the information about all your orders ")
                  }}
                  style={
                    pathname.endsWith("org-users")
                      ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                      : {}
                  }
                >
                  <ListItemIcon style={{ minWidth: "37px" }}>
                    <SupervisedUserCircleIcon className={classes.colorAction}
                      style={{
                        color: pathname.endsWith("org-users")
                          ? "#29AEE6"
                          : "#95919E"
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Organization Users"
                    className={"itemMenuTitle"}
                    style={
                      pathname.endsWith("org-users")
                        ? { color: "#29AEE6", }
                        : { color: "#95919E" }}
                  />
                </ListItem>
               
         
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/payments"
                  onClick={() => {
                    handleHeaderDetail("Payments", "Here is the information about all your orders ")
                  }}
                  style={
                    pathname.endsWith("payments")
                      ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                      : {}
                  }
                >
                  <ListItemIcon style={{ minWidth: "37px" }}>
                    <PaymentOutlined className={classes.colorAction}
                      style={{
                        color: pathname.endsWith("payments")
                          ? "#29AEE6"
                          : "#95919E"
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Payments" className={"itemMenuTitle"}
                    style={
                      pathname.endsWith("payments")
                        ? { color: "#29AEE6", }
                        : { color: "#95919E" }} />
                </ListItem>
         
                {paramValue == "booking.com" || paramValue == "worldTransfer.com" ? (
                  <>
                    <ListItemButton component={Link} to="/dashboard/integration" style={{ padding: "8px 16px" }}>
                      <ListItemIcon style={{ minWidth: "37px" }} >
                        <MergeTypeIcon className={classes.colorAction}
                          style={{
                            color: pathname.endsWith("integration")
                              ? "#29AEE6"
                              : "#95919E"
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Integration"
                        className={"itemMenuTitle"}
                        style={
                          pathname.endsWith("integration")
                            ? { color: "#29AEE6", }
                            : { color: "#95919E" }}
                      />
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {/* API */}
                        <ListItemButton
                          sx={{ pl: 3 }}
                          component={Link}
                          style={
                            pathname.endsWith("api-key")
                              ? { backgroundColor: "#C7E4EF", display: "flex", padding: "2px 16px 2px 62px" }
                              : { display: "flex", padding: "2px 16px 2px 62px"  }
                          }
                          to={`/dashboard/integration/api-key?integration=${paramValue}`}
                        >
                          <ListItemIcon style={{ minWidth: "26px" }}>
                            <Circle className={classes.colorAction}
                              style={{
                                color: pathname.endsWith("api-key")
                                  ? "#29AEE6"
                                  : "#95919E",
                                  fontSize:12,

                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="API Key"
                            className={"itemMenuTitle"}
                            style={
                              pathname.endsWith("api-key")
                                ? { color: "#29AEE6", }
                                : { color: "#95919E" }}
                          />
                        </ListItemButton>

                        {/* Dispatcher */}
                        <ListItemButton
                          sx={{ pl: 3 }}
                          component={Link}
                          style={
                            pathname.endsWith("/integration/dispatchers")
                              ? { backgroundColor: "#C7E4EF", display: "flex", padding: "2px 16px 2px 62px" }
                              : { display: "flex", padding: "2px 16px 2px 62px"  }
                          }
                          to={`/dashboard/integration/dispatchers?integration=${paramValue}`}
                        >
                          <ListItemIcon style={{ minWidth: "26px" }}>
                            <Circle className={classes.colorAction}
                              style={{
                                color: pathname.endsWith("/integration/dispatchers")
                                  ? "#29AEE6"
                                  : "#95919E",
                                  fontSize:12,
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Dispatcher"
                            className={"itemMenuTitle"}
                            style={
                              pathname.endsWith("/integration/dispatchers")
                                ? { color: "#29AEE6", }
                                : { color: "#95919E" }}
                          />
                        </ListItemButton>

                        {/* commission */}
                        <ListItemButton
                          sx={{ pl: 3 }}
                          component={Link}
                          style={
                            pathname.endsWith("commission")
                              ? { backgroundColor: "#C7E4EF", display: "flex", padding: "2px 16px 2px 62px"  }
                              : { display: "flex", padding: "2px 16px 2px 62px"  }
                          }
                          to={`/dashboard/integration/commission?integration=${paramValue}`}
                        >
                          <ListItemIcon style={{ minWidth: "26px" }}>
                            <Circle className={classes.colorAction}
                              style={{
                                color: pathname.endsWith("commission")
                                  ? "#29AEE6"
                                  : "#95919E",
                                  fontSize:12,
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Commission"
                            className={"itemMenuTitle"}
                            style={
                              pathname.endsWith("commission")
                                ? { color: "#29AEE6", }
                                : { color: "#95919E" }}
                          />
                        </ListItemButton>

                        {/* Rate API */}
                        <ListItemButton
                          sx={{ pl: 3 }}
                          component={Link}
                          style={
                            pathname.endsWith("api")
                              ? { backgroundColor: "#C7E4EF", display: "flex", padding: "2px 16px 2px 62px"  }
                              : { display: "flex", padding: "2px 16px 2px 62px" }
                          }
                          to={`/dashboard/integration/rates?integration=${paramValue}`}
                        >
                          <ListItemIcon style={{ minWidth: "26px" }}>
                            <Circle className={classes.colorAction}
                              style={{
                                color: pathname.endsWith("api")
                                  ? "#29AEE6"
                                  : "#95919E",
                                  fontSize:12,
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Rate API"
                            className={"itemMenuTitle"}
                            style={
                              pathname.endsWith("api")
                                ? { color: "#29AEE6", }
                                : { color: "#95919E" }}
                          />
                        </ListItemButton>

                        {/* <ListItemButton
                          sx={{ pl: 3 }}
                          component={Link}
                          style={
                            pathname.endsWith("locations")
                              ? { backgroundColor: "#C7E4EF", display: "flex", padding: "8px 16px 8px 24px" }
                              : { display: "flex", padding: "8px 16px 8px 24px" }
                          }
                        to={`/dashboard/integration/locations`}
                        >
                          <ListItemIcon>
                            <MergeTypeIcon className={classes.colorAction}
                              style={{
                                color: pathname.endsWith("locations")
                                  ? "#29AEE6"
                                  : "#95919E"
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Locations"
                            className={"itemMenuTitle"}
                            style={
                              pathname.endsWith("locations")
                                ? { color: "#29AEE6", }
                                : { color: "#95919E" }}
                          />
                        </ListItemButton>

                        <ListItemButton
                          sx={{ pl: 3 }}
                          component={Link}
                          style={
                            pathname.endsWith("details")
                              ? { backgroundColor: "#C7E4EF", display: "flex", padding: "8px 16px 8px 24px" }
                              : { display: "flex", padding: "8px 16px 8px 24px" }
                          }
                        to={`/dashboard/integration/location/details`}
                        >
                          <ListItemIcon>
                            <MergeTypeIcon className={classes.colorAction}
                              style={{
                                color: pathname.endsWith("details")
                                  ? "#29AEE6"
                                  : "#95919E"
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Details"
                            className={"itemMenuTitle"}
                            style={
                              pathname.endsWith("details")
                                ? { color: "#29AEE6", }
                                : { color: "#95919E" }}
                          />
                        </ListItemButton>

                        <ListItemButton
                          sx={{ pl: 3 }}
                          component={Link}
                          style={
                            pathname.endsWith("home")
                              ? { backgroundColor: "#C7E4EF", display: "flex", padding: "8px 16px 8px 24px" }
                              : { display: "flex", padding: "8px 16px 8px 24px" }
                          }
                        to={`/dashboard/integration/location/home`}
                        >
                          <ListItemIcon>
                            <MergeTypeIcon className={classes.colorAction}
                              style={{
                                color: pathname.endsWith("home")
                                  ? "#29AEE6"
                                  : "#95919E"
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary="Home"
                            className={"itemMenuTitle"}
                            style={
                              pathname.endsWith("home")
                                ? { color: "#29AEE6", }
                                : { color: "#95919E" }}
                          />
                        </ListItemButton> */}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  <ListItem
                    button
                    component={Link}
                    to="/dashboard/integration"
                    onClick={() => {
                      handleHeaderDetail("Integration", "Here is the information about all your orders ")
                    }}
                    style={
                      pathname.endsWith("integration")
                        ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                        : {}
                    }
                  >
                    <ListItemIcon style={{ minWidth: "37px" }}>
                      <MergeTypeIcon className={classes.colorAction}
                        style={{
                          color: pathname.endsWith("integration")
                            ? "#29AEE6"
                            : "#95919E"
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Integration"
                      className={"itemMenuTitle"}
                      style={
                        pathname.endsWith("integration")
                          ? { color: "#29AEE6", }
                          : { color: "#95919E" }}
                    />
                  </ListItem>
                )}

                {/* this will be remove once testing is done start */}
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/testing"
                  onClick={() => {
                    handleHeaderDetail("Testing", "Here we can test the bidding rates for different country")
                  }}
                  style={
                    pathname.endsWith("testing")
                      ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                      : {}
                  }
                >
                  <ListItemIcon style={{ minWidth: "37px" }}>
                    <Biotech className={classes.colorAction}
                      style={{
                        color: pathname.endsWith("org-users")
                          ? "#29AEE6"
                          : "#95919E"
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Testing"
                    className={"itemMenuTitle"}
                    style={
                      pathname.endsWith("testing")
                        ? { color: "#29AEE6", }
                        : { color: "#95919E" }}
                  />
                </ListItem>
                {/* this will be remove once testing is done end */}
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/organization/reports"
                  onClick={() => {
                    handleHeaderDetail("Reports", "Here is the information about all your orders ")
                  }}
                  style={
                    isCurrentPath("reports") ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" } : {}
                  }
                >
                  <ListItemIcon style={{ minWidth: "37px" }}>
                    <SummarizeIcon className={classes.colorAction}
                      style={{
                        color: pathname.endsWith("reports")
                          ? "#29AEE6"
                          : "#95919E"
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Reports" className={"itemMenuTitle"}
                    style={
                      pathname.endsWith("reports")
                        ? { color: "#29AEE6", }
                        : { color: "#95919E" }}
                  />
                </ListItem>

                <ListItem
                  button
                  component={Link}
                  to="/dashboard/organization/settings"
                  onClick={() => {
                    handleHeaderDetail("Settings", "Here is the information about all your orders ")
                  }}
                  style={
                    isCurrentPath("settings") ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" } : {}
                  }
                >
                  <ListItemIcon style={{ minWidth: "37px" }}>
                    <Settings className={classes.colorAction}
                      style={{
                        color: pathname.endsWith("settings")
                          ? "#29AEE6"
                          : "#95919E"
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Settings" className={"itemMenuTitle"}
                    style={
                      pathname.endsWith("settings")
                        ? { color: "#29AEE6", }
                        : { color: "#95919E" }}
                  />
                </ListItem>
              </>
            ))}
          {role == "OrgUser" && (
            <>
              {userAuthorities.includes("Customers") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/customers"
                  onClick={() => {
                    handleHeaderDetail("Customers", "Here is the information about all your orders ")
                  }}
                  style={
                    pathname.endsWith("customers")
                      ? { backgroundColor: "#C7E4EF" }
                      : {}
                  }
                >
                  <ListItemIcon>
                    <ContactPhoneOutlinedIcon className={classes.colorAction} />
                  </ListItemIcon>
                  <ListItemText primary="Customers" style={{ color: "#95919E" }} />
                </ListItem>
              )}
              {userAuthorities.includes("Bookings") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/bookings"
                  onClick={() => {
                    handleHeaderDetail("Bookings", "Here is the information about all your orders ")
                  }}
                  style={
                    pathname.endsWith("bookings")
                      ? { backgroundColor: "#C7E4EF" }
                      : {}
                  }
                >
                  <ListItemIcon>
                    <DepartureBoardOutlinedIcon className={classes.colorAction} />
                  </ListItemIcon>
                  <ListItemText primary="Bookings" style={{ color: "#95919E" }} />
                </ListItem>
              )}
              {userAuthorities.includes("OrgUsers") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/org-users"
                  onClick={() => {
                    handleHeaderDetail("Organization Users", "Here is the information about all your orders ")
                  }}
                  style={
                    pathname.endsWith("org-users")
                      ? { backgroundColor: "#C7E4EF" }
                      : {}
                  }
                >
                  <ListItemIcon>
                    <SupervisedUserCircleIcon className={classes.colorAction} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Organization Users"
                    style={{ color: "#95919E" }}
                  />
                </ListItem>
              )}
              {userAuthorities.includes("Payments") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/payments"
                  onClick={() => {
                    handleHeaderDetail("Payments", "Here is the information about all your orders ")
                  }}
                  style={
                    pathname.endsWith("payments")
                      ? { backgroundColor: "#C7E4EF" }
                      : {}
                  }
                >
                  <ListItemIcon>
                    <PaymentOutlined className={classes.colorAction} />
                  </ListItemIcon>
                  <ListItemText primary="Payments" style={{ color: "#95919E" }} />
                </ListItem>
              )}

              {userAuthorities.includes("Integration") &&
                paramValue == "booking.com" || paramValue == "worldTransfer.com" ? (
                <>
                  <ListItemButton component={Link} to="/dashboard/integration" style={{ padding: "8px 16px" }}>
                    <ListItemIcon>
                      <MergeTypeIcon className={classes.colorAction} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Integration"
                      style={{ color: "#95919E" }}
                    />
                  </ListItemButton>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 3 }} component={Link}
                        style={
                          pathname.endsWith("api-key")
                            ? { backgroundColor: "#C7E4EF", display: "flex", padding: "8px 16px 8px 24px" }
                            : { display: "flex", padding: "8px 16px 8px 24px" }
                        }
                        to={`/dashboard/integration/api-key?integration=${paramValue}`}>
                        <ListItemIcon>
                          <MergeTypeIcon className={classes.colorAction} />
                        </ListItemIcon>
                        <ListItemText
                          primary="API Key"
                          style={{ color: "#95919E" }}
                        />
                      </ListItemButton>
                      {userAuthorities.includes("Integration Dispatchers") &&
                        <ListItemButton sx={{ pl: 3 }} component={Link}
                          style={
                            pathname.endsWith("/integration/dispatchers")
                              ? { backgroundColor: "#C7E4EF", display: "flex", padding: "8px 16px 8px 24px" }
                              : { display: "flex", padding: "8px 16px 8px 24px" }
                          }
                          to={`/dashboard/integration/dispatchers?integration=${paramValue}`}>
                          <ListItemIcon>
                            <MergeTypeIcon className={classes.colorAction} />
                          </ListItemIcon>
                          <ListItemText
                            primary="Dispatcher"
                            style={{ color: "#95919E" }}
                          />
                        </ListItemButton>}
                      {userAuthorities.includes("Comission") &&
                        <ListItemButton
                          sx={{ pl: 3 }}
                          component={Link}
                          style={
                            pathname.endsWith("commission")
                              ? { backgroundColor: "#C7E4EF", display: "flex", padding: "8px 16px 8px 24px" }
                              : { display: "flex", padding: "8px 16px 8px 24px" }
                          }
                          to={`/dashboard/integration/commission?integration=${paramValue}`}>
                          <ListItemIcon>
                            <MergeTypeIcon className={classes.colorAction} />
                          </ListItemIcon>
                          <ListItemText
                            primary="Commission"
                            style={{ color: "#95919E" }}
                          />
                        </ListItemButton>}
                    </List>
                  </Collapse>
                </>
              ) : (
                userAuthorities.includes("Integration") && (
                  <ListItem
                    button
                    component={Link}
                    to="/dashboard/integration"
                    onClick={() => {
                      handleHeaderDetail("Integration", "Here is the information about all your orders ")
                    }}
                    style={
                      pathname.endsWith("integration")
                        ? { backgroundColor: "#C7E4EF" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <MergeTypeIcon className={classes.colorAction} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Integration"
                      style={{ color: "#95919E" }}
                    />
                  </ListItem>
                )
              )}
              {userAuthorities.includes("Reports") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/organization/reports"
                  onClick={() => {
                    handleHeaderDetail("Reports", "Here is the information about all your orders ")
                  }}
                  style={
                    isCurrentPath("reports") ? { backgroundColor: "#C7E4EF" } : {}
                  }
                >
                  <ListItemIcon>
                    <SummarizeIcon className={classes.colorAction} />
                  </ListItemIcon>
                  <ListItemText primary="Reports" style={{ color: "#95919E" }} />
                </ListItem>
              )}
              {userAuthorities.includes("Setting") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/organization/settings"
                  onClick={() => {
                    handleHeaderDetail("Settings", "Here is the information about all your orders ")
                  }}
                  style={
                    isCurrentPath("settings") ? { backgroundColor: "#C7E4EF" } : {}
                  }
                >
                  <ListItemIcon>
                    <Settings className={classes.colorAction} />
                  </ListItemIcon>
                  <ListItemText primary="Settings" style={{ color: "#95919E" }} />
                </ListItem>
              )}
              <ListItem
                button
                component={Link}
                to="/dashboard/change-pass"
                onClick={() => {
                  handleHeaderDetail("Change Password", "Here is the information about all your orders ")
                }}
                style={
                  pathname.endsWith("change-pass")
                    ? { backgroundColor: "#C7E4EF" }
                    : {}
                }
              >
                <ListItemIcon>
                  <MergeTypeIcon className={classes.colorAction} />
                </ListItemIcon>
                <ListItemText
                  primary="Change Password"
                  style={{ color: "#95919E" }}
                />
              </ListItem>
            </>
          )}
          {role == "Admin" && (
            <ListItem button component={Link} to="/dashboard/settings"
              onClick={() => {
                handleHeaderDetail("Settings", "Here is the information about all your orders ")
              }}
            >
              <ListItemIcon>
                <Settings className={classes.colorAction} />
              </ListItemIcon>
              <ListItemText primary="Settings" style={{ color: "#95919E" }} />
            </ListItem>
          )}
          {role == "CustomerUser" && (
            <>
              {userAuthorities.includes("Bookings") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/customer-bookings"
                  onClick={() => {
                    handleHeaderDetail("Bookings", "Here is the information about all your orders ")
                  }}
                  style={
                    pathname.endsWith("-bookings")
                      ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                      : {}
                  }
                >
                  <ListItemIcon style={{ minWidth: "37px" }}>
                    {/* <DepartureBoardOutlinedIcon className={classes.colorAction}  
                     style={{color:pathname.endsWith("-bookings")
                     ? "#29AEE6"
                     : "#95919E"}}
                    /> */}
                    <BookingIcon color={pathname.endsWith("-bookings")
                      ? "#29AEE6"
                      : "#95919E"} />
                  </ListItemIcon>
                  <ListItemText primary="Bookings" className={"itemMenuTitle"}
                    style={
                      pathname.endsWith("-bookings")
                        ? { color: "#29AEE6", }
                        : { color: "#95919E" }} />
                </ListItem>
              )}
              {userAuthorities.includes("Customer Users") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/customer-users"
                  onClick={() => {
                    handleHeaderDetail("Customer Users", "Here is the information about all your orders ")
                  }}
                  style={
                    pathname.endsWith("-users")
                      ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                      : {}
                  }
                >
                  <ListItemIcon style={{ minWidth: "37px" }}>
                    {/* <SupervisedUserCircleIcon className={classes.colorAction}
                     style={{color:pathname.endsWith("-users")
                     ? "#29AEE6"
                     : "#95919E"}}
                    /> */}
                    <MangeUserIcon color={pathname.endsWith("-users")
                      ? "#29AEE6"
                      : "#95919E"} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Manage Users"
                    className={"itemMenuTitle"}
                    style={
                      pathname.endsWith("-users")
                        ? { color: "#29AEE6", }
                        : { color: "#95919E" }}
                  />
                </ListItem>
              )}
              {userAuthorities.includes("Customer Profile") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/customer-profile"
                  onClick={() => {
                    handleHeaderDetail("Customer Profile", "Here is the information about all your orders ")
                  }}
                  style={
                    pathname.endsWith("-profile")
                      ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                      : {}
                  }
                >
                  <ListItemIcon style={{ minWidth: "37px" }}>
                    {/* <PersonIcon className={classes.colorAction}
                     style={{color:pathname.endsWith("-profile")
                     ? "#29AEE6"
                     : "#95919E"}}
                    /> */}
                    <MyProfileIcon color={pathname.endsWith("-profile")
                      ? "#29AEE6"
                      : "#95919E"} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Customer Profile"
                    className={"itemMenuTitle"}
                    style={
                      pathname.endsWith("-profile")
                        ? { color: "#29AEE6", }
                        : { color: "#95919E" }}
                  />
                </ListItem>
              )}
              {userAuthorities.includes("Manage Passengers") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/manage-passengers"
                  onClick={() => {
                    handleHeaderDetail("Manage Passengers", "Here is the information about all your orders ")
                  }}
                  style={
                    pathname.endsWith("-passengers")
                      ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                      : {}
                  }
                >
                  <ListItemIcon style={{ minWidth: "37px" }}>

                    <MangePassengersIcon color={pathname.endsWith("-passengers")
                      ? "#29AEE6"
                      : "#95919E"} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Manage Passengers"
                    className={"itemMenuTitle"}
                    style={
                      pathname.endsWith("-passengers")
                        ? { color: "#29AEE6", }
                        : { color: "#95919E" }}
                  />
                </ListItem>
              )}
              <ListItem
                button
                component={Link}
                to="/dashboard/customer-changePass"
                onClick={() => {
                  handleHeaderDetail("Change Password", "Here is the information about all your orders ")
                }}
                style={
                  pathname.endsWith("changePass")
                    ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                    : {}
                }
              >
                <ListItemIcon style={{ minWidth: "37px" }}>
                  <PersonIcon className={classes.colorAction}
                    style={{
                      color: pathname.endsWith("changePass")
                        ? "#29AEE6"
                        : "#95919E"
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Change Password"
                  className={"itemMenuTitle"}
                  style={
                    pathname.endsWith("changePass")
                      ? { color: "#29AEE6", }
                      : { color: "#95919E" }}
                />
              </ListItem>

            </>
          )}
          {role === "SubAdmin" && (
            <>
              {userAuthorities.includes("Onbordings") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/onboardings"
                  style={
                    pathname.endsWith("onboardings")
                      ? { backgroundColor: "#C7E4EF" }
                      : {}
                  }
                >
                  <ListItemIcon>
                    <PostAddOutlinedIcon className={classes.colorAction} />
                  </ListItemIcon>
                  <ListItemText primary="Onbordings" style={{ color: "#95919E" }} />
                </ListItem>
              )}

              {userAuthorities.includes("Taxi Companies") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/taxi-companies"
                  style={
                    pathname.endsWith("companies")
                      ? { backgroundColor: "#C7E4EF" }
                      : {}
                  }
                >
                  <ListItemIcon>
                    <LocalTaxiOutlinedIcon className={classes.colorAction} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Taxi Companies"
                    style={{ color: "#95919E" }}
                  />
                </ListItem>
              )}
              {userAuthorities.includes("Manage Sub Admins") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/sub-admin"
                  style={
                    pathname.endsWith("-admin")
                      ? { backgroundColor: "#C7E4EF" }
                      : {}
                  }
                >
                  <ListItemIcon>
                    <SupervisedUserCircleIcon className={classes.colorAction} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Manage Sub Admins"
                    style={{ color: "#95919E" }}
                  />
                </ListItem>
              )}
              {userAuthorities.includes("Vehicles") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/vehicles"
                  style={
                    pathname.endsWith("vehicles")
                      ? { backgroundColor: "#C7E4EF" }
                      : {}
                  }
                >
                  <ListItemIcon>
                    <CommuteOutlinedIcon className={classes.colorAction} />
                  </ListItemIcon>
                  <ListItemText primary="Vehicles" style={{ color: "#95919E" }} />
                </ListItem>
              )}
              {userAuthorities.includes("Networks") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/networks"
                  style={
                    pathname.endsWith("networks")
                      ? { backgroundColor: "#C7E4EF" }
                      : {}
                  }
                >
                  <ListItemIcon>
                    <AccountTreeOutlinedIcon className={classes.colorAction} />
                  </ListItemIcon>
                  <ListItemText primary="Networks" style={{ color: "#95919E" }} />
                </ListItem>
              )}
              {userAuthorities.includes("Drivers") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/drivers"
                  style={
                    pathname.endsWith("drivers")
                      ? { backgroundColor: "#C7E4EF" }
                      : {}
                  }
                >
                  <ListItemIcon>
                    <PermContactCalendarOutlinedIcon
                      className={classes.colorAction}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Drivers" style={{ color: "#95919E" }} />
                </ListItem>
              )}
              {userAuthorities.includes("Dispatchers") && (
                <ListItem
                  button
                  component={Link}
                  to="/dashboard/dispatchers"
                  style={
                    pathname.endsWith("dispatchers")
                      ? { backgroundColor: "#C7E4EF" }
                      : {}
                  }
                >
                  <ListItemIcon>
                    <ContactPhoneOutlinedIcon className={classes.colorAction} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Dispatchers"
                    style={{ color: "#95919E" }}
                  />
                </ListItem>
              )}
              {userAuthorities.includes("Settings") && (
                <ListItem button component={Link} to="/dashboard/settings">
                  <ListItemIcon>
                    <Settings className={classes.colorAction} />
                  </ListItemIcon>
                  <ListItemText primary="Settings" style={{ color: "#95919E" }} />
                </ListItem>
              )}
              <ListItem
                button
                component={Link}
                to="/dashboard/sub-changePass"
                style={
                  pathname.endsWith("changePass")
                    ? { backgroundColor: "#C7E4EF" }
                    : {}
                }
              >
                <ListItemIcon>
                  <PersonIcon className={classes.colorAction} />
                </ListItemIcon>
                <ListItemText
                  primary="Change Password"
                  style={{ color: "#95919E" }}
                />
              </ListItem>
            </>
          )}
        </Box>

        <Box >

          {role == "Customer" &&
            <>
              {/* contact us */}
              <ListItem
                button
                component={Link}
                to="/dashboard/customer-contact"
                onClick={() => {
                  handleHeaderDetail("Contact us", "Here is the information about all your orders ")
                }}
                // { backgroundColor: "#C7E4EF" }
                style={
                  pathname.endsWith("-contact")
                    ? { backgroundColor: "#F7F7FB", borderRightColor: "#2CA9DD", borderRightWidth: 4, borderStyle: "solid" }
                    : {}
                }
              >
                <ListItemIcon style={{ minWidth: "37px" }}>
                  <ChatIcon color={pathname.endsWith("-contact")
                    ? "#29AEE6"
                    : "#95919E"} />
                </ListItemIcon>
                <ListItemText primary="Contact us" className={"itemMenuTitle"}
                  style={
                    pathname.endsWith("-contact")
                      ? { color: "#29AEE6", }
                      : { color: "#95919E" }} />
              </ListItem>
            </>
          }

          {/* logout */}
          {/* <ListItem button onClick={() => resetAll()}> */}
          <ListItem button onClick={() => { setLogoutModalOpen(true) }}>
            <ListItemIcon style={{ minWidth: "37px" }}>
              {/* <PowerSettingsNewOutlinedIcon className={classes.colorAction} /> */}
              <LogoutIcon className={classes.colorAction} />
            </ListItemIcon>
            <ListItemText primary="LogOut" className={"itemMenuTitle"} style={{ color: "#FF3B30" }} />
          </ListItem>
        </Box>
      </Box>
      {role == "OrgUser" && !!userError && (
        <ErrorSnack
          errorMsg={`${userError}. Please Refresh To Update`}
          reset={() => resetError()}
        />
      )}
      {/* logout */}
      <Dialog
        open={logoutModalOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleLogoutClose}
        aria-describedby="alert-dialog-slide-description"
        className={classes.modalRoot}
      >
        <DialogTitle
          style={{ display: "flex", justifyContent: "center", paddingTop: 50 }}
        >
          <LogoutIcon className={classes.colorAction} style={{ width: 42, height: 42 }} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography style={{ fontSize: 24, fontWeight: 700, color: "#170F49" }}>
                Are you sure , you want to Logout?
              </Typography>
            </Box>
          </DialogContentText>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 60,
              padding: "0px 90px 60px 90px",
            }}
          >
            <MOButton title="Cancel" icon={false} onClick={handleLogoutClose} />
            <MOButton
              title="Logout"
              type="danger"
              icon={false}
              onClick={async () => {
                resetAll()
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
const mapDispatch = {
  resetAll: ThunksLogin.logout,
  getAuthorities: ThunksUsers.getAuthorities,
  getCustomerAuthorities: CustomerUsers.getAuthorities,
  getSubAdminAuthorities: SubAdminThunks.getAuthorities,
  getCustomers: ThunksCustomers.Customers,
  resetError: Local.getUserAuthorizations.reset,
};

const mapState = (state: any) => ({
  role: state.userInfo.user.role,
  userData: state.userInfo.user,
  userAuthorities: state.userInfo.user.authorities,
  userError: state?.userInfo?.error,
});

export default connect(mapState, mapDispatch)(MainListItems);
