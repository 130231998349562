import NETWORKS from "../constants/networks";

export type NetworksRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
  };
};

const Networks: NetworksRepo = {
  api: {
    request: () => ({
      type: NETWORKS.NETWORKS_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: NETWORKS.NETWORKS_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: NETWORKS.NETWORKS_API.SET_RESPONSE,
      payload: { data },
    }),
  },
};

export default Networks;