import { connect } from "react-redux";
import { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  makeStyles,
  Button,
  Typography,
  Drawer,
  Box,
  InputAdornment,
  Slider,
  Divider,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import Bookings from "../../../redux/actions/local/bookings";
import ThunksBookings from "../../../redux/actions/thunks/bookings";
import ExclusiveSelect from "../../../components/commons/ExclusiveSelect";
import CarExclusiveSelect from "../../../components/commons/CarExclusiveSelect";
import DatePicker from "../../../components/commons/DatePicker";
import LocationField from "../../../components/commons/LocationField";
import Input from "../../../components/commons/Input";
import Local from "../../../redux/actions/local";
import * as momentTimeZone from "moment-timezone";
import MOButton from "../../../components/commons/MOButton";
import {
  LuggageIcon,
  PersonIcon,
  SignalPassengerIcon,
} from "../../../assets/icons";
import { ChatBubbleOutlineOutlined } from "@material-ui/icons";
import MuiPhoneNumber from "material-ui-phone-number";
import ThunksCustomers from "../../../redux/actions/thunks/customer";

const useStyles = makeStyles((theme) => ({
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    margin: theme.spacing(2),
    width: 596,
  },

  button: {
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 8,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
  },

  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    height: 40,
  },
  textfield_lower: {
    height: 40,
  },
  textfield_side: {
    height: 40,
  },
  ExclusiveSelect: {
    // marginBottom: 20,
    marginRight: 26,
  },
  MultiSelect: {
    marginTop: 20,
  },
  select: {
    marginTop: 20,
    width: 310,
  },
  autoComplete: {
    backgroundColor: "red",
  },
  heading: {
    fontSize: 20,
    fontWeight: 500,
    color: "#000000",
  },
  headingDetail: {
    fontSize: 12,
    // fontWeight:500,
    color: "#787486",
  },
}));

function DuplicateBookingDialog({
  bookingState,
  open,
  onClose,
  dispatchers,
  loading,
  role,
  duplicateBooking,
  duplicateCustomerBooking,
  getCustomers,
  clientCompany
}) {
  const classes = useStyles();
  const [errorTrigger, setErrorTrigger] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);
  const [data, setData] = useState({
    dateTime: bookingState.dateTime,
    paymentType: bookingState.paymentType,
    // dispatcherId: bookingState.dispatcherId || "",
    nameCaller: bookingState.nameCaller,
    pickup: bookingState.pickup,
    destination: bookingState.destination,
    // fare: bookingState.fare,
    fare: bookingState.finalPrice,
    guestName: bookingState.guestName,
    guestPhoneNumber: bookingState.guestPhoneNumber,
    flightNumber: bookingState.flightNumber,
    noOfPeople: bookingState.noOfPeople,
    carType: bookingState.carType,
    client: bookingState?.client || "",
    nameCaller: bookingState?.nameCaller || "",
    timeZone: bookingState.timeZone,
    newTimeZone: undefined,
    noOfLuggage: bookingState.noOfLuggage,
    bookingReference: bookingState.bookingReference || bookingState.refId
  });

  const updateField = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleChange = (event) => {
    // updateField("clientId", event.target.value);
    setData({...data,clientId:event.target.value})
  };
  const handleDuplicateBooking = () => {
    if (role == "Customer" || role == "CustomerUser") {
      duplicateCustomerBooking(data);
    }
    if (role != "Customer") {
      duplicateBooking(bookingState.id, data);
    }
  };

  useEffect(()=>{
    getCustomers()
    if(bookingState?.clientId){
      setData({...data,clientId: bookingState?.clientId || ""})
    }
  },[])

  return (
    <>
      <Drawer anchor={"right"} open={open} onClose={onClose}>
        <div className={classes.dialogPaper}>
          <Grid
            container
            direction="column"
            // justifyContent="center"
            // alignItems="center"
          >
            <Typography className={classes.heading}>
              Duplicate Booking
            </Typography>
            <Typography className={classes.headingDetail}>
              Provide the information to finalise your car reservation
            </Typography>
          </Grid>

          <Grid style={{ marginTop: 16 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              // alignItems="center"
              className={classes.ExclusiveSelect}
            >
              <DatePicker
                date={
                  data.dateTime !== ""
                    ? new Date(data.dateTime).toLocaleString("en-US", {
                        timeZone: data.newTimeZone || data.timeZone,
                      })
                    : new Date()
                }
                onChange={(date) =>
                  setData({
                    ...data,
                    dateTime: date,
                    newTimeZone: momentTimeZone.tz.guess(),
                  })
                }
              />
              <MOButton title="Now" icon={false} />
            </Grid>

            <Box
              style={{ display: "flex", alignItems: "center", marginTop: 18 }}
            >
              <PersonIcon />
              <Typography
                style={{
                  fontSize: 17,
                  fontWeight: 400,
                  marginLeft: 3,
                  marginRight: 6,
                }}
              >
                {data?.noOfPeople}
              </Typography>
              <Slider
                size="small"
                defaultValue={1}
                step={1}
                min={1}
                max={16}
                value={data.noOfPeople}
                aria-label="Small"
                valueLabelDisplay="auto"
                onChange={(e, newValue) => {
                  updateField("noOfPeople", newValue);
                }}
              />
              <ChatBubbleOutlineOutlined
                style={{ marginRight: 62, marginLeft: 6 }}
                onClick={() => {
                  setNoteOpen(!noteOpen);
                }}
              />
            </Box>

            <Box style={{ display: "flex" }}>
              <ExclusiveSelect
                value={data.paymentType}
                onClick={(value) => updateField("paymentType", value)}
              />
              <Box style={{ width: "100%", marginLeft: "12px" }}>
                {noteOpen && (
                  <Input
                    fullWidth
                    placeholder="Note"
                    size="small"
                    multiline
                    value={bookingState.note}
                    className={classes.textfield_side}
                    onChange={(e) => updateField("note", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ChatBubbleOutlineOutlined
                            style={{ color: "#AAAAAA" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Box>
            </Box>

            <Divider light style={{ marginTop: 22, marginBottom: 12 }} />

            {role !== "Customer" && role !== "CustomerUser" && (
              <>
                {/* {data?.client?.firstName && (
                  <Typography style={{ marginTop: 10 }}>
                    Client Name:
                    {" " +
                      data?.client?.firstName +
                      " " +
                      data?.client?.lastName}
                  </Typography>
                )} */}
                       <Input
                        value={data?.clientId}
                        onChange={handleChange}
                        select
                        variant={"outlined"}
                        label="Requested By"
                        size="small"
                        fullWidth
                        className={classes.textfield_upper}
                      >
                <MenuItem key={"none"} value={""}>
                  {"None"}
                </MenuItem>
                {!loading &&
                  clientCompany?.map((item, index) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.companyName}
                    </MenuItem>
                  ))}
              </Input>
{/* 
              {errorTrigger && !data?.clientId && (
                    <p
                      className="error-text-danger error-text-style"
                      style={{ 

                        marginTop:"4px"
                       }}
                    >
                      Requested By is required
                    </p>
                  )} */}
                {/* // Dispatcher in edit and duplicate booking in web should be removed */}
                {/* <Input
                    value={data.dispatcherId}
                    onChange={(e) =>
                      updateField("dispatcherId", e.target.value)
                    }
                    select
                    variant={"outlined"}
                    label="Dispatchers"
                    size="small"
                    fullWidth
                    className={classes.textfield_upper}
                  >
                    <MenuItem key={"none"} value={""}>
                      {"None"}
                    </MenuItem>
                    {!loading &&
                      dispatchers?.map((item, index) => {
                        const key = item?.User?.id || item?.id;
                        const value = item?.User?.id || item?.id;
                        const displayName = item?.User?.displayName || item?.displayName;

                        return (
                          <MenuItem key={key} value={value}>
                            {displayName}
                          </MenuItem>
                        );
                      })}
                  </Input> */}
              </>
            )}
            {/* <Input
              label="Name Caller"
              size="small"
              fullWidth
              value={data.nameCaller}
              className={classes.textfield_upper}
              onChange={(e) => updateField("nameCaller", e.target.value)}
            /> */}

            <Grid
              container
              spacing={2}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              <Grid item xs={6}>
                <Input
                  fullWidth
                  placeholder="Passenger Name"
                  size="small"
                  value={data.guestName}
                  className={classes.textfield_lower}
                  onChange={(e) => updateField("guestName", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SignalPassengerIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  fullWidth
                  label="Booking Id "
                  size="small"
                  value={data.bookingReference}
                  className={classes.textfield_side}
                  onChange={(e) =>
                    updateField("bookingReference", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <MuiPhoneNumber
                  fullWidth
                  variant="outlined"
                  placeholder="Passenger Phone Number"
                  defaultCountry={"nl"}
                  className={classes.textfield_lower}
                  size="small"
                  value={data.guestPhoneNumber}
                  onChange={(phoneNumber) => {
                    updateField("guestPhoneNumber", phoneNumber);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider light style={{ marginTop: 1 }} />
              </Grid>

              <Grid item xs={6}>
                <Input
                  fullWidth
                  label="Flight Number "
                  size="small"
                  value={data.flightNumber}
                  className={classes.textfield_side}
                  onChange={(e) => updateField("flightNumber", e.target.value)}
                />
              </Grid>

              {role !== "Customer" && role !== "CustomerUser" ? (
                <Grid item xs={6}>
                  <Input
                    fullWidth
                    label="Fare"
                    size="small"
                    value={data.fare}
                    className={classes.textfield_side}
                    onChange={(e) => updateField("fare", e.target.value)}
                  />
                </Grid>
              ) : null}
              <Grid
                item
                xs={role !== "Customer" && role !== "CustomerUser" ? 12 : 6}
              >
                <Input
                  select
                  value={data.noOfLuggage}
                  fullWidth
                  size="small"
                  variant="outlined"
                  className={classes.textfield_lower}
                  onChange={(e) => updateField("noOfLuggage", e.target.value)}
                  placeholder="Number Of Luggage "
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LuggageIcon />
                      </InputAdornment>
                    ),
                  }}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                    (item, index) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    )
                  )}
                </Input>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.MultiSelect}>
            <CarExclusiveSelect
              value={data.carType}
              noOfPeople={data.noOfPeople}
              onClick={(value) => updateField("carType", value)}
            />
          </Grid>
          {/* locations */}
          <LocationField
            location={data.pickup}
            placeholder={"Pickup"}
            onChange={(value) => {
              if (value) {
                geocodeByAddress(value.label)
                  .then((results) => getLatLng(results[0]))
                  .then(({ lat, lng }) =>
                    updateField("pickup", {
                      name: value,
                      coordinates: [lat, lng],
                    })
                  );
              }
            }}
          />
          <LocationField
            location={data.destination}
            placeholder={"Destination"}
            onChange={(value) => {
              if (value) {
                geocodeByAddress(value.label)
                  .then((results) => getLatLng(results[0]))
                  .then(({ lat, lng }) =>
                    updateField("destination", {
                      name: value,
                      coordinates: [lat, lng],
                    })
                  );
              }
            }}
          />
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ marginTop: 16 }}
          >
            <Box style={{ marginRight: 15 }}>
              <MOButton
                title="Cancel"
                icon={false}
                onClick={onClose}
                type="secondary"
              />
            </Box>
            <MOButton
              title="Duplicate"
              icon={false}
              disabled={
                !data.guestPhoneNumber ||
                !data.dateTime ||
                !data.destination ||
                !data.noOfPeople ||
                !data.pickup
              }
              onClick={() => {
                delete data.newTimeZone;
                handleDuplicateBooking();
                onClose();
              }}
            />
            {/* <Button
              style={{
                borderRadius: 6,
                backgroundColor: "primary",
                color: "#FFFFFF",
                height: 40,
                marginLeft: 10,
              }}
              variant="contained"
              onClick={onClose}
            >
              Cancel
            </Button> */}
            {/* <Button
              style={{
                borderRadius: 6,
                backgroundColor: "#2AABD5",
                color: "#FFFFFF",
                height: 40,
                marginLeft: 10,
              }}
              disabled={
                !data.guestPhoneNumber ||
                !data.dateTime ||
                !data.destination ||
                !data.noOfPeople ||
                !data.pickup
              }
              variant="contained"
              onClick={() => {
                delete data.newTimeZone;
                handleDuplicateBooking();
                onClose();
              }}
            >
              Duplicate
            </Button> */}
          </Grid>
        </div>
      </Drawer>
    </>
  );
}
const mapState = (state) => ({
  loading: state.dispatchers.loading,
  status: state,
  clientCompany: state.customers.response.data,
});

const mapDispatch = {
  duplicateBooking: ThunksBookings.DuplicateBooking,
  duplicateCustomerBooking: ThunksBookings.DuplicateCustomerBooking,
  getCustomers: ThunksCustomers.Customers,
};

export default connect(mapState, mapDispatch)(DuplicateBookingDialog);
