const colors = {
  blue: {
    light: "#d6edf7",
    dark: "#2aabd5",
    main: "#add8e6",
  },
  text: {
    dark: "#4b4b4b",
    light: "#A5A5A5",
    // primary:"",
    // secondary: ""
  },
};

export default colors;
