import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Input from "../../../components/commons/Input";
import { Grid, Paper } from "@material-ui/core";
import ThunksVehicle from "../../../redux/actions/thunks/vehicles-listing";
import { connect } from "react-redux";
import { AddBrand } from "../../../redux/actions/local/vehicle-listing";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import Local from "../../../redux/actions/local";

const styles = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    height: 600,
    marginLeft: 16,
    marginRight: 34,
  },

  button: {
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 8,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
  },
  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    width: 550,
    height: 40,
  },
});

const DialogTitle = withStyles(styles as any)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
interface AddVehicleProps {
  addBrand: (brandName: string) => any;
  error: any;
  success: any;
  reset: () => any;
}

const AddVehicle: React.FC<AddVehicleProps> = ({
  addBrand,
  error,
  success,
  reset,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [brandName, setBrandName] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // alert(JSON.stringify(status));

  return (
    <>
      <Button
        style={{
          borderRadius: 8,
          backgroundColor: "#2AABD5",
          color: "#FFFFFF",
          height: 40,
        }}
        onClick={handleClickOpen}
        variant="contained"
        // startIcon={<AddIcon />}
      >
        Add Vehicle Brand
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Vehicle Listing
        </DialogTitle>
        <DialogContent dividers>
          <Grid>
            <Input
              label="Vehicle Brand "
              value={brandName}
              autoFocus
              size="small"
              className={classes.textfield_upper}
              onChange={(e: any) => setBrandName(e.target.value)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              borderRadius: 8,
              backgroundColor: "#2AABD5",
              color: "#FFFFFF",
              height: 40,
            }}
            disabled={brandName.length < 2}
            onClick={() => {
              addBrand(brandName);
              handleClose();
              setBrandName("");
            }}
            variant="contained"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {error.status && (
        <ErrorSnack reset={() => reset()} errorMsg={error.message} />
      )}
      {success.status && (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"Vehicle Brand Created"}
        />
      )}
    </>
  );
};
const mapState = (state: any) => ({
  error: state.addBrand.error,
  success: state.addBrand.response,
});

const mapDispatch = {
  addBrand: ThunksVehicle.AddBrand,
  reset: Local.AddBrand.api.reset,
};
export default connect(mapState, mapDispatch)(AddVehicle);
