import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/BreadCrumb";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import AvailabilityIcon from "../../../components/iconLibrary/AvailabilityIcon";
import MOSwitch from "../../../components/commons/MOSwitch";
import DropDown from "../../../components/commons/DropDown";
import HorizontalIcon from "../../../assets/icons/horizontalIcon.png";
import { connect } from "react-redux";
import mobioApi from "../../../services";
import { formatDate } from "../../../helpers/getTimeFormat";
import PopoverItem from "../../../components/commons/PopoverItem";
import DeleteAvailability from "./Modals/DeleteAvailability";
import { toastMessage } from "../../../helpers/toastMessage";
import EditIcon from "../../../assets/icons/editUpdateIcon.png";
import DeleteIcon from "../../../assets/icons/menuDelete.png";

const Index = ({ token }) => {
  const navigate = useNavigate();
  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();
  const [availabilityList, setAvailabilityList] = useState([]);
  const [anchorEl, setAnchorEl] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [singleData, setSingleData] = useState("");

  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },

    {
      name: "Manage AMS",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },

    {
      name: "Availability",
      // url: "",
    },
  ];
  const getAvailability = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getAvailability(airportId);
      const getData = response?.data?.data;
      setAvailabilityList(getData);
    } catch (error) {
      setAvailabilityList([]);
    }
  };

  const deleteAvailability = async (data) => {
    try {
      const api = mobioApi(token);
      const response = await api.deleteAvailability({
        availibilityName: data?.stopSaleName,
      });
      toastMessage("success", response?.data?.msg);
      const getUpdateData = availabilityList?.filter(
        (af) => af?.stopSaleName !== data?.stopSaleName
      );
      setAvailabilityList(getUpdateData);
      setSingleData("");
      setDeleteModal(false);
    } catch (error) {
      toastMessage("error", error.response?.data?.msg);
    }
  };

  const statusChangeHandle = async (data, status) => {
    try {
      const payload = {
        airportId: data?.airportId,
        locationId: [data?.locationId],
        locationType: data?.locationType,
        stopSaleName: data?.stopSaleName,
        status: status,
        startDate: data?.startDate,
        endDate: data?.endDate,
      };
      if (data?.locationType === "oneLocation") {
        payload.vehicleTypeId = data?.serviceLevels?.map((sm) => sm.id);
      }
      const api = mobioApi(token);
      await api.addAvailability(payload);

      const getUpdateData = availabilityList?.map((am) => {
        if (am?.id === data?.id) {
          am.status = status;
        }
        return am;
      });
      setAvailabilityList(getUpdateData);
      toastMessage("success", "Status has been updated");
    } catch (error) {
      toastMessage("error", error?.response?.data?.msg);
    }
  };

  useEffect(() => {
    getAvailability();
  }, []);

  return (
    <>
      <div>
        <div className="mt-18 flex justify-content-between">
          <div>
            <Breadcrumb
              paths={[
                {
                  name: "Availability ",
                  url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
                },
              ]}
            />
            <div className="breadcrumb-description">
              Dispatchers can be assigned to bookings and use the driver app
            </div>
          </div>
          <div className="flex gap-8 ">
            <div className="input-field-sm w-186">
              <select>
                <option>All Location Selected</option>
              </select>
            </div>
            <div className="input-field-sm w-186">
              <select>
                <option>Active</option>
              </select>
            </div>

            <div>
              <MOButtonV2
                text="FILTER RESULT"
                textColor="#ffffff"
                radius={4}
                backgroundColor="#29AEE6"
                height={30}
                fontSize={11}
                padding={"8.5px 30.5px"}
                onClick={() => {}}
              />
            </div>
            <div>
              <MOButtonV2
                text="CREATE A STOP SALE"
                textColor="#ffffff"
                radius={4}
                backgroundColor="#29AEE6"
                height={30}
                fontSize={11}
                padding={"8.5px 30.5px"}
                onClick={() => {
                  navigate(
                    `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/create-stop-sale`,
                    {
                      state: {
                        action: "add",
                      },
                    }
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-12 ml-6">
          <Breadcrumb paths={secondaryPaths} secondary={true} />
        </div>
        <div>
          <div className="bg-white  mt-20 border-radius-8">
            {/* table */}

            <div className="">
              <table className="custom-table-v3">
                <thead>
                  <tr>
                    <th className="">Name</th>
                    <th className="">Start Date</th>
                    <th className="">End Date</th>
                    <th className="">Locations</th>
                    <th className="">Vehicles</th>
                    <th className="">Active</th>
                    <th className="">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {availabilityList?.length > 0 ? (
                    availabilityList?.map((row, index) => (
                      <tr key={index}>
                        <td className="flex align-item-center">
                          <AvailabilityIcon />
                          <span className="ml-6"> {row?.stopSaleName}</span>
                        </td>
                        <td>{formatDate(row?.startDate)}</td>
                        <td>{formatDate(row?.endDate)}</td>
                        <td style={{ maxWidth: "140px" }}>
                          {row?.polygons?.name}
                        </td>
                        <td style={{ maxWidth: "140px" }}>
                          <PopoverItem
                            names={row.serviceLevels?.map((sm) => sm.name)}
                            showItems={2}
                          />
                        </td>
                        <td>
                          <MOSwitch
                            isOnStatus={row.status}
                            id={row.id}
                            setStatus={(value) => {
                              statusChangeHandle(row, value);
                            }}
                          />
                        </td>
                        <td>
                          <DropDown
                            dropDownOpen={anchorEl}
                            setDropDownOpen={setAnchorEl}
                            uniqueId={row.id}
                            IconRender={() => <img src={HorizontalIcon} />}
                            dropDownShape="horizontal"
                            menu={[
                              {
                                onClick: () => {
                                  navigate(
                                    `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/create-stop-sale`,
                                    {
                                      state: {
                                        action: "edit",
                                        data: row,
                                      },
                                    }
                                  );
                                },
                                IconRender: () => <img src={EditIcon} />,
                                title: "Edit",
                              },
                              {
                                onClick: () => {
                                  setDeleteModal(true);
                                  setSingleData(row);
                                },
                                IconRender: () => <img src={DeleteIcon} />,
                                title: "Delete",
                              },
                            ]}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      <tr>
                        <td colSpan="7">
                          <div className="table-no-record-section">
                            No records are found.
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Modals */}
      <DeleteAvailability
        modalOpen={deleteModal}
        setModalOpen={setDeleteModal}
        apiCall={() => {
          deleteAvailability(singleData);
        }}
      />
    </>
  );
};

// export default Index;
const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Index);
