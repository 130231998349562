import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Link } from "@material-ui/core";
import { connect } from "react-redux";
import ThunkSettings from "../../../redux/actions/thunks/settings";
import mobioApi from "../../../services";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { AppSettingsKeys } from "../../../enum"
const useStyles = makeStyles((theme) => ({
  mainCard: {
    display: "flex",
    alignContent: "center",
    minWidth: 275,
  },
  root: {
    padding: 20
  },

  title: {
    marginLeft: 80,
    marginRight: 80,
  },
  input: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  innerInput: {
    paddingTop: 48,
  },
  button: {
    backgroundColor: theme.palette.error.main,
    color: "#fff",
  },
}));
const AppSettings = ({
  token,
  createSetting
}) => {
  const classes = useStyles();
  const [number, setNumber] = useState("");
  const [VAT, setVAT] = useState("");
  
  const [load, setLoad] = useState(true);
  const loadSettingNumber = () => {
    setLoad(true)
    const api = mobioApi();
    api.getSetting({
      keyName: AppSettingsKeys.SUPPORT_NUMBER
    }).then((res) => {
      if (res?.data?.keyValue) {
        setNumber(res?.data?.keyValue);
      } else {
        setNumber("");
      }
      
      setLoad(false);
    });
  }
  const loadSettingVat = () => {
    setLoad(true)
    const api = mobioApi();
    api.getSetting({
      keyName: AppSettingsKeys.VAT_PERCENTAGE
    }).then((res) => {
      if (res?.data?.keyValue) {
        setVAT(res?.data?.keyValue);
      } else {
        setVAT("");
      }
      
      setLoad(false);
    });
  }

  useEffect(() => {
    loadSettingNumber();
    loadSettingVat();
  }, []);

  return (
    <>
    <div style={{display: 'flex', direction: "row"}}> 
    <div>
    <Toolbar className={classes.toolbar} style={{ marginLeft: "-102px"}}>
      <Typography
        component="h1"
        variant="h6"
        color="#4B4B4B"
        noWrap
        className={classes.title}
      >
        Change Support Number
      </Typography>
          
    </Toolbar>
      <Grid
        container
        alignItems="center"
        direction="row"
        className={classes.mainCard}
      >
        <Card className={classes.root}>
            {load ? (
              <CardContent>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CircularProgress />
              </div>
              </CardContent>
            ) : (
              <CardContent style={{width: "110%"}}>
              <div className={classes.input}>
                <TextField
                  value={number}
                  autoFocus={true}
                  id="standard-basic"
                  label="Number(With Country code)"
                  style={{ marginBottom: "24px" }}
                  onChange={(e) => setNumber(e.target.value)}
                />
                
              </div>
              </CardContent>
              
            )} 
          
          <Grid container justifyContent="center" alignItems="center">
            {!load && (
              <CardActions>
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    // disabled={loader}
                    onClick={async() => {
                      await createSetting({
                        keyName: AppSettingsKeys.SUPPORT_NUMBER,
                        keyValue: number
                      });
                    }}
                  >
                    Submit
                  </Button> 
              </CardActions>
            )} 
          </Grid>
        </Card>
      </Grid>
    </div>
    {/* <div>
      <Toolbar className={classes.toolbar} style={{ marginLeft: "-102px"}}>
      <Typography
        component="h1"
        variant="h6"
        color="#4B4B4B"
        noWrap
        className={classes.title}
      >
        Change VAT Percentage
      </Typography>
          
    </Toolbar>
      <Grid
        container
        alignItems="center"
        direction="row"
        className={classes.mainCard}
      >
        <Card className={classes.root}>
            {load ? (
              <CardContent>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CircularProgress />
              </div>
              </CardContent>
            ) : (
              <CardContent style={{width: "110%"}}>
              <div className={classes.input}>
                <TextField
                  value={VAT}
                  autoFocus={true}
                  id="standard-basic"
                  label="VAT(%)"
                  style={{ marginBottom: "24px" }}
                  onChange={(e) => setVAT(e.target.value)}
                />
                
              </div>
              </CardContent>
              
            )} 
          
          <Grid container justifyContent="center" alignItems="center">
            {!load && (
              <CardActions>
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    // disabled={loader}
                    onClick={async() => {
                      await createSetting({
                        keyName: "vat_percentage",
                        keyValue: VAT
                      });
                    }}
                  >
                    Submit
                  </Button> 
              </CardActions>
            )} 
          </Grid>
        </Card>
      </Grid>
    </div> */}
    </div>
    <Link href="/dashboard/settings/termsandconditions">
      <Button style={{ marginTop:"20px", border:"solid 1px black"}}>
        Terms and Conditions
      </Button>
    </Link>
    </>
    
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {
  createSetting: ThunkSettings.createSetting,
};

export default connect(mapState, mapDispatch)(AppSettings);
