import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Box, Button, Grid, Modal, Paper, Checkbox, TextField, Toolbar, Typography, MenuItem, Select} from "@material-ui/core";
import MaterialTable from "material-table";
import CardContent from "@material-ui/core/CardContent";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import mobioApi from "../../../services";
import ThunksBooking from "../../../redux/actions/thunks/bookings";
import Local from "../../../redux/actions/local";
import ThunkCommission from "../../../redux/actions/thunks/commission";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  mainCard: {
    display: "flex",
    alignContent: "center",
    minWidth: 275,
  },
  root: {
      padding: 20
    // justifyContent: "center",
    // alignItems: "center",
  },

  title: {
    // fontSize: 24,
    // fontWeight: 600,
    // textAlign: "center",
    marginLeft: 80,
    marginRight: 80,
    // marginBottom: 28,
  },
  input: {
    display: "flex",
    flexDirection: "column",
  },
  innerInput: {
    paddingTop: 48,
  },
  button: {
    backgroundColor: theme.palette.error.main,
    color: "#fff",
  },
}));
const CustomerCommission = ({
  connectSuccess,
  connectError,
  reset,
  error,
  loader,
  message,
  addCustomerCommission,
  getCustomerCommissions,
  updateCommission,
  deleteCommission,
  clientId
}) => {
  const classes = useStyles();
  const [commissions, setCommissions] = useState([])
  const [selected, setSelected] = useState([])
  const [success, setSuccess] = useState(false);
  const [load, setLoad] = useState(true);
  const [commission, setCommission] = useState(null);
  const [serviceLevel, setServiceLevel] = useState(0);
  const [roundable, setRoundable] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const [toTime, setToTime] = useState(
    dayjs('2018-01-01T00:00:00.000Z')
  );

  const [fromTime, setFromTime] = useState(
    dayjs('2018-01-01T00:00:00.000Z')
  );


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const handleSubmit = async () => {
    let fromTimeUTC = fromTime.add(new Date().getTimezoneOffset(), "Minute")
    let toTimeUTC = toTime.add(new Date().getTimezoneOffset(), "Minute")
    let fromT = fromTimeUTC.hour()+":"+ fromTimeUTC.minute()+":"+fromTimeUTC.second()
    let toT = toTimeUTC.hour()+":"+ toTimeUTC.minute()+":"+toTimeUTC.second()
    
    if (toTime && fromTime && commission && serviceLevel !== 0) {
      console.log("here is clientId", clientId)
      let result = await addCustomerCommission({
        clientId: clientId,
        toTime: toT,
        fromTime: fromT,
        commission,
        roundable,
        serviceLevel
      });
      
      setOpen(false);
      if (result) {
        setToTime(dayjs('2018-01-01T00:00:00.000Z'));
        setFromTime(dayjs('2018-01-01T00:00:00.000Z'));
        setCommission(null);
        setRoundable(false);
        setServiceLevel(0)
      }
  
      await fetchCommissions();
    } else {
      alert("Please fill all required fields.")
    }
    
  }

  const fetchCommissions = async () => {
    let commissions = await getCustomerCommissions(clientId);
    setCommissions(commissions);
    setLoad(false);
  }

  useEffect(() => {
    (async () => {
      await fetchCommissions()
    })();
  }, [connectSuccess, connectError, loader, load]);

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const columns = [
    { title: "Commission(%)", field: "commission", editable: "never" },
    { title: "From", field: "fromTime", editable: "never"},
    { title: "To", field: "toTime", editable: "never"},
    { title: "Service Level", field: "serviceLevel", editable: "never" },
    { title: "Roundable", field: "roundable", editable: "never", lookup: { true: "Yes", false: "No" }},
    { title: "Apply", field: "apply",
      lookup: { 
        true: <Checkbox {...label} checked={true} style={{marginLeft: -11}}  disabled />,
        false: <Checkbox {...label} checked={false} style={{marginLeft: -11}} disabled defaultChecked />
      }
    },
  ];

  return (
    <div style={{ marginLeft: 20}}>
    <Button
        style={{
        
          borderRadius: 8,
          backgroundColor: "#2AABD5",
          color: "#FFFFFF",
          height: 40,
          marginTop: 20
        }}
        onClick={()=>handleOpen()}
        variant="contained" 
      >
        Add Rule
      </Button>

      <div style={{ height: 20}}></div>
      <></>
      
      <MaterialTable
        icons={tableIcons}
        isLoading={loading}
        columns={columns}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        data={commissions}
        options={{
          filtering: false,
          actionsColumnIndex: -1,
          search: false,
          title: false
        }}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                await deleteCommission(oldData.id);
                await fetchCommissions();
                const index = oldData.tableData.id;
                resolve();
              }, 1000);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                await updateCommission(oldData.id, newData);
                await fetchCommissions();
                const index = oldData.tableData.id;
                resolve();
              }, 1000);
            }),
        }}
        title="Customer Commission Rule"
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 5,
          p: 4,
        }}>
          <Grid container>
            
            <Toolbar className={classes.toolbar} style={{ marginLeft: "-102px"}}>
            <Typography
              component="h1"
              variant="h6"
              color="#4B4B4B"
              noWrap
              className={classes.title}
            >
              Customer Commission
            </Typography>
                
          </Toolbar>
            <Grid
              container
              alignItems="center"
              direction="row"
              className={classes.mainCard}
            >
              <Card className={classes.root}>
                <CardContent>
                  <Grid container justifyContent="center" alignItems="center">
                    
                  </Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
               
                <Stack spacing={3}>
                <Typography
                  component="p"
                  color="#4B4B4B"
                >
                  Time Range *
                </Typography>
                <div style={{display: "flex", flexDirection: "row", marginTop: 5}}>
                  <div style= {{ marginRight: 5}}>
                  <TimePicker
                    value={fromTime}
                    ampm={false}
                    ampmInClock = {false}
                    onChange={(value)=> {
                      setFromTime(value);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    
                  />
                  </div>
                  <div style= {{ marginLeft: 5}}>
                  <TimePicker
                    value={toTime}
                    ampm={false}
                    ampmInClock = {false}
                    onChange={(value)=> {
                      setToTime(value)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    
                  />
                </div>
                </div>
                 
                 
                </Stack>
              </LocalizationProvider>
                  <Select
                      style={{ width: "100%", marginBottom: 10, marginTop: 22 }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue={"0"}
                      label={"serviceLevel"}
                      value={serviceLevel}
                      onChange={(e) => {
                        setServiceLevel(e.target.value)
                      }}
                    >
                      <MenuItem value="0">Select Service Level *</MenuItem>
                      <MenuItem value="STANDARD">STANDARD</MenuItem>
                      <MenuItem value="EXECUTIVE">EXECUTIVE</MenuItem>
                      <MenuItem value="ELECTRIC">ELECTRIC</MenuItem>
                      <MenuItem value="MINI_BUS">MINI BUS</MenuItem>
                      <MenuItem value="CARRIER">CARRIER</MenuItem>
                      <MenuItem value="LARGE_CARRIER">LARGE CARRIER</MenuItem>
                      <MenuItem value="EXE_CARRIER">EXE CARRIER</MenuItem>
                      <MenuItem value="LUXURY">LUXURY</MenuItem>

                      <MenuItem value="ELECTRIC_LUXURY">ELECTRIC LUXURY</MenuItem>
                      <MenuItem value="ELECTRIC_PEOPLE_CARRIER">ELECTRIC PEOPLE CARRIER</MenuItem>
                      <MenuItem value="ELECTRIC_EXE_PEOPLE_CARRIER">ELECTRIC EXE PEOPLE CARRIER</MenuItem>
                      <MenuItem value="ELECTRIC_LARGE_PEOPLE_CARRIER">ELECTRIC LARGE PEOPLE CARRIER</MenuItem>

                    </Select>

                  <TextField
                      onChange={(e)=>setCommission(e.target.value)}
                      value={commission}
                      label={"Commission(%) *"} 
                      style={{ width: "100%", marginBottom: 10 }}
                  />
                  <Checkbox {...label} checked={roundable} style={{marginLeft: -11}} onChange={()=>setRoundable(!roundable)} defaultChecked /> Roundable
                
                </CardContent>
                
                <Grid container justifyContent="center" alignItems="center">
                <Button onClick={handleSubmit} style={{ backgroundColor: "#2AABD5", color: "#FFFFFF",}}>Submit</Button>
                </Grid>
                
              </Card>
            </Grid>
            {connectSuccess && (
              <ErrorSnack success reset={() => reset()} errorMsg={message} />
            )}
            {connectError && (
              <ErrorSnack reset={() => reset()} errorMsg={error.message} />
            )}
        
          </Grid>
        </Box>
      </Modal>
      
    </div>
  );
};


const mapDispatch = {
  updateCommission: ThunkCommission.updateCommission,
  deleteCommission: ThunkCommission.deleteCommission,
  addCustomerCommission: ThunkCommission.addCustomerCommission,
  getCustomerCommissions: ThunkCommission.getCustomerCommissions,
};

export default connect(null, mapDispatch)(CustomerCommission);
