import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@mui/material";
import { Box } from "@material-ui/core";
import { connect } from "react-redux";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { BodyTextEditor } from "./BodyTextEditor";
import ThunkSettings from "../../../redux/actions/thunks/settings";
import ReactHtmlParser from "react-html-parser"
import ErrorSnack from "../../../components/commons/ErrorSnack";

const useStyles = makeStyles((theme) => ({
  mainCard: {
    display: "flex",
    alignContent: "center",
    minWidth: 275,
  },
  root: {
    padding: 20
  },

  title: {
    marginLeft: 80,
    marginRight: 80,
  },
  input: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  innerInput: {
    paddingTop: 48,
  },
  button: {
    backgroundColor: theme.palette.error.main,
    color: "#fff",
  },
}));
function TermsAndConditions ({user,token, updateTermsAndCondition, getLatestTermsAndConditions}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState({ 0:"", bodyText:"<p></p>"});
  const [latestTerm, setLatestTerms] = useState([]);
  const [editEnable, setEditEnable] = useState(false);
  useEffect(()=>{
    initialUpdate()
  },[]);

  const initialUpdate = async() =>{
    const result = await getLatestTermsAndConditions();
    const data = result.termsandconditions;
    setLatestTerms(data);
    setValues({ 0:"", bodyText:data})
  }

  const handleClick = async() => {
    console.log(values.bodyText)
    if(values.bodyText == "<p></p>" || values.bodyText == "<p><br></p>" ){
      return ;

    }else{
      setOpen(false)
      const updatedResult =  await updateTermsAndCondition(values);
      console.log(updatedResult.termsandconditions)
      setOpen(true)
      setLatestTerms(updatedResult.termsandconditions);
    }
    
  }

  const editClick = () => {
    setEditEnable(true)
  }

  useEffect(()=>{
    if(latestTerm !== "<p></p>" || values.bodyText == "<p><br></p>"){
      setEditEnable(false)
    }else{
      setEditEnable(true)
    }
  },[latestTerm]);
    return(
      <Box sx={{ display:"flex", flexDirection:"column"}}>
        <Toolbar className={classes.toolbar} style={{ marginLeft: "-102px"}}>
          <Typography
            component="h1"
            variant="h6"
            color="#4B4B4B"
            noWrap
            className={classes.title}
          >
            Change Terms and Conditions
          </Typography>
        </Toolbar>
        <Box sx={{ display:"flex", flexDirection:"column"}}>
          <Box sx={{ maxHeight:"400px", overflow:"auto"}}>
            {
              ReactHtmlParser(latestTerm)
            }
          </Box>
          {
            editEnable &&  <Box sx={{ maxHeight:"400px", overflow:"auto"}}>
            <BodyTextEditor
              value={values.bodyText}
              setValue={bodyText => setValues({...values, bodyText })}
            />
          </Box>
          }
          {
            !editEnable ? <Button 
            sx={{marginTop:"10px", marginLeft:"10px", width:"100px",border:"solid 1px black"}} 
            onClick={editClick}>Edit</Button>:<Button 
            sx={{marginTop:"10px", marginLeft:"10px", width:"100px",border:"solid 1px black"}} 
            onClick={handleClick}>Submit</Button>
          }
        </Box>
        {
          open && <ErrorSnack success errorMsg={"Successfully Submitted"} />
        }
      </Box>
    )
}
const mapState = (state) => ({
    user: state.userInfo.user,
    token: state.userInfo.token,
  });
  
const mapDispatch = {
    updateTermsAndCondition:ThunkSettings.updateTermsAndConditions,
    getLatestTermsAndConditions:ThunkSettings.getLatestTermsAndConditions
  };
  
export default connect(mapState, mapDispatch)(TermsAndConditions);