import React from 'react'

const MangePassengersIcon = ({color}) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.4421 15.1359C13.9603 15.1359 12.8567 12.0754 12.7202 10.0187C12.6439 8.87799 12.466 6.20443 13.6599 4.50203C14.0207 4.02337 14.4557 3.65124 14.9356 3.41066C15.4156 3.17007 15.9294 3.06662 16.4425 3.10724C16.6023 3.10724 16.7555 3.1902 16.8685 3.33787C16.9814 3.48554 17.0449 3.68583 17.0449 3.89467C17.0449 4.1035 16.9814 4.30379 16.8685 4.45146C16.7555 4.59913 16.6023 4.68209 16.4425 4.68209C16.0967 4.64381 15.7486 4.70292 15.4218 4.85541C15.095 5.00791 14.7969 5.25025 14.5478 5.5661C13.7121 6.75773 13.8579 8.94728 13.9201 9.88221C13.9884 10.9059 14.5739 13.561 16.4421 13.561C16.6019 13.561 16.7551 13.644 16.8681 13.7917C16.981 13.9393 17.0445 14.1396 17.0445 14.3485C17.0445 14.5573 16.981 14.7576 16.8681 14.9053C16.7551 15.0529 16.6019 15.1359 16.4421 15.1359Z" fill={color}/>
    <path d="M16.442 15.1359C16.2823 15.1359 16.129 15.0529 16.0161 14.9053C15.9031 14.7576 15.8396 14.5573 15.8396 14.3485C15.8396 14.1396 15.9031 13.9393 16.0161 13.7917C16.129 13.644 16.2823 13.561 16.442 13.561C18.3102 13.561 18.8957 10.9053 18.964 9.88221C19.0262 8.94781 19.1716 6.7572 18.3359 5.5661C18.087 5.25028 17.7891 5.00794 17.4624 4.85544C17.1356 4.70294 16.7877 4.64383 16.442 4.68209C16.2823 4.68209 16.129 4.59913 16.0161 4.45146C15.9031 4.30379 15.8396 4.1035 15.8396 3.89466C15.8396 3.68583 15.9031 3.48554 16.0161 3.33787C16.129 3.1902 16.2823 3.10724 16.442 3.10724C16.955 3.06662 17.4687 3.17004 17.9486 3.41053C18.4285 3.65102 18.8635 4.02301 19.2242 4.5015C20.4177 6.20338 20.2402 8.87694 20.1639 10.0187C20.0274 12.076 18.923 15.1359 16.442 15.1359ZM9.33793 24.3897C9.19077 24.3895 9.04874 24.3189 8.93865 24.1913C8.82856 24.0636 8.75803 23.8877 8.74037 23.6967C8.52351 21.3439 8.75081 19.6609 9.43431 18.5538C9.98449 17.6609 10.8065 17.1585 11.9462 17.0183C13.9694 16.7685 14.5513 16.0446 14.4361 13.9206C14.4305 13.8175 14.4405 13.7139 14.4655 13.6158C14.4906 13.5177 14.5302 13.427 14.582 13.3488C14.6867 13.1911 14.8351 13.0942 14.9945 13.0794C15.1539 13.0646 15.3112 13.1333 15.4319 13.2701C15.5526 13.407 15.6268 13.601 15.638 13.8093C15.8433 17.6042 13.9445 18.3533 12.0595 18.5864C11.2491 18.6861 10.7126 18.9906 10.372 19.5418C9.90979 20.2914 9.76361 21.6258 9.9363 23.5088C9.9464 23.6193 9.93847 23.7314 9.91301 23.8376C9.88755 23.9439 9.84515 24.0418 9.78861 24.1251C9.73206 24.2083 9.66266 24.2749 9.58497 24.3205C9.50729 24.3661 9.42309 24.3897 9.33793 24.3897Z" fill={color}/>
    <path d="M23.5007 24.1229C23.4822 24.1229 23.4637 24.1218 23.4453 24.1192C23.3665 24.1098 23.2899 24.0803 23.2198 24.0322C23.1498 23.9841 23.0876 23.9185 23.037 23.839C22.9864 23.7596 22.9482 23.6678 22.9247 23.5691C22.9011 23.4704 22.8927 23.3665 22.8999 23.2635C23.1537 19.6125 22.2943 18.7663 20.8253 18.5852C18.9403 18.3526 17.0411 17.6035 17.246 13.8082C17.2572 13.5998 17.3314 13.4059 17.4521 13.269C17.5728 13.1321 17.7301 13.0635 17.8895 13.0782C18.0489 13.093 18.1973 13.1899 18.302 13.3477C18.4067 13.5054 18.4592 13.7111 18.4479 13.9195C18.3327 16.0439 18.915 16.7678 20.9378 17.0172C24.1424 17.4125 24.2774 20.853 24.0999 23.4063C24.0862 23.6019 24.0173 23.7837 23.9064 23.9163C23.7956 24.0488 23.6509 24.1225 23.5007 24.1229ZM6.78057 10.7504C4.63207 10.7504 3.67629 8.11197 3.55863 6.33818C3.49317 5.36282 3.34177 3.07878 4.37305 1.6084C4.68487 1.19342 5.06108 0.870564 5.47637 0.661576C5.89165 0.452587 6.33636 0.36231 6.78057 0.396824C6.94033 0.396824 7.09355 0.479784 7.20652 0.627454C7.31948 0.775124 7.38295 0.975407 7.38295 1.18424C7.38295 1.39308 7.31948 1.59336 7.20652 1.74103C7.09355 1.88871 6.94033 1.97167 6.78057 1.97167C6.50366 1.94016 6.22481 1.98643 5.96271 2.1074C5.70061 2.22836 5.46134 2.42121 5.26096 2.67299C4.5879 3.6326 4.70757 5.43317 4.75857 6.20169C4.81359 7.02901 5.28385 9.17552 6.78057 9.17552C6.94033 9.17552 7.09355 9.25848 7.20652 9.40615C7.31948 9.55381 7.38295 9.7541 7.38295 9.96294C7.38295 10.1718 7.31948 10.3721 7.20652 10.5197C7.09355 10.6674 6.94033 10.7504 6.78057 10.7504Z" fill={color}/>
    <path d="M6.78085 10.7509C6.62109 10.7509 6.46787 10.6679 6.3549 10.5202C6.24193 10.3726 6.17847 10.1723 6.17847 9.96346C6.17847 9.75462 6.24193 9.55434 6.3549 9.40667C6.46787 9.259 6.62109 9.17604 6.78085 9.17604C8.27717 9.17604 8.74783 7.029 8.80285 6.20169C8.84943 5.49931 8.97432 3.63365 8.30086 2.67299C8.10052 2.42112 7.86126 2.22822 7.59915 2.10725C7.33704 1.98628 7.05816 1.94005 6.78125 1.97166C6.62149 1.97166 6.46827 1.8887 6.3553 1.74103C6.24233 1.59336 6.17887 1.39308 6.17887 1.18424C6.17887 0.975405 6.24233 0.775122 6.3553 0.627452C6.46827 0.479782 6.62149 0.396822 6.78125 0.396822C7.22559 0.362299 7.67043 0.45267 8.0858 0.661848C8.50116 0.871026 8.87741 1.19415 9.18917 1.60945C10.2205 3.08035 10.0678 5.36335 10.0028 6.3387C9.88513 8.11197 8.92975 10.7504 6.78125 10.7504L6.78085 10.7509Z" fill={color}/>
    <path d="M0.814253 18.5227C0.667028 18.5226 0.524947 18.4519 0.414849 18.3242C0.30475 18.1964 0.234259 18.0203 0.21669 17.8293C0.0319595 15.8171 0.229541 14.3735 0.820678 13.4155C1.09281 13.0081 1.42666 12.6791 1.80112 12.4491C2.17558 12.2191 2.58249 12.0933 2.99608 12.0795C4.66307 11.8743 5.09398 11.3435 5.00001 9.61227C4.99289 9.50807 5.00174 9.40306 5.02602 9.30338C5.0503 9.2037 5.08954 9.11134 5.14144 9.0317C5.19333 8.95206 5.25685 8.88674 5.32827 8.83955C5.3997 8.79236 5.4776 8.76425 5.55743 8.75685C5.63726 8.74946 5.71742 8.76293 5.79322 8.79649C5.86902 8.83004 5.93895 8.883 5.99891 8.95228C6.05888 9.02156 6.10769 9.10576 6.14248 9.19997C6.17727 9.29418 6.19736 9.39651 6.20156 9.50098C6.38067 12.7966 4.73857 13.4465 3.10933 13.647C2.85654 13.6453 2.60618 13.7115 2.37378 13.8415C2.14139 13.9716 1.93192 14.1626 1.75839 14.4029C1.38732 15.0051 1.27086 16.0938 1.41302 17.6408C1.42313 17.7514 1.41518 17.8635 1.3897 17.9698C1.36422 18.076 1.32178 18.174 1.2652 18.2573C1.20861 18.3405 1.13915 18.4071 1.06142 18.4527C0.98369 18.4983 0.899446 18.5218 0.814253 18.5217V18.5227ZM12.7089 18.2991C12.6904 18.2991 12.6719 18.298 12.6535 18.2954C12.4944 18.2765 12.3476 18.1758 12.2454 18.0154C12.1431 17.8551 12.0937 17.6482 12.1081 17.4403C12.337 14.1541 11.4555 13.7714 10.4528 13.6481C8.82312 13.447 7.18183 12.7976 7.35974 9.50203C7.37097 9.29368 7.44507 9.0997 7.56572 8.96276C7.68637 8.82582 7.84369 8.75714 8.00308 8.77183C8.16247 8.78652 8.31087 8.88337 8.41563 9.04108C8.52039 9.19879 8.57293 9.40444 8.56169 9.61279C8.46812 11.3441 8.89902 11.8748 10.5656 12.08C13.3434 12.4223 13.4611 15.3846 13.3081 17.5825C13.2944 17.7781 13.2255 17.96 13.1146 18.0925C13.0038 18.225 12.8591 18.2987 12.7089 18.2991Z" fill={color}/>
    </svg>
    
  )
}

export default MangePassengersIcon