import PASSENGERS from "../constants/passengers";

export type PassengersRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
  };
};

const Passengers: PassengersRepo = {
  api: {
    request: () => ({
      type: PASSENGERS.PASSENGERS_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: PASSENGERS.PASSENGERS_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: PASSENGERS.PASSENGERS_API.SET_RESPONSE,
      payload: { data },
    }),
  },
};

export default Passengers;
