import SIGN_UP from "../../actions/local/constants/sign-up";
import signUpState, { SignUpState } from "../../initial-state/sign-up";

const signUpReducer = (state: SignUpState = signUpState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SIGN_UP.RESET_ALL: {
      return {
        ...signUpState,
      };
    }
    case SIGN_UP.FIELD.UPDATE: {
      const { field, newContent } = payload;
      return {
        ...state,
        [field]: newContent,
      };
    }
    case SIGN_UP.FIELD.CLEAR: {
      const { field } = payload;
      return {
        ...state,
        [field]: "",
      };
    }
    case SIGN_UP.FLAGS.TOGGLE_COMPLETE: {
      const { newState } = payload;
      return {
        ...state,
        flags: {
          ...state.flags,
          completed: newState,
        },
      };
    }
    case SIGN_UP.FLAGS.UPDATE_STEP: {
      const { step } = payload;
      return {
        ...state,
        flags: {
          ...state.flags,
          currentStep: step,
        },
      };
    }
    case SIGN_UP.FLAGS.RESET: {
      return {
        ...state,
        flags: {
          completed: false,
          currentStep: 1,
        },
      };
    }
    default:
      return state;
  }
};

export default signUpReducer;
