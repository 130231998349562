import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import ThunksCustomers from "../../../redux/actions/thunks/customer";
import _ from "lodash";
import { DataUsageOutlined } from "@material-ui/icons";
import CustomerCommission from "./CustomerCommission";
const useStyles = makeStyles((theme) => ({
  formControl: {},
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  submit: { margin: theme.spacing(3, 0, 2) },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginLeft: "24%"
    },
  },
};
function getStyles(item, personName, theme) {
  if (personName.indexOf(item.id) > -1) {
    return {
      fontWeight: theme.typography.fontWeightRegular,
      backgroundColor: theme.palette.grey[200],
    };
  } else
    return {
      fontWeight: theme.typography.fontWeightMedium,
    };
}
function CustomerDispatchers({
  id,
  dispatchers,
  customerDispatchers,
  assignDispatchers,
  getCustomerDispatchers,
}) {
  const [loadering, setLoadering] = useState(true);
  const [personName, setPersonName] = useState([]);

  useEffect(() => {
    if (loadering) {
      getCustomerDispatchers('customer', id);
      setPersonName(dispatchers.map((item) => item.id));
      setLoadering(false);
    }
  }, [loadering]);
  const {
    loading,
    response: { data },
    error,
  } = customerDispatchers;
  const classes = useStyles();
  const theme = useTheme();

  const finalData = data.map((item) => ({
    id: item.id,
    displayName: item.displayName,
    fullName: item.fullName,
  }));

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setPersonName(value);
  };

  return (
    <div style={{ margin: 20 }}>
      <FormControl fullWidth className={classes.formControl}>
        <InputLabel id="demo-mutiple-chip-label">Dispatchers</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          displayEmpty={true}
          multiple
          value={personName}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) =>
            data
              .filter((item) => selected.includes(item.id))
              .map((item) => (
                <Chip
                  key={item.id}
                  label={item.displayName}
                  className={classes.chip}
                />
              ))
          }
          MenuProps={MenuProps}
        >
          {!loading &&
            data.map((item) => (
              <MenuItem
                key={item}
                value={item.id}
                style={getStyles(item, personName, theme)}
              >
                {item.displayName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Button
        color="primary"
        variant="contained"
        disabled={personName.length === 0}
        onClick={() => {
          assignDispatchers({ customerId: id, dispatcherIds: personName });
        }}
        className={classes.submit}
      >
        update
      </Button>
      {/* <CustomerCommission clientId={id}/> */}
    </div>
  );
}

const mapState = (state) => ({ customerDispatchers: state.customerDispatcher });
const mapDispatch = {
  assignDispatchers: ThunksCustomers.assignCustomerDispatcher,
  getCustomerDispatchers: ThunksCustomers.getCustomerDispatchers,
};

export default connect(mapState, mapDispatch)(CustomerDispatchers);
