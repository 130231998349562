import { useEffect, useState } from "react";
import EditIcon from "../../../assets/icons/lucide_edit.svg";
import HourlyEditModal from "./Modals/HourlyEditModal";
import mobioApi from "../../../services";
import { useParams } from "react-router";
import { removeLast00 } from "../../../helpers/getTimeFormat";
import { getCountryCurrency } from "../../../helpers/getCountryCurrency";

const HourlyRule = ({ token, serviceLevelsList, setRateStatusData }) => {
  const { airportId, country } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [hourlyRateList, setHourlyRateList] = useState([]);
  const [selectDefaultService, setSelectDefaultService] = useState(null);

  const [countryCurrency, setCountryCurrency] = useState("");

  const countryCurrencyHandle = async (countryName) => {
    const currency = await getCountryCurrency(countryName);
    setCountryCurrency(currency);
  };

  const getHourlyRate = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getHourlyRate(airportId);
      setHourlyRateList(response?.data?.data);
      if (response?.data?.data?.length > 0) {
        response?.data?.data?.forEach((rate) => {
          if (rate?.hourlyRateLevel.length > 0) {
            setRateStatusData((pre) => {
              return { ...pre, hourlyRates: true };
            });
          }
        });
      }
    } catch (error) {
      setHourlyRateList([]);
    }
  };

  useEffect(() => {
    getHourlyRate();
    if (country) {
      countryCurrencyHandle(country);
    }
  }, []);

  useEffect(() => {
    if (!modalOpen) {
      setSelectDefaultService("");
    }
  }, [modalOpen]);

  const updateHourlyList = (data) => {
    const updateList = hourlyRateList?.map((dm) => {
      if (Object.keys(data).length > 0 && dm.id == data.serviceLevelId) {
        if (dm?.hourlyRateLevel?.length > 0) {
          dm.hourlyRateLevel[0].minBookingHour = data.minBookingHour;
          // dm.hourlyRateLevel[0].cancellationTime = data.cancellationTime;
          dm.hourlyRateLevel[0].rate = data.rate;
        } else {
          const appendData = {
            minBookingHour: data.minBookingHour,
            // cancellationTime: data.cancellationTime,
            rate: data.rate,
          };
          dm?.hourlyRateLevel.push(appendData);
        }
        setRateStatusData((pre) => {
          return { ...pre, hourlyRates: true };
        });
      }
      return dm;
    });
    setHourlyRateList(updateList);
  };

  return (
    <div>
      <div className="bg-white  mt-20 border-radius-8">
        <table className="custom-table-v3">
          <thead>
            <tr>
              <th>Vehicle Type</th>
              <th>Min Booking Hour</th>
              {/* <th>Cancellation Time</th> */}
              <th>Hourly Rate</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {hourlyRateList?.map((row, index) => (
              <tr key={index}>
                <td>
                  <div className="flex align-item-center">
                    <img
                      src={row?.icon}
                      alt="icon"
                      className="distance-rate-table-icon"
                    />
                    <p className="m-0"> {row?.name}</p>
                  </div>
                </td>
                <td>
                  {row.hourlyRateLevel.length > 0
                    ? `${removeLast00(row.hourlyRateLevel[0]?.minBookingHour)}`
                    : "--"}{" "}
                </td>
                {/* <td>
                  {" "}
                  {row.hourlyRateLevel.length > 0
                    ? `${removeLast00(
                        row.hourlyRateLevel[0]?.cancellationTime
                      )}`
                    : "--"}{" "}
                </td> */}
                <td>
                  {row.hourlyRateLevel.length > 0
                    ? `${row.hourlyRateLevel[0]?.rate} ${countryCurrency}`
                    : "--"}{" "}
                </td>
                <td>
                  <img
                    src={EditIcon}
                    className="cursor-pointer"
                    style={{ width: "23px", height: "23px" }}
                    onClick={() => {
                      setModalOpen(true);
                      setSelectDefaultService(row?.id);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <HourlyEditModal
        airportId={airportId}
        token={token}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        serviceLevelsList={serviceLevelsList}
        selectDefaultService={selectDefaultService}
        updateList={updateHourlyList}
        hourlyRateList={hourlyRateList}
        countryCurrency={countryCurrency}
      />
    </div>
  );
};

export default HourlyRule;
