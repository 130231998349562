import React, { useEffect, useState } from "react";
import {
  ArrowDownwardOutlined,
  ArrowForwardOutlined,
  ArrowUpwardOutlined,
} from "@material-ui/icons";
import AddLeadTimeOfferModal from "./Modals/AddLeadTimeOfferModal";
import mobioApi from "../../../services";
import { useParams } from "react-router";
import { makeItHours, removeLast00 } from "../../../helpers/getTimeFormat";

const LeadTimeList = ({
  token,
  serviceLevelsList,
  leadTimeModalOpen,
  setLeadTimeModalOpen = () => {},
}) => {
  const { airportId } = useParams();
  const [leadTime, setLeadTime] = useState([]);
  const [singleLeadTime, setSingleLeadTime] = useState("");

  const getLeadTime = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getLeadTime(airportId);
      setLeadTime(response?.data.data);
    } catch (error) {
      setLeadTime([]);
    }
  };

  useEffect(() => {
    getLeadTime();
  }, []);

  useEffect(() => {
    if (!leadTimeModalOpen) {
      setSingleLeadTime("");
    }
  }, [leadTimeModalOpen]);

  return (
    <div>
      <div className="bg-white  mt-20 border-radius-8">
        <table className="custom-table-v3">
          <thead>
            <tr>
              <th>Vehicle Type</th>
              <th>Prior Time</th>
              <th>Rate Change</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {leadTime?.length > 0 ? (
              leadTime?.map((row, index) => (
                <tr key={index}>
                  <td>
                    <div className="flex align-item-center">
                      <img
                        src={row?.associatedLeadTime?.icon}
                        alt="icon"
                        className="distance-rate-table-icon"
                      />
                      <p className="m-0"> {row?.associatedLeadTime.name}</p>
                    </div>
                  </td>
                  {/* <td>{removeLast00(row?.priorTime)} </td> */}
                  <td>{makeItHours(row?.priorTime)} </td>
                  <td className="flex align-item-center">
                    {row?.rate}%{" "}
                    <ArrowUpwardOutlined
                      style={{
                        color: "#428702",
                        width: "17px",
                        height: "21px",
                        marginLeft: "6px",
                      }}
                    />
                  </td>
                  {/* <td>
                  <div className="flex align-item-center">
                    5%
                    <ArrowDownwardOutlined
                      style={{
                        color: "#FC0808",
                        width: "17px",
                        height: "21px",
                        marginLeft: "6px",
                      }}
                    />
                  </div>
                </td> */}

                  <td>
                    <ArrowForwardOutlined
                      onClick={() => {
                        setSingleLeadTime(row);
                        setLeadTimeModalOpen(true);
                      }}
                      style={{
                        color: "#29AEE6",
                        cursor: "pointer",
                      }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <>
                <tr>
                  <td colSpan="4">
                    <div className="table-no-record-section">
                      No records are found.
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      <AddLeadTimeOfferModal
        token={token}
        modalOpen={leadTimeModalOpen}
        singleLeadTime={singleLeadTime}
        setModalOpen={setLeadTimeModalOpen}
        serviceLevelsList={serviceLevelsList}
        apiCall={() => {
          getLeadTime();
        }}
      />
    </div>
  );
};

export default LeadTimeList;
