import React from "react";

const OffersIcons = ({ color = "#29AEE6" }) => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4683 27.5269C21.648 27.5269 27.4683 21.7066 27.4683 14.5269C27.4683 7.34715 21.648 1.52686 14.4683 1.52686C7.28856 1.52686 1.46826 7.34715 1.46826 14.5269C1.46826 21.7066 7.28856 27.5269 14.4683 27.5269Z"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M18.4567 9.20874L10.7749 19.2542"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M11.9571 12.4587C13.0177 12.4587 13.8775 11.5989 13.8775 10.5383C13.8775 9.47761 13.0177 8.6178 11.9571 8.6178C10.8964 8.6178 10.0366 9.47761 10.0366 10.5383C10.0366 11.5989 10.8964 12.4587 11.9571 12.4587Z"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M16.9795 20.1405C18.0402 20.1405 18.9 19.2807 18.9 18.22C18.9 17.1594 18.0402 16.2996 16.9795 16.2996C15.9189 16.2996 15.0591 17.1594 15.0591 18.22C15.0591 19.2807 15.9189 20.1405 16.9795 20.1405Z"
        stroke={color}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default OffersIcons;
