import React, { forwardRef, useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Slide,
  Typography,
  makeStyles,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UserAuthorities from "./UserAuthorities";
import Local from "../../../redux/actions/local";
import CustomerUsers from "../../../redux/actions/thunks/CustomerUsers";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import { getInitials } from "../../../helpers/getNameAlpha";
import Badge from "@mui/material/Badge";
import {
  Block,
  ChevronLeftOutlined,
  ChevronRightOutlined,
  DeleteOutline,
  EditOutlined,
  MailOutline,
  MenuBook,
} from "@material-ui/icons";
import { BookingMenuIcon, DeleteCSIcon } from "../../../assets/icons";
import MOButton from "../../../components/commons/MOButton";
import { Pagination } from "@mui/material";
import { useLocation } from "react-router";
import DropDown from "../../../components/commons/DropDown";

const useStyles = makeStyles({
  root: {
    ".MuiDialogContent-root": {
      // width:"900px"
    },
    "& .MuiDialog-paperWidthLg": {
      // borderRadius:32,
    },
    "& .MuiDialog-paper": {
      // width: "900px !important"
    },
    "& .MuiDialog-paperWidthSm": {
      width: "690px !important",

    },
    "& .MuiDialogTitle-root": {},
    "& .MuiPaper-rounded": {
      borderRadius: 32,
    },
  },
  redIcon: {
    color: "red",
  },
  customerUserCard: {
    backgroundColor: "#FFFFFF",
    padding: "17px 36px",
    borderRadius: 4,
    marginBottom: 10,
    display: "flex",
  },

});

const StyledBadge = styled(Badge)(({ theme, block }) => ({
  "& .MuiBadge-badge": {
    // backgroundColor: '#44b700',
    backgroundColor: block ? "#F42A41" : "#14BA6D",
    position: 'absolute',
    top: 0,
    right: 7,
    // color: '#44b700',
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    // '&::after': {
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   width: '100%',
    //   height: '100%',
    //   borderRadius: '50%',
    //   animation: 'ripple 1.2s infinite ease-in-out',
    //   border: '1px solid currentColor',
    //   content: '""',
    // },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const options = ["Delete", "Edit User", "Block User"];

const ITEM_HEIGHT = 48;

function UserList({
  message,
  getAllAuthorizations,
  allClientUsers,
  deleteUser,
  getAllClientUsers,
  selectUser,
  reset,
  response,
  resetUpadte,
  myUser,
  blockUser,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [agree, setAgree] = useState(false);
  const [blockModalUser, setBlockModalUser] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [singleUserData, setSingleUserData] = useState({});
  const [page, setPage] = React.useState(1);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(async () => {
    await getAllAuthorizations();
    setLoading(false);
  }, []);

  const locationQuery = useLocation();
  
  useEffect(() => {
    const queryParams = new URLSearchParams(locationQuery.search);
    const searchingQuery = queryParams.get('search');
    if(searchingQuery){
      getAllClientUsers(searchingQuery)
    }else{
      // setSearchText("")
      getAllClientUsers()
    }
    
  }, [locationQuery]);

  const columns = [
    { title: "Email", field: "email", editable: "never", type: "email" },
    { title: "First Name", field: "firstName", editable: "never" },
    { title: "Last Name", field: "lastName", editable: "never" },
    {
      title: "Status",
      field: "block",
      editable: "never",
      render: (rowData) => (rowData.block ? "Blocked" : "Active"),
    },
  ];
  const handleClose = () => {
    setAgree(false);
  };

  const handleBlockUserClose = () => {
    setBlockModalUser(false);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };
    return (
    <>
      {/* New Design */}
      {allClientUsers?.map((clientUser, index) => {
        return (
          <>
            <Box key={index} className={classes.customerUserCard}>
              {/*  */}
              <Box style={{ display: "flex", flex: 1 }}>
                <Box>
                  <StyledBadge
                    block={clientUser.block}
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                  >
                    <Avatar
                      variant="square"
                      style={{ borderRadius: 4, width: 53, height: 48 }}
                      alt="Remy Sharp"
                      src=""
                    >
                      {getInitials(
                        `${clientUser.firstName} ${clientUser.lastName}`
                      )}
                    </Avatar>
                  </StyledBadge>
                </Box>
                <Box style={{ marginLeft: 22 }}>
                  <Typography
                    style={{ fontSize: 22, fontWeight: 500, lineHeight: 1.2 }}
                  >{`${clientUser.firstName} ${clientUser.lastName}`}</Typography>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <MailOutline
                      style={{ color: "#29AEE6", width: 21, height: 16 }}
                    />
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#878787",
                      }}
                    >
                      {clientUser.email}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/*  */}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flex: 1,
                  borderLeft: "1px solid",
                  paddingLeft: 20,
                }}
              >
                <Box>
                  <Typography
                    style={{ fontSize: 12, fontWeight: 500, color: "#878787" }}
                  >
                    Authorities
                  </Typography>
                  {clientUser?.userAuthorities?.map((userAuth) => {
                    return (
                      <Chip
                        label={userAuth?.name}
                        variant="outlined"
                        style={{
                          backgroundColor: "#29AEE6",
                          color: "#ffffff",
                          height: 30,
                          border: "none",
                          marginTop: 7,
                          marginRight: 16,
                        }}
                      />
                    );
                  })}
                </Box>
                {/* menu */}
                <DropDown
                      dropDownOpen={anchorEl}
                      setDropDownOpen={setAnchorEl}
                      uniqueId={clientUser.id}
                      menu={[
                        {
                          onClick: () => {
                            setSingleUserData(clientUser);
                            setAgree(true);
                          },
                          IconRender: () => <DeleteOutline />,
                          title: "Delete",
                        },  
                        {
                          onClick: () => {
                            selectUser(clientUser);
                          },
                          IconRender: () => <EditOutlined />, 
                          title: "Edit User",
                        },
                        {
                          onClick: () => {
                            setSingleUserData(clientUser);
                        setBlockModalUser(true);
                          },
                          IconRender: () => <Block />, 
                          title: "Block",
                        },
                      ]}
                    />
                {/* <div className="dropdown-menu-section">
                  <button onClick={() => setAnchorEl(clientUser.id)}>
                    <BookingMenuIcon />
                  </button>
                  <div
                    className={`${
                      clientUser.id === anchorEl
                      ? "dropdown-menu-active"
                      : "dropdown-menu-hide"
                      } dropdown-menu-list`}
                  >
                    <button
                      onClick={() => {
                        setSingleUserData(clientUser);
                        setAgree(true);
                        setAnchorEl("");
                      }}
                    >
                      <DeleteOutline /> Delete
                    </button>
                    <button
                      onClick={() => {
                        selectUser(clientUser);
                        setAnchorEl("");
                      }}
                    >
                      <EditOutlined /> Edit User
                    </button>
                    <button
                      onClick={() => {
                        setSingleUserData(clientUser);
                        setBlockModalUser(true);
                        setAnchorEl("");
                      }}
                    >
                      <Block /> {clientUser?.block ? "Unblock" : "Block"}
                    </button>
                  </div>
                </div> */}
                {/* <Box>              
                 <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  // onClick={handleClick}
                  onClick={handleClick}
                >
                  <BookingMenuIcon />
                </IconButton> 
                <Menu
                  // id="long-menu"
                  // MenuListProps={{
                  //   'aria-labelledby': 'long-button',
                  // }}
                  // anchorEl={anchorEl}
                  // open={open}
                  // onClose={handleMenuClose}
                  // PaperProps={{
                  //   style: {
                  //     maxHeight: ITEM_HEIGHT * 4.5,
                  //     width: '20ch',
                  //   },
                  // }}
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem  onClick={()=>{
                    //  setSingleUserData(clientUser)
                    handleStoreUserData(clientUser)
                     setAgree(true);
                     handleMenuClose()
                  }}>
                      <DeleteOutline/> Delete 
                  </MenuItem>
                  <MenuItem  onClick={()=>{
                     selectUser(clientUser);
                     handleMenuClose()
                  }}>
                      <EditOutlined/> Edit User
                  </MenuItem>
                  <MenuItem  onClick={()=>{
                    console.log(index,"clientUser",clientUser)
                    // setSingleUserData(clientUser)
                    handleStoreUserData(clientUser)
                    setBlockModalUser(true);
                    handleMenuClose()
                  }}>
                      <Block/> Block
                  </MenuItem>
                </Menu>
              
            </Box> */}
              </Box>
            </Box>
          </>
        );
      })}

      {/*  */}
      {/* <MaterialTable
        icons={tableIcons}
        columns={columns}
        isLoading={loading}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        data={allClientUsers.filter((item) => item.id != myUser.id)}
        actions={[
          (rowData) => {
            return {
              icon: () =>
                rowData.block ? (
                  <LockIcon className={classes.redIcon} />
                ) : (
                  <LockOpenIcon />
                ),
              tooltip: rowData.block ? "Unblock User" : "Block User",
              onClick: async (event, rowData) => {
                // await blockUser(rowData.id, rowData.block);
                // await getAllClientUsers();
                setSingleUserData(rowData)
                setBlockModalUser(true);
              },
            };
          },
          {
            icon: () => <EditIcon />,
            tooltip: "Edit User",
            onClick: (event, rowData) => {
              selectUser(rowData);
            },
          },
          {
            icon: () => <DeleteIcon />,
            tooltip: "Delete User",
            onClick: async (event, rowData) => {
              // setDeleteData(rowData);
              setSingleUserData(rowData)
              setAgree(true);
            },
          },
        ]}
        detailPanel={[
          {
            tooltip: "Authories Assigned",
            render: (rowData) => {
              return <UserAuthorities data={rowData} />;
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          detailPanelType: "single",
        }}
        title="Organization Users"
      /> */}
      {/* Pagination */}
      {/* <Box
        style={{
          backgroundColor: "#E0E0E0",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "15px 17px",
        }}
      >
        <Typography
          style={{
            color: "#1C1C1C",
            fontSize: 12,
            fontWeight: 600,
            marginRight: 70,
          }}
        >
          Rows per page: {page}
        </Typography>
        <Typography
          style={{
            color: "#1C1C1C",
            fontSize: 12,
            fontWeight: 600,
            marginRight: 40,
          }}
        >
          1-10 of 276
        </Typography>
        <ChevronLeftOutlined />
        <ChevronRightOutlined />

        <Pagination count={10} page={page} onChange={handleChange} />
      </Box> */}

      {!!message?.error && (
        <ErrorSnack errorMsg={message.error} reset={() => reset()} />
      )}
      {!!message?.success && (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"User Deleted Successfully"}
        />
      )}
      {!!response?.error && (
        <ErrorSnack errorMsg={response.error} reset={() => resetUpadte()} />
      )}
      {!!response?.success && (
        <ErrorSnack
          reset={() => resetUpadte()}
          success
          errorMsg={"Authorizations Update Successfully"}
        />
      )}
      {/* User delete */}
      <Dialog
        open={agree}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className={classes.root}
      >
        <DialogTitle
          style={{ display: "flex", justifyContent: "center", paddingTop: 50 }}
        >
          <DeleteCSIcon />{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
           <Box style={{display:"flex",flexDirection:"column", alignItems:"center"}}>
           <Typography style={{ fontSize: 24, fontWeight: 700,color:"#170F49" }}>
              Are you sure , you want to delete user?
            </Typography>
            <Typography
              style={{ textAlign: "center", fontSize: 18, fontWeight: 400 }}
            >
              By deleting the user, all the data will be lost
            </Typography>
           </Box>
           
            {/* Are you sure to delete this user? */}
          </DialogContentText>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 60,
              padding: "0px 90px 60px 90px",
            }}
          >
            <MOButton title="Cancel" icon={false} onClick={handleClose} />
            <MOButton
              title="Delete"
              type="danger"
              icon={false}
              onClick={async () => {
                await deleteUser(singleUserData.id);
                await getAllClientUsers();
                setAgree(false);
              }}
            />
          </Box>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button
            onClick={async () => {
              await deleteUser(deleteData.id);
              await getAllClientUsers();
              setAgree(false);
            }}
          >
            Yes
          </Button>
        </DialogActions> */}
      </Dialog>

      {/* Block User */}
      <Dialog
        open={blockModalUser}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleBlockUserClose}
        aria-describedby="alert-dialog-slide-description"
        className={classes.root}
      >
        <DialogTitle
          style={{ display: "flex", justifyContent: "center", paddingTop: 50 }}
        >
          <Block style={{ fontSize: 39, color: "#F42A41" }} />{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography style={{ fontSize: 24, fontWeight: 700,color:"#170F49" }}>
                Are you sure , you want to {singleUserData?.block ? "unblock" : "block"} user?
              </Typography>
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: 400,
                  width: 480,
                }}
              >
                {singleUserData?.block ? "By unblocking user, you are giving all the authorities listed to them" : "By blocking the user, you can take away all the authority from them."}
              </Typography>
            </Box>
            {/* Are you sure to delete this user? */}
          </DialogContentText>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 60,
              padding: "0px 90px 60px 90px",
            }}
          >
            <MOButton
              title="Cancel"
              icon={false}
              onClick={handleBlockUserClose}
            />
            <MOButton
              // title="Block"
              title={singleUserData.block ? "Un-Block" : "Block"}
              type="danger"
              icon={false}
              onClick={async () => {
                console.log("singleUserData.id", singleUserData.id);
                await blockUser(singleUserData.id, singleUserData.block);
                await getAllClientUsers();
                setSingleUserData({});
                setBlockModalUser(false);
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

const mapState = (state) => ({
  message: state.customerUsers.deleteUser,
  allClientUsers: state?.customerUsers?.allClientUsers || [],
  response: state.customerUsers.updateUser,
  myUser: state.userInfo.user,
});

const mapDispatch = {
  getAllAuthorizations: CustomerUsers.getAllAuthorizations,
  deleteUser: CustomerUsers.deleteCustomerUser,
  blockUser: CustomerUsers.blockUser,
  getAllClientUsers: CustomerUsers.customerUsers,
  selectUser: Local.dialogActions.selectUser,
  reset: Local.deleteCustomerUser.reset,
  resetUpadte: Local.UpdateCustomerUser.reset,
};

export default connect(mapState, mapDispatch)(UserList);
