import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ReactElement, useState, useEffect } from "react";
import { connect } from "react-redux";
import Pressable from "../../../components/commons/pressable";
import ThunksLogin from "../../../redux/actions/thunks/login";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import { Profile, ProfilePassword } from "../../../redux/actions/local/user";
import { FormHelperText } from "@mui/material";
import {
  PersonOutlineOutlined,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../../components/commons/ButtonGrouping";
import { OfficeIcon, SignalPassengerIcon } from "../../../assets/icons";
import MOButton from "../../../components/commons/MOButton";

interface Props {
  user: any;
  updateUser: any;
  changePassword: any;
  error: any;
  success: any;
  reset: () => any;
  resetPass: () => any;
  resetPassError: (error: any, errorMessage: any) => any;
  profileError: any;
  profileSuccess: any;
  profileErrormsg: any;
}
const useStyles = makeStyles((theme) => ({
  container: {
    // marginTop: theme.spacing(4),
    backgroundColor: "#FFFFFF",
    padding: "62px 46px",
    marginTop: "16px",
  },
  passwordOuterContainer: {
    padding: "0 16px",
  },
  passwordContainer: {
    padding: "44px 16px",
    backgroundColor: "#FFFFFF",
    marginTop: "16px",
  },
  pageTitle: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 8,
  },
  innerContainer: {
    border: "1px solid #00000036",
    borderRadius: 4,
    width: "100%",
    padding: "47px 28px 25px 47px",
    position: "relative",
  },
  fieldTitle: {
    display: "flex",
    border: "1px solid #00000059",
    maxWidth: "max-content",
    padding: "10px 16px",
    position: "absolute",
    top: -18,
    left: 48,
    backgroundColor: "#ffffff",
    borderRadius: 4,
  },
  topWidth: {},
}));
function CustomerProfile({
  user,
  updateUser,
  changePassword,
  error,
  reset,
  resetPass,
  resetPassError,
  success,
  profileError,
  profileSuccess,
  profileErrormsg,
}: Props): ReactElement {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setCurrentShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [passStrength, setPassStrength] = useState("");
  const [profile, setProfile] = useState(user);
  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confNewPassword: "",
  });
  const [type, setType] = useState("Customer Profile");
  let regex = /^[0-9]+$/;
  let regexBankAccNo = /^^[A-Z]{2}[A-Za-z0-9]{13,31}$/;
  let firstNameErr = profile.firstName == "" || profile.firstName == null;
  let lastNameErr = profile.lastName == "" || profile.lastName == null;
  let companyNameErr = profile.companyName == "" || profile.companyName == null;
  let companyAddressErr =
    profile.companyAddress == "" || profile.companyAddress == null;
  let companyPostCodeErr =
    profile.companyPostCode == "" || profile.companyPostCode == null;
  let companyCityErr = profile.companyCity == "" || profile.companyCity == null;
  let companyRegistrationNumberErr =
    profile.companyRegistrationNumber == "" ||
    profile.companyRegistrationNumber == null;
  let companyVATNumberErr =
    profile.companyVATNumber == "" || profile.companyVATNumber == null;
  let bankAccountNumberErr =
    profile.bankAccountNumber == "" || profile.bankAccountNumber == null;
  let bankAccountHolderNameErr =
    profile.bankAccountHolderName == "" ||
    profile.bankAccountHolderName == null;
  let vatPercentageErr = regex.test(profile.vatPercentage);
  let bankAccNumberRegErr = regexBankAccNo.test(profile.bankAccountNumber);

  useEffect(() => {
    setProfile(user);
  }, [user]);

  const handleUpload = () => {
    if (
      !firstNameErr &&
      !lastNameErr &&
      !companyNameErr &&
      !companyCityErr &&
      !companyAddressErr &&
      !companyPostCodeErr &&
      !companyRegistrationNumberErr &&
      !companyVATNumberErr &&
      !bankAccountHolderNameErr &&
      !bankAccountNumberErr &&
      vatPercentageErr &&
      bankAccNumberRegErr
    ) {
      updateUser(
        profile.firstName,
        profile.lastName,
        profile.companyName,
        profile.companyAddress,
        profile.companyPostCode,
        profile.companyCity,
        profile.companyRegistrationNumber,
        profile.companyVATNumber,
        profile.bankAccountHolderName,
        profile.bankAccountNumber,
        profile.vatPercentage
      );
    }
  };

  useEffect(() => {
    console.log("Password Strength: Weak");
    const strongRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\-]{8,}$/;
    if (strongRegex.test(password.newPassword)) {
      setPassStrength("Very Strong");
    } else if (
      /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}/.test(password.newPassword)
    ) {
      setPassStrength("Strong");
    } else if (password.newPassword !== "") {
      setPassStrength("Weak");
    }
  }, [password.newPassword]);

  return (
    <>
      {/* update UI start*/}
      <StyledToggleButtonGroup
        value={type}
        exclusive
        onChange={(e: any, value: any) => value !== null && setType(value)}
      >
        <StyledToggleButton
          disabled={loading}
          key={0}
          value={"Customer Profile"}
        >
          Customer Profile
        </StyledToggleButton>
        <StyledToggleButton disabled={loading} key={0} value={"Password"}>
          Password
        </StyledToggleButton>
      </StyledToggleButtonGroup>
      {type == "Customer Profile" && (
        <>
          <Grid container className={classes.container} direction="row">
            {/* profile */}
            <Box className={classes.innerContainer}>
              <Box className={classes.fieldTitle}>
                {/* <SignalPassengerIcon /> */}
                <PersonOutlineOutlined />
                <Typography className={`${classes.pageTitle}`}>
                  Profile Details
                </Typography>
              </Box>
              <Grid container direction="column" spacing={2}>
                {/* email */}
                <Grid item>
                  <TextField
                    placeholder="email"
                    variant="outlined"
                    onChange={(e) =>
                      setProfile({ ...profile, email: e.target.value })
                    }
                    fullWidth
                    value={profile.email}
                  />
                  {firstNameErr ? (
                    <FormHelperText sx={{ color: "red" }}>
                      This field is required
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </Grid>
                {/* first name */}
                <Grid item>
                  <TextField
                    placeholder="First Name"
                    variant="outlined"
                    onChange={(e) =>
                      setProfile({ ...profile, firstName: e.target.value })
                    }
                    fullWidth
                    value={profile.firstName}
                  />
                  {firstNameErr ? (
                    <FormHelperText sx={{ color: "red" }}>
                      This field is required
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </Grid>
                {/* last name */}
                <Grid item>
                  <TextField
                    placeholder="Last Name"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setProfile({ ...profile, lastName: e.target.value })
                    }
                    value={profile.lastName}
                  />
                  {lastNameErr ? (
                    <FormHelperText sx={{ color: "red" }}>
                      This field is required
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </Grid>
                {/* Contact Number */}
                <Grid item>
                  <TextField
                    placeholder="Contact Number"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setProfile({ ...profile, contact_number: e.target.value })
                    }
                    value={profile.contact_number}
                  />
                  {/* {lastNameErr ? (
                    <FormHelperText sx={{ color: "red" }}>
                      This field is required
                    </FormHelperText>
                  ) : (
                    <></>
                  )} */}
                </Grid>

                {/* Address */}
                <Grid item>
                  <TextField
                    placeholder="Address"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setProfile({ ...profile, address: e.target.value })
                    }
                    value={profile.address}
                  />
                  {/* {lastNameErr ? (
                    <FormHelperText sx={{ color: "red" }}>
                      This field is required
                    </FormHelperText>
                  ) : (
                    <></>
                  )} */}
                </Grid>

                <Grid container xl={12} item>
                  <Box style={{ width: "100%", display: "flex", gap: 24 }}>
                    <Box style={{ width: "100%" }}>
                      {/* City */}
                      <InputLabel id="select-city" style={{ marginBottom: 12 }}>
                        City
                      </InputLabel>
                      <Select
                        fullWidth
                        variant="outlined"
                        labelId="select-city"
                        id="select-city"
                        // label="City"
                        onChange={(e) =>
                          setProfile({ ...profile, city: e.target.value })
                        }
                        value={profile.city}
                      >
                        <MenuItem value={"North Holland Province"}>
                          North Holland Province
                        </MenuItem>
                      </Select>
                    </Box>
                    {/* State* */}
                    <Box style={{ width: "100%" }}>
                      <InputLabel
                        id="select-state"
                        style={{ marginBottom: 12 }}
                      >
                        State
                      </InputLabel>
                      <Select
                        fullWidth
                        variant="outlined"
                        labelId="select-state"
                        id="select-state"
                        placeholder="State"
                        onChange={(e) =>
                          setProfile({ ...profile, state: e.target.value })
                        }
                        value={profile.state}
                      >
                        <MenuItem value={"North Holland Province"}>
                          North Holland Province
                        </MenuItem>
                      </Select>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* company */}
            <Box className={classes.innerContainer} style={{ marginTop: 25 }}>
              <Box className={classes.fieldTitle}>
                <OfficeIcon style={{ width: 30, height: 30 }} />
                <Typography className={`${classes.pageTitle}`}>
                  Company Details
                </Typography>
              </Box>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <TextField
                    placeholder="Company Name"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setProfile({ ...profile, companyName: e.target.value })
                    }
                    value={profile.companyName}
                  />
                  {companyNameErr ? (
                    <FormHelperText sx={{ color: "red" }}>
                      This field is required
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item>
                  <TextField
                    placeholder="Company Address"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setProfile({ ...profile, companyAddress: e.target.value })
                    }
                    value={profile.companyAddress}
                  />
                  {companyAddressErr ? (
                    <FormHelperText sx={{ color: "red" }}>
                      This field is required
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item>
                  <TextField
                    placeholder="Company Postal Code"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setProfile({
                        ...profile,
                        companyPostCode: e.target.value,
                      })
                    }
                    value={profile.companyPostCode}
                  />
                  {companyPostCodeErr ? (
                    <FormHelperText sx={{ color: "red" }}>
                      This field is required
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item>
                  <TextField
                    placeholder="Company City"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setProfile({ ...profile, companyCity: e.target.value })
                    }
                    value={profile.companyCity}
                  />
                  {companyCityErr ? (
                    <FormHelperText sx={{ color: "red" }}>
                      This field is required
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item>
                  <TextField
                    placeholder="Company Registration Number"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setProfile({
                        ...profile,
                        companyRegistrationNumber: e.target.value,
                      })
                    }
                    value={profile.companyRegistrationNumber}
                  />
                  {companyRegistrationNumberErr ? (
                    <FormHelperText sx={{ color: "red" }}>
                      This field is required
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item>
                  <TextField
                    placeholder="Company VAT Number"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setProfile({
                        ...profile,
                        companyVATNumber: e.target.value,
                      })
                    }
                    value={profile.companyVATNumber}
                  />
                  {companyVATNumberErr ? (
                    <FormHelperText sx={{ color: "red" }}>
                      This field is required
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item>
                  <TextField
                    placeholder="Bank Account Number"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setProfile({
                        ...profile,
                        bankAccountNumber: e.target.value,
                      })
                    }
                    value={profile.bankAccountNumber}
                  />
                  {bankAccountNumberErr ? (
                    <FormHelperText sx={{ color: "red" }}>
                      This field is required
                    </FormHelperText>
                  ) : (
                    <>
                      {" "}
                      {!bankAccNumberRegErr && (
                        <FormHelperText sx={{ color: "red" }}>
                          Invalid Bank Account Number.
                        </FormHelperText>
                      )}{" "}
                    </>
                  )}
                </Grid>
                <Grid item>
                  <TextField
                    placeholder="Bank Account Holder Name"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setProfile({
                        ...profile,
                        bankAccountHolderName: e.target.value,
                      })
                    }
                    value={profile.bankAccountHolderName}
                  />
                  {bankAccountHolderNameErr ? (
                    <FormHelperText sx={{ color: "red" }}>
                      This field is required
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item>
                  <TextField
                    placeholder="VAT Percentage*"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      setProfile({ ...profile, vatPercentage: e.target.value })
                    }
                    value={profile.vatPercentage}
                  />
                  {!vatPercentageErr ? (
                    <FormHelperText sx={{ color: "red" }}>
                      This field is required
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Box>
            {/*  */}
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 24,
                width: "100%",
              }}
            >
              <MOButton
                onClick={() => {
                  handleUpload();
                }}
                icon={false}
                title="Update"
                // loading={loading}
              />
            </Box>
          </Grid>
        </>
      )}
      {type == "Password" && (
        <>
          <Box className={classes.passwordOuterContainer}>
            <Grid
              container
              className={classes.passwordContainer}
              direction="column"
              spacing={4}
            >
              <Typography
                className={`${classes.pageTitle} ${classes.topWidth}`}
                style={{ marginLeft: 0 }}
              >
                Password Change
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#5A657C",
                  marginBottom: 20,
                }}
              >
                Your New Password Must Be Different From Previous Used
                Passwords.
              </Typography>
              <Grid item xs={6}>
                {/* <InputLabel
                  htmlFor="current-password"
                  style={{ fontSize: "0.8rem" }}
                >
                  Current Password
                </InputLabel> */}
                <Input
                  id="current-password"
                  placeholder="Current Password"
                  fullWidth
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      currentPassword: e.target.value,
                    })
                  }
                  type={showCurrentPassword ? "text" : "password"}
                  value={password.currentPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password"
                        onClick={() => {
                          setCurrentShowPassword(!showCurrentPassword);
                        }}
                      >
                        {!showCurrentPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                {/* <InputLabel
                  htmlFor="new-password"
                  style={{ fontSize: "0.8rem" }}
                >
                  Change Password
                </InputLabel> */}
                <Typography
                  style={{ fontSize: 16, fontWeight: 500, marginBottom: 8 }}
                >
                  Change Password
                </Typography>
                <Input
                  placeholder="New Password"
                  id="new-password"
                  fullWidth
                  onChange={(e) =>
                    setPassword({ ...password, newPassword: e.target.value })
                  }
                  type={showPassword ? "text" : "password"}
                  value={password.newPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {!showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {passStrength && (
                  <Typography
                    style={{ fontSize: 14, fontWeight: 600, marginTop: 6 }}
                  >
                    Password Strength:{" "}
                    <span style={{ fontWeight: 400 }}>{passStrength}</span>
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                {/* <InputLabel
                  htmlFor="new-password"
                  style={{ fontSize: "0.8rem" }}
                >
                  Confirm New Password
                </InputLabel> */}
                <Input
                  id="new-password"
                  placeholder="Confirm Password"
                  fullWidth
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      confNewPassword: e.target.value,
                    })
                  }
                  type={showConfPassword ? "text" : "password"}
                  value={password.confNewPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password"
                        onClick={() => {
                          setShowConfPassword(!showConfPassword);
                        }}
                      >
                        {!showConfPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>

              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                style={{ marginTop: 100 }}
              >
                <Grid item>
                  <MOButton type="secondary" icon={false} title="Cancel" />
                </Grid>
                <Grid item>
                  <MOButton
                    onClick={() => {
                      if (passStrength != "Very Strong") {
                        resetPassError(
                          "Password Weak",
                          "Password should have a number, alphabet and special character with minimum length of 8 digits"
                        );
                      } else if (
                        password.newPassword === password.confNewPassword
                      ) {
                        changePassword({
                          password: password.currentPassword,
                          newPassword: password.newPassword,
                        });
                        setPassword({
                          currentPassword: "",
                          newPassword: "",
                          confNewPassword: "",
                        });
                      } else {
                        resetPassError(
                          "Password Mismatch,",
                          "Password Did Not Match"
                        );
                      }
                    }}
                    // size="large"
                    icon={false}
                    title="Change Password"
                    // loading={loading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      {/* update UI end */}
      {/* <Grid container spacing={6} className={classes.container} direction="row">
        <Grid item container direction="column" xs={6} spacing={4}>
          <Typography
            variant="h2"
            className={`${classes.pageTitle} ${classes.topWidth}`}
          >
            Profile
          </Typography>
          <Grid item>
            <TextField
              label="First Name"
              onChange={(e) =>
                setProfile({ ...profile, firstName: e.target.value })
              }
              fullWidth
              value={profile.firstName}
            />
            {firstNameErr ? (
              <FormHelperText sx={{ color: "red" }}>
                This field is required
              </FormHelperText>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <TextField
              label="Last Name"
              fullWidth
              onChange={(e) =>
                setProfile({ ...profile, lastName: e.target.value })
              }
              value={profile.lastName}
            />
            {lastNameErr ? (
              <FormHelperText sx={{ color: "red" }}>
                This field is required
              </FormHelperText>
            ) : (
              <></>
            )}
          </Grid>

          <Grid item>
            <TextField
              label="Company Name"
              fullWidth
              onChange={(e) =>
                setProfile({ ...profile, companyName: e.target.value })
              }
              value={profile.companyName}
            />
            {companyNameErr ? (
              <FormHelperText sx={{ color: "red" }}>
                This field is required
              </FormHelperText>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <TextField
              label="Company Address"
              fullWidth
              onChange={(e) =>
                setProfile({ ...profile, companyAddress: e.target.value })
              }
              value={profile.companyAddress}
            />
            {companyAddressErr ? (
              <FormHelperText sx={{ color: "red" }}>
                This field is required
              </FormHelperText>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <TextField
              label="Company Postal Code"
              fullWidth
              onChange={(e) =>
                setProfile({ ...profile, companyPostCode: e.target.value })
              }
              value={profile.companyPostCode}
            />
            {companyPostCodeErr ? (
              <FormHelperText sx={{ color: "red" }}>
                This field is required
              </FormHelperText>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <TextField
              label="Company City"
              fullWidth
              onChange={(e) =>
                setProfile({ ...profile, companyCity: e.target.value })
              }
              value={profile.companyCity}
            />
            {companyCityErr ? (
              <FormHelperText sx={{ color: "red" }}>
                This field is required
              </FormHelperText>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <TextField
              label="Company Registration Number"
              fullWidth
              onChange={(e) =>
                setProfile({
                  ...profile,
                  companyRegistrationNumber: e.target.value,
                })
              }
              value={profile.companyRegistrationNumber}
            />
            {companyRegistrationNumberErr ? (
              <FormHelperText sx={{ color: "red" }}>
                This field is required
              </FormHelperText>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <TextField
              label="Company VAT Number"
              fullWidth
              onChange={(e) =>
                setProfile({ ...profile, companyVATNumber: e.target.value })
              }
              value={profile.companyVATNumber}
            />
            {companyVATNumberErr ? (
              <FormHelperText sx={{ color: "red" }}>
                This field is required
              </FormHelperText>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <TextField
              label="Bank Account Number"
              fullWidth
              onChange={(e) =>
                setProfile({ ...profile, bankAccountNumber: e.target.value })
              }
              value={profile.bankAccountNumber}
            />
            {bankAccountNumberErr ? (
              <FormHelperText sx={{ color: "red" }}>
                This field is required
              </FormHelperText>
            ) : (
              <>
                {" "}
                {!bankAccNumberRegErr && (
                  <FormHelperText sx={{ color: "red" }}>
                    Invalid Bank Account Number.
                  </FormHelperText>
                )}{" "}
              </>
            )}
          </Grid>
          <Grid item>
            <TextField
              label="Bank Account Holder Name"
              fullWidth
              onChange={(e) =>
                setProfile({
                  ...profile,
                  bankAccountHolderName: e.target.value,
                })
              }
              value={profile.bankAccountHolderName}
            />
            {bankAccountHolderNameErr ? (
              <FormHelperText sx={{ color: "red" }}>
                This field is required
              </FormHelperText>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <TextField
              label="VAT Percentage*"
              fullWidth
              onChange={(e) =>
                setProfile({ ...profile, vatPercentage: e.target.value })
              }
              value={profile.vatPercentage}
            />
            {!vatPercentageErr ? (
              <FormHelperText sx={{ color: "red" }}>
                This field is required
              </FormHelperText>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <Pressable
              onClick={() => {
                handleUpload();
              }}
              size="large"
              title="Update"
              loading={loading}
            />
          </Grid>
        </Grid>
        <Grid item container direction="column" xs={6} spacing={4}>
          <Typography
            variant="h2"
            className={`${classes.pageTitle} ${classes.topWidth}`}
          >
            Change Password
          </Typography>
          <Grid item>
            <InputLabel
              htmlFor="current-password"
              style={{ fontSize: "0.8rem" }}
            >
              Current Password
            </InputLabel>
            <Input
              id="current-password"
              fullWidth
              onChange={(e) =>
                setPassword({ ...password, currentPassword: e.target.value })
              }
              type={showCurrentPassword ? "text" : "password"}
              value={password.currentPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password"
                    onClick={() => {
                      setCurrentShowPassword(!showCurrentPassword);
                    }}
                  >
                    {!showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item>
            <InputLabel htmlFor="new-password" style={{ fontSize: "0.8rem" }}>
              Change Password
            </InputLabel>
            <Input
              id="new-password"
              fullWidth
              onChange={(e) =>
                setPassword({ ...password, newPassword: e.target.value })
              }
              type={showPassword ? "text" : "password"}
              value={password.newPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {!showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item>
            <InputLabel htmlFor="new-password" style={{ fontSize: "0.8rem" }}>
              Confirm New Password
            </InputLabel>
            <Input
              id="new-password"
              fullWidth
              onChange={(e) =>
                setPassword({ ...password, confNewPassword: e.target.value })
              }
              type={showConfPassword ? "text" : "password"}
              value={password.confNewPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password"
                    onClick={() => {
                      setShowConfPassword(!showConfPassword);
                    }}
                  >
                    {!showConfPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item>
            <Pressable
              onClick={() => {
                if (password.newPassword === password.confNewPassword) {
                  changePassword({
                    password: password.currentPassword,
                    newPassword: password.newPassword,
                  });
                } else {
                  resetPassError(
                    "Password Mismatch,",
                    "Password Did Not Match"
                  );
                }
              }}
              size="large"
              title="Change Password"
              // loading={loading}
            />
          </Grid>
        </Grid>
      </Grid> */}

      {success && (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"Profile Updated"}
        />
      )}
      {error && (
        <ErrorSnack
          reset={() => reset()}
          errorMsg={"Unable to update profile"}
        />
      )}
      {profileSuccess && (
        <ErrorSnack
          reset={() => resetPass()}
          success
          errorMsg={"Password Updated"}
        />
      )}
      {profileError && (
        <ErrorSnack reset={() => resetPass()} errorMsg={profileErrormsg} />
      )}
    </>
  );
}

const mapState = (state: any) => ({
  user: state.userInfo.user,
  success: state.profile.response.status,
  error: state.profile.error.status,
  profileError: state.profilePassword.error.status,
  profileErrormsg: state.profilePassword.error.message,
  profileSuccess: state.profilePassword.response.status,
});
const mapDispatch = {
  updateUser: ThunksLogin.updateCustomerUser,
  changePassword: ThunksLogin.changeCustomerPassword,
  reset: Profile.api.reset,
  resetPass: ProfilePassword.api.reset,
  resetPassError: ProfilePassword.api.error,
};

export default connect(mapState, mapDispatch)(CustomerProfile);
