import React from "react";
import Dashboard from "./tabs/Dashboard";
import RequestAnalysis from "./tabs/RequestAnalysis";
import OperationalEfficiency from "./tabs/OperationalEfficiency";
import FinancialPerformance from "./tabs/FinancialPerformance";
import GeographicalAnalysis from "./tabs/GeographicalAnalysis";

const RenderAnalysisScreen = ({ type }) => {
  switch (type) {
    case "dashboard":
      return <Dashboard />;
    case "request_analysis":
      return <RequestAnalysis />;
    case "operational_efficiency":
      return <OperationalEfficiency />;
    case "financial_performance":
      return <FinancialPerformance />;
    case "geographical_analysis":
      return <GeographicalAnalysis />;
    default:
      return <></>;
  }
};

export default RenderAnalysisScreen;
