export type LoginStates = {
  displayName: string;
  password: string;
};

const loginStates: LoginStates = {
  displayName: "",
  password: "",
};

export default loginStates;
