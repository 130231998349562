// v1
// import React from "react";
// import { Bar } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   Title,
//   Tooltip,
//   Legend,
//   BarElement,
//   CategoryScale,
//   LinearScale,
// } from "chart.js";

// // Registering components with ChartJS
// ChartJS.register(
//   Title,
//   Tooltip,
//   Legend,
//   BarElement,
//   CategoryScale,
//   LinearScale
// );

// // Service colors
// const colors = {
//   Standard: "#0059C8",
//   Luxury: "#E2A03F",
//   Executive: "#058E6E",
//   Executive_People_Carrier: "#BD6B00",
//   Large_People_Carrier: "#000000",
//   People_Carrier: "#AD1519",
//   Minibus: "#737373",
//   Electric_Luxury: "#2CA9DD",
// };

// // Car services
// const services = Object.keys(colors);

// // Generate random data
// const getRandomData = () => {
//   return services.reduce((acc, service) => {
//     acc[service] = Math.floor(Math.random() * 100) + 1; // Random percentage between 1 and 100
//     return acc;
//   }, {});
// };

// // Cities and their services
// const cities = ["Heumen", "Gennep", "Berkelland", "Amsterdam", "Alkmaar"];

// const generateCityData = () => {
//   return cities.reduce((acc, city) => {
//     const numberOfServices =
//       Math.floor(Math.random() * (services.length - 1)) + 1; // Random number of services (1 to max services)
//     const selectedServices = services
//       .sort(() => 0.5 - Math.random())
//       .slice(0, numberOfServices);
//     acc[city] = selectedServices.reduce((obj, service) => {
//       obj[service] = getRandomData()[service];
//       return obj;
//     }, {});
//     return acc;
//   }, {});
// };

// const ChartComponent = () => {
//   const cityData = generateCityData();

//   const chartData = {
//     labels: cities,
//     datasets: services.map((service) => ({
//       label: service,
//       data: cities.map((city) => cityData[city][service] || 0),
//       backgroundColor: colors[service],
//       stack: "Stack 0",
//     })),
//   };

//   const options = {
//     indexAxis: "y",
//     responsive: true,
//     plugins: {
//       legend: {
//         position: "right",
//       },
//       tooltip: {
//         callbacks: {
//           label: function (context) {
//             const city = context.label;
//             const service = context.dataset.label;
//             const value = context.raw;
//             return ${city}: ${service} (${value}%);
//           },
//         },
//       },
//     },
//     scales: {
//       x: {
//         beginAtZero: true,
//         stacked: true,
//       },
//       y: {
//         stacked: true,
//       },
//     },
//   };

//   return (
//     <div>
//       <Bar data={chartData} options={options} />
//     </div>
//   );
// };

// export default ChartComponent;

// v2
import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

// Registering components with ChartJS
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

// Service colors
const colors = {
  Standard: "#0059C8",
  Luxury: "#E2A03F",
  Executive: "#058E6E",
  Executive_People_Carrier: "#BD6B00",
  Large_People_Carrier: "#000000",
  People_Carrier: "#AD1519",
  Minibus: "#737373",
  Electric_Luxury: "#2CA9DD",
};

// Car services
const services = Object.keys(colors);

// Generate random data
const getRandomData = () => {
  return services.reduce((acc, service) => {
    acc[service] = Math.floor(Math.random() * 100) + 1; // Random percentage between 1 and 100
    return acc;
  }, {});
};

// Cities and their services
const cities = ["Heumen", "Gennep", "Berkelland", "Amsterdam", "Alkmaar"];

const generateCityData = () => {
  return cities.reduce((acc, city) => {
    const numberOfServices =
      Math.floor(Math.random() * (services.length - 1)) + 1; // Random number of services (1 to max services)
    const selectedServices = services
      .sort(() => 0.5 - Math.random())
      .slice(0, numberOfServices);
    acc[city] = selectedServices.reduce((obj, service) => {
      obj[service] = getRandomData()[service];
      return obj;
    }, {});
    return acc;
  }, {});
};

const ChartComponent = () => {
  const cityData = generateCityData();

  // Prepare datasets for chart
  const datasets = services.map((service) => ({
    label: service,
    data: cities.map((city) => cityData[city][service] || 0),
    backgroundColor: colors[service],
    stack: "Stack 0", // Stack by city
  }));

  const chartData = {
    labels: cities,
    datasets,
  };

  const options = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const city = context.label;
            const service = context.dataset.label;
            const value = context.raw;
            return `${city}: ${service} (${value}%)`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <div>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default ChartComponent;
