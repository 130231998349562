import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
function BasicDateTimePicker({ date, onChange, disablePast = false }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        value={date}
        inputVariant="outlined"
        disablePast={disablePast}
        onChange={onChange}
        label={""}
        showTodayButton
        format="yyyy-MM-dd | HH:mm"
        keyboardIcon={<ExpandMoreIcon />}
      />
    </MuiPickersUtilsProvider>
  );
}

export default BasicDateTimePicker;
