import { ErrorMessage, Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import ThunksBooking from "../../../../redux/actions/thunks/bookings";
import { apiKeyValidationSchema } from "../../../../validations/apiKeyValidation";
import { toastMessage } from "../../../../helpers/toastMessage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const GetBookingForm = ({
  integrationType,
  bookingIntegration,
  companyId,
  integrationTypeData,
  setIntegrationTypeData = () => {},
  manageUrl,
}) => {
  const navigate = useNavigate();
  const [editAllow, setEditAllow] = useState(
    integrationTypeData?.connectionStatus
  );
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      setSubmitting(true);
      bookingIntegration(companyId, {
        type: integrationType,
        clientId: values.clientId,
        clientSecret: values.clientSecret,
      });
      setIntegrationTypeData({
        ...integrationTypeData,
        connectionStatus: true,
        id: integrationTypeData?.value,
      });
    } catch (error) {
      toastMessage("error", error?.response.data.msg);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (integrationTypeData) {
      setEditAllow(integrationTypeData?.connectionStatus);
    }
  }, [integrationTypeData]);
  return (
    <Formik
      initialValues={{
        clientId: "",
        clientSecret: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={apiKeyValidationSchema}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <div>
            <div className="">
              <div className="input_field width-full ">
                <Field
                  type="text"
                  name="clientId"
                  placeholder="Client ID *"
                  disabled={editAllow}
                  style={{
                    textIndent: "14px",
                    height: "60px",
                    backgroundColor: editAllow && "#F3F3F3",
                    border: editAllow && "none",
                  }}
                />
                <ErrorMessage
                  name="clientId"
                  component="div"
                  className="formik-error-message"
                />
              </div>
              <div className="input_field width-full mt-24">
                <Field
                  type="text"
                  name="clientSecret"
                  placeholder="Client Secret *"
                  disabled={editAllow}
                  style={{
                    textIndent: "14px",
                    height: "60px",
                    backgroundColor: editAllow && "#F3F3F3",
                    border: editAllow && "none",
                  }}
                />
                <ErrorMessage
                  name="clientSecret"
                  component="div"
                  className="formik-error-message"
                />
              </div>
            </div>
            {integrationTypeData?.connectionStatus ? (
              <>
                <div className="mt-40 flex justify-content-between">
                  {/* <MOButtonV2
                    text="EDIT CREDENTIALS"
                    textColor="#595959"
                    borderRadius={8}
                    backgroundColor="transparent"
                    height={50}
                    radius={4}
                    widthSize={"22%"}
                    disabled={isSubmitting}
                    type="button"
                    fontSize={11}
                    border="1.5px solid #595959"
                    onClick={() => {
                      setEditAllow(!editAllow);
                    }}
                  /> */}
                  <MOButtonV2
                    text="MANAGE INTEGRATION"
                    textColor="#ffffff"
                    borderRadius={8}
                    backgroundColor="#29AEE6"
                    height={50}
                    radius={4}
                    widthSize={"100%"}
                    disabled={isSubmitting}
                    type="button"
                    fontSize={11}
                    onClick={() => {
                      navigate(manageUrl);
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="mt-40 flex justify-content-center">
                  <MOButtonV2
                    text="SAVE SETTINGS"
                    textColor="#ffffff"
                    borderRadius={8}
                    backgroundColor="#29AEE6"
                    height={50}
                    radius={4}
                    widthSize={"100%"}
                    disabled={isSubmitting}
                    type="submit"
                    fontSize={11}
                  />
                </div>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapState = (state) => ({
  companyId: state.userInfo.user.id,
});

const mapDispatch = {
  bookingIntegration: ThunksBooking.BookingIntegration,
};

export default connect(mapState, mapDispatch)(GetBookingForm);
