export const PASSENGERS_API = {
    SET_REQUEST: "ACTION.PASSENGERS.SET_REQUEST",
    SET_RESPONSE: "ACTION.PASSENGERS.SET_RESPONSE",
    SET_ERROR: "ACTION.PASSENGERS.SET_ERROR",
    SET_RETRY: "ACTION.PASSENGERS.SET_RETRY",
  };
  
  const PASSENGERS = {
    RESET_ALL: "ACTION.LOCAL.PASSENGERS.RESET_ALL",
    PASSENGERS_API,
  };
  
  export default PASSENGERS;
  