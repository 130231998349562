import React, { useEffect } from "react";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import ArrowRight from "@material-ui/icons/ArrowRight";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import DetailTable from "./DetailPanel";
import ThunksVehiclesListing from "../../../redux/actions/thunks/vehicles-listing";
import { connect } from "react-redux";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import ErrorSnack from "../../../components/commons/ErrorSnack";

function Listing({
  getVehiclesListing,
  response,
  loading,
  deleteBrand,
  error,
}) {
  useEffect(() => {
    getVehiclesListing();
  }, []);
  useEffect(() => {
    if (loading) getVehiclesListing();
  }, [loading]);
  return (
    <>
      <MaterialTable
        icons={tableIcons}
        isLoading={loading}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        columns={[
          { title: "Make", field: "brandName" },
          { title: "ID", field: "id" },
        ]}
        data={response}
        actions={[
          {
            icon: DeleteOutline,
            tooltip: "Delete Vehicle Brand",
            onClick: (event, rowData) => deleteBrand(rowData.id),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Vehicles"
        detailPanel={[
          {
            icon: ArrowRight,
            tooltip: "Show Details",
            render: (rowData) => {
              return <DetailTable rowData={rowData} />;
            },
          },
        ]}
      />
      {error.status && <ErrorSnack errorMsg={error.message} />}
    </>
  );
}

const mapState = (state) => ({
  response: state.vehiclesListing.response.data,
  loading: state.vehiclesListing.loading,
  error: state.vehiclesListing.error,
});

const mapDispatch = {
  getVehiclesListing: ThunksVehiclesListing.VehiclesListing,
  deleteBrand: ThunksVehiclesListing.DeleteBrand,
};

export default connect(mapState, mapDispatch)(Listing);
