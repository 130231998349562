import React, { useEffect, useRef, useState } from "react";
import { BookingMenuIcon } from "../../assets/icons";
import { MoreHoriz } from "@material-ui/icons";
import ToolTipMessage from "./ToolTipMessage";
import ExclamationIcon from "../../assets/icons/manage_heroicons_exclaimation-circle.svg";
const DropDown = ({
  IconRender,
  menu = [],
  uniqueId = "",
  dropDownOpen = false,
  setDropDownOpen = () => {},
  dropDownShape = "vertical",
}) => {
  //   const {
  //     IconRender,
  //     menu = [],
  //     uniqueId = "",
  //     dropDownOpen = false,
  //     setDropDownOpen = () => {},
  //   } = props;
  //   menu will look like this pattern menu :[{onClick:()=>{},IconRender,title}]
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropDownOpen(null);
      }
    };

    if (dropDownOpen === uniqueId) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropDownOpen, uniqueId, setDropDownOpen]);

  return (
    <div ref={dropdownRef} className="dropdown-menu-section">
      <button
        onClick={() => {
          if (dropDownOpen === uniqueId) {
            setDropDownOpen(null);
          } else {
            setDropDownOpen(uniqueId);
          }
          // setIsDropDownOpen(true)
        }}
        className={`${
          dropDownShape === "horizontal" && "dropdown-menu-horizontal"
        }`}
      >
        {/* <BookingMenuIcon /> */}
        {typeof IconRender === "function" ? IconRender() : <MoreHoriz />}
      </button>
      <div
        className={`${
          uniqueId === dropDownOpen
            ? "dropdown-menu-active"
            : "dropdown-menu-hide"
        } dropdown-menu-list`}
      >
        {/* <div className="square-shape"></div> */}
        {menu?.map((item) => {
          return (
            <>
              <div className="tooltip-section">
                <button
                  className={
                    item?.disabled ? (item.disabled ? "disable-btn" : "") : ""
                  }
                  disabled={item.disabled ? item.disabled : false}
                  onClick={() => {
                    item?.onClick();
                    setDropDownOpen("");
                  }}
                >
                  <p className="m-0" style={{ marginRight: "10px" }}>
                    {typeof item.IconRender === "function" && item.IconRender()}{" "}
                  </p>
                  <p className="m-0">{item.title}</p>
                </button>
                {item?.tooltipStatus && (
                  <ToolTipMessage
                    type="primary"
                    content={
                      <>
                        <p className="font-12 font-500">
                          {item?.tooltipMessage}
                        </p>
                      </>
                    }
                  >
                    <div className="tab-selection-tooltip-icon">
                      <div>
                        <img
                          src={ExclamationIcon}
                          alt="ExclamationIcon"
                          style={{
                            cursor: "pointer",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </div>
                    </div>
                  </ToolTipMessage>
                )}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default DropDown;
