import React from 'react'

const ChatIcon = ({color}) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.744 3.89125C16.2746 3.89125 19.894 7.5107 19.894 12.0413C19.894 13.0405 19.712 14.0326 19.3548 14.9256C19.2718 15.1331 19.2739 15.3651 19.3607 15.5711L20.4639 18.1913H17.344C17.151 18.1913 16.9638 18.2569 16.8131 18.3775C15.4488 19.4689 13.6327 20.1913 11.744 20.1913C7.21348 20.1913 3.59404 16.5718 3.59404 12.0413C3.59404 7.5107 7.21348 3.89125 11.744 3.89125ZM21.594 12.0413C21.594 6.57181 17.2135 2.19125 11.744 2.19125C6.2746 2.19125 1.89404 6.57181 1.89404 12.0413C1.89404 17.5107 6.2746 21.8913 11.744 21.8913C13.9439 21.8913 16.0284 21.0962 17.6346 19.8913H21.4428C22.1938 19.8913 22.702 19.1259 22.4105 18.4338L21.0588 15.2234C21.4173 14.2095 21.594 13.1225 21.594 12.0413ZM7.74406 11.3913C7.10893 11.3913 6.59406 11.9061 6.59406 12.5413C6.59406 13.1764 7.10893 13.6913 7.74406 13.6913C8.37919 13.6913 8.89406 13.1764 8.89406 12.5413C8.89406 11.9061 8.37919 11.3913 7.74406 11.3913ZM11.7441 11.3913C11.1089 11.3913 10.5941 11.9061 10.5941 12.5413C10.5941 13.1764 11.1089 13.6913 11.7441 13.6913C12.3792 13.6913 12.8941 13.1764 12.8941 12.5413C12.8941 11.9061 12.3792 11.3913 11.7441 11.3913ZM15.7441 11.3913C15.1089 11.3913 14.5941 11.9061 14.5941 12.5413C14.5941 13.1764 15.1089 13.6913 15.7441 13.6913C16.3792 13.6913 16.8941 13.1764 16.8941 12.5413C16.8941 11.9061 16.3792 11.3913 15.7441 11.3913Z" fill={color}/>
    </svg>
    
  )
}

export default ChatIcon