import { useSearchParams } from 'react-router-dom';
import BookingIntegrationApiKey from "./BookingIntegrationApiKey";
import WorldTransferApiKey from "./WorldTransferApiKey";

const ApiKey = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get("integration")
  return (
    <div>
      {value == 'booking.com' ?
        <BookingIntegrationApiKey />
        : <WorldTransferApiKey />}
    </div>
  );
};

export default ApiKey;
