import React from "react";
import DoughnutChart from "../../../../components/commons/DoughnutChart";
import HorizontalMultipleBarChart from "../../../../components/commons/HorizontalMultipleBarChart";

const RequestAnalysis = () => {
  return (
    <div>
      <h1>RequestAnalysis</h1>
      <div className="bg-white">
        {/* <DoughnutChart />
        <HorizontalMultipleBarChart /> */}
      </div>
    </div>
  );
};

export default RequestAnalysis;
