import { Requests } from "../../../../services";

export type ThunkSettingsRepo = {
    createSetting: (error: any) => any;
    updateTermsAndConditions:(data:any) => any;
    getLatestTermsAndConditions:(data:any) => any;
};

const ThunkSettings: ThunkSettingsRepo = {
    createSetting: (data) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      console.log(token);
      
      const api: Requests = mobioApi(token);
  
      return api
        .createSetting(data)
        .then((response: any) => {
            alert("Updated successfully.");

        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
                alert("The API request timed out. Please Refresh the page")
            );
          } else {
              console.log(error)
            let errorMsg = error;
            if (error?.response?.data?.msg) {
              errorMsg = error?.response?.data?.msg;
            }
            alert(errorMsg);
          }
        });
    
  },
    updateTermsAndConditions: (data) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      
      const api: Requests = mobioApi(token);

      return api
        .updateTermsAndConditions({ "data":data.bodyText})
        .then((response: any) => {
            return response.data

        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
                alert("The API request timed out. Please Refresh the page")
            );
          } else {
              console.log(error)
            let errorMsg = error;
            if (error?.response?.data?.msg) {
              errorMsg = error?.response?.data?.msg;
            }
            alert(errorMsg);
          }
        });
    
  },
  getLatestTermsAndConditions: () => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    
    const api: Requests = mobioApi(token);
    return api
      .getLatestTermsAndConditions()
      .then((response: any) => {
          return response.data
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
              alert("The API request timed out. Please Refresh the page")
          );
        } else {
            console.log(error)
          let errorMsg = error;
          if (error?.response?.data?.msg) {
            errorMsg = error?.response?.data?.msg;
          }
          alert(errorMsg);
        }
      });
  
},
};

export default ThunkSettings;
