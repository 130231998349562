export const CUSTOMERS_API = {
  SET_REQUEST: "ACTION.CUSTOMERS.SET_REQUEST",
  SET_RESPONSE: "ACTION.CUSTOMERS.SET_RESPONSE",
  SET_ERROR: "ACTION.CUSTOMERS.SET_ERROR",
  SET_RETRY: "ACTION.CUSTOMERS.SET_RETRY",
};

export const ADD_CUSTOMER_API = {
  SET_RESPONSE: "ACTION.ADD_CUSTOMER.SET_RESPONSE",
  SET_ERROR: "ACTION.ADD_CUSTOMER.SET_ERROR",
  SET_RETRY: "ACTION.ADD_CUSTOMER.SET_RETRY",
  SET_REQUEST: "ACTION.ADD_CUSTOMER.SET_REQUEST",
  RESET: "ACTION.ADD_CUSTOMER.RESET",
};

export const CUSTOMER_DISPATCHER_API = {
  SET_RESPONSE: "ACTION.CUSTOMER_DISPATCHER.SET_RESPONSE",
  SET_ERROR: "ACTION.CUSTOMER_DISPATCHER.SET_ERROR",
  SET_RETRY: "ACTION.CUSTOMER_DISPATCHER.SET_RETRY",
  SET_REQUEST: "ACTION.CUSTOMER_DISPATCHER.SET_REQUEST",
};

const CUSTOMERS = {
  RESET_ALL: "ACTION.LOCAL.CUSTOMERS.RESET_ALL",
  FIELD: {
    UPDATE: "ACTION.LOCAL.CUSTOMERS.FIELD.UPDATE",
    CLEAR: "ACTION.LOCAL.CUSTOMERS.FIELD.CLEAR",
  },
  FLAGS: {
    TOGGLE_COMPLETE: "ACTION.LOCAL.CUSTOMERS.FLAGS.TOGGLE_COMPLETE",
    UPDATE_STEP: "ACTION.LOCAL.CUSTOMERS.FLAGS.UPDATE_STEP",
    RESET: "ACTION.LOCAL.CUSTOMERS.FLAGS.RESET",
  },
  CUSTOMERS_API,
};
const CUSTOMER_DISPATCHER = {
  CUSTOMER_DISPATCHER_API,
};

const ADD_CUSTOMER = {
  ADD_CUSTOMER_API,
};

export { CUSTOMERS, ADD_CUSTOMER, CUSTOMER_DISPATCHER };
