import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/BreadCrumb";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import { Add, FlightOutlined, VisibilityOutlined } from "@material-ui/icons";
import SearchingInput from "../../../components/commons/SearchingInput";
import DropDown from "../../../components/commons/DropDown";
import EditIcon from "../../../assets/icons/editUpdateIcon.png";
import SettingIcon from "../../../assets/icons/menu_icon_settings.svg";
import ManageIcon from "../../../assets/icons/menu_manage_icon.svg";
import DeleteIcon from "../../../assets/icons/menuDelete.png";
import HorizontalIcon from "../../../assets/icons/horizontalIcon.png";
import mobioApi from "../../../services";
import { connect } from "react-redux";
import { toastMessage } from "../../../helpers/toastMessage";
import MOSwitch from "../../../components/commons/MOSwitch";
import DeleteModal from "../../../components/commons/Modals/DeleteModal";
import { allAirports } from "../../../data/airports";
import {
  getTimeFormat24Hour,
  makeItHours,
  removeLast00,
} from "../../../helpers/getTimeFormat";

const Index = ({ token }) => {
  const navigate = useNavigate();
  const { integrationType, integrationId, country, countryId, city, id } = useParams();
  const [airportStatus, setAirportStatus] = useState("all");
  const [airportList, setAirportList] = useState([]);
  const [dampAirportList, setDampAirportList] = useState([]);
  const [anchorEl, setAnchorEl] = useState("");
  // const [deleteAirportModal, setDeleteAirportModal] = useState(false);
  // const [singleData, setSingleData] = useState("");

  const paths = [
    {
      name: `${city} - Airports`,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
  ];
  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: "/dashboard/integration/rates/city",
    },
  ];

  const getAirports = async () => {
    try {
      const airportList = allAirports?.filter(
        (af) => af.country === country && af.city === city
      );
      const api = mobioApi(token);
      const response = await api.getAirports(id);
      const apiData = response.data.data;
      const updateAirportList = airportList?.map((al) => {
        const matchAirport = apiData?.filter(
          (af) => af.name === al.name || af.iata === al.iata
        );
        if (matchAirport.length > 0) {
          al = matchAirport[0];
        }
        return al;
      });
      setAirportList(updateAirportList);
      setDampAirportList(updateAirportList);
    } catch (error) {
      setAirportList([]);
      setDampAirportList([]);
    }
  };

  useEffect(() => {
    getAirports();
  }, []);

  // const handleAirportFilter = (statusFilter) => {
  //   if (statusFilter === airportStatus) {
  //     setAirportStatus("all");
  //   } else {
  //     setAirportStatus(statusFilter);
  //   }
  // };

  // useEffect(() => {
  //   if (airportStatus === "active") {
  //     setAirportList(dampAirportList?.filter((ap) => ap.status === "1"));
  //   } else if (airportStatus === "inactive") {
  //     setAirportList(dampAirportList?.filter((ap) => ap.status === "0"));
  //   } else {
  //     setAirportList(dampAirportList);
  //   }
  // }, [airportStatus]);

  // const deleteAirportHandler = async (id) => {
  //   try {
  //     const api = mobioApi(token);
  //     const response = await api.deleteAirport(id);
  //     navigate(
  //       `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`
  //     );
  //     toastMessage("success", response?.data.msg);
  //     setDeleteAirportModal(false);
  //     setSingleData("");
  //   } catch (error) {
  //     toastMessage("error", error?.response?.data.msg);
  //   }
  // };

  const statusChange = async (data, status) => {
    try {
      const payload = {
        name: data?.name,
        IATA: data?.iata,
        latitude: data?.latitude,
        longitude: data?.longitude,
        centralZone: data?.centralZone,
        farthestCentralZone: data?.farthestCentralZone,
        leadTime: data?.leadTime,
        VAT: data?.VAT,
        cityId: id,
        isActive: status,
      };
      const api = mobioApi(token);
      await api.addNewAirport(payload);

      toastMessage("success", "Status has been updated");
    } catch (error) {
      toastMessage("error", error.response?.data?.msg);
    }
  };
  return (
    <div>
      <div className="mt-18 flex justify-content-between">
        <div>
          <Breadcrumb paths={paths} />
          <div className="breadcrumb-description">
            Use this area to manage the area your fleet operates from
          </div>
          <div className="mt-12 ml-6">
            <Breadcrumb paths={secondaryPaths} secondary={true} />
          </div>
        </div>
        <div className="flex">
          <div>
            <SearchingInput />
          </div>
        </div>
      </div>
      <div className="bg-white border-radius-8 mt-20">
        {/* <div className="city-airport-status-section">
          <button
            onClick={() => {
              handleAirportFilter("active");
            }}
            className={`city-airport-status-btn cursor-pointer mr-12 ${
              airportStatus === "active"
                ? "city-airport-status-btn-active"
                : "city-airport-status-btn-inactive"
            }`}
          >
            Active Airport
          </button>
          <button
            onClick={() => {
              handleAirportFilter("inactive");
            }}
            className={`city-airport-status-btn cursor-pointer ${
              airportStatus === "inactive"
                ? "city-airport-status-btn-active"
                : "city-airport-status-btn-inactive"
            }`}
          >
            Inactive Airport
          </button>
        </div> */}
        <div className="city-airport-table-list">
          <div className="mt-16">
            <table className="custom-table-v2">
              <thead>
                <tr>
                  <th>Airport</th>
                  <th>
                    IATA <br /> Code
                  </th>
                  <th>
                    Furthest <br /> distance
                  </th>
                  <th>
                    Fixed <br /> Routes
                  </th>
                  <th>
                    Service <br /> Levels
                  </th>
                  <th>
                    Lead Time <br /> (Hr)
                  </th>
                  <th>VAT %</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {airportList.map((row, index) => (
                  <tr key={index} className="custom-table-hover">
                    <td className="flex align-item-center">
                      <FlightOutlined
                        style={{ rotate: "60deg", marginRight: "8px" }}
                      />
                      <p className="m-0">{row.name}</p>
                    </td>
                    <td>{row?.iata}</td>
                    <td>{row?.farthestCentralZone || "--"}</td>
                    <td>{row?.associatedAirport?.length || "--"}</td>
                    <td>{row?.airportServiceLevels?.length || "--"}</td>
                    <td>
                      {/* {row?.leadTime ? removeLast00(row?.leadTime) : "--"} */}
                      {row?.leadTime || row?.leadTime === 0
                        ? makeItHours(row?.leadTime)
                        : "--"}
                    </td>
                    <td>{row?.VAT || "--"}</td>
                    <td className="">
                      {row?.id ? (
                        <>
                          <MOSwitch
                            isOnStatus={row.isActive === "0" ? false : true}
                            id={row.id}
                            setStatus={(value) => {
                              statusChange(row, value);
                            }}
                          />
                        </>
                      ) : (
                        "--"
                      )}
                    </td>

                    <td>
                      <DropDown
                        dropDownOpen={anchorEl}
                        setDropDownOpen={setAnchorEl}
                        uniqueId={row.id || row.iata}
                        IconRender={() => <img src={HorizontalIcon} />}
                        dropDownShape="horizontal"
                        // menu={
                        //   row?.id
                        //     ? [
                        //         {
                        //           onClick: () => {
                        //             navigate(
                        //               `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${id}/area-operation`,
                        //               {
                        //                 state: row,
                        //               }
                        //             );
                        //           },
                        //           IconRender: () => <img src={SettingIcon} />,
                        //           title: "AREA OF OPERATION",
                        //         },
                        //         {
                        //           onClick: () => {
                        //             navigate(
                        //               `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${id}/airport/${row?.iata}/${row?.id}/mange-location`
                        //             );
                        //           },
                        //           IconRender: () => <img src={ManageIcon} />,
                        //           title: "MANAGE",
                        //         },
                        //       ]
                        //     : [
                        //         {
                        //           onClick: () => {
                        //             navigate(
                        //               `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${id}/area-operation`,
                        //               {
                        //                 state: row,
                        //               }
                        //             );
                        //           },
                        //           IconRender: () => <img src={SettingIcon} />,
                        //           title: "AREA OF OPERATION",
                        //         },
                        //       ]
                        // }

                        menu={[
                          {
                            onClick: () => {
                              navigate(
                                `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${id}/area-operation`,
                                {
                                  state: row,
                                }
                              );
                            },
                            IconRender: () => <img src={SettingIcon} />,
                            title: "AREA OF OPERATION",
                          },
                          {
                            onClick: () => {
                              navigate(
                                `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${id}/airport/${row?.iata}/${row?.id}/mange-location`
                              );
                            },
                            IconRender: () => <img src={ManageIcon} />,
                            title: "MANAGE",
                            disabled: row?.id ? false : true,
                            tooltipStatus: row?.id ? false : true,
                            tooltipMessage:
                              "Please first specify the area of operation, then the options for additional features will become available.",
                          },
                        ]}
                      />
                      {/* 
                        // {
                          //   onClick: () => {
                          //     setDeleteAirportModal(true);
                          //     setSingleData(row);
                          //   },
                          //   IconRender: () => <img src={DeleteIcon} />,
                          //   title: "Delete",
                          // }, */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <DeleteModal
        modalOpen={deleteAirportModal}
        setModalOpen={setDeleteAirportModal}
        title="Delete Location"
        heading="Are You Sure?"
        description="Are you certain you want to delete this location?"
        apiCall={() => {
          deleteAirportHandler(singleData?.id);
        }}
      /> */}
    </div>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Index);
