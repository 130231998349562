const AUTH_URL_SHEET_API = {
  SET_REQUEST: "ACTION.AUTH_URL.SET_REQUEST",
  SET_RESPONSE: "ACTION.AUTH_URL.SET_RESPONSE",
  SET_ERROR: "ACTION.AUTH_URL.SET_ERROR",
};

const AUTH_URL = {
  AUTH_URL_SHEET_API,
};

const CREATE_SHEET_API = {
  SET_REQUEST: "ACTION.CREATE_SHEET.SET_REQUEST",
  SET_RESPONSE: "ACTION.CREATE_SHEET.SET_RESPONSE",
  SET_ERROR: "ACTION.CREATE_SHEET.SET_ERROR",
};

const CREATE_SHEET = {
  CREATE_SHEET_API,
};

const IMPORT_SHEET_API = {
  SET_REQUEST: "ACTION.IMPORT_SHEET.SET_REQUEST",
  SET_RESPONSE: "ACTION.IMPORT_SHEET.SET_RESPONSE",
  SET_ERROR: "ACTION.IMPORT_SHEET.SET_ERROR",
  SET_RETRY: "ACTION.IMPORT_SHEET.SET_RETRY",
  RESET: "ACTION.IMPORT_SHEET.RESET",
};

const IMPORT_SHEET = {
  IMPORT_SHEET_API,
};

const SHEET_API = {
  SET_REQUEST: "ACTION.SHEET.SET_REQUEST",
  SET_RESPONSE: "ACTION.SHEET.SET_RESPONSE",
  SET_ERROR: "ACTION.SHEET.SET_ERROR",
};

const SHEET = {
  SHEET_API,
};

export {
  AUTH_URL,
  CREATE_SHEET,
  IMPORT_SHEET,
  SHEET,
  IMPORT_SHEET_API
};
