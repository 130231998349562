import React, { useEffect, useState } from "react";

const ButtonToggle = ({
  btnList = [],
  activeToggle,
  setActiveToggle = () => {},
}) => {
  return (
    <div className="btn-toggle-section">
      <div className="toggle-manage-section">
        {btnList?.map((btn) => {
          return (
            <button
              key={btn}
              onClick={() => setActiveToggle(btn)}
              className={`${
                activeToggle === btn
                  ? "active-toggle-btn"
                  : "in-active-toggle-btn"
              }`}
            >
              {btn}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ButtonToggle;
