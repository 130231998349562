export const APPROVE_API = {
  SET_REQUEST: "ACTION.APPROVE.SET_REQUEST",
  SET_RESPONSE: "ACTION.APPROVE.SET_RESPONSE",
  SET_ERROR: "ACTION.APPROVE.SET_ERROR",
  SET_RETRY: "ACTION.APPROVE.SET_RETRY",
  RESET: "ACTION.APPROVE.RESET",
};

export const REJECT_API = {
  SET_REQUEST: "ACTION.REJECT.SET_REQUEST",
  SET_RESPONSE: "ACTION.REJECT.SET_RESPONSE",
  SET_ERROR: "ACTION.REJECT.SET_ERROR",
  SET_RETRY: "ACTION.REJECT.SET_RETRY",
  RESET: "ACTION.REJECT.RESET",
};

const REJECT = {
  RESET: "ACTION.LOCAL.REJECT.RESET",
  RESET_ALL: "ACTION.LOCAL.REJECT.RESET_ALL",
  FIELD: {
    UPDATE: "ACTION.LOCAL.REJECT.FIELD.UPDATE",
    CLEAR: "ACTION.LOCAL.REJECT.FIELD.CLEAR",
  },
  FLAGS: {
    TOGGLE_COMPLETE: "ACTION.LOCAL.REJECT.FLAGS.TOGGLE_COMPLETE",
    UPDATE_STEP: "ACTION.LOCAL.REJECT.FLAGS.UPDATE_STEP",
    RESET: "ACTION.LOCAL.REJECT.FLAGS.RESET",
  },
  REJECT_API,
};

export { REJECT };
