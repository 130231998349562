import React from "react";
import LineChart from "../../../../components/commons/LineChart";
import HeaderDescription from "../../../../components/commons/HeaderDescription";

const ConversionRate = () => {
  const dataArray = [17, 18, 19, 20, 21, 21.5, 20.5, 19.5, 19, 19.5];

  return (
    <div>
      <HeaderDescription heading={"Conversion Rate"} />
      <h6 className="text-success font-24 font-700 m-0">+84%</h6>
      <LineChart dataArray={dataArray} height="90px" />
    </div>
  );
};

export default ConversionRate;
