import React from "react";
import { Route, Navigate } from "react-router-dom";
import { connect } from "react-redux";

interface IPrivateRouteProps {
  component: any;
  token: any;
  path?: any;
}

const PrivateRoute: React.FunctionComponent<IPrivateRouteProps> = ({
  component: Component,
  token,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      element={token ? <Component /> : <Navigate to={"/login"} />}
    />
  );
};
const mapState = (state: any) => ({
  token: state.userInfo.token,
});
export default connect(mapState)(PrivateRoute);
