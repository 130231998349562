import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Signup from "./sign-up";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Road, Car, Dots, Buildings, Logo } from "../../assets/icons";
import Login from "./login";
import SignUp from "../../redux/actions/local/sign-up";
import ForgotPassword from "./forgotPassword";
import CustomerLogin from "./customer";

interface PublicLayoutProps {
  signUp: any;
  reset: any;
}

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    height: "100vh",
    background: `linear-gradient(0deg, #BAD5DC 10%, #428C9F 90%)`,
    width: "100%",
  },
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    paddingRight: "10vw",
    paddingLeft: "10vw",
  },
  road: {
    width: "50vw",
    height: "2.5vw",
    position: "absolute",
    bottom: 0,
    right: 0,
    overflow: "hidden",
    zIndex: 12,
  },
  car: {
    position: "absolute",
    width: "17vw",
    height: "8.3vw",
    bottom: 0,
    right: "30vw",
    zIndex: 24,
  },
  buildings: {
    position: "absolute",
    width: "44vw",
    height: "26vw",
    bottom: 0,
    zIndex: 8,
    right: "3vw",
  },
  title: {
    color: "#fff",
    width: "27vw",
    lineHeight: 1.4,
    marginTop: "-2vw",
  },
  subtitle: {
    width: "30vw",
    overflow: "wrap",
    fontSize: "1rem",
    fontFamily: "Montserrat",
    lineHeight: 1.56,
    color: "#fff",
    marginTop: "1rem",
  },
  dots: {
    marginTop: "4vw",
    marginLeft: "-2vw",
    width: "4vw",
    height: "4vw",
  },
  wrapText: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    zIndex: 32,
    top: "2rem",
    left: "60vw",
  },
  logo: {
    marginTop: "5vh",
  },
  pageTitle: {
    fontWeight: 700,
  },
  fieldsContainer: {
    display: "flex",
    overflow: "auto",
    width: "100%",
    flexDirection: "column",
    flexShrink: 1,
    marginTop: "2vh",
    marginBottom: "auto",
  },
  secondStep: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    marginTop: "5vh",
  },
  topWidth: { marginTop: "5vh" },
}));

const PublicLayout: React.FunctionComponent<PublicLayoutProps> = ({
  signUp,
  reset,
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  // alert(JSON.stringify(signUp.state.flags.currentStep));
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          className={classes.container}
        >
          <Logo title="Mobio" className={classes.logo} />
          {pathname == "/login" && (
            <>
              <Typography
                variant="h2"
                className={`${classes.pageTitle} ${classes.topWidth}`}
              >
                Mobio Enterprise Login
              </Typography>
              <div className={classes.fieldsContainer}>
                <Login />
              </div>
            </>
          )}
          {pathname == "/sign-up" && (
            <>
              {signUp?.state?.flags?.currentStep == 1 ? (
                <Typography
                  variant="h2"
                  className={`${classes.pageTitle} ${classes.topWidth}`}
                >
                  Mobio Enterprise Sign up
                </Typography>
              ) : (
                <div className={classes.secondStep}>
                  <IconButton color="primary" onClick={() => reset()}>
                    <KeyboardBackspaceIcon />
                  </IconButton>
                  <Typography variant="h2" className={classes.pageTitle}>
                    Almost there
                  </Typography>
                </div>
              )}

              <div className={classes.fieldsContainer}>
                <Signup />
              </div>
            </>
          )}

          {pathname == "/forgot-password/customer" && (
            <>
              <Typography
                variant="h2"
                className={`${classes.pageTitle} ${classes.topWidth}`}
              >
                Forgot Password
              </Typography>
              <div className={classes.fieldsContainer}>
                <ForgotPassword role={"client"}/>
              </div>
            </>
          )}
          {pathname == "/forgot-password/enterprise" && (
            <>
              <Typography
                variant="h2"
                className={`${classes.pageTitle} ${classes.topWidth}`}
              >
                Forgot Password
              </Typography>
              <div className={classes.fieldsContainer}>
                <ForgotPassword  role={"enterprise"}/>
              </div>
            </>
          )}
          {pathname === "/customer" && (
            <>
              <Typography
                variant="h2"
                className={`${classes.pageTitle} ${classes.topWidth}`}
              >
                Customer Login
              </Typography>
              <div className={classes.fieldsContainer}>
                <CustomerLogin />
              </div>
            </>
          )}
        </Grid>
        <Hidden mdDown>
          <Grid
            item
            md={false}
            lg={6}
            xl={6}
            className={classes.imageContainer}
          >
            <div className={classes.wrapText}>
              <Dots title="grid" className={classes.dots} />
              <Typography variant="h2" className={classes.title}>
                Simple, Efficient &<br />
                Cost Saving
              </Typography>
              <Typography className={classes.subtitle}>
                It is a long established fact that a reader will
                <br />
                be distracted by the readable content of a<br />
                page when looking at its layout.
              </Typography>
            </div>
            <Buildings title="buildings" className={classes.buildings} />
            <Car title="car" className={classes.car} />
            <Road title="road" className={classes.road} />
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

const mapState = (state: any) => ({
  signUp: state?.signUp,
});
const mapDispatch = {
  reset: SignUp.flags.reset,
};

export default connect(mapState, mapDispatch)(PublicLayout);
