import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SignUp, {
  LocalSignUpRepo,
  Field,
} from "../../../redux/actions/local/sign-up";
import ThunkSignUp, {
  ThunkSignUpRepo,
} from "../../../redux/actions/thunks/sign-up";
import { StepOne, StepTwo } from "../../../components/sign-up";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import Local from "../../../redux/actions/local";

interface SignupProps {
  signUp: any;
  updateStep: LocalSignUpRepo["flags"]["updateStep"];
  postSignUp: ThunkSignUpRepo["postSignUp"];
  updateField: LocalSignUpRepo["field"]["update"];
  clearField: LocalSignUpRepo["field"]["clear"];
  errorMsg: any;
  reset: () => any;
  success: any;
}

export type HandleChange = (field: Field, value: any) => any;

export type Error = {
  email: boolean;
  password: boolean;
  companyName: boolean;
  userName: boolean;
  address: boolean;
  postalCode: boolean;
  city: boolean;
  country: boolean;
  addressLineTwo: boolean;
  phoneNumber: boolean;
  IBAN: boolean;
};

const Signup: React.FunctionComponent<SignupProps> = ({
  signUp,
  postSignUp,
  updateField,
  clearField,
  updateStep,
  errorMsg,
  reset,
  success,
}) => {
  const [error, setError] = useState<Error>({
    email: false,
    password: false,
    companyName: false,
    country: false,
    userName: false,
    addressLineTwo: false,
    address: false,
    postalCode: false,
    city: false,
    phoneNumber: false,
    IBAN: false,
  });

  /* generic handle change */
  const handleChange = (field: Field, value: any) => {
    /* for all fields that are empty and not email or password */
    field !== "password" &&
      field !== "email" &&
      value === "" &&
      setError({ ...error, [field]: true });

    /* Remove error */
    field !== "password" &&
      field !== "email" &&
      error[field as Field] === true &&
      value !== "" &&
      setError({ ...error, [field]: true });

    if (
      field === "userName" ||
      field === "companyName" ||
      field === "city" ||
      field === "address" ||
      field === "postalCode"
    ) {
      if (value.length > 0) {
        value && setError({ ...error, [field]: false });
      }
    }
    if (field === "password") {
      const passwordRgx =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/;

      !passwordRgx.test(value) &&
        error["password"] === false &&
        setError({ ...error, password: true });

      passwordRgx.test(value) &&
        error["password"] === true &&
        setError({ ...error, password: false });
    }

    /*
      - email Rgx fails, current error.email === false => email validation error
      - email Rgx passes, current error.email === true => clear validation error
    */
    if (field === "email") {
      const emailRgx =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      !emailRgx.test(value) &&
        error["email"] === false &&
        setError({ ...error, email: true });

      emailRgx.test(value) &&
        error["email"] === true &&
        setError({ ...error, email: false });
    }

    if (field === "IBAN") {
      const ibanRgx = /^NL[0-9]{2}[A-z0-9]{4}[0-9]{10}$/;

      !ibanRgx.test(value) &&
        error["IBAN"] === false &&
        setError({ ...error, IBAN: true });

      ibanRgx.test(value) &&
        error["IBAN"] === true &&
        setError({ ...error, IBAN: false });
    }

    updateField(field, value);
  };

  const handleSubmit = () => {
    /* iterate through comparables, find if all are false then call sign up api */
    let error_msg = '';
    const test: boolean = [
      "companyName",
      "country",
      "password",
      "email",
      "city",
      "address",
      "postalCode",
      "phoneNumber",
      "userName",
      "IBAN",
    ]
      .slice(0)
      .reduce((acc: any, curr: any, index, arr) => {
        console.log("error ", error[curr as Field], error);
        if (error[curr as Field] === true) {
          error_msg += curr + " validation error"
          arr.splice(1);
          acc = true;
          return acc;
        } else return false;
      }, false);

    console.log("signuptest ", test);
    postSignUp(error_msg);
    /* add snackbar warning support */
  };

  useEffect(() => {
    setError({
      email: false,
      password: false,
      companyName: false,
      country: false,
      userName: false,
      addressLineTwo: false,
      address: false,
      postalCode: false,
      city: false,
      phoneNumber: false,
      IBAN: false,
    })
  }, [])


  return (
    <>
      {signUp.flags.currentStep === 1 && (
        <StepOne
          error={error}
          email={signUp.email}
          password={signUp.password}
          userName={signUp.userName}
          updateStep={updateStep}
          handleChange={handleChange}
        />
      )}
      {signUp.flags.currentStep === 2 && (
        <StepTwo
          error={error}
          companyName={signUp.companyName}
          country={signUp.country}
          city={signUp.city}
          address={signUp.address}
          addressLineTwo={signUp.addressLineTwo}
          postalCode={signUp.postalCode}
          IBAN={signUp.IBAN}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
        />
      )}
      {errorMsg.status && (
        <ErrorSnack reset={() => reset()} errorMsg={errorMsg.message} />
      )}
      {success.status && success.data && (
        <ErrorSnack reset={() => reset()} success errorMsg={success.data} />
      )}
    </>
  );
};

const mapState = (state: any) => ({
  signUp: state.signUp.state,
  loading: state.signUp.api.loading,
  errorMsg: state.signUp.api.error,
  success: state.signUp.api.response,
});

const mapDispatch = {
  updateField: SignUp.field.update,
  clearField: SignUp.field.clear,
  postSignUp: ThunkSignUp.postSignUp,
  updateStep: SignUp.flags.updateStep,
  reset: Local.SignUp.api.reset,
};

export default connect(mapState, mapDispatch)(Signup);
