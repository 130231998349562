import Local from "../../local";

export type ThunksReportsListing = {
  getBookingsByCustomerId: (id: any, start: any, end: any) => any;
  getCompanies: (id: any) => any;
  getBookingsByIntegrationId: (id: any, start: any, end: any, companyName: any) => any;
  getReportForOrganization: (obj: any) => any;
  getCompaniesAndCustomersByOrgId: (start: any, end: any) => any;
};
const ThunksReports: ThunksReportsListing = {
  getBookingsByCustomerId: (id, start, end) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    console.log("this is user token", token);
    const api = mobioApi(token);
    return api
      .getBookingsByCustomerId(id, start, end)
      .then((response: any) => {
        return response.data
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        return data;
      });
  },
  getCompanies: () => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    console.log("this is user token", token);
    const api = mobioApi(token);
    dispatch(Local.Passengers.api.request());
    return api
      .getCompanies()
      .then((response: any) => {
        return response.data
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        return data;
      });
  },
  getCompaniesAndCustomersByOrgId: (start, end) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .getCompaniesAndCustomersByOrgId(start, end)
      .then((response: any) => {
        return response.data
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        return data;
      });
  },
  getBookingsByIntegrationId: (id, start, end, companyName) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    console.log("this is user token", token);
    const api = mobioApi(token);
    dispatch(Local.Passengers.api.request());
    return api
      .getBookingsByIntegrationId(id, start, end, companyName)
      .then((response: any) => {
        return response.data
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        return data;
      });
  },
  getReportForOrganization: (obj) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    console.log("this is user token", token);
    const api = mobioApi(token);
    dispatch(Local.Passengers.api.request());
    return api
      .getReportForOrganization(obj)
      .then((response: any) => {
        return response.data
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        return data;
      });
  },

};

export default ThunksReports;
