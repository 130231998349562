import Local from "../../local";

export type ThunksNetworksListing = {
  Networks: () => any;
  //   updatePassengers: (id: string, obj: any) => any;
  //   deletePassenger: (id: string) => any;
  createNetwork: (obj: any) => any;
  deleteNetwork: (id: string) => any;
  getDriversForNetwork: (obj: any) => any;
  getDispatchersForNetwork: (obj: any) => any;
  getAllUsersForNetwork: (obj: any) => any;
  getNetworkByOrganizationId: (obj: any) => any;
  getNetworkById: (id: string) => any;
  getNetworkByNetworkUserId: (id: string) => any;
  getNetworkDocsByNetworkId: (networkid: any) => any;
};
const ThunksNetworks: ThunksNetworksListing = {
  Networks: () => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    dispatch(Local.Networks.api.request());
    return api
      .getAllNetworks()
      .then((response: any) => {
        dispatch(Local.Networks.api.response(response.data));
        return response.data
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        dispatch(Local.Passengers.api.error("Request Failed", data.msg));
      });
  },
  createNetwork: (obj) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    console.log("this is user token", token);
    console.log(obj);

    const api = mobioApi(token);
    dispatch(Local.Networks.api.request());

    return api
      .createNetwork(obj)
      .then((response: any) => {
        const { data } = response;
        dispatch(Local.Networks.api.response(data));

        // Assuming the response includes networkId
        const networkId = data.networkId;

        return {
          state: true,
          networkId: networkId, // Return networkId along with success state
        };
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;

        return {
          state: false,
          msg: data.msg,
        };
      });
  },

  getNetworkDocsByNetworkId:
    (networkid) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      console.log("this is the ID", networkid.networkId);

      const api = mobioApi(token);

      return api
        .getNetworkDocsByNetworkId(networkid.networkId)
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          console.log(data.msg);
        });
    },

  deleteNetwork: (id) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    dispatch(Local.Networks.api.request());
    return api
      .deleteNetwork(id)
      .then((response: any) => {
        dispatch(Local.Networks.api.response(response.data));
        return true;
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        dispatch(Local.Networks.api.error("Request Failed", data.msg));
      });
  },
  getDriversForNetwork:
    (obj) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .getDriversForNetwork(obj)
        .then((response: any) => {
          return response.data.number;
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          console.log(data.msg);
        });
    },
  getDispatchersForNetwork:
    (obj) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .getDispatchersForNetwork(obj)
        .then((response: any) => {
          return response.data.number;
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          console.log(data.msg);
        });
    },
  getAllUsersForNetwork: (obj) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .getAllUsersForNetwork(obj)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        console.log(data.msg);
      });
  },
  getNetworkByNetworkUserId: (id) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .getNetworkByNetworkUserId(id)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        console.log(data.msg);
      });
  },
  getNetworkByOrganizationId:
    (id) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .getNetworkByOrganizationId(id)
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          console.log(data.msg);
        });
    },
  getNetworkById:
    (id) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .getNetworkById(id)
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          const {
            response: { data },
          } = error;
          console.log(data.msg);
        });
    },
};

export default ThunksNetworks;
