import {
  DIALOG,
  ORG_USER_API,
  ORG_AUTHORIZATIONS,
  ORG_USER_UPDATE_API,
  ORG_USER_GET_API,
  ORG_USER_DELETE_API,
  USER_UPDATE_API,
  GET_USER_AUTHORIZATIONS,
  UPDATE_ORG_USER_PASSWORD,
} from "../constants/orgUsers";

export type openCloseDialog = {
  open: () => any;
  close: () => any;
  selectUser: (data: any) => any;
};

const dialogActions: openCloseDialog = {
  open: () => ({
    type: DIALOG.OPEN_DIALOG,
    payload: {},
  }),
  close: () => ({
    type: DIALOG.CLOSE_DIALOG,
    payload: {},
  }),
  selectUser: (data) => ({
    type: DIALOG.SELECT_USER,
    payload: { data },
  }),
};

export type CreateUser = {
  request: (data: any) => any;
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};

const CreateOrgUser: CreateUser = {
  request: (data) => ({
    type: ORG_USER_API.SET_REQUEST,
    payload: { data },
  }),
  error: (type, message) => ({
    type: ORG_USER_API.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: ORG_USER_API.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: ORG_USER_API.RESET,
    payload: {},
  }),
};

export type Authorizations = {
  request: () => any;
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};

const GetAuthorizations: CreateUser = {
  request: (data) => ({
    type: ORG_AUTHORIZATIONS.SET_REQUEST,
    payload: { data },
  }),
  error: (type, message) => ({
    type: ORG_AUTHORIZATIONS.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: ORG_AUTHORIZATIONS.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: ORG_AUTHORIZATIONS.RESET,
    payload: {},
  }),
};

export type UpdateUser = {
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};

const UpdateOrgUser: UpdateUser = {
  error: (type, message) => ({
    type: ORG_USER_UPDATE_API.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: ORG_USER_UPDATE_API.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: ORG_USER_UPDATE_API.RESET,
    payload: {},
  }),
};

export type AllUser = {
  request: (data: any) => any;
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};

const getAllUsers: AllUser = {
  request: (data) => ({
    type: ORG_USER_GET_API.SET_REQUEST,
    payload: { data },
  }),
  error: (type, message) => ({
    type: ORG_USER_GET_API.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: ORG_USER_GET_API.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: ORG_USER_GET_API.RESET,
    payload: {},
  }),
};

export type Delete = {
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};

const deleteUser: Delete = {
  error: (type, message) => ({
    type: ORG_USER_DELETE_API.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: ORG_USER_DELETE_API.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: ORG_USER_DELETE_API.RESET,
    payload: {},
  }),
};

export type Update = {
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};

const UpdateCompleteUser: Update = {
  error: (type, message) => ({
    type: USER_UPDATE_API.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: USER_UPDATE_API.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: USER_UPDATE_API.RESET,
    payload: {},
  }),
};

export type getUserAuthrz = {
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};
const getUserAuthorizations: getUserAuthrz = {
  error: (type, message) => ({
    type: GET_USER_AUTHORIZATIONS.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: GET_USER_AUTHORIZATIONS.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: GET_USER_AUTHORIZATIONS.RESET_ERROR,
  }),
};
export type updatePass = {
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};
const UpdatePasswordError: updatePass = {
  error: (type, message) => ({
    type: UPDATE_ORG_USER_PASSWORD.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: UPDATE_ORG_USER_PASSWORD.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: UPDATE_ORG_USER_PASSWORD.RESET_ERROR,
  }),
};

export {
  dialogActions,
  CreateOrgUser,
  GetAuthorizations,
  UpdateOrgUser,
  getAllUsers,
  deleteUser,
  UpdateCompleteUser,
  getUserAuthorizations,
  UpdatePasswordError,
};
