import {
  BOOKINGS_LISTING,
  DISPATCHERS_LISTING,
  // DISPATCHERS_BY_BOOKING,
  CREATE_BOOKING,
  BOOKING_INTEGRATION,
  BOOKING_STATS,
} from "../constants/bookings";

export type Field =
  | "pickup"
  | "destination"
  | "dateTime"
  | "noOfPeople"
  | "carType"
  | "fare"
  | "dispatcherId"
  | "guestName"
  | "guestPhoneNumber"
  | "flightNumber"
  | "nameCaller"
  | "paymentType";

export type CreateBookingRepo = {
  resetAll: () => any;
  field: {
    update: (field: Field, newContent: string) => any;
    clear: (field: Field) => any;
  };
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
};

const CreateBooking: CreateBookingRepo = {
  resetAll: () => ({
    type: CREATE_BOOKING.RESET_ALL,
    payload: {},
  }),
  field: {
    update: (field, newContent) => ({
      type: CREATE_BOOKING.FIELD.UPDATE,
      payload: { field, newContent },
    }),
    clear: (field) => ({
      type: CREATE_BOOKING.FIELD.CLEAR,
      payload: { field },
    }),
  },
  api: {
    request: () => ({
      type: CREATE_BOOKING.CREATE_BOOKING_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: CREATE_BOOKING.CREATE_BOOKING_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: CREATE_BOOKING.CREATE_BOOKING_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: CREATE_BOOKING.CREATE_BOOKING_API.RESET,
      payload: {},
    }),
  },
};

export default CreateBooking;

export type BookingsListingRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
};
export type BookingIntegrationRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
};

export type DispatchersListingRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
  };
};

const BookingsListing: BookingsListingRepo = {
  api: {
    request: () => ({
      type: BOOKINGS_LISTING.BOOKINGS_LISTING_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: BOOKINGS_LISTING.BOOKINGS_LISTING_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: BOOKINGS_LISTING.BOOKINGS_LISTING_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: BOOKINGS_LISTING.BOOKINGS_LISTING_API.RESET,
      payload: {},
    }),
  },
};
const BookingIntegration: BookingIntegrationRepo = {
  api: {
    request: () => ({
      type: BOOKING_INTEGRATION.BOOKING_INTEGRATION_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: BOOKING_INTEGRATION.BOOKING_INTEGRATION_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: BOOKING_INTEGRATION.BOOKING_INTEGRATION_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: BOOKING_INTEGRATION.BOOKING_INTEGRATION_API.RESET,
      payload: {},
    }),
  },
};

// Dispatcher in edit and duplicate booking in web should be removed
// const DispatchersByBooking: DispatchersListingRepo = {
//   api: {
//     request: () => ({
//       type: DISPATCHERS_BY_BOOKING.DISPATCHERS_BY_BOOKING_API.SET_REQUEST,
//       payload: {},
//     }),
//     error: (type, message) => ({
//       type: DISPATCHERS_BY_BOOKING.DISPATCHERS_BY_BOOKING_API.SET_ERROR,
//       payload: { type, message },
//     }),
//     response: (data) => ({
//       type: DISPATCHERS_BY_BOOKING.DISPATCHERS_BY_BOOKING_API.SET_RESPONSE,
//       payload: [...data],
//     }),
//   },
// };

const DispatchersListing: DispatchersListingRepo = {
  api: {
    request: () => ({
      type: DISPATCHERS_LISTING.DISPATCHERS_LISTING_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: DISPATCHERS_LISTING.DISPATCHERS_LISTING_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: DISPATCHERS_LISTING.DISPATCHERS_LISTING_API.SET_RESPONSE,
      payload: { data },
    }),
  },
};

export type BookingStats = {
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  request: () => any;
  reset: () => any;
};
const TotalOverAssigned: BookingStats = {
  request: () => ({
    type: BOOKING_STATS.SET_REQUEST,
    payload: {},
  }),
  error: (type, message) => ({
    type: BOOKING_STATS.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: BOOKING_STATS.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: BOOKING_STATS.SET_RESET,
    payload: {},
  }),
};


export {
  BookingsListing,
  DispatchersListing,
  // DispatchersByBooking,
  CreateBooking,
  BookingIntegration,
  TotalOverAssigned
};
