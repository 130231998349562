import React from "react";

const AnalysisIcon = ({ color = "#29AEE6" }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.1869 0.893066H22.5869V20.0931H24.1869V0.893066Z"
        fill={color}
      />
      <path d="M19.387 23.2931H0.187012V24.8931H19.387V23.2931Z" fill={color} />
      <path
        d="M4.98672 13.6931H3.38672V21.6931H4.98672V13.6931Z"
        fill={color}
      />
      <path d="M9.7875 8.89307H8.1875V21.6931H9.7875V8.89307Z" fill={color} />
      <path d="M14.5873 4.09302H12.9873V10.493H14.5873V4.09302Z" fill={color} />
      <path
        d="M19.3871 0.893066H17.7871V10.4931H19.3871V0.893066Z"
        fill={color}
      />
      <path
        d="M16.1872 21.693C15.2379 21.693 14.3098 21.4115 13.5205 20.8841C12.7311 20.3566 12.1159 19.607 11.7526 18.7299C11.3893 17.8528 11.2942 16.8877 11.4794 15.9566C11.6646 15.0255 12.1218 14.1702 12.7931 13.4989C13.4644 12.8276 14.3197 12.3705 15.2508 12.1853C16.1819 12 17.147 12.0951 18.0241 12.4584C18.9012 12.8217 19.6508 13.4369 20.1783 14.2263C20.7057 15.0156 20.9872 15.9437 20.9872 16.893C20.9872 18.1661 20.4815 19.387 19.5813 20.2871C18.6811 21.1873 17.4602 21.693 16.1872 21.693ZM16.1872 13.693C15.5543 13.693 14.9356 13.8807 14.4094 14.2323C13.8831 14.5839 13.473 15.0837 13.2308 15.6684C12.9886 16.2532 12.9252 16.8966 13.0487 17.5173C13.1722 18.138 13.4769 18.7082 13.9245 19.1558C14.372 19.6033 14.9422 19.9081 15.5629 20.0315C16.1837 20.155 16.8271 20.0916 17.4118 19.8494C17.9965 19.6072 18.4963 19.1971 18.8479 18.6708C19.1995 18.1446 19.3872 17.5259 19.3872 16.893C19.3872 16.0443 19.0501 15.2304 18.45 14.6303C17.8498 14.0302 17.0359 13.693 16.1872 13.693Z"
        fill={color}
      />
      <path
        d="M19.1475 18.729L18.0161 19.8604L22.8131 24.6574L23.9445 23.526L19.1475 18.729Z"
        fill={color}
      />
    </svg>
  );
};

export default AnalysisIcon;
