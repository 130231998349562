import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Input from "../../../components/commons/Input";
import { Grid, Paper } from "@material-ui/core";
import { connect } from "react-redux";
// import { AddCustomer } from "../../../redux/actions/local/customers";
import ErrorSnack from "../../../components/commons/ErrorSnack";
// import ThunksCustomers from "../../../redux/actions/thunks/customer";
import Local from "../../../redux/actions/local";
import AddUserAuthorities from "./AddUserAuthorities";
import ThunksUsers from "../../../redux/actions/thunks/OrgUsers";

const styles = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    height: 600,
    marginLeft: 16,
    marginRight: 34,
  },

  button: {
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 8,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
  },
  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    width: 550,
    height: 40,
  },
});

const DialogTitle = withStyles(styles as any)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

interface AddUserProps {
  openDialog: () => any;
  closeDialog: () => any;
  postUser: (data: any) => any;
  getAllOrgUsers: () => any;
  updateOrgUser: (data: any) => any;
  status: any;
  failure: any;
  success: any;
  dialogIsOpen: any;
  selectedUser: any;
  response: any;
  reset: () => any;
  resetUpadte: () => any;
}

const AddUser: React.FC<AddUserProps> = ({
  openDialog,
  closeDialog,
  getAllOrgUsers,
  postUser,
  updateOrgUser,
  status,
  failure,
  success,
  reset,
  response,
  dialogIsOpen,
  selectedUser,
  resetUpadte,
}) => {
  const initailUser = {
    email: "",
    displayName: "",
    fullName: "",
  };
  const classes = useStyles();
  const [user, setUser] = useState<any>(initailUser);
  const [error, setError] = useState<any>({
    email: false,
    displayName: false,
    fullName: false,
  });
  const handleClickOpen = () => {
    openDialog();
  };
  const handleClose = () => {
    closeDialog();
  };
  useEffect(() => {
    if (!!selectedUser?.id) {
      setUser(selectedUser);
    } else {
      setUser(initailUser);
    }
  }, [selectedUser?.id]);

  useEffect(() => {
    if (user.displayName !== "") {
      setError({ ...error, displayName: false });
    } else {
      setError({ ...error, displayName: true });
    }
  }, [user.displayName]);

  useEffect(() => {
    if (user?.selectedAuthorities?.length > 0) {
      setError({ ...error, selectedAuthorities: false });
    } else {
      setError({ ...error, selectedAuthorities: true });
    }
  }, [user.selectedAuthorities]);

  useEffect(() => {
    if (user.fullName !== "") {
      setError({ ...error, fullName: false });
    } else {
      setError({ ...error, fullName: true });
    }
  }, [user.fullName]);

  useEffect(() => {
    const rgx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (rgx.test(user.email) && user.email !== "") {
      setError({ ...error, email: false });
    } else {
      setError({ ...error, email: true });
    }
  }, [user.email]);

  const handleChange = (e: any) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Button
        style={{
          borderRadius: 8,
          backgroundColor: "#2AABD5",
          color: "#FFFFFF",
          height: 40,
        }}
        onClick={handleClickOpen}
        variant="contained"
        // startIcon={<AddIcon />}
      >
        {!!selectedUser?.id ? "Edit User" : "Add User"}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={dialogIsOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {!!selectedUser?.id ? "Update User" : "Create User"}
        </DialogTitle>
        <DialogContent dividers>
          <Grid>
            <Input
              label="User Email "
              value={user.email}
              disabled={!!selectedUser?.id}
              required
              autoFocus
              type="email"
              error={error.email}
              size="small"
              name="email"
              className={classes.textfield_upper}
              onChange={(e: any) => handleChange(e)}
            />
          </Grid>
          <Grid>
            <Input
              label="User Name"
              value={user.displayName}
              required
              type="text"
              size="small"
              name="displayName"
              className={classes.textfield_upper}
              onChange={(e: any) => handleChange(e)}
            />
          </Grid>
          <Grid>
            <Input
              label="Full Name"
              value={user.fullName}
              required
              type="text"
              size="small"
              name="fullName"
              className={classes.textfield_upper}
              onChange={(e: any) => handleChange(e)}
            />
          </Grid>
          <AddUserAuthorities
            selectedAuthorities={(sel: any) =>
              setUser({ ...user, selectedAuthorities: sel })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              borderRadius: 8,
              backgroundColor: "#2AABD5",
              color: "#FFFFFF",
              height: 40,
            }}
            disabled={
              error.email ||
              error.displayName ||
              error.fullName ||
              error.selectedAuthorities
            }
            onClick={async () => {
              let hasError = 0;
              let getheringError: any = {};
              for (let key in user) {
                if (user[key] !== "") {
                  getheringError[key] = false;
                } else {
                  getheringError[key] = true;
                  hasError = 1;
                }
              }
              setError(getheringError);
              if (!hasError) {
                user.selectedAuthorities = user.selectedAuthorities.map(
                  (item: any) => {
                    return item.id;
                  }
                );
                if (!!selectedUser?.id) {
                  delete user.block;
                  delete user.tableData;
                  delete user.userAuthorities;
                  delete user.email;
                  await updateOrgUser(user);
                } else {
                  await postUser(user);
                }
                getAllOrgUsers();
                handleClose();
                setUser({});
              } else {
                alert("Please fill all the required fields.");
              }
            }}
            variant="contained"
          >
            {!!selectedUser?.id ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
      {!!failure ? (
        <ErrorSnack
          reset={() => reset()}
          errorMsg={failure?.message || failure}
        />
      ) : null}
      {!!success ? (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"User Created Successfully"}
        />
      ) : null}
      {!!response?.error && (
        <ErrorSnack errorMsg={response.error} reset={() => resetUpadte()} />
      )}
      {!!response?.success && (
        <ErrorSnack
          reset={() => resetUpadte()}
          success
          errorMsg={"User Updated Successfully"}
        />
      )}
    </div>
  );
};
const mapState = (state: any) => ({
  status: state,
  dialogIsOpen: state.orgUsers.dialog.isOpen,
  selectedUser: state.orgUsers.dialog.selectedUser,
  failure: state.orgUsers.createUser.error,
  success: state.orgUsers.createUser.success,
  response: state.orgUsers.updateCompleteUser,
});

const mapDispatch = {
  openDialog: Local.dialogActions.open,
  closeDialog: Local.dialogActions.close,
  postUser: ThunksUsers.createOrgUser,
  getAllOrgUsers: ThunksUsers.orgUsers,
  updateOrgUser: ThunksUsers.updateOrgUser,
  reset: Local.CreateOrgUser.reset,
  resetUpadte: Local.UpdateCompleteUser.reset,
};
export default connect(mapState, mapDispatch)(AddUser);
