import Local from "../../local";

export type ThunksOnboardingListing = {
  OnboardingListing: (orderFilter:any) => any;
  updateOnboardingListing: (obj: any) => any;
  CheckDisplayName: (obj: any) => any;
};

const ThunksOnboarding: ThunksOnboardingListing = {
  CheckDisplayName: (displayName) =>async (dispatch: any, getState: any, mobioApi: any) =>{
    const {
      userInfo: { token }
    } = getState();

    if (displayName ) {
      const api = mobioApi(token);
      return await api.CheckDisplayName({ displayName })
    }
  },
  OnboardingListing: (orderFilter) => (dispatch: any, getState: any, mobioApi: any) => {
    // console.log("driver list");
    const {
      userInfo: { token },
      onboardingListing: { response },
    } = getState();
    const api = mobioApi(token);
    dispatch(Local.OnboardingListing.api.request());

    return api
      .getOnboardingListing(orderFilter)
      .then((response: any) => {     
        dispatch(Local.OnboardingListing.api.response(response.data));
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        dispatch(Local.OnboardingListing.api.error("Request Failed", error?.response?.data?.msg));
      });
  },

  updateOnboardingListing: (obj) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const vehicle = {
      onBoardingId: obj.id,
      carId: obj.car.id,
      carYear: obj.car.year,
      carSeatingCapacity: obj.car.seatingCapacity,
      carCategory: obj.car.category,
      insuranceExpiry: obj.insuranceExpiry,
      licenseExpiry: obj.licenseExpiry,
      vehicleCardExpiry: obj.vehicleCardExpiry,
      numberPlate: obj.numberPlate,
      carcolor: obj.car.color,
    };
    console.log("VEHICLE: ", vehicle)
    const api = mobioApi(token);
    return api
      .updateOnboarding(vehicle)
      .then((response: any) => {
        alert("Updated Successfully");
      })
      .catch((error: any) => {
        const {
          response: {
            data: { msg },
          },
        } = error;
        alert(msg);
      });
  },
};

export default ThunksOnboarding;
