import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/BreadCrumb"; // Import the Breadcrumb component
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import { Add, Search } from "@material-ui/icons";
import CountryCard from "../../../components/commons/CountryCard";
import { connect } from "react-redux";
import mobioApi from "../../../services";
import AddCountryModal from "./Modals/AddCountryModal";
import DeleteModal from "../../../components/commons/Modals/DeleteModal";
import { toastMessage } from "../../../helpers/toastMessage";
import StatusCountryModal from "./Modals/StatusCountryModal";

const RateCountryPage = ({ token, companyId }) => {
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [dampCountryList, setDampCountryList] = useState([]);
  const [addCountryModal, setAddCountryModal] = useState(false);
  const [deleteCountryModal, setDeleteCountryModal] = useState(false);
  const [statusCountryModal, setStatusCountryModal] = useState(false);
  const { integrationType } = useParams();
  const [anchorEl, setAnchorEl] = useState("");
  const [singleData, setSingleData] = useState("");

  const paths = [{ name: "Location", url: "/dashboard/integration" }];
  const getCountries = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getCountries(companyId, integrationType);
      setCountryList(response?.data?.data);
      setDampCountryList(response?.data?.data);
    } catch (error) {
      setCountryList([]);
    }
  };

  const deleteCountry = async (countryId) => {
    try {
      const api = mobioApi(token);
      const response = await api.deleteCountry({ countryId: countryId });
      toastMessage("success", response?.data.msg);
      const updateList = dampCountryList?.filter(
        (item) => item?.id !== countryId
      );
      setDampCountryList(updateList);
      setCountryList(updateList);
      setDeleteCountryModal(false);
      setSingleData("");
    } catch (error) {
      toastMessage("error", error?.response?.data.msg);
    }
  };
  const statusCountry = async (data) => {
    try {
      const payload = {
        companyId: data?.companyId,
        integrationType: integrationType,
        countryName: data?.countryName,
        currency: data?.currency,
        isActive: !data.isActive,
        isDeleted: data?.isDeleted,
      };
      const api = mobioApi(token);
      await api.addNewCountry(payload);
      toastMessage("success", "Country has been disabled");
      const updateList = dampCountryList?.map((item) => {
        if (item?.id === data?.id) {
          data.isActive = !data?.isActive;
        }

        return item;
      });
      setDampCountryList(updateList);
      setCountryList(updateList);
      setStatusCountryModal(false);
      setSingleData("");
    } catch (error) {
      toastMessage("error", error?.response?.data.msg);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <div>
      <div
        className="mt-18"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "18px",
        }}
      >
        <div>
          <Breadcrumb paths={paths} />
          <div className="breadcrumb-description">
            Use this area to manage the area your fleet operates from
          </div>
        </div>
        <div>
          <MOButtonV2
            text="ADD COUNTRY"
            textColor="#ffffff"
            radius={4}
            backgroundColor="#29AEE6"
            // onClick={handleAddLocationClick}
            onClick={() => {
              setAddCountryModal(true);
            }}
            IconType={() => <Add style={{ marginRight: 10, fontSize: 16 }} />}
            height={30}
            fontSize={12}
            padding={"6px 64px 6px 64px"}
          />
        </div>
      </div>

      <div className="bg-white border-radius-8 pt-24  pb-40 pr-50 pl-50 mt-20">
        <div className="flex align-item-center justify-content-center">
          <div className="search-lg-section">
            <Search />
            <input
              placeholder="Search Country"
              onChange={(e) => {
                const value = e.target.value;
                if (value) {
                  const results = dampCountryList.filter((item) =>
                    item.countryName.toLowerCase().includes(value.toLowerCase())
                  );
                  setCountryList(results);
                } else {
                  setCountryList(dampCountryList);
                }
              }}
            />
          </div>
        </div>
        {/* title */}
        <div className="mt-24">
          <h1 className="font-17 font-500 text-align-center">
            Available Countries
          </h1>
        </div>
        <div className="mt-24 rate-country-main-body ">
          {countryList.map((data) => (
            <CountryCard
              data={data}
              key={data.id}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              setDeleteCountryModal={setDeleteCountryModal}
              setSingleData={setSingleData}
              setStatusCountryModal={setStatusCountryModal}
            />
          ))}
        </div>
      </div>
      {addCountryModal && (
        <AddCountryModal
          modalOpen={addCountryModal}
          setModalOpen={setAddCountryModal}
          token={token}
          integrationType={integrationType}
          companyId={companyId}
          apiCall={getCountries}
        />
      )}
      {statusCountryModal && (
        <StatusCountryModal
          modalOpen={statusCountryModal}
          setModalOpen={setStatusCountryModal}
          data={singleData}
          apiCall={() => {
            statusCountry(singleData);
          }}
        />
      )}
      {deleteCountryModal && (
        <DeleteModal
          modalOpen={deleteCountryModal}
          setModalOpen={setDeleteCountryModal}
          title="Delete Country"
          heading="Are You Sure?"
          description="Are you certain you want to delete this country?"
          apiCall={() => {
            deleteCountry(singleData?.id);
          }}
        />
      )}
    </div>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
  companyId: state.userInfo.user.id,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(RateCountryPage);
