import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerField = ({ label, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  const dayClassName = (date) => {
    const day = date.getDay();
    if (day === 0) {
      return "sun-sat-highlight";
    } else if (day === 6) {
      return "sun-sat-highlight";
    }
    return "";
  };

  return (
    <div className="date-picker-v2">
      <DatePicker
        {...field}
        {...props}
        selected={field.value}
        onChange={(val) => setFieldValue(field.name, val)}
        dayClassName={dayClassName}
      />
      {meta.touched && meta.error ? (
        <div className="text-dan">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default DatePickerField;
