// import { useEffect, useRef, useState } from "react";
// import { AccessTime, ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
// import ArrowUp from "../../assets/icons/clock_arrow_up.svg";
// import ArrowDown from "../../assets/icons/clock_arrow_down.svg";

// const ClockTimer = ({
//   form,
//   field,
//   style = {
//     textIndent: "10px",
//     height: "30px",
//   },
//   iconStyle = {
//     cursor: "pointer",
//     height: "20px",
//     width: "20px",
//     marginRight: "12px",
//   },
// }) => {
//   const ClockRef = useRef();
//   const [clockOpen, setClockOpen] = useState(false);
//   // Minutes array from 00 to 59
//   const [mints] = useState(
//     Array(60)
//       .fill(null)
//       .map((_, index) => (index > 9 ? index : `0${index}`))
//   );

//   // Hours array from 00 to 23 for 24-hour format
//   const [hours] = useState(
//     Array(24)
//       .fill(null)
//       .map((_, index) => (index > 9 ? index : `0${index}`))
//   );

//   // Selected time state
//   const [selectTime, setSelectTime] = useState({
//     hours: "",
//     mints: "",
//   });

//   // Handle clicks outside the clock to close the dropdown
//   useEffect(() => {
//     const handleMarkClick = (e) => {
//       if (!ClockRef.current?.contains(e.target)) {
//         setClockOpen(false);
//       }
//     };
//     document.addEventListener("mousedown", handleMarkClick);
//     return () => {
//       document.removeEventListener("mousedown", handleMarkClick);
//     };
//   }, []);

//   const handleHourSelect = (item) => {
//     setSelectTime((prev) => ({ ...prev, hours: item }));
//     // checkTiming(item, selectTime.mints);
//   };

//   const handleMinuteSelect = (item) => {
//     setSelectTime((prev) => ({ ...prev, mints: item }));
//     // checkTiming(selectTime.hours, item);
//   };

//   const checkTiming = (hours, mints) => {
//     if (hours && mints) {
//       const formattedTime = `${hours}:${mints}`;
//       form.setFieldValue(field.name, formattedTime);
//       setClockOpen(false);
//     }
//   };

//   return (
//     <div ref={ClockRef} className={`clock-timer`}>
//       <input
//         type="text"
//         onClick={() => setClockOpen(!clockOpen)}
//         value={field?.value || "--:--"}
//         readOnly
//         className={`clock-timer-input`}
//         style={style}
//       />

//       <section
//         className={`clock-options ${
//           clockOpen ? "clock-options-visible" : "clock-options-hidden"
//         }`}
//       >
//         <div
//           style={{
//             borderBottom: "1px solid #0000000F",
//           }}
//         >
//           <h1 className="font-11 font-500 text-align-center mt-12 mb-12">
//             Select time
//           </h1>
//         </div>
//         <div
//           className="flex"
//           style={{
//             margin: "0 auto",
//             width: "max-content",
//           }}
//         >
//           {/* Hours Scroll */}
//           <div className="">
//             <div className="flex justify-content-center">
//               <img
//                 src={ArrowUp}
//                 style={{
//                   width: "12px",
//                   height: "12px",
//                 }}
//               />
//             </div>
//             <div className="clock-options-hours">
//               {hours.map((item, index) => (
//                 <p
//                   onClick={() => handleHourSelect(item)}
//                   className={`clock-option ${
//                     selectTime.hours === item ? "selected" : ""
//                   }`}
//                   key={index}
//                 >
//                   {item}
//                 </p>
//               ))}
//             </div>
//             <div className="flex justify-content-center">
//               <img
//                 src={ArrowDown}
//                 style={{
//                   width: "12px",
//                   height: "12px",
//                 }}
//               />
//             </div>
//           </div>

//           {/* Minutes Scroll */}
//           <div>
//             <div className="flex justify-content-center">
//               <img
//                 src={ArrowUp}
//                 style={{
//                   width: "12px",
//                   height: "12px",
//                 }}
//               />
//             </div>
//             <div className="clock-options-minutes">
//               {mints.map((item, index) => (
//                 <p
//                   onClick={() => handleMinuteSelect(item)}
//                   className={`clock-option ${
//                     selectTime.mints === item ? "selected" : ""
//                   }`}
//                   key={index}
//                 >
//                   {item}
//                 </p>
//               ))}
//             </div>
//             <div className="flex justify-content-center">
//               <img
//                 src={ArrowDown}
//                 style={{
//                   width: "12px",
//                   height: "12px",
//                 }}
//               />
//             </div>
//           </div>
//         </div>

//         <div className="flex align-item-center justify-content-between mt-12">
//           <button
//             type="button"
//             className="clock-timer-cancel"
//             onClick={() => {
//               setClockOpen(false);
//             }}
//           >
//             CANCEL
//           </button>
//           <button
//             type="button"
//             className="clock-timer-save"
//             onClick={() => {
//               checkTiming(selectTime.hours || "00", selectTime.mints || "00");
//             }}
//           >
//             SAVE
//           </button>
//         </div>
//       </section>

//       <AccessTime style={iconStyle} onClick={() => setClockOpen(!clockOpen)} />
//     </div>
//   );
// };

// export default ClockTimer;

// V2
import { useEffect, useRef, useState } from "react";
import { AccessTime } from "@material-ui/icons";
import ArrowUp from "../../assets/icons/clock_arrow_up.svg";
import ArrowDown from "../../assets/icons/clock_arrow_down.svg";

const ClockTimer = ({
  form,
  field,
  style = {
    textIndent: "10px",
    height: "30px",
  },
  iconStyle = {
    cursor: "pointer",
    height: "20px",
    width: "20px",
    marginRight: "12px",
  },
}) => {
  const ClockRef = useRef();
  const hoursRef = useRef();
  const minutesRef = useRef();
  const [clockOpen, setClockOpen] = useState(false);

  // Minutes array from 00 to 59
  const [mints] = useState(
    Array(60)
      .fill(null)
      .map((_, index) => (index < 10 ? `0${index}` : `${index}`))
  );

  // Hours array from 00 to 23 for 24-hour format
  const [hours] = useState(
    Array(24)
      .fill(null)
      .map((_, index) => (index < 10 ? `0${index}` : `${index}`))
  );

  // Selected time state
  const [selectTime, setSelectTime] = useState({
    hours: "00",
    mints: "00",
  });

  // Handle clicks outside the clock to close the dropdown
  useEffect(() => {
    const handleMarkClick = (e) => {
      if (!ClockRef.current?.contains(e.target)) {
        setClockOpen(false);
      }
    };
    document.addEventListener("mousedown", handleMarkClick);
    return () => {
      document.removeEventListener("mousedown", handleMarkClick);
    };
  }, []);

  useEffect(() => {
    if (field?.value != "") {
      const [hour, minute] = field?.value?.split(":");
      if (selectTime.hours != hour && selectTime.mints != minute) {
        setSelectTime({ hours: hour || "00", mints: minute || "00" });
        scrollToHour(hour);
        scrollToMinute(minute);
      }
    }
  }, [field]);
  const handleHourSelect = (item) => {
    setSelectTime((prev) => ({ ...prev, hours: item }));
    scrollToHour(item);
  };

  const handleMinuteSelect = (item) => {
    setSelectTime((prev) => ({ ...prev, mints: item }));
    scrollToMinute(item);
  };

  const scrollToHour = (hour) => {
    const index = hours.findIndex((item) => item == hour);

    if (hoursRef.current) {
      hoursRef.current.scrollTo({
        top: index * 32, // Adjust 24 based on your item height
        behavior: "smooth",
      });
    }
  };

  const scrollToMinute = (minute) => {
    const index = mints.findIndex((item) => item == minute);
    if (minutesRef.current) {
      minutesRef.current.scrollTo({
        top: index * 32, // Adjust 24 based on your item height
        behavior: "smooth",
      });
    }
  };

  // Increment or decrement hours
  const handleHourChange = (direction) => {
    const currentHour = parseInt(selectTime.hours || "00", 10);
    let newHour;

    if (direction === "up") {
      newHour = currentHour === 23 ? 0 : currentHour + 1;
    } else {
      newHour = currentHour === 0 ? 23 : currentHour - 1;
    }

    const formattedHour = newHour < 10 ? `0${newHour}` : `${newHour}`;
    setSelectTime((prev) => ({ ...prev, hours: formattedHour }));
    scrollToHour(formattedHour);
  };

  // Increment or decrement minutes
  const handleMinuteChange = (direction) => {
    const currentMinute = parseInt(selectTime.mints || "00", 10);
    let newMinute;

    if (direction === "up") {
      newMinute = currentMinute === 59 ? 0 : currentMinute + 1;
    } else {
      newMinute = currentMinute === 0 ? 59 : currentMinute - 1;
    }

    const formattedMinute = newMinute < 10 ? `0${newMinute}` : `${newMinute}`;
    setSelectTime((prev) => ({ ...prev, mints: formattedMinute }));
    scrollToMinute(formattedMinute);
  };

  const checkTiming = (hours, mints) => {
    if (hours && mints) {
      const formattedTime = `${hours}:${mints}`;
      form.setFieldValue(field.name, formattedTime);
      setClockOpen(false);
    }
  };

  return (
    <div ref={ClockRef} className={`clock-timer`}>
      <input
        type="text"
        onClick={() => setClockOpen(!clockOpen)}
        value={field?.value || "--:--"}
        readOnly
        className={`clock-timer-input`}
        style={style}
      />

      <section
        className={`clock-options ${
          clockOpen ? "clock-options-visible" : "clock-options-hidden"
        }`}
      >
        <div style={{ borderBottom: "1px solid #0000000F" }}>
          <h1 className="font-11 font-500 text-align-center mt-12 mb-12">
            Select time
          </h1>
        </div>
        <div
          className="flex mt-12"
          style={{
            margin: "0 auto",
            width: "max-content",
            paddingTop: "12px",
          }}
        >
          {/* Hours Scroll */}
          <div>
            <div className="flex justify-content-center">
              <img
                src={ArrowUp}
                style={{
                  width: "12px",
                  height: "12px",
                  cursor: "pointer",
                }}
                onClick={() => handleHourChange("up")}
              />
            </div>
            <div className="clock-options-hours" ref={hoursRef}>
              {hours.map((item, index) => (
                <p
                  onClick={() => handleHourSelect(item)}
                  className={`clock-option ${
                    selectTime.hours === item ? "selected" : ""
                  }`}
                  key={index}
                >
                  {item}
                </p>
              ))}
            </div>
            <div className="flex justify-content-center">
              <img
                src={ArrowDown}
                style={{
                  width: "12px",
                  height: "12px",
                  cursor: "pointer",
                }}
                onClick={() => handleHourChange("down")}
              />
            </div>
          </div>

          {/* Minutes Scroll */}
          <div>
            <div className="flex justify-content-center">
              <img
                src={ArrowUp}
                style={{
                  width: "12px",
                  height: "12px",
                  cursor: "pointer",
                }}
                onClick={() => handleMinuteChange("up")}
              />
            </div>
            <div className="clock-options-minutes" ref={minutesRef}>
              {mints.map((item, index) => (
                <p
                  onClick={() => handleMinuteSelect(item)}
                  className={`clock-option ${
                    selectTime.mints === item ? "selected" : ""
                  }`}
                  key={index}
                >
                  {item}
                </p>
              ))}
            </div>
            <div className="flex justify-content-center">
              <img
                src={ArrowDown}
                style={{
                  width: "12px",
                  height: "12px",
                  cursor: "pointer",
                }}
                onClick={() => handleMinuteChange("down")}
              />
            </div>
          </div>
        </div>

        <div
          className="flex align-item-center justify-content-between mt-12"
          style={{
            paddingLeft: "6px",
            paddingRight: "6px",
            paddingTop: "12px",
            borderTop: "1px solid #0000000F",
            paddingBottom: "12px",
          }}
        >
          <button
            type="button"
            className="clock-timer-cancel"
            onClick={() => {
              setClockOpen(false);
            }}
          >
            CANCEL
          </button>
          <button
            type="button"
            className="clock-timer-save"
            onClick={() => {
              checkTiming(selectTime.hours || "00", selectTime.mints || "00");
            }}
          >
            SAVE
          </button>
        </div>
      </section>

      <AccessTime style={iconStyle} onClick={() => setClockOpen(!clockOpen)} />
    </div>
  );
};

export default ClockTimer;
