import React from 'react'

const MyProfileIcon = ({color}) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.4998 7.89004C8.4998 6.24169 10.0371 4.74004 12.1498 4.74004C14.2625 4.74004 15.7998 6.24169 15.7998 7.89004C15.7998 9.53838 14.2625 11.04 12.1498 11.04C10.0371 11.04 8.4998 9.53838 8.4998 7.89004ZM12.1498 3.04004C9.29197 3.04004 6.7998 5.12011 6.7998 7.89004C6.7998 10.66 9.29197 12.74 12.1498 12.74C15.0076 12.74 17.4998 10.66 17.4998 7.89004C17.4998 5.12011 15.0076 3.04004 12.1498 3.04004ZM5.4998 18.39C5.4998 17.82 5.98843 17.1528 7.32532 16.5861C8.5996 16.0459 10.3552 15.74 12.1498 15.74C13.9251 15.74 15.6796 16.0973 16.9608 16.6668C17.6025 16.9519 18.0853 17.2726 18.3958 17.5919C18.7027 17.9075 18.7998 18.175 18.7998 18.39C18.7998 18.4395 18.7829 18.5681 18.5373 18.7721C18.2843 18.9824 17.8611 19.2054 17.245 19.4049C16.0202 19.8017 14.2417 20.04 12.1498 20.04C10.0579 20.04 8.27945 19.8017 7.0546 19.4049C6.43854 19.2054 6.0153 18.9824 5.76226 18.7721C5.51672 18.5681 5.4998 18.4395 5.4998 18.39ZM12.1498 14.04C10.1944 14.04 8.20001 14.3688 6.66179 15.0209C5.18618 15.6465 3.7998 16.7293 3.7998 18.39C3.7998 19.1053 4.18467 19.6715 4.67574 20.0796C5.1593 20.4815 5.8075 20.7879 6.53073 21.0222C7.98445 21.4931 9.95599 21.74 12.1498 21.74C14.3436 21.74 16.3152 21.4931 17.7689 21.0222C18.4921 20.7879 19.1403 20.4815 19.6239 20.0796C20.1149 19.6715 20.4998 19.1053 20.4998 18.39C20.4998 17.6051 20.1282 16.9351 19.6147 16.4069C19.1049 15.8825 18.4159 15.4531 17.6513 15.1133C16.12 14.4328 14.1245 14.04 12.1498 14.04Z" fill={color}/>
</svg>

  )
}

export default MyProfileIcon