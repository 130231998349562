import {
  
  CLIENT_USER_API,
  CLIENT_AUTHORIZATIONS,
  CLIENT_USER_UPDATE_API,
  CLIENT_USER_GET_API,
  CLIENT_USER_DELETE_API,
  CLIENT_UPDATE_API,
  GET_CLIENT_AUTHORIZATIONS,
  UPDATE_CLIENT_USER_PASSWORD,
} from "../constants/clientUsers";

export type CreateUser = {
  request: (data: any) => any;
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};

const CreateCustomerUser: CreateUser = {
  request: (data) => ({
    type: CLIENT_USER_API.SET_REQUEST,
    payload: { data },
  }),
  error: (type, message) => ({
    type: CLIENT_USER_API.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: CLIENT_USER_API.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: CLIENT_USER_API.RESET,
    payload: {},
  }),
};

export type Authorizations = {
  request: () => any;
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};

const GetCustomerAuthorizations: CreateUser = {
  request: (data) => ({
    type: CLIENT_AUTHORIZATIONS.SET_REQUEST,
    payload: { data },
  }),
  error: (type, message) => ({
    type: CLIENT_AUTHORIZATIONS.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: CLIENT_AUTHORIZATIONS.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: CLIENT_AUTHORIZATIONS.RESET,
    payload: {},
  }),
};

export type UpdateUser = {
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};

const UpdateCustomerUser: UpdateUser = {
  error: (type, message) => ({
    type: CLIENT_USER_UPDATE_API.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: CLIENT_USER_UPDATE_API.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: CLIENT_USER_UPDATE_API.RESET,
    payload: {},
  }),
};

export type AllUser = {
  request: (data: any) => any;
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};

const getCustomerUsers: AllUser = {
  request: (data) => ({
    type: CLIENT_USER_GET_API.SET_REQUEST,
    payload: { data },
  }),
  error: (type, message) => ({
    type: CLIENT_USER_GET_API.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: CLIENT_USER_GET_API.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: CLIENT_USER_GET_API.RESET,
    payload: {},
  }),
};

export type Delete = {
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};

const deleteCustomerUser: Delete = {
  error: (type, message) => ({
    type: CLIENT_USER_DELETE_API.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: CLIENT_USER_DELETE_API.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: CLIENT_USER_DELETE_API.RESET,
    payload: {},
  }),
};

export type Update = {
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};

const UpdateCompleteCustomerUser: Update = {
  error: (type, message) => ({
    type: CLIENT_UPDATE_API.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: CLIENT_UPDATE_API.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: CLIENT_UPDATE_API.RESET,
    payload: {},
  }),
};

export type getUserAuthrz = {
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};
const getCustomerAuthorz: getUserAuthrz = {
  error: (type, message) => ({
    type: GET_CLIENT_AUTHORIZATIONS.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: GET_CLIENT_AUTHORIZATIONS.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: GET_CLIENT_AUTHORIZATIONS.RESET_ERROR,
  }),
};
export type updatePass = {
  error: (type: string | number, message: string) => any;
  response: (data: any) => any;
  reset: () => any;
};
const UpdateCustomerPasswordError: updatePass = {
  error: (type, message) => ({
    type: UPDATE_CLIENT_USER_PASSWORD.SET_ERROR,
    payload: { type, message },
  }),
  response: (data) => ({
    type: UPDATE_CLIENT_USER_PASSWORD.SET_RESPONSE,
    payload: { data },
  }),
  reset: () => ({
    type: UPDATE_CLIENT_USER_PASSWORD.RESET_ERROR,
  }),
};

export {
  
  CreateCustomerUser,
  GetCustomerAuthorizations,
  UpdateCustomerUser,
  getCustomerUsers,
  deleteCustomerUser,
  UpdateCompleteCustomerUser,
  getCustomerAuthorz,
  UpdateCustomerPasswordError,
};
