import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const LineChart = ({ dataArray = [], height = "90px" }) => {
  // Define the data array
  //   const dataArray = [
  //     17, 18, 19, 20, 21, 21.5, 20.5, 19.5, 19, 19.5, 20, 21, 21.5, 20.5, 19.5,
  //   ];

  // Generate labels based on the length of dataArray
  const labels = dataArray.map((_, index) => `Label ${index + 1}`);

  // Define the data for the chart
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Data",
        data: dataArray,
        fill: true,
        borderColor: "#29AEE6",
        pointStyle: false,
        backgroundColor: "rgba(40, 176, 225, 0.502)",
        // backgroundColor: (context) => {
        //   const chart = context.chart;
        //   const { ctx, chartArea } = chart;

        //   if (!chartArea) {
        //     // This case happens on initial chart render
        //     return null;
        //   }

        //   const gradient = ctx.createLinearGradient(
        //     10,
        //     chartArea.top,
        //     10,
        //     chartArea.bottom
        //   );
        //   gradient.addColorStop(0, "#28B0E180");
        //   gradient.addColorStop(0.7, "#28B0E180");
        //   gradient.addColorStop(1, "rgba(255, 255, 255, 0.0001)");

        //   return gradient;
        // },
        tension: 0.8,
      },
    ],
  };

  // Define the options for the chart
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    radius: 0,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      title: {
        display: false, // Hide the title
      },
    },
    scales: {
      x: {
        display: false, // Hide the x-axis
      },
      y: {
        display: false, // Hide the y-axis
      },
    },
  };

  return (
    <div style={{ height: height }}>
      {/* style={{ width: "100%", height: "100%" }} */}
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
