import { Button } from "@material-ui/core";
import { useState, useEffect } from "react";

const Title = ({ downloadSheet, selectedRecords }) => {
  const [sheetStats, setSheetStats] = useState({
    totalRides: 0,
    totalAmount: 0,
  });

  function findTotal(records) {
    const newStats = {
      totalAmount: 0,
      totalRides: 0,
    };
    records.map((item) => {
      if (!item?.tableData?.disabled && item.tableData.checked) {
        newStats.totalAmount = newStats.totalAmount + parseFloat(item.amount);
        newStats.totalRides = newStats.totalRides + parseInt(item.totalRide);
      }
    });
    setSheetStats({ ...newStats });
  }

  useEffect(() => {
    findTotal(selectedRecords);
  }, [selectedRecords]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ fontSize: "1.3rem" }}>PAYOUTS</div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: "10px" }}
          onClick={downloadSheet}
          disabled={
            [...selectedRecords].findIndex(
              (item) => item?.tableData?.checked
            ) == -1
              ? true
              : false
          }
        >
          Download Sheet
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        {sheetStats.totalAmount != 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              color: "#0000008A",
            }}
          >
            Total amount:
            <span
              style={{
                fontWeight: "500",
                color: "#0000008A",
                marginLeft: "0.3rem",
                fontSize: "0.875rem",
              }}
            >
              €{sheetStats?.totalAmount && sheetStats?.totalAmount.toFixed(2)}
            </span>
          </div>
        )}
        {sheetStats.totalRides != 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "0.5rem",
              fontWeight: "bold",
              color: "#0000008A",
            }}
          >
            No Of Rides:
            <span
              style={{
                fontWeight: "500",
                color: "#0000008A",
                fontFamily: "Montserrat,sans-serif",
                marginLeft: "0.3rem",
              }}
            >
              {sheetStats.totalRides}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Title;
