import React, { useState, useEffect, ChangeEventHandler, CSSProperties } from 'react';
import { CloudUpload } from "@material-ui/icons";
import { makeStyles, Theme } from "@material-ui/core/styles";

type Props = {
  label?: string | null | undefined,
  accept: string,
  callback?: any,
  style?: CSSProperties | undefined,
  cancel?: ChangeEventHandler<HTMLInputElement> | undefined | Function | null,
  disabled?: boolean,
  setSubmitDisabled?: Function,
  hasFiles?: any[] | null | undefined
};

const defaultProps = {
  label: 'Click here to upload a file',
  accept: '*',
  disabled: false
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    padding: '2rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '0.5rem',
    cursor: 'pointer',
    "&:hover": {
      background: "#e7e7e7",
    },
  },
  iconColor: {
    color: theme.palette.secondary.main,
  },
  previewImage: {
    width: '100%',
    maxWidth: 275
  }
}));

const FileUploader = ({ label, accept, callback, cancel, style, disabled, setSubmitDisabled, hasFiles }: Props) => {
  const [fileName, setFileName] = useState('');
  const [files, setFiles] = useState<any>([]);
  const classes = useStyles();

  const handleChange = (e: any) => {
    if (e?.target?.files[0]) {
      setFileName(e?.target?.files[0].name);
    }
  }

  useEffect(() => {
    if (hasFiles) {
      setFiles(hasFiles);
      setFileName(hasFiles[0])
    }
  }, [hasFiles && hasFiles.length && hasFiles[0]]);

  useEffect(() => {
    if (fileName === 'null') {
      setFileName('')
    }
  }, [fileName]);

  return (
    <div style={style} className='mb-5'>
      <label
        style={{
          border: `1px dashed ${disabled ? "#e3e0e0" : "#2aabd5"}`,
          color: `${disabled ? "#e3e0e0" : ""}`
        }}
        htmlFor="formFile"
        className={classes.label}
      >
        {!fileName && <CloudUpload className={classes.iconColor} fontSize={'large'} />}
        {!fileName && <p>{label}</p>}
        {fileName && <>
          <img
            src={typeof files[0] === 'string' ? files[0] : URL.createObjectURL(files[0])}
            className={classes.previewImage}
            alt={'logo'}
          />
          <p>{fileName}</p>
        </>}
      </label>
      <input
        hidden={true}
        name='upload'
        onChange={e => {
          setFiles(e?.target?.files)
          handleChange(e)
          if (callback) {
            callback(e?.target?.files)
          }
        }}
        className="form-control"
        type="file"
        id="formFile"
        accept={accept}
        disabled={disabled}
      />
    </div>
  );
};

FileUploader.defaultProps = defaultProps;

export default FileUploader;
