import React from "react";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import ReactDOM from "react-dom";
import "./index.css";
import "./assets/style/global.css";
import "./assets/style/common.css";
// import "./assets/style/style.css";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import Main from "./containers/main";
import reportWebVitals from "./reportWebVitals";
import Router from "./router";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
const store = configureStore();
const persistor = persistStore(store);
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={<div>Loading....</div>} persistor={persistor}>
          <BrowserRouter>
            <ToastContainer position="top-right" />
            <Router />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
