import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Typography } from "@material-ui/core";
import vehicles from "../../assets/VehicleSvgs";

const useStyles = makeStyles((theme) => ({
  toggleContainer: {
    marginBottom: theme.spacing(2),
  },

  buttonGroup: {
    backgroundColor: "green",
    width: 200,
  },

  text_car_image: {
    // marginTop: 8,
    marginTop: 11,
    fontSize: 12,
    fontWeight:400,
    // color: "black",
    color: "#0000007D",

  },
  new_cars_text: {
    maxWidth: 74,
    // marginLeft: 100
  },
  new_cars: {
    width: 48,
    // marginLeft: -57
  },
  opaque: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    opacity: 1,
    alignItems:"center",
    justifyContent:"center"
  },
  transparent: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    opacity: 0.3,
    alignItems:"center",
    justifyContent:"center"
  },
}));

export default function ToggleButtonNotEmpty({ onClick, value, noOfPeople }) {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={6}>
        <div>
          <ToggleButtonGroup
            value={value}
            exclusive
            onChange={(e, value) => value !== null && onClick(value)}
          >
          {/* Electric */}
          <ToggleButton
          
              disabled={noOfPeople > 4}
              value="ELECTRIC"
              aria-label="electric"
              style={{width:"100px" , height:87}}
            >
              <div
           
                className={
                  noOfPeople > 4 ? classes.transparent : classes.opaque
                }
              >
                {vehicles("ELECTRIC", "#4b4b4b", "48","21")}
                <Typography className={classes.text_car_image}>
                  Electric
                </Typography>
              </div>
            </ToggleButton>
          {/* Standard */}
            <ToggleButton
              disabled={noOfPeople > 4}
              value="STANDARD"
              aria-label="standard"
              style={{width:"100px",height:87}}
              
            >
              <div
                className={
                  noOfPeople > 4 ? classes.transparent : classes.opaque
                }
              >
                {vehicles("STANDARD", "#4b4b4b", "48","21")}
                <Typography className={classes.text_car_image}>
                  Standard
                </Typography>
              </div>
            </ToggleButton>
          {/* Executive */}

            <ToggleButton
              disabled={noOfPeople > 4}
              value="EXECUTIVE"
              aria-label="executive"
              style={{width:"100px",height:87}}
            >
              <div
                className={
                  noOfPeople > 4 ? classes.transparent : classes.opaque
                }
              >
                {vehicles("EXECUTIVE", "#4b4b4b", "48","21")}
                <Typography className={classes.text_car_image}>
                  Executive
                </Typography>
              </div>
            </ToggleButton>
            {/* Carrier */}
            <ToggleButton
              disabled={noOfPeople > 5}
              value="CARRIER"
              aria-label="carrier"
              style={{width:"100px",height:87}}
            >
              <div
                className={
                  noOfPeople > 5 ? classes.transparent : classes.opaque
                }
              >
                {vehicles("CARRIER", "#4b4b4b", "48","21")}
                <Typography className={classes.text_car_image}>
                  Carrier
                </Typography>
              </div>
            </ToggleButton>
            {/* Luxury */}
            <ToggleButton
              disabled={noOfPeople > 4}
              value="LUXURY"
              aria-label="Luxury"
              style={{width:"100px",height:87}}
            >
              <div
                className={
                  noOfPeople > 4 ? classes.transparent : classes.opaque
                }
              >
                {vehicles("LUXURY", "#4b4b4b", "48","21")}
                <Typography className={classes.text_car_image}>
                  Luxury
                </Typography>
              </div>
            </ToggleButton>
           {/* Exe Carrier  */}
           <ToggleButton
              disabled={noOfPeople > 7}
              value="EXE_CARRIER"
              aria-label="execarrier"
              style={{width:"100px",height:87}}
            >
              <div
                className={
                  noOfPeople > 7 ? classes.transparent : classes.opaque
                }
              >
                {vehicles("EXE_CARRIER", "#4b4b4b", "48","21")}
                <Typography className={classes.text_car_image}>
                  Exe Carrier
                </Typography>
              </div>
            </ToggleButton>
            
          
          
          
          </ToggleButtonGroup>
        </div>
        <div className={classes.toggleContainer}>
        
          <ToggleButtonGroup
            value={value}
            exclusive
            onChange={(e, value) => value !== null && onClick(value)}
          >
            <ToggleButton
              disabled={noOfPeople > 16}
              value="MINI_BUS"
              aria-label="minibus"
              style={{width:"100px",height:87}}
            >
              <div
                className={
                  noOfPeople > 16 ? classes.transparent : classes.opaque
                }
              >
                {vehicles("MINI_BUS", "#4b4b4b", "48","21")}
                <Typography className={classes.text_car_image}>
                  MiniBus
                </Typography>
              </div>
            </ToggleButton>
           
            <ToggleButton value="LARGE_CARRIER" aria-label="largecarrier"    style={{width:"100px",height:87}}>
              <div className={classes.opaque}>
                {vehicles("LARGE_CARRIER", "#4b4b4b", "48","21")}
                <Typography className={classes.text_car_image}>
                  Lrg Carrier
                </Typography>
              </div>
            </ToggleButton>

            <ToggleButton
              disabled={noOfPeople > 4}
              value="ELECTRIC_LUXURY"
              aria-label="Electric Luxury"
              style={{width:"100px",height:87}}
            >
              <div
                className={
                  noOfPeople > 4
                    ? `${classes.transparent} ${classes.new_cars}`
                    : `${classes.opaque} ${classes.new_cars}`
                }
              >
                {vehicles("ELECTRIC_LUXURY", "#4b4b4b", "48","21")}
                <Typography
                  className={classes.text_car_image}
                  style={{
                    maxWidth: 74,
                    marginTop:0
                  }}
                >
                  Electric Luxury
                </Typography>
              </div>
            </ToggleButton>

            <ToggleButton
              disabled={noOfPeople > 5}
              value="ELECTRIC_PEOPLE_CARRIER"
              aria-label="Electric People Carrier"
              // style={{width:"100px"}}
              style={{width:"100px",height:87,paddingLeft:0,paddingRight:0}}
            >
              <div
                className={
                  noOfPeople > 5
                    ? `${classes.transparent} ${classes.new_cars}`
                    : `${classes.opaque} ${classes.new_cars}`
                }
              >
                {vehicles("ELECTRIC_PEOPLE_CARRIER", "#4b4b4b", "48","21")}
                <Typography
                  className={classes.text_car_image}
                  style={{
                    // maxWidth: 100,
                    marginTop:-4,
                    lineHeight:1.2
                  }}
                >
                  Electric People Carrier
                </Typography>
              </div>
            </ToggleButton>
            <ToggleButton
              disabled={noOfPeople > 7}
              value="ELECTRIC_EXE_PEOPLE_CARRIER"
              aria-label="Electric Exe People Carrier"
              // style={{width:"100px"}}
              style={{width:"100px",height:87}}
            >
              <div
                className={
                  noOfPeople > 7
                    ? `${classes.transparent} ${classes.new_cars}`
                    : `${classes.opaque} ${classes.new_cars}`
                }
              >
                {vehicles("ELECTRIC_EXE_PEOPLE_CARRIER", "#4b4b4b", "48","21")}
                <Typography
                  className={classes.text_car_image}
                  style={{
                    maxWidth: 74,
                    marginTop:-6,
                    lineHeight:1.2
                  }}
                >
                  Electric Exe People Carrier
                </Typography>
              </div>
            </ToggleButton>

            <ToggleButton
              disabled={noOfPeople > 8}
              value="ELECTRIC_LARGE_PEOPLE_CARRIER"
              aria-label="Electric Lrg People Carrier"
              // style={{width:"100px"}}
              style={{width:"100px",height:87}}
            >
              <div
                className={
                  noOfPeople > 8
                    ? `${classes.transparent} ${classes.new_cars}`
                    : `${classes.opaque} ${classes.new_cars}`
                }
              >
                {vehicles("ELECTRIC_LARGE_PEOPLE_CARRIER", "#4b4b4b", "48","21")}
                <Typography
                  className={classes.text_car_image}
                  style={{
                    maxWidth: 74,
                    marginTop:-6,
                    lineHeight:1.2
                  }}
                >
                  Electric Lrg People Carrier
                </Typography>
              </div>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Grid>
    </Grid>
  );
}
