import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import mobioApi from "../services";

const logger = createLogger();

/* enhance redux: add redux devtools */
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* pass api to thunk.withExtraArgument */
const middleware = [thunk.withExtraArgument(mobioApi), logger];

const configureStore = (): any => {
  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
  );
};

export default configureStore;
