import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useNavigate } from "react-router-dom";
import { ChevronRight, KeyboardBackspace } from "@material-ui/icons";

const Breadcrumb = ({ paths, secondary = false,backType="manual" }) => {
  const navigate = useNavigate();

  const handlePathClick = (path) => {
    navigate(path.url);
  };

  return (
    <div className="breadcrumb">
      {!secondary && (
        <KeyboardBackspace
          style={{
            fontSize: 26,
            marginLeft: 6,
            cursor: "pointer",
            marginRight: 10,
            verticalAlign: "middle",
          }}
          onClick={() => {
            if(backType === "auto"){
              navigate(-1)
            }else{
              handlePathClick(paths[0])
            }
           }}
        />
      )}

      {paths.map((path, index) => (
        <React.Fragment key={index}>
          {index !== paths.length - 1 ? (
            <span
              style={{ cursor: "pointer" }}
              className={
                index === paths.length - 1
                  ? `breadcrumb-current font-600 ${
                      secondary ? "font-14 " : " font-16"
                    }`
                  : `breadcrumb-link-blue font-600 ${
                      secondary ? "font-14 " : " font-16"
                    }`
              }
              onClick={() => handlePathClick(path)}
            >
              {path.name}{" "}
              <span>
                <ChevronRight
                  style={{
                    color: index === paths.length - 2 ? "#000000" : "#29AEE6",
                    verticalAlign: "middle",
                    width: "0.9em",
                    height: "0.9em",
                  }}
                />
              </span>{" "}
            </span>
          ) : (
            <span
              className={`breadcrumb-current-black  ${
                secondary ? "font-14 font-600" : " font-16"
              } `}
            >
              {path.name}
            </span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
