import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import { ErrorMessage, Field, Form, Formik } from "formik";
import GoogleMapReact from "google-map-react";
import mobioApi from "../../../services";
import { connect } from "react-redux";
import { toastMessage } from "../../../helpers/toastMessage";
import Breadcrumb from "../../../components/BreadCrumb";
import TimePickerField from "../../../components/commons/TimePickerField";
import {
  convertTimeStringToDate,
  getTimeFormat24Hour,
} from "../../../helpers/getTimeFormat";
import AreaOperationDetailImage from "../../../assets/icons/area_of_opertation_detail.svg";
import InfoIcon from "../../../assets/icons/ph_info.svg";
import { areaOfOperationsValidationSchema } from "../../../validations/rateModuleValidation";
import {
  daysOptions,
  hoursOptions,
  weeksOptions,
} from "../../../helpers/generateNumber";

const infoDataList = [
  {
    title: "What is latitude and longitude?",
    description:
      "Latitude and longitude are a series of numbers that point to an exact location on a map.",
  },
  {
    title: "What is a central zone?",
    description:
      "This is the main area you cover. You'll have capacity to take all trips that start and end within this radius.",
  },
  {
    title: "How far can you go from the central zone?",
    description:
      "We recommend setting a distance you can cover without harming quality of service.",
  },
];

const Index = ({ token }) => {
  const navigate = useNavigate();
  const locationState = useLocation();
  const [mapInstance, setMapInstance] = useState(null);
  const [mapsInstance, setMapsInstance] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 52.367925,
    lng: 4.846423,
  });
  // const [circleRef, setCircleRef] = useState(null); // Add circleRef to hold the current circle
  const { integrationType, integrationId, country, countryId, city, cityId } =
    useParams();
  const formik = useRef();
  const mapRef = useRef();
  const circleRef = useRef(null);

  const zoom = 11;
  const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const airportStatus = locationState?.state?.isActive;
      const payload = {
        name: values?.name,
        IATA: values?.IATA,
        latitude: values?.latitude,
        longitude: values?.longitude,
        centralZone: values?.centralZone,
        farthestCentralZone: values?.farthestCentralZone,
        // leadTime: getTimeFormat24Hour(values?.leadTime),
        leadTime: values.leadTime,
        leadDays: values.leadDays,
        leadWeeks: values.leadWeeks,

        VAT: values?.VAT,
        cityId: cityId,
        isActive:
          airportStatus === true || airportStatus === false
            ? airportStatus
            : true,
      };
      const api = mobioApi(token);

      const response = await api.addNewAirport(payload);

      toastMessage(
        "success",
        response?.data?.msg || "Location Added Successfully"
      );
      // navigate(`/dashboard/integration/rates/${tabData?.integrationType}`);
      navigate(-1);
    } catch (error) {
      console.log("Error", error);
      toastMessage("error", error.response?.data?.msg);
    }
  };

  // useEffect(() => {
  //   if (mapInstance && mapsInstance && circleRef) {
  //     circleRef.setMap(null); // Remove the old circle
  //     const newCircle = new mapsInstance.Circle({
  //       strokeColor: "#FF0000",
  //       strokeOpacity: 0.8,
  //       strokeWeight: 2,
  //       fillColor: "#FF0000",
  //       fillOpacity: 0.35,
  //       map: mapInstance,
  //       center: {
  //         lat: formik.current.values.latitude,
  //         lng: formik.current.values.longitude,
  //       },
  //       radius: formik.current.values.centralZone * 100,
  //     });

  //     setCircleRef(newCircle); // Update circleRef with the new circle
  //   }
  // }, [formik.current?.values.centralZone]); // Dependency on centralZone

  const handleApiLoaded = (map, maps, radius, lat, lng) => {
    setMapInstance(map);
    setMapsInstance(maps);

    // If circle already exists, update its properties
    if (circleRef?.current) {
      circleRef.current.setCenter({ lat, lng });
      circleRef.current.setRadius(radius * 1000);
    } else {
      // Create the circle if it doesn't exist
      circleRef.current = new maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map,
        center: { lat, lng },
        radius: radius * 1000,
      });
    }
  };
  const paths = [
    {
      name: `Area of Operation`,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },
  ];
  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },
    {
      name: "Area of Operation",
      url: "/dashboard/integration/rates/city",
    },
  ];

  const getAirportDetail = async (id) => {
    try {
      const api = mobioApi(token);
      const response = await api.getAirportDetail(id);
      const airportData = response?.data?.data;
      if (airportData) {
        if (formik?.current) {
          formik?.current?.setFieldValue("name", airportData?.name);
          formik?.current?.setFieldValue("IATA", airportData?.iata);
          formik?.current?.setFieldValue(
            "centralZone",
            airportData?.centralZone
          );
          formik?.current?.setFieldValue("longitude", airportData?.longitude);
          formik?.current?.setFieldValue("latitude", airportData?.latitude);
          formik?.current?.setFieldValue(
            "leadTime",
            airportData?.leadTime?.toString()
          );
          formik?.current?.setFieldValue(
            "leadDays",
            airportData?.leadDays?.toString()
          );
          formik?.current?.setFieldValue(
            "leadWeeks",
            airportData?.leadWeeks?.toString()
          );
          // formik?.current?.setFieldValue(
          //   "leadTime",
          //   airportData?.leadTime
          //     ? convertTimeStringToDate(airportData?.leadTime)
          //     : convertTimeStringToDate("00:00:00")
          // );
          formik?.current?.setFieldValue(
            "supportNumber",
            airportData?.supportNumber
          );
          formik?.current?.setFieldValue(
            "farthestCentralZone",
            airportData?.farthestCentralZone
          );
          formik?.current?.setFieldValue("VAT", airportData?.VAT);
        }

        mapRef.current = mapInstance;
        handleApiLoaded(
          mapInstance,
          mapsInstance,
          parseFloat(airportData?.centralZone),
          airportData.latitude,
          airportData.longitude
        );
        setMapCenter({
          lat: airportData.latitude,
          lng: airportData.longitude,
        });
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (locationState?.state?.airportId) {
      getAirportDetail(locationState?.state?.airportId);
    }
  }, []);

  useEffect(() => {
    if (mapInstance?.zoom) {
      mapRef.current = mapInstance;
      mapRef?.current?.setCenter({
        lat: formik?.current?.values?.latitude,
        lng: formik?.current?.values?.longitude,
      });
    }
  }, [mapInstance]);

  return (
    <>
      <div className="mt-18 flex justify-content-between">
        <div>
          <Breadcrumb paths={paths} />
          <div className="mt-12 ml-6">
            <Breadcrumb paths={secondaryPaths} secondary={true} />
          </div>
        </div>
      </div>
      <div>
        <div className="flex gap-16">
          {/* Form detail */}
          <div className="width-70">
            <div>
              <Formik
                innerRef={formik}
                initialValues={{
                  name: locationState?.state?.name || "",
                  IATA: locationState?.state?.iata || "",
                  centralZone: locationState?.state?.centralZone || 60,
                  longitude: locationState?.state?.longitude || 4.846423,
                  latitude: locationState?.state?.latitude || 52.367925,
                  // leadTime: locationState.state?.leadTime
                  //   ? convertTimeStringToDate(locationState.state.leadTime)
                  //   : convertTimeStringToDate("00:00:00"),
                  farthestCentralZone:
                    locationState?.state?.farthestCentralZone || "",
                  VAT: locationState?.state?.VAT || "",

                  leadTime: locationState?.state?.leadTime?.toString() || "0",
                  leadDays: locationState?.state?.leadDays?.toString() || "0",
                  leadWeeks: locationState?.state?.leadWeeks?.toString() || "0",
                }}
                validationSchema={areaOfOperationsValidationSchema}
                onSubmit={handleFormSubmit}
              >
                {({ values, setFieldValue, isSubmitting, handleChange }) => (
                  <Form>
                    {/* map */}
                    <div className="bg-white area-operation-spacing border-radius-8 mt-26">
                      <div>
                        <h2 className="text-black font-16 font-500 m-0">
                          How far is your Central Zone?
                        </h2>
                        <p className="text-gray font-13 font-500 m-0 mb-14">
                          How far is your Central Zone?
                        </p>
                      </div>
                      <div className="edit-location-zone-map-section">
                        {/* form */}
                        <div className="bg-white main-section-spacing border-radius-8 zone-map-section-form">
                          <div className="flex gap-16 input_field edit-location-field">
                            <div>
                              <label htmlFor="longitude">Longitude</label>
                              <Field
                                type="text"
                                placeholder="Latitude"
                                id={`longitude`}
                                name={`longitude`}
                              />
                              <ErrorMessage
                                name={`longitude`}
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div>
                              <label htmlFor="latitude">Latitude</label>
                              <Field
                                type="text"
                                placeholder="Latitude"
                                id={`latitude`}
                                name={`latitude`}
                              />
                              <ErrorMessage
                                name={`latitude`}
                                component="div"
                                className="text-danger"
                              />
                            </div>
                            <div>
                              <label htmlFor="centralZone">
                                How far is your Central Zone?
                              </label>
                              <div className="far_central_zone_field">
                                <Field
                                  type="number"
                                  id={`centralZone`}
                                  name={`centralZone`}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "centralZone",
                                      e.target.value
                                    );
                                    handleApiLoaded(
                                      mapInstance,
                                      mapsInstance,
                                      e.target.value,
                                      values.latitude,
                                      values.longitude
                                    );
                                    handleChange(e);
                                  }}
                                />
                                <ErrorMessage
                                  name={`centralZone`}
                                  component="div"
                                  className="text-danger"
                                />
                                <div className="far_central_zone_field_post_fix">
                                  <p>KM</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ height: "487px", width: "100%" }}>
                          <GoogleMapReact
                            // ref={mapRef}
                            bootstrapURLKeys={{ key: apiKey }}
                            // defaultCenter={{
                            //   lat: values.latitude,
                            //   lng: values.longitude,
                            // }}
                            center={mapCenter}
                            defaultZoom={zoom}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => {
                              handleApiLoaded(
                                map,
                                maps,
                                values.centralZone,
                                values.latitude,
                                values.longitude
                              );

                              mapRef.current = map;
                              setMapCenter({
                                lat: values.latitude,
                                lng: values.longitude,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* farthestCentralZone Zone */}
                    <div className="bg-white area-operation-spacing border-radius-8 mt-22">
                      <div className=" ">
                        <h2 className="text-black font-16 font-500 m-0">
                          How far will you go out of your central zone?
                        </h2>
                        <p className="text-gray font-13 font-500 m-0 mb-14 mt-10">
                          Furthest distance from coordinates
                        </p>
                        <div className="area-operation-central-zone-field">
                          <div className="flex-1">
                            <Field
                              type="number"
                              id="farthestCentralZone"
                              name="farthestCentralZone"
                              className="h-45"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          </div>
                          <div>
                            <p className="font-15 font-500">KM</p>
                          </div>
                        </div>

                        <ErrorMessage
                          name={`farthestCentralZone`}
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    {/* Lead Time */}
                    <div className="bg-white area-operation-spacing border-radius-8 mt-22">
                      <div>
                        <div className="">
                          <h2 className="text-black font-16 font-500 m-0">
                            How much notice time do you need in advance to
                            fulfil a booking?
                          </h2>
                          <p className="text-gray font-13 font-500 m-0 mb-14 mt-10">
                            Lead time
                          </p>
                          {/* <Field
                            name="leadTime"
                            className="area-operation-lead-time"
                            component={TimePickerField}
                            value={values.leadTime}
                          />

                          <ErrorMessage
                            name={`leadTime`}
                            component="div"
                            className="text-danger"
                          /> */}
                        </div>

                        {/* Lead Time  Hour and Days and Weeks */}
                        <div className="flex gap-24">
                          {/* Hour */}
                          <div className="flex-1">
                            <div className="area-of-operation-lead-time-field">
                              <Field
                                as="select"
                                name="leadTime"
                                onChange={handleChange}
                                value={values.leadTime}
                                style={{ textIndent: "12px" }}
                              >
                                <option value="0" label="0" />
                                {hoursOptions.map((hr) => (
                                  <option key={hr} value={hr}>
                                    {hr}
                                  </option>
                                ))}
                              </Field>
                              <p className="font-15 font-500">Hours</p>
                            </div>
                            <ErrorMessage
                              name={`leadTime`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          {/* Days */}
                          <div className="flex-1">
                            <div className="area-of-operation-lead-time-field">
                              <Field
                                as="select"
                                name="leadDays"
                                onChange={handleChange}
                                value={values.leadDays}
                                style={{ textIndent: "12px" }}
                              >
                                <option value="0" label="0" />
                                {daysOptions.map((day) => (
                                  <option key={day} value={day}>
                                    {day}
                                  </option>
                                ))}
                              </Field>
                              <p className="font-15 font-500">Days</p>
                            </div>
                            <ErrorMessage
                              name={`leadDays`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          {/* Weeks */}
                          <div className="flex-1">
                            <div className="area-of-operation-lead-time-field">
                              <Field
                                as="select"
                                name="leadWeeks"
                                onChange={handleChange}
                                value={values.leadWeeks}
                                style={{ textIndent: "12px" }}
                              >
                                <option value="0" label="0" />
                                {weeksOptions.map((week) => (
                                  <option key={week} value={week}>
                                    {week}
                                  </option>
                                ))}
                              </Field>
                              <p className="font-15 font-500">Weeks</p>
                            </div>
                            <ErrorMessage
                              name={`leadWeeks`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* VAT % */}
                    <div className="bg-white area-operation-spacing border-radius-8 mt-22">
                      <div className=" input_field ">
                        <h2 className="text-black font-16 font-500 m-0">
                          What is the VAT percentage applied to rides at this
                          location?
                        </h2>
                        <p className="text-gray font-13 font-500 m-0 mb-14 mt-10">
                          VAT %
                        </p>

                        <Field
                          type="text"
                          id={`VAT`}
                          name={`VAT`}
                          placeholder="VAT %"
                          className="h-45"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                        <ErrorMessage
                          name={`VAT`}
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    {/* Create Location Submit Button */}
                    <div className="mt-24 flex flex-1 justify-content-between">
                      <div></div>
                      <div>
                        <MOButtonV2
                          type="submit"
                          text="SUBMIT"
                          textColor="#ffffff"
                          radius={4}
                          backgroundColor="#29AEE6"
                          fontSize={10}
                          padding={"12px 33px"}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          {/* Booking Detail */}
          <div className="width-30">
            <div className="bg-white area-operation-spacing border-radius-8 mt-26 ">
              <h2 className="font-16 font-500">
                What is an area of operation?
              </h2>
              <div className="mt-30">
                <img
                  src={AreaOperationDetailImage}
                  alt="AreaOperationDetailImage"
                />
              </div>

              <div className="mt-32">
                {infoDataList?.map((infoData) => {
                  return (
                    <>
                      <div className="flex align-item-start mb-20">
                        <div>
                          <img src={InfoIcon} alt="InfoIcon" />
                        </div>
                        <div className="ml-8">
                          <h3 className="font-12 font-500">{infoData.title}</h3>
                          <p className="font-9 font-400 mt-10">
                            {infoData.description}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Index);
