import BookingCircleIcon from "../../../assets/icons/bookingCircleImage.png";
import BookingMainImage from "../../../assets/icons/bookingName.png";
import WTCircleIcon from "../../../assets/icons/wtcircleicon.png";
import WTMainImage from "../../../assets/icons/wtmainImage.png";
import TripCircleIcon from "../../../assets/icons/tripCircle.png";
import TripMainImage from "../../../assets/icons/tripMain.png";

export const integrationData = [
  {
    id: 1,
    circleIcon: BookingCircleIcon,
    mainImage: BookingMainImage,
    value: "Booking.com",
    connectionStatus: false,
  },
  {
    id: 2,
    circleIcon: WTCircleIcon,
    mainImage: WTMainImage,
    value: "WorldTransfer.com",
    connectionStatus: false,
  },
  {
    id: 3,
    circleIcon: TripCircleIcon,
    mainImage: TripMainImage,
    value: "Trip.com",
    connectionStatus: false,
  },
];
