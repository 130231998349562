import { connect } from "react-redux";
import { useState, useEffect } from "react";
import ThunksReports from "../../../redux/actions/thunks/reports";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import moment from "moment";
import { Button } from "@material-ui/core";
import {Box} from "@material-ui/core";
import {CircularProgress} from "@material-ui/core";
import { saveAs } from 'file-saver';
import * as momentTimeZone from 'moment-timezone';

function TccBookingDetailTable ({rowData, getBookingsByCustomerId,getBookingsByIntegrationId, getReportForOrganization, range}) {
  const [response, setResponse] = useState([]);
  const [averageCp, setAverageCp] = useState(0);
  const [averageDp, setAverageDp] = useState(0);
  const [averagePercentage, setAveragePercentage] = useState(0);
  const [averageResult, setAverageResult] = useState(0);
  const [total, setTotal] = useState(0);
  const [loader, setLoader] = useState(false)
  const id = rowData.id;
  const columns = [
    // { title: "Id", field: "id", editable: "never" },
    { title: "Date", field: "dateTime", editable: "never", type: "dateTime", render: (rowData) => {
        // let offset = new Date().getTimezoneOffset();
        // let currentTime = new Date(rowData.dateTime).getTime()-offset*60*1000;
        // return (moment(currentTime).format("YYYY-MM-DD"))
        return momentTimeZone(rowData.dateTime).tz(rowData.timeZone).format("YYYY-MM-DD")
    } },
    { title: "Time", field: "dateTime", editable: "never", type: "dateTime", render: (rowData) => {
      // let offset = new Date().getTimezoneOffset();
      // let currentTime = new Date(rowData.dateTime).getTime()-offset*60*1000;
      // return (moment(currentTime).format("HH:mm:ss"))
      return momentTimeZone(rowData.dateTime).tz(rowData.timeZone).format("HH:mm:ss")
  } },
    { title: "Booking ID",  editable: "never",field: "refId", type : "refId", render: (rowData)=> {
      return <p>{ rowData.bookingReference? rowData.bookingReference : rowData.refId}</p>
    }},
    { title: "CP", editable: "never",field:"fare", type:"fare", render: (rowData) => {
        return <p>€{rowData.fare ? rowData.fare : '0'}</p>
    }},
    { title: "DP", editable: "never",field:"finalPrice", type:"finalPrice", render: (rowData) => {
        return <p>€{rowData.finalPrice ? rowData.finalPrice : '0'}</p>
    }},
    { title: "Percentage", editable: "never", render : (rowData) => {
        return <p style={ (rowData.fare - rowData.finalPrice) < 0 ? { color:"red"}:{}}>
            { rowData.fare ? (((rowData.fare - rowData.finalPrice)/rowData.fare)*100).toFixed(2) : (((rowData.fare - rowData.finalPrice)/1)*100).toFixed(2)}
        %</p>

    }},
    { title: "Result", field: "companyName", editable: "never", render : (rowData) => {
        return <p style={ (rowData.fare - rowData.finalPrice) < 0 ? { color : "red"}:{}}>€
            {(rowData.fare - rowData.finalPrice).toFixed(2)}
        </p>

    },},
  ];
  useEffect(()=>{
    setResponse([]);
    let startTime = moment(range.startDate).format("YYYY-MM-DD");
    let endTime = moment(range.endDate).format("YYYY-MM-DD");
    if(rowData.role){
      getNumbersOfIntegratinoBookings(id, startTime, endTime);
    }else{
      getNumbersOfBookings(id, startTime, endTime);
    }
      
  },[ id, range])


  const getNumbersOfBookings = async(id, start, end) => {
    let result = await getBookingsByCustomerId(id, start, end);
    if(result.length > 0){
      let totalCP = 0;
      let totalDp = 0;
      let total = 0;
      let resultTotal=0
      for(let i in result){
        totalCP += result[i].fare;
        totalDp += result[i].finalPrice;
        resultTotal += result[i].fare - result[i].finalPrice;
      }
      total = totalCP-totalDp;
      setAverageCp(totalCP/(result.length));
      setAverageDp(totalDp/(result.length));
      setAveragePercentage(totalCP != 0? ((totalCP-totalDp)/totalCP).toFixed(2): ((totalCP-totalDp)/1).toFixed(2));
      setAverageResult(resultTotal/(result.length))
      setTotal(total)
      setResponse(result)
    }else{
    setAverageCp(0);
    setAverageDp(0);
    setAveragePercentage(0);
    setAverageResult(0)
    setTotal(0)
    setResponse(result)
    }
    
   
  }
  const getNumbersOfIntegratinoBookings = async(id, start, end) => {
    let result = await getBookingsByIntegrationId(id, start, end, rowData.companyName);
    console.log("here is", result.length)
    if(result.length > 0){
      let totalCP = 0;
      let totalDp = 0;
      let total = 0
      let resultTotal=0
      for(let i in result){
        totalCP += result[i].fare;
        totalDp += result[i].finalPrice;
        resultTotal += result[i].fare - result[i].finalPrice;
      }
      total = totalCP-totalDp;
      setAverageCp(totalCP/(result.length));
      setAverageDp(totalDp/(result.length));
      setAveragePercentage(totalCP != 0? (((totalCP-totalDp)/totalCP)).toFixed(2): ((totalCP-totalDp)/1).toFixed(2));
      setAverageResult(resultTotal/(result.length))
      setTotal(total)
      setResponse(result)
     
    }else{
    setAverageCp(0);
    setAverageDp(0);
    setAveragePercentage(0);
    setAverageResult(0)
    setTotal(0)
    setResponse(result)
    }
    
  }
  const handleDownloadReport = async() => { 
    const zone = new Date().getTimezoneOffset();
    const ttcName=rowData?.companyName;
    setLoader(true) 
      await getReportForOrganization({
        booking: response,
        start: range.startDate,
        end: range.endDate,
        orgData: rowData,
        averageCp: averageCp,
        averageDp: averageDp,
        averagePercentage: averagePercentage, 
        averageResult:averageResult,
        total: total,
        zone : zone
      }).then((response) =>{ 
        
        const pdfName=`${ttcName} Financial Performance Summary.pdf`;
        const url = response.url;
        // let alink1 = document.createElement('a');
        // alink1.href = `${process.env.REACT_APP_API_BASE_URL}/company/report/download?url=${url}`;
        // alink1.download = pdfName;
        // alink1.click();

        saveAs(`${process.env.REACT_APP_API_BASE_URL}/company/report/download?url=${url}`, pdfName);
        setLoader(false)
      });

  }


  return(
    <div>
        <div style={{ display: "flex", flexDirection:"row", marginTop:40, alignItems:"center"}}>
          <div style={{ display: "flex", flexDirection:"row", marginLeft: 100}}>
            <p style={{ marginRight: 20}}>Average Cp: €{averageCp == NaN ? 0 : averageCp.toFixed(2)}</p>
            <p style={{ marginRight: 20}}>Average Dp: €{averageDp == NaN ? 0 : averageDp.toFixed(2)}</p>
            <p style={{ marginRight: 20}}>Average Percentage: {averagePercentage == NaN ? 0 : (averagePercentage*100).toFixed(2)}%</p>
            <p style={{ marginRight: 20}}>Average Result: €{averageResult == NaN ? 0 : averageResult.toFixed(2)}</p>
            <p style={{ marginRight: 20}}>Total: €{total.toFixed(2)}</p>
          </div>
          <Button style={{ border: "solid 1px black", marginRight: 10, marginLeft:"auto"}} onClick={handleDownloadReport}>Download Report</Button>
        </div>
        <MaterialTable
        icons={tableIcons}
        // isLoading={loading}
        columns={columns}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        data={response}
        options={{
          actionsColumnIndex: -1,
          search: false,
          showTitle: false
        }}
      />
      {loader && 
          <Box sx={{ 
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: "rgba(255, 255, 255, 0.3)", 
            zIndex: 9999,
            }}>
            <CircularProgress />
          </Box>
        }
    </div>
  )
}

const mapState = (state) => ({
  range: state.reports
});

const mapDispatch = {
  getBookingsByCustomerId: ThunksReports.getBookingsByCustomerId,
  getBookingsByIntegrationId: ThunksReports.getBookingsByIntegrationId,
  getReportForOrganization: ThunksReports.getReportForOrganization,

};

export default connect(mapState, mapDispatch)(TccBookingDetailTable);