export type ReportStates = {
    startDate: String;
    endDate: String;
  };
  
  const reportStates: ReportStates = {
    startDate: "",
    endDate: "",
  };
  
export default reportStates;