import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CreatebookingModal from "./CreateBookingModal";
import Bookings from "../../../../redux/actions/local/bookings";
import { connect } from "react-redux";

function AddBooking({ from, to, driver, clientId, loadData, initalLoad, updateSelectedRow, updateField}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    updateField("dateTime", new Date());
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  

  return (
    <div>
      <Button
        style={{
          borderRadius: 8,
          backgroundColor: "#2AABD5",
          color: "#FFFFFF",
          height: 40,
          textTransform: "none",
        }}
        variant="contained"
        onClick={handleClickOpen}
      >
        Add Booking
      </Button>
      <CreatebookingModal open={open} onClose={handleClose} from={from} to={to} driver={driver} clientId={clientId} loadData={loadData} initalLoad={initalLoad} updateSelectedRow={updateSelectedRow}/>
    </div>
  );
}

const mapDispatch = {
  updateField: Bookings.field.update,
};

export default connect(null, mapDispatch)(AddBooking);
