import Local from "../../local";

export type ThunksOrgUsers = {
  getAllAuthorizations: () => any;
  UpdateUserAuthorizations: (data: string) => any;
  createOrgUser: (data: string) => any;
  orgUsers: () => any;
  deleteOrgUser: (data: any) => any;
  updateOrgUser: (data: any) => any;
  getAuthorities: (data: any) => any;
  blockUser: (data: any, blockCheck: any) => any;
  updateUserPassword: (data: any) => any;
};

const ThunksUsers: ThunksOrgUsers = {
  getAllAuthorizations: () => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .getAllAuthorities()
      .then((res: any) => {
        dispatch(Local.GetAuthorizations.response(res.data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = error;
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          }
          dispatch(Local.CreateOrgUser.error("Request Failed", errorMsg));
          alert(errorMsg);
        }
      });
  },
  createOrgUser: (data) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .createOrganisationUser(data)
      .then((res: any) => {
        console.log("resposnse create user", res);
        dispatch(Local.CreateOrgUser.response(res.data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = "Error Creating User";
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          }
          dispatch(Local.CreateOrgUser.error("Request Failed", errorMsg));
        }
      });
  },
  orgUsers: () => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .getOrgUsers()
      .then((res: any) => {
        dispatch(Local.getAllUsers.response(res.data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = error;
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          }
          dispatch(Local.getAllUsers.error("Request Failed", errorMsg));
          alert(errorMsg);
        }
      });
  },
  deleteOrgUser: (data) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .deleteUser(data)
      .then((res: any) => {
        dispatch(Local.deleteUser.response(res.data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = "Error Deleting User";
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          }
          dispatch(Local.deleteUser.error("Request Failed", errorMsg));
        }
      });
  },
  UpdateUserAuthorizations:
    (data) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .updateUserAuthorizations(data)
        .then((res: any) => {
          dispatch(Local.UpdateOrgUser.response(res.data));
        })
        .catch((error: any) => {
          console.log("error", error);

          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = "Cannot Update User";
            if (error?.response?.data?.message) {
              errorMsg = error?.response?.data?.message;
            }
            dispatch(Local.UpdateOrgUser.error("Request Failed", errorMsg));
          }
        });
    },
  updateOrgUser: (data) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .updateOrgUser(data)
      .then((res: any) => {
        dispatch(Local.UpdateCompleteUser.response(res.data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = "Cannot Update User";
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          }
          dispatch(Local.UpdateCompleteUser.error("Request Failed", errorMsg));
        }
      });
  },
  getAuthorities: (data) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .getAuthorities({ id: data })
      .then((res: any) => {
        dispatch(Local.getUserAuthorizations.response(res.data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = "Authorities Not Found";
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          }
          dispatch(
            Local.getUserAuthorizations.error("Request Failed", errorMsg)
          );
        }
      });
  },
  blockUser:
    (data, blockCheck) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .blockUser({ id: data, isBlocked: blockCheck })
        .then((res: any) => {})
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = "Authorities Not Found";
            if (error?.response?.data?.message) {
              errorMsg = error?.response?.data?.message;
            }
          }
        });
    },
  updateUserPassword:
    (data) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .updatePass(data)
        .then((res: any) => {
          dispatch(Local.UpdatePasswordError.response(res.data));
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = "Password Update Error";
            if (error?.response?.data?.message) {
              errorMsg = error?.response?.data?.message;
            }
            dispatch(
              Local.UpdatePasswordError.error("Request Failed", errorMsg)
            );
          }
        });
    },
};

export default ThunksUsers;
