export const DIALOG = {
  OPEN_DIALOG: "ACTION.OPEN_DIALOG",
  CLOSE_DIALOG: "ACTION.CLOSE_DIALOG",
  SELECT_USER: "ACTION.SELECT_USER",
};

export const ORG_USER_API = {
  SET_REQUEST: "ACTION.ORG_USER.SET_REQUEST",
  SET_RESPONSE: "ACTION.ORG_USER.SET_RESPONSE",
  SET_ERROR: "ACTION.ORG_USER.SET_ERROR",
  SET_RETRY: "ACTION.ORG_USER.SET_RETRY",
  RESET: "ACTION.ORG_USER.RESET",
};
export const ORG_AUTHORIZATIONS = {
  SET_REQUEST: "ACTION.ORG_AUTHORIZATIONS.SET_REQUEST",
  SET_RESPONSE: "ACTION.ORG_AUTHORIZATIONS.SET_RESPONSE",
  SET_ERROR: "ACTION.ORG_AUTHORIZATIONS.SET_ERROR",
  SET_RETRY: "ACTION.ORG_AUTHORIZATIONS.SET_RETRY",
  RESET: "ACTION.ORG_AUTHORIZATIONS.RESET",
};

export const ORG_USER_UPDATE_API = {
  SET_REQUEST: "ACTION.ORG_USER_UPDATE_API.SET_REQUEST",
  SET_RESPONSE: "ACTION.ORG_USER_UPDATE_API.SET_RESPONSE",
  SET_ERROR: "ACTION.ORG_USER_UPDATE_API.SET_ERROR",
  SET_RETRY: "ACTION.ORG_USER_UPDATE_API.SET_RETRY",
  RESET: "ACTION.ORG_USER_UPDATE_API.RESET",
};
export const ORG_USER_GET_API = {
  SET_REQUEST: "ACTION.ORG_USER_GET_API.SET_REQUEST",
  SET_RESPONSE: "ACTION.ORG_USER_GET_API.SET_RESPONSE",
  SET_ERROR: "ACTION.ORG_USER_GET_API.SET_ERROR",
  SET_RETRY: "ACTION.ORG_USER_GET_API.SET_RETRY",
  RESET: "ACTION.ORG_USER_GET_API.RESET",
};

export const ORG_USER_DELETE_API = {
  SET_RESPONSE: "ACTION.ORG_USER_DELETE_API.SET_RESPONSE",
  SET_ERROR: "ACTION.ORG_USER_DELETE_API.SET_ERROR",
  RESET: "ACTION.ORG_USER_DELETE_API.RESET",
};
export const USER_UPDATE_API = {
  SET_RESPONSE: "ACTION.USER_UPDATE_API.SET_RESPONSE",
  SET_ERROR: "ACTION.USER_UPDATE_API.SET_ERROR",
  RESET: "ACTION.USER_UPDATE_API.RESET",
};
export const GET_USER_AUTHORIZATIONS = {
  SET_RESPONSE: "ACTION.GET_USER_AUTHORIZATIONS.SET_RESPONSE",
  SET_ERROR: "ACTION.GET_USER_AUTHORIZATIONS.SET_ERROR",
  RESET_ERROR: "ACTION.GET_USER_AUTHORIZATIONS.RESET_ERROR",
};
export const UPDATE_ORG_USER_PASSWORD = {
  SET_RESPONSE: "ACTION.UPDATE_ORG_USER_PASSWORD.SET_RESPONSE",
  SET_ERROR: "ACTION.UPDATE_ORG_USER_PASSWORD.SET_ERROR",
  RESET_ERROR: "ACTION.UPDATE_ORG_USER_PASSWORD.RESET_ERROR",
};
