import { useEffect, useState } from "react";
import { Paper, Button } from "@material-ui/core";
import MaterialTable from "material-table";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import StarIcon from "@mui/icons-material/Star";
import { connect } from "react-redux";
import ArrowRight from "@material-ui/icons/ArrowRight";

import ThunksDispatchers from "../../../redux/actions/thunks/dispatchers";
import ThunksDrivers from "../../../redux/actions/thunks/drivers";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import {
  emptyValidation,
  emailValidation,
  IBANValueValidation,
} from "../../../validations";
import ThunksUsers from "../../../redux/actions/thunks/users";
import { Ghost, GhostFilled } from "../../../assets/icons";
import CompanyDetails from "../../../components/commons/CompanyDetails";
import { exportToExcel } from "react-json-to-excel";
import ThunksNetworks from "../../../redux/actions/thunks/networks";

function Listing({
  getDispatchersListing,
  loading,
  response,
  changeDriversRole,
  error,
  BlockDispatcher,
  UnblockDispatcher,
  updateStar,
  updateGhost,
  getAllNetworks,
  networks,
}) {
  const [nets, setNets] = useState({});
  const [operationMessage, setOperationMessage] = useState("");
  useEffect(() => {
    getDispatchersListing();
    getAllNetworks();
  }, []);

  useEffect(() => {
    if (loading) getDispatchersListing();
  }, [loading]);
  console.log("this is data from dispatcher comp", response);

  const columns = [
    {
      title: "Name",
      field: "displayName",
      validate: (rowData) => {
        return emptyValidation(rowData, "displayName");
      },
    },
    {
      title: "Network Name",
      field: "networkId",
      render: (rowData) => rowData.network?.name,
      lookup: networks?.reduce(
        (acc, item) => ({ ...acc, [item.id]: item.name }),
        {}
      ),
    },
    {
      title: "Full Name",
      field: "fullName",
      validate: (rowData) => {
        return emptyValidation(rowData, "fullName");
      },
    },
    {
      title: "Email",
      field: "email",
      validate: (rowData) => {
        return emailValidation(rowData, "email");
      },
    },
    {
      title: "IBAN",
      field: "IBAN",
      validate: (rowData) => {
        return IBANValueValidation(rowData, "IBAN");
      },
    },
    { title: "Address", field: "address" },
    {
      title: "Phone Number",
      field: "phoneNumber",
      validate: (rowData) => {
        return emptyValidation(rowData, "phoneNumber");
      },
    },
    {
      title: "Status",
      field: "status",
      lookup: { available: "Available", occupied: "Occupied" },
    },
    {
      title: "Role",
      field: "role",
      lookup: { Dispatcher: "Dispatcher", Driver: "Driver" },
    },
    {
      title: "OS",
      field: "operatingSystem",
      editable: "never",
      lookup: { android: "Android", ios: "iOS" },
    },
    {
      title: "",
      field: "block",
      width: "4%",
      editable: "never",
      cellStyle: {
        paddingBottom: 10,
        paddingRight: 0,
      },
      render: (rowData) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {rowData.ghost ? (
                <GhostFilled
                  style={{ marginRight: 20, cursor: "pointer" }}
                  onClick={async () => {
                    await updateGhost(rowData.id, { ghost: false });
                    getDispatchersListing(false);
                  }}
                />
              ) : (
                <Ghost
                  style={{ marginRight: 20, cursor: "pointer" }}
                  onClick={async () => {
                    await updateGhost(rowData.id, { ghost: true });
                    getDispatchersListing(false);
                  }}
                />
              )}
              {rowData.star ? (
                <StarIcon
                  style={{ marginRight: 20, cursor: "pointer" }}
                  onClick={async () => {
                    await updateStar(rowData.id, { star: false });
                    getDispatchersListing(false);
                  }}
                />
              ) : (
                <StarBorderOutlinedIcon
                  style={{ marginRight: 20, cursor: "pointer" }}
                  onClick={async () => {
                    await updateStar(rowData.id, { star: true });
                    getDispatchersListing(false);
                  }}
                />
              )}
              {rowData.block ? (
                <BlockOutlinedIcon
                  style={{ color: "red", marginRight: -20, cursor: "pointer" }}
                  onClick={async () => {
                    await UnblockDispatcher(rowData.id);
                    setOperationMessage("Dispatcher Unblocked");
                  }}
                />
              ) : (
                <BlockOutlinedIcon
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    await BlockDispatcher(rowData.id);
                    setOperationMessage("Dispatcher Blocked");
                  }}
                />
              )}
            </div>
          </>
        );
      },
    },
  ];
  const handleDownload = () => {
    let result = response.map((obj) => {
      const {
        id,
        passwordResetToken,
        passwordTokenExpire,
        isDeleted,
        tableData,
        pushToken,
        networkId,
        network,
        ...rest
      } = obj;

      return { ...rest, "Network Name": network.name };
    });
    exportToExcel(result, "DispatcherLists");
  };

  return (
    <div
      style={{
        width: "full",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <Button
        style={{
          border: "solid 1px black",
          marginTop: "10px",
          width: "fit-content",
          color: "#1976d2",
        }}
        onClick={() => handleDownload()}
      >
        Download Dispatcher List
      </Button>
      <MaterialTable
        icons={tableIcons}
        style={{ minWidth: "100%" }}
        isLoading={loading}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        columns={columns}
        data={response}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                //   setData([...data, newData]);

                resolve();
              }, 1000);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                changeDriversRole(oldData.id, newData);
                let successMessage = "";
                if (oldData.role !== newData.role) {
                  successMessage = "Role has been updated";
                } else {
                  successMessage = "Dispatcher data has been updated";
                }
                setOperationMessage(successMessage);
                const index = oldData.tableData.id;
                resolve();
              }, 1000);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                //   const dataDelete = [...data];
                const index = oldData.tableData.id;
                resolve();
              }, 1000);
            }),
        }}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Dispatchers"
        detailPanel={[
          {
            icon: ArrowRight,
            tooltip: "Show Details",
            render: (rowData) => {
              return <CompanyDetails rowData={rowData} />;
            },
          },
        ]}
      />
      {error.status && <ErrorSnack errorMsg={error.message} />}
      {operationMessage && (
        <ErrorSnack
          reset={() => setOperationMessage("")}
          success
          errorMsg={operationMessage}
        />
      )}
    </div>
  );
}

const mapState = (state) => ({
  response: state.dispatchers.response.data,
  loading: state.dispatchers.loading,
  error: state.dispatchers.error,
  networks: state.networks.response.data,
});

const mapDispatch = {
  getDispatchersListing: ThunksDispatchers.DispatchersListing,
  BlockDispatcher: ThunksDispatchers.BlockDispatcher,
  UnblockDispatcher: ThunksDispatchers.UnblockDispatcher,
  changeDriversRole: ThunksDrivers.Role,
  updateStar: ThunksUsers.updateStar,
  updateGhost: ThunksUsers.updateGhost,
  getAllNetworks: ThunksNetworks.Networks,

  // deleteBrand: ThunksVehiclesListing.DeleteBrand,
};

export default connect(mapState, mapDispatch)(Listing);
