import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, FeatureGroup, Polygon } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { EditControl } from "react-leaflet-draw";
import AddNewPolygon from "./Modal/AddNewPolygon";
import { connect } from "react-redux";
import mobioApi from "../../../services";
import { toastMessage } from "../../../helpers/toastMessage";
import ListIcon from "../../../assets/icons/ic_baseline-list-alt.svg";
import CloseIcon from "../../../assets/icons/close_icon.svg";
import SearchingInput from "../../../components/commons/SearchingInput";
import DeleteIcon from "../../../assets/icons/delete-outline.svg";
import EditIcon from "../../../assets/icons/lucide_edit.svg";
import DeletePolygonModal from "./Modal/DeletePolygonModal";
import FixedRouteRateModal from "../RateManage/Modals/FixedRouteRateModal";
import { useLocation } from "react-router";
import { filterServiceLevel } from "../../../helpers/filterServiceLevel";

// Create a custom control for the map
const createCustomControl = (toggleSideMenu) =>
  L.Control.extend({
    onAdd: function (map) {
      const btn = L.DomUtil.create("button", "custom-control-list-box");
      btn.innerHTML = `<img src="${ListIcon}"/>`;
      btn.style.cursor = "pointer";
      btn.onclick = function () {
        toggleSideMenu((prev) => !prev);
      };

      return btn;
    },
    onRemove: function (map) {
      // Cleanup if needed when the control is removed
    },
  });

const PolygonManageArea = ({
  centerPoint,
  airportId,
  token,
  countryName,
  integrationType,
}) => {
  const locationData = useLocation();
  const [editablePolygonIndex, setEditablePolygonIndex] = useState(null);
  const featureGroupRef = useRef();
  const [polygonData, setPolygonData] = useState([]);
  const [dumpPolygonData, setDumpPolygonData] = useState([]);
  const [isFeatureGroupReady, setIsFeatureGroupReady] = useState(false);
  const [polygonModal, setPolygonModal] = useState(false);
  const [deletePolygonModal, setDeletePolygonModal] = useState(false);
  const [deletePolygonId, setDeletePolygonId] = useState("");
  const [addUpdatePolygon, setAddUpdatePolygon] = useState("");
  const [sideMenuList, setSideMenuList] = useState(false);
  // fixed Rate
  const [fixedRateModalOpen, setFixedRateModalOpen] = useState(false);
  const [serviceLevelsList, setServiceLevelsList] = useState([]);
  const [singleData, setSingleData] = useState(null);

  // Function to handle polygon edit
  // v3 edit
  const _onEdited = (e) => {
    const { layers } = e;
    layers.eachLayer((layer) => {
      const { id } = layer.options;
      const updatedPolygon = layer.toGeoJSON();

      setPolygonData((prevPolygonData) => {
        const matchData = prevPolygonData.filter((pf, index) => index === id);

        setAddUpdatePolygon({
          airportId: airportId,
          newPolygon: updatedPolygon,
          name: matchData[0]?.name,
          id: matchData[0]?.id,
          action: "edit",
        });

        setPolygonModal(true);

        // Re-add the updated polygon to the FeatureGroup
        if (featureGroupRef.current) {
          featureGroupRef.current.removeLayer(layer); // Remove old layer
          const latLngs = updatedPolygon.geometry.coordinates[0].map(
            ([lng, lat]) => L.latLng(lat, lng)
          );
          const newPolygon = L.polygon(latLngs, { id });
          newPolygon.addTo(featureGroupRef.current);
        }

        return prevPolygonData.map((polygon, index) =>
          // index === id ? updatedPolygon : polygon
          index === id ? polygon : polygon
        );
      });
    });
  };

  // Function to handle polygon creation
  const _onCreated = (e) => {
    const { layer } = e;
    const newPolygon = layer.toGeoJSON();
    setAddUpdatePolygon({
      airportId: airportId,
      newPolygon: newPolygon,
    });
    setPolygonModal(true);
  };

  // Function to handle polygon deletion
  const _onDeleted = (e) => {
    const { layers } = e;
    layers.eachLayer((layer) => {
      const { id } = layer.options;
      // setPolygonData((prevData) => prevData.filter((_, index) => index !== id));
      setPolygonData((prevData) => {
        const deletePolygonData = prevData?.filter((_, index) => index === id);
        deletePolygonHandler(deletePolygonData[0]?.id);
        return prevData.filter((_, index) => index !== id);
      });
    });
    setEditablePolygonIndex(null); // Clear editable polygon after deletion
  };

  // Function to handle map ready event
  const _onFeatureGroupReady = (e) => {
    if (!isFeatureGroupReady) {
      featureGroupRef.current = e.target;
      setIsFeatureGroupReady(true);
    }
  };

  useEffect(() => {
    if (featureGroupRef.current) {
      featureGroupRef.current.eachLayer((layer) => {
        featureGroupRef.current.removeLayer(layer); // Remove old layers
      });

      polygonData.forEach((polygon, index) => {
        if (polygon.geometry && polygon.geometry.coordinates) {
          const { coordinates } = polygon.geometry;
          const latLngs = coordinates[0].map(([lng, lat]) =>
            L.latLng(lat, lng)
          );

          const options = {
            color: editablePolygonIndex === index ? "red" : "purple",
          };

          const newPolygon = L.polygon(latLngs, options);
          newPolygon.options.id = index;

          newPolygon.on("click", () => {
            setSingleData({
              iata: locationData?.state["iata"],
              data: polygon,
            });
            setFixedRateModalOpen(true);
            setEditablePolygonIndex(index);
          });

          newPolygon.addTo(featureGroupRef.current);

          const center = newPolygon.getBounds().getCenter();
          const customIcon = L.divIcon({
            html: `<div className="polygon-label">${polygon?.name}</div>`,
            className: "polygon-label",
          });

          const marker = L.marker(center, { icon: customIcon });
          marker.addTo(featureGroupRef.current);
        }
      });
    }
  }, [polygonData, editablePolygonIndex, isFeatureGroupReady]);

  useEffect(() => {
    getPolygonHandler();
    getAllServiceLevels();
  }, []);

  const deletePolygonHandler = async (id) => {
    try {
      const api = mobioApi(token);
      const response = await api.deletePolygon(id);
      toastMessage("success", response?.data.msg);
      setPolygonData(polygonData?.filter((pf) => pf?.id !== id));
    } catch (error) {
      toastMessage("error", error.response.data.msg);
    } finally {
      setDeletePolygonId("");
      setDeletePolygonModal(false);
    }
  };
  const getPolygonHandler = async () => {
    try {
      const api = mobioApi(token);
      // const response = await api.getPolygon(airportId);
      const response = await api.getFixedRate(airportId);
      // response?.data?.result
      if (response?.data?.data?.length > 0) {
        const getPolygonData = response?.data?.data?.map((item) => {
          return {
            id: item?.id,
            name: item?.name,
            type: "Feature",
            properties: {},
            geometry: {
              type: "Polygon",
              coordinates: item?.coordinates,
            },
            airportId: item?.airportId,
            associatedFixedRate: item?.associatedFixedRate,
          };
        });
        setPolygonData(getPolygonData);
        setDumpPolygonData(getPolygonData);
      }
    } catch (error) {
      toastMessage("error", error.response.data.msg);
    }
  };

  const getAllServiceLevels = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getAllServiceLevels();
      // setServiceLevelsList(response?.data?.data);
      const getResponseData = response?.data?.data;
      if (getResponseData?.length > 0) {
        setServiceLevelsList(
          filterServiceLevel(integrationType, getResponseData)
        );
      }
    } catch (error) {
      setServiceLevelsList([]);
    }
  };

  const addFixedRates = async (payload) => {
    try {
      const api = mobioApi(token);
      const response = await api.addFixedRate(payload);
      getPolygonHandler();
      setSingleData(null);
      setFixedRateModalOpen(false);
      toastMessage("success", response?.data?.data?.msg);
    } catch (error) {
      toastMessage("error", error?.response?.data?.data?.msg);
    }
  };
  return (
    <>
      <div className="add-polygon-main-section ">
        {sideMenuList && (
          <>
            <div className="polygon-list-box">
              <div className="flex align-item-start justify-content-between">
                <p className="font-14 font-600">Polygons</p>
                <img
                  src={CloseIcon}
                  onClick={() => {
                    setSideMenuList(!sideMenuList);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <SearchingInput
                className="polygon-list-search"
                crossIcon={true}
                onSearching={(value) => {
                  if (value) {
                    const results = dumpPolygonData.filter((item) =>
                      item.name.toLowerCase().includes(value.toLowerCase())
                    );
                    setPolygonData(results);
                  } else {
                    setPolygonData(dumpPolygonData);
                  }
                }}
              />
              <div
                style={{
                  paddingTop: "10px",
                }}
              >
                {polygonData?.map((item, index) => {
                  return (
                    <>
                      <div className="single-polygon">
                        <div className="flex align-item-center justify-content-between">
                          <p
                            className="font-400 font-13"
                            onClick={() => {
                              setEditablePolygonIndex(index);
                            }}
                            style={{
                              borderBottom: "1px solid black",
                              cursor: "pointer",
                            }}
                          >
                            {item?.name}
                          </p>
                          <div>
                            <img
                              src={DeleteIcon}
                              style={{
                                width: "24px",
                                height: "24px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setDeletePolygonModal(true);
                                setDeletePolygonId(item?.id);
                              }}
                            />
                            <img
                              src={EditIcon}
                              style={{
                                width: "24px",
                                height: "24px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                // setSingleData(item);
                                setSingleData({
                                  iata: locationData?.state["iata"],
                                  data: item,
                                });
                                setFixedRateModalOpen(true);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </>
        )}

        <div className="bg-white main-section-spacin border-radius-8 polygon-section-area flex-1">
          <MapContainer
            center={[centerPoint?.lat, centerPoint.lng]}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: "80vh", zIndex: 8 }}
            whenCreated={(map) => {
              // mapRef.current = map;
              const CustomControl = createCustomControl(setSideMenuList);
              const customControl = new CustomControl({ position: "topright" });
              map.addControl(customControl);
            }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <FeatureGroup ref={featureGroupRef}>
              <EditControl
                position="topright"
                onEdited={_onEdited}
                onCreated={_onCreated}
                onDeleted={_onDeleted}
                onMounted={_onFeatureGroupReady}
                draw={{
                  rectangle: false,
                  circle: false,
                  circlemarker: false,
                  marker: false,
                  polyline: false,
                  polygon: true, // Always allow polygon creation
                }}
                edit={{
                  edit: editablePolygonIndex !== null,
                  // remove: editablePolygonIndex !== null,
                  remove: false,
                }}
              />
            </FeatureGroup>
          </MapContainer>
        </div>
      </div>
      <AddNewPolygon
        modalOpen={polygonModal}
        setModalOpen={setPolygonModal}
        data={addUpdatePolygon}
        setData={setAddUpdatePolygon}
        allPolygons={polygonData}
        setPolygonData={setPolygonData}
        getAllPolygonAPI={getPolygonHandler}
      />
      <DeletePolygonModal
        modalOpen={deletePolygonModal}
        setModalOpen={setDeletePolygonModal}
        apiCall={() => {
          deletePolygonHandler(deletePolygonId);
        }}
      />

      {/* Fixed Rate modal */}
      <FixedRouteRateModal
        modalOpen={fixedRateModalOpen}
        setModalOpen={setFixedRateModalOpen}
        serviceLevelsList={serviceLevelsList}
        singleData={singleData}
        addFixedRateApi={addFixedRates}
        countryName={countryName || null}
      />
    </>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(PolygonManageArea);
