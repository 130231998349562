import React, { forwardRef, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Slide,
  makeStyles,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SubAdminAuthorities from "./SubAdminAuthorities";
import Local from "../../../redux/actions/local";
import SubAdminThunks from "../../../redux/actions/thunks/SubAdmins";

const useStyles = makeStyles({
  redIcon: {
    color: "red",
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SubAdminList({
  message,
  getAllAuthorizations,
  allSubAdmins,
  deleteSubAdmin,
  getAllSubAdmins,
  selectUser,
  reset,
  response,
  resetUpadte,
  myUser,
  blockSubAdmin,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [agree, setAgree] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  useEffect(async () => {
    await getAllAuthorizations();
    setLoading(false);
  }, []);

  const columns = [
    { title: "Email", field: "email", editable: "never", type: "email" },
    { title: "Display Name", field: "displayName", editable: "never" },
    { title: "Full Name", field: "fullName", editable: "never" },
    {
      title: "Status",
      field: "block",
      editable: "never",
      render: (rowData) => (rowData.block ? "Blocked" : "Active"),
    },
  ];
  const handleClose = () => {
    setAgree(false);
  };
  return (
    <>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        isLoading={loading}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        data={allSubAdmins.filter((item) => item.id != myUser.myId)}
        actions={[
          (rowData) => {
            return {
              icon: () =>
                rowData.block ? (
                  <LockIcon className={classes.redIcon} />
                ) : (
                  <LockOpenIcon />
                ),
              tooltip: rowData.block ? "Unblock Sub Admin" : "Block Sub Admin",
              onClick: async (event, rowData) => {
                await blockSubAdmin(rowData.id, rowData.block);
                await getAllSubAdmins();
              },
            };
          },
          {
            icon: () => <EditIcon />,
            tooltip: "Edit Sub Admin",
            onClick: (event, rowData) => {
              selectUser(rowData);
            },
          },
          {
            icon: () => <DeleteIcon />,
            tooltip: "Delete Sub Admin",
            onClick: async (event, rowData) => {
              setDeleteData(rowData);
              setAgree(true);
            },
          },
        ]}
        detailPanel={[
          {
            tooltip: "Authorities Assigned",
            render: (rowData) => {
              return <SubAdminAuthorities data={rowData} />;
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          detailPanelType: "single",
        }}
        title="Sub Admins"
      />
      {!!message?.error && (
        <ErrorSnack errorMsg={message.error} reset={() => reset()} />
      )}
      {!!message?.success && (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"Sub Admin Deleted Successfully"}
        />
      )}
      {!!response?.error && (
        <ErrorSnack errorMsg={response.error} reset={() => resetUpadte()} />
      )}
      {!!response?.success && (
        <ErrorSnack
          reset={() => resetUpadte()}
          success
          errorMsg={"Authorizations Update Successfully"}
        />
      )}
      <Dialog
        open={agree}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button
            onClick={async () => {
              await deleteSubAdmin(deleteData.id);
              await getAllSubAdmins();
              setAgree(false);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapState = (state) => ({
  message: state.subAdmin.deleteUser,
  allSubAdmins: state?.subAdmin?.allSubAdmin || [],
  response: state.subAdmin.updateSubAdmin,
  myUser: state.userInfo.user,
});

const mapDispatch = {
  getAllAuthorizations: SubAdminThunks.getAllAuthorizations,
  deleteSubAdmin: SubAdminThunks.deleteSubAdmin,
  blockSubAdmin: SubAdminThunks.blockSubAdmin,
  getAllSubAdmins: SubAdminThunks.subAdmins,
  selectUser: Local.dialogActions.selectUser,
  reset: Local.deleteSubAdmin.reset,
  resetUpadte: Local.UpdateSubAdminUser.reset,
};

export default connect(mapState, mapDispatch)(SubAdminList);