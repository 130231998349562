import { useEffect, useState } from "react";
import { KeyboardArrowDownOutlined } from "@material-ui/icons";

const VehicleList = ({
  serviceLevelsList = [],
  setSelectServiceLevel = () => {},
  defaultValue,
}) => {
  const [selected, setSelected] = useState(serviceLevelsList[0]);
  const [listStatus, setListStatus] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      const getServiceLevelDetail = serviceLevelsList.filter(
        (sf) => sf.id === defaultValue
      );
      setSelectServiceLevel(defaultValue);
      setSelected(getServiceLevelDetail[0]);
    } else {
      if (serviceLevelsList.length > 0) {
        setSelectServiceLevel(serviceLevelsList[0]?.id);
        setSelected(serviceLevelsList[0]);
      }
    }
  }, [defaultValue]);
  return (
    <>
      <div className="vehicle-list-section">
        <div
          className="selected-vehicle-list"
          onClick={() => {
            setListStatus(!listStatus);
          }}
        >
          <img src={selected?.icon} alt="icon" />
          <h4 className="m-0">{selected?.name}</h4>
          <KeyboardArrowDownOutlined />
        </div>
        {listStatus && (
          <>
            <div className="list-of-services">
              <ul>
                {serviceLevelsList?.map((list) => {
                  return (
                    <li
                      onClick={() => {
                        setSelected(list);
                        setSelectServiceLevel(list.id);
                        setListStatus(false);
                      }}
                      className={`${
                        selected?.id === list.id ? "selected" : "unselected"
                      }`}
                    >
                      <img src={list?.icon} />
                      <p className="m-0">{list?.name}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default VehicleList;
