import { VEHICLES_LISTING, ADD_BRAND } from "../constants/vehicle-listing";

export type VehiclesListingRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
  };
};

export type AddBrandRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
};

export type DeleteBrandRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
  };
};

const VehiclesListing: VehiclesListingRepo = {
  api: {
    request: () => ({
      type: VEHICLES_LISTING.VEHICLES_LISTING_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: VEHICLES_LISTING.VEHICLES_LISTING_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: VEHICLES_LISTING.VEHICLES_LISTING_API.SET_RESPONSE,
      payload: { data },
    }),
  },
};

const AddBrand: AddBrandRepo = {
  api: {
    request: () => ({
      type: ADD_BRAND.ADD_BRAND_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: ADD_BRAND.ADD_BRAND_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: ADD_BRAND.ADD_BRAND_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: ADD_BRAND.ADD_BRAND_API.RESET,
      payload: {},
    }),
  },
};

const DeleteBrand: DeleteBrandRepo = {
  api: {
    request: () => ({
      type: ADD_BRAND.ADD_BRAND_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: ADD_BRAND.ADD_BRAND_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: ADD_BRAND.ADD_BRAND_API.SET_RESPONSE,
      payload: { data },
    }),
  },
};

export { VehiclesListing, AddBrand, DeleteBrand };
