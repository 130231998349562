import { makeStyles } from "@material-ui/core/styles";

import { useEffect, useState } from "react";

import SearchingInput from "../../../components/commons/SearchingInput";
import { useSelector } from "react-redux";
import mobioApi from "../../../services";

import ThirdPartyCard from "../../../components/commons/ThirdPartyCard";
import { integrationData } from "./integrationData";

const Integration = () => {
  const [thirdPartyIntegrationData, setThirdPartyIntegrationData] =
    useState(integrationData);
  const token = useSelector((state) => state.userInfo.token);

  // useEffect(() => {
  //   const fetchIntegrationData = async () => {
  //     const updatedIntegrationData = await Promise.all(
  //       integrationData?.map(async (integration) => {
  //         const api = mobioApi(token);
  //         const res = await api.getBookingIntegration(integration?.value);
  //         return {
  //           ...integration,
  //           connectionStatus: res.data?.bookingIntegration,
  //         };
  //       })
  //     );
  //     setThirdPartyIntegrationData(updatedIntegrationData);
  //   };

  //   fetchIntegrationData();
  // }, [token]);

  return (
    <>
      <div className="mb-20 flex justify-content-end">
        <SearchingInput />
      </div>

      <div className="flex gap-16">
        {thirdPartyIntegrationData?.map((data) => {
          return (
            <ThirdPartyCard
              data={data}
              key={data?.id}
              connectionUrl={`/dashboard/integration/connection/${data?.value}`}
            />
          );
        })}
      </div>

      {/* <div className="flex gap-16">
        {thirdPartyIntegrationData?.map((data) => {
          return (
            <ThirdPartyConnection
              data={data}
              key={data?.id}
              setModalOpen={setModalOpen}
              setCloseConnectionModal={setCloseConnectionModal}
              setConnectionThirdParty={setConnectionThirdParty}
              disableFunctionally={{ newTab: true }}
              newTabUrl={`/dashboard/integration/get-booking/${data?.value}`}
            />
          );
        })}
      </div> */}

      {/* <div
        style={{
          marginTop: "80px",
          paddingTop: "20px",
          borderTop: "1px solid #BDBDBD",
        }}
      >
        <h2 className="font-16 font-400 text-gray mb-16">
          Integration for Bidding Booking
        </h2>
        <IntegrationForBidding />
      </div> */}

      {/* Modal */}
      {/* <APIConnectionModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        connectionThirdParty={connectionThirdParty}
        setConnectionThirdParty={setConnectionThirdParty}
        thirdPartyIntegrationData={thirdPartyIntegrationData}
        setThirdPartyIntegrationData={setThirdPartyIntegrationData}
      />
      <APIDisconnectModal
        modalOpen={closeConnectionModal}
        setModalOpen={setCloseConnectionModal}
        connectionThirdParty={connectionThirdParty}
        setConnectionThirdParty={setConnectionThirdParty}
        thirdPartyIntegrationData={thirdPartyIntegrationData}
        setThirdPartyIntegrationData={setThirdPartyIntegrationData}
      /> */}
    </>
  );
};

export default Integration;
