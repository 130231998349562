const excludeBookingComServiceLevel = [
  "Electric Executive People Carrier",
  "Electric People Carrier",
  "Electric Large People Carrier",

  "Electric Exe People Carrier",
  "Electric Lrg People Carrier",
];

export const filterServiceLevel = (
  thirdParty = "Booking.com",
  serviceLevels = []
) => {
  let excludeServiceLevel = [];
  if (thirdParty === "Booking.com") {
    excludeServiceLevel = excludeBookingComServiceLevel;
  }
  return serviceLevels?.filter(
    (serviceLevel) => !excludeServiceLevel.includes(serviceLevel.name)
  );
};
