import { Box, Button, Typography, makeStyles } from "@material-ui/core"
import ThunksBooking from "../../../redux/actions/thunks/bookings";
import { connect } from "react-redux";
import { CloseRounded } from "@mui/icons-material";
import { useState, forwardRef} from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { HighlightOff } from "@material-ui/icons";
import MOButton from "../../../components/commons/MOButton";

const useStyles = makeStyles({
  redIcon: {
    color: "red",
  },
  customerUserCard: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    padding: "17px 36px",
    borderRadius: 4,
    marginBottom: 10,
    display: "flex",
  },
  modalRoot: {
    ".MuiDialogContent-root": {
      // width:"900px"
    },
    "& .MuiDialog-paperWidthLg": {
      // borderRadius:32,
    },
    "& .MuiDialog-paper": {
      // width: "900px !important"
    },
    "& .MuiDialog-paperWidthSm": {
      width: "690px !important",
    },
    "& .MuiDialogTitle-root": {},
    "& .MuiPaper-rounded": {
      borderRadius: 32,
    },
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CancelBooking ({bookingState, cancelBooking, role, userId,agree,handleClose}) {
  const classes = useStyles();
  // const [agree, setAgree] = useState(false);

  // const handleClickOpen = () => {
  //   setAgree(true);
  // };

  // const handleClose = () => {
  //   setAgree(false);
  // };
  const handleCancelBooking = () =>{
    handleClose();
    cancelBooking(bookingState.id, role, userId)
  }
  return(
    <>
    {/* <Button
        style={{
          minWidth: 40,
          borderRadius: 25,
          marginLeft: 4,
        }}
        onClick={()=>{handleClickOpen()}}
      >
        <CloseRounded/>
      </Button> */}
      <Dialog
        open={agree}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className={classes.modalRoot}
      >
        {/* <DialogTitle>Alert</DialogTitle> */}
        <DialogTitle
          style={{ display: "flex", justifyContent: "center", paddingTop: 50 }}
        >
          <HighlightOff style={{width:40, height:40,color:"#FF3B30"}} />{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"  
          style={{ paddingLeft: 10, paddingRight: 10,display:"flex",alignItems:"center",justifyContent:"center" }}>
          <Typography style={{ fontSize: 24, fontWeight: 700, color:"#170F49" }}>
          Are you sure to cancel this booking?
            </Typography>
           
          </DialogContentText>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 60,
              padding: "0px 90px 60px 90px",
            }}
          >
            <MOButton title="No" icon={false} onClick={handleClose}/>
            <MOButton
              title="Yes"
              type="danger"
              icon={false}
              onClick={handleCancelBooking}
            />
          </Box>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button style={{ color: "red" }} onClick={handleCancelBooking}>Yes</Button>

        
        </DialogActions> */}
      </Dialog>
    </>
    
  )
}
const mapState = (state) => ({
  data: state.bookings.api.response.data,
  loading: state.bookings.api.loading,
  error: state.bookings.api.error,
  role: state.userInfo.user.role,
  userId: state.userInfo.user.id
});

const mapDispatch = {
  cancelBooking: ThunksBooking.CancelBooking
};

export default connect(mapState, mapDispatch)(CancelBooking);