import { Button } from "@material-ui/core";
import { AddOutlined, PlusOne } from "@material-ui/icons";
import React from "react";

const MOButton = (props) => {
  const {
    onClick = () => {},
    title = "Button",
    icon = true,
    disabled = false,
    type = "primary",
    submitType = "button",
  } = props;
  return (
    <Button
      disabled={disabled}
      type={submitType}
      style={{
        borderRadius: 20,
        backgroundColor: "transparent",
        color: type === "danger" ? "#FF3B30" : "#000000",
        height: 40,
        textTransform: "none",
        border: `2px solid ${
          (type === "primary" && "#0059C8") ||
          (type === "secondary" && "#878787") ||
          (type === "danger" && "#FF3B30")
        }`,
        fontSize: 16,
        padding: "12px 20px 12px 18px",
        //   borderImageSource: "radial-gradient(100% 496.42% at 0% 72.38%, #29AEE6 0%, #0059C8 100%)"
      }}
      variant="contained"
      onClick={onClick}
    >
      {icon && (
        <AddOutlined
          style={{
            backgroundColor: "#ffffff",
            fontSize: "10",
            borderRadius: "10px",
            color: "#29AEE6",
            marginRight: "4px",
          }}
        />
      )}

      {title}
    </Button>
  );
};

export default MOButton;
