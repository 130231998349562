import Local from "../../local";

export type ThunksDispatchersListing = {
  DispatchersListing: () => any;
  // getDispatchersByBooking:(id:any)=>any;
  BlockDispatcher: (id: any) => any;
  UnblockDispatcher: (id: any) => any;
};

const ThunksDispatchers: ThunksDispatchersListing = {
  UnblockDispatcher: (id) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      dispatchers: { response },
      userInfo: { token, user },
    } = getState();
    let data = response.data;
    const api = mobioApi(token);

    return api
      .unblockDispatcher(id)
      .then((response: any) => {
        data.map((item: any) => {
          if (item.id == id) {
            item.block = 0
          }
        })
        dispatch(Local.DispatchersListing.api.response(data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.DispatchersListing.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(
            Local.DispatchersListing.api.error("Request Failed", data.msg)
          );
        }
      });

  },
  BlockDispatcher: (id) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      dispatchers: { response },
      userInfo: { token, user },
    } = getState();
    let data = response.data;
    console.log("this is user token", token);
    const api = mobioApi(token);
    // dispatch(Local.DispatchersListing.api.request());
    return api
      .blockDispatcher(id)
      .then((response: any) => {
        data.map((item: any) => {
          if (item.id == id) {
            item.block = 1
          }
        })
        dispatch(Local.DispatchersListing.api.response(data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.DispatchersListing.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(
            Local.DispatchersListing.api.error("Request Failed", data.msg)
          );
        }
      });

  },
  DispatchersListing: (load: any = true) => (dispatch: any, getState: any, mobioApi: any) => {
    console.log("dispatchers list");
    const {
      userInfo: { token, user },
    } = getState();
    console.log("this is user token", token);
    const api = mobioApi(token);
    if (load) {
      dispatch(Local.DispatchersListing.api.request());
    }
    if (user.role == "Organization") {
      return api
        .getCustomerDispatchers()
        .then((response: any) => {
          dispatch(Local.DispatchersListing.api.response(response.data));
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              Local.DispatchersListing.api.error(
                "Request Failed",
                "The API request timed out. Please Refresh the page"
              )
            );
          } else {
            const {
              response: { data },
            } = error;
            dispatch(
              Local.DispatchersListing.api.error("Request Failed", data.msg)
            );
          }
        });
    } else {
      return api
        .getDispatchers()
        .then((response: any) => {
          dispatch(Local.DispatchersListing.api.response(response.data));
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              Local.DispatchersListing.api.error(
                "Request Failed",
                "The API request timed out. Please Refresh the page"
              )
            );
          } else {
            const {
              response: { data },
            } = error;
            dispatch(
              Local.DispatchersListing.api.error("Request Failed", data.msg)
            );
          }
        });
    }
  },
  // getDispatchersByBooking: (id: any) => (dispatch: any, getState: any, mobioApi: any) => {
  //   const {
  //     userInfo: { token },
  //   } = getState();
  //   const api = mobioApi(token);
  //   dispatch(Local.DispatchersByBooking.api.request());
  //   return api
  //     .getDispatchersByBookingService(id)
  //     .then((response: any) => {
  //       dispatch(Local.DispatchersByBooking.api.response(response.data));
  //     })
  //     .catch((error: any) => {
  //       if (error.code === "ECONNABORTED") {
  //         dispatch(
  //           Local.DispatchersByBooking.api.error(
  //             "Request Failed",
  //             "The API request timed out. Please Refresh the page"
  //           )
  //         );
  //       } else {
  //         const {
  //           response: { data },
  //         } = error;
  //         dispatch(
  //           Local.DispatchersByBooking.api.error("Request Failed", data.msg)
  //         );
  //         dispatch(Local.DispatchersByBooking.api.response([]));
  //       }
  //     });
  // },
};

export default ThunksDispatchers;
