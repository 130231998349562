import React from "react";

const HeaderDescription = ({ heading, description , className}) => {
  return (
    <div className={`header-description-section ${className}`}>
      <h1>{heading}</h1>
      <p>{description}</p>
    </div>
  );
};

export default HeaderDescription;
