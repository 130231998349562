import { useEffect, useState } from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router";
import mobioApi from "../../../services";
import { connect } from "react-redux";
import { toastMessage } from "../../../helpers/toastMessage";
import { formatDate } from "../../../helpers/getTimeFormat";
import DatePickerField from "../../../components/commons/DatePickerField";

const CreateStopSale = ({ token }) => {
  const navigate = useNavigate();
  const stateData = useLocation();
  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();
  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },

    {
      name: "Manage AMS",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },

    {
      name: "Availability",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/availability`,
    },
    {
      name:
        stateData.state?.action === "edit"
          ? "Edit a stop sale"
          : "Create a stop sale",
      // url: "",
    },
  ];

  const [polygonList, setPolygonList] = useState([]);
  const [serviceLevelsList, setServiceLevelsList] = useState([]);

  const getPolygons = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getPolygon(airportId);
      const getData = response?.data?.result?.map((item) => {
        return { value: item?.name, label: item?.name, id: item?.id };
      });
      setPolygonList(getData);
    } catch (error) {
      setPolygonList([]);
    }
  };
  const getAllServiceLevels = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getAllServiceLevels();
      const getData = response?.data?.data?.map((item) => {
        return { value: item?.name, label: item?.name, id: item?.id };
      });
      setServiceLevelsList(getData);
    } catch (error) {
      setServiceLevelsList([]);
    }
  };

  useEffect(() => {
    getPolygons();
    getAllServiceLevels();
  }, []);
  return (
    <>
      <div>
        <div className="mt-18 flex justify-content-between">
          <div>
            <Breadcrumb
              paths={[
                {
                  name: `${
                    stateData.state?.action === "edit"
                      ? "Edit a stop sale"
                      : "Create a stop sale"
                  }`,
                  url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/availability`,
                },
              ]}
            />
            <div className="breadcrumb-description">
              Dispatchers can be assigned to bookings and use the driver app
            </div>
            <div className="mt-12 ml-6">
              <Breadcrumb paths={secondaryPaths} secondary={true} />
            </div>
          </div>
        </div>
        <div>
          {/*  */}
          <Formik
            initialValues={{
              locationType:
                stateData?.state?.action === "edit"
                  ? stateData.state?.data?.locationType
                  : "",
              locationId: "",
              locationIdMultiple:
                stateData?.state?.action === "edit"
                  ? [
                      {
                        value: stateData.state?.data?.polygons?.name,
                        label: stateData.state?.data.polygons?.name,
                        id: stateData.state?.data.polygons?.id,
                      },
                    ]
                  : "",
              locationIdOne:
                stateData?.state?.action === "edit"
                  ? {
                      value: stateData.state?.data?.polygons?.name,
                      label: stateData.state?.data.polygons?.name,
                      id: stateData.state?.data.polygons?.id,
                    }
                  : "",
              // startDate:
              //   stateData?.state?.action === "edit"
              //     ? formatDate(stateData?.state?.data?.startDate)
              //     : "",
              // endDate:
              //   stateData?.state?.action === "edit"
              //     ? formatDate(stateData?.state?.data?.endDate)
              //     : "",

              startDate:
                stateData?.state?.action === "edit"
                  ? new Date(stateData?.state?.data?.startDate)
                  : new Date(),
              endDate:
                stateData?.state?.action === "edit"
                  ? new Date(stateData?.state?.data?.endDate)
                  : new Date(),
              vehicleTypeId:
                stateData?.state?.action === "edit" &&
                stateData.state?.data?.locationType === "oneLocation"
                  ? stateData.state?.data?.serviceLevels?.map((sm) => {
                      return { value: sm?.name, label: sm?.name, id: sm?.id };
                    })
                  : "",
              stopSaleName:
                stateData?.state?.action === "edit"
                  ? stateData?.state?.data?.stopSaleName
                  : "",
              status:
                stateData?.state?.action === "edit"
                  ? stateData?.state?.data?.status
                  : true,
              airportId: airportId || "",
            }}
            onSubmit={async (values, { setSubmitting }) => {
              const payload = {
                locationType: values.locationType,
                startDate: values.startDate,
                endDate: values.endDate,
                stopSaleName: values.stopSaleName,
                status: values.status ? true : false,
                airportId: values.airportId || airportId,
              };
              if (values.locationType === "multiLocation") {
                payload.locationId = values.locationIdMultiple?.map(
                  (item) => item?.id
                );
              } else if (values.locationType === "oneLocation") {
                payload.locationId = [values?.locationIdOne?.id];
                payload.vehicleTypeId = values.vehicleTypeId?.map(
                  (vm) => vm?.id
                );
              }

              try {
                const api = mobioApi(token);
                const response = await api.addAvailability(payload);
                toastMessage("success", response?.data?.msg);
                navigate(-1);
              } catch (error) {
                toastMessage("error", error.response?.data?.msg);
              } finally {
                setSubmitting(false);
              }
              setSubmitting(false);
            }}
          >
            {({ values, setFieldValue, isSubmitting, handleChange }) => (
              <Form>
                <div>
                  <div className="bg-white  mt-20 border-radius-8 main-section-spacing-md">
                    <h3 className="m-0 font-17 font-600">
                      What kind of stop sale do you want to create?
                    </h3>

                    <div>
                      <div className="mt-20">
                        <label>
                          <div className="flex">
                            <div>
                              <Field
                                type="radio"
                                name="locationType"
                                value="multiLocation"
                                checked={
                                  values.locationType === "multiLocation"
                                }
                                onChange={handleChange}
                                disabled={stateData.state?.action === "edit"}
                              />
                            </div>
                            <div className="ml-10">
                              <p
                                className={`m-0 font-15 font-400 ${
                                  values.locationType !== "multiLocation" &&
                                  "text-black-gray-100"
                                }`}
                              >
                                A stop sale for multiple locations and all
                                vehicle types
                              </p>
                              <p className="m-0 font-11 font-400 text-black-gray-100 w-316">
                                This will stop any rides being assigned to you
                                for all vehicle types for the date period and
                                locations you choose.
                              </p>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="mt-10">
                        <label>
                          <div className="flex">
                            <div>
                              <Field
                                type="radio"
                                name="locationType"
                                value="oneLocation"
                                checked={values.locationType === "oneLocation"}
                                onChange={handleChange}
                                disabled={stateData.state?.action === "edit"}
                              />
                            </div>
                            <div className="ml-10">
                              <p
                                className={`m-0 font-15 font-400 ${
                                  values.locationType !== "oneLocation" &&
                                  "text-black-gray-100"
                                }`}
                              >
                                A stop sale for one location and specific
                                vehicle types
                              </p>
                              <p className="m-0 font-11 font-400 text-black-gray-100 w-341">
                                This will stop any rides being assigned to you
                                for the date period, location and vehicle types
                                you choose.
                              </p>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  {values.locationType && (
                    <>
                      {/* after select the points */}
                      {values.locationType === "oneLocation" ? (
                        <>
                          <div className="bg-white  mt-20 border-radius-8 main-section-spacing-md">
                            <h2 className="m-0 font-17 font-600 ">
                              Select location and vehicle types
                            </h2>

                            <div className="mt-32">
                              <p className="m-0 font-17 font-400 mb-16">
                                Select an available location
                              </p>
                              <Field name="locationIdOne">
                                {({ field }) => (
                                  <Select
                                    {...field}
                                    value={values.locationIdOne}
                                    isMulti={false}
                                    options={polygonList}
                                    placeholder="Select a location"
                                    isSearchable={false}
                                    className="availability-select-box"
                                    isDisabled={
                                      stateData.state?.action === "edit"
                                    }
                                    onChange={(option) =>
                                      setFieldValue("locationIdOne", option)
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                            <div className="mt-20">
                              <p className="m-0 font-17 font-400 mb-16">
                                Select specific vehicle types
                              </p>
                              <Field name="vehicleTypeId">
                                {({ field }) => (
                                  <Select
                                    {...field}
                                    isMulti
                                    options={serviceLevelsList}
                                    placeholder="Select one or more vehicle types"
                                    isSearchable={false}
                                    className="availability-select-box"
                                    onChange={(option) =>
                                      setFieldValue("vehicleTypeId", option)
                                    }
                                    isDisabled={
                                      stateData.state?.action === "edit"
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="bg-white  mt-20 border-radius-8 main-section-spacing-md">
                            <p className="m-0 font-17 font-600 mb-16">
                              Select locations
                            </p>
                            <Field name="locationIdMultiple">
                              {({ field }) => (
                                <Select
                                  {...field}
                                  isMulti
                                  value={values.locationIdMultiple}
                                  options={polygonList}
                                  placeholder="Select one or more locations"
                                  isSearchable={false}
                                  className="availability-select-box"
                                  onChange={(option) =>
                                    setFieldValue("locationIdMultiple", option)
                                  }
                                  isDisabled={
                                    stateData.state?.action === "edit"
                                  }
                                />
                              )}
                            </Field>
                          </div>
                        </>
                      )}
                      {/*  */}
                      <div className="bg-white  mt-20 border-radius-8 main-section-spacing-md">
                        <h2 className="m-0 font-17 font-600 ">
                          Choose stop sale period
                        </h2>
                        <div className="input-field-lg mt-32 ">
                          <p className="m-0 font-17 font-400 mb-14">
                            Start Date
                          </p>
                          {/* <Field
                            type="date"
                            value={values.startDate}
                            name="startDate"
                            style={{
                              textIndent: "4px",
                              height: "43px",
                              paddingRight: "20px",
                            }}
                          /> */}
                          <DatePickerField
                            placeholder="Select Date"
                            name="startDate"
                            value={values?.startDate}
                            onChange={(value) =>
                              setFieldValue("startDate", value)
                            }
                          />
                          <ErrorMessage name="startDate" component="div" />
                        </div>
                        <div className="input-field-lg mt-20">
                          <p className="m-0 font-17 font-400 mb-14">End Date</p>
                          {/* <Field
                            type="date"
                            value={values.endDate}
                            name="endDate"
                            style={{
                              textIndent: "4px",
                              height: "43px",
                              paddingRight: "20px",
                            }}
                          /> */}
                          <DatePickerField
                            placeholder="Select Date"
                            name="endDate"
                            value={values?.endDate}
                            onChange={(value) =>
                              setFieldValue("endDate", value)
                            }
                          />
                          <ErrorMessage name="endDate" component="div" />
                        </div>
                      </div>
                      {/*  */}
                      <div className="bg-white  mt-20 border-radius-8 main-section-spacing-md">
                        <h2 className="m-0 font-17 font-600 ">
                          Choose stop sale period
                        </h2>
                        <p className="m-0 font-13 font-400 text-black-gray-100 mt-6">
                          This name is for your reference. It won't be shown to
                          customers
                        </p>
                        <div className="input-field-lg mt-16 ">
                          <Field
                            type="text"
                            name="stopSaleName"
                            placeholder="Name (e.g. Summer)"
                            disabled={stateData.state?.action === "edit"}
                          />
                          <ErrorMessage name="stopSaleName" component="div" />
                        </div>
                      </div>
                      <div className="flex justify-content-end mt-20">
                        <MOButtonV2
                          type="submit"
                          text={
                            stateData?.state?.action === "edit"
                              ? "EDIT STOP SALE"
                              : "CREATE STOP SALE"
                          }
                          textColor="#ffffff"
                          radius={4}
                          backgroundColor="#29AEE6"
                          height={30}
                          fontSize={11}
                          padding={"8.5px 32px"}
                          onClick={() => {}}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(CreateStopSale);
