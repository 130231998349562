// import { useEffect, useRef, useState } from "react";

// const PopoverItem = ({ names = [], showItems = 2 }) => {
//   const [showAll, setShowAll] = useState(false);

//   const sortedNames = [...names].sort((a, b) => a.length - b.length);
//   const displayedNames = sortedNames.slice(0, showItems);
//   const remainingNames = sortedNames.slice(showItems);
//   const remainingCount = remainingNames.length;

//   const modalRef = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (modalRef.current && !modalRef.current.contains(event.target)) {
//         setShowAll(false);
//       }
//     };

//     if (showAll) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [showAll]);

//   return (
//     <div className="name-container">
//       <div style={{ display: "flex", gap: "10px" }}>
//         {displayedNames.map((name, index) => (
//           <span key={index} className="name-badge">
//             {name}
//           </span>
//         ))}
//       </div>

//       {remainingCount > 0 && (
//         <span className="remaining-circle" onClick={() => setShowAll(!showAll)}>
//           +{remainingCount}
//         </span>
//       )}

//       {showAll && (
//         <div className="remaining-modal" ref={modalRef}>
//           {remainingNames.map((name, index) => (
//             <span key={index} className="name-badge">
//               {name}
//             </span>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default PopoverItem;


//v2
// import { useEffect, useRef, useState } from "react";

// const PopoverItem = ({ names = [], showItems = 2 }) => {
//   const [showAll, setShowAll] = useState(false);

//   // Sort names by length
//   const sortedNames = [...names].sort((a, b) => a.length - b.length);

//   // Determine the displayed and remaining names
//   let displayedNames = sortedNames.slice(0, showItems);
//   const remainingNames = sortedNames.slice(showItems);
//   const remainingCount = remainingNames.length + (displayedNames.some(name => name.includes("...")) ? 1 : 0);

//   // Adjust names based on length
//   let adjustedNames = [...displayedNames];
//   const totalLength = adjustedNames.reduce((sum, name) => sum + name.length, 0);

//   if (displayedNames.length > 1) {
//     if (displayedNames[0].length > 16) {
//       // If the first name is longer than 16 characters
//       adjustedNames[0] = displayedNames[0].slice(0, 16) + "...";
//       adjustedNames = [adjustedNames[0]];
//       remainingNames.unshift(displayedNames[1]);
//     } else if (totalLength > 16) {
//       // If the total length exceeds 16 characters
//       const remainingChars = 16 - displayedNames[0].length;
//       adjustedNames[1] = displayedNames[1].slice(0, remainingChars - 3) + "...";
//     }
//   } else if (displayedNames[0].length > 16) {
//     // If only one name and it's longer than 16 characters
//     adjustedNames[0] = displayedNames[0].slice(0, 16) + "...";
//   }

//   const modalRef = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (modalRef.current && !modalRef.current.contains(event.target)) {
//         setShowAll(false);
//       }
//     };

//     if (showAll) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [showAll]);

//   return (
//     <div className="name-container">
//       <div style={{ display: "flex", gap: "10px" }}>
//         {/* Display names with truncation if needed */}
//         {adjustedNames.map((name, index) => (
//           <span key={index} className="name-badge">
//             {name}
//           </span>
//         ))}
//       </div>

//       {/* Show the +1 circle if there are more names or names were truncated */}
//       {remainingCount > 0 ? (
//         <span
//           className="remaining-circle"
//           onClick={() => setShowAll(!showAll)}
//           title="See Full Name"
//         >
//           {remainingCount > 1 ? `+${remainingCount}` : "+1"}
//         </span>
//       ) : null}

//       {/* Modal-like dropdown to show remaining names */}
//       {showAll && (
//         <div className="remaining-modal" ref={modalRef}>
//           {/* Show full names in modal */}
//           {[...sortedNames].map((name, index) => (
//             <span key={index} className="name-badge">
//               {name}
//             </span>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default PopoverItem;

//v3
// import { useEffect, useRef, useState } from "react";

// const PopoverItem = ({ names = [], showItems = 2 }) => {
//   const [showAll, setShowAll] = useState(false);

//   // Sort names by length
//   const sortedNames = [...names].sort((a, b) => a.length - b.length);

//   // Determine the displayed and remaining names
//   let displayedNames = sortedNames.slice(0, showItems);
//   const remainingNames = sortedNames.slice(showItems);
//   const remainingCount = remainingNames.length + (displayedNames.some(name => name.includes("...")) ? 1 : 0);

//   // Adjust names based on length
//   let adjustedNames = [...displayedNames];
//   const maxDisplayLength = 16;

//   if (displayedNames.length > 1) {
//     const firstNameLength = displayedNames[0].length;
    
//     if (firstNameLength >= maxDisplayLength) {
//       // If the first name is longer than the max length
//       adjustedNames[0] = displayedNames[0].slice(0, maxDisplayLength) + "...";
//       adjustedNames = [adjustedNames[0]];
//       remainingNames.unshift(displayedNames[1]);
//     } else {
//       // Calculate remaining characters for the second name
//       const remainingChars = maxDisplayLength - firstNameLength;
//       if (displayedNames[1].length > remainingChars) {
//         adjustedNames[1] = displayedNames[1].slice(0, remainingChars - 3) + "...";
//       } else {
//         adjustedNames[1] = displayedNames[1];
//       }
//     }
//   } else if (displayedNames[0].length > maxDisplayLength) {
//     // If only one name and it's longer than max length
//     adjustedNames[0] = displayedNames[0].slice(0, maxDisplayLength) + "...";
//   }

//   const modalRef = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (modalRef.current && !modalRef.current.contains(event.target)) {
//         setShowAll(false);
//       }
//     };

//     if (showAll) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [showAll]);

//   return (
//     <div className="name-container">
//       <div style={{ display: "flex", gap: "10px" }}>
//         {/* Display names with truncation if needed */}
//         {adjustedNames.map((name, index) => (
//           <span key={index} className="name-badge">
//             {name}
//           </span>
//         ))}
//       </div>

//       {/* Show the +1 circle if there are more names or names were truncated */}
//       {(remainingCount > 0 || displayedNames.some(name => name.includes("..."))) && (
//         <span
//           className="remaining-circle"
//           onClick={() => setShowAll(!showAll)}
//           title="See Full Name"
//         >
//           {remainingCount > 0 ? `+${remainingCount}` : "+1"}
//         </span>
//       )}

//       {/* Modal-like dropdown to show remaining names */}
//       {showAll && (
//         <div className="remaining-modal" ref={modalRef}>
//           {/* Show full names in modal */}
//           {[...sortedNames].map((name, index) => (
//             <span key={index} className="name-badge">
//               {name}
//             </span>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default PopoverItem;


// v4
import { useEffect, useRef, useState } from "react";

const PopoverItem = ({ names = [], showItems = 2 }) => {
  const [showAll, setShowAll] = useState(false);

  // Sort names by length
  const sortedNames = [...names].sort((a, b) => a.length - b.length);

  // Determine the displayed and remaining names
  let displayedNames = sortedNames.slice(0, showItems);
  const remainingNames = sortedNames.slice(showItems);

  // Adjust names based on length
  let adjustedNames = [...displayedNames];
  const maxDisplayLength = 16;
  let truncatedCount = 0;

  if (displayedNames.length > 1) {
    const firstNameLength = displayedNames[0].length;

    if (firstNameLength >= maxDisplayLength) {
      // If the first name is longer than the max length
      adjustedNames[0] = displayedNames[0].slice(0, maxDisplayLength) + "...";
      truncatedCount += 1;
      adjustedNames = [adjustedNames[0]];
      remainingNames.unshift(displayedNames[1]);
    } else {
      // Calculate remaining characters for the second name
      const remainingChars = maxDisplayLength - firstNameLength;
      if (displayedNames[1].length > remainingChars) {
        adjustedNames[1] = displayedNames[1].slice(0, remainingChars - 3) + "...";
        truncatedCount += 1;
      } else {
        adjustedNames[1] = displayedNames[1];
      }
    }
  } else if (displayedNames[0].length > maxDisplayLength) {
    // If only one name and it's longer than max length
    adjustedNames[0] = displayedNames[0].slice(0, maxDisplayLength) + "...";
    truncatedCount += 1;
  }

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowAll(false);
      }
    };

    if (showAll) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAll]);

  // Calculate remaining count for the circle
  const remainingCount = remainingNames.length + truncatedCount;

  return (
    <div className="name-container">
      <div style={{ display: "flex", gap: "10px" }}>
        {/* Display names with truncation if needed */}
        {adjustedNames.map((name, index) => (
          <span key={index} className="name-badge">
            {name}
          </span>
        ))}
      </div>

      {/* Show the +1 or +2 circle if there are more names or names were truncated */}
      {(remainingCount > 0 || truncatedCount > 0) && (
        <span
          className="remaining-circle"
          onClick={() => setShowAll(!showAll)}
          title="See Full Name"
        >
          {`+${remainingCount}`}
        </span>
      )}

      {/* Modal-like dropdown to show remaining names */}
      {showAll && (
        <div className="remaining-modal" ref={modalRef}>
          {/* Show full names in modal */}
          {[...sortedNames].map((name, index) => (
            <span key={index} className="name-badge">
              {name}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default PopoverItem;
