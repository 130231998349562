import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/BreadCrumb";
import PolygonManageArea from "./PolygonManageArea";
import { useEffect, useState } from "react";

const paths = [
  {
    name: "Add Polygon",
    url: "/dashboard/integration/rates/city-airport/detail",
  },
];
const Index = () => {
  const navigate = useNavigate();
  const locationData = useLocation();
  const { integrationType, integrationId } = useParams();
  const [secondaryPaths, setSecondaryPaths] = useState([]);

  useEffect(() => {
    const pathArray = [];
    Object.keys(locationData.state).forEach((item) => {
      if (item === "country") {
        pathArray.push({
          name: locationData.state[item],
          url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
        });
      }
      if (item === "city") {
        pathArray.push({
          name: locationData.state[item],
          url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${locationData.state["country"]}/${locationData.state["countryId"]}/city`,
        });
      }

      if (item === "airportId") {
        pathArray.push({
          name: "Airports",
          url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${locationData.state["country"]}/${locationData.state["countryId"]}/${locationData.state["city"]}/${locationData.state["cityId"]}/city-airport`,
        });
      }

      if (item === "iata") {
        pathArray.push({
          name: `Manage ${locationData.state["iata"]}`,
          url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${locationData.state["country"]}/${locationData.state["countryId"]}/${locationData.state["city"]}/${locationData.state["cityId"]}/airport/${locationData.state["iata"]}/${locationData.state["airportId"]}/mange-location`,
        });

        pathArray.push({
          name: `Fixed Route Rate`,
          url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${locationData.state["country"]}/${locationData.state["countryId"]}/${locationData.state["city"]}/${locationData.state["cityId"]}/airport/${locationData.state["iata"]}/${locationData.state["airportId"]}/rate-manage`,
        });
      }
    });
    pathArray.push({
      name: "Polygon",
      url: "",
    });
    setSecondaryPaths(pathArray);
  }, []);

  return (
    <>
      <div>
        <div className="mt-18 flex justify-content-between">
          <div>
            <Breadcrumb paths={paths} backType="auto" />

            <div className="mt-8 ml-6">
              <Breadcrumb paths={secondaryPaths} secondary={true} />
            </div>
          </div>
          {/* <div className="flex">
            <MOButtonV2
              text="CREATE POLYGON"
              textColor="#ffffff"
              radius={4}
              backgroundColor="#29AEE6"
              // onClick={handleAddLocationClick}
              IconType={() => <Add style={{ marginRight: 10, fontSize: 16 }} />}
              height={30}
              fontSize={12}
              padding={"6px 64px 6px 64px"}
            />
          </div> */}
        </div>
      </div>
      <div>
        <PolygonManageArea
          integrationType={integrationType}
          centerPoint={locationData.state?.centerPoint}
          airportId={locationData?.state?.airportId}
          countryName={locationData?.state?.country || null}
        />
      </div>
    </>
  );
};

export default Index;
