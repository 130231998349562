import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import { useParams } from "react-router";
import { integrationData } from "./integrationData";
import ButtonToggle from "../../../components/commons/ButtonToggle";
import GetBookingForm from "./Form/GetBookingForm";
import { toastMessage } from "../../../helpers/toastMessage";
import mobioApi from "../../../services";
import { connect, useSelector } from "react-redux";
import RateModuleForm from "./Form/RateModuleForm";
import MOSwitch from "../../../components/commons/MOSwitch";
import ConformModal from "../../../components/commons/Modals/ConformModal";
import DisconnectIcon from "../../../assets/icons/disconnect-outlined.png";
import ThunksBooking from "../../../redux/actions/thunks/bookings";

const Integration = ({ removeBookingIntegration, companyId }) => {
  const [activeToggle, setActiveToggle] = useState("Get Booking");
  const { integrationType } = useParams();
  const [integrationTypeData, setIntegrationTypeData] = useState({});
  const [disconnectConnectionModal, setDisconnectConnectionModal] =
    useState(false);

  //   check connection with get booking integration
  const token = useSelector((state) => state.userInfo.token);
  const fetchIntegrationData = async (preData) => {
    try {
      const api = mobioApi(token);
      const res = await api.getBookingIntegration(integrationType);
      setIntegrationTypeData({
        ...preData,
        connectionStatus: res.data?.bookingIntegration,
      });
    } catch (error) {
      toastMessage(
        "error",
        "Error occurred during checking the connection, Please refresh the page"
      );
    }
  };

  const fetchRateIntegrationData = async (preData) => {
    try {
      const api = mobioApi(token);
      const res = await api.getRateIntegration(integrationType);
      setIntegrationTypeData({
        ...preData,
        connectionStatus:
          res?.data?.data[0]?.integrationType === preData?.value,
        id: res?.data?.data[0]?.id ? res?.data?.data[0]?.id : preData?.id,
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (integrationType) {
      const getData = integrationData.filter(
        (item) => item.value === integrationType
      );
      if (getData?.length > 0) {
        setIntegrationTypeData(getData[0]);
      } else {
        setIntegrationTypeData({
          ...integrationTypeData,
        });
      }

      //   checking connection
      if (activeToggle && activeToggle === "Get Booking") {
        fetchIntegrationData(getData[0]);
      } else if (activeToggle && activeToggle === "Rate Module") {
        fetchRateIntegrationData(getData[0]);
      }
    }
  }, [integrationType, activeToggle]);

  //   disconnected the connection
  const deleteRateIntegration = async () => {
    try {
      if (integrationTypeData?.id) {
        if (activeToggle === "Rate Module") {
          const api = mobioApi(token);
          await api.deleteRateIntegration(integrationTypeData?.id);
        } else if (activeToggle === "Get Booking") {
          await removeBookingIntegration(companyId, integrationTypeData?.value);
        } else {
          toastMessage("error", "There is no tab is selected");
          return;
        }

        toastMessage("success", "Connection has been removed");
        setDisconnectConnectionModal(false);

        // update state
        setIntegrationTypeData({
          ...integrationTypeData,
          connectionStatus: false,
        });
      } else {
        toastMessage("success", "Value is not found");
      }
    } catch (error) {
      toastMessage("error", error?.response?.data?.msg);
    }
  };

  return (
    <div>
      <div className="mt-18 flex justify-content-between">
        <div>
          <Breadcrumb
            paths={[
              {
                name: `${integrationType} Integration`,
                url: `/dashboard/integration/`,
              },
            ]}
          />
        </div>
      </div>
      <div className="integration-connection-section">
        <div className="inner-integration-section">
          {/* header section */}
          <div className="flex align-item-center justify-content-between">
            <img src={integrationTypeData?.mainImage} />
            <div className="flex">
              <p
                className={`font-500 font-15  ${
                  integrationTypeData?.connectionStatus
                    ? "text-success mr-18 "
                    : "text-gray-light"
                }`}
              >
                {integrationTypeData?.connectionStatus
                  ? "CONNECTED"
                  : "NOT CONNECTED"}
              </p>
              {integrationTypeData?.connectionStatus && (
                <>
                  <MOSwitch
                    id={integrationTypeData?.id || 1}
                    isOnStatus={integrationTypeData?.connectionStatus}
                    setStatus={(status) => {
                      setDisconnectConnectionModal(!status);
                    }}
                    isParentControl={true}
                  />
                </>
              )}
            </div>
          </div>
          {/* body section */}
          <div className="mt-56">
            <div className="flex justify-content-center">
              <ButtonToggle
                btnList={["Get Booking", "Rate Module"]}
                activeToggle={activeToggle}
                setActiveToggle={setActiveToggle}
              />
            </div>
            {/* forms */}
            <div className="mt-56">
              {activeToggle === "Get Booking" && (
                <GetBookingForm
                  integrationType={integrationType}
                  integrationTypeData={integrationTypeData}
                  setIntegrationTypeData={setIntegrationTypeData}
                  token={token}
                  manageUrl={`/dashboard/integration/get-booking/${integrationTypeData?.value}`}
                />
              )}
              {activeToggle === "Rate Module" && (
                <RateModuleForm
                  integrationTypeData={integrationTypeData}
                  setIntegrationTypeData={setIntegrationTypeData}
                  token={token}
                  manageUrl={`/dashboard/integration/rates/${integrationTypeData?.value}/${integrationTypeData?.id}`}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <ConformModal
        title="Disconnect API"
        description="Are you certain you want to disconnect?"
        successBtnText="Disconnect"
        icon={DisconnectIcon}
        modalOpen={disconnectConnectionModal}
        setModalOpen={setDisconnectConnectionModal}
        apiCall={() => {
          deleteRateIntegration();
        }}
      />
    </div>
  );
};

// export default Integration;
const mapState = (state) => ({
  companyId: state.userInfo.user.id,
});

const mapDispatch = {
  removeBookingIntegration: ThunksBooking.RemoveBookingIntegration,
};

export default connect(mapState, mapDispatch)(Integration);
