import React from "react";

const VerticalProgressBar = ({
  progress,
  maxLabelValue,
  timeLabel = "1",
  timeUnit = "Min",
}) => {
  const progressPercentage = (progress / maxLabelValue) * 100;

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          position: "relative",
          height: "180px",
          width: "10px",
          backgroundColor: "#F2F7FF",
          borderRadius: "30px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: 0,
            height: `${progressPercentage}%`,
            width: "100%",
            backgroundColor: "#36B3E7",
            borderRadius: "30px",
          }}
        ></div>
      </div>
      <p className="m-0">{timeLabel}</p>
      <p className="m-0">{timeUnit}</p>
    </div>
  );
};

export default VerticalProgressBar;
