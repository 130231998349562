import React from "react";
import HeaderDescription from "../../../../components/commons/HeaderDescription";

const PopularRouteProgressBar = () => {
  const ProgressBar = ({ header }) => {
    return (
      <>
        <div>
          <h5 className="m-0 font-14 font-600">{header}</h5>
          <div className="flex align-item-center justify-content-center gap-16">
            <div
              className="flex-1"
              style={{
                backgroundColor: "#D7F0FA",
                height: "8px",
                borderRadius: "30px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#36B3E7",
                  width: `${60}%`,
                  height: "100%",
                  borderRadius: "30px",
                }}
              ></div>
            </div>
            <div>
              <p className="m-0 font-14 font-600">
                74% <span style={{ color: "#CFCFCF" }}>Correct</span>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div>
      <HeaderDescription heading={"Popular Route"} />

      <div>
        {/* San Isidro */}
        <div className="mt-12">
          <ProgressBar header={"San Isidro"} />
        </div>
        {/* Greensboro (NC) */}
        <div className="mt-12">
          <ProgressBar header={"Greensboro (NC)"} />
        </div>
        {/* La Plata */}
        <div className="mt-12">
          <ProgressBar header={"La Plata"} />
        </div>
      </div>
    </div>
  );
};

export default PopularRouteProgressBar;
