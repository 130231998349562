import Local from "../../local";

export type ThunksDriversListing = {
  Drivers: (load: any) => any;
  Role: (id: any, payload: any) => any;
  UnblockDriver: (id: any) => any;
  BlockDriver: (id: any) => any;
  deleteDriver: (id: any) => any;
};

export type ThunksDriverRole = {
  Role: (id: any, payload: any) => any;
};

const ThunksDrivers: ThunksDriversListing = {
  UnblockDriver: (id) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token, user },
    } = getState();

    const api = mobioApi(token);
  
    return api
      .unblockDriver(id)
      .then((response: any) => {
        return 1
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.Drivers.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(
            Local.Drivers.api.error("Request Failed", data.msg)
          );
        }
      });
    
  },
  BlockDriver: (id) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
  
    const api = mobioApi(token);
    
    return api
      .blockDriver(id)
      .then((response: any) => {
        return 1
      })
      .catch((error: any) => {
        
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.Drivers.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(
            Local.Drivers.api.error("Request Failed", data.msg)
          );
        }
      });
    
  },
  Drivers: (load: any = true) => (dispatch: any, getState: any, mobioApi: any) => {
    console.log("drivers list");
    const {
      userInfo: { token },
    } = getState();
    console.log("this is user token", token);
    const api = mobioApi(token);
    if (load) {
      dispatch(Local.Drivers.api.request());
    }
    return api
      .getDrivers()
      .then((response: any) => {
        dispatch(Local.Drivers.api.response(response.data));
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        dispatch(Local.Drivers.api.error("Request Failed", data.msg));
      });
  },

  Role: (id, payload) => async (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    console.log("this is user token", token);
    const api = mobioApi(token);
    // dispatch(Local.DriversListing.api.request());

    return await api
      .changeDriverRole(id, payload)
      .then((response: any) => {
        dispatch(Local.Drivers.api.request());
        dispatch(Local.DispatchersListing.api.request());
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        alert(data.msg);
        dispatch(Local.Drivers.api.request());
        dispatch(Local.DispatchersListing.api.request());
        dispatch(Local.Drivers.api.error("Request Failed", data.msg));
      });
  },
  deleteDriver: (id) => async (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    dispatch(Local.Drivers.api.request());
    return await api
      .deleteDriver({id: id})
      .then((response: any) => {
        dispatch(Local.Drivers.api.response(response.data));
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        alert(data.msg);
        dispatch(Local.Drivers.api.request());
        dispatch(Local.Drivers.api.error("Request Failed", data.msg));
      });
  },
};

export default ThunksDrivers;
