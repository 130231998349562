import TaxiCompanies from "./TaxiCompanies";

import React from "react";

import { Button, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddTaxiCompany from "./AddTaxiCompany";
import AddTaxiCompanyForm from "./AddTaxiCompanyForm";

const useStyles = makeStyles({
  main_dropdown: {
    marginTop: 20,
  },
  image: {
    height: 80,
    width: 80,
    marginRight: 20,
    borderRadius: 14,
  },

  user_info: {
    marginLeft: 24,
  },
});

function TaxiCompany() {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="row-reverse"
        justifyContent="flex-start"
        alignItems="center"
      >
        <AddTaxiCompanyForm />
        {/* <AddTaxiCompany /> */}
      </Grid>
      <TaxiCompanies />
    </>
  );
}

export default TaxiCompany;
