import { CREATE_LOCATION } from "../constants/rateModule";

export type Field =
  | "companyId"
  | "integrationType"
  | "countryName"
  | "stateName"
  | "cityName"
  | "airportLat"
  | "airportLng"
  | "centralZone"
  | "leadTime"
  | "iata"
  | "airportName"
  | "supportNumber";

export type CreateLocationRepo = {
  resetAll: () => any;
  field: {
    update: (field: Field, newContent: string) => any;
    clear: (field: Field) => any;
  };
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
};

const CreateLocation: CreateLocationRepo = {
  resetAll: () => ({
    type: CREATE_LOCATION.RESET_ALL,
    payload: {},
  }),
  field: {
    update: (field, newContent) => ({
      type: CREATE_LOCATION.FIELD.UPDATE,
      payload: { field, newContent },
    }),
    clear: (field) => ({
      type: CREATE_LOCATION.FIELD.CLEAR,
      payload: { field },
    }),
  },
  api: {
    request: () => ({
      type: CREATE_LOCATION.CREATE_LOCATION_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: CREATE_LOCATION.CREATE_LOCATION_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: CREATE_LOCATION.CREATE_LOCATION_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: CREATE_LOCATION.CREATE_LOCATION_API.RESET,
      payload: {},
    }),
  },
};

export default CreateLocation;

export { CreateLocation };
