import IntegrationServicesCard from "../../../../components/commons/IntegrationServicesCard";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../../components/BreadCrumb";

import DispatcherIcon from "../../../..//components/iconLibrary/DispatcherIcon";
import OffersIcons from "../../../../components/iconLibrary/OffersIcons";
import SearchingInput from "../../../../components/commons/SearchingInput";

import { useState } from "react";
import { connect } from "react-redux";


function ManageCardsPage({ token }) {
  const navigate = useNavigate();
  const {
    integrationType,

  } = useParams();





  const paths = [
    {
      name: `Manage`,
      url: "/dashboard/integration/",
    },
  ];

  const secondaryPaths = [
    {
      name: integrationType,
      url: `/dashboard/integration`,
    },
   

    {
      name: `Manage`,
      // url: "/dashboard/integration/rate-api/city-airport/detail",
    },
  ];
  const [cardsData, setCardData] = useState([
    {
        heading: "Commission Rule",
        description: "Defining the Driver events-on the basis of Driver details.",
        url: `/dashboard/integration/commission/?integration=${integrationType}`,
        Icon: <OffersIcons />,
        // statusKey: "commissionRule",
        // tooltipMessage: "Please provide this information",
      },
      {
        heading: "Dispatcher",
        description:
          "The dispatcher coordinates and optimizes for efficient operations.",
        url: `/dashboard/integration/dispatchers/?integration=${integrationType}`,
        Icon: <DispatcherIcon />,
        // statusKey: "dispatcherStatus",
        // tooltipMessage: "Please provide the dispatcher",
      },
  ]);



  return (
    <div>
      <div className="mt-18 flex justify-content-between">
        <div>
          <Breadcrumb paths={paths} backType="auto" />
          <div className="breadcrumb-description">
            Manage Dispatcher and Commission Rule from here.
          </div>
          <div className="mt-8 ml-6">
            <Breadcrumb paths={secondaryPaths} secondary={true} />
          </div>
        </div>
        <div>
          <SearchingInput />
        </div>
      </div>

      <div className="mt-16 integrationServicesCard-main-section">
        {cardsData.map((data, index) => (
          <IntegrationServicesCard
            key={index}
            data={data}
          />
        ))}
      </div>
    </div>
  );
}

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(ManageCardsPage);

