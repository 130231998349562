export function formatAndCapitalize(text) {
    if (!text) return '';
  
    
    let separatedText = text.replace(/([a-z])([A-Z])/g, '$1 $2') // Handle camelCase
                            .replace(/([a-z])([a-z])([A-Z])/g, '$1 $2$3') // Handle words without spaces
                            .replace(/([A-Z][a-z]+)/g, ' $1'); // Handle any uppercase followed by lowercase
  

    return separatedText
      .split(/[^a-zA-Z0-9]/) // Split on any non-alphanumeric characters
      .filter(Boolean) // Remove any empty parts
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter
      .join(' ');
  }