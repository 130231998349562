const REPORT = {
    RESET_ALL: "ACTION.LOCAL.REPORT.RESET_ALL",
    FIELD: {
      UPDATE: "ACTION.LOCAL.REPORT.FIELD.UPDATE",
      CLEAR: "ACTION.LOCAL.REPORT.FIELD.CLEAR",
    },
    FLAGS: {
      TOGGLE_COMPLETE: "ACTION.LOCAL.REPORT.FLAGS.TOGGLE_COMPLETE",
      UPDATE_STEP: "ACTION.LOCAL.REPORT.FLAGS.UPDATE_STEP",
      RESET: "ACTION.LOCAL.REPORT.FLAGS.RESET",
    },
  };
  
export default REPORT;
  