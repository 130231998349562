import LOGIN from "../constants/login";

export type Field = "displayName" | "password" | "token";

export type LocalLoginRepo = {
  resetAll: () => any;
  field: {
    update: (field: Field, newContent: string) => any;
    clear: (field: Field) => any;
  };
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
};

const Login: LocalLoginRepo = {
  resetAll: () => ({
    type: LOGIN.RESET_ALL,
    payload: {},
  }),
  field: {
    update: (field, newContent) => ({
      type: LOGIN.FIELD.UPDATE,
      payload: { field, newContent },
    }),
    clear: (field) => ({
      type: LOGIN.FIELD.CLEAR,
      payload: { field },
    }),
  },
  api: {
    request: () => ({
      type: LOGIN.LOGIN_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: LOGIN.LOGIN_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: LOGIN.LOGIN_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: LOGIN.LOGIN_API.RESET,
      payload: {},
    }),
  },
};

export default Login;
