import { GET_CLIENT_AUTHORIZATIONS } from "../../actions/local/constants/clientUsers";
import { GET_USER_AUTHORIZATIONS } from "../../actions/local/constants/orgUsers";
import USER from "../../actions/local/constants/user";
import userStates from "../../initial-state/user";
import storage from "redux-persist/lib/storage";

const userReducer = (state: any | null = userStates, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case USER.RESET_USER: {
      storage.removeItem("persist:root");
      return {
        ...userStates,
      };
    }
    case USER.UPDATE_USER: {
      return {
        ...state,
        ...payload,
      };
    }
    case USER.PROFILE_UPDATE: {
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    }
    case USER.PROFILE_IMAGE: {
      return {
        ...state,
        user: {
          ...state.user,
          imageUrl: payload
        },
      };
    }
    case GET_USER_AUTHORIZATIONS.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        error: message,
      };
    }
    case GET_USER_AUTHORIZATIONS.RESET_ERROR: {
      return {
        ...state,
        error: undefined,
      };
    }
    case GET_USER_AUTHORIZATIONS.SET_RESPONSE: {
      const { data } = payload;
      return {
        ...state,
        user: {
          ...state.user,
          authorities: data.authorities,
          block: data?.block?.block,
        },
      };
    }
    case GET_CLIENT_AUTHORIZATIONS.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        error: message,
      };
    }
    case GET_CLIENT_AUTHORIZATIONS.RESET_ERROR: {
      return {
        ...state,
        error: undefined,
      };
    }
    case GET_CLIENT_AUTHORIZATIONS.SET_RESPONSE: {
      const { data } = payload;
      return {
        ...state,
        user: {
          ...state.user,
          authorities: data.authorities,
          block: data?.block?.block,
        },
      };
    }
    default:
      return state;
  }
};

export default userReducer;
