import { toast } from "react-toastify";

export const toastMessage = (
  type = "success",
  message,
  customOption = {
    className: "",
  }
) => {
  const options = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
    className: customOption.className,
  };

  if (type === "error") {
    toast.error(message, options);
  } else {
    toast.success(message, options);
  }
};
