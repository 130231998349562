import React from "react";

const FinancialPerformance = () => {
  return (
    <div>
      <h1>FinancialPerformance</h1>
    </div>
  );
};

export default FinancialPerformance;
