import DRIVERS from "../constants/drivers";

export type DriversRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
  };
};

const Drivers: DriversRepo = {
  api: {
    request: () => ({
      type: DRIVERS.DRIVERS_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: DRIVERS.DRIVERS_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: DRIVERS.DRIVERS_API.SET_RESPONSE,
      payload: { data },
    }),
  },
};

export default Drivers;
