import { ReactElement, useState } from "react";
import {
  StyledToggleButtonGroup,
  StyledToggleButton,
} from "../../../components/commons/ButtonGrouping";

import Table from "./Table";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
interface Props {}

export default function DriverOnboarding({}: Props): ReactElement {
  const [filter, setFilter] = useState("Pending");
  const [orderFilter,setOrderFilter]=useState("ASC")
  return (
    <>
      <StyledToggleButtonGroup
        value={filter}
        exclusive
        onChange={(e: any, value: any) => value !== null && setFilter(value)}
      >
        <StyledToggleButton key="Pending" value="Pending">
          Pending
        </StyledToggleButton>
        <StyledToggleButton key="Rejected" value="Rejected">
          Rejected
        </StyledToggleButton>
        <StyledToggleButton key="Approved" value="Approved">
          Approved
        </StyledToggleButton>
      </StyledToggleButtonGroup>
      <Box style={{marginTop:24}}>
        <Box style={{backgroundColor:"#ffffff",paddingTop:24,paddingRight:24, display:"flex",justifyContent:"end"}}>
          <Box>
          <InputLabel id="demo-simple-select-label" style={{marginBottom:6}}>Order By</InputLabel>
          <FormControl>
           
            <Select
            style={{width:290}}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={orderFilter}
              // label="By Order"
              variant="outlined"

              onChange={(e)=>{               
                setOrderFilter(e.target.value as string) 
              }}
            >
              <MenuItem value={"ASC"}>Ascending</MenuItem>
              <MenuItem value={'DESC'}>Descending </MenuItem>
            </Select>
          </FormControl>
          </Box>
        </Box>
        <Table filter={filter} orderFilter={orderFilter}/>
      </Box>

      {/* <Table filter={filter} /> */}
    </>
  );
}
