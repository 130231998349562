import MOModalWrapper from "../../../../components/commons/MOModalWrapper";
import { Form, Formik } from "formik";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import { GetCountries } from "react-country-state-city";
import { useEffect, useState } from "react";
import Select from "react-select";
import { City } from "country-state-city";
import { LocationOnOutlined } from "@material-ui/icons";
import { toastMessage } from "../../../../helpers/toastMessage";
import mobioApi from "../../../../services";
import { allAirports } from "../../../../data/airports";

const customFormatOptionLabel = (option, { context, selectValue }) => {
  return (
    <>
      {context === "menu" && (
        <div className="flex align-item-center">
          <LocationOnOutlined style={{ color: "#34abe6", fontSize: 21 }} />
          <p className="font-14 font-400 ml-10">{`${option.label}`}</p>
        </div>
      )}
      {context === "value" && (
        <>
          <div className="flex align-item-center">
            <LocationOnOutlined style={{ color: "#34abe6", fontSize: 21 }} />
            <p className="font-14 font-400 ml-10">{`${option.label}`}</p>
          </div>
        </>
      )}
    </>
  );
};

const AddCityModal = ({
  modalOpen,
  setModalOpen,
  country,
  countryId,
  apiCall,
  token,
}) => {
  const [cityList, setCityList] = useState([]);
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const payload = {
        countryId: countryId,
        name: values.cityDetail.name,
        isActive: true,
        isDeleted: false,
      };
      const api = mobioApi(token);
      const response = await api.addNewCity(payload);
      toastMessage("success", response?.data?.msg);
      apiCall();
      setModalOpen(false);
    } catch (error) {
      toastMessage("error", error?.response?.data.msg);
    } finally {
      setSubmitting(false);
    }
  };

  // const gettingListOfCity = () => {
  //   GetCountries().then((result) => {
  //     result?.forEach((item) => {
  //       if (item.name === country) {
  //         console.log("mactced.................");
  //         const getCities = City.getCitiesOfCountry(item?.iso2);
  //         // const getCitiesOption=  getCities?.map((cityItem) => {
  //         //   return {
  //         //     value: cityItem.name,
  //         //     label: cityItem.name,
  //         //     ...cityItem,
  //         //   };
  //         // })
  //         // setCityList(
  //         //   getCitiesOption
  //         // );
  //         return;
  //       }
  //       console.log("no matched");
  //     });
  //   });
  // };

  const gettingListOfCity = () => {
    const getCountryLevelAirport = allAirports.filter(
      (af) => af.country === country
    );
    GetCountries().then((result) => {
      result?.some((item) => {
        if (item.name === country) {
          const getCities = City.getCitiesOfCountry(item?.iso2);
          // const getCitiesOption = getCities?.map((cityItem) => {
          //   if (
          //     getCountryLevelAirport.filter((gf) => gf.city === cityItem.name)
          //       ?.length > 0
          //   ) {
          //     return {
          //       value: cityItem.name,
          //       label: cityItem.name,
          //       ...cityItem,
          //     };
          //   }
          //   // return {
          //   //   value: cityItem.name,
          //   //   label: cityItem.name,
          //   //   ...cityItem,
          //   // };
          // });
          const uniqueCitiesSet = new Set(); // To store unique city names

          const getCitiesOption = getCities
            ?.filter((cityItem) => {
              // Check if there is a match for the city in the airport list
              const hasAirport = getCountryLevelAirport.some(
                (gf) => gf.city === cityItem.name
              );

              // Ensure the city is not already added to the uniqueCitiesSet
              if (hasAirport && !uniqueCitiesSet.has(cityItem.name)) {
                uniqueCitiesSet.add(cityItem.name); // Add city to set to avoid duplicates
                return true;
              }
              return false;
            })
            .map((cityItem) => ({
              value: cityItem.name,
              label: cityItem.name,
              ...cityItem,
            }));
          setCityList(getCitiesOption);

          return true;
        }

        return false;
      });
    });
  };

  useEffect(() => {
    gettingListOfCity();
  }, []);
  return (
    <MOModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={"Add City"}
      className={"country-city-modal header-modal-section"}
    >
      <div>
        <Formik
          initialValues={{
            cityDetail: "",
          }}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="mt-10">
                <h6 className="m-0 font-12 font-600 mb-12">Select City</h6>
                <Select
                  closeMenuOnSelect={true}
                  isMulti={false}
                  options={cityList}
                  formatOptionLabel={customFormatOptionLabel}
                  onChange={(e) => {
                    setFieldValue("cityDetail", e);
                  }}
                  className="select-country-list-field"
                />
              </div>

              <div></div>
              {/*  */}

              <div className="mt-20">
                <MOButtonV2
                  text={"PROCEED"}
                  type="submit"
                  textColor="#ffffff"
                  radius={4}
                  backgroundColor="#29AEE6"
                  height={44}
                  fontSize={11}
                  widthSize={"100%"}
                  padding={"16px 0px"}
                  disabled={isSubmitting || !values.cityDetail}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </MOModalWrapper>
  );
};

export default AddCityModal;
