import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getCountryCurrency } from "../../../helpers/getCountryCurrency";
import AirportPickupFeesForm from "./forms/AirportPickupFeesForm";
import MeetAndGreetFeesForm from "./forms/MeetAndGreetFeesForm";

const AirportPickupFees = ({ token }) => {
  const { airportId, country } = useParams();
  const [airportStatus, setAirportStatus] = useState("airport-pick-up");
  const [countryCurrency, setCountryCurrency] = useState("");

  const countryCurrencyHandle = async (countryName) => {
    const currency = await getCountryCurrency(countryName);
    setCountryCurrency(currency);
  };

  useEffect(() => {
    if (country) {
      countryCurrencyHandle(country);
    }
  }, []);

  const handleAirportFilter = (statusFilter) => {
    if (statusFilter === airportStatus) {
      setAirportStatus("airport-pick-up");
    } else {
      setAirportStatus(statusFilter);
    }
  };

  return (
    <div>
      {/* main body */}
      <div className="bg-white airport-pickup-fee-section border-radius-8">
        {/* Section for Airport Pick-up Fee and Meet & Greet Fee */}
        <div>
          <div className="airport-pickup-status-section">
            <button
              onClick={() => {
                handleAirportFilter("airport-pick-up");
              }}
              className={`airport-pickup-status-btn cursor-pointer mr-12 ${
                airportStatus === "airport-pick-up"
                  ? "airport-pickup-status-btn-active"
                  : "airport-pickup-status-btn-inactive"
              }`}
            >
              Airport Pick-Up Fee
            </button>
            <button
              onClick={() => {
                handleAirportFilter("meet-greet-fee");
              }}
              className={`airport-pickup-status-btn cursor-pointer ${
                airportStatus === "meet-greet-fee"
                  ? "airport-pickup-status-btn-active"
                  : "airport-pickup-status-btn-inactive"
              }`}
            >
              Meet & Greet Fee
            </button>
          </div>
        </div>
        {/* header section */}

        {/* body */}
        <div>
          {/* formik */}
          {airportStatus === "airport-pick-up" ? (
            <AirportPickupFeesForm
              countryCurrency={countryCurrency}
              airportId={airportId}
              token={token}
            />
          ) : (
            <>
              <MeetAndGreetFeesForm
                countryCurrency={countryCurrency}
                airportId={airportId}
                token={token}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AirportPickupFees;
