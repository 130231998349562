import { PAYMENT_PAYINS } from "../constants/paymentPayins";

export type PaymentPayInsRepo = {
    api: {
      request: () => any;
      error: (type: string | number, message: string) => any;
      response: (data: any) => any;
    };
  };
const PaymentPayIn: PaymentPayInsRepo = {
    api: {
      request: () => ({
        type:PAYMENT_PAYINS.PAYMENT_PAYINS_API.SET_REQUEST,
        payload: {},
      }),
      error: (type, message) => ({
        type: PAYMENT_PAYINS.PAYMENT_PAYINS_API.SET_ERROR,
        payload: { type, message },
      }),
      response: (data) => ({
        type: PAYMENT_PAYINS.PAYMENT_PAYINS_API.SET_RESPONSE,
        payload: { data },
      }),
    },
  };

  export { PaymentPayIn};