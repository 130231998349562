import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/BreadCrumb";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import { Add, LocationOnOutlined, UpdateOutlined } from "@material-ui/icons";
import { useLocation, useNavigate, useParams } from "react-router";
import EditIcon from "../../../assets/icons/lucide_edit.svg";
import DeleteIcon from "../../../assets/icons/delete-outline.svg";
import BookingLogo from "../../../assets/icons/bookingName.png";
import AirplaneImage from "../../../assets/icons/aeroplane.svg";
import MOSwitch from "../../../components/commons/MOSwitch";
import AirportDetailCard from "../../../components/commons/AirportDetailCard";
import CardImage2 from "../../../assets/icons/mdi_plane-car.svg";
import CardImage1 from "../../../assets/icons/direction.svg";
import { RouteOutlined } from "@mui/icons-material";
import ReactCountryFlag from "react-country-flag";
import NotificationIcon from "../../../assets/icons/hugeicons_notification.svg";
import GoogleMapReact from "google-map-react";
import DeleteLocationModal from "./Modals/DeleteLocationModal";
import { countryCodeList } from "../../../data/countryCodeList";
import { connect } from "react-redux";
import mobioApi from "../../../services";
import { toastMessage } from "../../../helpers/toastMessage";

const Index = ({ token }) => {
  const navigate = useNavigate();
  const { integrationType, integrationId, country, countryId, city, cityId, airportId } =
    useParams();
  const stateData = useLocation();
  const [airportDetail, setAirportDetail] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const paths = [
    {
      name: airportDetail?.iata,
      url: "/dashboard/integration/rates/city-airport",
    },
  ];
  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },
    {
      name: airportDetail?.iata,
      url: "/dashboard/integration/rates/city",
    },
  ];
  const airportDetailList = [
    {
      Icon: () => <img src={CardImage1} />,
      titleText: "Furthest distance",
      detailText: "100 KM",
    },
    {
      Icon: () => (
        <RouteOutlined
          style={{
            color: "#29AEE6",
          }}
        />
      ),
      titleText: "Fixed Routes",
      detailText: "37",
    },
    {
      Icon: () => <img src={CardImage2} />,
      titleText: "Service Levels",
      detailText: "9",
    },
  ];
  const airportDetail2 = [
    {
      Icon: () => (
        <ReactCountryFlag
          countryCode={
            countryCodeList?.filter((list) => list.name === country)[0]?.code
          }
          svg
          style={{
            width: "31px",
            height: "23px",
          }}
        />
      ),
      titleText: "Country",
      detailText: country,
    },
    {
      Icon: () => (
        <LocationOnOutlined
          style={{
            color: "#29AEE6",
            width: "31px",
            height: "31px",
          }}
        />
      ),
      titleText: "City",
      detailText: city,
    },
    {
      Icon: () => (
        <UpdateOutlined
          style={{ color: "#29AEE6", width: "31px", height: "31px" }}
        />
      ),
      titleText: "Lead Time",
      detailText: airportDetail?.leadTime,
    },
  ];
  const handleAddLocationClick = () => {
    navigate(
      `/dashboard/integration/rates/add-location/${integrationType}/${integrationId}/add`
    );
  };

  const center = {
    lat: airportDetail?.centralZoneLatitude,
    lng: airportDetail?.centralZoneLongitude,
  }; // Example coordinates
  const zoom = 11;
  const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY; // Replace with your Google Maps API key
  const radius = airportDetail?.centralZone; // 60 km in meters

  const handleApiLoaded = (map, maps) => {
    // Create the circle
    new maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map,
      center,
      radius: radius * 100,
    });
  };

  useEffect(() => {
    if (stateData) {
      setAirportDetail(stateData.state);
    }
  }, []);

  const updateAirportStatus = async (id, status) => {
    try {
      const api = mobioApi(token);
      const response = await api.airportStatusUpdate(id, status);
      toastMessage("success", response?.data.msg);
    } catch (error) {
      toastMessage("error", error?.response?.data.msg);
    }
  };
  const deleteLocationHandler = async (id) => {
    try {
      const api = mobioApi(token);
      const response = await api.deleteLocation(id);
      setDeleteModalOpen(false);
      navigate(`/dashboard/integration/rates/${integrationType}/${integrationId}`);
      toastMessage("success", response?.data.msg);
    } catch (error) {
      toastMessage("error", error?.response?.data.msg);
    }
  };
  return (
    <div>
      <div className="mt-18 flex justify-content-between">
        <div>
          <Breadcrumb paths={paths} backType="auto" />

          <div className="mt-8 ml-6">
            <Breadcrumb paths={secondaryPaths} secondary={true} />
          </div>
        </div>
        <div className="flex">
          <MOButtonV2
            text="ADD LOCATION"
            textColor="#ffffff"
            radius={4}
            backgroundColor="#29AEE6"
            onClick={handleAddLocationClick}
            IconType={() => <Add style={{ marginRight: 10, fontSize: 16 }} />}
            height={30}
            fontSize={12}
            padding={"6px 64px 6px 64px"}
          />
          <div>
            <img
              src={EditIcon}
              alt="EditIcon"
              style={{ marginLeft: "15px", marginRight: "15px" }}
              className="cursor-pointer"
              onClick={() => {
                navigate("/dashboard/integration/rates/add-location");
              }}
            />
            <img
              src={DeleteIcon}
              alt="DeleteIcon"
              className="cursor-pointer"
              onClick={() => {
                setDeleteModalOpen(true);
              }}
            />
          </div>
        </div>
      </div>

      {/* Detail */}
      <div className="mt-18 flex">
        {/*left */}
        <div className="mr-24">
          {/* upper section */}
          <div className="flex gap-16">
            {/* section 1 */}
            <section className="airport-banner-detail-section">
              {/* logo */}
              <div style={{ textAlign: "right" }}>
                <img src={BookingLogo} className="logo-image" />
              </div>
              {/* detail */}
              <div className="flex justify-content-between">
                <div className="mt-20">
                  <div className="max-w-214">
                    <p className="m-0 font-12 font-500 text-white">Airport</p>
                    <p className="m-0 mt-8 font-20 font-600 text-white">
                      {airportDetail?.name}
                    </p>
                  </div>

                  <div className="mt-20">
                    <MOSwitch
                      isOnStatus={
                        airportDetail?.status === 1 ||
                        airportDetail?.status === "1" ||
                        airportDetail?.status === true
                          ? true
                          : false
                      }
                      setStatus={(status) => {
                        updateAirportStatus(airportDetail?.id, status ? 1 : 0);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <img src={AirplaneImage} alt="AirplaneImage" />
                </div>
              </div>
            </section>
            {/* section 2 */}
            <section>
              {airportDetailList?.map((list) => {
                return (
                  <div className="mb-20">
                    <AirportDetailCard
                      Icon={list.Icon}
                      titleText={list.titleText}
                      detailText={list.detailText}
                    />
                  </div>
                );
              })}
            </section>
          </div>
          {/* down section */}
          <section>
            <div className="flex gap-24">
              {airportDetail2?.map((list) => {
                return (
                  <div className="mb-20">
                    <AirportDetailCard
                      size="lg"
                      Icon={list.Icon}
                      titleText={list.titleText}
                      detailText={list.detailText}
                    />
                  </div>
                );
              })}
            </div>
          </section>
        </div>
        {/* right */}
        <div className="">
          <div className="airport-activities-section">
            <h3 className="m-0 font-15 font-600 mb-20 ">Activities</h3>

            {/*  */}
            {[1, 2, 3, 4, 5]?.map((_, index) => {
              return (
                <>
                  <div className="activity-notification flex" key={index}>
                    <div>
                      <div
                        className="notification-icon"
                        style={{
                          zIndex: "20",
                        }}
                      >
                        <img src={NotificationIcon} />
                      </div>
                      {index + 1 != 5 && (
                        <>
                          <div
                            className="flex align-item-center flex-col"
                            style={{
                              // border: "1px solid red",
                              flex: 1,
                              height: "calc(100% - 30px)",
                            }}
                          >
                            <div className="activity-notification-line"></div>
                          </div>
                        </>
                      )}
                    </div>

                    <div className="activity-message-detail mt-12 ml-16 ">
                      <div className="flex justify-content-between align-item-end ">
                        <h3 className="m-0 font-11 font-600">
                          Dispatcher Added
                        </h3>
                        <p className="m-0 font-8 font-700 text-primary">
                          Just Now
                        </p>
                      </div>
                      <div className="activity-description">
                        <p className="m-0 font-8 font-500 text-gray">
                          You have successfully added the dispatcher Mr. Azeez
                          Mahmood.
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>

      {/* map */}
      <div className="airport-detail-poly-map">
        <div className="bg-white main-section-spacing border-radius-8 airport-detail-poly-map-detail">
          <div className="flex gap-16 ">
            <div>
              <h4 className="m-0 font-13 font-500">Longitude</h4>
              <p className="m-0 font-18 font-600">
                {airportDetail?.centralZoneLongitude}
              </p>
            </div>
            <div>
              <h4 className="m-0 font-13 font-500">Latitude</h4>
              <p className="m-0 font-18 font-600 ">
                {airportDetail?.centralZoneLatitude}
              </p>
            </div>
            <div>
              <h4 className="m-0 font-13 font-500">Distance of Central Zone</h4>
              <p className="m-0 font-18 font-600">
                {airportDetail?.centralZone} KM
              </p>
            </div>
          </div>
        </div>

        <div className="add-poly-section">
          <div>
            <Add
              style={{
                width: "32px",
                height: "32px",
                color: "#28B0E1",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(
                  `/dashboard/integration/rates/${integrationType}/${integrationId}/add-polygon`,
                  {
                    state: {
                      integrationType,
                      country,
                      countryId,
                      city,
                      cityId,
                      airportId,
                      iata: airportDetail?.iata,
                      centerPoint: {
                        lat: airportDetail?.latitude,
                        lng: airportDetail?.longitude,
                      },
                    },
                  }
                );
              }}
            />
          </div>
        </div>
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey }}
          defaultCenter={center}
          defaultZoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        />
      </div>
      {/* Modal */}
      <DeleteLocationModal
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        apiCall={() => {
          deleteLocationHandler(countryId);
        }}
      />
    </div>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Index);
