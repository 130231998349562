import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Dialog,
  DialogTitle,
  makeStyles,
  Button,
  Typography,
  Box,
  InputAdornment,
} from "@material-ui/core";
import Input from "../../../components/commons/Input";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import ThunksPassengers from "../../../redux/actions/thunks/passengers";
import MuiPhoneNumber from "material-ui-phone-number";
import MOButton from "../../../components/commons/MOButton";
import { SignalPassengerIcon } from "../../../assets/icons";
import { EmailOutlined } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  disabled: {
    backgroundColor: "red",
  },
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    margin: theme.spacing(2),
  },
  button: {
    borderRadius: 6,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
    marginLeft: 10,

    "&:disabled": {
      backgroundColor: "#8bd2e8",
      borderRadius: 6,
      color: "#FFFFFF",
      height: 40,
      marginLeft: 10,
    },
  },
  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    height: 40,
  },
  textfield_lower: {
    height: 40,
  },
  textfield_side: {
    height: 40,
  },
  ExclusiveSelect: {
    marginRight: 26,
  },
  MultiSelect: {
    marginTop: 20,
  },
  select: {
    marginTop: 20,
    width: 310,
  },
  autoComplete: {
    backgroundColor: "red",
  },
  modalHeadingContainer: {
    padding: 0,
  },
}));

function SimpleDialog({ open, onClose, createPassenger, userData ,updatePassengers}) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [guestNameEmpty, setGuestNameEmpty] = useState(false);
  const [passengerCreate, setPassengerCreate] = useState(false);
  const [passengerUpdate, setPassengerUpdate] = useState(false);
  const [phoneDuplicated, setPhoneDuplicated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const classes = useStyles();
  useEffect(() => {
    if (userData) {
      setName(userData.passengerName);
      setEmail(userData.email);
      setPhone(userData?.phone);
    } else {
      setName("");
      setEmail("");
      setPhone("");
    }
  }, [userData]);
  
  return (
    <>
      <Dialog
        disableBackdropClick
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <div className={classes.dialogPaper}>
          <Grid>
            <Grid
              container
              direction="row"
              // justifyContent="center"
              alignItems="center"
            >
              <DialogTitle
                className={classes.modalHeadingContainer}
                id="simple-dialog-title"
              >
                <Typography style={{ fontWeight: 500, fontSize: 24 }}>
                  {userData ? "Edit Details" : " Add Passenger"}
                </Typography>
              </DialogTitle>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              <Grid item xs={12}>
                <Input
                  fullWidth
                  placeholder="Passenger Name"
                  size="small"
                  multiline
                  value={name}
                  className={classes.textfield_side}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SignalPassengerIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  fullWidth
                  placeholder="Passenger Email"
                  size="small"
                  multiline
                  value={email}
                  className={classes.textfield_side}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlined style={{ color: "#AAAAAA" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MuiPhoneNumber
                  fullWidth
                  variant="outlined"
                  placeholder="Phone Number"
                  defaultCountry={"nl"}
                  value={phone}
                  onChange={(value) => {
                    setPhone(value);
                  }}
                  className={classes.textfield_side}
                />
                {/* <Input
                  fullWidth
                  label="Phone Number "
                  size="small"
                  value={phone}
                  className={classes.textfield_side}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                /> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <MOButton
              variant="contained"
              type="secondary"
              icon={false}
              onClick={onClose}
              title="Cancel"
            />
            <Box style={{ marginLeft: 7 }}>
              <MOButton
                disabled={name == "" || phone == ""}
                onClick={async () => {
                  if (name != "" && phone != "") {
                    // let result = await createPassenger({
                    //   passengerName: name,
                    //   phone: phone,
                    //   email: email
                    // });
                    let result =null
                    console.log(userData)
                    if(userData?.id){
                      result = await updatePassengers(userData?.id, {
                      id:userData.id,
                      passengerName: name,
                      phone: phone,
                      email: email
                      });
                    }else{
                      console.log("create called")
                     result= await createPassenger({
                      passengerName: name,
                      phone: phone,
                      email: email
                    });
                    }
                    
                    if (result == true) {
                      if(userData){
                        setPassengerUpdate(true)
                      }else{

                      setPassengerCreate(true);
                      }
                      onClose();
                    } else {
                      setErrorMessage(result);
                    }
                  } else {
                    setGuestNameEmpty(true);
                  }
                }}
                icon={false}
                title={" Save"}
              />
            </Box>

            {/* <Button
              className={classes.button}
              disabled={name == "" || phone == ""}
              variant="contained"
              onClick={async () => {
                if (name != "" && phone != "") {
                  let result = await createPassenger({
                    passengerName: name,
                    phone: phone,
                  });
                  if (result == true) {
                    setPassengerCreate(true);
                    onClose();
                  } else {
                    setErrorMessage(result);
                  }
                } else {
                  setGuestNameEmpty(true);
                }
              }}
            >
              Create
            </Button> */}
          </Grid>
        </div>
      </Dialog>
      {errorMessage != "" && (
        <ErrorSnack reset={() => setErrorMessage("")} errorMsg={errorMessage} />
      )}
      {guestNameEmpty && (
        <ErrorSnack
          reset={() => setGuestNameEmpty(false)}
          errorMsg={"You must insert Guest Name and Phone Number."}
        />
      )}
      {phoneDuplicated && (
        <ErrorSnack
          reset={() => setPhoneDuplicated(false)}
          errorMsg={"Your Phone Number has already existed"}
        />
      )}
      {passengerCreate && (
        <ErrorSnack
          reset={() => setPassengerCreate(false)}
          success
          errorMsg={"Passenger Created"}
        />
      )}
      {passengerUpdate && (
        <ErrorSnack
          reset={() => setPassengerUpdate(false)}
          success
          errorMsg={"Passenger Updated"}
        />
      )}
    </>
  );
}
const mapState = (state) => ({});

const mapDispatch = {
  createPassenger: ThunksPassengers.createPassenger,
  updatePassengers: ThunksPassengers.updatePassengers,
};

export default connect(mapState, mapDispatch)(SimpleDialog);
