type ApiResponse = {
  status: boolean;
  data: any;
};

type ApiError = {
  status: boolean;
  type: string;
  message: string;
};

type ApiState = {
  loading: boolean;
  retry: boolean;
  response: ApiResponse;
  error: ApiError;
};

const apiState: ApiState = {
  loading: false,
  retry: false,
  response: {
    status: false,
    data: [],
  },
  error: {
    status: false,
    type: "",
    message: "",
  },
};

/* change after apis */
type ApiActions = {
  SET_REQUEST: string;
  SET_RESPONSE: string;
  SET_ERROR: string;
  SET_RETRY: string;
  RESET?: string;
};

function sharedApiReducer(actions: ApiActions) {
  const { SET_REQUEST, SET_RESPONSE, SET_ERROR, SET_RETRY, RESET } = actions;

  return function apiReducer(state: ApiState = apiState, action: any) {
    const { type, payload } = action;
    switch (type) {
      case RESET:
        return apiState;

      case SET_REQUEST:
        return {
          ...state,
          loading: true,
          response: { status: false, data: [] },
          error: { status: false, type: "", message: "" },
        };

      case SET_RESPONSE:
        return {
          ...state,
          loading: false,
          retry: false,
          response: {
            status: true,
            data: payload.data,
          },
          error: { status: false, type: "", message: "" },
        };

      case SET_ERROR:
        return {
          ...state,
          loading: false,
          /* turn this true if retry functionality needs to be included */
          retry: false,
          response: { status: false, data: [] },
          error: {
            status: true,
            type: payload.type,
            message: payload.message,
          },
        };

      case SET_RETRY:
        return {
          ...state,
          loading: false,
          retry: true,
          response: { status: false, data: [] },
          error: { status: false, type: "", message: "" },
        };
      default:
        return state;
    }
  };
}

export default sharedApiReducer;
