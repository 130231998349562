import {
  DIALOG,
  CLIENT_USER_API,
  CLIENT_AUTHORIZATIONS,
  CLIENT_USER_UPDATE_API,
  CLIENT_USER_GET_API,
  CLIENT_USER_DELETE_API,
  CLIENT_UPDATE_API,
  GET_CLIENT_AUTHORIZATIONS,
  UPDATE_CLIENT_USER_PASSWORD,
} from "../../actions/local/constants/clientUsers";

import clientUserState, { clientUsersState } from "../../initial-state/CustomerUsers";

const clientUsers = (state: clientUsersState = clientUserState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case CLIENT_USER_API.SET_RESPONSE: {
      return {
        ...state,
        createUser: { success: "Successfully Created User", error: "" },
      };
    }
    case CLIENT_USER_API.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        createUser: { success: "", error: message },
      };
    }
    case CLIENT_USER_API.RESET: {
      return {
        ...state,
        createUser: { success: "", error: "" },
      };
    }
    case CLIENT_AUTHORIZATIONS.SET_RESPONSE: {
      const { data } = payload;
      return {
        ...state,
        authorizations: data,
      };
    }
    case CLIENT_AUTHORIZATIONS.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        authorizationsError: message,
      };
    }
    case CLIENT_USER_GET_API.SET_RESPONSE: {
      const { data } = payload;

      return {
        ...state,
        allClientUsers: data,
      };
    }
    case CLIENT_USER_GET_API.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        allClientUsersError: message,
      };
    }
    case CLIENT_USER_DELETE_API.SET_RESPONSE: {
      return {
        ...state,
        deleteUser: { success: "Successfully Deleted User", error: "" },
      };
    }
    case CLIENT_USER_DELETE_API.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        deleteUser: { success: "", error: message },
      };
    }
    case CLIENT_USER_DELETE_API.RESET: {
      return {
        ...state,
        deleteUser: { success: "", error: "" },
      };
    }
    case CLIENT_USER_UPDATE_API.SET_RESPONSE: {
      return {
        ...state,
        updateUser: { success: "Successfully Updated User", error: "" },
      };
    }
    case CLIENT_USER_UPDATE_API.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        updateUser: { success: "", error: message },
      };
    }
    case CLIENT_USER_UPDATE_API.RESET: {
      return {
        ...state,
        updateUser: { success: "", error: "" },
      };
    }
    case CLIENT_UPDATE_API.SET_RESPONSE: {
      return {
        ...state,
        updateCompleteUser: { success: "Successfully Updated User", error: "" },
      };
    }
    case CLIENT_UPDATE_API.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        updateCompleteUser: { success: "", error: message },
      };
    }
    case CLIENT_UPDATE_API.RESET: {
      return {
        ...state,
        updateCompleteUser: { success: "", error: "" },
      };
    }
    case UPDATE_CLIENT_USER_PASSWORD.SET_RESPONSE: {
      return {
        ...state,
        updatePass: { success: "Successfully Updated Password", error: "" },
      };
    }
    case UPDATE_CLIENT_USER_PASSWORD.SET_ERROR: {
      const { message } = payload;
      return {
        ...state,
        updatePass: { success: "", error: message },
      };
    }
    case UPDATE_CLIENT_USER_PASSWORD.RESET_ERROR: {
      return {
        ...state,
        updatePass: { success: "", error: "" },
      };
    }
    default:
      return state;
  }
};

export default clientUsers;
