import { CREATE_LOCATION } from "../../actions/local/constants/rateModule";
import createAddLocationState, {
  CreateAddLocationState,
} from "../../initial-state/rate-module";

const locationReducer = (
  state: CreateAddLocationState = createAddLocationState,
  action: any
) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_LOCATION.RESET_ALL: {
      return {
        ...createAddLocationState,
      };
    }
    case CREATE_LOCATION.FIELD.UPDATE: {
      const { field, newContent } = payload;
      return {
        ...state,
        [field]: newContent,
      };
    }
    case CREATE_LOCATION.FIELD.CLEAR: {
      const { field } = payload;
      return {
        ...state,
        [field]: "",
      };
    }
    default:
      return state;
  }
};

export default locationReducer;
