import React from "react";
import Tooltip from "@mui/material/Tooltip";

const ToolTipMessage = ({
  placement = "top-start",
  children,
  content = "My New Message",
  type = "",
}) => {
  return (
    <Tooltip
      arrow
      title={content}
      placement={placement}
      className={"primary-tooltip"}
      componentsProps={{
        tooltip: {
          sx: {
            color: "#ffffff",
            backgroundColor: "#29AEE6",
            borderRadius: "6px",
            padding: "8px 12px",
          },
        },
        arrow: {
          sx: {
            color: "#29AEE6",
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default ToolTipMessage;
