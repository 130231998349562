import React from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PublicLayout from "../containers/public";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../containers/private";
import Privacy from "../containers/public/privacy";
import PrivacyPolicy from "../containers/public/privacyPolicy";
import Landing from "../containers/public/landing";

interface IRouterProps {}

const Router: React.FunctionComponent<IRouterProps> = (props) => {
  return (
    <Routes>
      <Route element={<Landing />} path="/" />
      <Route element={<PrivacyPolicy />} path={"/privacy-policy"} />
      <PublicRoute component={PublicLayout} path={"/login"} />
      <PublicRoute component={PublicLayout} path={"/sign-up"} />
      <PublicRoute component={Privacy} path={"/privacy"} />
      <PublicRoute component={PublicLayout} path={"/forgot-password/customer"} />
      <PublicRoute component={PublicLayout} path={"/forgot-password/enterprise"} />
      <PublicRoute component={PublicLayout} path={"/customer"} />
      <PrivateRoute component={Dashboard} path={"/dashboard/*"} />
      <Route path="*" element={<Navigate to={"/login"} />} />
    </Routes>
  );
};

export default Router;
