/* pop ups and other visibilities that can get toggled on */

export type VisibilitiesState = {
  editDialog: boolean;
};

const visibilitiesState: VisibilitiesState = {
  editDialog: false,
};

export default visibilitiesState;
