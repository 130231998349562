import MOModalWrapper from "../../../components/commons/MOModalWrapper";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import DeleteIcon from "../../../assets/icons/delete-outline.svg";

const ConformModal = ({
  modalOpen,
  setModalOpen,
  title = "Title",
  description = "",
  apiCall = () => {},
  heading = "Are You Sure?",
  icon = DeleteIcon,
  successBtnText = "YES",
}) => {
  return (
    <MOModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={title}
      className={"delete-modal-section"}
    >
      <div className="mt-30">
        <div className="flex flex-col align-item-center">
          <img src={icon} alt="disconnection icon" />
          <h2 className="font-20 font-600 m-0 mt-24 ">{heading}</h2>
          <p className="m-0 mt-8">{description}</p>
          <div className="flex align-item-center justify-content-end mt-50 mb-56">
            <div className="mr-20">
              <MOButtonV2
                text="NO, Cancel"
                textColor="#1D2433"
                radius={7}
                backgroundColor="#ffffff"
                border="1px solid #E0E0E0"
                fontSize={12}
                height={38}
                onClick={() => {
                  setModalOpen(false);
                }}
              />
            </div>
            <div>
              <MOButtonV2
                text={successBtnText}
                textColor="#FCFCFD"
                radius={7}
                backgroundColor="#2CA9DD"
                fontSize={12}
                height={38}
                onClick={() => {
                  apiCall();
                }}
                // border="1px solid #FC0808"
              />
            </div>
          </div>
        </div>
      </div>
    </MOModalWrapper>
  );
};

export default ConformModal;
