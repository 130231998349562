import VISIBILITIES from "../constants/visibilities";

export type LocalVisibilitiesRepo = {
  visibilitiesToggle: (field: string, newState: boolean) => any;
};

const Visibilities: LocalVisibilitiesRepo = {
  visibilitiesToggle: (field, newState) => ({
    type: VISIBILITIES.TOGGLE,
    payload: { field, newState },
  }),
};

export default Visibilities;
