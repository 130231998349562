import { format, parse, isDate } from "date-fns";

export const getTimeFormat24Hour = (time) => {
  // return format(time, "HH:mm:ss");
  let date;
  if (isDate(time)) {
    date = time;
  } else if (
    typeof time === "string" &&
    time.includes(":") &&
    time.length === 5
  ) {
    date = parse(time, "HH:mm", new Date());
  } else {
    date = new Date(time);
  }
  return format(date, "HH:mm:ss");
};

export const convertTimeStringToDate = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  return date;
};

export const removeLast00 = (timeStr) => {
  const parts = timeStr.split(":");
  if (parts.length > 2) {
    return parts.slice(0, -1).join(":");
  }
  return timeStr;
};

export const makeItHours = (hour) => {
  if (hour < 0 || hour > 24) {
    return "Invalid hour";
  }
  const formattedHour = hour.toString().padStart(2, "0");
  return `${formattedHour}:00`;
};

export const formatDate = (inputDate) => {
  if (inputDate) return format(new Date(inputDate), "yyyy-MM-dd");
};
