import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const BookingValueProgress = () => {
  return (
    <div style={{ width: "100px", height: "100px" }}>
      <section style={{ position: "relative" }}>
        <div style={{ width: 80, height: 80, position: "absolute", top: 0 }}>
          <CircularProgressbar
            value={86}
            strokeWidth={5}
            styles={buildStyles({
              pathColor: `#29AEE6`,
            })}
          />
        </div>
        <div
          style={{
            width: 62,
            height: 62,
            position: "absolute",
            top: "9px",
            left: "9px",
          }}
        >
          <CircularProgressbar
            value={76}
            strokeWidth={7}
            // background="#28B0E180"
            styles={buildStyles({
              pathColor: `#28B0E180`,
            })}
          />
        </div>
        <div
          style={{
            width: 44,
            height: 44,
            position: "absolute",
            top: "18px",
            left: "18px",
          }}
        >
          <CircularProgressbar
            value={66}
            strokeWidth={10}
            // background="#0000FF"
            styles={buildStyles({
              pathColor: `#0000FF`,
            })}
          />
        </div>
      </section>
    </div>
  );
};

export default BookingValueProgress;
