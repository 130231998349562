import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ThunksCustomers from "../../../redux/actions/thunks/customer";
import mobioApi from "../../../services";
import ErrorSnack from "../../../components/commons/ErrorSnack";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    width: "80%",
    color: "#fff",
    alignSelf: "center",
    marginTop: 10,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

function DispatcherAssign({ getDispatchers, dispatchers, token, setOpen, setloading, type  }) {
  const [loadering, setLoadering] = useState(true);
  const [success, setSuccess] = useState(false);
  const [selected, setSelected] = useState("");

  const classes = useStyles();
  const isAllSelected =
    dispatchers.length > 0 && selected.length === dispatchers.length;

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const setDispatcher = () => {
    const api = mobioApi(token);
    api
    .postIntegrationDispatcher({ dispatcherId: selected, type: type })
      .then((res) => {
        setSuccess(true);
        setLoadering(true);
        setOpen(false)
        setloading(true)
      })
      .catch((err) => alert("error"));
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel id="mutiple-select-label">Select Dispatcher</InputLabel>
        <Select
          labelId="mutiple-select-label"
          value={selected}
          onChange={handleChange}
          renderValue={(selected) =>
            dispatchers
              .filter((item) => selected.includes(item.id))
              .map((item) => item.displayName)
              .join(", ")
          }
          MenuProps={MenuProps}
        >
          <MenuItem
            value="all"
            classes={{
              root: isAllSelected ? classes.selectedAll : "",
            }}
          >
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary="Select One"
            />
          </MenuItem>
          {dispatchers?.map((option) => (
            <MenuItem key={option} value={option.id}>
              <ListItemText primary={option?.displayName || option.fullName} />
            </MenuItem>
          ))}
        </Select>
        <Button
          size="large"
          color="primary"
          variant="contained"
          onClick={() => setDispatcher()}
          className={classes.button}
        >
          Set Dispatcher
        </Button>
      </FormControl>
      {success && (
        <ErrorSnack
          success
          reset={() => setSuccess(false)}
          errorMsg={"Dispatcher Updated Successfully."}
        />
      )}
    </>
  );
}

const mapState = (state) => ({
  token: state.userInfo.token,
  dispatchers: state.customerDispatcher.response.data,
});
const mapDispatch = {
  getDispatchers: ThunksCustomers.getCustomerDispatchers,
};

export default connect(mapState, mapDispatch)(DispatcherAssign);
