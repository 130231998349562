export const ONBOARDING_LISTING_API = {
  SET_REQUEST: "ACTION.ONBOARDING_LISTING.SET_REQUEST",
  SET_RESPONSE: "ACTION.ONBOARDING_LISTING.SET_RESPONSE",
  SET_ERROR: "ACTION.ONBOARDING_LISTING.SET_ERROR",
  SET_RETRY: "ACTION.ONBOARDING_LISTING.SET_RETRY",
};

const ONBOARDING_LISTING = {
  ONBOARDING_LISTING_API,
};

export default ONBOARDING_LISTING;
