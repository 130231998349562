import React, { ReactElement } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Props {
  errorMsg: any;
  success?: boolean;
  reset?: () => any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function ErrorSnack({
  errorMsg,
  success,
  reset,
}: Props): ReactElement {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    typeof reset !== "undefined" && reset();
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={success ? "success" : "error"}>
          {errorMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
