import {
    SUB_ADMIN_API,
    SUB_ADMIN_AUTHORIZATIONS,
    SUB_ADMIN_UPDATE_API,
    SUB_ADMIN_GET_API,
    SUB_ADMIN_DELETE_API,
    USER_UPDATE_API,
    GET_USER_AUTHORIZATIONS,
    UPDATE_SUB_ADMIN_PASSWORD,
  } from "../constants/subAdmins";
  
  export type CreateSubUser = {
    request: (data: any) => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
  
  const CreateSubAdminUser: CreateSubUser = {
    request: (data) => ({
      type: SUB_ADMIN_API.SET_REQUEST,
      payload: { data },
    }),
    error: (type, message) => ({
      type: SUB_ADMIN_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: SUB_ADMIN_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: SUB_ADMIN_API.RESET,
      payload: {},
    }),
  };
  
  export type Authorizations = {
    request: (data: any) => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
  
  const GetSubAdminAllAuthorizations: Authorizations = {
    request: (data) => ({
      type: SUB_ADMIN_AUTHORIZATIONS.SET_REQUEST,
      payload: { data },
    }),
    error: (type, message) => ({
      type: SUB_ADMIN_AUTHORIZATIONS.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: SUB_ADMIN_AUTHORIZATIONS.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: SUB_ADMIN_AUTHORIZATIONS.RESET,
      payload: {},
    }),
  };
  
  export type UpdateSub = {
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
  
  const UpdateSubAdminUser: UpdateSub = {
    error: (type, message) => ({
      type: SUB_ADMIN_UPDATE_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: SUB_ADMIN_UPDATE_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: SUB_ADMIN_UPDATE_API.RESET,
      payload: {},
    }),
  };
  
  export type AllSubs = {
    request: (data: any) => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
  
  const getAllSubAdmins: AllSubs = {
    request: (data) => ({
      type: SUB_ADMIN_GET_API.SET_REQUEST,
      payload: { data },
    }),
    error: (type, message) => ({
      type: SUB_ADMIN_GET_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: SUB_ADMIN_GET_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: SUB_ADMIN_GET_API.RESET,
      payload: {},
    }),
  };
  
  export type DeleteSub = {
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
  
  const deleteSubAdmin: DeleteSub = {
    error: (type, message) => ({
      type: SUB_ADMIN_DELETE_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: SUB_ADMIN_DELETE_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: SUB_ADMIN_DELETE_API.RESET,
      payload: {},
    }),
  };
  
  export type UpdateSubComplete = {
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
  
  const UpdateCompleteSubAdmin: UpdateSubComplete = {
    error: (type, message) => ({
      type: USER_UPDATE_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: USER_UPDATE_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: USER_UPDATE_API.RESET,
      payload: {},
    }),
  };
  
  export type getSubAuthrz = {
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
  const getSubAdminAuthorizations: getSubAuthrz = {
    error: (type, message) => ({
      type: GET_USER_AUTHORIZATIONS.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: GET_USER_AUTHORIZATIONS.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: GET_USER_AUTHORIZATIONS.RESET_ERROR,
    }),
  };
  export type updateSubPass = {
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
  const UpdateSubAdminPasswordError: updateSubPass = {
    error: (type, message) => ({
      type: UPDATE_SUB_ADMIN_PASSWORD.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: UPDATE_SUB_ADMIN_PASSWORD.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: UPDATE_SUB_ADMIN_PASSWORD.RESET_ERROR,
    }),
  };
  
  export {
    CreateSubAdminUser,
    GetSubAdminAllAuthorizations,
    UpdateSubAdminUser,
    getAllSubAdmins,
    deleteSubAdmin,
    UpdateCompleteSubAdmin,
    getSubAdminAuthorizations,
    UpdateSubAdminPasswordError,
  };