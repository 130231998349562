import visibilitiesState, {
  VisibilitiesState,
} from "../../initial-state/visibilities";
import VISIBILITIES from "../../actions/local/constants/visibilities";

/*
Reducer to toggle different component visibilities
*/
const visibilitiesReducer = (
  state: VisibilitiesState = visibilitiesState,
  action: any
) => {
  const { type, payload } = action;

  switch (type) {
    case VISIBILITIES.TOGGLE: {
      const { field, newState } = payload;
      return {
        ...state,
        [field]: newState,
      };
    }
    default:
      return state;
  }
};

export default visibilitiesReducer;
