import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface IPressableProps {
  title: string;
  size?: "large" | "medium" | "small";
  onClick?: any;
  loading?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: "2.5rem",
    fontWeight: "bold",
    borderRadius: 12,
    textTransform: "none",
    boxShadow: "none",
  },
  loadingWrapper: {
    paddingTop: "0.06rem",
    paddingBottom: "0.06rem",
  },
}));

const Pressable: React.FunctionComponent<IPressableProps> = ({
  title,
  loading = false,
  disabled = false,
  size,
  onClick,
}) => {
  const classes = useStyles();
  /* Styling remains */
  return (
    <Button
      className={classes.button}
      onClick={onClick}
      disabled={disabled}
      variant="contained"
      color="primary"
      size={size ? size : "medium"}
    >
      {loading ? (
        <div className={classes.loadingWrapper}>
          <CircularProgress size={"1.0rem"} />
        </div>
      ) : (
        `${title}`
      )}
    </Button>
  );
};

export default Pressable;
