import Local from "../../local";

export type ThunksSubAdmin = {
  getAllAuthorizations: () => any;
  UpdateSubAdminAuthorizations: (data: string) => any;
  createSubAdmin: (data: string) => any;
  subAdmins: () => any;
  deleteSubAdmin: (data: any) => any;
  updateSubAdmin: (data: any) => any;
  getAuthorities: (data: any) => any;
  blockSubAdmin: (data: any, blockCheck: any) => any;
  updateSubAdminPassword: (data: any) => any;
};

const SubAdminThunks: ThunksSubAdmin = {
  getAllAuthorizations: () => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .getSubAdminAllAuthorities()
      .then((res: any) => {
        dispatch(Local.GetSubAdminAllAuthorizations.response(res.data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = error;
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          }
          dispatch(
            Local.GetSubAdminAllAuthorizations.error("Request Failed", errorMsg)
          );
        }
      });
  },
  createSubAdmin: (data) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .createSubAdmin(data)
      .then((res: any) => {
        console.log("resposnse create user", res);
        dispatch(Local.CreateSubAdminUser.response(res.data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = "Error Creating User";
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          }
          dispatch(Local.CreateSubAdminUser.error("Request Failed", errorMsg));
        }
      });
  },
  subAdmins: () => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .getSubAdmins()
      .then((res: any) => {
        dispatch(Local.getAllSubAdmins.response(res.data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = error;
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          }
          dispatch(Local.getAllSubAdmins.error("Request Failed", errorMsg));
          alert(errorMsg);
        }
      });
  },
  deleteSubAdmin: (data) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .deleteSubAdmin(data)
      .then((res: any) => {
        dispatch(Local.deleteSubAdmin.response(res.data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = "Error Deleting User";
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          }
          dispatch(Local.deleteSubAdmin.error("Request Failed", errorMsg));
        }
      });
  },
  UpdateSubAdminAuthorizations:
    (data) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .updateSubAdminAuthorizations(data)
        .then((res: any) => {
          dispatch(Local.UpdateSubAdminUser.response(res.data));
        })
        .catch((error: any) => {
          console.log("error", error);

          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = "Cannot Update User";
            if (error?.response?.data?.message) {
              errorMsg = error?.response?.data?.message;
            }
            dispatch(
              Local.UpdateSubAdminUser.error("Request Failed", errorMsg)
            );
          }
        });
    },
  updateSubAdmin: (data) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .updateSubAdmin(data)
      .then((res: any) => {
        // dispatch(Local.UpdateCompleteSubAdmin.response(res.data));
        dispatch(Local.UpdateSubAdminUser.response(res.data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = "Cannot Update User";
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          }
          dispatch(
            Local.UpdateCompleteSubAdmin.error("Request Failed", errorMsg)
          );
        }
      });
  },
  getAuthorities: (data) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    return api
      .getSubAdminAuthorities({ id: data })
      .then((res: any) => {
        dispatch(Local.getSubAdminAuthorizations.response(res.data));
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(alert("The API request timed out. Please Refresh the page"));
        } else {
          let errorMsg = "Authorities Not Found";
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          }
          dispatch(Local.getSubAdminAuthorizations.error("Request Failed", errorMsg));
        }
      });
  },
  blockSubAdmin:
    (data, blockCheck) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .blockSubAdmin({ id: data, isBlocked: blockCheck })
        .then((res: any) => {})
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = "User Not Blocked";
            if (error?.response?.data?.message) {
              errorMsg = error?.response?.data?.message;
            }
          }
        });
    },
  updateSubAdminPassword:
    (data) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .updateSubAdminPass(data)
        .then((res: any) => {
          dispatch(Local.UpdateSubAdminPasswordError.response(res.data));
        })
        .catch((error: any) => {
          if (error.code === "ECONNABORTED") {
            dispatch(
              alert("The API request timed out. Please Refresh the page")
            );
          } else {
            let errorMsg = "Password Update Error";
            if (error?.response?.data?.message) {
              errorMsg = error?.response?.data?.message;
            }
            dispatch(
              Local.UpdateSubAdminPasswordError.error(
                "Request Failed",
                errorMsg
              )
            );
          }
        });
    },
};

export default SubAdminThunks;