import {
  FlightOutlined,
  LocationCityOutlined,
  Room as LocationIcon,
  LocationOnOutlined,
} from "@material-ui/icons";
import DropDown from "./DropDown";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../assets/icons/menuDelete.png";
import EnableIcon from "../../assets/icons/enable_menu_icon.svg";
import DisableIcon from "../../assets/icons/diable_menu_icon.svg";
import AirplaneIcon from "../../assets/icons/airplane_menu_icon.svg";

const CityCard = ({
  data,
  onClick,
  anchorEl,
  setAnchorEl,
  setDeleteCityModal = () => {},
  setSingleData = () => {},
  setStatusCityModal = () => {},
  integrationType,
  integrationId,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`city-page-section ${
        !data?.isActive && "country-card-section-disable"
      }`}
      // onClick={onClick}
    >
      <div className="flex align-item-center">
        <div>
          <LocationOnOutlined style={{ color: "#34abe6", fontSize: 21 }} />{" "}
        </div>
        <div className="city-name">
          <h1 className="m-0">{data?.name}</h1>
        </div>
      </div>
      <div>
        {/* <img src={ArrowIcon} /> */}
        <DropDown
          dropDownOpen={anchorEl}
          setDropDownOpen={setAnchorEl}
          uniqueId={data.id}
          // IconRender={() => <img src={HorizontalIcon} />}
          dropDownShape="vertical"
          menu={
            data.isActive
              ? [
                  {
                    onClick: () => {
                      onClick();
                    },
                    IconRender: () => <img src={AirplaneIcon} />,
                    title: "Airport",
                  },
                  {
                    onClick: () => {
                      setSingleData(data);
                      setStatusCityModal(true);
                    },
                    IconRender: () => <img src={DisableIcon} />,
                    title: "Disable",
                  },
                ]
              : [
                  {
                    onClick: () => {
                      navigate(
                        `/dashboard/integration/rates/${integrationType}/${integrationId}/${data.countryName}/${data?.id}/city`
                      );
                    },
                    IconRender: () => <img src={AirplaneIcon} />,
                    title: "Airport",
                  },
                  {
                    onClick: () => {
                      setSingleData(data);
                      setDeleteCityModal(true);
                    },
                    IconRender: () => <img src={DeleteIcon} />,
                    title: "Delete",
                  },
                  {
                    onClick: () => {
                      setSingleData(data);
                      setStatusCityModal(true);
                    },
                    IconRender: () => <img src={EnableIcon} />,
                    title: "Enable",
                  },
                ]
          }
        />
      </div>
    </div>
  );
};

export default CityCard;
