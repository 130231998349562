import GooglePlacesAutocomplete from "react-google-places-autocomplete";
function LocationView({ location, onChange, placeholder }) {
  function getObj() {
    if (typeof location.name == "object" || location.name == "") {
      console.log(location.name);
      return location.name;
    }
    if (typeof location.name == "string") {
      let newLocation = { label: location.name, value: {} };
      return newLocation;
    }
  }
  return (
    <GooglePlacesAutocomplete
      apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
      debounce={200}
      autocompletionRequest={{
        componentRestrictions: {
          country: ["nl"],
        },
      }}
      selectProps={{
        value: getObj(),
        onChange: onChange,
        placeholder: placeholder,

        styles: {
          container: () => ({
            marginTop: 14,
          }),
          input: (provided) => ({
            ...provided,
            color: "grey",
          }),
          option: (provided) => ({
            ...provided,
            color: "grey",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "grey",
          }),
          menu: (provided) => ({
            // ...provided,
            backgroundColor: "FFFFF",
          }),
        },
      }}
    />
  );
}

export default LocationView;
