export const NETWORKS_API = {
    SET_REQUEST: "ACTION.NETWORKS.SET_REQUEST",
    SET_RESPONSE: "ACTION.NETWORKS.SET_RESPONSE",
    SET_ERROR: "ACTION.NETWORKS.SET_ERROR",
    SET_RETRY: "ACTION.NETWORKS.SET_RETRY",
  };

  const NETWORKS = {
    RESET_ALL: "ACTION.LOCAL.NETWORKS.RESET_ALL",
    NETWORKS_API,
  };

export default NETWORKS;