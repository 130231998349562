import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/BreadCrumb";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import { Add, Search } from "@material-ui/icons";
import CityCard from "../../../components/commons/CityCard";
import mobioApi from "../../../services";
import { connect } from "react-redux";
import AddCityModal from "./Modal/AddCityModal";
import StatusCityModal from "./Modal/StatusCityModal";
import { toastMessage } from "../../../helpers/toastMessage";
import DeleteModal from "../../../components/commons/Modals/DeleteModal";

const CityPage = ({ token }) => {
  const navigate = useNavigate();
  const [cityList, setCityList] = useState([]);
  const [dumpCityList, setDumpCityList] = useState([]);
  const [addCityModal, setAddCityModal] = useState(false);

  const [deleteCityModal, setDeleteCityModal] = useState(false);
  const [statusCityModal, setStatusCityModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState("");
  const [singleData, setSingleData] = useState("");

  const { integrationType, integrationId, id, country } = useParams();

  const paths = [
    {
      name: `Location - ${country} `,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
  ];

  const getCities = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getCountryCities(id);
      setCityList(response?.data?.data);
      setDumpCityList(response?.data?.data);
    } catch (error) {
      setCityList([]);
      setDumpCityList([]);
    }
  };

  const statusCity = async (data) => {
    try {
      const payload = {
        countryId: data?.countryId,
        name: data?.name,
        isActive: !data.isActive,
        isDeleted: data?.isDeleted,
      };
      const api = mobioApi(token);
      await api.addNewCity(payload);
      toastMessage("success", "City status has been changed");
      const updateList = dumpCityList?.map((item) => {
        if (item?.id === data?.id) {
          data.isActive = !data?.isActive;
        }

        return item;
      });
      setDumpCityList(updateList);
      setCityList(updateList);
      setStatusCityModal(false);
      setSingleData("");
    } catch (error) {
      toastMessage("error", error?.response?.data.msg);
    }
  };

  const deleteCity = async (id) => {
    try {
      const api = mobioApi(token);
      const response = await api.deleteCountryCity(id);
      toastMessage("success", response?.data.msg);
      const updateList = dumpCityList?.filter((item) => item?.id !== id);
      setDumpCityList(updateList);
      setCityList(updateList);
      setDeleteCityModal(false);
      setSingleData("");
    } catch (error) {
      toastMessage("error", error?.response?.data.msg);
    }
  };

  useEffect(() => {
    getCities();
  }, []);

  return (
    <div>
      <div
        className="mt-18"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Breadcrumb paths={paths} />
          <div className="breadcrumb-description">
            Use this area to manage the area your fleet operates from
          </div>
        </div>
        <div>
          <MOButtonV2
            text="ADD CITY"
            textColor="#ffffff"
            radius={4}
            backgroundColor="#29AEE6"
            // onClick={handleAddLocationClick}
            onClick={() => {
              setAddCityModal(true);
            }}
            IconType={() => <Add style={{ marginRight: 10, fontSize: 16 }} />}
            height={30}
            fontSize={12}
            padding={"6px 78px 6px 78px"}
          />
        </div>
      </div>
      <div className="bg-white border-radius-8 pt-24  pb-40 pr-24 pl-34 mt-20">
        <div className="flex align-item-center justify-content-center">
          <div className="search-lg-section">
            <Search />
            <input
              placeholder="Search"
              onChange={(e) => {
                const value = e.target.value;
                if (value) {
                  const results = dumpCityList.filter((item) =>
                    item.name.toLowerCase().includes(value.toLowerCase())
                  );
                  setCityList(results);
                } else {
                  setCityList(dumpCityList);
                }
              }}
            />
          </div>
        </div>
        {/* title */}
        <div className="mt-24">
          <h1 className="font-17 font-500 text-align-center">
            Available Cities{" "}
          </h1>
        </div>
        <div className="rate-city-main-body mt-24 ">
          {cityList.map((data) => (
            <CityCard
              data={data}
              key={data.id}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              integrationType={integrationType}
              integrationId={integrationId}
              onClick={() => {
                navigate(
                  `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${id}/${data.name}/${data.id}/city-airport`
                );
              }}
              setDeleteCityModal={setDeleteCityModal}
              setSingleData={setSingleData}
              setStatusCityModal={setStatusCityModal}
            />
          ))}
        </div>
      </div>
      {addCityModal && (
        <AddCityModal
          modalOpen={addCityModal}
          setModalOpen={setAddCityModal}
          country={country}
          countryId={id}
          apiCall={getCities}
          token={token}
        />
      )}
      {statusCityModal && (
        <StatusCityModal
          modalOpen={statusCityModal}
          setModalOpen={setStatusCityModal}
          data={singleData}
          apiCall={() => {
            statusCity(singleData);
          }}
        />
      )}
      {deleteCityModal && (
        <DeleteModal
          modalOpen={deleteCityModal}
          setModalOpen={setDeleteCityModal}
          title="Delete City"
          heading="Are You Sure?"
          description="Are you certain you want to delete this city?"
          apiCall={() => {
            deleteCity(singleData?.id);
          }}
        />
      )}
    </div>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(CityPage);
