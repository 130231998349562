import REPORT from "../constants/report";

export type Field = "startDate" | "endDate" ;

export type LocalReportRepo = {
  resetAll: () => any;
  field: {
    update: (field: Field, newContent: string) => any;
    clear: (field: Field) => any;
  };
};

const Report: LocalReportRepo = {
  resetAll: () => ({
    type: REPORT.RESET_ALL,
    payload: {},
  }),
  field: {
    update: (field, newContent) => ({
      type: REPORT.FIELD.UPDATE,
      payload: { field, newContent },
    }),
    clear: (field) => ({
      type: REPORT.FIELD.CLEAR,
      payload: { field },
    }),
  },
};

export default Report;
