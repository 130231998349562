import { useState, useEffect } from "react";

const DisplayName = ({item, getDisplayNameByClientId, getDisplayNameByUserId}) =>{

     const [name, setName] = useState("")
     const showDisplayName = async (item) => {
        if(item.cancelledByUser !== "") {
            const result = await getDisplayNameByUserId(item.cancelledByUser);
            setName(result.displayName)
        }
        if(item.cancelledByClient !== "") {
          const result = await getDisplayNameByClientId(item.cancelledByClient);
          setName(result.firstName + " " + result.lastName)
         }
      }

      useEffect(()=>{
        showDisplayName(item)
      },[item])
    
    return (
        <div style={{ width: "50%" }}>
            <h3 style={{ margin: 0, fontSize: "15px" }}>
            { item.cancelledByUser !== "" ? "Cancelled By User": "Cancelled By Client"}
            </h3>
            <span>{name}</span>
        </div>
    )
}
export default DisplayName