export type clientUsersState = {
  dialog: {
    isOpen: Boolean;
    selectedUser: any;
  };
  createUser: { success: String; error: String };
  deleteUser: { success: String; error: String };
  updateUser: { success: String; error: String };
  updatePass: { success: String; error: String };
  updateCompleteUser: { success: String; error: String };
  authorizations: Array<{ id: string; name: number }>;
  authorizationsError: any;
  allClientUsers: any;
  allClientUsersError: any;
  userAuthorities: any;
  userAuthoritiesError: any;
};

const clientUserState: clientUsersState = {
  dialog: {
    isOpen: false,
    selectedUser: {},
  },
  createUser: { success: "", error: "" },
  deleteUser: { success: "", error: "" },
  updateUser: { success: "", error: "" },
  updatePass: { success: "", error: "" },
  updateCompleteUser: { success: "", error: "" },
  authorizationsError: {},
  authorizations: [],
  allClientUsers: [],
  allClientUsersError: {},
  userAuthorities: [],
  userAuthoritiesError: {},
};

export default clientUserState;
