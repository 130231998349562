import moment from "moment";

export const finalPriceCalculator = (commissionPercentage, roundable, price) => {
    try {
        let commission = price - (commissionPercentage * price) / 100;
        if (roundable) {
            // round up by 5. ex 82 => 85
            commission = Math.floor(commission / 5) * 5;
        }

        return Math.round(commission);
    } catch (error) {

    }

}

export const finalPriceCalculation = (booking, role, commissions, isRideEnded = false) => {
    try {
        booking.finalPrice = commissionCheckAndCalculation(booking, role, commissions, isRideEnded)
        // all those booking which doesn't fall into any commission rule will display 0
        if (!booking.finalPrice)
            booking.finalPrice = 0;
        return booking.finalPrice;
    } catch (error) {

    }

}

export const commissionCheckAndCalculation = (booking, role, commissions, isRideEnded) => {
    let DP = 0;
    try {
        if (booking.fromWebhook && booking.finalPrice == null && !isRideEnded && (role === 'Dispatcher' || role === 'Driver' || role === 'Organization' || role === 'OrgUser') && commissions.length > 0) {
            commissions.map(rule => {
                if (rule.apply && booking.carType == rule.serviceLevel) {
                    let applicable = CheckTimeValidity(rule.fromTime, rule.toTime, booking.dateTime.split(".")[0])
                    if (applicable) {
                        DP = finalPriceCalculator(rule.commission, rule.roundable, Number(booking.fare));
                    }
                }
            })
        } else if (!booking.fromWebhook) {
            commissions.map(rule => {
                if (rule.apply && booking.carType == rule.serviceLevel) {
                    let applicable = CheckTimeValidity(rule.fromTime, rule.toTime, booking.dateTime)
                    console.log(applicable)
                    if (applicable) {
                        DP = finalPriceCalculator(rule.commission, rule.roundable, Number(booking.fare));
                    }
                }
            });
            if (!DP) {
                DP = 0;
            }
        }
        return DP;
    } catch (error) {

    }

}

export const CheckTimeValidity = (startTime, endTime, dateTobeCompared) => {
    const newDateTime = moment(dateTobeCompared).format("HH:mm:ss")
    const beginningTime = moment(startTime, 'HH:mm:ss');
    const endingTime = moment(endTime, 'HH:mm:ss');
    const givenTime = moment(newDateTime, 'HH:mm:ss');
    const isBetween = givenTime.isBetween(beginningTime, endingTime);
    if (isBetween) {
        return true
    } else {
        return false
    }
}
