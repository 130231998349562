import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Slide,
  Typography,
  makeStyles,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { Button } from "@mui/material";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import { emptyValidation, emailValidation } from "../../../validations";
import ThunksPassengers from "../../../redux/actions/thunks/passengers";
import { exportToExcel } from "react-json-to-excel";
import AddPassenger from "./AddPassenger";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import MOButton from "../../../components/commons/MOButton";
import {
  Block,
  ChevronLeftOutlined,
  ChevronRightOutlined,
  CloudDownloadOutlined,
  DeleteOutline,
  EditOutlined,
  MailOutline,
} from "@material-ui/icons";
import { getInitials } from "../../../helpers/getNameAlpha";
import { BookingMenuIcon, DeleteCSIcon } from "../../../assets/icons";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import DropDown from "../../../components/commons/DropDown";
import { useLocation } from "react-router";
import AddPassengerForm from "./AddPassengerForm";

const useStyles = makeStyles({
  redIcon: {
    color: "red",
  },
  customerUserCard: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    padding: "17px 36px",
    borderRadius: 4,
    marginBottom: 10,
    display: "flex",
  },
  modalRoot: {
    ".MuiDialogContent-root": {
      // width:"900px"
    },
    "& .MuiDialog-paperWidthLg": {
      // borderRadius:32,
    },
    "& .MuiDialog-paper": {
      // width: "900px !important"
    },
    "& .MuiDialog-paperWidthSm": {
      width: "690px !important",
    },
    "& .MuiDialogTitle-root": {},
    "& .MuiPaper-rounded": {
      borderRadius: 32,
    },
  },
});

const StyledBadge = styled(Badge)(({ theme, block }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    position: "absolute",
    top: 0,
    right: 7,
    // backgroundColor: block ? "#F42A41" : "#14BA6D",
    // color: '#44b700',
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    // '&::after': {
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   width: '100%',
    //   height: '100%',
    //   borderRadius: '50%',
    //   animation: 'ripple 1.2s infinite ease-in-out',
    //   border: '1px solid currentColor',
    //   content: '""',
    // },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function ManagePassengers({
  getPassengers,
  loading,
  response,
  error,
  updatePassengers,
  deletePassenger,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [singleUserData, setSingleUserData] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updateError, setUpdateError] = useState("");
  const [passengerUpdate, setPassengerUpdate] = useState("");
  const [deleteError, setDeleteError] = useState("");
  const [passengerDelete, setPassengerDelete] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchingValue, setSearchingValue] = useState("");
  const onClose = () => {
    setOpen(false);
    setSingleUserData(null);
  };
  useEffect(() => {
    getPassengers(searchingValue);
  }, [searchingValue]);

  useEffect(() => {
    if (loading) getPassengers(searchingValue);
  }, [loading]);
  const columns = [
    {
      title: "Name",
      field: "passengerName",
      validate: (rowData) => {
        return emptyValidation(rowData, "passengerName");
      },
    },
    {
      title: "PhoneNumber",
      field: "phone",
      validate: (rowData) => {
        return emptyValidation(rowData, "phone");
      },
    },
  ];
  const handleDownload = () => {
    exportToExcel(response, "PassengerLists");
  };

  const handleDeleteClose = () => {
    setDeleteModalOpen(false);
  };

  const locationQuery = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(locationQuery.search);
    const searchingQuery = queryParams.get("search");
    if (searchingQuery) {
      setSearchingValue(searchingQuery);
    } else {
      setSearchingValue("");
    }
  }, [locationQuery]);

  return (
    <>
      <div
        style={{
          width: "full",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          {/* <Button
            sx={{
              marginTop: "10px",
              width: "fit-content",
              color: "white",
              background: "rgb(42, 171, 213)",
              marginRight: 2,
              "&:hover": { background: "rgb(42, 171, 213)" },
            }}
            onClick={() => setOpen(true)}
          >
            Add Passenger
          </Button> */}
          {/* <Button
            sx={{
              border: "solid 1px black",
              marginTop: "10px",
              width: "fit-content",
              marginRight: "10px",
            }}
            onClick={() => handleDownload()}
          >
            Download Passenger List
          </Button> */}
          <MOButton title="Add Passenger" onClick={() => setOpen(true)} />
          <Button
            style={{
              marginLeft: 11,
              borderRadius: 20,
              backgroundColor: "transparent",
              color: "#29AEE6",
              height: 40,
              textTransform: "none",
              border: `2px solid #878787`,
              fontSize: 16,
              padding: "12px 20px 12px 18px",
            }}
            variant="contained"
            onClick={() => handleDownload()}
          >
            <CloudDownloadOutlined style={{ marginRight: 8 }} />
            Download Passenger List
          </Button>
        </div>

        {/* Update UI start */}
        <Grid container xl={12} style={{ marginTop: 16 }}>
          {response?.map((passengerUser, index) => {
            return (
              <>
                <Box key={index} className={classes.customerUserCard}>
                  <Box style={{ display: "flex", flex: 1 }}>
                    <Box>
                      <StyledBadge
                        block={false}
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                      >
                        <Avatar
                          variant="square"
                          style={{ borderRadius: 4, width: 53, height: 48 }}
                          alt="Remy Sharp"
                          src=""
                        >
                          {getInitials(passengerUser.passengerName)}
                        </Avatar>
                      </StyledBadge>
                    </Box>
                    <Box style={{ marginLeft: 22 }}>
                      <Typography
                        style={{
                          fontSize: 22,
                          fontWeight: 500,
                          lineHeight: 1.2,
                        }}
                      >
                        {passengerUser.passengerName}
                      </Typography>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <MailOutline
                          style={{ color: "#29AEE6", width: 21, height: 16 }}
                        />
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: "#878787",
                          }}
                        >
                          {passengerUser?.email || ""}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {/*  */}
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flex: 1,
                      borderLeft: "1px solid",
                      paddingLeft: 20,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 24,
                        fontWeight: 500,
                      }}
                    >
                      {passengerUser.phone}
                    </Typography>
                    {/* menu */}
                    <DropDown
                      dropDownOpen={anchorEl}
                      setDropDownOpen={setAnchorEl}
                      uniqueId={passengerUser.id}
                      menu={[
                        {
                          onClick: () => {
                            setSingleUserData(passengerUser);
                            setDeleteModalOpen(true);
                          },
                          IconRender: () => <DeleteOutline />,
                          title: "Delete",
                        },
                        {
                          onClick: () => {
                            setSingleUserData(passengerUser);
                            setOpen(true);
                          },
                          IconRender: () => <EditOutlined />,
                          title: "Edit",
                        },
                      ]}
                    />
                  </Box>
                </Box>
              </>
            );
          })}
        </Grid>
        {/* Pagination */}
        {/* <Box
          style={{
            backgroundColor: "#E0E0E0",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "15px 17px",
            width: "100%",
          }}
        >
          <Typography
            style={{
              color: "#1C1C1C",
              fontSize: 12,
              fontWeight: 600,
              marginRight: 70,
            }}
          >
            Rows per page: 12
          </Typography>
          <Typography
            style={{
              color: "#1C1C1C",
              fontSize: 12,
              fontWeight: 600,
              marginRight: 40,
            }}
          >
            1-10 of 276
          </Typography>
          <ChevronLeftOutlined />
          <ChevronRightOutlined />
        </Box> */}
        {/* Update UI end */}
        {/* <MaterialTable
          style={{ minWidth: "100%" }}
          icons={tableIcons}
          isLoading={loading}
          columns={columns}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          data={response}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(async () => {
                  let result = await updatePassengers(oldData.id, newData);
                  if (result == true) {
                    setPassengerUpdate("Passenger Updated");
                  } else {
                    setUpdateError(result);
                  }
                  const index = oldData.tableData.id;
                  resolve();
                }, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(async () => {
                  const index = oldData.tableData.id;
                  let result = await deletePassenger(oldData.id);
                  if (result == true) {
                    setPassengerDelete("Passenger Deleted");
                  } else {
                    setDeleteError(result);
                  }
                  resolve();
                }, 1000);
              }),

            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                console.log("newValue: " + newValue);
                setTimeout(resolve, 1000);
              });
            },
          }}
          options={{
            actionsColumnIndex: -1,
          }}
          title="Passengers"
        /> */}

        {updateError != "" && (
          <ErrorSnack reset={() => setUpdateError("")} errorMsg={updateError} />
        )}
        {passengerUpdate && (
          <ErrorSnack
            reset={() => setPassengerUpdate("")}
            success
            errorMsg={"Passenger Updated"}
          />
        )}
        {deleteError != "" && (
          <ErrorSnack reset={() => setDeleteError("")} errorMsg={deleteError} />
        )}
        {passengerDelete && (
          <ErrorSnack
            reset={() => setPassengerDelete("")}
            success
            errorMsg={"Passenger Deleted"}
          />
        )}
      </div>
      {/* <AddPassenger open={open} onClose={onClose} userData={singleUserData} /> */}
      <AddPassengerForm
        open={open}
        onClose={onClose}
        userData={singleUserData}
      />
      <Dialog
        open={deleteModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDeleteClose}
        aria-describedby="alert-dialog-slide-description"
        className={classes.modalRoot}
      >
        <DialogTitle
          style={{ display: "flex", justifyContent: "center", paddingTop: 50 }}
        >
          <DeleteCSIcon />{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <Typography
              style={{ fontSize: 24, fontWeight: 700, color: "#170F49" }}
            >
              Are you sure , you want to delete passenger?
            </Typography>
            <Typography
              style={{ textAlign: "center", fontSize: 18, fontWeight: 400 }}
            >
              By deleting the user, all the data will be lost
            </Typography>
            {/* Are you sure to delete this user? */}
          </DialogContentText>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 60,
              padding: "0px 90px 60px 90px",
            }}
          >
            <MOButton title="Cancel" icon={false} onClick={handleDeleteClose} />
            <MOButton
              title="Delete"
              type="danger"
              icon={false}
              onClick={async () => {
                if (singleUserData.id) {
                  let result = await deletePassenger(singleUserData.id);
                  if (result == true) {
                    setPassengerDelete("Passenger Deleted");
                  } else {
                    setDeleteError(result);
                  }
                }

                setDeleteModalOpen(false);
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

const mapState = (state) => ({
  response: state.passengers.response.data,
  loading: state.passengers.loading,
  error: state.passengers.error.status,
});

const mapDispatch = {
  getPassengers: ThunksPassengers.Passengers,
  updatePassengers: ThunksPassengers.updatePassengers,
  deletePassenger: ThunksPassengers.deletePassenger,
};

export default connect(mapState, mapDispatch)(ManagePassengers);
