import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import DateTimePicker from "react-datetime-picker";

const TimePickerField = ({ field, form, ...props }) => {
  return (
    <div>
      <DateTimePicker
        {...field}
        {...props}
        onChange={(val) => form.setFieldValue(field.name, val)}
        value={field.value}
        disableClock={true}
        format="HH:mm"
        clearIcon={null}
        calendarIcon={null}
      />
    </div>
  );
};

export default TimePickerField;
