import Chip from "@material-ui/core/Chip";
const PermissionsList = ({ list = [] }) => {
    return (
      <>
        {list?.map((item) => (
            <Chip label={item.name} size="small" style={{marginBottom:"4px"}}/>
        ))}
      </>
    );
  };
  
  export default PermissionsList;