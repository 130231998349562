import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Breadcrumb from "../../../components/BreadCrumb";
import EditIcon from "../../../assets/icons/lucide_edit.svg";
import SearchingInput from "../../../components/commons/SearchingInput";
import { connect } from "react-redux";
import mobioApi from "../../../services";
import ServiceLevelModal from "./Modals/ServiceLevelModal";
import { removeLast00 } from "../../../helpers/getTimeFormat";
import { filterServiceLevel } from "../../../helpers/filterServiceLevel";

const Index = ({ token }) => {
  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();
  const [airportServiceLevelsList, setAirportServiceLevelsList] = useState([]);
  const [selectDefaultService, setSelectDefaultService] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editStatus, setEditStatus] = useState({ title: "", id: "" });

  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },

    {
      name: `Manage ${airportIATA}`,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },

    {
      name: "Service Level",
      // url: "",
    },
  ];
  const getAirportServiceLevels = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getAirportServiceLevels(airportId);
      // setAirportServiceLevelsList(response?.data?.data);
      const getResponseData = response?.data?.data;
      if (getResponseData?.length > 0) {
        setAirportServiceLevelsList(
          filterServiceLevel(integrationType, getResponseData || [])
        );
      }
    } catch (error) {
      setAirportServiceLevelsList([]);
    }
  };

  const updateAirportServiceList = (data) => {
    if (data?.applyToAll) {
      const updateList = airportServiceLevelsList?.map((am) => {
        if (am?.associatedServiceLevel?.length > 0) {
          am.associatedServiceLevel[0].cancellationTime = data.cancellationTime;
          am.associatedServiceLevel[0].idleTime = data.idleTime;
          am.associatedServiceLevel[0].leadTime = data.leadTime;
          am.associatedServiceLevel[0].passenger = data.passenger;
          am.associatedServiceLevel[0].rate = data.rate;
          am.associatedServiceLevel[0].serviceLevelId = data.serviceLevelId;
          am.associatedServiceLevel[0].suitcases = data.suitcases;
        } else {
          const appendData = {
            cancellationTime: data.cancellationTime,
            idleTime: data.idleTime,
            leadTime: data.leadTime,
            passenger: data.passenger,
            rate: data.rate,
            serviceLevelId: data.serviceLevelId,
            suitcases: data.suitcases,
          };
          am?.associatedServiceLevel.push(appendData);
        }

        return am;
      });
      setAirportServiceLevelsList(updateList);
    } else {
      const updateList = airportServiceLevelsList?.map((am) => {
        if (Object.keys(data).length > 0 && am.id == data.serviceLevelId) {
          if (am?.associatedServiceLevel?.length > 0) {
            am.associatedServiceLevel[0].cancellationTime =
              data.cancellationTime;
            am.associatedServiceLevel[0].idleTime = data.idleTime;
            am.associatedServiceLevel[0].leadTime = data.leadTime;
            am.associatedServiceLevel[0].passenger = data.passenger;
            am.associatedServiceLevel[0].rate = data.rate;
            am.associatedServiceLevel[0].serviceLevelId = data.serviceLevelId;
            am.associatedServiceLevel[0].suitcases = data.suitcases;
          } else {
            const appendData = {
              cancellationTime: data.cancellationTime,
              idleTime: data.idleTime,
              leadTime: data.leadTime,
              passenger: data.passenger,
              rate: data.rate,
              serviceLevelId: data.serviceLevelId,
              suitcases: data.suitcases,
            };
            am?.associatedServiceLevel.push(appendData);
          }
        }

        return am;
      });
      setAirportServiceLevelsList(updateList);
    }
  };

  useEffect(() => {
    getAirportServiceLevels();
  }, []);
  return (
    <>
      <div>
        <div className="mt-18 flex justify-content-between">
          <div>
            <Breadcrumb
              paths={[
                {
                  name: "Service Level",
                  url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
                },
              ]}
            />
            <div className="breadcrumb-description">
              Change your distance and fixed route rates for specific events.
            </div>
          </div>
          <div className="flex">
            <div className="">
              <SearchingInput />
            </div>
            {/* <MOButtonV2
              text="ADD NEW CAMPAIGN"
              textColor="#ffffff"
              radius={4}
              backgroundColor="#29AEE6"
              height={30}
              fontSize={11}
              padding={"6.5px 31px"}
              IconType={() => <Add style={{ marginRight: 10, fontSize: 16 }} />}
              onClick={() => {
                // setAddModalOpen(true);
              }}
            /> */}
          </div>
        </div>
        <div className="mt-12 ml-6">
          <Breadcrumb paths={secondaryPaths} secondary={true} />
        </div>
        <div>
          <div className="bg-white mt-16 border-radius-8">
            {/* Header */}

            <div className="bg-white border-radius-8">
              <table className="custom-table-v3">
                <thead>
                  <tr>
                    <th>Service Level</th>
                    <th>Lead Time (hrs)</th>
                    {/* <th>Cancel Time (hrs)</th> */}
                    <th>Passengers</th>
                    <th>Suitcases</th>

                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {airportServiceLevelsList.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <div className="flex align-item-center">
                          <img
                            src={row.icon}
                            alt="icon"
                            className="distance-rate-table-icon"
                          />
                          <p className="m-0"> {row.name}</p>
                        </div>
                      </td>
                      <td>
                        {row.associatedServiceLevel[0]?.leadTime
                          ? removeLast00(
                              row.associatedServiceLevel[0]?.leadTime
                            )
                          : "---"}
                      </td>
                      {/* <td>
                        {row.associatedServiceLevel[0]?.cancellationTime
                          ? removeLast00(
                              row.associatedServiceLevel[0]?.cancellationTime
                            )
                          : "---"}
                      </td> */}
                      <td>
                        {row.associatedServiceLevel[0]?.passenger || "---"}{" "}
                      </td>
                      <td>
                        {row.associatedServiceLevel[0]?.suitcases || "---"}
                      </td>

                      <td>
                        <img
                          src={EditIcon}
                          className="cursor-pointer"
                          style={{ width: "23px", height: "23px" }}
                          onClick={() => {
                            setSelectDefaultService(row.id);
                            setModalOpen(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ServiceLevelModal
          token={token}
          airportId={airportId}
          modalOpen={modalOpen}
          updateList={updateAirportServiceList}
          setModalOpen={setModalOpen}
          editStatus={editStatus}
          serviceLevelList={airportServiceLevelsList}
          defaultService={selectDefaultService}
        />
      </div>
    </>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Index);
