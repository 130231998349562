import { Button, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Listing from "./Listing";
import AddVehicle from "./AddVehicle";

const useStyles = makeStyles({
  main_dropdown: {
    marginTop: 20,
  },
  image: {
    height: 80,
    width: 80,
    marginRight: 20,
    borderRadius: 14,
  },

  user_info: {
    marginLeft: 24,
  },
});

function VehicleDetails() {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="row-reverse"
        justifyContent="flex-start"
        alignItems="center"
      >
        <AddVehicle />
      </Grid>
      <Listing />
    </>
  );
}

export default VehicleDetails;
