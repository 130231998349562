import * as React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import { connect } from "react-redux";
import ThunksUsers from "../../../redux/actions/thunks/OrgUsers";
import Local from "../../../redux/actions/local";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginLeft: "24%",
    },
  },
};
function getStyles(item, selected, Theme) {
  const index = selected.findIndex((sel) => sel.id == item.id);
  return {
    fontWeight:
      index === -1
        ? Theme.typography.fontWeightRegular
        : Theme.typography.fontWeightMedium,
  };
}
const useStyles = makeStyles((theme) => ({
  formControl: {},
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  submit: { margin: theme.spacing(3, 0, 2) },
}));

const AddUserAuthorities = ({
  allAuthorities,
  selectedAuthorities,

  selectedUser,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [selected, setSelected] = React.useState(
    selectedUser?.userAuthorities || []
  );
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const index = selected.findIndex(
      (item) => item.id == value[value.length - 1]
    );
    if (index == -1) {
      const selectedObjects = [...allAuthorities].findIndex(
        (authr) => authr.id === value[value.length - 1]
      );
      setSelected([...selected, allAuthorities[selectedObjects]]);
    } else {
      setSelected(selected.filter((item) => item.id != selected[index].id));
    }
  };
  React.useEffect(() => {
    selectedAuthorities(selected);
  }, [selected.length]);

  return (
    <div>
      <FormControl fullWidth className={classes.formControl}>
        <InputLabel id="demo-mutiple-chip-label">Authorities</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          displayEmpty={true}
          multiple
          value={selected}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          MenuProps={MenuProps}
          renderValue={(renderItem) =>
            selected.map((item) => {
              const findedIndex = allAuthorities.findIndex(
                (authority) => authority.id === item.id
              );
              return findedIndex > -1 ? (
                <Chip
                  key={allAuthorities[findedIndex].id}
                  label={allAuthorities[findedIndex].name}
                  className={classes.chip}
                />
              ) : null;
            })
          }
        >
          {allAuthorities.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              style={getStyles(item, selected, theme)}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const mapState = (state) => ({
  allAuthorities: state.orgUsers.authorizations,
  selectedUser: state.orgUsers.dialog.selectedUser,
});

const mapDispatch = {
  getAllAuthorizations: ThunksUsers.getAllAuthorizations,
};

export default connect(mapState, mapDispatch)(AddUserAuthorities);
