import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import ThunksCustomers from "../../../redux/actions/thunks/customer";
import { connect } from "react-redux";
import tableIcons from "../../../components/commons/ConstantsMaterialTable";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import CustomerDispatchers from "./CustomerDispatchers";
import CustomerCommission from "./CustomerCommission";
import EditIcon from "@mui/icons-material/Edit";
import UpdateCustomer from "./UpdateCustomer";
function Customers({ getCustomers, loading, response, status, error }) {
  const [clientDate,setClientDate]=useState("");
  const [modalShow,setModalShow]=useState("");
  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    if (loading) {
      getCustomers();
    }
  }, [loading]);

  

  console.log("===> CUSTOMERJS: ", response);
  const columns = [
    // { title: "Id", field: "id", editable: "never" },
    { title: "Email", field: "email", editable: "never", type: "email" },
    { title: "First Name", field: "firstName", editable: "never" },
    { title: "Last Name", field: "lastName", editable: "never" },
    { title: "Company Name", field: "companyName", editable: "never" },
    { title: "Company Address", field: "companyAddress", editable: "never" },
    { title: "Company City", field: "companyCity", editable: "never" },
    { title: "Company Registration Nr", field: "companyRegistrationNumber", editable: "never" },
    { title: "Company VAT Nr", field: "companyVATNumber", editable: "never" },
    { title: "Bank Account Nr", field: "bankAccountNumber", editable: "never" },
    { title: "Bank Account Holder Name", field: "bankAccountHolderName", editable: "never" },
    { title: "VAT(%)", field: "vatPercentage", editable: "never" },
    {
      title: "No of Dispatchers",
      field: "dispatchers",
      editable: "never",
      type: "numeric",
      render: (rowData) => rowData.dispatchers.length,
    },
  ];

  // alert(JSON.stringify(response));
  const handleClickOpen = () => {
    setModalShow(true);
  };
    const handleClose = () => {
      setClientDate("")
      setModalShow(false);
  };

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        isLoading={loading}
        columns={columns}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        data={response}
        detailPanel={[
          {
            tooltip: "Dispatcher Assigned",
            render: (rowData) => {
              return (
                <>
                <CustomerDispatchers
                  id={rowData.id}
                  dispatchers={rowData.dispatchers}
                />
                <CustomerCommission clientId={rowData.id}/>
                </>
              );
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Customers"
        actions={[
          (rowData) => {
            return {
             
            icon: () => <EditIcon />,
            tooltip: "Edit User",
            onClick: (event, rowData) => {
              setClientDate(rowData);
              handleClickOpen()
            },
            };
          },
          
          
        ]}
      />
      {error.status && <ErrorSnack errorMsg={error.message} />}
      <UpdateCustomer 
        modalShow={modalShow}
        setModalShow={setModalShow}
        handleClose={handleClose}
        setDate={clientDate}

        />
    </>
  );
}

const mapState = (state) => ({
  response: state.customers.response.data,
  loading: state.customers.loading,
  error: state.customers.error.status,
});

const mapDispatch = {
  getCustomers: ThunksCustomers.Customers,
};

export default connect(mapState, mapDispatch)(Customers);
