import Local from "../../local";

export type ThunksCustomersListing = {
  TaxiCompanies: () => any;
  CreateCompany: (obj: any) => any;
  UpdateCompany: (id: any, obj: any) => any;
};

const ThunksCompany: ThunksCustomersListing = {
  UpdateCompany: (id, obj) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token},
    } = getState();

    const api = mobioApi(token);
  
    return api
      .updateCompany(id, obj)
      .then((response: any) => {
        console.log(response);
        
        return response.data;
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.TaxiCompanies.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          alert(data.msg);
          dispatch(
            Local.TaxiCompanies.api.error("Request Failed", data.msg)
          );
        }
      });
  },
  CreateCompany: (obj) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token, user },
    } = getState();

    const api = mobioApi(token);
  
    return api
      .createCompany(obj)
      .then((response: any) => {
        
        return response.data;
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.TaxiCompanies.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          alert(data.msg);
          dispatch(
            Local.TaxiCompanies.api.error("Request Failed", data.msg)
          );
        }
      });
  },
  TaxiCompanies: () => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    dispatch(Local.TaxiCompanies.api.request());
    return api
      .getTaxiCompanies()
      .then((response: any, loading: boolean) => {
        dispatch(Local.TaxiCompanies.api.response(response.data));
      })

      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.TaxiCompanies.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(Local.TaxiCompanies.api.error("Request Failed", data.msg));
        }
      });
  },
};

export default ThunksCompany;
