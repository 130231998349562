import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = () => {
  const chartData = {
    labels: [
      "Booking.com",
      "Trip.com",
      "Worldtransfer.com",
      "Booking.com",
      "Trip.com",
      "Worldtransfer.com",
    ],
    datasets: [
      {
        data: [216, 121, 193, 216, 121, 193],
        backgroundColor: [
          "#6FD195",
          "#537FF1",
          "#FFAE4C",
          "#8979FF",
          "#FF928A",
          "#3CC3DF",
        ],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
        titleFont: {
          family: "Arial",
          size: 14,
          weight: "bold",
        },
        bodyFont: {
          family: "Arial",
          size: 12,
          weight: "normal",
        },
      },
    },
    layout: {
      padding: {
        left: 200,
        right: 200,
      },
    },
  };

  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: (chart) => {
      const { ctx, width, height } = chart;
      ctx.save();
      ctx.font = "bold 24px Arial";
      ctx.fillStyle = "#000";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText("1152", width / 2, height / 2);
    },
  };

  const labelLinesPlugin = {
    id: "labelLines",
    afterDraw: (chart) => {
      const {
        ctx,
        chartArea: { top, bottom, left, right },
      } = chart;
      const labels = [
        {
          text: "Booking.com",
          value: "216",
          color: "#6FD195",
          x: left - 120,
          y: top + 40,
        },
        {
          text: "Trip.com",
          value: "121",
          color: "#537FF1",
          x: left - 120,
          y: top + 120,
        },
        {
          text: "Worldtransfer.com",
          value: "193",
          color: "#FFAE4C",
          x: left - 120,
          y: top + 200,
        },
        {
          text: "Booking.com",
          value: "216",
          color: "#8979FF",
          x: right + 120,
          y: top + 40,
        },
        {
          text: "Trip.com",
          value: "121",
          color: "#FF928A",
          x: right + 120,
          y: top + 120,
        },
        {
          text: "Worldtransfer.com",
          value: "193",
          color: "#3CC3DF",
          x: right + 120,
          y: top + 200,
        },
      ];

      labels.forEach((label) => {
        ctx.beginPath();
        ctx.moveTo(label.x > right ? right : left, label.y);
        ctx.lineTo(label.x, label.y);
        ctx.strokeStyle = label.color;
        ctx.stroke();

        ctx.font = "bold 12px Arial";
        ctx.fillStyle = label.color;
        ctx.textAlign = label.x > right ? "left" : "right";
        ctx.fillText(label.text, label.x, label.y - 10);

        ctx.font = "normal 12px Arial";
        ctx.fillText(label.value, label.x, label.y + 10);
      });
    },
  };

  return (
    <div>
      <Doughnut
        data={chartData}
        options={chartOptions}
        plugins={[centerTextPlugin, labelLinesPlugin]}
      />
    </div>
  );
};

export default DoughnutChart;
