import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Dialog,
  DialogTitle,
  makeStyles,
  Button,

} from "@material-ui/core";
import moment from 'moment';

import Bookings from "../../../../redux/actions/local/bookings";
import ThunksBookings from "../../../../redux/actions/thunks/bookings";
import ExclusiveSelect from "../../../../components/commons/ExclusiveSelect";
import CarExclusiveSelect from "../../../../components/commons/CarExclusiveSelect";
import DatePicker from "../../../../components/commons/DatePicker";
import LocationField from "../../../../components/commons/LocationField";
import Input from "../../../../components/commons/Input";
import MenuItem from "@material-ui/core/MenuItem";

import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import ThunksCustomers from "../../../../redux/actions/thunks/customer";
import Local from "../../../../redux/actions/local";
import ErrorSnack from "../../../../components/commons/ErrorSnack";

const useStyles = makeStyles((theme) => ({
  disabled: {
    backgroundColor: "red",
  },
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    margin: theme.spacing(2),
  },
  button: {
    borderRadius: 6,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
    marginLeft: 10,

    "&:disabled": {
      backgroundColor: "#8bd2e8",
      borderRadius: 6,
      color: "#FFFFFF",
      height: 40,
      marginLeft: 10,
    },
  },
  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    height: 40,
  },
  textfield_lower: {
    height: 40,
  },
  textfield_side: {
    height: 40,
  },
  ExclusiveSelect: {
    marginRight: 26,
  },
  MultiSelect: {
    marginTop: 20,
  },
  select: {
    marginTop: 20,
    width: 310,
  },
  autoComplete: {
    backgroundColor: "red",
  },
}));

function CreateAdditionalBookingModal({
  bookingState,
  clientId,
  open,
  onClose,
  updateField,
  dispatchers,
  loading,
  getDispatchers,
  CreateAdditionalBooking,
  role,
  reset,
  error,
  success,
  createBookingError,
  createBookingSuccess,
  from,
  to,
  driver,
  initalLoad,
  updateSelectedRow
}) {
  const classes = useStyles();
  const handleChange = (event) => {
    updateField("clientId", event.target.value);
  };

  useEffect(() => {
    if (open) {
      if (role !== "Customer" ||role !== "CustomerUser") {
        getDispatchers();
      }
      updateField("dateTime", bookingState.dateTime !== ""
        ? bookingState.dateTime
        : new Date());
    }
    // alert(JSON.stringify(dispatchers));
  }, [open]);

  return (
    <>
      <Dialog
        disableBackdropClick
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <div className={classes.dialogPaper}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <DialogTitle className={classes.heading} id="simple-dialog-title">
              Add Booking
            </DialogTitle>
          </Grid>

          <Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              // alignItems="center"
              className={classes.ExclusiveSelect}
            >
              <DatePicker
                disablePast={false}
                date={
                  bookingState.dateTime !== ""
                    ? bookingState.dateTime
                    : new Date()
                }
                onChange={(date) => updateField("dateTime", date)}
              />
              <ExclusiveSelect
                value={bookingState.paymentType}
                onClick={(value) => updateField("paymentType", value)}
              />
            </Grid>
            {role !== "Customer" &&role !== "CustomerUser" && (
              <Input
                value={bookingState.clientId}
                onChange={handleChange}
                select
                variant={"outlined"}
                label="Requested By"
                size="small"
                fullWidth
                className={classes.textfield_upper}
              >
                <MenuItem key={"none"} value={""}>
                  {"None"}
                </MenuItem>
                {!loading &&
                  dispatchers?.map((item, index) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.companyName}
                    </MenuItem>
                  ))}
              </Input>
            )}
            <Input
              label="Booking ID"
              size="small"
              fullWidth
              value={bookingState.bookingReference}
              className={classes.textfield_upper}
              onChange={(e) => updateField("bookingReference", e.target.value)}
            />
            <Input
              label="Name Caller"
              size="small"
              fullWidth
              value={bookingState.nameCaller}
              className={classes.textfield_upper}
              onChange={(e) => updateField("nameCaller", e.target.value)}
            />
            <LocationField
              location={bookingState.pickup}
              placeholder={"Pickup"}
              onChange={(value) => {
                if (value) {
                  geocodeByAddress(value.label)
                    .then((results) => getLatLng(results[0]))
                    .then(({ lat, lng }) =>
                      updateField("pickup", {
                        name: value,
                        coordinates: [lat, lng],
                      })
                    );
                }
              }}
            />
            <LocationField
              location={bookingState.destination}
              placeholder={"Destination"}
              onChange={(value) => {
                if (value) {
                  geocodeByAddress(value.label)
                    .then((results) => getLatLng(results[0]))
                    .then(({ lat, lng }) =>
                      updateField("destination", {
                        name: value,
                        coordinates: [lat, lng],
                      })
                    );
                }
              }}
            />

            <Grid
              container
              spacing={2}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              <Grid item xs={12}>
                <Input
                  fullWidth
                  label="Note"
                  size="small"
                  multiline
                  value={bookingState.note}
                  className={classes.textfield_side}
                  onChange={(e) => updateField("note", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  fullWidth
                  label="Guest Name "
                  size="small"
                  value={bookingState.guestName}
                  className={classes.textfield_lower}
                  onChange={(e) => updateField("guestName", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  fullWidth
                  label="Flight Number "
                  size="small"
                  value={bookingState.flightNumber}
                  className={classes.textfield_side}
                  onChange={(e) => updateField("flightNumber", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  fullWidth
                  label="Guest Phone"
                  value={bookingState.guestPhoneNumber}
                  size="small"
                  className={classes.textfield_lower}
                  onChange={(e) =>
                    updateField("guestPhoneNumber", e.target.value)
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <Input
                  fullWidth
                  label="Fare"
                  size="small"
                  value={bookingState.fare}
                  className={classes.textfield_side}
                  onChange={(e) => updateField("fare", e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  select
                  value={bookingState.noOfPeople}
                  fullWidth
                  size="small"
                  variant="outlined"
                  className={classes.textfield_lower}
                  onChange={(e) => updateField("noOfPeople", e.target.value)}
                  label="Passengers"
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((item, index) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Input>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.MultiSelect}>
            <CarExclusiveSelect
              value={bookingState.carType}
              noOfPeople={bookingState.noOfPeople}
              onClick={(value) => updateField("carType", value)}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              // disabled
              style={{
                borderRadius: 6,
                backgroundColor: "primary",
                color: "#FFFFFF",
                height: 40,
                marginLeft: 10,
              }}
              variant="contained"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              // disabled={ !bookingReference }
              variant="contained"
              onClick={async () => {
                if (bookingState.bookingReference == '') {
                  alert("Please fill up booking reference");
                } else {
                  let originClientId = clientId;
                  let res = await CreateAdditionalBooking(bookingState.bookingReference, from, moment(from).endOf("isoWeek").format("YYYY-MM-DD"), driver, originClientId);
                  if (res.id) {
                    updateSelectedRow(res.id)
                    await initalLoad()
                    onClose();
                  }
                }
              }}
            >
              Create
            </Button>
          </Grid>
        </div>
      </Dialog>
      {createBookingError.status && (
        <ErrorSnack reset={() => reset()} errorMsg={error.message} />
      )}
      {createBookingSuccess.status && (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"Booking Created"}
        />
      )}
    </>
  );
}
const mapState = (state) => ({
  error: state.customers.error,
  success: state.customers.response,
  dispatchers: state.customers.response.data,

  createBookingError: state.customerbooking.error,
  createBookingSuccess: state.customerbooking.response,

  loading: state.customers.loading,
  bookingState: state.bookings.state,
});

const mapDispatch = {
  updateField: Bookings.field.update,
  getDispatchers: ThunksCustomers.getCustomerDispatchers,
  CreateAdditionalBooking: ThunksBookings.CreateAdditionalBooking,
  createCustomerBooking: ThunksBookings.CreateCustomerBooking,
  reset: Local.CreateBooking.api.reset,
};

export default connect(mapState, mapDispatch)(CreateAdditionalBookingModal);
