import Local from "../../local";

export type ThunksCustomersListing = {
  Customers: () => any;
  addCustomer: (user: any) => any;
  updateCustomer: (user: any) => any;
  getCustomerDispatchers: (type: string | null, customerId: string | null, integrationType: string | null) => any;
  assignCustomerDispatcher: (obj: any) => any;
  getDisplayNameByClientId: (id: string) => any;
  sendEmailCustomer: (id:String,email:String) => any;
};

const ThunksCustomers: ThunksCustomersListing = {
  Customers: () => (dispatch: any, getState: any, mobioApi: any) => {
    console.log("customers list");
    const {
      userInfo: { token },
    } = getState();
    console.log("this is taxi company token", token);
    const api = mobioApi(token);
    dispatch(Local.Customers.api.request());

    return api
      .getCustomers()
      .then((response: any) => {
        dispatch(Local.Customers.api.response(response.data));
        return true;
        console.log("this is response of customers  form api", response.data);
      })
      .catch((error: any) => {
        const {
          response: { data },
        } = error;
        dispatch(Local.Customers.api.error("Request Failed", data.msg));
      });
  },
  addCustomer: (user: any) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    dispatch(Local.AddCustomer.api.request());

    return api
      .addCustomer(user)
      .then((response: any) => {
        dispatch(Local.AddCustomer.api.response(response));
        dispatch(Local.Customers.api.request());
        //   console.log(
        //     "this is response of added customer from thunk",
        //     response.data
        //   );
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.AddCustomer.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(Local.AddCustomer.api.error("Request Failed", data.msg));
        }
      });
  },
  updateCustomer: (user: any) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);
    dispatch(Local.AddCustomer.api.request());

    return api
      .updateOrgCustomer(user)
      .then((response: any) => {
        dispatch(Local.AddCustomer.api.response(response));
        dispatch(Local.Customers.api.request());
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.AddCustomer.api.error(
              "Request Failed",
              "The API request timed out. Please Refresh the page"
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(Local.AddCustomer.api.error("Request Failed", data.msg));
        }
      });
  },
  getCustomerDispatchers:
    (type = null, customerId = null, integrationType = null) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      dispatch(Local.CustomerDispatchers.api.request());
      return api
      .getCustomerDispatchers(type, customerId, integrationType == 'booking.com' ? "Booking.com" : 'WorldTransfer.com')
        .then((response: any) => {
          dispatch(Local.CustomerDispatchers.api.response(response.data));
          return response.data
          // console.log("this is response of customer dispatchers", response.data);
        })
        .catch((error: any) => {
          const {
            response: {
              data: { msg },
            },
          } = error;
          dispatch(Local.CustomerDispatchers.api.error("Request Failed", msg));
        });
    },
  assignCustomerDispatcher:
    (obj) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .assignCustomerDispatcher(obj)
        .then((response: any) => {
          dispatch(Local.CustomerDispatchers.api.request());
          dispatch(Local.Customers.api.request());
        })
        .catch((error: any) => {
          const {
            response: {
              data: { msg },
            },
          } = error;
          alert(msg);
        });
    },
    getDisplayNameByClientId:
    (id) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .getDisplayNameByClientId({id:id})
        .then((response: any) => {
         return response.data
        })
        .catch((error: any) => {
          const {
            response: {
              data: { msg },
            },
          } = error;
          alert(msg);
        });
    },
    sendEmailCustomer:
    (id,email) => (dispatch: any, getState: any, mobioApi: any) => {
      const {
        userInfo: { token },
      } = getState();
      const api = mobioApi(token);
      return api
        .sendEmailOrgCustomer({customerId:id,email})
        .then((response: any) => {
          dispatch(Local.Customers.api.request());
         return response.data
        })
        .catch((error: any) => {
          const {
            response: {
              data: { msg },
            },
          } = error;
          alert(msg);
        });
    },
};

export default ThunksCustomers;
