import LOGIN from "../../actions/local/constants/login";
import loginState, { LoginStates } from "../../initial-state/login";

const loginReducer = (state: LoginStates = loginState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN.RESET_ALL: {
      return {
        ...loginState,
      };
    }
    case LOGIN.FIELD.UPDATE: {
      const { field, newContent } = payload;
      return {
        ...state,
        [field]: newContent,
      };
    }
    case LOGIN.FIELD.CLEAR: {
      const { field } = payload;
      return {
        ...state,
        [field]: "",
      };
    }

    default:
      return state;
  }
};

export default loginReducer;
