import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Pressable from "../commons/pressable";
import { Link } from "react-router-dom";
import MobioTextField from "../commons/fields/mobioTextField";
import { HandleChange, Error } from "../../containers/public/sign-up";

interface StepOneProps {
  userName: string;
  error: Error;
  email: string;
  password: string;
  updateStep: any;
  handleChange: HandleChange;
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: "1rem",
  },
  divider: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "7vh",
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.text.disabled,
    borderBottomStyle: "solid",
    width: "45%",
  },
  or: {
    color: theme.palette.text.disabled,
  },
  signInContainer: {
    alignSelf: "center",
    marginTop: "7vh",
    color: theme.palette.text.disabled,
  },
  signIn: {
    color: theme.palette.primary.dark,
    textDecoration: "none",
  },
  customerLogin: {
    marginTop: "2vh",
    padding: theme.spacing(1.4),
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderRadius: 15,
    alignSelf: "center",
  },
}));

const StepOne: React.FunctionComponent<StepOneProps> = ({
  userName,
  error,
  email,
  password,
  updateStep,
  handleChange,
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <>
      <MobioTextField
        error={error["userName"]}
        fullWidth
        onChange={(event) => handleChange("userName", event.target.value)}
        label="Display Name"
        value={userName}
        className={classes.field}
      />
      <MobioTextField
        fullWidth
        error={error["email"]}
        onChange={(event) => handleChange("email", event.target.value)}
        label="Email"
        value={email}
        className={classes.field}
      />
      <div className={classes.field}>
        <FormControl fullWidth>
          <InputLabel htmlFor="password-first">Password</InputLabel>
          <Input
            id="password-first"
            onChange={(event) => handleChange("password", event.target.value)}
            type={showPassword ? "text" : "password"}
            value={password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {!showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <p style={{ fontSize:9, opacity:0.3 }}>Password must be 8 digits long, and contains at least 1 digit, 1 uppercase and 1 lowercase letter!</p>
      </div>
      <Pressable onClick={() => updateStep(2)} size={"large"} title={"Next"} />
      <div className={classes.divider}>
        <div className={classes.border} />
        <span className={classes.or}>OR</span>
        <div className={classes.border} />
      </div>
      <Link
        to="/customer"
        className={`${classes.customerLogin} ${classes.signIn}`}
      >
        Customer Login
      </Link>
      <span className={classes.signInContainer}>
        Already have an account?{" "}
        <Link to="/login" className={classes.signIn}>
          Sign In
        </Link>
      </span>
    </>
  );
};

export default StepOne;
