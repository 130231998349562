import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";
import {
  getDistance,
  getDistanceAndDuration,
  haversineDistance,
} from "./distanceCalculate";
import { toastMessage } from "../../../helpers/toastMessage";

const Index = () => {
  const [resultData, setResultData] = useState([]);
  // Form submission handler
  const handleSubmit = async (values) => {
    console.log("Form values:", values);
    try {
      const encoded = Buffer.from(
        values.clientId + ":" + values.clientSecret
      ).toString("base64");
      const response = await axios.post(
        `${process.env.REACT_APP_RATES_BASE_URL}/oauth2/token?grant_type=client_credentials`,
        {},
        {
          headers: { Authorization: "Basic " + encoded },
        }
      );
      if (response.data.access_token) {
        const dataObj = {
          origin: values.origin_detail,
          destination: values.destination_detail,
          passengers: values.passengers,
          pickupDateTime: new Date(values.pickupDateTime).toISOString(),
          pickupTimezone: "Europe/Paris",
          drivingDistanceInKm: values.drivingDistanceInKm,
        };
        const rateReponse = await axios.post(
          `${process.env.REACT_APP_RATES_BASE_URL}/v2/search-results`,
          dataObj,
          {
            headers: { Authorization: "Bearer " + response.data.access_token },
          }
        );

        if (!!rateReponse?.data?.results) {
          setResultData(rateReponse.data.results);
        }
      }
    } catch (error) {
      setResultData([]);
      toastMessage("error", error.response.data.errorMessage);
      console.error(error);
    }

    // You can add API submission logic here
  };

  const setDistance = async (
    origin_detail,
    destination_detail,
    setFieldValue
  ) => {
    try {
      const distance = await haversineDistance(
        origin_detail,
        destination_detail
      );
      setFieldValue("drivingDistanceInKm", distance?.toFixed(2));
    } catch (error) {
      console.log("error", error.message);
    }
  };

  const getLocationDetail = async (name) => {
    let data = null;
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            address: name,
            key: process.env.REACT_APP_GOOGLE_MAP_API_KEY, // Your Google API key
          },
        }
      );
      if (response.data.results.length > 0) {
        data = response.data.results[0];
        return data;
      }
    } catch (error) {
      return data;
    }
  };

  return (
    <>
      <div className="bg-white  mt-20 border-radius-8 main-section-spacing-sm">
        <h1 className="font-16 font-600 mb-20">Ride Details Form</h1>
        <Formik
          initialValues={{
            origin: "",
            destination: "",
            origin_detail: "",
            destination_detail: "",
            passengers: 1,
            pickupDateTime: "",
            drivingDistanceInKm: "",
            clientId: "",
            clientSecret: "",
          }}
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              {/* Form Fields */}
              <div className="flex gap-12">
                <div className="flex-1 input_field">
                  <p className="font-12 font-600 mb-6">Origin</p>
                  {/* <Field
                  name="origin"
                  className=""
                  type="text"
                  placeholder="Enter origin"
                /> */}
                  <GooglePlacesAutocomplete
                    selectProps={{
                      value: values.origin,
                      onChange: async (value) => {
                        const getValue = await getLocationDetail(value.label);
                        const latitude = getValue?.geometry?.location?.lat;
                        const longitude = getValue?.geometry?.location?.lng;
                        const name = getValue?.formatted_address;
                        const addressComponents =
                          getValue?.address_components || [];

                        // Safely find the city, country, and postcode
                        const city = addressComponents.find((component) =>
                          component.types.includes("locality")
                        )?.long_name;

                        const country = addressComponents.find((component) =>
                          component.types.includes("country")
                        )?.short_name;

                        setFieldValue("origin_detail", {
                          latitude,
                          longitude,
                          name,
                          city,
                          country,
                        });
                        setFieldValue("origin", value);
                        if (values.destination_detail) {
                          setDistance(
                            { latitude, longitude },
                            values.destination_detail,
                            setFieldValue
                          );
                        }
                      },
                      placeholder: "Enter origin",
                    }}
                    apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                    className=""
                  />
                  <ErrorMessage
                    name="origin"
                    component="p"
                    className="text-danger font-12"
                  />
                </div>

                <div className="flex-1 input_field">
                  <p className="font-12 font-600 mb-6">Destination</p>
                  <GooglePlacesAutocomplete
                    selectProps={{
                      value: values.destination,
                      onChange: async (value) => {
                        const getValue = await getLocationDetail(value.label);
                        const latitude = getValue?.geometry?.location?.lat;
                        const longitude = getValue?.geometry?.location?.lng;
                        const name = getValue?.formatted_address;
                        const addressComponents =
                          getValue?.address_components || [];

                        // Safely find the city, country, and postcode
                        const city = addressComponents.find((component) =>
                          component.types.includes("locality")
                        )?.long_name;

                        const country = addressComponents.find((component) =>
                          component.types.includes("country")
                        )?.short_name;

                        setFieldValue("destination_detail", {
                          latitude,
                          longitude,
                          name,
                          city,
                          country,
                        });
                        if (values.origin_detail) {
                          setDistance(
                            values.origin_detail,
                            { latitude, longitude },
                            setFieldValue
                          );
                        }

                        setFieldValue("destination", value);
                      },
                      placeholder: "Enter destination",
                    }}
                    apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                    className=""
                  />
                  {/* <Field
                  name="destination"
                  className=""
                  type="text"
                  placeholder="Enter destination"
                /> */}
                  <ErrorMessage
                    name="destination"
                    component="p"
                    className="text-danger font-12"
                  />
                </div>
              </div>

              <div className="mt-20 input_field">
                <p className="font-12 font-600 mb-6">Passengers</p>
                <Field
                  className=""
                  type="number"
                  name="passengers"
                  value={values.passengers}
                  onChange={(e) => setFieldValue("passengers", e.target.value)}
                />
                <ErrorMessage
                  name="passengers"
                  component="p"
                  className="text-danger font-12"
                />
              </div>

              <div className="mt-20 input_field">
                <p className="font-12 font-600 mb-6">Pickup Date and Time</p>
                <Field
                  type="datetime-local"
                  name="pickupDateTime"
                  className=""
                  value={values.pickupDateTime}
                />
                <ErrorMessage
                  name="pickupDateTime"
                  component="p"
                  className="text-danger font-12"
                />
              </div>

              <div className="mt-20">
                <p className="font-600 font-12 mb-6">
                  Driving Distance (in Km)
                </p>
                <div className=" ">
                  <div className="flex align-item-center input_field">
                    <Field
                      className=""
                      type="number"
                      step={"0.1"}
                      name="drivingDistanceInKm"
                      value={values.drivingDistanceInKm}
                      onChange={(e) =>
                        setFieldValue("drivingDistanceInKm", e.target.value)
                      }
                      placeholder="Enter distance"
                    />
                  </div>
                  <ErrorMessage
                    name="drivingDistanceInKm"
                    component="p"
                    className="text-danger font-12"
                  />
                </div>
              </div>
              <div className="mt-20">
                <p className="font-600 font-12 mb-6">Client Id</p>
                <div className=" ">
                  <div className="flex align-item-center input_field">
                    <Field
                      className=""
                      type="text"
                      // step={"0.1"}
                      name="clientId"
                      value={values.clientId}
                      onChange={(e) =>
                        setFieldValue("clientId", e.target.value)
                      }
                      placeholder="Enter Client Id"
                    />
                  </div>
                  <ErrorMessage
                    name="drivingDistanceInKm"
                    component="p"
                    className="text-danger font-12"
                  />
                </div>
              </div>
              <div className="mt-20">
                <p className="font-600 font-12 mb-6">Client Secret</p>
                <div className=" ">
                  <div className="flex align-item-center input_field">
                    <Field
                      className=""
                      type="text"
                      // step={"0.1"}
                      name="clientSecret"
                      value={values.clientSecret}
                      onChange={(e) =>
                        setFieldValue("clientSecret", e.target.value)
                      }
                      placeholder="Enter Client Secret"
                    />
                  </div>
                  <ErrorMessage
                    name="drivingDistanceInKm"
                    component="p"
                    className="text-danger font-12"
                  />
                </div>
              </div>

              <div className="flex justify-content-end mt-20">
                <MOButtonV2
                  type="submit"
                  text={"Save"} //ADD INSTRUCTION
                  textColor="#ffffff"
                  radius={4}
                  backgroundColor="#29AEE6"
                  height={30}
                  fontSize={11}
                  padding={"8.5px 30.5px"}
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div className="mt-20">
        <div className="bg-white border-radius-8">
          <div className="">
            <table className="custom-table-v3 ">
              <thead>
                <tr>
                  <th>Id</th>
                  <th className="">Transport Category</th>
                  <th className="">Price (Min-Max)</th>
                  <th className="">Min Passengers</th>
                  <th className="">Max Passengers</th>
                  <th className="">Provider Name</th>
                  <th className="">Provider Contact</th>
                </tr>
              </thead>
              <tbody>
                {resultData?.length ? (
                  resultData.map((row, index) => (
                    <tr key={index}>
                      <td className="text-warp">{row?.searchResultId}</td>
                      <td className="text-warp">{row?.transportCategory}</td>
                      <td className="text-warp">{`${row?.price?.salePriceMin} - ${row?.price?.salePriceMax} (${row.price.currency})`}</td>
                      <td className="text-warp">{row?.minPassengers}</td>
                      <td className="text-warp">{row?.maxPassengers}</td>
                      <td className="text-warp">{row?.provider?.name}</td>
                      <td className="text-warp">
                        {row?.provider?.phoneNumber}
                      </td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td colSpan="9">
                        <div className="table-no-record-section">
                          No records are found.
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
