import Dispatchers from "./Dispatchers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  main_dropdown: {
    marginTop: 20,
  },
  image: {
    height: 80,
    width: 80,
    marginRight: 20,
    borderRadius: 14,
  },

  user_info: {
    marginLeft: 24,
  },
});

function DriversDetail() {
  return (
    <> 
      <Dispatchers />
    </>
  );
}

export default DriversDetail;
