import React from "react";

const AirportDetailCard = ({ Icon, titleText, detailText, size = "md" }) => {
  return (
    <>
      {size === "md" && (
        <>
          <div className="airport-detail-card-section">
            <div className="image-section">
              {typeof Icon === "function" && Icon()}
            </div>
            <div className="text-detail">
              <p className="m-0">{detailText}</p>
              <p className="m-0">{titleText}</p>
            </div>
          </div>
        </>
      )}
      {size === "lg" && (
        <>
          <div className="airport-detail-card-section-lg">
            <div className="image-section">
              {typeof Icon === "function" && Icon()}
            </div>
            <div className="text-detail">
              <p className="m-0">{titleText}</p>
              <p className="m-0">{detailText}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AirportDetailCard;
