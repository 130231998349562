import SIGN_UP from "../constants/sign-up";

export type Field =
  | "userName"
  | "email"
  | "password"
  | "companyName"
  | "country"
  | "city"
  | "address"
  | "addressLineTwo"
  | "postalCode"
  | "IBAN";

export type LocalSignUpRepo = {
  resetAll: () => any;
  field: {
    update: (field: Field, newContent: string) => any;
    clear: (field: Field) => any;
  };
  flags: {
    toggleComplete: (newState: boolean) => any;
    updateStep: (step: number) => any;
    reset: () => any;
  };
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
};

const SignUp: LocalSignUpRepo = {
  resetAll: () => ({
    type: SIGN_UP.RESET_ALL,
    payload: {},
  }),
  field: {
    update: (field, newContent) => ({
      type: SIGN_UP.FIELD.UPDATE,
      payload: { field, newContent },
    }),
    clear: (field) => ({
      type: SIGN_UP.FIELD.CLEAR,
      payload: { field },
    }),
  },
  flags: {
    toggleComplete: (newState) => ({
      type: SIGN_UP.FLAGS.TOGGLE_COMPLETE,
      payload: { newState },
    }),
    updateStep: (step) => ({
      type: SIGN_UP.FLAGS.UPDATE_STEP,
      payload: { step },
    }),
    reset: () => ({
      type: SIGN_UP.FLAGS.RESET,
      payload: {},
    }),
  },
  api: {
    request: () => ({
      type: SIGN_UP.SIGN_UP_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: SIGN_UP.SIGN_UP_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: SIGN_UP.SIGN_UP_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: SIGN_UP.SIGN_UP_API.RESET,
      payload: {},
    }),
  },
};

export default SignUp;
