import { Input } from '@material-ui/core';
import React, { useState, useRef } from 'react';
import { connect } from "react-redux";
import ThunksOnboardingListing from "../../../redux/actions/thunks/onboarding-listing";

function DisplayName({ displayName, setDisplayName, CheckDisplayName }) {
    const [displayName_, setDisplayName_] = useState(displayName);
    const [msg, setMsg] = useState("");
    const [color, setColor] = useState("green");
    const debounceTimeout = useRef(null);

    const handleChange = (e) => {
        let display = e.target.value.trim();
        setDisplayName_(display);
        setDisplayName(display);

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(async () => {
            if (display.split(" ").length > 1) {
                setMsg("Spaces not allowed!");
                setColor("red");
            } else if (display !== "") {
                try {
                    await CheckDisplayName(display);
                    setMsg("Available");
                    setColor("green");
                } catch (e) {
                    setMsg("Taken");
                    setColor("red");
                }
            } else {
                setMsg("");
            }
        }, 1000); // Adjust the delay as needed
    };

    return (
        <>
            <Input
                value={displayName_}
                size="small"
                onChange={handleChange}
            />
            <p style={{ color: color, fontSize: 10, margin: "0" }}>{msg}</p>
        </>
    );
}

const mapDispatch = {
    CheckDisplayName: ThunksOnboardingListing.CheckDisplayName,
};

export default connect(null, mapDispatch)(DisplayName);
