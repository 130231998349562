import FixedRouteRate from "./FixedRouteRate";
import DistanceRate from "./DistanceRate";
import HourlyRule from "./HourlyRule";
import AirportPickupFees from "./AirportPickupFees";
import LeadTimeList from "./LeadTimeList";

const RenderRateManageScreen = ({
  type,
  token,
  setMenuType,
  serviceLevelsList,
  setRateStatusData,
  leadTimeModalOpen,
  checkRateModalOpen,
  setLeadTimeModalOpen = () => {},
  setCheckRateModalOpen = () => {},
}) => {
  switch (type) {
    case "fixed_route_rate":
      return (
        <FixedRouteRate token={token} serviceLevelsList={serviceLevelsList} />
      );
    case "distance_rate":
      return (
        <DistanceRate
          token={token}
          serviceLevelsList={serviceLevelsList}
          checkRateModalOpen={checkRateModalOpen}
          setCheckRateModalOpen={setCheckRateModalOpen}
          setRateStatusData={setRateStatusData}
        />
      );
    case "hourly_rule":
      return (
        <HourlyRule
          token={token}
          setRateStatusData={setRateStatusData}
          serviceLevelsList={serviceLevelsList}
        />
      );
    case "airport_pickup_fees":
      return <AirportPickupFees token={token} />;
    case "lead_time":
      return (
        <LeadTimeList
          token={token}
          setMenuType={setMenuType}
          serviceLevelsList={serviceLevelsList}
          leadTimeModalOpen={leadTimeModalOpen}
          setLeadTimeModalOpen={setLeadTimeModalOpen}
        />
      );

    default:
      return <></>;
  }
};

export default RenderRateManageScreen;
