import MOModalWrapper from "../../../../components/commons/MOModalWrapper";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import { GetCountries } from "react-country-state-city";
import { useEffect, useState } from "react";
import Select from "react-select";
import ReactCountryFlag from "react-country-flag";
import mobioApi from "../../../../services";
import { toastMessage } from "../../../../helpers/toastMessage";

const customFormatOptionLabel = (option, { context, selectValue }) => {
  return (
    <>
      {context === "menu" && (
        <div className="flex mb-6 align-item-center">
          <ReactCountryFlag
            countryCode={option?.iso2}
            svg
            style={{
              width: "26px",
              height: "20px",
              borderRadius: 0,
            }}
          />
          <p className="font-14 font-400 ml-10">{`${option.label} - ${option?.currency}`}</p>
        </div>
      )}
      {context === "value" && (
        <>
          <div className="flex align-item-center">
            <ReactCountryFlag
              countryCode={option?.iso2}
              svg
              style={{
                width: "26px",
                height: "20px",
                borderRadius: 0,
              }}
            />
            <p className="font-14 font-400 ml-10">{`${option.label} - ${option?.currency}`}</p>
          </div>
        </>
      )}
    </>
  );
};
const AddCountryModal = ({
  modalOpen,
  setModalOpen,
  token,
  integrationType,
  companyId,
  apiCall,
}) => {
  const [countryList, setCountryList] = useState([]);
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const payload = {
        companyId: companyId,
        integrationType: integrationType,
        countryName: values.countryDetail?.name,
        currency: values.countryDetail?.currency,
        isActive: true,
        isDeleted: false,
      };
      const api = mobioApi(token);
      const response = await api.addNewCountry(payload);
      toastMessage("success", response?.data?.msg);
      apiCall();
      setModalOpen(false);
    } catch (error) {
      toastMessage("error", error?.response?.data.msg);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    GetCountries().then((result) => {
      setCountryList(
        result?.map((item) => {
          return { value: item.name, label: item.name, ...item };
        })
      );
    });
  }, []);
  return (
    <MOModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={"Add Country"}
      className={"country-city-modal header-modal-section"}
    >
      <div>
        <Formik
          initialValues={{
            countryDetail: "",
          }}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="mt-10">
                <h6 className="m-0 font-12 font-600 mb-12">Select Country</h6>
                <Select
                  placeholder="Choose a country"
                  closeMenuOnSelect={true}
                  isMulti={false}
                  options={countryList}
                  formatOptionLabel={customFormatOptionLabel}
                  onChange={(e) => {
                    console.log("e", e);
                    setFieldValue("countryDetail", e);
                  }}
                  className="select-country-list-field"
                />
              </div>

              <div className="mt-20">
                <MOButtonV2
                  text={"PROCEED"}
                  type="submit"
                  textColor="#ffffff"
                  radius={4}
                  backgroundColor="#29AEE6"
                  height={44}
                  fontSize={11}
                  widthSize={"100%"}
                  padding={"16px 0px"}
                  disabled={isSubmitting || !values.countryDetail}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </MOModalWrapper>
  );
};

export default AddCountryModal;
