import Local from "../../local";
import _ from "lodash";

export type ThunksSpreadSheets = {
  AuthURL: (data: any) => any;
  CreateSheet: (data: any) => any;
  ImportSheet: (data: any) => any;
};

const ThunksSpreadSheet: ThunksSpreadSheets = {
  AuthURL: (data: any) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);

    dispatch(Local.AuthSpreadSheet.api.request());
    return api
      .getAuthURL(data)
      .then((response: any) => {
        dispatch(Local.AuthSpreadSheet.api.response(response.data.authUrl));
        return response.data;
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.AuthSpreadSheet.api.error(
              "Request Failed",
              "Something went wrong. Please try again."
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(Local.AuthSpreadSheet.api.error("Request Failed", data.msg));
        }
      });
  },
  CreateSheet: (data: any) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);

    dispatch(Local.CreateSheet.api.request());
    return api
      .createSheet(data)
      .then((response: any) => {
        dispatch(Local.CreateSheet.api.response(response.data));
        return response.data;
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.CreateSheet.api.error(
              "Request Failed",
              "Something went wrong. Please try again."
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(Local.CreateSheet.api.error("Request Failed", data.msg));
          return data;
        }
      });
  },
  ImportSheet: (data: any) => (dispatch: any, getState: any, mobioApi: any) => {
    const {
      userInfo: { token },
    } = getState();
    const api = mobioApi(token);  
    dispatch(Local.ImportSheet.api.request());
    return api
      .importSheet(data)
      .then((response: any) => {
        dispatch(Local.ImportSheet.api.response(response.data));
        return response.data;
      })
      .catch((error: any) => {
        if (error.code === "ECONNABORTED") {
          dispatch(
            Local.ImportSheet.api.error(
              "Request Failed",
              "Something went wrong. Please try again."
            )
          );
        } else {
          const {
            response: { data },
          } = error;
          dispatch(Local.ImportSheet.api.error("Request Failed", data.message));
          return data;
        }
      });
  },
};

export default ThunksSpreadSheet;
