import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import ThunksBooking from "../../../redux/actions/thunks/bookings";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import Local from "../../../redux/actions/local";
import mobioApi from "../../../services";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  mainCard: {
    display: "flex",
    alignContent: "center",
    minWidth: 275,
  },
  root: {
    padding: 20
  },

  title: {
    marginLeft: 80,
    marginRight: 80,
  },
  input: {
    display: "flex",
    flexDirection: "column",
  },
  innerInput: {
    paddingTop: 48,
  },
  button: {
    backgroundColor: theme.palette.error.main,
    color: "#fff",
  },
}));
const BookingIntegrationApiKey = ({
  bookingIntegration,
  removeBookingIntegration,
  companyId,
  connectSuccess,
  connectError,
  reset,
  error,
  token,
  loader,
  message,
}) => {
  const classes = useStyles();
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [integration, setIntegration] = useState(false);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const api = mobioApi(token);
    api.getBookingIntegration("Booking.com").then((res) => {
      setIntegration(res.data.bookingIntegration);
      setLoad(false);
    });
  }, [connectSuccess, connectError, loader, load]);
  return (
    <>
    <Toolbar className={classes.toolbar} style={{ marginLeft: "-102px"}}>
      <Typography
        component="h1"
        variant="h6"
        color="#4B4B4B"
        noWrap
        className={classes.title}
      >
        API KEY ( Booking.com )
      </Typography>
          
    </Toolbar>
      <Grid
        container
        alignItems="center"
        direction="row"
        className={classes.mainCard}
      >
        <Card className={classes.root}>
            {load ? (
              <CardContent>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CircularProgress />
              </div>
              </CardContent>
            ) : (
              !integration && (
                <CardContent>
                <div className={classes.input}>
                  <TextField
                    autoFocus={true}
                    id="standard-basic"
                    label="Client Id"
                    style={{ marginBottom: "24px" }}
                    onChange={(e) => setClientId(e.target.value)}
                  />
                  <TextField
                    id="standard-basic"
                    label="Client Secret"
                    style={{ marginBottom: "24px" }}
                    onChange={(e) => setClientSecret(e.target.value)}
                  />
                </div>
                </CardContent>
              )
            )} 
          
          <Grid container justifyContent="center" alignItems="center">
            {!load && (
              <CardActions>
                {integration ? (
                  <Button
                    size="large"
                    variant="contained"
                    className={classes.button}
                    // disabled={loader}
                    onClick={() => {
                      if(window.confirm("Are you sure you want to disconnet current integration?")) {
                        removeBookingIntegration(companyId,"Booking.com");
                      }

                      
                    }}
                  >
                    Disconnect
                  </Button>
                ) : (
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    // disabled={loader}
                    onClick={() => {
                      bookingIntegration(companyId, {
                        clientId,
                        clientSecret,
                        type: "Booking.com"
                      });
                    }}
                  >
                    Connect
                  </Button>
                )} 
              </CardActions>
            )} 
          </Grid>
        </Card>
      </Grid>
      {connectSuccess && (
        <ErrorSnack success reset={() => reset()} errorMsg={message} />
      )}
      {connectError && (
        <ErrorSnack reset={() => reset()} errorMsg={error.message} />
      )} 
    </>
  );
};

const mapState = (state) => ({
  companyId: state.userInfo.user.id,
  token: state.userInfo.token,
  error: state.bookingIntegration.error,
  connectSuccess: state.bookingIntegration.response.status,
  message: state.bookingIntegration.response.data,
  loader: state.bookingIntegration.loading,
  connectError: state.bookingIntegration.error.status,
});

const mapDispatch = {
  bookingIntegration: ThunksBooking.BookingIntegration,
  removeBookingIntegration: ThunksBooking.RemoveBookingIntegration,
  reset: Local.BookingIntegration.api.reset,
};

export default connect(mapState, mapDispatch)(BookingIntegrationApiKey);
