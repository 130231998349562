import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  getTimeFormat24Hour,
  removeLast00,
} from "../../../../helpers/getTimeFormat";
import { toastMessage } from "../../../../helpers/toastMessage";
import { commissionValidationSchema } from "../../../../validations/rateModuleValidation";
import mobioApi from "../../../../services";
import ClockTimer from "../../../../components/commons/ClockTimer";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import MOModalWrapper from "../../../../components/commons/MOModalWrapper";

const AddCommissionModal = ({
  modalOpen,
  setModalOpen,
  token,
  status = "add",
  commissionId,
}) => {
  const formik = useRef();

  const [commissionDetails, setCommissionDetails] = useState({});
  const { integrationId, airportId } = useParams();

  const handleSubmit = async (values) => {
    try {
      const payload = {
        integrationId,
        airportId,
        ...values,
        startTime: getTimeFormat24Hour(values.startTime),
        endTime: getTimeFormat24Hour(values.endTime),
      };
      const api = mobioApi(token);
      if (status == "add") {
        await api.addRateCommissionRule(payload);
        toastMessage("success", "Commission Rule Added");
      } else if (status == "edit") {
        await api.updateRateCommissionRule(payload);
        toastMessage("success", "Commission Rule Updated");
      }
      setModalOpen(false);
    } catch (error) {
      toastMessage("error", error.response?.data?.msg);
    }
  };

  const getCommissionDetails = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getRateCommissionRule(commissionId);
      setCommissionDetails(response?.data.data);
    } catch (error) {
      setCommissionDetails([]);
    }
  };

  useEffect(() => {
    if (status == "edit" && commissionId) {
      getCommissionDetails();
    } else {
      setCommissionDetails({});
    }
  }, [status, commissionId]);

  return (
    <MOModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      className={"add-commission-modal"}
      title={
        <>
          <div>
            <h3 className="font-17 font-500 ml-21 text-black">
              Add Commission Rule
            </h3>
          </div>
        </>
      }
    >
      <div className="pt-8 ml-21 mr-24">
        <Formik
          innerRef={formik}
          initialValues={{
            serviceLevel: "",
            commission: "",
            apply: true,
            roundable: false,
            ...commissionDetails,
            startTime: removeLast00(commissionDetails?.startTime ?? "00:00:00"),
            endTime: removeLast00(commissionDetails?.endTime ?? "00:00:00"),
          }}
          validationSchema={commissionValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              {console.log(values)}
              <div className="flex align-item-center gap-16 mt-18">
                {/* Start Time */}
                <div className="flex-1">
                  <p className="m-0 mb-8 font-14 font-600 text-black">
                    Start Time
                  </p>

                  <Field
                    name="startTime"
                    render={({ field, form }) => {
                      return (
                        <ClockTimer
                          field={field}
                          form={form}
                          style={{
                            height: "32px",
                          }}
                          iconStyle={{
                            height: "16px",
                            width: "16px",
                            cursor: "pointer",
                            marginRight: "4px",
                          }}
                        />
                      );
                    }}
                  />

                  <ErrorMessage
                    name="startTime"
                    component="p"
                    className="text-danger font-10"
                  />
                </div>

                {/* End Time */}
                <div className="flex-1">
                  <p className="m-0 mb-8 font-14 font-600 text-black">
                    End Time
                  </p>

                  <Field
                    name="endTime"
                    render={({ field, form }) => {
                      return (
                        <ClockTimer
                          field={field}
                          form={form}
                          style={{
                            height: "32px",
                          }}
                          iconStyle={{
                            height: "16px",
                            width: "16px",
                            cursor: "pointer",
                            marginRight: "4px",
                          }}
                        />
                      );
                    }}
                  />

                  <ErrorMessage
                    name="endTime"
                    component="p"
                    className="text-danger font-10"
                  />
                </div>
              </div>

              {/* Service Level */}
              <div className="mt-26">
                <h3 className="m-0 mb-8 font-14 font-600 text-black">
                  Select Service Level
                </h3>
                <div className="airport-service-level-fields">
                  <Field
                    as="select"
                    name="serviceLevel"
                    className="w-full"
                    placeholder="Select Service Level"
                  >
                    <option value="">Select Service Level</option>
                    <option value="STANDARD">STANDARD</option>
                    <option value="CARRIER">CARRIER</option>
                    <option value="EXECUTIVE">EXECUTIVE</option>
                    <option value="EXE_CARRIER">EXE CARRIER</option>
                    <option value="ELECTRIC_LARGE_PEOPLE_CARRIER">
                      ELECTRIC LARGE PEOPLE CARRIER
                    </option>
                    <option value="LUXURY">LUXURY</option>
                    <option value="ELECTRIC_EXE_PEOPLE_CARRIER">
                      ELECTRIC EXE PEOPLE CARRIER
                    </option>
                    <option value="ELECTRIC_PEOPLE_CARRIER">
                      ELECTRIC PEOPLE CARRIER
                    </option>
                    <option value="ELECTRIC_LUXURY">ELECTRIC LUXURY</option>
                    <option value="LARGE_CARRIER">LARGE CARRIER</option>
                    <option value="MINI_BUS">MINI BUS</option>
                    <option value="ELECTRIC">ELECTRIC</option>
                  </Field>
                  <ErrorMessage
                    name="serviceLevel"
                    component="p"
                    className="text-danger font-10"
                  />
                </div>
              </div>

              {/* Commission */}
              <div className="mt-26">
                <h3 className="m-0 mb-8 font-14 font-600 text-black">
                  Commission (%)
                </h3>
                <div className="airport-service-level-fields">
                  <Field
                    type="number"
                    name="commission"
                    placeholder="Enter commission percentage"
                    className="w-full"
                  />
                  <ErrorMessage
                    name="commission"
                    component="p"
                    className="text-danger font-10"
                  />
                </div>
              </div>

              {/* Roundable */}
              <div
                className="font-12 mt-15 flex"
                style={{ alignItems: "flex-start" }}
              >
                <Field
                  id="roundable"
                  type="checkbox"
                  name="roundable"
                  checked={values.roundable}
                />
                <label
                  htmlFor="roundable"
                  style={{
                    marginLeft: 7,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span className="font-14 font-600 text-black">Roundable</span>
                  <span className="font-11" style={{ color: "gray" }}>
                    Round it up in Organization Favour.
                  </span>
                </label>
              </div>

              {/* Submit Button */}
              <div className="flex align-item-center justify-content-end mt-42">
                <MOButtonV2
                  type="submit"
                  text={status == "add" ? "Add" : "Update"}
                  textColor="#ffffff"
                  radius={4}
                  widthSize={137}
                  height={32}
                  padding={"10px 12px 10px 12px"}
                  backgroundColor="#29AEE6"
                  fontSize={11}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </MOModalWrapper>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(AddCommissionModal);
