import ONBOARDING_LISTING from "../constants/onboarding-listing";

export type OnboardingLitingRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
  };
};

const OnboardingListing: OnboardingLitingRepo = {
  api: {
    request: () => ({
      type: ONBOARDING_LISTING.ONBOARDING_LISTING_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: ONBOARDING_LISTING.ONBOARDING_LISTING_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: ONBOARDING_LISTING.ONBOARDING_LISTING_API.SET_RESPONSE,
      payload: { data },
    }),
  },
};

export default OnboardingListing;
