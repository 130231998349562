import React from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddUser from "./AddUser";
import UserList from "./UserList";
import { connect } from "react-redux";
import ThunksUsers from "../../../redux/actions/thunks/OrgUsers";
import AddUserForm from "./AddUserForm";

function OrgUsers({ getAllAuthorizations, getAllOrgUsers }) {
  React.useEffect(() => {
    getAllAuthorizations();
    getAllOrgUsers();
  }, []);

  return (
    <>
      <Grid
        container
        direction="row-reverse"
        x
        justifyContent="flex-start"
        alignItems="center"
      >
        {/* <AddUser /> */}
        <AddUserForm />
      </Grid>
      <UserList />
    </>
  );
}

const mapDispatch = {
  // getCustomers: ThunksCustomers.Customers,
  getAllAuthorizations: ThunksUsers.getAllAuthorizations,
  getAllOrgUsers: ThunksUsers.orgUsers,
};

export default connect(null, mapDispatch)(OrgUsers);
