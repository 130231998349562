const AvailabilityIcon = ({ color = "#29AEE6" }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.435 4.28438V3.19025C12.0685 3.1695 11.7434 3.16972 11.3838 3.19056V4.28438C11.3838 4.57419 11.6196 4.80997 11.9094 4.80997C12.1992 4.80997 12.435 4.57419 12.435 4.28438Z"
        fill={color}
      />
      <path d="M20.607 19.3231V18.1689L19.6191 19.3231H20.607Z" fill={color} />
      <path
        d="M11.9294 19.4121C11.6294 19.4018 11.3846 19.6404 11.3846 19.9374V21.0312C11.7329 21.0512 12.0877 21.0512 12.4357 21.0312V19.9582C12.4357 19.6669 12.2086 19.4219 11.9294 19.4121ZM18.8012 14.6231C16.4183 14.6231 14.4797 16.5617 14.4797 18.9446C14.4797 21.3275 16.4184 23.2661 18.8012 23.2661C21.1841 23.2661 23.1228 21.3275 23.1228 18.9446C23.1228 16.5617 21.1841 14.6231 18.8012 14.6231ZM18.1641 21.047H15.9345C15.7927 21.047 15.665 20.9616 15.6108 20.8307C15.5565 20.6997 15.5865 20.549 15.6867 20.4488L17.3061 18.8294C17.5204 18.6151 17.5349 18.2659 17.339 18.0346C17.0893 17.7396 16.6278 17.7618 16.4077 18.08L16.2227 18.3476C16.1126 18.5068 15.8945 18.5467 15.7352 18.4365C15.576 18.3264 15.5362 18.1082 15.6463 17.949L15.8314 17.6813C16.3137 16.984 17.3257 16.9343 17.8739 17.5817C18.3035 18.0891 18.2717 18.8548 17.8017 19.3249L16.7804 20.3462H18.1641C18.3576 20.3462 18.5145 20.503 18.5145 20.6966C18.5145 20.8901 18.3576 21.047 18.1641 21.047ZM22.0184 19.6454C22.0184 19.8389 21.8616 19.9958 21.668 19.9958H21.3176V20.6966C21.3176 20.8901 21.1608 21.047 20.9672 21.047C20.7737 21.047 20.6168 20.8902 20.6168 20.6966V19.9958H18.8678C18.731 19.9958 18.6068 19.9162 18.5496 19.792C18.4924 19.6677 18.5127 19.5215 18.6016 19.4175L20.701 16.9648C20.7964 16.8534 20.9511 16.8132 21.0887 16.864C21.2263 16.9148 21.3177 17.046 21.3177 17.1926V19.295H21.6681C21.8616 19.295 22.0184 19.4519 22.0184 19.6454ZM5.96381 17.2566L5.17871 18.0417C5.41154 18.3037 5.65967 18.5519 5.92195 18.785L6.70701 17.9999C6.91258 17.7943 6.91266 17.4623 6.70706 17.2566C6.50149 17.0511 6.16942 17.051 5.96381 17.2566ZM15.7645 12.4039C15.9597 12.4039 16.1149 12.2444 16.1149 12.0535C16.1149 11.8603 15.9577 11.7031 15.7645 11.7031H13.6269C13.6738 11.9336 13.6739 12.1729 13.6269 12.4039H15.7645Z"
        fill={color}
      />
      <path
        d="M11.9095 11.0025C11.3298 11.0025 10.8583 11.4741 10.8583 12.0537C10.8583 12.6333 11.3299 13.1049 11.9095 13.1049C12.4891 13.1049 12.9606 12.6333 12.9606 12.0537C12.9606 11.4741 12.4891 11.0025 11.9095 11.0025ZM4.02558 11.5281H2.93176C2.92169 11.7021 2.91602 11.8772 2.91602 12.0537C2.91602 12.2302 2.9216 12.4053 2.93149 12.5793H4.02562C4.31544 12.5793 4.55121 12.3435 4.55121 12.0537C4.55117 11.7639 4.31539 11.5281 4.02558 11.5281ZM11.5591 7.14821V10.337C11.7895 10.29 12.0289 10.2899 12.2599 10.337V7.14821C12.2599 6.95302 12.1003 6.7978 11.9095 6.7978C11.7162 6.7978 11.5591 6.95495 11.5591 7.14821ZM6.70636 6.10737L5.93595 5.33695C5.67406 5.57013 5.42584 5.81835 5.19266 6.08028L5.96303 6.85061C6.1686 7.05618 6.50067 7.05626 6.70632 6.85066C6.91193 6.64505 6.91202 6.31298 6.70636 6.10737ZM20.8872 12.5793C20.9073 12.2309 20.9073 11.8769 20.8872 11.5281H19.8142C19.5229 11.5281 19.2779 11.7553 19.2681 12.0345C19.2577 12.3346 19.4967 12.5793 19.7934 12.5793H20.8872ZM17.8558 6.85061L18.6409 6.06551C18.4078 5.80322 18.1596 5.55509 17.8976 5.32227L17.1126 6.10728C16.907 6.31285 16.9069 6.64491 17.1125 6.85057C17.3181 7.05618 17.6502 7.05626 17.8558 6.85061Z"
        fill={color}
      />
      <path
        d="M11.9099 0.84082C8.92249 0.84082 6.10883 2.00922 3.98722 4.13078C1.86566 6.25238 0.697266 9.06604 0.697266 12.0535C0.69731 18.2361 5.72725 23.2661 11.9099 23.2661H11.9187C12.2036 23.2658 12.4355 23.031 12.4355 22.7426V21.7332C6.89709 22.0382 2.21563 17.6206 2.21563 12.0534C2.21563 6.7165 6.55821 2.35918 11.9099 2.35918C17.4572 2.35918 21.8921 7.01651 21.5894 12.579H22.599C22.8875 12.579 23.1222 12.3471 23.1225 12.062V12.0534C23.1225 5.8708 18.0926 0.840864 11.9099 0.84082Z"
        fill={color}
      />
    </svg>
  );
};

export default AvailabilityIcon;
