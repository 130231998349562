import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Dialog,
  DialogTitle,
  makeStyles,
  Button,
  Typography,
  Box,
  InputAdornment,
} from "@material-ui/core";
import Input from "../../../components/commons/Input";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import ThunksPassengers from "../../../redux/actions/thunks/passengers";
import MuiPhoneNumber from "material-ui-phone-number";
import MOButton from "../../../components/commons/MOButton";
import { SignalPassengerIcon } from "../../../assets/icons";
import { EmailOutlined } from "@material-ui/icons";
import { ErrorMessage, Form, Formik } from "formik";
const useStyles = makeStyles((theme) => ({
  disabled: {
    backgroundColor: "red",
  },
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    margin: theme.spacing(2),
  },
  button: {
    borderRadius: 6,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
    marginLeft: 10,

    "&:disabled": {
      backgroundColor: "#8bd2e8",
      borderRadius: 6,
      color: "#FFFFFF",
      height: 40,
      marginLeft: 10,
    },
  },
  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    height: 40,
  },
  textfield_lower: {
    height: 40,
  },
  textfield_side: {
    height: 40,
  },
  ExclusiveSelect: {
    marginRight: 26,
  },
  MultiSelect: {
    marginTop: 20,
  },
  select: {
    marginTop: 20,
    width: 310,
  },
  autoComplete: {
    backgroundColor: "red",
  },
  modalHeadingContainer: {
    padding: 0,
  },
}));

function SimpleDialog({
  open,
  onClose,
  createPassenger,
  userData,
  updatePassengers,
}) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [guestNameEmpty, setGuestNameEmpty] = useState(false);
  const [passengerCreate, setPassengerCreate] = useState(false);
  const [passengerUpdate, setPassengerUpdate] = useState(false);
  const [phoneDuplicated, setPhoneDuplicated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const classes = useStyles();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let result = null;
      if (userData?.id) {
        result = await updatePassengers(userData?.id, {
          id: userData.id,
          passengerName: values.passengerName,
          phone: values.phone,
          email: values.email,
        });
      } else {
        result = await createPassenger({
          passengerName: values.passengerName,
          phone: values.phone,
          email: values.email,
        });
      }

      if (result == true) {
        if (userData) {
          setPassengerUpdate(true);
        } else {
          setPassengerCreate(true);
        }
        onClose();
      } else {
        setErrorMessage(result);
      }
    } catch (error) {
      console.log("Error");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Dialog
        disableBackdropClick
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <div className={classes.dialogPaper}>
          <Grid>
            <Grid
              container
              direction="row"
              // justifyContent="center"
              alignItems="center"
            >
              <DialogTitle
                className={classes.modalHeadingContainer}
                id="simple-dialog-title"
              >
                <Typography style={{ fontWeight: 500, fontSize: 24 }}>
                  {userData ? "Edit Details" : " Add Passenger"}
                </Typography>
              </DialogTitle>
            </Grid>
            {/* Formik Form handling start */}
            <Formik
              initialValues={{
                passengerName: userData?.passengerName
                  ? userData?.passengerName
                  : "",
                email: userData?.email ? userData.email : "",
                phone: userData?.phone ? userData?.phone : "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.passengerName) {
                  errors.passengerName = "Passenger Name is required.";
                }
                if (!values.phone) {
                  errors.phone = "Phone Number is required.";
                }

                if (!values.email) {
                  errors.email = "Email is required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }

                return errors;
              }}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                  <Grid
                    container
                    spacing={2}
                    style={{ marginTop: 8, marginBottom: 8 }}
                  >
                    <Grid item xs={12}>
                      <Input
                        fullWidth
                        label="Passenger Name"
                        name="passengerName"
                        size="small"
                        value={values.passengerName}
                        className={classes.textfield_side}
                        onChange={(e) => {
                          setFieldValue("passengerName", e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SignalPassengerIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <ErrorMessage
                        name={`passengerName`}
                        component="div"
                        className="error-text-danger error-text-style"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        fullWidth
                        label="Passenger Email"
                        name="email"
                        size="small"
                        multiline
                        value={values.email}
                        className={classes.textfield_side}
                        onChange={(e) => {
                          setFieldValue("email", e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailOutlined style={{ color: "#AAAAAA" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <ErrorMessage
                        name={`email`}
                        component="div"
                        className="error-text-danger error-text-style"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MuiPhoneNumber
                        fullWidth
                        variant="outlined"
                        label="Phone Number"
                        name="phone"
                        defaultCountry={"nl"}
                        value={values.phone}
                        onChange={(value) => {
                          setFieldValue("phone", value);
                        }}
                        className={classes.textfield_side}
                      />
                      <ErrorMessage
                        name={`phone`}
                        component="div"
                        className="error-text-danger error-text-style"
                      />
                    </Grid>
                  </Grid>

                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    marginTop={"24px"}
                  >
                    <Box marginRight={"12px"}>
                      <MOButton
                        variant="contained"
                        type="secondary"
                        icon={false}
                        onClick={onClose}
                        title="Cancel"
                      />
                    </Box>
                    <MOButton
                      submitType="submit"
                      title={!!userData?.id ? "Update " : "Save"}
                      disabled={isSubmitting}
                      icon={false}
                    />
                  </Box>
                </Form>
              )}
            </Formik>
            {/* Formik Form handling end */}
          </Grid>
        </div>
      </Dialog>
      {errorMessage != "" && (
        <ErrorSnack reset={() => setErrorMessage("")} errorMsg={errorMessage} />
      )}
      {guestNameEmpty && (
        <ErrorSnack
          reset={() => setGuestNameEmpty(false)}
          errorMsg={"You must insert Guest Name and Phone Number."}
        />
      )}
      {phoneDuplicated && (
        <ErrorSnack
          reset={() => setPhoneDuplicated(false)}
          errorMsg={"Your Phone Number has already existed"}
        />
      )}
      {passengerCreate && (
        <ErrorSnack
          reset={() => setPassengerCreate(false)}
          success
          errorMsg={"Passenger Created"}
        />
      )}
      {passengerUpdate && (
        <ErrorSnack
          reset={() => setPassengerUpdate(false)}
          success
          errorMsg={"Passenger Updated"}
        />
      )}
    </>
  );
}
const mapState = (state) => ({});

const mapDispatch = {
  createPassenger: ThunksPassengers.createPassenger,
  updatePassengers: ThunksPassengers.updatePassengers,
};

export default connect(mapState, mapDispatch)(SimpleDialog);
