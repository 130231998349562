export const PROFILE_API = {
  SET_REQUEST: "ACTION.PROFILE_API.SET_REQUEST",
  SET_RESPONSE: "ACTION.PROFILE_API.SET_RESPONSE",
  SET_ERROR: "ACTION.PROFILE_API.SET_ERROR",
  SET_RETRY: "ACTION.PROFILE_API.SET_RETRY",
  RESET: "ACTION.PROFILE_API.RESET",
};

export const PROFILE_PASSWORD_API = {
  SET_REQUEST: "ACTION.PROFILE_PASSWORD_API.SET_REQUEST",
  SET_RESPONSE: "ACTION.PROFILE_PASSWORD_API.SET_RESPONSE",
  SET_ERROR: "ACTION.PROFILE_PASSWORD_API.SET_ERROR",
  SET_RETRY: "ACTION.PROFILE_PASSWORD_API.SET_RETRY",
  RESET: "ACTION.PROFILE_PASSWORD_API.RESET",
};

export const FORGOT_PASSWORD_API = {
  SET_REQUEST: "ACTION.FORGOT_PASSWORD_API.SET_REQUEST",
  SET_RESPONSE: "ACTION.FORGOT_PASSWORD_API.SET_RESPONSE",
  SET_ERROR: "ACTION.FORGOT_PASSWORD_API.SET_ERROR",
  SET_RETRY: "ACTION.FORGOT_PASSWORD_API.SET_RETRY",
  RESET: "ACTION.FORGOT_PASSWORD_API.RESET",
};

const USER = {
  RESET_USER: "ACTION.LOCAL.USER.RESET_USER",
  UPDATE_USER: "ACTION.LOCAL.USER.UPDATE_USER",
  PROFILE_UPDATE: "ACTION.LOCAL.USER.PROFILE_UPDATE",
  PROFILE_IMAGE: "ACTION.LOCAL.USER.PROFILE_IMAGE",
  SET_RESPONSE: "ACTION.LOCAL.USER.SET_RESPONSE",
};

export default USER;
