import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  CountrySelect,
  StateSelect,
  GetCity,
  GetCountries,
  GetState,
} from "react-country-state-city";
import { Country, State, City } from "country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import Breadcrumb from "../../../components/BreadCrumb";
import Select from "react-select";
import { allAirports } from "../../../data/airports";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import ReactCountryFlag from "react-country-flag";
import { Search } from "@material-ui/icons";
import EditLocation from "../EditLocation";
import { airportSelectionValidationSchema } from "../../../validations/rateModuleValidation";
import Location from "../../../redux/actions/local/rate-module";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { countryCodeList } from "../../../data/countryCodeList";

function AddLocation({ updateField, clearFields }) {
  const navigate = useNavigate();
  const { integrationType, integrationId, action } = useParams();
  const [tabSelection, setTabSelection] = useState(1);
  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [IATAAirports, setIATAAirports] = useState([]);
  const [searchIATA, setSearchIATA] = useState("");
  const [firstTabData, setFirstTabData] = useState({});
  // for edit portion
  const stateData = useLocation();
  const formikRef = useRef(null);

  const paths = [
    {
      name: action === "add" ? "Add New Location" : "Edit Location",
      url: "/dashboard/integration/rates/city-airport/detail",
    },
  ];
  const secondaryPaths = [
    {
      name: stateData?.state?.country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: stateData?.state?.city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${stateData?.state?.country}/${stateData?.state?.countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${stateData?.state?.country}/${stateData?.state?.countryId}/${stateData?.state?.city}/${stateData?.state?.cityId}/city-airport`,
    },
    {
      name: stateData?.state?.data?.iata,
      url: "/dashboard/integration/rates/city",
    },
    {
      name: action === "add" ? "Add New Location" : "Edit Location",
      // url: "/dashboard/integration/rates/city-airport/detail",
    },
  ];

  const getAirportDetail = (city) => {
    return allAirports?.filter((air) => air?.city === city);
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    setFirstTabData({
      ...values.airportDetail,
      integrationType: integrationType,
    });
    setTabSelection(2);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "0.5px solid #000" : "0.5px solid #000",
      // boxShadow: state.isFocused ? "0 0 0 1px #000" : provided.boxShadow,
      borderWidth: "0.5px",
      borderRadius: "2px",
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        // border: "1px solid #000",
      },
    }),
    menu: (provided) => ({
      ...provided,
      // height: "200px",
      // overflowY: "auto",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "1px 6px",

      backgroundColor: state.isSelected ? "#fff" : "#fff",
      color: state.isSelected ? "#000" : "#000",
      "&:hover": {
        backgroundColor: state.isSelected ? "#e9ecef" : "#e9ecef",
        color: state.isSelected ? "#000" : "#000",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "16px",
      color: "#495057",
    }),
  };

  useEffect(() => {
    if (integrationType) {
      updateField("integrationType", integrationType);
    }
  }, [integrationType]);

  useEffect(() => {
    if (action === "edit") {
      const getCountryId = document.getElementById("select-country-id");
      getCountryId?.setAttribute("disabled", true);
      const { country, city } = stateData?.state;
      const stateName = stateData?.state?.data?.associatedCities.state;
      const iataName = stateData?.state?.data?.iata;
      handleToSetFieldValues(country, stateName, city, iataName, action);
    }
  }, []);

  const handleToSetFieldValues = (
    country,
    state,
    city,
    iata = "",
    mode = "searching"
  ) => {
    GetCountries().then((result) => {
      result?.forEach((item) => {
        if (item?.name === country) {
          setCountryId(item);
          updateField("countryName", item.id);
          //Search the specific city
          GetState(item.id).then((stateResult) => {
            const stateListArray = [];
            stateResult?.forEach((stateItem) => {
              stateListArray.push({
                value: stateItem.name,
                label: stateItem.name,
                ...stateItem,
              });
              if (stateItem?.name === state) {
                setStateId({
                  value: stateItem.name,
                  label: stateItem.name,
                  ...stateItem,
                });
                // setStateId(stateItem.id);
                updateField("stateName", stateItem.id);
                GetCity(item.id, stateItem.id).then((result) => {
                  const cityEditData = result?.map((city) => {
                    return {
                      value: city.name,
                      label: city.name,
                      stateName: stateItem.name || "",
                      ...city,
                    };
                  });
                  //default selected
                  const defaultCitySelected = cityEditData?.filter(
                    (cf) => cf.value === city
                  );
                  setSelectedCities(defaultCitySelected);
                  // set the city list
                  setCityList(cityEditData);
                  // set the airports

                  if (mode === "edit") {
                    const getSelectedAirport = getAirportDetail(
                      defaultCitySelected[0].value
                    );
                    // select the exist airport e.g if the city have multiple airport
                    const existAirport = getSelectedAirport.filter(
                      (ef) => ef.iata === iata
                    );
                    // setEditAirport();
                    if (formikRef?.current) {
                      formikRef?.current?.setFieldValue("airportDetail", {
                        ...existAirport[0],
                        stateName: stateItem.name,
                      });
                    }
                  }
                });
              }
            });
            setStateList(stateListArray);
          });
        }
      });
    });
  };
  const handleGetInfoByIATA = (countryValue, cityValue) => {
    // const countryData = Country.getAllCountries();
    const countryCode = countryCodeList.filter(
      (cf) => cf.name === countryValue
    );
    if (countryCode.length > 0) {
      const cityData = City.getCitiesOfCountry(countryCode[0].code).find(
        (city) => city.name === cityValue
      );

      if (cityData) {
        const getStateNameData = State.getStateByCodeAndCountry(
          cityData.stateCode,
          countryCode[0].code
        );
        // need logic for setting the data in the fields
        handleToSetFieldValues(
          countryValue,
          getStateNameData.name,
          cityValue,
          "",
          "searching"
        );
      }
    }
  };

  return (
    <>
      <div className="mt-18 flex justify-content-between">
        <div>
          <Breadcrumb paths={paths} backType="auto" />
          {action !== "add" && (
            <div className="mt-8 ml-6">
              <Breadcrumb paths={secondaryPaths} secondary={true} />
            </div>
          )}
        </div>
      </div>
      {/* tabs */}

      <section className="tab-selection-section">
        <div
          className={`tab-title ${tabSelection >= 1 ? "active" : "deactive"}`}
          onClick={() => {
            setTabSelection(1);
          }}
        >
          1
        </div>
        <div className="tab-line"></div>
        <div
          className={`tab-title ${tabSelection >= 2 ? "active" : "deactive"}`}
          onClick={() => {
            setTabSelection(2);
          }}
        >
          2
        </div>
      </section>
      {tabSelection === 2 && (
        <>
          <EditLocation
            setTabSelection={() => setTabSelection(1)}
            tabData={firstTabData}
            editData={action === "edit" ? stateData?.state?.data : ""}
          />
        </>
      )}

      {tabSelection === 1 && (
        <>
          <Formik
            innerRef={formikRef}
            initialValues={{
              airportDetail: "",
            }}
            validationSchema={airportSelectionValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ setFieldValue, values, isSubmitting }) => (
              <Form>
                <div className="bg-white main-section-spacing border-radius-8">
                  <div className="add-location-section">
                    <div className="add-location-left-panel">
                      <div className="add-location-left-panel-inner-section">
                        <div className="pr-16 ">
                          <div
                            className={`select-h select-location-field ${
                              action === "edit" && "select-country-box-disabled"
                            }`}
                          >
                            {action === "edit" && (
                              <div className="select-inner-country-box-disabled"></div>
                            )}
                            <h6 className="m-0 font-16 font-500 mb-14">
                              Select Country
                            </h6>
                            <CountrySelect
                              defaultValue={countryId}
                              id="select-country-id"
                              showFlag={true}
                              onChange={(e) => {
                                setCountryId(e);
                                updateField("countryName", e.id);
                                setSelectedCities([]);
                                setIATAAirports([]);
                                setCityList([]);
                                setStateId("");
                                GetState(e.id).then((stateResult) => {
                                  const getStateResult = stateResult?.map(
                                    (item) => {
                                      return {
                                        value: item.name,
                                        label: item.name,
                                        ...item,
                                      };
                                    }
                                  );
                                  setStateList(getStateResult);
                                });
                              }}
                              placeHolder="Select Country"
                            />
                          </div>

                          <div>
                            {countryId?.id && (
                              <div className="location-country-detail">
                                <ReactCountryFlag
                                  countryCode={countryId?.iso2}
                                  svg
                                  style={{
                                    width: "7em",
                                    height: "7em",
                                    borderRadius: 4,
                                  }}
                                />
                                <h3>{countryId?.name}</h3>
                                <p>{countryId?.currency_symbol}</p>
                              </div>
                            )}
                          </div>

                          {/* State */}
                          {/* <div className="mt-18 select-h select-location-field">
                            <h6 className="m-0 font-16 font-500 mb-14">
                              Select State
                            </h6>
                            <StateSelect
                              defaultValue={stateEdit}
                              countryid={countryId?.id}
                              onChange={(e) => {
                                setStateId(e.id);
                                updateField("stateName", e.id);
                                setSelectedCities([]);
                                GetCity(countryId?.id, e.id).then((result) => {
                                  setCityList(
                                    result?.map((city) => {
                                      return {
                                        value: city.name,
                                        label: city.name,
                                        stateName: e.name || "",
                                        ...city,
                                      };
                                    })
                                  );
                                });
                              }}
                              placeHolder="Select State"
                            />
                          </div> */}

                          {/* State V2 */}
                          <div className="mt-18">
                            <h6 className="m-0 font-16 font-500 mb-14">
                              Select State
                            </h6>
                            <Select
                              value={stateId}
                              closeMenuOnSelect={true}
                              isMulti={false}
                              options={stateList}
                              styles={customStyles}
                              onChange={(e) => {
                                setStateId(e);
                                updateField("stateName", e.id);
                                setSelectedCities([]);
                                setIATAAirports([]);
                                GetCity(countryId?.id, e.id).then((result) => {
                                  setCityList(
                                    result?.map((city) => {
                                      return {
                                        value: city.name,
                                        label: city.name,
                                        stateName: e.name || "",
                                        ...city,
                                      };
                                    })
                                  );
                                });
                              }}
                              className="select-h"
                            />
                          </div>

                          {/* City */}
                          <div className="mt-18">
                            <h6 className="m-0 font-16 font-500 mb-14">
                              Select City
                            </h6>
                            <Select
                              value={selectedCities}
                              closeMenuOnSelect={false}
                              isMulti
                              options={cityList}
                              styles={customStyles}
                              onChange={(e) => {
                                setSelectedCities(e);
                                updateField("cityName", e);
                              }}
                              className="select-h"
                            />
                          </div>
                          {/* Or Search by IATA */}
                          <div className="mt-18">
                            <div className="add-location-iata-search">
                              <p className="font-18 font-600">OR</p>
                            </div>
                            <div className="flex align-item-end gap-16">
                              <div className="input_field width-full">
                                <label
                                  htmlFor="IATA"
                                  className="font-16 font-500 mb-14"
                                >
                                  Search by IATA
                                </label>
                                <input
                                  name="IATA"
                                  value={searchIATA}
                                  onChange={(e) => {
                                    setSearchIATA(e.target.value);
                                  }}
                                  className="h-45"
                                />
                              </div>

                              <div>
                                <MOButtonV2
                                  text=""
                                  IconType={() => {
                                    return <Search />;
                                  }}
                                  textColor="#ffffff"
                                  backgroundColor="#29AEE6"
                                  padding="14px 20px"
                                  onClick={() => {
                                    if (searchIATA.length > 0) {
                                      const getIATABaseAirports =
                                        allAirports.filter(
                                          (af) => af.iata === searchIATA
                                        );
                                      if (getIATABaseAirports.length > 0) {
                                        handleGetInfoByIATA(
                                          getIATABaseAirports[0].country,
                                          getIATABaseAirports[0].city
                                        );
                                        setIATAAirports((old) => {
                                          return [
                                            ...old,
                                            getIATABaseAirports[0],
                                          ];
                                        });
                                        setSearchIATA("");
                                      }
                                    }
                                  }}
                                  //

                                  radius={4}
                                  height={45}
                                  fontSize={12}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* right */}
                    <div className="right-panel">
                      <div className="right-panel-content">
                        <ErrorMessage
                          name="airportDetail"
                          component="div"
                          className="text-danger"
                        />
                        <h2 className="font-600 font-20">
                          City Names with Airports
                        </h2>
                        {/* search Base result */}
                        {IATAAirports?.map((list, index) => {
                          if (
                            selectedCities.length < 1 ||
                            selectedCities?.filter(
                              (sf) => sf.label !== list.city
                            ).length > 0
                          ) {
                            return (
                              <div key={index}>
                                <h3 className="airportcityhead font-600 font-16">
                                  {list?.city}
                                </h3>
                                <p className="airportdesc">
                                  Airport of {list?.city}
                                </p>
                                <div className="airport-list">
                                  <div className="airport">
                                    <input
                                      type="radio"
                                      name={`airportDetail`}
                                      onChange={() => {
                                        setFieldValue("airportDetail", {
                                          ...list,
                                        });
                                      }}
                                    />
                                    <label
                                      htmlFor={`${list?.city}-${list?.name}`}
                                    >
                                      {list?.name}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                        {/* selection base result */}
                        {selectedCities.map((city, index) => (
                          <div key={index}>
                            <h3 className="airportcityhead font-600 font-16">
                              {city.label}
                            </h3>
                            <p className="airportdesc">
                              Airport of {city.label}
                            </p>
                            <div className="airport-list">
                              {getAirportDetail(city?.label) &&
                                getAirportDetail(city?.label)?.map(
                                  (airport, index) => (
                                    <div key={index} className="airport">
                                      <input
                                        checked={
                                          values?.airportDetail?.name ===
                                          airport?.name
                                        }
                                        type="radio"
                                        name={`airportDetail`}
                                        // id={`${city?.label}-${airport?.name}`}
                                        onChange={() => {
                                          setFieldValue("airportDetail", {
                                            ...airport,
                                            stateName: city?.stateName,
                                          });
                                        }}
                                      />
                                      <label
                                        htmlFor={`${city?.label}-${airport?.name}`}
                                      >
                                        {airport?.name}
                                      </label>
                                    </div>
                                  )
                                )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      textAlign: "right",
                      paddingRight: "22px",
                    }}
                  >
                    <MOButtonV2
                      type="submit"
                      text="Submit"
                      textColor="#ffffff"
                      radius={4}
                      backgroundColor="#29AEE6"
                      height={35}
                      fontSize={10}
                      padding={"12px 33px 12px 33px"}
                      disable={isSubmitting}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
}
// export default AddLocation
const mapState = (state) => ({
  locationState: state.rateModule.locationState,
});

const mapDispatch = {
  updateField: Location.field.update,
  clearFields: Location.resetAll,
};

export default connect(mapState, mapDispatch)(AddLocation);
