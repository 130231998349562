export const VEHICLES_LISTING_API = {
  SET_REQUEST: "ACTION.VEHICLE_LISTING.SET_REQUEST",
  SET_RESPONSE: "ACTION.VEHICLE_LISTING.SET_RESPONSE",
  SET_ERROR: "ACTION.VEHICLE_LISTING.SET_ERROR",
  SET_RETRY: "ACTION.VEHICLE_LISTING.SET_RETRY",
};

export const ADD_BRAND_API = {
  SET_RESPONSE: "ACTION.ADD_BRAND.SET_RESPONSE",
  SET_ERROR: "ACTION.ADD_BRAND.SET_ERROR",
  SET_RETRY: "ACTION.ADD_BRAND.SET_RETRY",
  SET_REQUEST: "ACTION.ADD_BRAND.SET_REQUEST",
  RESET: "ACTION.ADD_BRAND.RESET",
};

const ADD_BRAND = {
  ADD_BRAND_API,
};

const VEHICLES_LISTING = {
  VEHICLES_LISTING_API,
};

export { VEHICLES_LISTING, ADD_BRAND };
