import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Input from "../../../components/commons/Input";
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  InputAdornment,
  List,
  ListItem,
  Paper,
} from "@material-ui/core";
import { connect } from "react-redux";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import Local from "../../../redux/actions/local";
import AddUserAuthorities from "./AddUserAuthorities";
import CustomerUsers from "../../../redux/actions/thunks/CustomerUsers";
import MOButton from "../../../components/commons/MOButton";
import { SignalPassengerIcon } from "../../../assets/icons";
import { MailOutline } from "@material-ui/icons";

const styles = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  root: {
    ".MuiDialogContent-root": {
      // width:"900px"
    },
    "& .MuiDialog-paperWidthLg": {
        borderRadius:10,
    },
    "& .MuiDialog-paper": {
      // width: "900px !important"
    },
    "& .MuiDialog-paperWidthSm": {
      width: "900px !important",
     
    },
    "& .MuiDialogTitle-root": {},
  },
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    height: 600,
    marginLeft: 16,
    marginRight: 34,
  },

  button: {
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 8,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
  },
  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    // width: 550,
    width: "100%",
    height: 40,
  },
  customDialog: {
    maxWidth: "80%", // Adjust the maximum width as needed
  },
});

const DialogTitle = withStyles(styles as any)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

interface AddUserProps {
  openDialog: () => any;
  closeDialog: () => any;
  postUser: (data: any) => any;
  getAllClientUsers: (text:any) => any;
  updateClientUser: (data: any) => any;
  status: any;
  failure: any;
  success: any;
  dialogIsOpen: any;
  selectedUser: any;
  response: any;
  reset: () => any;
  resetUpadte: () => any;
}

const AddUser: React.FC<AddUserProps> = ({
  openDialog,
  closeDialog,
  getAllClientUsers,
  postUser,
  updateClientUser,
  status,
  failure,
  success,
  reset,
  response,
  dialogIsOpen,
  selectedUser,
  resetUpadte,
}) => {
  const initailUser = {
    email: "",
    firstName: "",
    lastName: "",
  };
  const classes = useStyles();
  const [user, setUser] = useState<any>(initailUser);
  const [error, setError] = useState<any>({
    email: false,
    firstName: false,
    lastName: false,
  });
  const handleClickOpen = () => {
    openDialog();
  };
  const handleClose = () => {
    closeDialog();
  };
  useEffect(() => {
    if (!!selectedUser?.id) {
      setUser(selectedUser);
    } else {
      setUser(initailUser);
    }
  }, [selectedUser?.id]);

  useEffect(() => {
    if (user.firstName !== "") {
      setError({ ...error, firstName: false });
    } else {
      setError({ ...error, firstName: true });
    }
  }, [user.firstName]);

  useEffect(() => {
    if (user?.selectedAuthorities?.length > 0) {
      setError({ ...error, selectedAuthorities: false });
    } else {
      setError({ ...error, selectedAuthorities: true });
    }
  }, [user.selectedAuthorities]);

  useEffect(() => {
    if (user.lastName !== "") {
      setError({ ...error, lastName: false });
    } else {
      setError({ ...error, lastName: true });
    }
  }, [user.lastName]);

  useEffect(() => {
    const rgx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (rgx.test(user.email) && user.email !== "") {
      setError({ ...error, email: false });
    } else {
      setError({ ...error, email: true });
    }
  }, [user.email]);

  const handleChange = (e: any) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <MOButton
        title={!!selectedUser?.id ? "Edit User" : "Add User"}
        onClick={handleClickOpen}
      />
      {/* <Button
        style={{
          borderRadius: 8,
          backgroundColor: "#2AABD5",
          color: "#FFFFFF",
          height: 40,
        }}
        onClick={handleClickOpen}
        variant="contained"
        // startIcon={<AddIcon />}
      >
        {!!selectedUser?.id ? "Edit User" : "Add User"}
      </Button> */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={dialogIsOpen}
        className={classes.root}
        maxWidth={"lg"}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {!!selectedUser?.id ? "Update User" : "Create User"}
        </DialogTitle>
        <DialogContent>
          <div style={{ width: "900px" }}>
            <Grid>
              <Input
                placeholder="First Name"
                value={user.firstName}
                required
                type="text"
                // size="small"
                name="firstName"
                className={classes.textfield_upper}
                onChange={(e: any) => handleChange(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SignalPassengerIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid>
              <Input
                placeholder="Last Name"
                value={user.lastName}
                required
                type="text"
                size="small"
                name="lastName"
                className={classes.textfield_upper}
                onChange={(e: any) => handleChange(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SignalPassengerIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid>
              <Input
                placeholder="Email "
                value={user.email}
                disabled={!!selectedUser?.id}
                required
                autoFocus
                type="email"
                error={error.email}
                size="small"
                name="email"
                className={classes.textfield_upper}
                onChange={(e: any) => handleChange(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutline style={{ color: "#AAAAAA" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/*  */}
            {/* <List style={{display:"flex"}}>
                {[
                  'Bookings',
                  'Customer Profile',
                  'Customer Users',
                ].map((item, index) => (
                  <ListItem key={item}>
                    <Checkbox
                      
                      // overlay={true}
                      // disableIcon
                      // variant="soft"
                      // label={item}
                    />
                    <Chip label={item}/>
                  </ListItem>
                ))}
          </List> */}
            {/*  */}
            <AddUserAuthorities
              selectedAuthorities={(sel: any) =>
                setUser({ ...user, selectedAuthorities: sel })
              }
            />

            <Box
              style={{
                marginTop: 21,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <MOButton
                title={!!selectedUser?.id ? "Update User" : "Add User"}
                disabled={
                  error.email ||
                  error.firstName ||
                  error.lastName ||
                  error.selectedAuthorities
                }
                onClick={async () => {
                  let hasError = 0;
                  let getheringError: any = {};
                  for (let key in user) {
                    if (user[key] !== "") {
                      getheringError[key] = false;
                    } else {
                      getheringError[key] = true;
                      hasError = 1;
                    }
                  }
                  setError(getheringError);
                  if (!hasError) {
                    user.selectedAuthorities = user.selectedAuthorities.map(
                      (item: any) => {
                        return item.id;
                      }
                    );
                    if (!!selectedUser?.id) {
                      delete user.block;
                      delete user.tableData;
                      delete user.userAuthorities;
                      delete user.email;
                      await updateClientUser(user);
                    } else {
                      await postUser(user);
                    }
                    getAllClientUsers("");
                    handleClose();
                    setUser({});
                  } else {
                    alert("Please fill all the required fields.");
                  }
                }}
              />
            </Box>
          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button
            style={{
              borderRadius: 8,
              backgroundColor: "#2AABD5",
              color: "#FFFFFF",
              height: 40,
            }}
            disabled={
              error.email ||
              error.firstName ||
              error.lastName ||
              error.selectedAuthorities
            }
            onClick={async () => {
              let hasError = 0;
              let getheringError: any = {};
              for (let key in user) {
                if (user[key] !== "") {
                  getheringError[key] = false;
                } else {
                  getheringError[key] = true;
                  hasError = 1;
                }
              }
              setError(getheringError);
              if (!hasError) {
                user.selectedAuthorities = user.selectedAuthorities.map(
                  (item: any) => {
                    return item.id;
                  }
                );
                if (!!selectedUser?.id) {
                  delete user.block;
                  delete user.tableData;
                  delete user.userAuthorities;
                  delete user.email;
                  await updateClientUser(user);
                } else {
                  await postUser(user);
                }
                getAllClientUsers();
                handleClose();
                setUser({});
              } else {
                alert("Please fill all the required fields.");
              }
            }}
            variant="contained"
          >
            {!!selectedUser?.id ? "Update" : "Add"}
          </Button>
        </DialogActions> */}
      </Dialog>
      {!!failure ? (
        <ErrorSnack
          reset={() => reset()}
          errorMsg={failure?.message || failure}
        />
      ) : null}
      {!!success ? (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"User Created Successfully"}
        />
      ) : null}
      {!!response?.error && (
        <ErrorSnack errorMsg={response.error} reset={() => resetUpadte()} />
      )}
      {!!response?.success && (
        <ErrorSnack
          reset={() => resetUpadte()}
          success
          errorMsg={"User Updated Successfully"}
        />
      )}
    </div>
  );
};
const mapState = (state: any) => ({
  status: state,
  dialogIsOpen: state.orgUsers.dialog.isOpen,
  selectedUser: state.orgUsers.dialog.selectedUser,
  failure: state.customerUsers.createUser.error,
  success: state.customerUsers.createUser.success,
  response: state.customerUsers.updateCompleteUser,
});
const mapDispatch = {
  openDialog: Local.dialogActions.open,
  closeDialog: Local.dialogActions.close,
  postUser: CustomerUsers.createCustomerUser,
  getAllClientUsers: CustomerUsers.customerUsers,
  updateClientUser: CustomerUsers.updateCustomerUser,
  reset: Local.CreateCustomerUser.reset,
  resetUpadte: Local.UpdateCompleteCustomerUser.reset,
};
export default connect(mapState, mapDispatch)(AddUser);
