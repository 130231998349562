import RichTextEditor from "react-rte"
import { useState } from "react";
export function BodyTextEditor({ value, setValue }) {

    const [editorValue, setEditorValue] =
      useState(RichTextEditor.createValueFromString(value, 'html'));
  
    const handleChange = (value) => {
      setEditorValue(value);
      setValue(value.toString("html"));
    };
  
    return (
      <RichTextEditor
        value={editorValue}
        onChange={handleChange}
        required
        id="body-text"
        name="bodyText"
        type="string"
        multiline
        variant="filled"
        style={{ height: "400px" }}
      />
    );
  }

  