import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { Box } from "@material-ui/core";
import Breadcrumb from "../../../components/BreadCrumb";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import EditIcon from "../../../assets/icons/editUpdateIcon.png";
import DeleteIcon from "../../../assets/icons/menuDelete.png";
import MOSwitch from "../../../components/commons/MOSwitch";
import DeleteCommissionModal from "./Modals/DeleteCommissionModal";
import AddCommissionModal from "./Modals/AddCommissionModal";
import SearchingInput from "../../../components/commons/SearchingInput";

import mobioApi from "../../../services";
import DropDown from "../../../components/commons/DropDown";
import HorizontalIcon from "../../../assets/icons/horizontalIcon.png";
import { connect } from "react-redux";

const SERVICE_LEVEL_ICONS = {
  LUXURY:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5045.png",
  LARGE_PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5050.png",
  EXECUTIVE:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5046.png",
  STANDARD:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5044.png",
  MINI_BUS:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5050.png",
  EXECUTIVE_PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5050.png",
  ELECTRIC:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5047.png",
  CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/image+5050.png",
  ELECTRIC_LUXURY:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/Group+1171275273+(1).png",
  ELECTRIC_PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/Group+1171275273.png",
  ELECTRIC_EXE_PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/Group+1171275273.png",
  ELECTRIC_LARGE_PEOPLE_CARRIER:
    "https://mobio-stage-frontend.s3.eu-west-1.amazonaws.com/Group+1171275273.png",
  EXE_CARRIER: "",
  LARGE_CARRIER: "",
  PEOPLE_CARRIER: "",
};

const Index = ({ token }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [commissionId, setCommissionId] = useState();
  const [anchorEl, setAnchorEl] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);

  const [data, setData] = useState([]);

  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();

  const getCommissionRules = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getAllRateCommissionRule({
        integrationId,
        airportId,
      });
      setData(response?.data?.data);
    } catch (error) {
      setData([]);
    }
  };

  const updateCommissionRuleStatus = async (commissionRule) => {
    const api = mobioApi(token);
    await api.updateRateCommissionRule({
      ...commissionRule,
      apply: !commissionRule.apply,
    });
  };

  useEffect(() => {
    if (addModalOpen == false && deleteModalOpen == false) {
      setCommissionId("");
      getCommissionRules();
    }
  }, [addModalOpen, deleteModalOpen]);

  const paths = [
    {
      name: "Commission Rule",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },
  ];

  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },

    {
      name: "Manage AMS",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },
    {
      name: "Commission Rule",
    },
  ];

  return (
    <>
      <div>
        <Box
          className="mt-18"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Breadcrumb paths={paths} />
            <div className="breadcrumb-description">
              Comission Rule for Services
            </div>
            <div className="mt-8 ml-6">
              <Breadcrumb paths={secondaryPaths} secondary={true} />
            </div>
          </div>
          <div className="flex gap-12">
            <div>
              <SearchingInput />
            </div>
            <div>
              <MOButtonV2
                text="+ ADD COMISSION RULE"
                textColor="#ffffff"
                radius={4}
                backgroundColor="#29AEE6"
                onClick={() => {
                  setModalStatus("add");
                  setAddModalOpen(true);
                }}
                height={30}
                widthSize="168px"
                fontSize={10}
                padding={"8px 12px 8px 12px"}
              />
            </div>
          </div>
        </Box>
        <div className="bg-white main-section-spacing border-radius-8">
          {/* Header */}

          <div className="bg-white border-radius-8">
            <table className="custom-table-v3">
              <thead>
                <tr>
                  <th>Service Level</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Commission</th>
                  <th>Roundable</th>
                  <th>Apply</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <div className="flex align-item-center">
                        <img
                          src={SERVICE_LEVEL_ICONS[row?.serviceLevel]}
                          alt="icon"
                          className="distance-rate-table-icon"
                        />
                        <p
                          className="m-0"
                          style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          {row.serviceLevel.replaceAll("_", " ").toLowerCase()}
                        </p>
                      </div>
                    </td>
                    <td>{row.startTime}</td>
                    <td>{row.endTime}</td>
                    <td>{row.commission}</td>
                    <td>{row.roundable ? "Yes" : "No"}</td>
                    <td>
                      <div className="flex">
                        <MOSwitch
                          isOnStatus={row.apply}
                          id={row.id}
                          setStatus={() => {
                            updateCommissionRuleStatus(row);
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <DropDown
                        dropDownOpen={anchorEl}
                        setDropDownOpen={setAnchorEl}
                        uniqueId={row.id}
                        IconRender={() => <img src={HorizontalIcon} />}
                        dropDownShape="horizontal"
                        menu={[
                          {
                            onClick: () => {
                              setCommissionId(row.id);
                              setModalStatus("edit");
                              setAddModalOpen(true);
                            },
                            IconRender: () => <img src={EditIcon} />,
                            title: "Edit",
                          },
                          {
                            onClick: () => {
                              setCommissionId(row.id);
                              setDeleteModalOpen(true);
                            },
                            IconRender: () => <img src={DeleteIcon} />,
                            title: "Delete",
                          },
                        ]}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* Modals */}
        <AddCommissionModal
          modalOpen={addModalOpen}
          setModalOpen={setAddModalOpen}
          status={modalStatus}
          commissionId={commissionId}
        />
        <DeleteCommissionModal
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          commissionId={commissionId}
        />
      </div>
    </>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Index);
