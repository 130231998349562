import { GetCountries } from "react-country-state-city";

export const getCountryCurrency = async (countryName) => {
  try {
    const result = await GetCountries();
    const getCurrency = result?.filter((item) => item.name === countryName);
    return getCurrency?.length > 0 ? getCurrency[0]?.currency : "NULL";
  } catch (error) {
    console.error("Error fetching countries", error);
    return "NULL";
  }
};
