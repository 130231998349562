import React, { useState } from "react";
import VerticalProgressBar from "../../../../components/commons/VerticalProgressBar";
import HeaderDescription from "../../../../components/commons/HeaderDescription";

const LeadTimeProgress = () => {
  const [progress, setProgress] = useState(100);
  const [showLabels, setShowLabels] = useState(true);
  const labels = [0, 100, 200, 300];
  const maxLabelValue = labels[labels.length - 1];

  const Labels = ({ labels }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginRight: "20px",
          height: "180px",
        }}
      >
        {labels
          .slice()
          .reverse()
          .map((label, index) => (
            <div
              key={index}
              style={{
                textAlign: "right",
                marginBottom: `${index !== labels.length - 1 ? 36 : 0}px`,
              }}
            >
              {label}
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="flex">
      <div>
        <HeaderDescription heading={"Lead Time"} />
      </div>
      <div className="flex ml-16">
        {showLabels && <Labels labels={labels} />}
        <div className="">
          <VerticalProgressBar
            progress={100}
            maxLabelValue={maxLabelValue}
            timeLabel="60"
            timeUnit="Min"
          />
        </div>
        <div className="ml-16">
          <VerticalProgressBar
            progress={150}
            maxLabelValue={maxLabelValue}
            timeLabel="24"
            timeUnit="Hrs."
          />
        </div>
        <div className="ml-16">
          <VerticalProgressBar
            progress={300}
            maxLabelValue={maxLabelValue}
            timeLabel="14"
            timeUnit="Days"
          />
        </div>
      </div>
    </div>
  );
};

export default LeadTimeProgress;
