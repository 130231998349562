import React from "react";
import { Box, LinearProgress } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { Typography, linearProgressClasses } from "@mui/material";
import { Cancel, CheckCircle, RadioButtonUnchecked } from "@material-ui/icons";

const DriverEventsProcess = (props) => {
  const { title, status } = props;
  //   status will be modify after we integrate the backend data
  // for now i will consider only three status "PENDING, SUCCESS,CANCEL"

  const statusColor =
    (status === "PENDING" && "#C1C9D2") ||
    (status === "SUCCESS" && "#54A753") ||
    (status === "CANCEL" && "#F42A41");
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 4,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      // backgroundColor:
      //   theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
      backgroundColor: theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      // backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
      backgroundColor: statusColor,
    },
  }));

  return (
    <Box sx={{ maxWidth: "max-content" }}>
      <Box>
        <BorderLinearProgress variant="determinate" value={100} />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {status === "SUCCESS" && (
          <CheckCircle
            style={{
              width: "16px",
              height: "16px",
              color: statusColor,
              marginRight: "8px",
            }}
          />
        )}
        {status === "PENDING" && (
          <RadioButtonUnchecked
            style={{
              width: "16px",
              height: "16px",
              color: statusColor,
              marginRight: "8px",
            }}
          />
        )}
        {status === "CANCEL" && (
          <Cancel
            style={{
              width: "16px",
              height: "16px",
              color: statusColor,
              marginRight: "8px",
            }}
          />
        )}

        <Typography
          color={statusColor}
          sx={{ paddingRight: "8px", fontSize: "12px", fontWeight: "500" }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default DriverEventsProcess;
