export type adminUserState = {
    dialog: {
      isOpen: Boolean;
      selectedUser: any;
    };
    createSubAdmin: { success: String; error: String };
    deleteSubAdmin: { success: String; error: String };
    updateSubAdmin: { success: String; error: String };
    updateSubAdminPass: { success: String; error: String };
    updateCompleteSubAdmin: { success: String; error: String };
    authorizations: Array<{ id: string; name: number }>;
    authorizationsError: any;
    allSubAdmin: any;
    allSubAdminError: any;
    subAdminAuthorities: any;
    subAdminAuthoritiesError: any;
  };
  
  const subAdminState: adminUserState = {
    dialog: {
      isOpen: false,
      selectedUser: {},
    },
    createSubAdmin: { success: "", error: "" },
    deleteSubAdmin: { success: "", error: "" },
    updateSubAdmin: { success: "", error: "" },
    updateSubAdminPass: { success: "", error: "" },
    updateCompleteSubAdmin: { success: "", error: "" },
    authorizationsError: {},
    authorizations: [],
    allSubAdmin: [],
    allSubAdminError: {},
    subAdminAuthorities: [],
    subAdminAuthoritiesError: {},
  };
  
  export default subAdminState;