import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Add } from "@material-ui/icons";
import Breadcrumb from "../../../components/BreadCrumb";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import DeleteDispatcherModal from "./Modals/DeleteDispatcherModal";
import AddDispatcherModal from "./Modals/AddDispatcherModal";
import DropDown from "../../../components/commons/DropDown";
import HorizontalIcon from "../../../assets/icons/horizontalIcon.png";
import EditIcon from "../../../assets/icons/editUpdateIcon.png";
import DeleteIcon from "../../../assets/icons/menuDelete.png";
import SearchingInput from "../../../components/commons/SearchingInput";
import mobioApi from "../../../services";

const Index = ({ token }) => {
  const [data, setData] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [dispatcherId, setDispatcherId] = useState();
  const [modalStatus, setModalStatus] = useState("add");
  const [anchorEl, setAnchorEl] = useState("");

  const {
    integrationType,
    integrationId,
    country,
    countryId,
    city,
    cityId,
    airportId,
    airportIATA,
  } = useParams();

  const getDispatchers = async () => {
    try {
      const api = mobioApi(token);
      const response = await api.getAllRateIntegrationDispatcher({
        integrationId,
        airportId,
      });
      setData(
        response.data.data.map((item, index) => ({ ...item, id: index + 1 }))
      );
    } catch (error) {
      setData([]);
    }
  };

  useEffect(() => {
    if (addModalOpen == false && deleteModalOpen == false) {
      setDispatcherId("");
      getDispatchers();
    }
  }, [addModalOpen, deleteModalOpen]);

  const paths = [
    {
      name: "Dispatchers",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },
  ];

  const secondaryPaths = [
    {
      name: country,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}`,
    },
    {
      name: city,
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/city`,
    },
    {
      name: "Airports",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/city-airport`,
    },

    {
      name: "Manage AMS",
      url: `/dashboard/integration/rates/${integrationType}/${integrationId}/${country}/${countryId}/${city}/${cityId}/airport/${airportIATA}/${airportId}/mange-location`,
    },
    {
      name: "Dispatchers",
    },
  ];

  return (
    <div>
      <div className="mt-18 flex justify-content-between">
        <div>
          <Breadcrumb paths={paths} />
          <div className="breadcrumb-description">
            Dispatchers can be assigned to bookings and use the driver app
          </div>
          <div className="mt-12 ml-6">
            <Breadcrumb paths={secondaryPaths} secondary={true} />
          </div>
        </div>
        <div className="flex">
          <div className="mr-12">
            <SearchingInput />
          </div>
          <MOButtonV2
            text="ADD A DISPATCHER"
            textColor="#ffffff"
            radius={4}
            backgroundColor="#29AEE6"
            height={30}
            fontSize={11}
            padding={"8.5px 44px"}
            IconType={() => <Add style={{ marginRight: 10, fontSize: 16 }} />}
            onClick={() => {
              setDispatcherId("");
              setModalStatus("add");
              setAddModalOpen(true);
            }}
          />
        </div>
      </div>

      <div>
        <div className="bg-white  mt-20 border-radius-8">
          {/* table */}

          <div className="">
            <table className="custom-table-v3">
              <thead>
                <tr>
                  <th className="">Dispatcher</th>
                  <th className="">Mobile Number</th>
                  <th className="">Locations</th>
                  <th className="">Action</th>
                </tr>
              </thead>
              <tbody className="dispatcher-list-tbod">
                {data &&
                  data.map((row, index) => (
                    <tr key={index}>
                      <td className="dispatcher-table-img">
                        <img src={row.imageUrl} />
                        {row.fullName}
                      </td>
                      <td>{row.phoneNumber}</td>
                      <td className="rm-service-leave-section">
                        <div className="flex align-item-center">
                          <p>{row.airports.length}</p>
                          <div className="flex align-item-center gap-8">
                            {row.airports?.map((a) => {
                              return (
                                <div className="rm-service-leave-badge">
                                  {a.name}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </td>
                      <td>
                        <DropDown
                          dropDownOpen={anchorEl}
                          setDropDownOpen={setAnchorEl}
                          uniqueId={row.id}
                          IconRender={() => <img src={HorizontalIcon} />}
                          dropDownShape="horizontal"
                          menu={[
                            {
                              onClick: () => {
                                setDispatcherId(row.dispatcherId);
                                setModalStatus("edit");
                                setAddModalOpen(true);
                              },
                              IconRender: () => <img src={EditIcon} />,
                              title: "Edit",
                            },
                            {
                              onClick: () => {
                                setDispatcherId(row.dispatcherId);
                                setDeleteModalOpen(true);
                              },
                              IconRender: () => <img src={DeleteIcon} />,
                              title: "Delete",
                            },
                          ]}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Modals */}
      <AddDispatcherModal
        modalOpen={addModalOpen}
        setModalOpen={setAddModalOpen}
        status={modalStatus}
        dispatcherId={dispatcherId}
      />
      <DeleteDispatcherModal
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        dispatcherId={dispatcherId}
      />
    </div>
  );
};

const mapState = (state) => ({
  token: state.userInfo.token,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Index);
