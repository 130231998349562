import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FileUploader from "../../../components/commons/FileUploader";
import ThunksUsers from "../../../redux/actions/thunks/users";

const useStyles = makeStyles((theme) => ({
  mainCard: {
    display: "flex",
    alignContent: "center",
    minWidth: 275,
  },
  root: {
    padding: 20,
  },

  title: {
    marginLeft: 80,
    marginRight: 80,
  },
  input: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  innerInput: {
    paddingTop: 48,
  },
  button: {
    backgroundColor: theme.palette.error.main,
    color: "#fff",
  },
}));

const OrganizationSettings = ({ user, token, updateUser, getLatestImage }) => {
  const classes = useStyles();
  const [load, setLoad] = useState(false);
  const [file, setFile] = useState(null);
  const [logo, setLogo] = useState(user?.imageUrl);

  const uploadLogo = async () => {
    if (user?.id) {
      const data = new FormData();
      setLoad(true);
      data.append('imageUrl', file);
      updateUser(user?.id, data).then((res) => {
        if (res?.data?.imageUrl) {
          setLogo(res?.data?.imageUrl);
        } else {
          setLogo(null);
        }
        setLoad(false);
      }).catch((error) => {
        setLoad(false);
        alert('error')
      });
    }
  };

  const removeLogo = async () => {
    if (user?.id && logo) {
      setFile('');
      await uploadLogo();
      window.location.reload();
    }
  }

  const getLogo = (files) => {
    if (files) {
      setFile(files[0]);
      return files[0];
    }
  }

  useEffect(() => {
    if (user?.imageUrl) {
      setLogo(user?.imageUrl);
    }
  }, [user]);
  useEffect(() => {
    getLatestImage();
  }, []);
  return (
    <>
      <div style={{ display: "flex", direction: "row" }}>
        <div>
          <Toolbar className={classes.toolbar} style={{ marginLeft: "-102px" }}>
            <Typography
              component="h1"
              variant="h6"
              color="#4B4B4B"
              noWrap
              className={classes.title}
            >
              Organization settings
            </Typography>
          </Toolbar>
          <Grid
            container
            alignItems="center"
            direction="row"
            className={classes.mainCard}
          >
            <Card className={classes.root}>
              {load ? (
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <CircularProgress />
                  </div>
                </CardContent>
              ) : (
                <CardContent style={{ width: "100%" }}>
                  <div className={classes.input}>
                    <Typography
                      component="h1"
                      variant="h6"
                      color="#4B4B4B"
                      noWrap
                      className={classes.title}
                    >
                      Your organization's logo
                    </Typography>
                    <FileUploader
                      accept={'.png, .jpg, .jpeg'}
                      label={'Upload logo here'}
                      callback={getLogo}
                      hasFiles={[logo]}
                    />
                  </div>
                </CardContent>
              )}

              <Grid container justifyContent="center" alignItems="center">
                {!load && (
                  <CardActions>
                    <Button
                      size="large"
                      color="primary"
                      variant="contained"
                      disabled={!file}
                      onClick={uploadLogo}
                    >
                      Upload
                    </Button>
                    {/* <Button
                      size="large"
                      color="danger"
                      variant="contained"
                      disabled={!logo}
                      onClick={removeLogo}
                    >
                      Remove
                    </Button> */}
                  </CardActions>
                )}
              </Grid>
            </Card>
          </Grid>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  user: state.userInfo.user,
  token: state.userInfo.token,
});

const mapDispatch = {
  updateUser: ThunksUsers.updateUser,
  getLatestImage: ThunksUsers.getLatestImage,
};

export default connect(mapState, mapDispatch)(OrganizationSettings);
