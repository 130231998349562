import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { DropPointIcon, PickPointIcon } from "../../../assets/icons";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#AAAAAA",
    marginBottom: "15px",
  },
  eventTitle: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "1.1",
  },
  eventLocationTitle: {
    fontSize: "10px",
    fontWeight: "400",
    color: "#1C1C1C",
    lineHeight: "1.1",
  },
}));
const driverEventDisplay = [
  { name: "Driver Departed", title: "DRIVER_DEPARTED_TO_PICKUP" },
  { name: "Driver Arrived", title: "DRIVER_ARRIVED_AT_PICKUP" },
  { name: "Passenger In Car", title: "DRIVER_DEPARTED_TO_DROPOFF" },
  { name: "Passenger Dropped Off", title: "DRIVER_ARRIVED_AT_DROPOFF" },
  { name: "No Show", title: "DRIVER_SUBMITTED_CUSTOMER_NO_SHOW" },
];
const TripTracking = (props) => {
  const { events } = props;
  const classes = useStyles();

  return (
    <Box sx={{ minWidth: "200px", paddingLeft: "22px" }}>
      <Typography className={classes.mainTitle}>Trip Tracking:</Typography>
      {driverEventDisplay?.map((data, index) => {
        return (
          <>
            {events
              ?.filter((ef) => ef.eventName === data.title)
              .map((eventData) => {
                return (
                  <>
                    <Box key={index} sx={{ position: "relative" }}>
                      <Box sx={{ display: "flex", marginBottom: "20px" }}>
                        {index === 0 ? <PickPointIcon /> : <DropPointIcon />}

                        <Box sx={{ marginLeft: "10px" }}>
                          <Typography
                            className={classes.eventTitle}
                            style={{ color: "#BDBDBD" }}
                          >
                            {data.name}
                          </Typography>
                          <Typography className={classes.eventTitle}>
                            {moment(eventData.createdAt).format(
                              "DD MMM YYYY HH:mm:ss"
                            )}
                          </Typography>
                          <Typography className={classes.eventLocationTitle}>
                            {eventData.lat}, {eventData.long}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          borderLeft: "2px solid #000000",
                          height: "26px",
                          position: "absolute",
                          top: "28px",
                          left: "12px",
                        }}
                      />
                    </Box>
                  </>
                );
              })}
          </>
        );
      })}
      {/* {events.map((data, index) => {
        return (
          <>
            <Box key={index} sx={{ position: "relative" }}>
              <Box sx={{ display: "flex", marginBottom: "20px" }}>
                {index === 0 ? <PickPointIcon /> : <DropPointIcon />}

                <Box sx={{ marginLeft: "10px" }}>
                  <Typography
                    className={classes.eventTitle}
                    style={{ color: "#BDBDBD" }}
                  >
                    {data.eventName}
                  </Typography>
                  <Typography className={classes.eventTitle}>
                    {moment(data.createdAt).format('DD MMM YYYY HH:mm:ss')}
                  </Typography>
                  <Typography className={classes.eventLocationTitle}>
                   
                    {data.lat}, {data.long}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  borderLeft: "2px solid #000000",
                  height: "26px",
                  position: "absolute",
                  top: "28px",
                  left: "12px",
                }}
              />
            </Box>
          </>
        );
      })} */}
    </Box>
  );
};

export default TripTracking;
