import React from "react";
import Listing from "./Listing";

function DispatchersListing() {
  return (
    <div>
      <Listing />
    </div>
  );
}

export default DispatchersListing;
