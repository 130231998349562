import React from "react";

const GeographicalAnalysis = () => {
  return (
    <div>
      <h1>GeographicalAnalysis</h1>
    </div>
  );
};

export default GeographicalAnalysis;
