import * as Yup from "yup";

export const airportSelectionValidationSchema = Yup.object({
  airportDetail: Yup.object({}).required("At least one airport is required "),
});

export const addLocationValidationSchema = Yup.object({
  centralZone: Yup.number().required("Central Zone is required"),

  centralZoneLongitude: Yup.number()
    .required("Longitude is required")
    .min(-180, "Longitude must be between -180 and 180")
    .max(180, "Longitude must be between -180 and 180"),

  centralZoneLatitude: Yup.number()
    .required("Latitude is required")
    .min(-90, "Latitude must be between -90 and 90")
    .max(90, "Latitude must be between -90 and 90"),

  leadTime: Yup.string().required("Lead Time is required"),

  supportNumber: Yup.string()
    .required("Support Number is required")
    .matches(
      /^[+]?[0-9]{1,3}?[-.s]?[(]?[0-9]{1,4}[)]?[-.s]?[0-9]{1,4}[-.s]?[0-9]{1,9}$/,
      "Invalid phone number format"
    ),
});

export const areaOfOperationsValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  IATA: Yup.string()
    .length(3, "IATA code must be exactly 3 characters")
    .required("IATA code is required"),
  centralZone: Yup.number()
    .min(1, "Central Zone must be at least 1")
    .required("Central Zone is required"),
  longitude: Yup.number()
    .min(-180, "Longitude must be between -180 and 180")
    .max(180, "Longitude must be between -180 and 180")
    .required("Longitude is required"),
  latitude: Yup.number()
    .min(-90, "Latitude must be between -90 and 90")
    .max(90, "Latitude must be between -90 and 90")
    .required("Latitude is required"),
  // leadTime: Yup.date().required("Lead Time is required"),
  // farthestCentralZone: Yup.string().required(
  //   "Farthest Central Zone is required"
  // ),
  farthestCentralZone: Yup.number()
    .required("Farthest Central Zone is required")
    .when("centralZone", (centralZone, schema) => {
      return schema.min(
        centralZone,
        "Farthest Central Zone must be equal to or greater than Central Zone"
      );
    }), // Validation for farthestCentralZone
  VAT: Yup.string().required("VAT is required"),
});

export const distanceRateValidationSchema = Yup.object({
  initialRate: Yup.number()
    .required("Initial Rate is required")
    .positive("Initial Rate must be positive"),

  initialDistance: Yup.number()
    .required("Initial Distance is required")
    .positive("Initial Distance must be positive"),

  pricePerKM: Yup.number()
    .required("Rate per KM is required")
    .positive("Rate per KM must be positive"),

  pricePerMint: Yup.number()
    .required("Rate per Minute is required")
    .positive("Rate per Minute must be positive"),

  // waitPrice: Yup.number()
  //   .required("Wait Rate is required")
  //   .positive("Wait Rate must be positive"),
});

export const hourlyRateValidationSchema = Yup.object({
  minBookingHour: Yup.date()
    .required("Minimum Booking Hour is required")
    .nullable(),
  rate: Yup.number()
    .required("Rate is required")
    .positive("Rate must be positive"),
});

export const pickupInstructionsValidationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters long"),

  startTime: Yup.string().required("Start Time is required"),

  endTime: Yup.string().required("End Time is required"),

  // selectDate: Yup.string().required("Select Date is required"),
  selectDate: Yup.mixed()
    .required("Select Date is required")
    .test(
      "not-empty",
      "Select Date cannot be empty",
      (value) => value !== null && value !== undefined && value !== ""
    ),

  instruction: Yup.string().max(
    500,
    "Instruction must be 500 characters or less"
  ), // Optional field but with a limit

  // file: Yup.mixed()
  //   .nullable() // Assuming file can be null
  //   .required("File is required"),

  internationalArrival: Yup.string().required(
    "International Arrival is required"
  ),

  domesticArrival: Yup.string().required("Domestic Arrival is required"),

  pickupType: Yup.string().required("Pickup Type is required"),

  meetingPoints: Yup.array().of(
    Yup.object().shape({
      terminal: Yup.string().required("Terminal is required"),
      location: Yup.string().required("Location is required"),
    })
  ),
});

export const serviceLevelValidationSchema = Yup.object({
  serviceLevelId: Yup.string().required("Service Level ID is required"),

  leadTime: Yup.string().required("Lead Time is required"),

  passenger: Yup.string().required("Passenger is required"),

  suitcases: Yup.string().required("Suitcases is required"),

  idleTime: Yup.date().required("Idle Time is required"),

  cancellationTime: Yup.date().required("Cancellation Time is required"),
});

export const campaignValidationSchema = Yup.object({
  differenceType: Yup.string().required("Type is required"),

  differencePercentage: Yup.number()
    .required("Percentage is required")
    .min(0, "Difference Percentage must be at least 0")
    .max(100, "Difference Percentage must be at most 100"),

  serviceLevelId: Yup.string().required("Service Level ID is required"),

  startTime: Yup.string().required("Start Time is required"),

  endTime: Yup.string().required("End Time is required"),

  startDate: Yup.date().required("Start Date is required"),

  endDate: Yup.date().required("End Date is required"),

  campaignName: Yup.string().required("Campaign Name is required"),
});

export const contactInformationValidationSchema = Yup.object({
  addressLineOne: Yup.string().required("Address Line One is required"),
  city: Yup.string().required("City is required"),
  country: Yup.string().required("Country is required"),
  zipCode: Yup.string()
    .required("Zip Code is required")
    .matches(/^[0-9]{5}$/, "Zip Code must be exactly 5 digits"),

  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  landline: Yup.string().required("Landline must be exactly 10 digits"),
  mobile: Yup.string().required("Mobile is required"),

  email: Yup.string()
    .required("Email is required")
    .email("Email must be a valid email address"),

  genericEmail: Yup.string().email(
    "Generic Email must be a valid email address"
  ),

  supportNumber: Yup.string().required(
    "Support Number must be exactly 10 digits"
  ),
});

export const autoDriverValidationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  contact: Yup.string().required("Contact is required"),
  ride: Yup.number()
    .required("Ride duration is required")
    .positive("Ride duration must be a positive number"),
  rideLng: Yup.string().required("Ride Longitude is required"),
  rideLat: Yup.string().required("Ride Latitude is required"),
  arrived: Yup.number()
    .required("Arrived duration is required")
    .positive("Arrived duration must be a positive number"),
  arrivedLng: Yup.string().required("Arrived Longitude is required"),
  arrivedLat: Yup.string().required("Arrived Latitude is required"),
  noShow: Yup.string().required("No Show duration is required"),
  noShowLng: Yup.string().required("No Show Longitude is required"),
  noShowLat: Yup.string().required("No Show Latitude is required"),
});

export const commissionValidationSchema = Yup.object().shape({
  startTime: Yup.string().required("Start time is required"),
  endTime: Yup.string().required("End time is required"),
  serviceLevel: Yup.string()
    .oneOf(
      [
        "STANDARD",
        "CARRIER",
        "EXECUTIVE",
        "EXE_CARRIER",
        "ELECTRIC_LARGE_PEOPLE_CARRIER",
        "LUXURY",
        "ELECTRIC_EXE_PEOPLE_CARRIER",
        "ELECTRIC_PEOPLE_CARRIER",
        "ELECTRIC_LUXURY",
        "LARGE_CARRIER",
        "MINI_BUS",
        "ELECTRIC",
      ],
      "Please select a valid service level"
    )
    .required("Service level is required"),
  commission: Yup.number()
    .typeError("Commission must be a number")
    .required("Commission is required"),
  roundable: Yup.boolean().required("Roundable is required"),
});

export const servicelevelValidationSchema = Yup.object().shape({
  serviceLevelsFrom: Yup.array().of(
    Yup.object().shape({
      checked: Yup.boolean(),
      rate: Yup.number()
        .test(
          "rate-required",
          "Rate must be greater than 0",
          function (value) {
            const { checked } = this.parent;
            return !checked || (checked && value > 0);
          }
        )
        .typeError("Rate must be a number"),
    })
  ),
  serviceLevelsTo: Yup.array().of(
    Yup.object().shape({
      checked: Yup.boolean(),
      rate: Yup.number()
        .test(
          "rate-required",
          "Rate must be greater than 0",
          function (value) {
            const { checked } = this.parent;
            return !checked || (checked && value > 0);
          }
        )
        .typeError("Rate must be a number"),
    })
  ),
});
