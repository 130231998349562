import { useNavigate } from "react-router";
import ArrowIcon from "../../assets/icons/maki_arrow.svg";

const ThirdPartyCard = ({ data, connectionUrl }) => {
  const navigate = useNavigate();
  return (
    <div className="thirdPartyConnection-main" key={data?.id}>
      {/* header section */}
      <div className="tpc-header-section">
        <div className="tpc-header-icon">
          <img
            src={data.circleIcon}
            alt="circle image of the third party integration"
          />
        </div>
      </div>
      {/* body section */}
      <div className="tpc-body-main flex justify-content-center align-item-center">
        <img src={data.mainImage} alt="integration" />
      </div>
      {/* footer section */}
      <div className="tpc-footer-main flex justify-content-end align-item-center">
        <div
          className=" flex  align-item-center cursor-pointer"
          onClick={() => {
            navigate(connectionUrl);
          }}
        >
          <p className="font-13 font-500 text-primary ">Proceed</p>
          <img src={ArrowIcon} className="ml-10" alt="ArrowIcon" />
        </div>
      </div>
    </div>
  );
};

export default ThirdPartyCard;
