import { Grid } from "@material-ui/core";
import { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircle from "@material-ui/icons/CheckCircle";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import Image from "react-image-enlarger";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { showGoogleMap } from "../../../../helpers";
import { floor } from "lodash";
import { Button } from '@material-ui/core';
import * as XLSX from 'xlsx';
import CircularProgress from "@material-ui/core/CircularProgress";
// import { saveAs } from 'file-saver';
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import moment from "moment";
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

import Union from "../../../../assets/icons/Union.png";
import Destination from "../../../../assets/icons/Destination.png";
import Envelop from "../../../../assets/icons/Envelop.png";
import person from "../../../../assets/icons/person.png";
import { cartypes } from "../VehicleTypes";
import UpdateBooking from "../UpdateBooking";

import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../../../components/commons/ButtonGrouping";
import CreateBookings from "../CreateBooking";
import { useEffect } from "react";
import ThunksBooking from "../../../../redux/actions/thunks/bookings";
import ThunkSpreadSheet from "../../../../redux/actions/thunks/spread-sheet";
import ThunksUsers from "../../../../redux/actions/thunks/users";
import ThunksCustomers from "../../../../redux/actions/thunks/customer";
import ErrorSnack from "../../../../components/commons/ErrorSnack";
import vehicles from "../../../../assets/VehicleSvgs";
import { DifCars, EventTextDetails } from "../../../../enum";
import DisplayName from "../DisplayName";
import DetailViewDots from "./DetailDot";
import * as momentTimeZone from 'moment-timezone';
import BookingCard from "../../../../components/commons/BookingCard/BookingCard";
import UpdateBookingModal from "../UpdateBookingModal";
import DuplicateBookingModal from "../DuplicateBookingModal";
import CancelBooking from "../CancelBooking";
import Box from '@material-ui/core/Box';
import { useLocation, useNavigate  } from 'react-router-dom';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    borderBottom: '1px solid #4b4b4b',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
    position: "relative",
    paddingTop: "28px",
  },
  listSection: {
    backgroundColor: "inherit",
    padding: 0,
  },
  ul: {
    backgroundColor: "inherit",
  },
  root_Tab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  //accordian
  root_accordian: {
    width: "100%",
    backgroundColor: "red",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  cancelBooking: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    opacity: "0.4",
    background: "red"
  }
}));

function BookingList({
  getBookings,
  data,
  loading,
  error,
  role,
  GetDriverEventsBybooking,
  GetBookingdriver,
  getDisplayNameByClientId,
  getDisplayNameByUserId
}) {
  const classes = useStyles();
  const [type, setType] = useState("planning");
  const [expand, setExpand] = useState("");
  const [events, setEvents] = useState([]);
  const [driver, setDriver] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openEditBookingModal, setOpenEditBookingModal] = useState(false)
  const [duplicateOpenModal, setDuplicateOpenModal] = useState(false);
  const [singleBooking, setSingleBooking] = useState("")
  const [anchorEl, setAnchorEl] = useState(null);
  const [cancelBookingModal, setCancelBookingModal] = useState(false);
  const [loadingProcess,setLoadingProcess]=useState(false)

  
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(null);


  const handleEyeButton = async (pickup, dest, driverId) => {
    const originalLat = pickup.coordinates[0];
    const originalLng = pickup.coordinates[1];
    const destinationLat = dest.coordinates[0];
    const destinationLng = dest.coordinates[1];
    const origin = encodeURIComponent(originalLat + "," + originalLng);
    const destination = encodeURIComponent(destinationLat + "," + destinationLng);
    const { lat, lng } = await getDisplayNameByUserId(driverId);
    const waypoints = encodeURIComponent(lat + "," + lng);
    const link = `https://www.google.com/maps/dir/?api=1&origin=${origin}&waypoints=${waypoints}&destination=${destination}`;
    window.open(link, "_blank");
  }

  const showGooglePlace = (lat, lng) => {
    const origin = encodeURIComponent(lat + "," + lng);
    const link = `https://www.google.com/maps/place/${origin}`;
    window.open(link, "_blank");
  }

  const toggleAcordion = (newId) => {
    setExpand((id) => (id == newId ? "" : newId));
  };

  const toggleHandleEvent = async (data) => {
    setEvents([])
    setDriver(null)
    if (data.driverId) {
      await getDriverInfo(data.driverId)
    }
    await getEvents(data.id)
    toggleAcordion(data.id)
  }

  useEffect(async () => {
    getBookings(type, page, limit, searchText);
  }, [searchText]);

  useEffect(() => {
    getBookings(type, page, limit, searchText);
  }, [type]);

  useEffect(() => {
    console.log("Loading calling ",loading)
    loading && getBookings(type, page, limit, searchText);
  }, [loading]);

  useEffect(() => {
    countTotalPage()
  }, [data.length])

  const locationQuery = useLocation();
  
  useEffect(() => {
    const queryParams = new URLSearchParams(locationQuery.search);
    const searchingQuery = queryParams.get('search');
    if(searchingQuery){
      setSearchText(searchingQuery)
    }else{
      setSearchText("")
    }
    
  }, [locationQuery]);

  const getDriverInfo = async (id) => {
    console.log("result")

    const result = await GetBookingdriver(id)
    console.log("result", result)
    setDriver(result);
  }

  const getEvents = async (id) => {
    const result = await GetDriverEventsBybooking(id);
    setEvents(result);
  }

  function SingleSource({ src, title }) {
    const [zoomed, setZoomed] = useState(false);

    return (
      <div style={{ marginRight: 16 }}>
        <span>{title}</span>
        <Image
          style={{ width: "150px", height: "150px" }}
          // zoomed={zoomed}
          src={src}
        // onClick={() => saveAs(src, 'image.jpg')}
        // onRequestClose={() => setZoomed(false)}
        />
      </div>
    );
  }
  const transformExcelData = async (data) => {
    const epoch = moment().unix();
    const genId = `EX${epoch}`;
    return Promise.all(data.map(async (item) => {
      if (!item[1] || !item[5] || !item[6] || !item[7] || !item[8]) {
        console.log('Validation Error: Missing required Booking fields.');
        return;
      }
      const formattedDate = new Date((item[1] - 25569) * 86400 * 1000)
        .toISOString()
        .replace('T', ' ')
        .substring(0, 19);
      return {
        bookingReference: item[0],
        dateTime: formattedDate,
        noOfPeople: item[5].toString(),
        carType: item[6],
        notes: item[9],
        paymentType: item[10],
        pickup: item[7].trim(),
        destination: item[8].trim(),
        guestPhoneNumber: item[4],
        guestName: item[3],
        flightNumber: item[2],
        refId: genId,
      };
    }));
  };
  const returnImage = (type) => {
    switch (type) {
      case "CASH":
        return <LocalAtmIcon />;
      case "CARD":
        return <CreditCardIcon />;
      case "ACCOUNT":
        return <AccountBalanceWalletOutlinedIcon />;
    }
  };

  // handle edit booking
  const handleEditClickOpen = () => {
    setOpenEditBookingModal(true);
  };
  const handleEditClose = () => {
    setSingleBooking("")
    setOpenEditBookingModal(false);
  };
  // handle duplicate booking
  const handleDuplicateClickOpen = () => {
    setDuplicateOpenModal(true)
  }
  const handleDuplicateClose = () => {
    setSingleBooking("")
    setDuplicateOpenModal(false)
  }

  // handle cancel booking
  const handleCancelBookingOpen = () => {
    setCancelBookingModal(true);
  };

  const handleCancelBookingClose = () => {
    setCancelBookingModal(false);
  };
  const onpageChange = async (selectedPage) => {
    await getBookings(type, selectedPage, limit, searchText);
  };

  const countTotalPage = () => {
    const finalCount = data[0]?.count || 0
    if (parseInt(finalCount) % parseInt(limit) !== 0) {
      setTotal(floor(parseInt(finalCount) / parseInt(limit)) + 1);
    } else {
      setTotal(parseInt(finalCount) / parseInt(limit));
    }
  };
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledToggleButtonGroup
          value={type}
          exclusive
          onChange={(e, value) => { value != null && setType(value); setPage(1) }}
        >
          <StyledToggleButton disabled={loading} key={0} value={"planning"}>
            Planning
          </StyledToggleButton>
          <StyledToggleButton disabled={loading} key={0} value={"assigned"}>
            In Progress
          </StyledToggleButton>
          <StyledToggleButton disabled={loading} key={2} value={"history"}>
            History
          </StyledToggleButton>
        </StyledToggleButtonGroup>

        <div style={{ display: "flex" }}>
          {/* <div style={{ marginRight: 20 }}>
            <Search >
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase

                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </Search>
          </div> */}
          <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>
            <CreateBookings role={role} />
          </div>
        </div>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {!!total && (
          <div>
            <StyledToggleButtonGroup
              style={{ background: "white" }}
              value={page}
              exclusive
            >
              {page > 1 && (
                <StyledToggleButton
                  onClick={async () => {
                    setPage(page - 1);
                    await onpageChange(page - 1);
                  }}
                  style={{ marginRight: 10 }}
                >
                  <Typography
                    style={{ padding: "10 3 10 3" }}
                    component="h3"
                    align="center"
                  >
                    {" "}
                    Previous{" "}
                  </Typography>
                </StyledToggleButton>
              )}
              {page !== 1 && (
                <StyledToggleButton
                  onClick={async () => {
                    setPage(page - 1);
                    await onpageChange(page - 1);
                  }}
                  style={{ marginRight: 10 }}
                >
                  <Typography
                    style={{ padding: "10 3 10 3" }}
                    component="h3"
                    align="center"
                  >
                    {" "}
                    {page - 1}
                  </Typography>
                </StyledToggleButton>
              )}
              <StyledToggleButton
                style={{ background: "white", marginRight: 10 }}
              >
                <Typography
                  style={{ padding: "10 3 10 3", color: "#2aabd5" }}
                  component="h3"
                  align="center"
                >
                  {page}
                </Typography>
              </StyledToggleButton>

              {total > page && (
                <StyledToggleButton
                  style={{ marginRight: 10 }}
                  onClick={async () => {
                    setPage(page + 1);
                    await onpageChange(page + 1);
                  }}
                >
                  <Typography
                    style={{ padding: "10 3 10 3" }}
                    component="h3"
                    align="center"
                  >
                    {page + 1}
                  </Typography>
                </StyledToggleButton>
              )}
              {total > page && (
                <StyledToggleButton
                  style={{ marginRight: 10 }}
                  onClick={async () => {
                    setPage(page + 1);
                    await onpageChange(page + 1);
                  }}
                >
                  <Typography
                    style={{ padding: "10 3 10 3" }}
                    component="h3"
                    align="center"
                  >
                    Next
                  </Typography>
                </StyledToggleButton>
              )}
            </StyledToggleButtonGroup>
            {/* <div style={{ float: "right" }}>
              <ListSubheader style={{ marginTop: "-4px" }}>
                <span style={{ fontWeight: "bold" }}>Total Count: </span>
                <span>{12}</span>
              </ListSubheader>
            </div> */}
          </div>
        )}
      </Grid>
      
<List className={classes.root} subheader={<li />}>
        {data?.map((item, index) => {
          return (
            <li key={index}>
              {item?.data?.map((i, index) => {
                return <BookingCard
                  bookingDate={item.date}
                  data={i}
                  type={type}
                  key={index}
                  toggleHandleEvent={() => { toggleHandleEvent(i) }}
                  expand={expand}
                  driver={driver}
                  events={events}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  setSingleBooking={setSingleBooking}
                  handleEditModal={setOpenEditBookingModal}
                  handleDuplicateModal={setDuplicateOpenModal}
                  handleCancelModal={handleCancelBookingOpen}
                />
              })}
            </li>
          )
        })}
      </List>

      <List className={classes.root} subheader={<li />}>


        {loading ?
        <Box style={{width:"100%",textAlign:"center"}}>
          <CircularProgress style={{color:"#29AEE6"}}/>
        </Box>
         :<>
          {data.length == 0 && (
          <Typography style={{ marginTop: 30 }} component="h3" align="center">
            {type && type.toUpperCase()} BOOKINGS ARE EMPTY
          </Typography>
        )}
        </>}
        {/* {data.length == 0 && (
          <Typography style={{ marginTop: 30 }} component="h3" align="center">
            {type && type.toUpperCase()} BOOKINGS ARE EMPTY
          </Typography>
        )} */}
        {false && data?.map((item, index) => (
          <li key={index} className={classes.listSection}>
            <ul className={classes.ul}>
              <ListSubheader>
                <span>{item.date}</span>
              </ListSubheader>
              {item?.data?.map((i, index) => (
                <ListItem key={index}>
                  <div style={{ position: "relative" }}>
                    <div className={classes.root} style={{ maxWidth: "1000px", width: "full" }}>
                      <Accordion
                        expanded={i.id == expand ? true : false}
                        TransitionProps={{ unmountOnExit: true }}
                        style={i.rideEnd == false && i.cancelled == true ? { background: "rgb(255,0,0,0.4)" } : { background: "" }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid
                            container
                            direction="row"
                            style={{ marginTop: "20px", marginLeft: "10px" }}
                          >
                            <Typography className={classes.heading} style={{ maxWidth: 251, paddingRight: 2 }}>
                              {
                                type !== "planning" && <DetailViewDots type={type} data={i} />
                              }
                            </Typography>

                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ marginLeft: 15, marginRight: 18, marginTop: 10 }}
                          >
                            <Typography
                              style={{ fontWeight: 600 }}
                            >
                              {moment(i?.flightTime || i?.dateTime).format("HH:mm")}
                            </Typography>
                            <Typography
                              style={{ fontWeight: 600, }}
                            >
                              {i?.bookingReference ? i?.bookingReference : i.refId}
                            </Typography>
                          </Grid>
                          {/* <AccordionSummary
                            aria-controls="panel1a-content"
                            IconButtonProps={{
                              onClick: async () => {
                                setEvents([])
                                setDriver(null)
                                await getDriverInfo(i.driverId)
                                console.log(driver)
                                await getEvents(i.id)
                                toggleAcordion(i.id)
                              }
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            style={{ margin: 0, minWidth: '100%', marginRight: 107 }}
                          >
                            <Grid xs={12} md={10} lg={10}>
                            {i?.bookingReference ? i?.bookingReference : i.refId}
                          </Typography> */}
                          {/* </Grid> */}
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            IconButtonProps={{
                              onClick: async () => {
                                setEvents([])
                                setDriver(null)
                                if (i.driverId) {
                                  await getDriverInfo(i.driverId)
                                }
                                console.log(driver)
                                await getEvents(i.id)
                                toggleAcordion(i.id)
                              }
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            style={{ margin: 0, minWidth: '100%', marginRight: 107 }}
                          >
                            <Grid xs={12} md={10} lg={10}>

                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid
                                  container
                                  direction="row"
                                >
                                  <Typography className={classes.heading} style={{ maxWidth: 251, paddingRight: 2 }} onClick={() => { showGoogleMap(i.pickup.coordinates, i.destination.coordinates) }}>
                                    <img
                                      src={Union}
                                      style={{ marginRight: 10, marginTop: 6 }}
                                    />
                                    <span>{i?.pickup?.name}</span>
                                  </Typography>

                                  <Typography className={classes.heading} style={{ maxWidth: 251, paddingLeft: 2 }} onClick={() => { showGoogleMap(i.pickup.coordinates, i.destination.coordinates) }}>
                                    <img
                                      src={Destination}
                                      style={{ marginRight: 10, marginTop: 6 }}
                                    />
                                    {i?.destination?.name}
                                  </Typography>

                                  <Typography className={classes.heading}>
                                    <Grid
                                      container
                                      direction="row"
                                      alignItems="center"
                                      style={{ marginLeft: 10 }}
                                    >
                                      {/* <div style={{ marginRight: 10 }}>
                                  {returnImage(i.paymentType)}
                                </div> */}
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {/* <p
                                        style={{
                                          marginRight: 30,
                                          marginRight: 10,
                                          marginTop: 0,
                                          marginBottom: 0,
                                        }}
                                      >
                                        CP: €{i?.fare?.toFixed(2) || '0'}
                                      </p> */}
                                        <p
                                          style={{
                                            marginRight: 30,
                                            marginRight: 10,
                                            marginTop: 0,
                                            marginBottom: 0,
                                          }}
                                        >
                                          €{i?.fare?.toFixed(2) || '0'}
                                        </p>
                                      </div>
                                      <img src={person} style={{ marginRight: 10 }} />
                                      <p style={{ marginRight: 30 }}>
                                        {i.noOfPeople}
                                      </p>
                                      {cartypes
                                        .filter((cars) => cars.value == i.carType)
                                        .map((carname) => (
                                          <div style={{ width: DifCars[i?.carType] ? 100 : 0, marginLeft: DifCars[i?.carType] ? -2 : 0 }}>{vehicles(i.carType, "#4b4b4b", '50')}</div>
                                        ))}
                                    </Grid>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                              <div style={{ marginRight: '8px', marginTop: '8px', cursor: 'pointer' }}>
                                {
                                  type == "assigned" && <a onClick={() => handleEyeButton(i.pickup, i.destination, i.driverId)}> <VisibilityIcon /> </a>
                                }
                              </div>
                              {
                                (type == "planning" || type == "assigned") && <UpdateBooking data={i} role={role} />
                              }
                            </Grid>
                          </AccordionSummary>
                        </Grid>
                        <AccordionDetails>
                          <Grid xs={12} md={10} lg={10}>
                            <Typography className={classes.heading}>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                {i.client && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Client:
                                    </h3>
                                    {i.client?.firstName} {i.client?.lastName}
                                  </div>
                                )}

                              </div>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                {i.guestName && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Guest Name:
                                    </h3>
                                    {i.guestName}
                                  </div>
                                )}
                                {i.guestPhoneNumber && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Guest Number:
                                    </h3>
                                    {i.guestPhoneNumber}
                                  </div>
                                )}

                              </div>

                              <div style={{ display: "flex", flexDirection: "row" }}>
                                {i?.dispatcher &&
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Dispatcher Name:
                                    </h3>
                                    {i?.dispatcher?.displayName}
                                  </div>
                                }
                                {i?.driver &&
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Driver Name:
                                    </h3>
                                    {i?.driver?.displayName}
                                  </div>
                                }
                              </div>
                              {type != 'history' &&
                                <div
                                  style={{ display: "flex", flexDirection: "row" }}
                                >
                                  {i?.dateTime && (
                                    <div style={{ width: "50%" }}>
                                      <h3 style={{ margin: 0, fontSize: "15px" }}>
                                        Passenger Local Time:
                                      </h3>
                                      {momentTimeZone(i?.dateTime).tz(i?.timeZone).format("HH:mm")}
                                    </div>
                                  )}
                                  {i?.dateTime && (
                                    <div style={{ width: "50%" }}>
                                      <h3 style={{ margin: 0, fontSize: "15px" }}>
                                        Time Left To Pickup:
                                      </h3>
                                      {moment.utc(i?.dateTime).fromNow()}
                                    </div>
                                  )}
                                </div>
                              }
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "50%" }}>
                                  {i.nameCaller && (
                                    <>
                                      <h3 style={{ margin: 0, fontSize: "15px" }}>
                                        Name Caller:
                                      </h3>
                                      {i.nameCaller}
                                    </>
                                  )}
                                </div>
                                {driver?.car && driver?.car?.category &&

                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Driver Service Level:
                                    </h3>
                                    {driver?.car && driver?.car?.category}
                                  </div>

                                }
                              </div>
                              <div style={{ display: "flex", flexDirection: "row" }}>

                                {i.notes && (
                                  <div style={{ width: "50%" }}>
                                    <h3 style={{ margin: 0, fontSize: "15px" }}>
                                      Notes
                                    </h3>
                                    <span>{i.notes}</span>
                                  </div>
                                )}
                              </div>
                              {(i.cancelledByUser || i.cancelledByClient) && <DisplayName item={i} getDisplayNameByClientId={getDisplayNameByClientId} getDisplayNameByUserId={getDisplayNameByUserId} />}
                              {
                                type == "assigned" && <a onClick={() => handleEyeButton(i.pickup, i.destination, i.driverId)}> <VisibilityIcon /> </a>
                              }
                            </Typography>
                          </Grid>
                        </AccordionDetails>
                        {type == "history" && events.length > 0 &&
                          <>
                            <hr style={{ margin: 15 }} />
                            <AccordionDetails>
                              <div>
                                <div style={{ marginVertical: 10, }}>
                                  <div style={{ display: "flex", flexDirection: "row" }}>
                                    <p style={{ fontSize: 18, fontWeight: "bold", marginRight: 10, marginTop: 4, marginBottom: 0 }}>Driver Tracking Events</p>
                                    <div style={{
                                      borderRadius: 8,
                                      padding: 7, backgroundColor: i.rideEnd ? "green" : "#E4E4E4"
                                    }}>
                                      <p style={{ padding: 0, margin: 0, color: i.rideEnd ? "white" : "#4B4B4B", fontWeight: "bold", }} >
                                        Completed
                                      </p>
                                    </div>

                                  </div>
                                </div>
                                <div>
                                  <>
                                    {events.length > 0 && events.map(event =>
                                      <div style={{ display: "flex", flexDirection: "row", marginTop: 10, marginBottom: 10 }}>
                                        <div style={{ marginTop: 10, marginRight: 10, paddingLeft: 7, display: "flex", flexDirection: "row" }}>
                                          <div style={{ width: 4, marginTop: 16, backgroundColor: "black" }}>
                                          </div>
                                          <div style={{ marginLeft: -11 }}>
                                            <CheckCircle style={{ color: "green", width: 20 }} />
                                          </div>
                                        </div>

                                        <div style={{ display: "flex", flexDirection: "column", cursor: "pointer" }} onClick={() => { showGooglePlace(event.lat, event.long) }}>

                                          <p style={{ fontSize: 16, fontWeight: "bold", padding: 0, marginBottom: 5, marginTop: 8 }}>{EventTextDetails[event.eventName]}</p>

                                          <div style={{ display: "flex", flexDirection: "row" }}>
                                            <p style={{ fontSize: 14, color: "black", padding: 0, margin: 0 }}>{moment(event.createdAt).format('DD MMM YYYY HH:mm:ss')} </p>
                                            <div style={{ width: 5, height: 5, backgroundColor: "black", borderRadius: 8, marginRight: 5, marginLeft: 5, marginTop: 0 }}></div>
                                            <p style={{ fontSize: 14, padding: 0, margin: 0 }}>{event.lat}, {event.long} </p>
                                          </div>

                                        </div>
                                      </div>
                                    )}
                                  </>
                                </div>
                              </div>
                            </AccordionDetails>
                          </>
                        }
                        {i?.cancelled && i?.rideEnd && i?.cancellationReason?.picture?.length > 0 &&
                          <>
                            <hr style={{ margin: 15 }} />
                            <AccordionDetails>
                              <Grid item xs={6}>
                                <h4>No Show</h4>

                                <div style={{ display: "flex" }}>

                                  {i?.cancellationReason?.picture?.length > 0 &&
                                    i?.cancellationReason?.picture?.map(pic =>
                                      <SingleSource src={pic} />

                                    )}
                                </div>
                              </Grid>
                            </AccordionDetails>
                          </>
                        }
                      </Accordion>
                    </div>
                    {
                      i.cancelled && type !== "history" && <div className={classes.cancelBooking}></div>
                    }

                  </div>
                </ListItem>
              ))}
            </ul>
          </li>
        ))}

      </List>
      {error.status && <ErrorSnack errorMsg={error.message} />}
      {/* modals */}
      {singleBooking && (
        <UpdateBookingModal
          role={role}
          bookingState={singleBooking}
          // dispatchers={bookingState.data}
          open={openEditBookingModal}
          onClose={handleEditClose}
        />
      )}
      {singleBooking && (
        <DuplicateBookingModal
          role={role}
          bookingState={singleBooking}
          // dispatchers={bookingState.data}
          open={duplicateOpenModal}
          onClose={handleDuplicateClose}
        />
      )}
      <CancelBooking
        bookingState={singleBooking}
        agree={cancelBookingModal}
        handleClose={handleCancelBookingClose} />
    </>
  );
}

const mapState = (state) => ({
  data: state.bookings.api.response.data,
  loading: state.bookings.api.loading,
  error: state.bookings.api.error,
});

const mapDispatch = {
  GetDriverEventsBybooking: ThunksBooking.GetDriverEventsBybooking,
  GetBookingdriver: ThunksBooking.GetBookingdriver,
  getBookings: ThunksBooking.CustomerBookings,
  getDisplayNameByUserId: ThunksUsers.getDisplayNameByUserId,
  getDisplayNameByClientId: ThunksCustomers.getDisplayNameByClientId,
};

export default connect(mapState, mapDispatch)(BookingList);
