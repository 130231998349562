import {
    DIALOG,
    SUB_ADMIN_API,
    SUB_ADMIN_AUTHORIZATIONS,
    SUB_ADMIN_UPDATE_API,
    SUB_ADMIN_GET_API,
    SUB_ADMIN_DELETE_API,
    USER_UPDATE_API,
    UPDATE_SUB_ADMIN_PASSWORD,
  } from "../../actions/local/constants/subAdmins";
  import subAdminState, { adminUserState } from "../../initial-state/SubAdmin";
  
  const orgUsers = (state: adminUserState = subAdminState, action: any) => {
    const { type, payload } = action;
  
    switch (type) {
      case SUB_ADMIN_API.SET_RESPONSE: {
        return {
          ...state,
          createSubAdmin: { success: "Successfully Created User", error: "" },
        };
      }
      case SUB_ADMIN_API.SET_ERROR: {
        const { message } = payload;
        return {
          ...state,
          createSubAdmin: { success: "", error: message },
        };
      }
      case SUB_ADMIN_API.RESET: {
        return {
          ...state,
          createSubAdmin: { success: "", error: "" },
        };
      }
      case DIALOG.OPEN_DIALOG: {
        return {
          ...state,
          dialog: { isOpen: true },
        };
      }
      case DIALOG.CLOSE_SUBADMIN_DIALOG: {
        return {
          ...state,
          dialog: { isOpen: false },
        };
      }
      case DIALOG.SELECT_USER: {
        const { data } = payload;
        return {
          ...state,
          dialog: { selectedUser: data, isOpen: true },
        };
      }
      case SUB_ADMIN_AUTHORIZATIONS.SET_RESPONSE: {
        const { data } = payload;
        return {
          ...state,
          authorizations: data,
        };
      }
      case SUB_ADMIN_AUTHORIZATIONS.SET_ERROR: {
        const { message } = payload;
        return {
          ...state,
          authorizationsError: message,
        };
      }
      case SUB_ADMIN_GET_API.SET_RESPONSE: {
        const { data } = payload;
  
        return {
          ...state,
          allSubAdmin: data,
        };
      }
      case SUB_ADMIN_GET_API.SET_ERROR: {
        const { message } = payload;
        return {
          ...state,
          allSubAdminError: message,
        };
      }
      case SUB_ADMIN_DELETE_API.SET_RESPONSE: {
        return {
          ...state,
          deleteSubAdmin: { success: "Successfully Deleted User", error: "" },
        };
      }
      case SUB_ADMIN_DELETE_API.SET_ERROR: {
        const { message } = payload;
        return {
          ...state,
          deleteSubAdmin: { success: "", error: message },
        };
      }
      case SUB_ADMIN_DELETE_API.RESET: {
        return {
          ...state,
          deleteSubAdmin: { success: "", error: "" },
        };
      }
      case SUB_ADMIN_UPDATE_API.SET_RESPONSE: {
        return {
          ...state,
          updateCompleteSubAdmin: {
            success: "Successfully Updated User",
            error: "",
          },
        };
      }
      case SUB_ADMIN_UPDATE_API.SET_ERROR: {
        const { message } = payload;
        return {
          ...state,
          updateCompleteSubAdmin: { success: "", error: message },
        };
      }
      case SUB_ADMIN_UPDATE_API.RESET: {
        return {
          ...state,
          updateCompleteSubAdmin: { success: "", error: "" },
        };
      }
      case UPDATE_SUB_ADMIN_PASSWORD.SET_RESPONSE: {
        return {
          ...state,
          updateSubAdminPass: {
            success: "Successfully Updated Password",
            error: "",
          },
        };
      }
      case UPDATE_SUB_ADMIN_PASSWORD.SET_ERROR: {
        const { message } = payload;
        return {
          ...state,
          updateSubAdminPass: { success: "", error: message },
        };
      }
      case UPDATE_SUB_ADMIN_PASSWORD.RESET_ERROR: {
        return {
          ...state,
          updateSubAdminPass: { success: "", error: "" },
        };
      }
      case USER_UPDATE_API.RESET: {
        console.log('this calls')
        return {
          ...state,
          updateCompleteSubAdmin: { success: "", error: "" },
        };
      }
      default:
        return state;
    }
  };
  
  export default orgUsers;