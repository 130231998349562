export function getInitials(fullName) {
  // Split the full name into words
  const words = fullName?.split(" ");

  // Take the first letter of each word (up to two words)
  const initials = words?.slice(0, 2).map((word) => word.charAt(0));

  // Join the initials to form the result
  return initials?.join("");
}
