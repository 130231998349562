import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Input from "../../../components/commons/Input";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ThunksReject } from "../../../redux/actions/thunks/onboarding-verification";
import { connect } from "react-redux";
import {
  RejectOnboarding,
  LocalLocalVehicleAddRepo,
  Field,
} from "../../../redux/actions/local/verifyOnboarding";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import Local from "../../../redux/actions/local";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  disabledButton: {
    backgroundColor: theme.palette.primary || "red",
    height: 100,
  },
});

const useStyles = makeStyles((theme) => ({
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    height: 600,
    marginLeft: 16,
    marginRight: 34,
  },

  button: {
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 8,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
  },
  rejectButton: {
    backgroundColor: theme.palette.error.light,
    marginTop: theme.spacing(1),
  },
  disabledButton: {
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 8,
    backgroundColor: "red",
    color: "#FFFFFF",
    height: 90,
  },
  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    width: 550,
    height: 40,
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CustomizedDialogs({
  rejectOnboarding,
  updateField,
  reason,
  id,
  errorStatus,
  success,
  reset,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setValue("");
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div>
      <Button
        className={classes.rejectButton}
        fullWidth
        onClick={handleClickOpen}
      >
        Reject
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Reason for Rejection
        </DialogTitle>
        <DialogContent dividers>
          <Grid>
            <Input
              autoFocus
              label="Rejection Reason "
              value={reason}
              size="small"
              className={classes.textfield_upper}
              onChange={handleChange}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              textTransform: "none",
              borderRadius: 6,
              backgroundColor: "#2AABD5",
              color: "#FFFFFF",
              height: 30,
              margin: 4,
            }}
            size="small"
            disabled={value == ""}
            variant="contained"
            onClick={() => {
              rejectOnboarding(id, value);
              handleClose();
            }}
          >
            Post
          </Button>
        </DialogActions>
      </Dialog>
      {errorStatus && (
        <ErrorSnack reset={() => reset()} errorMsg={errorStatus.message} />
      )}
      {success && (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"Vehicle Brand Created"}
        />
      )}
    </div>
  );
}

const mapState = (state) => ({
  errorStatus: state.reject.error,
  success: state.reject.response,
  // success: state.reject,
});

const mapDispatch = {
  rejectOnboarding: ThunksReject.RejectOnboarding,
  updateField: RejectOnboarding.field.update,
  reset: Local.RejectOnboarding.api.reset,
};

export default connect(mapState, mapDispatch)(CustomizedDialogs);
