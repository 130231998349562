import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../../components/commons/ButtonGrouping";
import { Box } from "@material-ui/core";
import Breadcrumb from "../../../components/BreadCrumb";
import MOButtonV2 from "../../../components/commons/MOButtonV2";
import { Add, InfoOutlined } from "@material-ui/icons";
import HeaderDescription from "../../../components/commons/HeaderDescription";
import Select from "react-select";
import BookingValueProgress from "./charts/BookingValueProgress";
import PopularRouteProgressBar from "./charts/PopularRouteProgressBar";
import LeadTimeProgress from "./charts/LeadTimeProgress";
import CancellationRate from "./charts/CancellationRate";
import ConversionRate from "./charts/ConversionRate";

const paths = [
  { name: "Location & Rate", url: "/dashboard/integration/rates" },
  {
    name: "Deluxe taxi - Amsterdam",
    url: "/dashboard/integration/rates/city",
  },
  {
    name: "Select Airport",
    url: "/dashboard/integration/rates/mange-location",
  },
  {
    name: "Analysis & Rate Automation",
    url: "/dashboard/integration/rates/analysis-list",
  },
  {
    name: "Analysis Report",
    // url: "/dashboard/integration/rates/mange-location",
  },
];
const data = [
  {
    search: {
      date: "02.01.2024",
      time: "Morning",
    },
    booking: {
      date: "02.01.2024",
      time: "10:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 3,
    rate: 42,
  },
  {
    search: {
      date: "02.01.2024",
      time: "Afternoon",
    },
    booking: {
      date: "02.01.2024",
      time: "13:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 3,
    rate: 42,
  },
  {
    search: {
      date: "02.01.2024",
      time: "Evening",
    },
    booking: {
      date: "02.01.2024",
      time: "15:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 0,
    rate: 42,
  },
  {
    search: {
      date: "02.01.2024",
      time: "Night",
    },
    booking: {
      date: "02.01.2024",
      time: "18:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 2,
    rate: 42,
  },
  {
    search: {
      date: "02.01.2024",
      time: "Morning",
    },
    booking: {
      date: "02.01.2024",
      time: "10:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 3,
    rate: 42,
  },
  {
    search: {
      date: "02.01.2024",
      time: "Afternoon",
    },
    booking: {
      date: "02.01.2024",
      time: "13:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 3,
    rate: 42,
  },
  {
    search: {
      date: "02.01.2024",
      time: "Evening",
    },
    booking: {
      date: "02.01.2024",
      time: "15:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 0,
    rate: 42,
  },
  {
    search: {
      date: "02.01.2024",
      time: "Night",
    },
    booking: {
      date: "02.01.2024",
      time: "18:20",
    },
    pickup: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    drop: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    service: "Standard",
    frequency: 40,
    conversion: 2,
    rate: 42,
  },
];

const AnalysisReport = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("Location&Rate");
  return (
    <div>
      <StyledToggleButtonGroup
        value={type}
        exclusive
        onChange={(e, value) => {
          value != null && setType(value);
        }}
      >
        <StyledToggleButton disabled={loading} key={0} value={"Location&Rate"}>
          Location & Rate
        </StyledToggleButton>
        <StyledToggleButton disabled={loading} key={0} value={"Settings"}>
          Settings
        </StyledToggleButton>
      </StyledToggleButtonGroup>

      <Box
        className="mt-18"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Breadcrumb paths={paths} />
          <div className="breadcrumb-description">
            Use this area to manage analysis and rate automation
          </div>
        </div>
      </Box>
      <div>
        {/* Header Section */}
        <div className="bg-white main-section-spacing border-radius-8">
          <HeaderDescription
            heading={"Report"}
            description={"Report about the Analysis and automation."}
          />
        </div>
        {/* Chart section */}
        <div className="flex gap-16">
          <div className="bg-white main-section-spacing border-radius-8 chart-left-section">
            <PopularRouteProgressBar />
          </div>
          <div className="bg-white main-section-spacing border-radius-8 chart-right-section">
            <LeadTimeProgress />
          </div>
        </div>

        {/* chart section 2 */}
        <div className="flex gap-16">
          <div className="chart-left-section flex gap-16">
            <div className="bg-white main-section-spacing border-radius-8 flex-1 ">
              <div>
                <CancellationRate />
              </div>
            </div>
            <div className="bg-white main-section-spacing border-radius-8 flex-1 ">
              <div>
                <ConversionRate />
              </div>
            </div>
          </div>

          <div className="bg-white main-section-spacing border-radius-8 chart-right-section">
            <h5 className="font-16 font-600 m-0 mb-24">Booking Value</h5>
            <div className="flex justify-content-between">
              <div>
                <BookingValueProgress />
              </div>
              <div className="booking-value-progress">
                <p>
                  <span style={{ backgroundColor: "#29AEE6" }}></span>Morning
                </p>
                <p>
                  <span style={{ backgroundColor: "#90D4EE" }}></span>Afternoon
                </p>
                <p>
                  <span style={{ backgroundColor: "#0000FF" }}></span>Night
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* detail */}
        <div className="bg-white main-section-spacing border-radius-8">
          {/* Header */}
          <HeaderDescription heading={"Frequency Details"} />

          {/* table */}
          <div className="analysis-list-table mt-30">
            <table className="custom-table-rm">
              <thead>
                <tr>
                  <th className="">
                    <p className="m-0">Search</p>
                    <p className="m-0 font-8 font-400">(Date & Time)</p>
                  </th>
                  <th className="">
                    <p className="m-0">Booking</p>
                    <p className="m-0 font-8 font-400">(Date & Time)</p>
                  </th>
                  <th className="">Pickup Address</th>
                  <th className="">Drop-off Address</th>
                  <th className="">Service Level</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((row, index) => (
                  <tr key={index} className="font-12 font-600">
                    <td>
                      <p className="m-0"> {row.search.date}</p>
                      <p className="m-0"> {row.search.time}</p>
                    </td>
                    <td>
                      <p className="m-0">{row.booking.date}</p>
                      <p className="m-0">{row.booking.time}</p>
                    </td>
                    <td>{row.pickup}</td>
                    <td>{row.drop}</td>
                    <td>{row.service}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalysisReport;
