import { useEffect } from "react";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import ThunksTaxiCompanies from "../../../redux/actions/thunks/company-list";
import tableIcons from "../VehicleListing/constants";
import { IBANValueValidation } from "../../../validations";
import ThunksNetworks from "../../../redux/actions/thunks/networks";

function TaxiCompanies({
  companies,
  loading,
  getTaxiCompanies,
  getAllNetworks,
  networks,
  UpdateCompany,
}) {
  useEffect(() => {
    getTaxiCompanies();
    getAllNetworks();
  }, []);
  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1) || "";
  }

  return (
    <MaterialTable
      icons={tableIcons}
      editable={{
        onRowUpdate: async (newData, oldData) => {
          await UpdateCompany(oldData.id, newData);
          await getTaxiCompanies();
        },
      }}
      // other props
      title="Taxi Companies"
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
      }}
      options={{
        actionsColumnIndex: -1,
      }}
      isLoading={loading}
      columns={[
        { title: "Company Name", field: "companyName" },
        {
          title: "IBAN",
          field: "IBAN",
          validate: (rowData) => {
            return IBANValueValidation(rowData, "IBAN");
          },
        },

        { title: "Email", field: "email" },
        {
          title: "Network",
          field: "networkId",
          render: (rowData) => <p>{rowData?.network?.name}</p>,
          lookup: networks?.reduce(
            (acc, item) => ({ ...acc, [item.id]: item.name }),
            {}
          ),
        },

        {
          title: "Country",
          field: "activeCountry",
          render: (rowData) => (
            <span>{capitalizeFirstLetter(rowData.activeCountry)}</span>
          ),
        },
        { title: "Role", field: "role", editable: "never" },
        {
          title: "Active City",
          field: "activeCity",
          render: (rowData) => (
            <span>{capitalizeFirstLetter(rowData.activeCity)}</span>
          ),
        },
        { title: "Company Address", field: "companyAddress" },
        { title: "Company City", field: "companyCity" },
        {
          title: "Company Registration Nr",
          field: "companyRegistrationNumber",
        },
        { title: "Company VAT Nr", field: "companyVATNumber" },
        { title: "Bank Account Holder Name", field: "bankAccountHolderName" },
        {
          title: "VAT(%)",
          field: "vatPercentage",
        },
      ]}
      data={companies}
    />
  );
}

const mapState = (state) => ({
  companies: state.taxiCompanies.response.data,
  loading: state.taxiCompanies.loading,
  networks: state.networks.response.data,
});

const mapDispatch = {
  UpdateCompany: ThunksTaxiCompanies.UpdateCompany,
  getTaxiCompanies: ThunksTaxiCompanies.TaxiCompanies,
  getAllNetworks: ThunksNetworks.Networks,
};

export default connect(mapState, mapDispatch)(TaxiCompanies);
