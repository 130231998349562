import React from 'react'

function PrivacyPolicy() {
  return (
    <>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Document</title>
      <div style={{ width: 600, maxWidth: "100%", margin: "0 auto" }}>
        <h3 style={{ textAlign: "center" }}>PRIVACY POLICY</h3>
        <p>
          In order to facilitate you to better use our App (Mobio Dispatcher), we may collect and use
          information related to you.
        </p>
        <p>
          We hope you understand how we collect, use, store, and share information
          when using the Mobio Dispatcher App. We hope you can read this PRIVACY POLICY (the
          Privacy Policy") carefully and follow the guidelines to make the choices
          you think are appropriate when necessary.
        </p>
        <p>
          For the avoidance of doubt, your use or continued use of our App (Mobio Dispatcher) means by
          default that you agree to our collection, use, storage, and sharing of
          your relevant information following the <i>Privacy Policy</i>.
        </p>
        <h4 style={{ paddingTop: 20, marginBottom: 0 }}>
          1. INTRODUCTION AND SCOPE OF APPLICATION
        </h4>
        <div style={{ paddingLeft: 20 }}>
          <h4 style={{ marginTop: 12, marginBottom: 0 }}>1.1. Definitions</h4>
          <p>For the purposes of this Privacy Policy: </p>
          <ul style={{ paddingTop: 10 }}>
            <li style={{ listStyle: "lower-roman", marginBottom: 20 }}>
              <strong>App </strong> ( referred to as either "the Company", "We",
              "Us" or "Our" in this Disclaimer) refers to Mobio Dispatcher.
            </li>
            <li style={{ listStyle: "lower-roman", marginBottom: 20 }}>
              <strong>You,</strong> means the individual accessing the App, or the
              entity on behalf of which such individual is accessing or using the
              App, as applicable.
            </li>
            <li style={{ listStyle: "lower-roman", marginBottom: 20 }}>
              <strong>App </strong>
              refers to the Company’s App called Mobio Dispatcher.
            </li>
          </ul>
          <ul style={{ paddingTop: 10, paddingLeft: 17 }}>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>1.2.</strong>
              By using our App, you consent to the collection of information necessary to provide and improve the App’s functionality. This includes data such as your name, email, contact number, and any other relevant information that helps us provide you with personalized services.
              We will not use Google user data for any other purposes, such as marketing or advertising, without explicit user consent.
            </li>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>1.3.</strong>
              <p style={{ margin: 0, padding: 0 }}>
                <strong>Automatically collected information.</strong> When you use the Mobio Dispatcher App, we may automatically collect certain information about your device and usage. This includes, but is not limited to, your device's operating system, device identification code, resolution, location, and service-related data, for the sole purpose of improving app functionality.
              </p>
            </li>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>1.4.</strong>
              We may also collect your personal information when you send us an
              email message;
            </li>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>1.5.</strong>
              <div>
                <p style={{ margin: 0, padding: 0 }}>
                  You understand and agree that the following information does not
                  apply to
                </p>
                <p>Privacy Policy:</p>
                <p style={{ margin: 0, padding: 0 }}>
                  {" "}
                  Statistical data collected anonymously are not personal
                  information as defined in <i>Privacy Policy</i>. We will
                  automatically retain and store such information. At the same time,
                  we have the right to permit third parties to use and disclose
                  anonymously collected statistical data but only for improving the quality of the service.
                </p>
              </div>
            </li>
          </ul>
          <h4 style={{ paddingTop: 20, marginBottom: 0 }}>2. USE OF INFORMATION</h4>
          <ul style={{ paddingTop: 0, paddingLeft: 17 }}>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>2.1.</strong>
              The information we collect is used solely to provide you with a high-quality, personalized experience on the Mobio Dispatcher App. We collect data such as your name, email address, contact number, and other necessary information to optimize our service for you.
            </li>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>2.2.</strong>
              We may use your personal information for purposes such as:<br />
              &#8226; Improving the functionality of the App. <br />
              &#8226; Offering you personalized services within the app.<br />
              &#8226; Providing system updates and relevant notifications related to your use of the app.<br />
              {/* &#8226; Showing you personalized third-party promotional information through the system.<br/> */}
              <br />
              We will not use or share your data with third parties for promotional purposes without your explicit consent.
            </li>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>2.3.</strong>
              Purpose of use of the locations of the users. The Dispatch application
              should be seen as a “capacity network” and aims to give you the
              possibility to quickly consult the locations and status of drivers.
              This so that you can select a driver to send to a customer. This can
              be done by a Dispatch order for the purpose of carrying out a ride. This data is used only to provide the service and will not be shared with third parties for advertising purposes.
            </li>
          </ul>
          <h4 style={{ paddingTop: 20, marginBottom: 0 }}>
            3. INFORMATION DISCLOSURE
          </h4>
          <ul style={{ paddingTop: 0, paddingLeft: 17 }}>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>3.1.</strong>
              <div>
                .We promise that your registration information and the non-public
                content stored in the App during your use of the our App will not be
                publicly disclosed or provided to third parties, except under the
                following circumstances:
                <ul style={{ paddingTop: 10, paddingLeft: 17 }}>
                  <li
                    style={{ display: "flex", listStyle: "none", marginBottom: 20 }}
                  >
                    <strong style={{ marginRight: 3 }}>3.1.1.</strong>
                    If your explicit authorization has been given in advance;
                  </li>
                  <li
                    style={{ display: "flex", listStyle: "none", marginBottom: 20 }}
                  >
                    <strong style={{ marginRight: 3 }}>3.1.2.</strong>
                    To comply with relevant laws and regulations;
                  </li>
                  <li
                    style={{ display: "flex", listStyle: "none", marginBottom: 20 }}
                  >
                    <strong style={{ marginRight: 3 }}>3.1.3</strong>
                    Following the requirements of relevant government authorities;
                  </li>
                  <li
                    style={{ display: "flex", listStyle: "none", marginBottom: 20 }}
                  >
                    <strong style={{ marginRight: 3 }}>3.1.4</strong>
                    To respond to lawful requests from government authorities;
                  </li>
                  <li
                    style={{ display: "flex", listStyle: "none", marginBottom: 20 }}
                  >
                    <strong style={{ marginRight: 3 }}>3.1.5</strong>
                    To protect and defend our rights or property.
                  </li>
                </ul>
              </div>
            </li>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>3.2.</strong>
              If we use third-party service providers, they are prohibited from using your personal information for any purpose other than providing the services requested by us, and they must adhere to strict confidentiality agreements.
            </li>
          </ul>
          <h4 style={{ paddingTop: 20, marginBottom: 0 }}>
            4. SAFETY TIPS/REMINDER
          </h4>
          <ul style={{ paddingTop: 0, paddingLeft: 17 }}>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>4.1.</strong>
              We will strive to ensure the safety of your personal information, and
              use technical means to restrict unauthorized access, use, or
              disclosure of your personal information. Despite the above measures,
              please understand that due to technical limitations and various
              malicious methods that may exist, even if we strive to tighten
              safeguards, it is impossible to guarantee the 100% security of
              information. You need to understand that the system and communication
              network you use to access the App may have problems due to factors
              beyond our control.
            </li>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>4.2.</strong>
              We will only retain your personal information for the period necessary
              for the purpose stated in Privacy Policy and within/for the time limit
              required by laws and regulations.
            </li>
          </ul>
          <h4 style={{ paddingTop: 20, marginBottom: 0 }}>5. CHANGES</h4>
          <ul style={{ paddingTop: 0, paddingLeft: 17 }}>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              We may amend the terms of Privacy Policy, and such revisions are part
              of Privacy Policy. If such amendments result in a substantial
              reduction of your rights under Privacy Policy, we will notify you
              before the amendment takes effect. In this case/circumstance, if you
              continue to use our App, you agree by default to be bound by this
              revised privacy policy. The statement of Privacy Policy and its right
              to amendment, update and final interpretation belong to us.
            </li>
          </ul>
          <h4 style={{ paddingTop: 20, marginBottom: 0 }}>
            6. SECURITY OF YOUR PERSONAL INFORMATION
          </h4>
          <ul style={{ paddingTop: 0, paddingLeft: 17 }}>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>6.1.</strong>
              We secure your personal information from unauthorized access, use, or
              disclosure. We use the following methods for this purpose: - SSL
              Protocol
            </li>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>6.2.</strong>
              When personal information is transmitted to other parties, it is
              protected through the use of encryption, such as the Secure Sockets
              Layer (SSL) protocol.
            </li>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>6.3.</strong>
              We strive to take appropriate security measures to protect against
              unauthorized access to or alteration of your personal information.
              Unfortunately, no data transmission over the Internet or any wireless
              network can be guaranteed to be 100% secure. As a result, while we
              strive to protect your personal information, you acknowledge that: (a)
              there are security and privacy limitations inherent to the Internet
              which are beyond our control; and (b) security, integrity, and privacy
              of any and all information and data exchanged between you and us
              through this Site cannot be guaranteed.
            </li>
          </ul>
          <h4 style={{ paddingTop: 20, marginBottom: 0 }}>7. RIGHT TO DELETION</h4>
          <ul style={{ paddingTop: 0, paddingLeft: 17 }}>
            <li style={{ listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>7.1.</strong>
              Subject to certain exceptions set out below, on receipt of a
              verifiable request from you, we will:
              <div>
                <ul style={{ paddingTop: 10, paddingLeft: 35 }}>
                  <li style={{ listStyle: "disc", marginBottom: 20 }}>
                    Delete your personal information from our records; and
                  </li>
                  <li style={{ listStyle: "disc", marginBottom: 20 }}>
                    Direct any service providers to delete your personal information
                    from their records.
                  </li>
                </ul>
              </div>
            </li>
            <li style={{ listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>7.2.</strong>
              Please note that we may not be able to comply with requests to delete
              your personal information if it is necessary to:
              <div>
                <ul style={{ paddingTop: 10, paddingLeft: 35 }}>
                  <li style={{ listStyle: "disc", marginBottom: 20 }}>
                    Complete the use for which the personal information was
                    collected, provide service requested by you, or reasonably
                    anticipated within the context of our ongoing relationship with
                    you, or otherwise perform a contract between you and us;
                  </li>
                  <li style={{ listStyle: "disc", marginBottom: 20 }}>
                    Detect security incidents, protect against malicious, deceptive,
                    fraudulent, or illegal activity; or prosecute those responsible
                    for that activity;
                  </li>
                  <li style={{ listStyle: "disc", marginBottom: 20 }}>
                    Debug to identify and repair errors that impair existing
                    intended functionality;
                  </li>
                  <li style={{ listStyle: "disc", marginBottom: 20 }}>
                    Exercise free speech, ensure the right of another consumer to
                    exercise his or her right of free speech, or exercise another
                    right provided for by law;
                  </li>
                  <li style={{ listStyle: "disc", marginBottom: 20 }}>
                    Comply with all applicable data protection laws;
                  </li>
                  <li style={{ listStyle: "disc", marginBottom: 20 }}>
                    Engage in public or peer-reviewed scientific, historical, or
                    statistical research in the public interest that adheres to all
                    other applicable ethics and privacy laws.
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <h4 style={{ paddingTop: 20, marginBottom: 0 }}>
            8. E-MAIL COMMUNICATIONS
          </h4>
          <ul style={{ paddingTop: 0, paddingLeft: 17 }}>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>8.1.</strong>
              From time to time, we may contact you via email for the purpose of
              providing announcements, promotional offers and advertisements,
              alerts, confirmations, surveys, and/or other general communication. In
              order to improve our App, we may receive a notification when you open
              an email from us or click on a link therein
            </li>
            <li style={{ display: "flex", listStyle: "none", marginBottom: 20 }}>
              <strong style={{ marginRight: 3 }}>8.2.</strong>
              If you would like to stop receiving marketing or promotional
              communications via email from us, you may opt out of such
              communications by informing us when you receive our Email. .
            </li>
          </ul>
          <h4 style={{ paddingTop: 20, marginBottom: 0 }}>
            9. EXTERNAL DATA STORAGE SITES
          </h4>
          <p>
            We may store your data on servers provided by third party hosting
            vendors with whom we have contracted. We make sure the vendors we use are completely adhered to Google’s user data protection requirements.
          </p>
          <h4 style={{ paddingTop: 20, marginBottom: 0 }}>10. CONTACT</h4>
          <p style={{ paddingLeft: 17 }}>
            If you have any questions regarding privacy while using our App, or have
            any questions about our practices, contact us at:
          </p>
          <ul style={{ paddingTop: 10, paddingLeft: 55 }}>
            <li style={{ listStyle: "disc", marginBottom: 20 }}>
              By email: info@deluxetaxiamsterdam.com
            </li>
          </ul>
        </div>
      </div>
    </>

  )
}

export default PrivacyPolicy;