export enum AppSettingsKeys {
    SUPPORT_NUMBER= "support_number",
    VAT_PERCENTAGE= "VAT_percentage"
}

export enum EventTextDetails {
    DRIVER_DEPARTED_TO_DROPOFF= "Driver Departed to Drop-off Location",
    DRIVER_ARRIVED_AT_DROPOFF= "Driver Arrived at Drop-off Location",
    DRIVER_DEPARTED_TO_PICKUP= "Driver Departed to Pick-up Location",
    DRIVER_ARRIVED_AT_PICKUP= "Driver Arrived at Pick-up Location",
    DRIVER_SUBMITTED_CUSTOMER_NO_SHOW= "Driver Submitted Customer No Show Location"
}

export enum DifCars {
    ELECTRIC_LUXURY= 'ELECTRIC_LUXURY',
    ELECTRIC_PEOPLE_CARRIER= 'ELECTRIC_PEOPLE_CARRIER',
    ELECTRIC_EXE_PEOPLE_CARRIER= 'ELECTRIC_EXE_PEOPLE_CARRIER', 
    ELECTRIC_LARGE_PEOPLE_CARRIER= 'ELECTRIC_LARGE_PEOPLE_CARRIER'
}

export enum Roles {
    ORGANIZATION= 'Organization'
}

