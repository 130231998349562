import REPORT from "../../actions/local/constants/report";
import reportStates, { ReportStates} from "../../initial-state/reports";

const reportReducer = (state: ReportStates = reportStates, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case REPORT.RESET_ALL: {
      return {
        ...reportStates,
      };
    }
    case REPORT.FIELD.UPDATE: {
      const { field, newContent } = payload;
      return {
        ...state,
        [field]: newContent,
      };
    }
    case REPORT.FIELD.CLEAR: {
      const { field } = payload;
      return {
        ...state,
        [field]: "",
      };
    }

    default:
      return state;
  }
};

export default reportReducer;
