import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Input from "../../../components/commons/Input";
import { Box, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import ErrorSnack from "../../../components/commons/ErrorSnack";
import Local from "../../../redux/actions/local";
import AddSubAdminAuthorities from "./AddSubAdminAuthorities";
import SubAdminThunks from "../../../redux/actions/thunks/SubAdmins";
import MOButton from "../../../components/commons/MOButton";
import { ErrorMessage, Form, Formik } from "formik";
import { subAdminValidationSchema } from "../../../validations/subAdminValidation";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  section: {
    height: "100%",
    paddingTop: 5,
    backgroundColor: "#fff",
  },
  dialogPaper: {
    height: 600,
    marginLeft: 16,
    marginRight: 34,
  },

  button: {
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 8,
    backgroundColor: "#2AABD5",
    color: "#FFFFFF",
    height: 40,
  },
  textfield_upper: {
    marginTop: 14,
    marginBotton: 10,
    width: 550,
    height: 40,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AddSubAdminForm = ({
  openDialog,
  closeDialog,
  getAllSubAdmins,
  postSubAdmin,
  updateSubAdmins,
  status,
  failure,
  success,
  reset,
  response,
  dialogIsOpen,
  selectedUser,
  resetUpadte,
}) => {
  const classes = useStyles();
  const handleClickOpen = () => {
    openDialog();
  };
  const handleClose = () => {
    closeDialog();
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const payload = {
        displayName: values.displayName,
        fullName: values.fullName,
      };
      payload.selectedAuthorities = values.selectedAuthorities.map((item) => {
        return item.id;
      });
      if (selectedUser?.id) {
        payload.id = selectedUser.id;
        await updateSubAdmins(payload);
      } else {
        payload.email = values.email;
        await postSubAdmin(payload);
      }
    } catch (error) {
      console.log("Error");
      //   alert("Form not submit successfully ")
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (success) {
      getAllSubAdmins();
      handleClose();
    }

    if (response?.success) {
      getAllSubAdmins();
      handleClose();
    }
  }, [success, response?.success]);

  return (
    <div>
      <Box marginTop={"14px"} marginBottom={"24px"}>
        <MOButton
          title={!!selectedUser?.id ? "Edit Sub Admin" : "Add Sub Admin"}
          onClick={handleClickOpen}
        />
      </Box>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={dialogIsOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {!!selectedUser?.id ? "Update Sub Admin" : "Create Sub Admin"}
        </DialogTitle>
        <DialogContent dividers>
          {/* Formik Form handling start */}
          <Formik
            initialValues={{
              email: selectedUser ? selectedUser?.email : "",
              displayName: selectedUser ? selectedUser?.displayName : "",
              fullName: selectedUser ? selectedUser?.fullName : "",
              selectedAuthorities: selectedUser?.userAuthorities
                ? selectedUser?.userAuthorities
                : [],
            }}
            // validate={(values) => {
            //   const errors = {};
            //   if (!values.displayName) {
            //     errors.displayName = "Display Name is required.";
            //   }
            //   if (!values.fullName) {
            //     errors.fullName = "Full Name is required.";
            //   }

            //   if (values.selectedAuthorities.length == 0) {
            //     errors.selectedAuthorities =
            //       "Please select at least one authorities role";
            //   }

            //   if (!values.email) {
            //     errors.email = "Email is required";
            //   } else if (
            //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            //   ) {
            //     errors.email = "Invalid email address";
            //   }

            //   return errors;
            // }}
            validationSchema={subAdminValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form style={{ width: "550px" }}>
                {/* Email */}
                <Grid>
                  <Input
                    label="Email*"
                    value={values.email}
                    disabled={!!selectedUser?.id}
                    autoFocus
                    type="email"
                    size="small"
                    name="email"
                    className={classes.textfield_upper}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                  />
                  <ErrorMessage
                    name={`email`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>
                {/* Display Name */}
                <Grid>
                  <Input
                    label="Display Name*"
                    value={values.displayName}
                    type="text"
                    size="small"
                    name="displayName"
                    className={classes.textfield_upper}
                    onChange={(e) =>
                      setFieldValue("displayName", e.target.value)
                    }
                  />
                  <ErrorMessage
                    name={`displayName`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>
                <Grid>
                  <Input
                    label="User Name*"
                    value={values.fullName}
                    type="text"
                    size="small"
                    name="fullName"
                    className={classes.textfield_upper}
                    onChange={(e) => setFieldValue("fullName", e.target.value)}
                  />
                  <ErrorMessage
                    name={`fullName`}
                    component="div"
                    className="error-text-danger error-text-style"
                  />
                </Grid>
                <Grid>
                  <AddSubAdminAuthorities
                    selectedAuthorities={(sel) => {
                      setFieldValue("selectedAuthorities", sel);
                    }}
                  />
                  <Box marginTop={"8px"}>
                    <ErrorMessage
                      name={`selectedAuthorities`}
                      component="div"
                      className="error-text-danger error-text-style"
                    />
                  </Box>
                </Grid>

                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  marginTop={"24px"}
                >
                  <MOButton
                    submitType="submit"
                    title={
                      !!selectedUser?.id ? "Edit Sub Admin" : "Add Sub Admin"
                    }
                    disabled={isSubmitting}
                  />
                </Box>
              </Form>
            )}
          </Formik>
          {/* Formik Form handling end */}
        </DialogContent>
      </Dialog>
      {!!failure ? (
        <ErrorSnack
          reset={() => reset()}
          errorMsg={failure?.message || failure}
        />
      ) : null}
      {!!success ? (
        <ErrorSnack
          reset={() => reset()}
          success
          errorMsg={"User Created Successfully"}
        />
      ) : null}
      {!!response?.error && (
        <ErrorSnack errorMsg={response.error} reset={() => resetUpadte()} />
      )}
      {!!response?.success && (
        <ErrorSnack
          reset={() => resetUpadte()}
          success
          errorMsg={"User Updated Successfully"}
        />
      )}
    </div>
  );
};
const mapState = (state) => ({
  status: state,
  dialogIsOpen: state.orgUsers.dialog.isOpen,
  selectedUser: state.orgUsers.dialog.selectedUser,
  failure: state.subAdmin.createSubAdmin.error,
  success: state.subAdmin.createSubAdmin.success,
  response: state.subAdmin.updateCompleteSubAdmin,
});
const mapDispatch = {
  openDialog: Local.dialogActions.open,
  closeDialog: Local.dialogActions.close,
  reset: Local.CreateSubAdminUser.reset,
  resetUpadte: Local.UpdateCompleteSubAdmin.reset,
  postSubAdmin: SubAdminThunks.createSubAdmin,
  getAllSubAdmins: SubAdminThunks.subAdmins,
  updateSubAdmins: SubAdminThunks.updateSubAdmin,
};
export default connect(mapState, mapDispatch)(AddSubAdminForm);
