import React from "react";
import LineChart from "../../../../components/commons/LineChart";
import HeaderDescription from "../../../../components/commons/HeaderDescription";

const CancellationRate = () => {
  const dataArray = [
    18, 18.2, 18.4, 18.6, 18.8, 19, 19.2, 19.4, 19.6, 19.8, 20, 20.5, 20.6,
    20.7, 20.8, 20.9, 21.1, 21.3, 21.5, 21.7, 21.9, 20.5, 20.6, 20.7, 20.8,
    20.9, 19.5, 20.5, 21, 21.5, 20.5, 20.6, 20.7, 20.8, 20.9, 21.1, 21.3, 21.5,
    21.7, 21.9, 20.5, 19.5, 20.5, 21, 21.5, 20.5, 19, 19.5,
  ];

  return (
    <div>
      <HeaderDescription heading={"Cancellation Rate"} />
      <h6 className="text-danger font-24 font-700 m-0">-34%</h6>
      <LineChart dataArray={dataArray} height="90px" />
    </div>
  );
};

export default CancellationRate;
