import { exportNamedDeclaration } from "@babel/types";
import USER from "../constants/user";
import {
  PROFILE_API,
  PROFILE_PASSWORD_API,
  FORGOT_PASSWORD_API,
} from "../constants/user";

export type LocalUserRepo = {
  resetAll: () => any;
  updateUser: (obj: any | null) => any;
  updateProfile: (obj: any | null) => any;
  updateUserImage: (obj: any | null) => any;
  api: {
    response: (data: any) => any;
  };
};

export type ProfileRepo = {
  api: {
    request: () => any;
    error: (type: string | number, message: string) => any;
    response: (data: any) => any;
    reset: () => any;
  };
};

export const Profile: ProfileRepo = {
  api: {
    request: () => ({
      type: PROFILE_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: PROFILE_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: PROFILE_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: PROFILE_API.RESET,
      payload: {},
    }),
  },
};

export const ProfilePassword: ProfileRepo = {
  api: {
    request: () => ({
      type: PROFILE_PASSWORD_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: PROFILE_PASSWORD_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: PROFILE_PASSWORD_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: PROFILE_PASSWORD_API.RESET,
      payload: {},
    }),
  },
};

export const ForgotPassword: ProfileRepo = {
  api: {
    request: () => ({
      type: FORGOT_PASSWORD_API.SET_REQUEST,
      payload: {},
    }),
    error: (type, message) => ({
      type: FORGOT_PASSWORD_API.SET_ERROR,
      payload: { type, message },
    }),
    response: (data) => ({
      type: FORGOT_PASSWORD_API.SET_RESPONSE,
      payload: { data },
    }),
    reset: () => ({
      type: FORGOT_PASSWORD_API.RESET,
      payload: {},
    }),
  },
};

const User: LocalUserRepo = {
  resetAll: () => ({
    type: USER.RESET_USER,
    payload: {},
  }),
  updateUser: (payload: any) => ({
    type: USER.UPDATE_USER,
    payload: payload,
  }),
  updateProfile: (payload: any) => ({
    type: USER.PROFILE_UPDATE,
    payload: payload,
  }),
  updateUserImage: (payload: any) => ({
    type: USER.PROFILE_IMAGE,
    payload: payload,
  }),
  api: {
    response: (data) => ({
      type: USER.SET_RESPONSE,
      payload: { data },
    }),
  }
};

export default User;
