import { useNavigate, useParams } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { countryCodeList } from "../../data/countryCodeList";
import DropDown from "./DropDown";
import DeleteIcon from "../../assets/icons/menuDelete.png";
import EnableIcon from "../../assets/icons/enable_menu_icon.svg";
import DisableIcon from "../../assets/icons/diable_menu_icon.svg";
import { LocationCityOutlined } from "@material-ui/icons";

const CountryCard = ({
  data,
  anchorEl,
  setAnchorEl,
  setDeleteCountryModal = () => {},
  setSingleData = () => {},
  setStatusCountryModal = () => {},
}) => {
  const navigate = useNavigate();
  const { integrationType, integrationId } = useParams();
  return (
    <div
      className={`country-card-section ${
        !data?.isActive && "country-card-section-disable"
      }`}
    >
      <div className="flex align-item-center justify-content-between ">
        <div className="flex align-item-center">
          <div className="">
            <ReactCountryFlag
              countryCode={
                countryCodeList?.filter(
                  (item) => item.name === data?.countryName
                )[0]?.code
              }
              svg
              style={{
                width: "40px",
                height: "32px",
                borderRadius: 4,
              }}
            />
          </div>
          <div className="country-name-section">
            <h1>{data?.countryName}</h1>
          </div>
        </div>
        <div>
          {/* <img src={ArrowIcon} /> */}

          <DropDown
            dropDownOpen={anchorEl}
            setDropDownOpen={setAnchorEl}
            uniqueId={data.id}
            // IconRender={() => <img src={HorizontalIcon} />}
            dropDownShape="vertical"
            menu={
              data.isActive
                ? [
                    {
                      onClick: () => {
                        navigate(
                          `/dashboard/integration/rates/${integrationType}/${integrationId}/${data.countryName}/${data?.id}/city`
                        );
                      },
                      IconRender: () => <LocationCityOutlined />,
                      title: "Cities",
                    },
                    {
                      onClick: () => {
                        setSingleData(data);
                        setStatusCountryModal(true);
                      },
                      IconRender: () => <img src={DisableIcon} />,
                      title: "Disable",
                    },
                  ]
                : [
                    {
                      onClick: () => {
                        navigate(
                          `/dashboard/integration/rates/${integrationType}/${integrationId}/${data.countryName}/${data?.id}/city`
                        );
                      },
                      IconRender: () => <LocationCityOutlined />,
                      title: "Cities",
                    },
                    {
                      onClick: () => {
                        setSingleData(data);
                        setDeleteCountryModal(true);
                      },
                      IconRender: () => <img src={DeleteIcon} />,
                      title: "Delete",
                    },
                    {
                      onClick: () => {
                        setSingleData(data);
                        setStatusCountryModal(true);
                      },
                      IconRender: () => <img src={EnableIcon} />,
                      title: "Enable",
                    },
                  ]
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CountryCard;
