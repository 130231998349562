import MOModalWrapper from "../../../../components/commons/MOModalWrapper";
import MOButtonV2 from "../../../../components/commons/MOButtonV2";
import EnableIcon from "../../../../assets/icons/enable_location_icon.svg";
import DisableIcon from "../../../../assets/icons/enable_location_icon.svg";

const StatusCityModal = ({
  modalOpen,
  setModalOpen,
  apiCall = () => {},
  data,
}) => {
  return (
    <MOModalWrapper
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      title={data?.isActive ? "Disable City" : "Enable City"}
      className={"delete-modal-section"}
    >
      <div className="mt-30">
        <div className="flex flex-col align-item-center">
          <img
            src={data?.isActive ? DisableIcon : EnableIcon}
            alt="disconnection icon"
          />
          <h2 className="font-20 font-600 m-0 mt-24 ">{"Are You Sure?"}</h2>
          <p className="m-0 mt-8">
            {`Are you certain you want to ${
              data?.isActive ? "disable" : "enable"
            } this city?`}
          </p>
          <div className="flex align-item-center justify-content-end mt-50 mb-56">
            <div className="mr-20">
              <MOButtonV2
                text="NO, Cancel"
                textColor="#1D2433"
                radius={7}
                backgroundColor="#ffffff"
                border="1px solid #E0E0E0"
                fontSize={12}
                height={38}
                onClick={() => {
                  setModalOpen(false);
                }}
              />
            </div>
            <div>
              <MOButtonV2
                text={`YES, ${data?.isActive ? "Disable" : "Enable"}`}
                textColor="#FCFCFD"
                radius={7}
                backgroundColor="#2CA9DD"
                fontSize={12}
                height={38}
                onClick={() => {
                  apiCall();
                }}
                // border="1px solid #FC0808"
              />
            </div>
          </div>
        </div>
      </div>
    </MOModalWrapper>
  );
};

export default StatusCityModal;
